import { TImageData } from "../common/image-editor/TImageData";
import { DocumentDraftItem, EDocumentItemType, IDocumentDraftItem } from "../admin-console/administrator-console/documents/document-editor/IDocumentDraftItem";
import { IContentUnitText } from "../common/content/content-text/IContentUnitText";
import { IContentUnitImage } from "../common/content/content-image/IContentUnitImage";

export interface IReadableDocument {
  documentId: string;
  locale: string;
  title?: string;
  description?: string;
  items: IReadableDocumentItem[];
}

export class ReadableDocument implements IReadableDocument {
  documentId!: string;
  locale!: string;
  title?: string;
  description?: string;
  items!: ReadableDocumentItem[];
  //---------------------------------------------------------------------------
  constructor(source: IReadableDocument, isDraft: boolean = false) {
    Object.assign(this, source);
    this.items = [];
    source.items.forEach(item => {
      if (isDraft) {
        const draftItem = new DocumentDraftItem(item as IDocumentDraftItem);
        if (!draftItem.isDeleted) {
          this.items.push(draftItem);
        };
      } else {
        this.items.push(new ReadableDocumentItem(item));
      };
    });
  };
}

export interface IReadableDocumentItem {
  id: string;
  type: EDocumentItemType;
  path?: string;
  isNumbered: boolean;
  isMarked: boolean;
  header?: IContentUnitText;
  text?: IContentUnitText;
  image?: IContentUnitImage;
}

export class ReadableDocumentItem implements IReadableDocumentItem {
  id!: string;
  type!: EDocumentItemType;
  path?: string;
  isNumbered!: boolean;
  isMarked!: boolean;
  header?: IContentUnitText;
  text?: IContentUnitText;
  image?: IContentUnitImage;
  get fullPath(): string {
    return this.path ? `${this.path}.${this.id}` : this.id;
  };
  //---------------------------------------------------------------------------
  constructor(source: IReadableDocumentItem) {
    Object.assign(this, source);
    if (this.image) {
      this.image.imageData = new TImageData(this.image?.imageData);
    }
  };
}