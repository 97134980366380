import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../../context/app-auth-context/AppAuthContext";
import { ContentDirectoryDraft, IContentDirectoryDraftInitial } from "../content-directory-translator/IContentDirectoryDraft";
import { apiBasePath, pathSubmitContentDirectoryDraft } from "../../../../../utils/apiPathConstant";
import { stringFormatter } from "../../../../../utils/common";
import { apiPostPrivate, checkResponse } from "../../../../../utils/api";

interface IProps {
  msalInstanse: IPublicClientApplication;
  authConfig?: IAuthConfig;
  directoryId: string;
  localeId: string;
  abortSignal?: AbortSignal;
}

export async function submitContentDirectoryDraft(props: IProps): Promise<ContentDirectoryDraft> {
  if (!props.authConfig) throw new Error("submitContentDirectoryDraft: auth context is not provided");
  //---------------------------------------------------------------------------
  let uri = `${apiBasePath}${stringFormatter(pathSubmitContentDirectoryDraft, [props.directoryId, props.localeId])}`;
  const response = await apiPostPrivate(
    props.msalInstanse,
    props.authConfig,
    uri,
    props.abortSignal
  );
  checkResponse(response, "submitContentDirectoryDraft");
  //---------------------------------------------------------------------------
  const translationDraft = new ContentDirectoryDraft().initialize(response?.content as IContentDirectoryDraftInitial);
  return (translationDraft);
}