import GetTranslation from "../../../../common/functions/GetTranslation";
import ImageThumbnail from "../image-thumbnail/ImageThumbnail";
import { TImageLibraryItem } from "../TImageLibrary";
import styles from "./ImageListCard.module.css";
import CombineStyles from "../../../../../utils/combineStyles";
import { useContext, useState } from "react";
import { AppUiContextStore } from "../../../../../context/app-ui-context/AppUiContextProvider";
import { ImageListCardOptions } from "./ImageListCardOptions";
import PopupDialog from "../../../../common/popup-v2/popup-dialog/PopupDialog";
import { OkCancelToolbar } from "../../../../common/popup-v2/ok-cancel-toolbar/OkCancelToolbar";
import React from "react";
import { ImageUsageList } from "../image-usage/ImageUsageList";
import InputDescription from "../../../../common/image-editor/input-description/InputDescription";
import { ClassImageEditorContext } from "../../../../common/image-editor/image-editor-context/ImageEditorContextProvider";
import { IUiOption } from "../../../../common/options/IUiOption";

export type ImageHandler = (imageLibraryItem: TImageLibraryItem) => void;

const descriptionPlaceholder = {
  en: "Description is not set",
}

interface IProps {
  imageLibraryItem: TImageLibraryItem;
  selected: boolean;
  index: number;
  showToolbar: boolean;
  onImageSelect?: ImageHandler;
  onImageCopy?: ImageHandler;
  onImageEdit?: ImageHandler;
  onImageDelete?: ImageHandler;//(imageLibraryItem: TImageLibraryItem) => void;
  onDescriptionChanged?: ImageHandler;
}

export function ImageListCard(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const [showPopup, setShowPopup] = useState(false);
  const [description, setDescription] = useState(props.imageLibraryItem.description);
  const optionsInputDescription: IUiOption[] = [];
  ClassImageEditorContext.imageEditorUi?.menuContent.options.forEach(option => {
    switch (option.id) {
      case "OptionOk":
      case "OptionCancel":
        optionsInputDescription.push(option);
        break;
    }
  });

  //---------------------------------------------------------------------------
  // Select image action
  const selectImage = () => {
    //console.log("ImageListCard.selectImage", props.imageLibraryItem);
    props.onImageSelect && props.onImageSelect(props.imageLibraryItem);
  };
  //---------------------------------------------------------------------------
  const copyImage = () => {
    props.onImageCopy && props.onImageCopy(props.imageLibraryItem);
  };
  //---------------------------------------------------------------------------
  const editImage = () => {
    props.onImageEdit && props.onImageEdit(props.imageLibraryItem);
  };
  //---------------------------------------------------------------------------
  const showImageDeletePopup = () => {
    setShowPopup(true);
  }
  //---------------------------------------------------------------------------
  const onImageDeletePopupOk = () => {
    setShowPopup(false);
    props.onImageDelete?.(props.imageLibraryItem);
  }
  //---------------------------------------------------------------------------
  const onImageDeletePopupCancel = () => {
    setShowPopup(false);
  }
  //---------------------------------------------------------------------------
  const onDescriptionUpdate = (value: string) => {
    console.log("onDescriptionUpdate:", value);
    let s = value == "" ? undefined : value;
    if (props.imageLibraryItem.description != s) {
      props.imageLibraryItem.description = s;
      props.onDescriptionChanged?.(props.imageLibraryItem);
      setDescription(s);
    }
  };
  //---------------------------------------------------------------------------
  let descriptionToDisplay = props.imageLibraryItem.description
    ? props.imageLibraryItem.description
    : GetTranslation(appUiContext, descriptionPlaceholder, "")
  //---------------------------------------------------------------------------
  let dateTimeLocaleString: string | undefined = undefined;
  if (props.imageLibraryItem.lastModified) {
    try {
      //dateTimeLocaleString = new Date(props.data.uploadedAt).toLocaleString(appUiContext.locale.localeId);
      dateTimeLocaleString = new Date(props.imageLibraryItem.lastModified).toLocaleString();
    }
    catch (error) {
      dateTimeLocaleString = new Date(props.imageLibraryItem.lastModified).toLocaleString();
    }
  }
  //---------------------------------------------------------------------------
  let size = props.imageLibraryItem.heightPx && props.imageLibraryItem.widthPx
    ? `${props.imageLibraryItem.widthPx} x ${props.imageLibraryItem.heightPx} px`
    : undefined;
  //---------------------------------------------------------------------------
  //props.imageLibraryItem.id == "cf1ded7e-cfcb-4f54-9bf0-9f0eae7af627" && console.log("ImageListCard:", props.imageLibraryItem);
  //---------------------------------------------------------------------------
  return (
    <div
      //className={styles.container}
      id={`ImageListCard_${props.imageLibraryItem.id}`}
      className={CombineStyles([styles.container, props.selected ? styles.selected : ""])}
      //onClick={props.onSelectImage ? selectImage : openImage}
      onClick={selectImage}
    >
      <ImageThumbnail data={props.imageLibraryItem} width={"7em"} />
      <div className={styles.name}>
        {/* <div className={styles.value}>
          {description}
        </div> */}
        <InputDescription
          ui={ClassImageEditorContext.imageEditorUi?.inputDescription}
          options={optionsInputDescription}
          data={descriptionToDisplay}
          onUpdate={onDescriptionUpdate}
        />
        <div className={styles.id}>
          {`(id: ${props.imageLibraryItem.id})`}
        </div>
        <div className={styles.id}>
          {`Status: ${props.imageLibraryItem.imageStatus}`}
        </div>
        {size &&
          <div className={styles.system}>
            {size}
          </div>
        }
        <div className={styles.system}>
          <div>{dateTimeLocaleString && dateTimeLocaleString}</div>
        </div>
        <ImageUsageList
          imageData={props.imageLibraryItem}
        />
      </div>
      <ImageListCardOptions
        show={props.showToolbar}
        imageLibraryItem={props.imageLibraryItem}
        onEdit={editImage}
        onCopy={copyImage}
        //onDelete={deleteImage}
        onDelete={showImageDeletePopup}
      />
      {showPopup &&
        <PopupDialog
          header='Delete this image?'
          id={props.imageLibraryItem.id}
          isDraggable={false}
          isModal={true}
          shadowColor='var(--clr_accent_attention)'
          toolbarContent={
            <OkCancelToolbar
              dialogType="Warning"
              dialogButtons="YesNo"
              btnOptional={null}
              onCancel={onImageDeletePopupCancel}
              onOk={onImageDeletePopupOk}
            />
          }
          onClose={onImageDeletePopupCancel}
          //children={<div>Delete this image?</div>}
          children={
            <ImageListCard
              imageLibraryItem={props.imageLibraryItem}
              index={0}
              selected={false}
              showToolbar={false}
            />}
        />
      }
    </div>
  );
}
