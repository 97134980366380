import { useContext, useEffect, useState } from 'react';
import InputText, { IUiInputText } from '../../input-v2/input-text/InputText';
import styles from './InputDescription.module.css';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import IconInline from '../../icon/icon-inline/IconInline';
import CombineStyles from '../../../../utils/combineStyles';
import GetHint from '../../functions/GetHint';
import useClickOutsideDiv from '../../../../hooks/useClickOutsideDiv';
import { IUiOption } from '../../options/IUiOption';
import ConfirmOption from '../image-editor-option/ConfirmOption';
import { GetTUiMenuOption } from '../../menu/menu-content/menu-option/TUiMenuOption';
import CancelOption from '../image-editor-option/CancelOption';
import { EditFormState } from '../../edit-form/IEditFormState';
import ImageEditorOption from '../image-editor-option/ImageEditorOption';

const defaultOption: IUiOption = {
  id: "",
  directoryId: "",
  elementType: "",
  caption: undefined,
  hint: undefined
}

interface IProps {
  ui?: IUiInputText;
  data: string;
  options: IUiOption[];
  onUpdate: (updatedDescription: string) => void;
}

export default function InputDescription(props: IProps) {
  if (!props.ui) {
    return null;
  };
  const { appUiContext } = useContext(AppUiContextStore);
  const [editMode, setEditMode] = useState(false);
  const [state, setState] = useState<EditFormState<string>>();
  const inputDivRef = useClickOutsideDiv({
    unsavedChanges: editMode == true && state != undefined && state.isUpdated,
    handler: disableEditMode,
    ignorePopup: true
  });
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (editMode) {
      const newState = new EditFormState<string>({
        object: props.data,
        isValid: true
      });
      setState(newState);
    } else {
      setState(undefined);
    };
  }, [editMode]);
  function disableEditMode() {
    setEditMode(false);
  };
  //---------------------------------------------------------------------------
  const enableEditMode = () => {
    setEditMode(true);
  };
  //---------------------------------------------------------------------------
  const onUpdate = (updatedString: string) => {
    if (!state) return;
    const updatedState = new EditFormState<string>(state, {
      object: updatedString,
      isValid: true
    });
    setState(updatedState);
  };
  //---------------------------------------------------------------------------
  const onConfirm = () => {
    setEditMode(false);
    if (!state?.object) return;
    props.onUpdate(state.object);
  };
  //---------------------------------------------------------------------------
  const onCancel = () => {
    setEditMode(false);
  };
  //---------------------------------------------------------------------------
  let optionConfirm: IUiOption = defaultOption;
  let optionCancel: IUiOption = defaultOption;
  props.options.forEach(option => {
    switch (option.id) {
      case "OptionOk":
        optionConfirm = {
          ...option,
          iconId: 'check',
          customColor: 'var(--clr_accent_main)',
          isDisabled: !state?.isUpdated
        };
        break;
      case "OptionCancel":
        optionCancel = {
          ...option,
          iconId: 'close',
          customColor: 'var(--clr_accent_wrong)',
          isDisabled: false
        };
        break;
    };
  });
  //---------------------------------------------------------------------------
  if (editMode) return (
    <div
      ref={inputDivRef}
      className={CombineStyles([styles.container, styles.edit])}>
      <div
        className={styles.input}>
        <InputText
          ui={{ ...props.ui, hideCaption: true }}
          data={state?.object}
          onUpdate={onUpdate}
        />
      </div>
      <div className={styles.options}>
        <div className={styles.option}>
          <ImageEditorOption
            ui={optionCancel}
            onSelect={onCancel}
          />
        </div>
        <div className={styles.option}>
          <ImageEditorOption
            ui={optionConfirm}
            onSelect={onConfirm}
          />
        </div>
      </div>
    </div>
  ); else return (
    <div
      title={GetHint(appUiContext, props.ui.id, props.ui.hint)}
      className={CombineStyles([styles.container, styles.view])}
      onClick={enableEditMode}
    >
      {props.data}
      <IconInline imageName='pencil' />
    </div>
  );
}