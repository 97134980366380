import { IUiOption } from "../../../options/IUiOption";
import { IUiMenuContent } from "../../../options/menus/IUiMenuContent";
import { TUiMenuContent } from "../TUiMenuContent";

export type TUiMenuOption = {  
  id: string;
  directoryId: string;
  elementType: string;
  index: number;
  priorityLevel: number;
  caption: any; // uiTextProperty
  hint: any; // uiTextProperty
  iconFile?: string | null;
  isDefault: boolean;
  showDropdown?: boolean;
  disabled: boolean;
  visible: boolean;
  canHideCaption: boolean;
  customCaption?: any; // text with translations
  customColor?: string;
  action: any; // SSV 2023.05.25: action can be undefined or menu content (sub-menu)
  group?: string;
  count?: number;
}

export function GetTUiMenuOption(uiOption: IUiOption): TUiMenuOption {
  let updatedAction: string | TUiMenuContent | undefined = undefined;
  // Check if action is a submenu
  if (uiOption.action) {
    if (typeof uiOption.action == "string") {
      updatedAction = uiOption.action;
    } else {
      // consider it a IUiMenuContent
      const action = uiOption.action as IUiMenuContent;
      const suboptions = action.options.map(o => GetTUiMenuOption(o));
      const submenuContent: TUiMenuContent = {
        id: action.id,
        directoryId: action.directoryId,
        elementType: action.elementType,
        hint: action.hint,
        options: suboptions,
        optionGroups: [],
        visible: true,
        disabled: false
      };
      updatedAction = submenuContent;
    };
  };
  //---------------------------------
  return {
    id: uiOption.id,
    directoryId: uiOption.directoryId,
    elementType: uiOption.elementType,
    index: 0,
    priorityLevel: 0,
    caption: uiOption.caption,
    hint: uiOption.hint,
    iconFile: uiOption.iconId,
    isDefault: !!uiOption.isDefault,
    showDropdown: uiOption.isDropdown,
    disabled: !!uiOption.isDisabled,
    visible: true,
    canHideCaption: false,
    customCaption: uiOption.customCaption,
    customColor: uiOption.customColor, // NOTE: review!
    action: updatedAction,
    group: uiOption.group,
    count: uiOption.count
  };
}