import { IDocumentDraft } from "../../../administrator-console/documents/document-editor/IDocumentDraft";
import { DocumentDraftItem } from "../../../administrator-console/documents/document-editor/IDocumentDraftItem";

export interface IDocumentReviewState {
  documentItems: DocumentDraftItem[];
  isReadonly: boolean;
  isModified: boolean;
  isCompleted: boolean;
  isApproved: boolean;
}

interface IStateInitial {
  documentDraft: IDocumentDraft;
  isReadonly: boolean;
}

export class DocumentReviewState implements IDocumentReviewState {
  documentItems!: DocumentDraftItem[];
  isReadonly!: boolean;
  isModified!: boolean;
  isCompleted!: boolean;
  isApproved!: boolean;
  private initialItems!: DocumentDraftItem[];
  //---------------------------------------------------------------------------
  constructor(data: IDocumentReviewState) {
    Object.assign(this, data);
  };
  //---------------------------------------------------------------------------
  static initialize(source: IStateInitial) {
    const state = new DocumentReviewState({
      documentItems: source.documentDraft.items.map(item => new DocumentDraftItem(item)),
      isReadonly: source.isReadonly,
      isModified: false,
      isCompleted: false,
      isApproved: false
    });
    state.initialItems = source.documentDraft.items.map(item => new DocumentDraftItem(item));
    return state;
  };
  //---------------------------------------------------------------------------
  getUpdatedState(updatedItems: DocumentDraftItem[]): DocumentReviewState {
    const updatedState = new DocumentReviewState({
      ...this,
      documentItems: updatedItems
    });
    updatedState.checkChangesAndValidate();
    return updatedState;
  };
  //---------------------------------------------------------------------------
  checkChangesAndValidate() {
    let isModified = false;
    let isCompleted = true;
    let isApproved = true;
    //-------------------------------------------------------------------------
    for (let i = 0; i < this.documentItems.length; i++) {
      const item = this.documentItems[i];
      const initialItem = this.initialItems[i];
      if (item.status?.statusId != initialItem.status?.statusId)
        isModified = true;
      if (!item.status?.isCompleted)
        isCompleted = false;
      if (item.status?.isInTrouble)
        isApproved = false;
    }
    //-------------------------------------------------------------------------
    this.isModified = isModified;
    this.isCompleted = isCompleted;
    this.isApproved = isApproved;
  };
  //---------------------------------------------------------------------------
  restoreInitialState(): DocumentReviewState {
    const updatedState = new DocumentReviewState({
      ...this,
      documentItems: this.initialItems.map(item => new DocumentDraftItem(item)),
      isReadonly: false,
      isModified: false,
      isCompleted: false,
      isApproved: false
    });
    return updatedState;
  };
}