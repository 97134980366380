import { useContext, useEffect, useRef, useState } from 'react';
import styles from './ChecklistItemReject.module.css';
import { AppUiContextStore } from '../../../../../context/app-ui-context/AppUiContextProvider';
import GetName from '../../../../common/functions/GetName';
import CombineStyles from '../../../../../utils/combineStyles';
import InputsGroupWrapper from '../../../../common/input-v2/inputs-group-wrapper/InputsGroupWrapper';
import InputDropdown from '../../../../common/input-v2/input-dropdown/InputDropdown';
import { tmpUiEditFormRejection } from './IUiEditformRejectionReason';
import InputText from '../../../../common/input-v2/input-text/InputText';
import { getTUiMenuContent } from '../../../../common/menu/menu-content/TUiMenuContent';
import { IUiOption } from '../../../../common/options/IUiOption';
import FormOptions from '../../../../common/form-options-bar/FormOptions';
import { EContentUnitType } from '../../../administrator-console/dictionaries/rejection-reasons/DictionaryDraftItemRejectionReasons';
import { EditFormState } from '../../../../common/edit-form/IEditFormState';
import { IRequestRejectionReason } from '../../checklist/checklist-node-reject/IRequestRejectionReason';

interface IProps {
  unitType: EContentUnitType;
  data?: IRequestRejectionReason;
  // Filtered list of rejection reasons dictionary items depending on the type of the unit
  isReadonly: boolean;
  onReject: (reason: IRequestRejectionReason) => void;
  onCancel: () => void;
}

function onCloseWithUnsavedChanges() {
  alert("Unsaved changes");
};

export default function ChecklistItemReject(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const [editMode, setEditMode] = useState(props.data ? false : true);
  const [state, setState] = useState<EditFormState<IRequestRejectionReason>>();
  const editFormDivRef = useRef<HTMLDivElement>(null);
  // const editFormDivRef = useClickOutsideDiv({
  //   handler: onCloseWithUnsavedChanges,
  //   unsavedChanges: state && state.isUpdated,
  // });
  const reasonIdDisabledForTypes: EContentUnitType[] = ["AdLanguageSet", "AdModule"];
  //--------------------------------------------------------------------------- State
  useEffect(() => {
    const state = new EditFormState<IRequestRejectionReason>({
      object: props.data,
      isNew: props.data ? false : true,
      isValid: false
    });
    setState(state);
  }, [props.data]);
  //---------------------------------------------------------------------------
  const enableEditMode = () => {
    setEditMode(true);
  };
  //---------------------------------------------------------------------------
  const onReasonIdUpdate = (selectedOptionId?: string) => {
    if (!selectedOptionId) return;
    if (!state) return;
    const updatedData: IRequestRejectionReason = {
      ...state.object as IRequestRejectionReason,
      reasonId: selectedOptionId
    };
    const updatedState = new EditFormState<IRequestRejectionReason>(state, {
      object: updatedData,
      isUpdated: true,
      isValid: true
    });
    setState(updatedState);
  };
  //---------------------------------------------------------------------------
  const onReasonDetailsUpdate = (updatedDetails?: string) => {
    if (!state) return;
    const updatedData = {
      ...state.object as IRequestRejectionReason,
      reasonDetails: updatedDetails ? updatedDetails : undefined
    };
    const updatedState = new EditFormState<IRequestRejectionReason>(state, {
      object: updatedData,
      isUpdated: true,
      isValid: true
    });
    setState(updatedState);
  };
  //---------------------------------------------------------------------------
  const onReject = () => {
    if (!state?.object) return;
    props.onReject(state.object);
  };
  //---------------------------------------------------------------------------
  const onEditFormOptionSelect = (selectedOptionId: string) => {
    switch (selectedOptionId) {
      case "OptionOk":
        onReject();
        break;
      case "OptionCancel":
        setEditMode(false);
        props.onCancel();
        break;
    };
  };
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  tmpUiEditFormRejection.menuContent.options.forEach(option => {
    switch (option.id) {
      case "OptionOk":
        updatedOptions.push({
          ...option,
          iconId: "check",
          isDisabled: !state?.isUpdated || !state?.isValid,
          customColor: state?.object?.reasonId ? 'var(--clr_accent_wrong)' : undefined
        });
        break;
      case "OptionCancel":
        updatedOptions.push({ ...option, iconId: "close" });
        break;
    };
  });
  const formMenuContent = getTUiMenuContent({
    ...tmpUiEditFormRejection.menuContent,
    options: updatedOptions
  });
  //---------------------------------------------------------------------------
  let reasonString = undefined;
  if (props.data?.reasonId) {
    const reasonDictionaryItem = appUiContext.dictionaries?.requestRejectionReasons?.find(item => item.id == props.data?.reasonId);
    reasonString = `${GetName(appUiContext, props.data.reasonId, reasonDictionaryItem?.name)}`;
    if (props.data.reasonDetails || state?.object?.reasonDetails) {
      console.log(props.data.reasonDetails, state?.object?.reasonDetails)
      reasonString = `${reasonString}: ${props.data.reasonDetails || state?.object?.reasonDetails}`;
    };
  };
  //---------------------------------------------------------------------------
  // Filter rejection reasons based on content unit type
  const rejectionReasons = appUiContext.dictionaries?.requestRejectionReasons?.filter(reason => {
    return reason.objectTypes?.includes(props.unitType) && reason.status?.isAvailableToEndUsers;
  });
  //---------------------------------------------------------------------------
  if (editMode) return (
    <div
      ref={editFormDivRef}
      className={CombineStyles([
        styles.container,
        styles.editMode
      ])}>

      <div className={styles.inputs}>
        <InputsGroupWrapper>
          <InputDropdown
            ui={{
              ...tmpUiEditFormRejection.inputRejectionReason,
              // Disable reasonId dropdown in case of complex issue, because it's tied to a particular reason Id
              disabled: reasonIdDisabledForTypes.includes(props.unitType)
            }}
            data={state?.object?.reasonId}
            options={rejectionReasons}
            onUpdate={onReasonIdUpdate}
          />
          <InputText
            ui={{
              ...tmpUiEditFormRejection.inputRejectionReasonDetails,
              disabled: !state?.object?.reasonId
            }}
            data={state?.object?.reasonDetails}
            onUpdate={onReasonDetailsUpdate}
          />
        </InputsGroupWrapper>
      </div>

      <FormOptions
        ui={formMenuContent}
        applyMobileStyle={false}
        onSelect={onEditFormOptionSelect}
      />
    </div>
  ); else return (
    <div
      className={CombineStyles([
        styles.container,
        styles.viewMode,
        props.isReadonly ? styles.readonly : ""])}
      onClick={props.isReadonly ? undefined : enableEditMode}
    >
      {reasonString}
    </div>
  );
}