import { useContext } from 'react';
import { ScreenType, useAppScreenContext } from '../../../../context/app-screen-context/AppScreenProvider';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import GetHint from '../../functions/GetHint';
import { TUiMenuContent } from '../../menu/menu-content/TUiMenuContent';
import PopupHeader from '../popup-header/PopupHeader';
import PopupMenuItemGroup from './popup-menu-item-group/PopupMenuItemGroup';
import PopupMenuItem from './popup-menu-item/PopupMenuItem';
import styles from "./PopupMenu.module.css";
import PopupTest from '../popup/PopupTest';
import { TUiMenuOption } from '../../menu/menu-content/menu-option/TUiMenuOption';

interface IProps {
  ui: TUiMenuContent;
  anchorId?: string;
  displayIcon: boolean;
  fixHeight?: boolean;
  onClose: () => void;
  onBack?: () => void;
  onSelect?: (selectedOptionId: string) => void;
}

export default function PopupMenu(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const screenType = useAppScreenContext();
  const optionGroups: TUiMenuContent[] = props.ui.optionGroups.length > 0 ? props.ui.optionGroups : [];
  //---------------------------------------------------------------------------
  const getGroupId = (groupId?: string) => {
    if (groupId)
      return `${props.ui.id}_${groupId}`;
    else
      return `${props.ui.id}`;
  };
  //---------------------------------------------------------------------------
  props.ui.options?.forEach(option => {
    const groupId = getGroupId(option.group);
    let currentGroup = optionGroups.find(group => group.id == groupId);
    if (!currentGroup) {
      currentGroup = {
        ...props.ui,
        id: groupId,
        options: [],
        optionGroups: []
      };
      optionGroups.push(currentGroup);
    };
    //-------------------------------------------------------------------------
    currentGroup.options.push(option);
  });
  //---------------------------------------------------------------------------
  var menuOptionElements: JSX.Element[] = [];
  /* 
  if (props.ui.options.length > 0) {
    // Run through list of options
    props.ui.options.forEach(option => {
      menuOptionElements.push(
        <PopupMenuItem
          key={option.id}
          ui={option}
          displayIcon={props.displayIcon}
          showDividerBefore={option.showDividerBefore && props.ui.options.length > 1}
          showDividerAfter={option.showDividerAfter && props.ui.options.length > 1}
          onSelect={props.onSelect}
          onClose={props.onClose}
        />);
    });
  } else if (props.ui.optionGroups?.length > 0) {
    //-------------------------------------------------------------------------
    // Run through list of option groups
    const groupsCount = props.ui.optionGroups.length;
    var i = 0;
    props.ui.optionGroups.forEach(optionGroup => {
      const optionsGroup = optionGroup as TUiMenuContent;
      menuOptionElements.push(
        <PopupMenuItemGroup
          key={optionsGroup.id}
          ui={optionsGroup}
          displayIcon={props.displayIcon}
          isFirst={i == 0}
          isLast={i == groupsCount - 1}
          onSelect={props.onSelect}
          onClose={props.onClose}
        />
      );
      i = i + 1;
    });
  }; */
  //-------------------------------------------------------------------------
  // Run through list of option groups
    const groupsCount = optionGroups.length;
    var i = 0;
    optionGroups.forEach(optionGroup => {
      const optionsGroup = optionGroup as TUiMenuContent;
      menuOptionElements.push(
        <PopupMenuItemGroup
          key={optionsGroup.id}
          ui={optionsGroup}
          displayIcon={props.displayIcon}
          isFirst={i == 0}
          isLast={i == groupsCount - 1}
          onSelect={props.onSelect}
          onClose={props.onClose}
        />
      );
      i = i + 1;
    });
  //---------------------------------------------------------------------------
  return (
    <PopupTest
      id={props.ui.id}
      anchorId={props.anchorId}
      isModal={false}
      closeOnClickOutside={true}
      onClose={props.onClose}
    >

      {screenType == ScreenType.Mobile &&
        <PopupHeader
          header={GetHint(appUiContext, props.ui.id, props.ui.hint)}
          onClose={props.onClose}
          onBack={props.onBack}
        />}

      <div className={styles.container}>
        {menuOptionElements}
      </div>

    </PopupTest>
  );
}