import { IContentUnitText } from "../../../../common/content/content-text/IContentUnitText";
import { ClassContentUnitStatus, IContentUnitStatus } from "../../../../common/content/IContentUnit";

const ContactTypeArray = ["Email", "Website", "Mobilephone", "Landline", "Fax", "Whatsapp", "Telegram", "Twitter", "Skype", "Facebook", "Linkedin"] as const;
type TContactTypeTuple = typeof ContactTypeArray;
export type TContactType = TContactTypeTuple[number];

export function IsValidContactType(value: string): value is TContactType {
  return ContactTypeArray.includes(value as TContactType)
}

export interface IContact<T> {
  id: string;
  contactType: string; //TContactType;
  value?: T;
  description?: T;
  status?: IContentUnitStatus;
}

export class Contact<T> implements IContact<T> {
  id!: string;
  contactType!: string; //TContactType;
  value?: T;
  description?: T;
  status?: IContentUnitStatus;
  //---------------------------------------------------------------------------
  constructor(source: IContact<T>) {
    if (source)
      Object.assign(this, source);
  };
  //---------------------------------------------------------------------------
  static createContact(source: IContact<IContentUnitText>): Contact<string> {
    const result = new Contact<string>({
      id: source.id,
      contactType: source.contactType,
      value: source.value?.text,
      description: source.description?.text,
      status: source.status
    });
    return result;
  };
  //---------------------------------------------------------------------------
  static createContacts(sources?: IContact<IContentUnitText>[]): Contact<string>[] {
    if (!sources) return [];
    const results = sources.map(source => Contact.createContact(source));
    return results;
  }
  //---------------------------------------------------------------------------
  static getUpdatedContact(
    addressBlockContact: IContact<IContentUnitText>, // Contact that already in the selected list
    businessContact: IContact<string>           // Contact that just added to the list
  ): IContact<IContentUnitText> {
    const valueIsUpdated = addressBlockContact.value?.text !== businessContact.value;
    const descriptionIsUpdated = addressBlockContact.description?.text !== businessContact.description;
    const isUpdated = valueIsUpdated || descriptionIsUpdated;
    // If something is changed, all the statuses should bet set to 'Draft'
    //-------------------------------------------------------------------------
    const updatedValue: IContentUnitText = {
      type: "Contact",
      text: businessContact.value,
      status: isUpdated ? new ClassContentUnitStatus("Draft") : addressBlockContact.value?.status
    };
    //-------------------------------------------------------------------------
    const updatedDescription: IContentUnitText | undefined = businessContact.description ? {
      type: "Description",
      text: businessContact.description,
      status: isUpdated ? new ClassContentUnitStatus("Draft") : addressBlockContact.description?.status
    } : undefined;
    //-------------------------------------------------------------------------
    const result: IContact<IContentUnitText> = {
      id: addressBlockContact.id,
      contactType: addressBlockContact.contactType,
      value: updatedValue,
      description: updatedDescription,
      status: isUpdated ? new ClassContentUnitStatus("Draft") : addressBlockContact.status
    };
    return result;
  }
  //---------------------------------------------------------------------------
  static fromArray(source: any): Contact<string>[] {
    let result: Contact<string>[] = [];
    if (source)
      source.forEach((item: any) => result.push(new Contact(item)));
    return result;
  }
}