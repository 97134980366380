import { useParams } from 'react-router-dom';
import DictionaryEditorWrapper from '../../../common/dictionary-editor-wrapper/DictionaryEditorWrapper';
import { useContext, useEffect, useState } from 'react';
import { DictionaryTranslationDraft, DictionaryTranslatorState, IDictionaryTranslatorState } from '../dictionary-translator/DictionaryTranslator';
import getDictionaryTranslationDraft from '../dictionary-translator/getDictionaryTranslationDraft';
import { useMsal } from '@azure/msal-react';
import { AppAuthContextStore } from '../../../../../context/app-auth-context/AppAuthContext';
import HierarchicalTranslatorNode from './hierarchical-translator-node/HierarchicalTranslatorNode';
import { dictionaryNodeWrapperTmpUi } from '../../../administrator-console/dictionaries/common/dictionary-node-wrapper/DictionaryEditorNodeWrapper';
import { DictionaryTranslationDraftItem } from '../DictionaryTranslationDraftItem';
import saveDictionaryTranslationDraft from '../dictionary-translator/saveDictionaryTranslationDraft';
import deleteDictionaryTranslationDraft from '../dictionary-translator/deleteDictionaryTranslationDraft';
import publishDictionaryTranslationDraft from '../dictionary-translator/publishDictionaryTranslationDraft';
import { IDictionaryDraftFilter } from '../../../common/dictionary-editor-wrapper/dictionary-editor-filter/DictionaryEditorFilter';
import { useAbortController } from '../../../../../hooks/useAbortController';
import { EDictionaryType } from '../../../common/dictionary-draft-models/EDictionaryType';
import { EHttpStatusCode } from '../../../../../utils/HttpStatusCodes';
//-------------------------------------------------------------------------------------
interface IProps {
  dictionaryType: EDictionaryType
}
//-------------------------------------------------------------------------------------
export default function HierarchicalTranslator(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { localeId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState<DictionaryTranslatorState>();
  const abortController = useAbortController(`${props.dictionaryType}Translator`);
  //--------------------------------------------------------------------------- Get data
  useEffect(() => {
    getDraft(false);
    return (() => {
      abortController.abortOnUnmount();
    });
  }, []);
  //---------------------------------------------------------------------------
  const getDraft = (lockIsNeeded: boolean) => {
    if (!localeId)
      return;
    //console.log("DictionaryTranslator: getting draft...")
    //-----------------------------------------------------------------------
    // Get Dictionary translation draft
    setIsLoading(true);
    let controller = abortController.newController("getDictionaryTranslationDraft");
    getDictionaryTranslationDraft(
      instance,
      props.dictionaryType, //"BusinessTags",
      localeId,
      lockIsNeeded,
      appAuthContext.config,
      controller.signal
    ).then(dictionaryDraft => {
      //console.log("getDictionaryTranslationDraft:", dictionaryDraft);
      let locker = dictionaryDraft.locker;
      if (locker)
        locker.errorPrefix = `Dictionary [${props.dictionaryType}]`;
      let translatorState: DictionaryTranslatorState;
      if (state) {
        //-------------------------------------------------------------------
        // State is already set
        translatorState = new DictionaryTranslatorState(undefined, {
          state: state,
          spread: {
            draft: new DictionaryTranslationDraft(dictionaryDraft),
            editModeIsEnabled: !!(locker && locker.statusCode == EHttpStatusCode.OK)
          }
        });
      }
      else {
        //-------------------------------------------------------------------
        // State is not set yet
        translatorState = new DictionaryTranslatorState({
          dictionaryDraft: dictionaryDraft,
          editMode: !!(locker && locker.statusCode == EHttpStatusCode.OK)
        });
      }
      !controller.aborted && setState(translatorState);
    }).catch(error => {
      console.error(error);
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const onSaveDraft = () => {
    if (!state?.draft) {
      console.error("Cannot save dictionary draft, state is not set");
      return;
    }
    setIsLoading(true);
    let controller = abortController.newController("saveDictionaryTranslationDraft");
    saveDictionaryTranslationDraft(
      instance,
      state?.draft,
      appAuthContext.config
    ).then(dictionaryDraft => {
      const translatorState = new DictionaryTranslatorState({
        dictionaryDraft: dictionaryDraft,
        editMode: true
      });
      !controller.aborted && setState(translatorState);
    }).catch(error => {
      console.error(error);
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const onDeleteDraft = (releaseLockCallback?: () => void) => {
    if (state?.draft && state.draft.dictionaryId && state.draft.locale) {
      setIsLoading(true);
      let controller = abortController.newController("deleteDictionaryTranslationDraft");
      //-----------------------------------------------------------------------
      // Delete Dictionary translation draft
      deleteDictionaryTranslationDraft(
        instance,
        state.draft.dictionaryId,
        state.draft.locale,
        appAuthContext.config
      ).then(dictionaryDraft => {
        // Probably we should release lock on backend: need to update Locker component
        releaseLockCallback && releaseLockCallback();
        const translatorState = new DictionaryTranslatorState({
          dictionaryDraft: dictionaryDraft,
          editMode: false
        });
        !controller.aborted && setState(translatorState);
      }).catch(error => {
        console.error(error);
      }).finally(() => {
        !controller.aborted && setIsLoading(false);
      });
    } else console.error("Cannot delete dictionary draft, state is not set");
  };
  //---------------------------------------------------------------------------
  const onPublishDraft = () => {
    if (state?.draft && state.draft.locale) {
      setIsLoading(true);
      let controller = abortController.newController("publishDictionaryTranslationDraft");
      publishDictionaryTranslationDraft(
        instance,
        state.draft.dictionaryId,
        state.draft.locale,
        appAuthContext.config
      ).then(dictionaryDraft => {
        const translatorState = new DictionaryTranslatorState({
          dictionaryDraft: dictionaryDraft,
          editMode: false // ???
        });
        !controller.aborted && setState(translatorState);
      }).catch(error => {
        console.error(error);
      }).finally(() => {
        !controller.aborted && setIsLoading(false);
      });
    } else console.error("Cannot save dictionary draft, state is not set");
  };
  //---------------------------------------------------------------------------
  const onFilterUpdate = (updatedFilter: IDictionaryDraftFilter) => {
    setState(new DictionaryTranslatorState(undefined, {
      state: state as IDictionaryTranslatorState,
      spread: { filter: updatedFilter }
    }));
  };
  //---------------------------------------------------------------------------
  const onEnableEditMode = () => {
    getDraft(true);
  };
  //---------------------------------------------------------------------------
  const onDisableEditMode = () => {
    setState(new DictionaryTranslatorState(undefined, {
      state: state as IDictionaryTranslatorState,
      spread: {
        draft: state?.draftSessionInitial,
        editModeIsEnabled: false
      }
    }));
  };
  //---------------------------------------------------------------------------
  const onSourceLocaleUpdate = (localeId: string) => {
    setState(new DictionaryTranslatorState(undefined, {
      state: state as IDictionaryTranslatorState,
      spread: { sourceLocale: localeId }
    }));
  };
  //---------------------------------------------------------------------------
  const onTranslationDraftItemUpdate = (updatedItem: DictionaryTranslationDraftItem) => {
    if (state?.draft.items) {
      const updatedItems = state?.draft.items?.map(item => item.id == updatedItem.id ? updatedItem : item);
      const updatedDictionaryDraft = new DictionaryTranslationDraft(state.draft);
      updatedDictionaryDraft.items = updatedItems;
      setState(new DictionaryTranslatorState(undefined, {
        state: state as IDictionaryTranslatorState,
        spread: { draft: updatedDictionaryDraft }
      }));
    } else console.error("Items are being updated but state is not set");
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (selectedOptionId: string, releaseLockCallback?: () => void) => {
    switch (selectedOptionId) {
      case "optionEnableEditMode":
        setState(new DictionaryTranslatorState(undefined, {
          state: state as IDictionaryTranslatorState,
          spread: { editModeIsEnabled: true }
        }));
        break;
      case "optionDisableEditMode":
        onDisableEditMode();
        break;
      case "optionSave":
        onSaveDraft();
        break;
      case "optionDraftDelete":
        onDeleteDraft(releaseLockCallback);
        break;
      case "optionPublish":
        onPublishDraft();
        break;
      default:
        break;
    };
  };
  //---------------------------------------------------------------------------
  const filteredDictionaryItems = state?.draft?.items?.filter(item => {
    return item.filter(state.filter);
  });
  const dictionaryNodes = filteredDictionaryItems?.map(item => {
    return (
      <HierarchicalTranslatorNode
        key={item.id}
        ui={dictionaryNodeWrapperTmpUi}
        data={item}
        stateGroups={state?.filter.stateGroups}
        draftLocale={state?.draft.locale}
        sourceLocale={state?.sourceLocale}
        isHierarchical={state?.draft.isHierarchical}
        editModeIsEnabled={state?.editModeIsEnabled}
        filterState={state?.filter}
        onUpdate={onTranslationDraftItemUpdate}
      />
    );
  });
  //---------------------------------------------------------------------------
  return (
    <DictionaryEditorWrapper
      setup={{
        mode: "Translator",
        role: "SystemTranslator",
        entityType: "Dictionary"
      }}
      state={state}
      //tableHeader={tableHeader}
      isLoading={isLoading}
      onFilterUpdate={onFilterUpdate}
      onEnterEditMode={onEnableEditMode}
      onOptionSelect={onOptionSelect}
      onSourceLocaleUpdate={onSourceLocaleUpdate}
    >
      {dictionaryNodes}
    </DictionaryEditorWrapper>
  );
}