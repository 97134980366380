import { EImageStatus, TImageData } from "../../image-editor/TImageData";
import { ClassContentUnitStatus, IContentUnit } from "../IContentUnit";

export type EContentUnitImageType = 'Background' | 'Image' | 'Logo';

export interface IContentUnitImage extends IContentUnit {
  type: EContentUnitImageType;
  imageData?: TImageData;
  positionH?: "Left" | "Center" | "Right";
  positionV?: "Top" | "Center" | "Bottom";
  widthPercent?: number;
  heightPercent?: number;
}

export const getImageUnitStatus = (imageStatus: EImageStatus): ClassContentUnitStatus => {
  // if image is rejected, it should be removed from the ad module???
  switch (imageStatus) {//"None" | "Draft" | "ApprovePending" | "Approved" | "Published" | "Rejected"
    case "Published":
    case "Approved":
      return new ClassContentUnitStatus("Approved");
    default:
      return new ClassContentUnitStatus("Draft");
  };
};