import { apiGet, checkResponse } from "../../utils/api";
import { apiBasePath, pathGetLegalDocuments } from "../../utils/apiPathConstant";
import { stringFormatter } from "../../utils/common";
import { IReadableDocument, ReadableDocument } from "./IReadableDocument";

interface IProps {
  abortSignal?: AbortSignal;
  locale: string;
}

export async function getLegalDocumentsAsync(props: IProps): Promise<ReadableDocument[]> {
  let uri = `${apiBasePath}${stringFormatter(pathGetLegalDocuments, [props.locale])}`;
  const response = await apiGet(
    uri,
    props.abortSignal
  );
  //---------------------------------------------------------------------------
  checkResponse(response, "getLegalDocumentsAsync", props.abortSignal?.aborted);
  const docs = (response?.content as IReadableDocument[]).map(doc => new ReadableDocument(doc));
  return docs;
}