import React, { ReactNode, useEffect, useState } from "react";
import { DocumentDraftItem } from "../../../../administrator-console/documents/document-editor/IDocumentDraftItem";
import ChecklistNodeWrapper from "../../../checklist/checklist-node-wrapper/ChecklistNodeWrapper";
import ChecklistNodeAttribute from "../../../checklist/checklist-node-attribute/ChecklistNodeAttribute";
import ChecklistItemText from "./ChecklistItemText";
import ChecklistItems from "../checklist-items/ChecklistItems";
import { IHoveredItem } from "../../request-form/DocumentRequestForm";
import { IContentUnitText } from "../../../../../common/content/content-text/IContentUnitText";

interface IProps {
  level: number;
  prefix?: string;
  data: DocumentDraftItem;
  subItems?: DocumentDraftItem[];
  isReadonly: boolean;
  isExpanded: boolean;
  onHover: (item?: IHoveredItem) => void;
  onReview?: (updatedItems: DocumentDraftItem[]) => void;
}

export default function ChecklistItemSection(props: IProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const headerPath = `${props.data.fullPath}.header`;
  const annotationPath = `${props.data.fullPath}.text`;
  //---------------------------------------------------------------------------
  useEffect(() => {
    setIsExpanded(props.isExpanded);
  }, [props.isExpanded]);
  //---------------------------------------------------------------------------
  const onHover = () => {
    props.onHover({
      path: props.data.fullPath,
      status: props.data.status
    });
  };
  //---------------------------------------------------------------------------
  const onHoverEnd = () => {
    props.onHover();
  };
  //---------------------------------------------------------------------------
  const onHeaderHover = (hovered: boolean) => {
    if (hovered)
      props.onHover({
        path: headerPath,
        status: props.data.header?.status
      });
    else
      props.onHover();
  };
  //---------------------------------------------------------------------------
  const onTextHover = (hovered: boolean) => {
    if (hovered)
      props.onHover({
        path: annotationPath,
        status: props.data.text?.status
      });
    else
      props.onHover();
  };
  //---------------------------------------------------------------------------
  const onHeaderReview = (updatedTextUnit: IContentUnitText) => {
    if (!props.onReview) return;
    const updatedItem = new DocumentDraftItem({
      ...props.data,
      header: updatedTextUnit
    });
    updatedItem.updateStatus(props.subItems);
    props.onReview([updatedItem]);
  };
  //---------------------------------------------------------------------------
  const onTextReview = (updatedTextUnit: IContentUnitText) => {
    if (!props.onReview) return;
    const updatedItem = new DocumentDraftItem({
      ...props.data,
      text: updatedTextUnit
    });
    updatedItem.updateStatus(props.subItems);
    props.onReview([updatedItem]);
  };
  //---------------------------------------------------------------------------
  const onChildReview = (updatedItems: DocumentDraftItem[]) => {
    if (!props.onReview) return;
    // Get updated list of sub-items to be able to update current item status
    let updatedAllItems = props.subItems;
    updatedItems.forEach(updatedItem => {
      updatedAllItems = updatedAllItems?.map(item =>
        item.fullPath == updatedItem.fullPath ? updatedItem : item
      );
    });
    //-------------------------------------------------------------------------
    // Update current item status
    const updatedParent = new DocumentDraftItem({
      ...props.data
    });
    updatedParent.updateStatus(updatedAllItems);
    if (updatedParent.status?.statusId != props.data.status?.statusId) {
      updatedItems.push(updatedParent);
    };
    //-------------------------------------------------------------------------
    props.onReview(updatedItems);
  };
  //---------------------------------------------------------------------------
  const propSubItems: ReactNode[] = [];
  if (props.data.header) {
    const headerNode =
      <ChecklistItemText
        key={`${props.data.id}_header`}
        level={props.level + 1}
        data={props.data.header}
        isReadonly={props.isReadonly}
        onHover={onHeaderHover}
        onReview={onHeaderReview}
      />;
    propSubItems.push(headerNode);
  };
  if (props.data.text) {
    const annotationNode =
      <ChecklistItemText
        key={`${props.data.id}_text`}
        level={props.level + 1}
        data={props.data.text}
        isReadonly={props.isReadonly}
        onHover={onTextHover}
        onReview={onTextReview}
      />;
    propSubItems.push(annotationNode);
  };
  //---------------------------------------------------------------------------
  if (props.data.status) return (
    <React.Fragment>
      <ChecklistNodeWrapper
        data={props.data.status}
        isReadonly={true}
        onHover={onHover}
        onHoverEnd={onHoverEnd}
      >

        <ChecklistNodeAttribute
          level={props.level}
          customStyles={{ gridColumn: '1 / span 2' }}
          isExpanded={isExpanded}
          onToggle={() => setIsExpanded(!isExpanded)}
        >
          {props.prefix ? `${props.prefix}. ` : ""}
          {props.data.type}
        </ChecklistNodeAttribute>

      </ChecklistNodeWrapper>

      {isExpanded && propSubItems}

      {isExpanded && props.subItems &&
        <ChecklistItems
          path={props.data.fullPath}
          prefix={props.prefix}
          data={props.subItems}
          isReadonly={props.isReadonly}
          onHover={props.onHover}
          onReview={onChildReview}
        />}

    </React.Fragment>
  ); else return (
    <div>
      No status
    </div>
  );
}