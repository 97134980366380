import { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { AppAuthContextStore } from "../../../context/app-auth-context/AppAuthContext";
import styles from "./Locker.module.css";
import { ILocker, TLocker } from "./TLocker";
import CombineStyles from "../../../utils/combineStyles";

//---------------------------------------------------------------------------------
interface IProps {
  locker?: ILocker;
  inSession?: boolean;
  onLock?: () => void;
}
//---------------------------------------------------------------------------------
export function Locker(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const [locker, setLocker] = useState<TLocker>();
  const lockerRef = useRef<TLocker>();
  const ref = useRef<HTMLDivElement>(null);
  //---------------------------------------------------------------------------------
  useEffect(() => {
    return (() => {
      let locker = lockerRef.current;
      console.log("Locker.useEffect.return:", locker);
      if (locker && locker.isValid) {
        console.log("Locker.useEffect[locker].return: releasing lock...");
        locker.releaseLock(appAuthContext.config, instance);
      }
    });
  }, []);
  //---------------------------------------------------------------------------------
  useEffect(() => {
    if (props.inSession == false && locker?.isValid == true) {
      console.log("Locker.useEffect[props.inSession]: releasing lock...");
      locker.releaseLock(appAuthContext.config, instance);
    }
  }, [props.inSession])
  //---------------------------------------------------------------------------------
  useLayoutEffect(() => {
    console.log("Locker.useLayoutEffect:", props.locker);
    if (props.locker)
      lockerRef.current = new TLocker(props.locker);
    else
      lockerRef.current = undefined;
    setLocker(lockerRef.current);
  }, [props.locker]);
  //---------------------------------------------------------------------------------
  useEffect(() => {
    //console.log("Locker.useEffect[props.locker]:", props.locker);
    if (props.onLock && locker && ref.current && !locker.isValid) {
      props.onLock();
    }
  }, [locker, ref.current]);
  //---------------------------------------------------------------------------------
  useEffect(() => {
    console.log("Locker.useEffect[locker]:", locker);
  }, [locker]);
  //---------------------------------------------------------------------------------
  console.log("Locker.render");
  //---------------------------------------------------------------------------------
  if (locker && !locker.isValid)
    return (
      <div
        ref={ref}
        className={CombineStyles([styles.container, styles.error])}>
        {locker.getErrorPhrase()}
      </div>
    );
  else if (locker && props.inSession) return (
    <div
      className={styles.container}>
      Edit mode is enabled at <span>{locker.lockedAt.toLocaleString()}</span>, will expire at <span>{locker.expiresAt.toLocaleString()}</span>
    </div>
  );
  else return null;
}