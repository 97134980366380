import React, { useEffect, useRef } from "react";
import { ClassImageManager, useImageEditState } from "./IImageManager";
import { TImageData } from "../TImageData";
import ImageEditorPopupV2 from "../image-editor-popup/ImageEditorPopupV2";
import { ImageSelectorPopup } from "../image-selector-popup/ImageSelectorPopup";
import { IUiOption } from "../../options/IUiOption";
import ImageEditorOption from "../image-editor-option/ImageEditorOption";
import { ClassImageEditorContext } from "../image-editor-context/ImageEditorContextProvider";

interface IProps {
  image?: TImageData;
  inLibrary: boolean;
  onManagerCreate: (manager: ClassImageManager) => void;
  onImageUpdate: (image?: TImageData) => void;
  onImageEdit?: () => void;
  onImageSelect?: () => void;
}

export function ImageManager(props: IProps) {
  const [imageManager, setImageEditState, setDefaultImageEditState] = useImageEditState(props.inLibrary);
  const inputRef = useRef<HTMLInputElement>(null);
  //---------------------------------------------------------------------------
  let ui = ClassImageEditorContext.imageEditorUi;
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (imageManager && inputRef.current)
      imageManager.input = inputRef.current;
    props.onManagerCreate(imageManager);
  }, [imageManager, inputRef.current])
  //---------------------------------------------------------------------------
  const onImageEditDiscard = () => {
    imageManager.session?.removeFromContext();
    setDefaultImageEditState();
  };
  //---------------------------------------------------------------------------
  const closeImageEditorSave = () => {
    setDefaultImageEditState();
  };
  //---------------------------------------------------------------------------
  const onImageReady = async (image?: TImageData) => {
    if (image && imageManager.targetOperation == "SelectLocalImage") {
      image.imageManager = imageManager;
    }
    setDefaultImageEditState();
    props.onImageUpdate(image);
  }
  //---------------------------------------------------------------------------
  const onImageRemove = () => {
    if (props.image) {
      imageManager.removedImage = props.image;
      props.onImageUpdate(undefined);
    }
    else if (imageManager.removedImage) {
      props.onImageUpdate(imageManager.removedImage);
      imageManager.removedImage = undefined;
    }
  }
  //---------------------------------------------------------------------------
  const inputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("ImageManager.inputOnChange:", event.target.files);
    if (!event.target.files || event.target.files.length == 0) {
      return;// props.onCancel();  
    }
    let file = event.target.files[0];
    imageManager.addNewImage(file);
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (optionId: string) => {
    switch (optionId) {
      case "ImageEditor.OptionEdit":
        props.onImageEdit && props.onImageEdit();
        break;
      case "ImageEditor.OptionSelect":
        props.onImageSelect && props.onImageSelect();
        break;
      case "ImageEditor.OptionDelete":
        onImageRemove();
        break;
      case "ImageEditor.OptionUndelete":
        onImageRemove();
        break;
    };
  };
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  ui?.menuContent.options.forEach(option => {
    switch (option.id) {
      case "ImageEditor.OptionEdit":
        if (props.image && props.onImageEdit)
          updatedOptions.push({ ...option, iconId: "pencil" });
        break;
      case "ImageEditor.OptionSelect":
        if (props.onImageSelect)
          updatedOptions.push({ ...option, iconId: "userImagesLibrary" });
        break;
      case "ImageEditor.OptionDelete":
        if (props.image)
          updatedOptions.push({ ...option, iconId: "delete" });
        break;
      case "ImageEditor.OptionUndelete":
        if (props.image == undefined && imageManager.removedImage != undefined)
          updatedOptions.push({ ...option, iconId: "undo" });
        break;
    };
  });
  const optionElements = updatedOptions.map(option => (
    <ImageEditorOption
      key={option.id}
      ui={option}
      onSelect={onOptionSelect}
    />
  ));
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>

      {optionElements}

      {imageManager.editorOpen && imageManager.imageToEdit &&
        <ImageEditorPopupV2
          imageId={imageManager.imageToEdit?.id}
          //inLibrary={props.inLibrary}
          dialogButtons={props.inLibrary ? "SaveCancel" : "OkCancel"}
          onUrlAndBlobReady={onImageReady}
          onUnsavedChangesDiscard={onImageEditDiscard}
          onClose={closeImageEditorSave}
        />
      }

      {imageManager.libraryOpen &&
        <ImageSelectorPopup
          canSelectItem={true}
          itemIdToHide={imageManager.oldImage?.id}
          onSelectImage={onImageReady}
          onCancel={closeImageEditorSave}
        />
      }

      <input
        title="ImageFileInput"
        type='file'
        inputMode="none"
        accept="image/*"
        ref={inputRef}
        style={{ display: 'none' }}
        onChange={inputOnChange}
      />

    </React.Fragment>
  );

}

