import { IContentUnitImage } from '../../../../../common/content/content-image/IContentUnitImage';
import { ClassContentUnitStatus } from '../../../../../common/content/IContentUnit';
import ImageThumbnail from '../../../../../user-console/user-images/image-library/image-thumbnail/ImageThumbnail';
import ChecklistNodeAttribute from '../../../checklist/checklist-node-attribute/ChecklistNodeAttribute';
import ChecklistNodeWrapper from '../../../checklist/checklist-node-wrapper/ChecklistNodeWrapper';

interface IProps {
  data: IContentUnitImage;
  level?: number;
  isReadonly: boolean;
  onHover?: (hovered: boolean) => void;
  onReview?: (updatedImageUnit: IContentUnitImage) => void;
}

export default function ChecklistItemImage(props: IProps) {
  let imageIsReadonly = false;
  switch (props.data.imageData?.imageStatus) {
    case "Approved":
    case "Published":
      imageIsReadonly = true;
      break;
  };
  //---------------------------------------------------------------------------
  const onHover = () => {
    props.onHover && props.onHover(true);
  };
  //---------------------------------------------------------------------------
  const onHoverEnd = () => {
    props.onHover && props.onHover(false);
  };
  //---------------------------------------------------------------------------
  const onReview = (updatedStatus: ClassContentUnitStatus) => {
    const updatedUnit: IContentUnitImage = {
      ...props.data,
      status: updatedStatus
    };
    props.onReview?.(updatedUnit);
  };
  //---------------------------------------------------------------------------
  if (props.data.status) return (
    <ChecklistNodeWrapper
      data={props.data.status}
      isReadonly={imageIsReadonly || props.isReadonly}
      onHover={onHover}
      onHoverEnd={onHoverEnd}
      onReview={onReview}
    >

      <ChecklistNodeAttribute
        level={props.level}
        paddingRight
        customStyles={{ color: 'var(--clr_font_system)' }}
      >
        {props.data.type}
      </ChecklistNodeAttribute>

      <ChecklistNodeAttribute>
        <ImageThumbnail
          data={props.data.imageData}
          height='6em'
        />
      </ChecklistNodeAttribute>

    </ChecklistNodeWrapper>
  ); else return (
    <div>
      No status
    </div>
  );
}