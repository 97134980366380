import { Configuration } from "@azure/msal-browser";
import React, { Dispatch } from "react";
import { Actions } from "./AppAuthContextReducer";
import { TUser } from "./TUser";
import { ClassUserMessage } from "../../components/messages/Messages";

export interface IAuthConfig {
  clientId: string;
  msalConfig: Configuration;
  loginScopes: string[];
  apiScopes: string[];
  authorities: {
    signUpSignIn: string;
    forgotPassword?: string;
  };
  extraQueryParameters: {
    ui_locales: string,
    application_name: string,
    theme: string
  }
}

export interface IUserLocation {
  position: GeolocationPosition;
}
//-----------------------------------------------------------------------------
// Context Interface
export interface IAppAuthContext {
  user?: TUser;
  userLocation?: IUserLocation;
  config?: IAuthConfig;
  isInProcess: boolean;
  isHealthy: boolean;
  unreadMessageCount?: number;
  messages?: ClassUserMessage[];
}

interface IAppAuthContextStore {
  appAuthContext: IAppAuthContext;
  appAuthContextDispatch: Dispatch<Actions>;
}

//-----------------------------------------------------------------------------
// Context
export const AppAuthContextStore = React.createContext<IAppAuthContextStore>({} as IAppAuthContextStore);