import { useContext } from 'react';
import { BusinessContext } from '../../../context/business-context/BusinessContextProvider';
import { IUiSectionBusinessPage } from '../../business-space/adpage-editor/section-business-page/IUiSectionBusinessPage';
import { EditFormSection } from '../../common/console-layout/edit-form-sections/EditFormSection';
import { AppUiContextStore } from '../../../context/app-ui-context/AppUiContextProvider';
import GetHint from '../../common/functions/GetHint';
import AdModulesEditor from '../ad-modules-editor/AdModulesEditor';
import { ClassAdModule } from '../../common/ad-modules/IAdModule';
import AdStatus from '../ad-status/AdStatus';
import styles from './SectionAllModules.module.css';

interface IProps {
  ui: IUiSectionBusinessPage;
  isReadonly: boolean;
}

export default function SectionAllModules(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const { businessContext } = useContext(BusinessContext);
  //---------------------------------------------------------------------------
  const adLanguageSet = businessContext.adLanguageSetState?.adLanguageSet;
  if (!adLanguageSet) return null;
  const adModules: ClassAdModule[] = adLanguageSet.adModules ? adLanguageSet.adModules : [];
  //---------------------------------------------------------------------------
  return (
    <EditFormSection
      id={props.ui.id}
      caption={GetHint(appUiContext, props.ui.id, props.ui.hint)}
    >
      <div className={styles.container}>
        <AdStatus data={adLanguageSet?.status} />
        <AdModulesEditor
          ui={props.ui.adModule}
          locale={adLanguageSet.localeId}
          adModules={adModules}
          isReadonly={props.isReadonly}
          useExternalLayout={false}
        />
      </div>
    </EditFormSection>
  );
}