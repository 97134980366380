import React, { useEffect, useState } from 'react';
import FormOptions from '../../form-options-bar/FormOptions';
import InputText, { IUiInputText } from '../../input-v2/input-text/InputText';
import { getTUiMenuContent } from '../../menu/menu-content/TUiMenuContent';
import { IUiTextEditor } from '../IUiTextEditor';
import styles from './TextEditor.module.css';
import { IUiOption } from '../../options/IUiOption';
import { EditFormState } from '../../edit-form/IEditFormState';

interface IProps {
  ui: IUiTextEditor;
  data?: string;
  isReadonly?: boolean;
  onUpdate: (updatedText: string) => void; // Type in case of formatted text???
  onClose: () => void;
}

export default function TextEditor(props: IProps) {
  const [state, setState] = useState<EditFormState<string>>();
  //---------------------------------------------------------------------------
  useEffect(() => {
    const state = new EditFormState<string>({
      object: props.data ? props.data : "",
      isNew: props.data ? false : true,
      isValid: false
    });
    setState(state);
  }, [props.data]);
  //---------------------------------------------------------------------------
  const onUpdate = (updatedData: string, isValid: boolean) => {
    if (state) {
      const updatedState = new EditFormState<string>(state, {
        object: updatedData,
        isUpdated: true,
        isValid: isValid
      });
      setState(updatedState);
    } else {
      console.error("Text is being updated but state is not set");
    };
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (selectedOptionId: string) => {
    switch (selectedOptionId) {
      case "TextEditor.MenuContent.OptionOk":
        props.onUpdate(state?.object as string);
        break;
      case "TextEditor.MenuContent.OptionCancel":
        props.onClose();
        break;
    };
  };
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  props.ui.menuContent.options.forEach(option => {
    switch (option.id) {
      case "TextEditor.MenuContent.OptionOk":
        if (!props.isReadonly)
          updatedOptions.push({
            ...option,
            isDisabled: !state?.isUpdated || !state?.isValid,
            isDefault: state?.object ? true : false
          });
        break;
      case "TextEditor.MenuContent.OptionCancel":
        updatedOptions.push(option);
        break;
    };
  });
  const menuContent = getTUiMenuContent({
    ...props.ui.menuContent,
    options: updatedOptions
  });
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>

      <div className={styles.container}>
        <InputText
          ui={{
            ...props.ui.inputText as IUiInputText,
            hideCaption: true,
            hideHint: true,
            disabled: props.isReadonly
          }}
          data={state?.object}
          linesCount={5}
          isFocused={true}
          onUpdate={onUpdate}
        />
      </div>

      <FormOptions
        ui={menuContent}
        applyMobileStyle={false}
        onSelect={onOptionSelect}
      />

    </React.Fragment>
  );
}