import { useEffect, useState } from "react";
import InlineFormWrapper from "../../../../common/forms/inline-form-wrapper/InlineFormWrapper";
import InputText from "../../../../common/input-v2/input-text/InputText";
import { ITranslation } from "../../../common/directory-translator/translation-editor/ITranslation";
import TranslationEditor from "../../../common/directory-translator/translation-editor/TranslationEditor";
import { IUiContentItemEditform } from "./IUiContentItemEditform";
import { IUiOption } from "../../../../common/options/IUiOption";
import { ClassContentDirectoryDraftItem, IContentDirectoryTranslationItem } from "../content-directory-translator/IContentDirectoryDraftItem";
import { EditFormStateV2 } from "../../../../common/edit-form/IEditFormStateV2";

interface IProps {
  ui: IUiContentItemEditform;
  data: ClassContentDirectoryDraftItem;
  draftLocale: string;
  sourceLocale?: string;
  isReadonly: boolean;
  onUpdate: (updatedContentItem: ClassContentDirectoryDraftItem) => void;
  onClose: () => void;
}

export default function ContentItemEditform(props: IProps) {
  const [state, setState] = useState<EditFormStateV2<ClassContentDirectoryDraftItem>>();
  const isReadonly = !!props.isReadonly;
  //--------------------------------------------------------------------------- State
  useEffect(() => {
    const newState = new EditFormStateV2<ClassContentDirectoryDraftItem>({
      object: props.data
    });
    setState(newState);
  }, [props.data]);
  //---------------------------------------------------------------------------
  const getSourceValue = (id: string, textProperty: any) => {
    let result = undefined;
    if (props.sourceLocale && props.sourceLocale != "i18n" && !!textProperty) {
      result = textProperty[props.sourceLocale];
    } else {
      result = id;
    };
    return result;
  };
  //---------------------------------------------------------------------------
  const setUpdatedState = (updatedObject: ClassContentDirectoryDraftItem) => {
    if (!state?.object) return;
    const updatedState = new EditFormStateV2<ClassContentDirectoryDraftItem>(state, {
      object: updatedObject
    });
    setState(updatedState);
  };
  //---------------------------------------------------------------------------
  const onTextUpdate = (updatedTranslation: ITranslation) => {
    if (!state?.object) return;
    const updatedItem = new ClassContentDirectoryDraftItem({
      ...state.object,
      text: updatedTranslation
    });
    const original: IContentDirectoryTranslationItem = state.object.itemTranslation ? state.object.itemTranslation : {
      id: state.object.id,
      text: {
        value: undefined,
        fallbackAccepted: false
      }
    };
    if (original.text.value !== updatedItem.text.value || original.text.fallbackAccepted != updatedItem.text.fallbackAccepted)
      updatedItem.isTranslationEdited = true;
    else
      updatedItem.isTranslationEdited = false;
    setUpdatedState(updatedItem);
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (optionId: string) => {
    switch (optionId) {
      case "OptionClose":
      case "OptionCancel":
        props.onClose();
        break;
      //-----------------------------------------------------------------------
      case "OptionOk":
        if (!state?.object) return;
        if (!state?.isValid || !state?.isUpdated) return;
        props.onUpdate(state.object);
        break;
    };
  };
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  props.ui.menuContent.options.forEach(option => {
    switch (option.id) {
      case "OptionClose":
        if (isReadonly) {
          updatedOptions.push(option);
        };
        break;
      case "OptionCancel":
        if (!isReadonly) {
          updatedOptions.push(option);
        };
        break;
      case "OptionOk":
        if (!isReadonly) {
          updatedOptions.push({ ...option, isDisabled: !state?.isValid || !state?.isUpdated });
        };
        break;
    };
  });
  //---------------------------------------------------------------------------
  return (
    <InlineFormWrapper
      ui={{ ...props.ui.menuContent, options: updatedOptions }}
      onSelect={onOptionSelect}
      isUpdated={state?.isUpdated ? true : false}
      onClose={props.onClose}
    >
      <InputText
        ui={{ ...props.ui.inputId, disabled: true, hideHint: true }}
        data={props.data?.id ? props.data.id : ""}
      />
      <TranslationEditor
        ui={props.ui.textTranslationEditor}
        data={{
          elementId: props.data.id,
          elementPart: "Text",
          draftLocale: props.draftLocale,
          draftValue: state?.object?.text?.value, //props.data?.caption?.value,
          draftFallbackAccepted: !!state?.object?.text?.fallbackAccepted,
          sourceLocale: props.sourceLocale,
          sourceValue: getSourceValue(props.data.id, props.data.itemTranslation?.text),
          //publishedTranslations: props.data.itemPublished?.text
        }}
        parameters={props.data.itemOriginal?.parameters}
        editModeIsEnabled={!isReadonly}
        onUpdate={onTextUpdate}
      />
    </InlineFormWrapper>
  );
}