import React, { ReactNode, useEffect, useState } from "react";
import { DocumentDraftItem } from "../../../../administrator-console/documents/document-editor/IDocumentDraftItem";
import ChecklistNodeWrapper from "../../../checklist/checklist-node-wrapper/ChecklistNodeWrapper";
import ChecklistNodeAttribute from "../../../checklist/checklist-node-attribute/ChecklistNodeAttribute";
import ChecklistItemText from "./ChecklistItemText";
import { IHoveredItem } from "../../request-form/DocumentRequestForm";
import { IContentUnitText } from "../../../../../common/content/content-text/IContentUnitText";
import ChecklistItemImage from "./ChecklistItemImage";
import { IContentUnitImage } from "../../../../../common/content/content-image/IContentUnitImage";

interface IProps {
  level: number;
  prefix?: string;
  data: DocumentDraftItem;
  isReadonly: boolean;
  isExpanded: boolean;
  onHover: (item?: IHoveredItem) => void;
  onReview?: (updatedItem: DocumentDraftItem) => void;
}

export default function ChecklistItemParagraph(props: IProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  //---------------------------------------------------------------------------
  useEffect(() => {
    setIsExpanded(props.isExpanded);
  }, [props.isExpanded]);
  //---------------------------------------------------------------------------
  const onHover = () => {
    props.onHover({
      path: props.data.fullPath,
      status: props.data.status
    });
  };
  //---------------------------------------------------------------------------
  const onHoverEnd = () => {
    props.onHover();
  };
  //---------------------------------------------------------------------------
  const onTextHover = (hovered: boolean) => {
    if (hovered)
      props.onHover({
        path: `${props.data.fullPath}.text`,
        status: props.data.text?.status
      });
    else
      props.onHover();
  };
  //---------------------------------------------------------------------------
  const onImageHover = (hovered: boolean) => {
    if (hovered)
      props.onHover({
        path: `${props.data.fullPath}.image`,
        status: props.data.image?.status
      });
    else
      props.onHover();
  };
  //---------------------------------------------------------------------------
  const onTextReview = (updatedTextUnit: IContentUnitText) => {
    const updatedItem = new DocumentDraftItem({
      ...props.data,
      text: updatedTextUnit
    });
    updatedItem.updateStatus();
    props.onReview?.(updatedItem);
  };
  //---------------------------------------------------------------------------
  const onImageReview = (updatedImageUnit: IContentUnitImage) => {
    const updatedItem = new DocumentDraftItem({
      ...props.data,
      image: updatedImageUnit
    });
    updatedItem.updateStatus();
    props.onReview?.(updatedItem);
  };
  //---------------------------------------------------------------------------
  const propSubItems: ReactNode[] = [];
  if (props.data.text) {
    const textNode =
      <ChecklistItemText
        key={`${props.data.id}_text`}
        level={props.level + 1}
        data={props.data.text}
        isReadonly={props.isReadonly}
        onHover={onTextHover}
        onReview={onTextReview}
      />;
    propSubItems.push(textNode);
  };
  if (props.data.image) {
    const imageNode =
      <ChecklistItemImage
        key={`${props.data.id}_image`}
        level={props.level + 1}
        data={props.data.image}
        isReadonly={props.isReadonly}
        onHover={onImageHover}
        onReview={onImageReview}
      />;
    propSubItems.push(imageNode);
  };
  //---------------------------------------------------------------------------
  if (props.data.status) return (
    <React.Fragment>
      <ChecklistNodeWrapper
        data={props.data.status}
        isReadonly={true}
        onHover={onHover}
        onHoverEnd={onHoverEnd}
      >

        <ChecklistNodeAttribute
          level={props.level}
          customStyles={{ gridColumn: '1 / span 2' }}
          isExpanded={isExpanded}
          onToggle={() => setIsExpanded(!isExpanded)}
        >
          {props.prefix ? `${props.prefix}. ` : ""}
          {props.data.isMarked ? `·  ` : ""}
          {props.data.type}
        </ChecklistNodeAttribute>

      </ChecklistNodeWrapper>

      {isExpanded && propSubItems}

    </React.Fragment>
  ); else return (
    <div>
      No status
    </div>
  );
}