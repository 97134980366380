import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { TImageData } from "../../components/common/image-editor/TImageData";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

interface ISysLocales {
  i18nLocale: string;
  nativeLocale: string;
  pseudoLocale: string;
  pseudoRtlLocale: string;
}

interface IMapSettings {
  key: string;
  libraries: [];
}

export class TSysSettings {
  applicationName!: string;
  environment!: string;
  isProduction!: boolean;
  applicationAlias!: string;
  pageHeaderFormatString!: string;
  sysLanguagePrefix!: string;
  sysLanguageSuffix!: string;
  sysLocales!: ISysLocales;
  sysLocalesList!: string[];
  storageEndpoint!: string;
  testUtilsUrl!: string;
  mapSettings!: IMapSettings;
  version!: string;
  //---------------------------------------------------------------------
  private _appInsights?: ApplicationInsights;
  private _reactPlugin?: ReactPlugin;
  get reactAppInsightsPlugin() {
    return this._reactPlugin;
  }
  //---------------------------------------------------------------------
  /*
  private _appInsightsConnectionString: string = "";
  get appInsightsConnectionString(): string {
    return this._appInsightsConnectionString;
  }
  set appInsightsConnectionString(value: string) {
    //console.log("TSysSettings.setAppInsightsConnectionString:", value);
    if (this._appInsightsConnectionString != value) {
      this._appInsightsConnectionString = value;
      if (value) {
        this._reactPlugin = new ReactPlugin();
        this._appInsights = new ApplicationInsights({
          config: {
            connectionString: value,
            enableAutoRouteTracking: false,
            disableAjaxTracking: true,
            disableFetchTracking: true,
            //disableTelemetry: false,
            extensions: [this._reactPlugin]
          }
        });
        this._appInsights.loadAppInsights();
      }
    }
  }
  */
  //---------------------------------------------------------------------
  private _trackerAppInsightsConnectionString: string = "";
  get trackerAppInsightsConnectionString(): string {
    return this._trackerAppInsightsConnectionString;
  }
  set trackerAppInsightsConnectionString(value: string) {
    //console.log("TSysSettings.setAppInsightsConnectionString:", value);
    if (this._trackerAppInsightsConnectionString == value)
      return;
    this._trackerAppInsightsConnectionString = value;
    if (!value) {
      this._reactPlugin = undefined;
      this._appInsights = undefined;
      return;
    }
    this._reactPlugin = new ReactPlugin();
    this._appInsights = new ApplicationInsights({
      config: {
        connectionString: value,
        enableAutoRouteTracking: false,
        disableAjaxTracking: true,
        disableFetchTracking: true,
        //disableTelemetry: false,
        extensions: [this._reactPlugin]
      }
    });
    this._appInsights.loadAppInsights();
  }

  //---------------------------------------------------------------------
  private _defaultBusinessLogo?: TImageData;
  get defaultBusinessLogo(): TImageData | undefined {
    return this._defaultBusinessLogo;
  }
  set defaultBusinessLogo(value: any) {
    if (value)
      this._defaultBusinessLogo = new TImageData(value);
    else
      this._defaultBusinessLogo = undefined;
  }
  //---------------------------------------------------------------------
  constructor(source?: any, spread?: any) {
    if (source || spread) {
      Object.assign(this, source, spread);
    }
  }
}