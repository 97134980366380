import { useContext, useEffect, useRef, useState } from "react";
import { AppScreenContext, ScreenType } from "../../../../context/app-screen-context/AppScreenProvider";
import useNavigateWithContext from "../../../../hooks/useNavigateWithContext";
//import BusinessLogo from "../../../business-console/business-list/business-logo/BusinessLogo";
import styles from "./BusinessSearchCard.module.css";
import { BusinessSearchListItem } from "./BusinessSearchListItem";
import { PercentDonut } from "./PercentDonut";
import { TagChain } from "./TagChain";
import { TImageData } from "../../../common/image-editor/TImageData";
import { AppUiContextStore } from "../../../../context/app-ui-context/AppUiContextProvider";
import { GetTranslationForSearch } from "../../../common/functions/GetTranslation";
import { LanguageBarText } from "../../language-bar-text/LanguageBarText";
import { useTracker } from "../../../../utils/tracker";
import BusinessLogo from "../../../business-space/business-logo/BusinessLogo";
//-----------------------------------------------------------------------------
interface IProps {
  data: BusinessSearchListItem;
  defaultBusinessLogo?: TImageData;
  percents: boolean;
  onHover?: (id: string) => void;
  onSelect?: (id: string) => void;
}
//-----------------------------------------------------------------------------
export function BusinessSearchCard(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const screenType = useContext(AppScreenContext);
  const tracker = useTracker();
  const navigate = useNavigateWithContext();
  const [distance, setDistance] = useState<string>();
  const [tagExpanded, setTagExpanded] = useState(false);
  const [selectedLocale, setSelectedLocale] = useState<string>();
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (!selectedLocale || !props.data)
      return;
    props.data.selectedLocale = selectedLocale;
    //console.log("BusinessSearchCard.useEffect[selectedLocale]:", selectedLocale);
    tracker?.track("SearchResults", props.data);
  }, [props.data, selectedLocale])
  //-[props.data.distance]-----------------------------------------------------
  useEffect(() => {
    let d = props.data.minDistance;
    if (d == undefined)
      return;
    let unit: string;
    let digits: number = 0;
    let s: string;
    if (d < 1000) {
      unit = 'meter';
    }
    else {
      unit = 'kilometer';
      if (d < 10000)
        digits = 1;
      d /= 1000;
    }
    s = d.toLocaleString(
      undefined, {
      style: 'unit',
      unit: unit,
      useGrouping: false,
      minimumFractionDigits: digits,
      maximumFractionDigits: digits
    });
    setDistance(s);
  }, [props.data.distance]);
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (!props.data) {
      return;
    }
    let locale: string | undefined = undefined;
    try {
      for (let pref of appUiContext.preferredLanguages) {
        if (props.data.localesFiltered.indexOf(pref) >= 0) {
          locale = pref;
          return;
        }
      }
      if (props.data.localesFiltered.indexOf(appUiContext.locale.localeId) >= 0) {
        locale = appUiContext.locale.localeId;
      }
      else if (props.data.localesFiltered.length > 0) {
        locale = props.data.localesFiltered[0];
      }
    }
    finally {
      if (locale) {
        //console.log("setSelectedLocale:", locale, props.data.localesFiltered, appUiContext.preferredLanguages, appUiContext.locale.localeId);
        setSelectedLocale(locale);
      }
    }
  }, [props.data.localesFiltered, appUiContext.preferredLanguages, appUiContext.locale.localeId]);
  //---------------------------------------------------------------------------
  const onSelect = () => {
    //return navigate(`${props.data.businessId}`);
    if (screenType == ScreenType.Mobile) {
      // This will open business page in the same tab
      navigate(`${props.data.businessId}`);
    } else {
      //-------------------------------------------------------------------------
      // Open business page in a new browser tab
      window.open(`${props.data.businessId}`, '_blank', 'noopener,noreferrer');
    };
  };
  //---------------------------------------------------------------------------
  const onHover = () => {
    if (screenType == ScreenType.Desktop && props.onHover) {
      props.onHover(props.data.businessId)
    };
  }
  //---------------------------------------------------------------------------
  const onToggle = () => {
    setTagExpanded(!tagExpanded);
  }
  //---------------------------------------------------------------------------
  const onSelectLocale = (locale: string) => {
    console.log("BusinessSearchCard.onSelectLocale:", locale);
    setSelectedLocale(locale);
  }
  //---------------------------------------------------------------------------
  let found = props.data.getTags(appUiContext, "ExactMatch");
  let notFound = props.data.getTags(appUiContext, "NotFound");
  let levelMatch = props.data.getTags(appUiContext, "LevelMatch");
  let categoryMatch = props.data.getTags(appUiContext, "CategoryMatch");
  let abstractCategoryMatch = props.data.getTags(appUiContext, "AbstractCategoryMatch");
  let notSearchedButExists = props.data.getTags(appUiContext, "NotSearchedButExists");
  //---------------------------------------------------------------------------
  if (props.data.businessId == "0e5bd748-e0d4-424a-b87f-83ada537adca") {
    // console.log("BusinessSearchCard.render.selectedLocale:", selectedLocale);
    // console.log("BusinessSearchCard.render.data:", props.data);
    // let desc = GetTranslationLocale(
    //   appUiContext,
    //   props.data.description,
    //   props.data.businessId,
    //   undefined,
    //   selectedLocale,
    // );
    // console.log("BusinessSearchCard.render.desc:", desc);
  }
  //---------------------------------------------------------------------------
  //console.log("BusinessSearchCard.render:", props.data.businessName);
  //---------------------------------------------------------------------------
  if (!selectedLocale)
    return null;
  return (
    <div className={styles.columnContainer}>
      <div
        className={styles.rowContainer}
        onMouseOver={onHover}
      >
        <div
          className={styles.logoContainer}
          onClick={onSelect}
        >
          <BusinessLogo data={props.data.logo} useDefaultLogo={true} />
        </div>
        <div className={styles.details}>
          <div className={styles.name}>
            {props.data.text
            /*GetTranslationForSearch(
              props.data.businessName,
              selectedLocale,
              appUiContext
            )*/}
          </div>
          <div className={styles.description}>
            {GetTranslationForSearch(
              props.data.description,
              selectedLocale,
              appUiContext
            )}
          </div>
          <div className={styles.description}>
            {props.data.businessId}
          </div>
          <LanguageBarText
            parentKey={props.data.businessId}
            locales={props.data.localesFiltered}
            selectedLocale={selectedLocale}
            onSelectLocale={onSelectLocale}
          />
        </div>
        <div className={styles.tagAndDistance}>
          {/*<FoundTags data={props.data} />*/}
          {distance != undefined &&
            <div className={styles.distanceContainer}>
              {distance}
            </div>
          }
          <PercentDonut data={props.data} percents={props.percents} onToggle={onToggle} />
        </div>
      </div>
      {tagExpanded && found.length > 0 && <TagChain data={props.data} searchResult="ExactMatch" />}
      {tagExpanded && notFound.length > 0 && <TagChain data={props.data} searchResult="NotFound" />}
      {tagExpanded && levelMatch.length > 0 && <TagChain data={props.data} searchResult="LevelMatch" />}
      {tagExpanded && categoryMatch.length > 0 && <TagChain data={props.data} searchResult="CategoryMatch" />}
      {tagExpanded && abstractCategoryMatch.length > 0 && <TagChain data={props.data} searchResult="AbstractCategoryMatch" />}
      {tagExpanded && notSearchedButExists.length > 0 && <TagChain data={props.data} searchResult="NotSearchedButExists" />}
    </div>
  );
}
