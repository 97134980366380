import { IContentUnitText } from '../../../../common/content/content-text/IContentUnitText';
import { ClassContentUnitStatus } from '../../../../common/content/IContentUnit';
import ChecklistItemWrapper from '../checklist-item-wrapper/ChecklistItemWrapper';

interface IProps {
  data: IContentUnitText;
  level?: number;
  isReadonly: boolean;
  onHover?: (isHovered: boolean) => void;
  onReview?: (updatedTextUnit: IContentUnitText) => void;
}

export default function ChecklistItemText(props: IProps) {
  if (!props.data.status) return null;
  //---------------------------------------------------------------------------
  const onHover = (isHovered: boolean) => {
    props.onHover && props.onHover(isHovered);
  };
  //---------------------------------------------------------------------------
  const onReview = (updatedStatus: ClassContentUnitStatus) => {
    console.log("ChecklistItemText.onReview");
    if (props.isReadonly) return;
    const updatedUnit: IContentUnitText = {
      ...props.data,
      status: updatedStatus
    };
    props.onReview?.(updatedUnit);
  };
  //---------------------------------------------------------------------------
  return (
    <ChecklistItemWrapper
      unitType='ContentText'
      status={props.data.status}
      isReadonly={props.isReadonly}
      onHover={onHover}
      caption={props.data.type}
      level={props.level}
      onReview={onReview}
    >
      {props.data.text}
    </ChecklistItemWrapper>
  );
}