import { useEffect, useRef, useState } from 'react';
import FormOptions from '../../../../common/form-options-bar/FormOptions';
import InputText from '../../../../common/input-v2/input-text/InputText';
import InputsGroupWrapper from '../../../../common/input-v2/inputs-group-wrapper/InputsGroupWrapper';
import { getTUiMenuContent } from '../../../../common/menu/menu-content/TUiMenuContent';
import { IUiOption } from '../../../../common/options/IUiOption';
import styles from './EditformDocumentDetails.module.css';
import { IUiEditformDocumentDetails } from './IUiEditformDocumentDetails';
import { IDocumentDetails } from './IDocumentDetails';
import { EditFormState } from '../../../../common/edit-form/IEditFormState';
import { DocumentDetailsValidator, IDocumentDetailsValidationState } from './DocumentDetailsValidator';
import InputDropdown from '../../../../common/input-v2/input-dropdown/InputDropdown';
import { EDocumentType } from '../document-editor/IDocumentDraft';

interface IProps {
  ui: IUiEditformDocumentDetails;
  data?: IDocumentDetails;
  isNew?: boolean;
  existingDocuments?: IDocumentDetails[];
  onClose: () => void;
  onCreate?: (document: IDocumentDetails) => void;
}

export default function EditformDocumentDetails(props: IProps) {
  const [editorState, setEditorState] = useState<EditFormState<IDocumentDetails>>();
  const formValidator = useRef<DocumentDetailsValidator>();
  const [validationState, setValidationState] = useState<IDocumentDetailsValidationState>();
  const idIsEditable = !props.data || props.isNew;
  //--------------------------------------------------------------------------- Initialize
  useEffect(() => {
    formValidator.current = new DocumentDetailsValidator(props.ui);
    //-------------------------------------------------------------------------
    const validationState = formValidator.current.initialize(props.data);
    setValidationState(validationState);
    //-------------------------------------------------------------------------
    const state = new EditFormState<IDocumentDetails>({
      object: props.data ? props.data : {
        documentType: "Legal",
        locale: "en",
        documentId: ""
      },
      isNew: props.isNew ? true : false,
      isValid: false
    });
    setEditorState(state);
  }, [props.data]);
  //---------------------------------------------------------------------------
  const onTypeUpdate = (optionId?: string) => {
    if (!idIsEditable) return;
    if (!editorState) return;
    if (!formValidator.current) return;
    if (!validationState) return;
    let updatedDetails: IDocumentDetails = {
      ...editorState.object as IDocumentDetails,
      documentType: optionId as EDocumentType
    };
    //-------------------------------------------------------------------------
    const updatedState = new EditFormState<IDocumentDetails>(editorState, {
      object: updatedDetails,
      isValid: validationState.isValid
    });
    setEditorState(updatedState);
  };
  //---------------------------------------------------------------------------
  const onIdUpdate = (value: string) => {
    if (!idIsEditable) return;
    if (!editorState) return;
    if (!formValidator.current) return;
    if (!validationState) return;
    let updatedDetails: IDocumentDetails = {
      ...editorState.object as IDocumentDetails,
      documentId: value.replace(' ', '')
    };
    //-------------------------------------------------------------------------
    let updatedValidationState = formValidator.current.validateId(
      editorState.initialState?.documentId ? editorState.initialState.documentId : "",
      value,
      validationState,
      props.existingDocuments ? props.existingDocuments : []);
    setValidationState(updatedValidationState);
    //-------------------------------------------------------------------------
    const updatedState = new EditFormState<IDocumentDetails>(editorState, {
      object: updatedDetails,
      isValid: updatedValidationState.isValid
    });
    setEditorState(updatedState);
  };
  //---------------------------------------------------------------------------
  const onTitleUpdate = (value: string) => {
    if (!editorState) return;
    if (!formValidator.current) return;
    if (!validationState) return;
    let updatedDetails: IDocumentDetails = { ...editorState.object as IDocumentDetails, title: value };
    //-------------------------------------------------------------------------
    let updatedValidationState = formValidator.current.validateTitle(
      editorState.initialState?.documentId ? editorState.initialState.documentId : "",
      value,
      validationState,
      props.existingDocuments ? props.existingDocuments : []);
    setValidationState(updatedValidationState);
    //-------------------------------------------------------------------------
    const updatedState = new EditFormState<IDocumentDetails>(editorState, {
      object: updatedDetails,
      isValid: updatedValidationState.isValid
    });
    setEditorState(updatedState);
  };
  //---------------------------------------------------------------------------
  const onDescriptionUpdate = (value: string) => {
    if (!editorState) return;
    if (!formValidator.current) return;
    if (!validationState) return;
    let updatedDetails: IDocumentDetails = { ...editorState.object as IDocumentDetails, description: value };
    //-------------------------------------------------------------------------
    let updatedValidationState = formValidator.current.validateDescription(
      editorState.initialState?.documentId ? editorState.initialState.documentId : "",
      value,
      validationState,
      props.existingDocuments ? props.existingDocuments : []);
    setValidationState(updatedValidationState);
    //-------------------------------------------------------------------------
    const updatedState = new EditFormState<IDocumentDetails>(editorState, {
      object: updatedDetails,
      isValid: updatedValidationState.isValid
    });
    setEditorState(updatedState);
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (optionId: string) => {
    switch (optionId) {
      case "OptionCreate":
        if (props.onCreate && editorState?.object)
          props.onCreate(editorState.object);
        break;
      case "OptionCancel":
        props.onClose();
        break;
    };
  };
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  props.ui.menuContent.options.forEach(option => {
    switch (option.id) {
      case "OptionCreate":
        if (!props.data) {
          updatedOptions.push({
            ...option,
            iconId: "plus",
            isDisabled: !editorState?.isValid,
            isDefault: editorState?.isValid
          });
        };
        break;
      case "OptionCancel":
        updatedOptions.push({ ...option, iconId: "close" });
        break;
    };
  });
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>

      <InputsGroupWrapper addPadding>
        <InputDropdown
          ui={props.ui.inputType}
          data={editorState?.object?.documentType}
          options={props.ui.inputType.options}
          onUpdate={onTypeUpdate}
        />
        <InputText
          ui={{
            ...props.ui.inputId,
            disabled: !idIsEditable
          }}
          isFocused={true}
          data={editorState?.object?.documentId}
          validationState={validationState?.id}
          onUpdate={onIdUpdate}
        />
        <InputText
          ui={{
            ...props.ui.inputTitle,
            disabled: !editorState?.object?.documentId || !validationState?.id.isValid
          }}
          data={editorState?.object?.title}
          validationState={validationState?.title}
          onUpdate={onTitleUpdate}
        />
        <InputText
          ui={{
            ...props.ui.inputDescription,
            disabled: !editorState?.object?.documentId || !validationState?.id.isValid
          }}
          data={editorState?.object?.description}
          validationState={validationState?.description}
          onUpdate={onDescriptionUpdate}
        />
      </InputsGroupWrapper>

      <FormOptions
        ui={getTUiMenuContent({ ...props.ui.menuContent, options: updatedOptions })}
        onSelect={onOptionSelect}
      />
    </div>
  );
}