import React from "react";
import styles from './DictionaryItemIcon.module.css';
import CombineStyles from "../../../../../../utils/combineStyles";

interface IProps {
  type: "required" | "exclusive" | "manual" | "editors" | "viewers" | "language";
  enabled: boolean;
  hint?: string;
}

export default function DictionaryItemIcon(props: IProps) {
  const opacity = 1;
  //---------------------------------------------------------------------------
  let symbol: JSX.Element;
  switch (props.type) {
    case "required":
      symbol =
        <g
          stroke="currentColor"
          strokeWidth={12}
          strokeLinejoin="round"
          strokeLinecap="round"
          fill="none">
          <line
            // exclamation mark
            x1="50"
            y1="25"
            x2="50"
            y2="55"
          />
          <line
            x1="50"
            y1="75"
            x2="50"
            y2="75"
          />
        </g>;
      break;
    case "exclusive":
      symbol =
        <polyline
          stroke="currentColor"
          strokeWidth={12}
          strokeLinejoin="round"
          strokeLinecap="round"
          fill="none"
          points="35,40 53,25 53,75"
        />;
      break;
    case "manual":
      symbol =
        <svg x='15' y='15' width="70" height="70" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M13 24c-3.26 0-6.19-1.99-7.4-5.02l-3.03-7.61a1 1 0 0 1 1.24-1.32l.79.26c.56.18 1.02.61 1.24 1.16L7.25 15H8V3.25a1.25 1.25 0 0 1 2.5 0V12h1V1.25a1.25 1.25 0 0 1 2.5 0V12h1V2.75a1.25 1.25 0 0 1 2.5 0V12h1V5.75a1.25 1.25 0 0 1 2.5 0V16c0 4.42-3.58 8-8 8" />
        </svg>
      break;
    case "editors":
      symbol =
        <svg x='15' y='15' width="70" height="70" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M8.707 19.707L18 10.414L13.586 6l-9.293 9.293a1 1 0 0 0-.263.464L3 21l5.242-1.03c.176-.044.337-.135.465-.263M21 7.414a2 2 0 0 0 0-2.828L19.414 3a2 2 0 0 0-2.828 0L15 4.586L19.414 9z" />
        </svg>
      break;
    case "viewers":
      symbol =
        <svg x='15' y='15' width="70" height="70" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5M12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5s5 2.24 5 5s-2.24 5-5 5m0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3s3-1.34 3-3s-1.34-3-3-3" />
        </svg>
      break;
    case "language":
      symbol =
        <svg x='15' y='15' width="70" height="70" viewBox="0 0 512 512">
          <path
            fill="currentColor"
            d="m478.33 433.6l-90-218a22 22 0 0 0-40.67 0l-90 218a22 22 0 1 0 40.67 16.79L316.66 406h102.67l18.33 44.39A22 22 0 0 0 458 464a22 22 0 0 0 20.32-30.4ZM334.83 362L368 281.65L401.17 362Zm-66.99-19.08a22 22 0 0 0-4.89-30.7c-.2-.15-15-11.13-36.49-34.73c39.65-53.68 62.11-114.75 71.27-143.49H330a22 22 0 0 0 0-44H214V70a22 22 0 0 0-44 0v20H54a22 22 0 0 0 0 44h197.25c-9.52 26.95-27.05 69.5-53.79 108.36c-31.41-41.68-43.08-68.65-43.17-68.87a22 22 0 0 0-40.58 17c.58 1.38 14.55 34.23 52.86 83.93c.92 1.19 1.83 2.35 2.74 3.51c-39.24 44.35-77.74 71.86-93.85 80.74a22 22 0 1 0 21.07 38.63c2.16-1.18 48.6-26.89 101.63-85.59c22.52 24.08 38 35.44 38.93 36.1a22 22 0 0 0 30.75-4.9Z" />
        </svg>
      break;
    default:
      symbol = <></>;
      break;
  }
  //---------------------------------------------------------------------------
  return (
    <div
      title={props.hint}
      className={CombineStyles([
        styles.container,
        props.enabled ? styles.enabled : ''
      ])}
    >
      <svg xmlns="http://www.w3.org/2000/svg"
        width="1em" height="1em"
        viewBox="0 0 100 100"
        fill="none"
        strokeOpacity={opacity}
      >

        <rect
          stroke="currentColor"
          strokeWidth="7"
          x='5' y='5'
          width="90" height="90"
          rx='5'
        />

        {symbol}

      </svg>
    </div>
  );
}

export function TestSvg() {
  return (
    <div style={{
      //color: props.enabled ? 'inherit' : 'var(--clr_font_system)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 100 100" fill="none">

        {/* <circle cx="50" cy="50" r="50" fill='yellow' />

      <svg x="20" y="20" width="100" height="50" viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="50" fill='red' />
      </svg> */}

        {/* <rect
        stroke="red"
        width="100" height="100"
      /> */}

        <rect
          stroke="currentColor"
          strokeWidth="5"
          x='5' y='5'
          width="90" height="90"
          rx='5'
        />

        {/* <svg x='15' y='15' width="70" height="70" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M13 24c-3.26 0-6.19-1.99-7.4-5.02l-3.03-7.61a1 1 0 0 1 1.24-1.32l.79.26c.56.18 1.02.61 1.24 1.16L7.25 15H8V3.25a1.25 1.25 0 0 1 2.5 0V12h1V1.25a1.25 1.25 0 0 1 2.5 0V12h1V2.75a1.25 1.25 0 0 1 2.5 0V12h1V5.75a1.25 1.25 0 0 1 2.5 0V16c0 4.42-3.58 8-8 8" />
        </svg> */}
        <g
          stroke="currentColor"
          strokeWidth={12}
          strokeLinejoin="round"
          strokeLinecap="round"
          fill="none">
          <line
            // exclamation mark
            x1="50"
            y1="25"
            x2="50"
            y2="55"
          />
          <line
            x1="50"
            y1="75"
            x2="50"
            y2="75"
          />
        </g>

      </svg>
    </div>
  )
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
      <rect
        stroke="currentColor"
        width="24" height="24"
        rx="4" />
      <path
        fill="currentColor"
        d="M13 24c-3.26 0-6.19-1.99-7.4-5.02l-3.03-7.61a1 1 0 0 1 1.24-1.32l.79.26c.56.18 1.02.61 1.24 1.16L7.25 15H8V3.25a1.25 1.25 0 0 1 2.5 0V12h1V1.25a1.25 1.25 0 0 1 2.5 0V12h1V2.75a1.25 1.25 0 0 1 2.5 0V12h1V5.75a1.25 1.25 0 0 1 2.5 0V16c0 4.42-3.58 8-8 8" />
    </svg>
  );
}