import CombineStyles from "../../../../utils/combineStyles";
import { ETagSearchResult } from "../../TagTree";
import styles from "./TagChainElement.module.css";

interface IProps {
  name: string;
  searchResult: ETagSearchResult;
  comma: boolean;
}
export function TagChainElement(props: IProps) {
  let text = props.comma ? `${props.name}, ` : props.name;
  let style: string;
  switch (props.searchResult) {
    case "AbstractCategoryMatch":
      style = styles.abstractCategoryMatch;
      break;
    case "CategoryMatch":
      style = styles.categoryMatch;
      break;
    case "ExactMatch":
      style = styles.exactMatch;
      break;
    case "LevelMatch":
      style = styles.levelMatch;
      break;
    case "NotFound":
      style = styles.notFound;
      break;
    case "NotSearchedButExists":
      style = styles.notSearchedButExists;
      break;
  }
  const containerStyles = CombineStyles([styles.container, style]);
  return (
    <div className={containerStyles}>
      {text}
    </div>
  )
}