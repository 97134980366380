import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../../../context/app-auth-context/AppAuthContext";
import { apiPostPrivate, checkResponse } from "../../../../../../utils/api";
import { apiBasePath, pathUpdateUserMessages } from "../../../../../../utils/apiPathConstant";
import { ClassUserMessage } from "../../../../../messages/Messages";

interface IResponse {
  unreadMessageCount: number;
  messages: ClassUserMessage[];
  errors?: string;
}

interface IProps {
  msalInstance: IPublicClientApplication;
  authConfig?: IAuthConfig;
  abortSignal?: AbortSignal;
  updatedMessages: ClassUserMessage[];
}

export async function updateUserMessagesAsync(props: IProps): Promise<IResponse> {
  if (!props.authConfig) throw new Error("updateUserMessagesAsync: auth config is not provided");
  //---------------------------------------------------------------------------
  const response = await apiPostPrivate(
    props.msalInstance,
    props.authConfig,
    `${apiBasePath}${pathUpdateUserMessages}`,
    props.updatedMessages,
    undefined,
    props.abortSignal
  );
  //---------------------------------------------------------------------------
  let messages: ClassUserMessage[] = [];
  checkResponse(response, "updateUserMessagesAsync", props.abortSignal?.aborted);
  return {
    unreadMessageCount: response?.content.unreadMessageCount,
    messages: ClassUserMessage.fromArray(response?.content.messages)
  };
}