import { useMsal } from '@azure/msal-react';
import ConsoleContent from '../../../../common/console-layout/console-content/ConsoleContent';
import { IUiEditformDocumentRequest } from './IUiEditformDocumentRequest';
import { useContext, useEffect, useState } from 'react';
import { AppAuthContextStore } from '../../../../../context/app-auth-context/AppAuthContext';
import { AppUiContextStore } from '../../../../../context/app-ui-context/AppUiContextProvider';
import { useParams } from 'react-router-dom';
import useNavigateWithContext from '../../../../../hooks/useNavigateWithContext';
import { useAbortController } from '../../../../../hooks/useAbortController';
import { IDocumentRequest } from './IDocumentRequest';
import GetHint from '../../../../common/functions/GetHint';
import WikiSpinner from '../../../../app-layout/spinner/wikiSpinner';
import { Locker } from '../../../../common/locks/Locker';
import FormOptions from '../../../../common/form-options-bar/FormOptions';
import { getTUiMenuContent } from '../../../../common/menu/menu-content/TUiMenuContent';
import { DocumentReviewState } from './IDocumentReviewState';
import { IUiOption } from '../../../../common/options/IUiOption';
import { getDocumentRequestAsync } from './getDocumentRequestAsync';
import { NotificationContext } from '../../../../../context/notification-context/NotificationContextProvider';
import RequestDetails from './request-details/RequestDetails';
import GetName from '../../../../common/functions/GetName';
import { TUiBreadcrumb } from '../../../../common/breadcrumbs-trail/breadcrumb/TUiBreadcrumb';
import { pathAdminConsole, pathModeratorConsole } from '../../../AdminConsole';
import { pathDocuments } from '../../ModeratorConsole';
import ChecklistDocument from '../checklist/ChecklistDocument';
import DocumentContent, { IHighlightedItem } from '../../../../document-viewer/document-content/DocumentContent';
import { IReadableDocument } from '../../../../document-viewer/IReadableDocument';
import { IContentUnitStatus } from '../../../../common/content/IContentUnit';
import { DocumentDraftItem } from '../../../administrator-console/documents/document-editor/IDocumentDraftItem';
import { completeDocumentRequestAsync } from './completeDocumentRequestAsync';
import { IDocumentDraft } from '../../../administrator-console/documents/document-editor/IDocumentDraft';

export interface IHoveredItem {
  path: string;
  status?: IContentUnitStatus;
}

interface IProps {
  ui: IUiEditformDocumentRequest;
}

export default function DocumentRequestForm(props: IProps) {
  const { requestId, localeId } = useParams();
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { appUiContext, appUiContextDispatch } = useContext(AppUiContextStore);
  const { notificationContextDispatch } = useContext(NotificationContext);
  const navigate = useNavigateWithContext();
  const abortController = useAbortController("RegionalHomepageRequestEditForm");
  const [isLoading, setIsLoading] = useState(false);
  const [request, setRequest] = useState<IDocumentRequest>();
  const [state, setState] = useState<DocumentReviewState>();
  //const [locker, setLocker] = useState<TLocker>();
  const [highligtedItem, setHighligtedItem] = useState<IHighlightedItem>();
  //--------------------------------------------------------------------------- Data, AbortController, Colors
  useEffect(() => {
    if (!requestId) return;
    if (!localeId) return;
    setIsLoading(true);
    let controller = abortController.newController("getDocumentRequestAsync");
    getDocumentRequestAsync({
      msalInstanse: instance,
      authConfig: appAuthContext.config,
      abortSignal: controller.signal,
      requestId,
      localeId
    }).then(request => {
      setRequest(request);
    }).catch(error => {
      !controller.aborted && notificationContextDispatch({ type: "SetError", header: "Moderator: Get document request", message: error });
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
    //-------------------------------------------------------------------------
    return () => {
      abortController.abortOnUnmount();
    };
  }, []);
  //--------------------------------------------------------------------------- Breadcrumb, Review state
  useEffect(() => {
    if (!request) return;
    const locale = appUiContext.dictionaries?.supportedLocales?.find(l => l.id == request.localeId);
    const requestName = `${request.documentDraft.title} (${GetName(appUiContext, request.localeId, locale?.name)})`;
    const userBreadcrumb: TUiBreadcrumb = {
      id: request.id,
      caption: { en: requestName },
      path: `/${pathAdminConsole}/${pathModeratorConsole}/${pathDocuments}/${request.id}`
    };
    appUiContextDispatch({ type: "UpdateBreadcrumbsTrail", value: userBreadcrumb });
    //-------------------------------------------------------------------------
    //setLocker(request.locker);
    const reviewState = DocumentReviewState.initialize({
      documentDraft: request.documentDraft,
      isReadonly: request.dateTimeResolved != null || request.locker?.statusCode != 200
    });
    setState(reviewState);
  }, [request]);
  //---------------------------------------------------------------------------
  const onHover = (item?: IHoveredItem) => {
    if (!item) {
      setHighligtedItem(undefined);
    } else {
      let color = 'var(--clr_accent_attention)';
      if (item.status?.isApproved) {
        color = 'var(--clr_accent_main)';
      } else if (item.status?.isInTrouble) {
        color = 'var(--clr_accent_wrong)';
      };
      setHighligtedItem({
        path: item.path,
        color: color
      });
    };
  };
  //---------------------------------------------------------------------------
  const onReset = () => {
    if (!state) return;
    const updatedState = state.restoreInitialState();
    setState(updatedState);
  };
  //---------------------------------------------------------------------------
  const onItemsReview = (updatedItems: DocumentDraftItem[]) => {
    if (!state) return;
    let updatedAllItems = state.documentItems;
    updatedItems.forEach(updatedItem => {
      updatedAllItems = updatedAllItems.map(item =>
        item.fullPath == updatedItem.fullPath ? updatedItem : item
      );
    });
    const updatedState = state.getUpdatedState(updatedAllItems);
    setState(updatedState);
  };
  //---------------------------------------------------------------------------
  const onComplete = (approved: boolean) => {
    if (!request) return;
    if (!state) return;
    setIsLoading(true);
    let controller = abortController.newController("completeDocumentRequestAsync");
    const documentDraftToPass: IDocumentDraft = {
      ...request.documentDraft,
      items: state.documentItems
    };
    completeDocumentRequestAsync({
      msalInstanse: instance,
      authConfig: appAuthContext.config,
      abortSignal: controller.signal,
      requestId: request.id,
      documentDraft: documentDraftToPass,
      isApproved: approved
    }).then(_ => {
      navigate(`/${pathAdminConsole}/${pathModeratorConsole}/${pathDocuments}`);
    }).catch(error => {
      !controller.aborted && notificationContextDispatch({ type: "SetError", header: "Moderator: Complete document request", message: error });
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (optionId: string) => {
    switch (optionId) {
      case "OptionClose":
      case "OptionCancel":
        navigate(`/${pathAdminConsole}/${pathModeratorConsole}/${pathDocuments}`);
        break;
      case "OptionReset":
        onReset();
        break;
      case "OptionApprove":
        onComplete(true);
        break;
      case "OptionReject":
        onComplete(false);
        break;
    };
  };
  //---------------------------------------------------------------------------
  const sidebarContent = request?.documentDraft ?
    <DocumentContent
      data={request?.documentDraft as IReadableDocument}
      highligtedItem={highligtedItem}
    /> :
    undefined;
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  props.ui.menuContent.options.forEach(option => {
    switch (option.id) {
      case "OptionClose":
        state?.isReadonly && updatedOptions.push({
          ...option,
          iconId: "close"
        });
        break;
      case "OptionCancel":
        !state?.isReadonly && updatedOptions.push({
          ...option,
          iconId: "close"
        });
        break;
      case "OptionReset":
        !state?.isReadonly && updatedOptions.push({
          ...option,
          iconId: "refresh",
          isDisabled: !state?.isModified
        });
        break;
      case "OptionApprove":
        const approveIsDisabled = !(state?.isCompleted && state.isApproved);
        state && !state.isReadonly && updatedOptions.push({
          ...option,
          iconId: "check",
          customColor: approveIsDisabled ? undefined : 'var(--clr_accent_main)',
          isDisabled: approveIsDisabled
        });
        break;
      case "OptionReject":
        const rejectIsDisabled = !(state?.isCompleted && !state.isApproved);
        state && !state.isReadonly && updatedOptions.push({
          ...option,
          iconId: "ban",
          customColor: rejectIsDisabled ? undefined : 'var(--clr_accent_wrong)',
          isDisabled: rejectIsDisabled
        });
        break;
    };
  });
  const menuContent = getTUiMenuContent({ ...props.ui.menuContent, options: updatedOptions });
  const formOptions =
    <FormOptions
      ui={menuContent}
      optionIdVisibleOnMobile={"OptionCancel"}
      onSelect={onOptionSelect}
    />;
  //---------------------------------------------------------------------------
  return (
    <ConsoleContent
      prompt={GetHint(appUiContext, props.ui.id, props.ui.hint)}
      formOptions={formOptions}
      sidebarChildren={sidebarContent}
    >
      <WikiSpinner show={isLoading} />
      <Locker
        locker={request?.locker}
      />
      <RequestDetails
        data={request}
      />
      <ChecklistDocument
        data={state?.documentItems}
        isReadonly={!!state?.isReadonly}
        onHover={onHover}
        onReview={onItemsReview}
      />
    </ConsoleContent>
  );
}