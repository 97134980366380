import CombineStyles from "../../../../../utils/combineStyles";
import ButtonCaption from "../../../../common/button-caption/ButtonCaption";
import { ButtonType } from "../../../../common/button/ButtonType";
import { IUiOption } from "../../../../common/options/IUiOption";
import styles from "./UserMenuOptionButton.module.css";

interface IProps {
  ui: IUiOption;
  selected?: boolean;
  clickHandler: (optionId: string) => void;
}

export default function UserMenuOptionButton(props: IProps) {
  const isDropdown = !!props.ui.isDropdown;
  //-------------------------------------------------------------------------
  const clickHandler = () => {
    if (!props.ui.isDisabled)
      props.clickHandler(props.ui.id);
    else
      console.log(`Click handler disabled because this option (${props.ui.id}) is disabled`)
  }
  //-------------------------------------------------------------------------
  const containerStyle = CombineStyles([
    styles.container,
    isDropdown ? styles.dropdown : "",
    props.selected ? styles.default : "",
    props.ui.isDefault ? styles.default : ""
  ]);
  //-------------------------------------------------------------------------
  return (
    <div
      id={props.ui.id}
      className={containerStyle}
      onClick={clickHandler}
    >
      <ButtonCaption
        type={ButtonType.Toolbar}
        ui={{
          id: props.ui.id,
          iconFile: props.ui.iconId,
          caption: props.ui.caption,
          hint: props.ui.hint,
          disabled: !!props.ui.isDisabled,
          visible: true,
          canHideCaption: false//props.ui.canHideCaption
        }}
        customCaption={props.ui.customCaption}
        displayIcon={true}
        isDropdown={isDropdown}
      />
    </div>
  );
}