import { useState } from 'react';
import CombineStyles from '../../../../../../utils/combineStyles';
import Icon from '../../../../../common/icon/Icon';
import Toggler from '../../../../../common/toggler/Toggler';
import styles from './DocumentsListItem.module.css';
import { IDocumentsListItem } from './IDocumentsListItem';
import TranslationsItem from './translations-item/TranslationsItem';
import PopupSelectOriginal from './popup-select-original/PopupSelectOriginal';

interface IProps {
  data: IDocumentsListItem;
  onEdit: (documentId: string, locale: string) => void;
  onTranslate: (documentId: string, locale: string, originalLocale: string) => void;
}

export default function DocumentsListItem(props: IProps) {
  const [expanded, setExpanded] = useState(false);
  const [targetLocale, setTaretLocale] = useState<string>();
  const iconId = props.data.documentType === "Legal" ? "documentLegal" : "documentManual";
  //---------------------------------------------------------------------------
  const onToggle = () => {
    setExpanded(!expanded);
  };
  //---------------------------------------------------------------------------
  const onSelect = (locale: string, isNew: boolean) => {
    if (isNew) {
      const originals = props.data.docs.filter(doc => doc.originalExists);
      if (originals.length > 1)
        setTaretLocale(locale);
      else if (originals.length == 1) {
        props.onTranslate(props.data.documentId, locale, originals[0].locale);
      };
    } else {
      props.onEdit(props.data.documentId, locale);
    };
  };
  //---------------------------------------------------------------------------
  const onSourceLocaleSelect = (sourceLocale: string) => {
    if (!targetLocale) return;
    props.onTranslate(props.data.documentId, targetLocale, sourceLocale);
    setTaretLocale(undefined);
  };
  //---------------------------------------------------------------------------
  const translationItems = props.data.docs.map(doc => (
    <TranslationsItem
      key={doc.locale}
      data={doc}
      onSelect={onSelect}
    />
  ));
  //---------------------------------------------------------------------------
  return (
    <div className={styles.wrapper}>
      <div
        className={styles.container}
        onClick={onToggle}>

        <div
          title={props.data.documentType}
          className={styles.option}>
          <Icon key={iconId} imageName={iconId} />
        </div>

        <div className={styles.details}>
          <div className={styles.name}>
            {props.data.title ?? `{{${props.data.documentId}}}`}
          </div>
          <div>
            {props.data.description}
          </div>
        </div>

        {!props.data.originalExists &&
          <div
            title='New, not published yet'
            className={styles.new}>
            <Icon key="new" imageName="new" />
          </div>}

        <div
          title='Toggle'
          className={CombineStyles([styles.option, styles.toggler])}>
          <Toggler collapsed={!expanded} />
        </div>

      </div>

      {expanded && translationItems}

      {targetLocale &&
        <PopupSelectOriginal
          data={props.data.docs}
          onSelect={onSourceLocaleSelect}
          onClose={() => setTaretLocale(undefined)}
        />}

    </div>
  );
}