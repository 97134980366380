export type EMessageDeliveryType =
  "Internal" |
  "Email" |
  "Sms" |
  "WhatsApp";

type EMessageDeliveryStatus =
  "Created" |
  "Sent" |
  "Delivered" |
  "Read" |
  "Failed";

class MessageDeliveryStage {
  status!: EMessageDeliveryStatus;
  description!: string;
  private _dateTime!: Date;
  get dateTime(): Date {
    return this._dateTime;
  }
  set dateTime(value: any) {
    this._dateTime = new Date(value);
  }
  constructor(source: any) {
    Object.assign(this, source);
  }
}

export class MessageDelivery {
  deliveryType!: EMessageDeliveryType;
  addresses!: string[];
  private _deliveryHistory!: MessageDeliveryStage[];
  get deliveryHistory(): MessageDeliveryStage[] {
    return this._deliveryHistory;
  }
  set deliveryHistory(value: any[]) {
    this._deliveryHistory = value?.map(item => new MessageDeliveryStage(item))
  }
  constructor(source: any) {
    Object.assign(this, source);
  }
}
