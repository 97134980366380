import { IUiInteractiveForm } from "../../../../../common/forms/IUiInteractiveForm";
import { IUiImageEditor } from "../../../../../common/image-editor/IUiImageEditor";
import { IUiInputCheckbox } from "../../../../../common/input-v2/input-checkbox/InputCheckBox";
import { IUiInputDropdown } from "../../../../../common/input-v2/input-dropdown/InputDropdown";
import { IUiInputText } from "../../../../../common/input-v2/input-text/InputText";
import { IUiInput } from "../../../../../common/input-v2/IUiInput";

export interface IUiEditformDocumentItem extends IUiInteractiveForm {
  inputType: IUiInputDropdown;
  inputIsNumbered: IUiInputCheckbox;
  inputIsMarked: IUiInputCheckbox;
  inputHeader: IUiInputText;
  inputText: IUiInputText;
  inputImage: IUiInput;
  imageEditor?: IUiImageEditor;
}

export const tmpUiEditformDocumentItem: IUiEditformDocumentItem = {
  id: "",
  directoryId: "",
  elementType: "",
  caption: undefined,
  hint: undefined,
  menuContent: {
    id: "",
    directoryId: "",
    elementType: "",
    hint: undefined,
    options: [{
      id: "OptionCancel",
      directoryId: "",
      elementType: "",
      caption: { en: "Cancel" },
      hint: undefined
    }, {
      id: "OptionClose",
      directoryId: "",
      elementType: "",
      caption: { en: "Close" },
      hint: undefined
    }, {
      id: "OptionOk",
      directoryId: "",
      elementType: "",
      caption: { en: "Ok" },
      hint: undefined
    }]
  },
  inputType: {
    id: "InputDocumentItemType",
    directoryId: "",
    elementType: "",
    dictionaryId: "",
    caption: { en: "Type" },
    hint: { en: "Specify item type" },
    placeholder: { en: "Select type" },
    validation: {
      required: {
        value: 1,
        message: { en: "Type is required" }
      }
    }
  },
  inputIsNumbered: {
    id: "",
    directoryId: "",
    elementType: "",
    caption: { en: "Numbered" },
    hint: { en: "If true, the item will be numbered automatically" }
  },
  inputIsMarked: {
    id: "",
    directoryId: "",
    elementType: "",
    caption: { en: "Marked" },
    hint: { en: "If true, the item will be marked with a bullet point" }
  },
  inputHeader: {
    id: "",
    directoryId: "",
    elementType: "",
    caption: { en: "Header" },
    hint: { en: "Document item header. Relevant for title and section" },
    placeholder: { en: "Header text" },
  },
  inputText: {
    id: "",
    directoryId: "",
    elementType: "",
    caption: { en: "Text" },
    hint: { en: "This text serves as description for title and section or paragraph text" },
    placeholder: { en: "Content text" },
  },
  inputImage: {
    id: "",
    directoryId: "",
    elementType: "",
    caption: { en: "Image" },
    hint: { en: "Paragraph image" }
  }
}