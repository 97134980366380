export function geocodeLatLng(
  geocodr: google.maps.Geocoder | undefined,
  location: google.maps.LatLng | google.maps.LatLngLiteral | null | undefined,
  callBack: (address: string) => void
): string {
  let address = "";
  if (location) {
    const geocoder = new google.maps.Geocoder();
    geocoder
      .geocode({ location: location })
      .then((response) => {
        console.log(response);
        // if (response.results.length > 0) {
        //   address = response.results[0].formatted_address;
        //   callBack && callBack(address);
        // } else {
        //   window.alert("No results found");
        // }
      })
      .catch((e) => console.error("Geocoder failed due to: " + e))
    //.finally(()=>{ return address; });
  }
  return address;
}
//-----------------------------------------------------------------------------------------------------------
export function getLocationByUserInput(map: google.maps.Map | undefined, query: string | undefined, callback: any) {
  console.log("getLocationByUserInput.query:", query);
  if (!map || !query) {
    return;
  }
  let service = new google.maps.places.PlacesService(map);
  const request = {
    query: query,
    fields: ["name", "geometry", "types", "place_id"],
  };
  service.findPlaceFromQuery(
    request,
    (
      results: google.maps.places.PlaceResult[] | null,
      status: google.maps.places.PlacesServiceStatus
    ) => {
      if (status === google.maps.places.PlacesServiceStatus.OK && results) {
        console.log("Search Locations:", results);
        for (let i = 0; i < results.length; i++) {
          callback && callback(results[i]);
        }
      }
    }
  );
}
//-----------------------------------------------------------------------------------------------------------
type GetLocationsCallback = (results: google.maps.places.PlaceResult[]) => void;
//-----------------------------------------------------------------------------------------------------------
export function getLocationsByUserInput(map: google.maps.Map, query: string, callback: GetLocationsCallback) {
  console.log("getLocationsByUserInput.query:", query);
  let service = new google.maps.places.PlacesService(map);
  const request = {
    query: query,
    fields: ["name", "geometry", "types", "place_id"],
  };
  service.findPlaceFromQuery(
    request,
    (
      results: google.maps.places.PlaceResult[] | null,
      status: google.maps.places.PlacesServiceStatus
    ) => {
      if (status === google.maps.places.PlacesServiceStatus.OK && results) {
        console.log("Search Locations:", results);
        callback(results);
      }
    }
  );
}