import React, { useEffect, useState } from 'react';
import { IObjectLocation } from '../../../../business-space/business-editor/section-location/business-location-editor/IBusinessLocationData';
import ChecklistItemWrapper from '../checklist-item-wrapper/ChecklistItemWrapper';
import ChecklistItemText from '../checklist-item-text/ChecklistItemText';
import { IContentUnitText } from '../../../../common/content/content-text/IContentUnitText';

interface IProps {
  adModuleId: string;
  data: IObjectLocation<IContentUnitText>;
  isReadonly: boolean;
  isCollapsed: boolean;
  onHover?: (path: string[]) => void;
  onReview?: (updatedLocationUnit: IObjectLocation<IContentUnitText>) => void;
}

export default function ChecklistItemLocation(props: IProps) {
  if (!props.data.status) return null;
  const [collapsed, setCollapsed] = useState(true);
  //--------------------------------------------------------------------------- Collapsed
  useEffect(() => {
    setCollapsed(props.isCollapsed || !!props.data.status?.isCompleted);
  }, [props.isCollapsed, props.data.status]);
  //---------------------------------------------------------------------------
  const onToggle = () => {
    setCollapsed(!collapsed);
  };
  //---------------------------------------------------------------------------
  const onHover = (isHovered: boolean) => {
    if (!props.onHover) return;
    const path: string[] = isHovered ? [props.adModuleId, props.data.id] : [];
    props.onHover(path);
  };
  //---------------------------------------------------------------------------
  const onNameHover = (isHovered: boolean) => {
    if (!props.onHover) return;
    if (!props.data.name) return;
    const path: string[] = isHovered ? [props.adModuleId, props.data.id, props.data.name.type] : [];
    props.onHover(path);
  };
  //---------------------------------------------------------------------------
  const onAddressHover = (isHovered: boolean) => {
    if (!props.onHover) return;
    if (!props.data.addressText) return;
    const path: string[] = isHovered ? [props.adModuleId, props.data.id, props.data.addressText.type] : [];
    props.onHover(path);
  };
  //---------------------------------------------------------------------------
  const onNameReview = (updatedTextUnit: IContentUnitText) => {
    if (props.isReadonly) return;
    const updatedLocationUnit: IObjectLocation<IContentUnitText> = {
      ...props.data,
      name: updatedTextUnit
    };
    props.onReview?.(updatedLocationUnit);
  };
  //---------------------------------------------------------------------------
  const onAddressReview = (updatedTextUnit: IContentUnitText) => {
    if (props.isReadonly) return;
    const updatedLocationUnit: IObjectLocation<IContentUnitText> = {
      ...props.data,
      addressText: updatedTextUnit
    };
    props.onReview?.(updatedLocationUnit);
  };
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>
      <ChecklistItemWrapper
        unitType='ContentText'
        caption={"Location"}
        isGroup={true}
        level={1}
        status={props.data.status}
        isReadonly={props.isReadonly}
        isCollapsed={collapsed}
        onHover={onHover}
        onToggle={onToggle}
      />

      {/* Location name */}
      {props.data.name && !collapsed &&
        <ChecklistItemText
          data={props.data.name}
          level={2}
          isReadonly={props.isReadonly}
          onHover={onNameHover}
          onReview={onNameReview}
        />}

      {/* Location address */}
      {props.data.addressText && !collapsed &&
        <ChecklistItemText
          data={props.data.addressText}
          level={2}
          isReadonly={props.isReadonly}
          onHover={onAddressHover}
          onReview={onAddressReview}
        />}

    </React.Fragment>
  );
}