import { useContext } from 'react';
import { StageDescription } from '../../../regional-homepages/request-form/request-details/RequestDetails';
import RequestStatusBadge from '../../../regional-homepages/request-form/request-status/request-status-badge/RequestStatusBadge';
import { IDocumentRequest } from '../IDocumentRequest';
import styles from './RequestDetails.module.css';
import { AppUiContextStore } from '../../../../../../context/app-ui-context/AppUiContextProvider';
import GetName from '../../../../../common/functions/GetName';

interface IProps {
  data?: IDocumentRequest;
}

export default function RequestDetails(props: IProps) {
  if (!props.data) return null;
  const { appUiContext } = useContext(AppUiContextStore);
  const localeName = appUiContext.dictionaries?.supportedLocales?.find(l => l.id == props.data?.localeId)?.name;
  const actualLogRecord = props.data.log?.records.find(r => r.id == props.data?.log.actualRecordId);
  //---------------------------------------------------------------------------
  let statusDetails = undefined;
  switch (props.data.status) {
    case "New":
    case "Replaced":
      break;
    case "Assigned":
      statusDetails = StageDescription(
        actualLogRecord?.updatedAt ? actualLogRecord?.updatedAt : "",
        actualLogRecord?.assignedTo?.displayName, "to");
      break;
    case "Approved":
    case "Rejected":
      statusDetails = StageDescription(
        props.data.dateTimeResolved,
        actualLogRecord?.assignedTo.displayName);
      break;
  };
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>

      {/* Created */}
      <div>
        <RequestStatusBadge data={"Created"} />
      </div>
      <div className={styles.details}>
        {StageDescription(props.data.dateTimeCreated, props.data.createdBy.displayName)}
      </div>

      {/* Current status */}
      <div>
        <RequestStatusBadge
          data={props.data.status}
          isApproved={props.data.status == "Approved" ? true : undefined}
          isRejected={props.data.status == "Rejected" ? true : undefined} />
      </div>
      <div className={styles.details}>
        {statusDetails}
      </div>

      {/* Document title */}
      <div className={styles.caption}>
        Title
      </div>
      <div>
        {props.data.documentDraft.title}
      </div>

      {/* Locale */}
      <div className={styles.caption}>
        Locale
      </div>
      <div>
        {GetName(appUiContext, props.data.localeId, localeName)}
      </div>

    </div>
  );
}