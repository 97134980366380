import { IAdLanguageSetReviewIssue } from "../../ad-content-editor/IAdLanguageSet";
import { EApprovalRequestUnitStatus } from "../../admin-console/moderator-console/approval-request-checklist/IApprovalRequestUnit";
import { Contact } from "../../business-space/business-editor/section-contacts/contact/Contact";
import { IBusinessLocationData } from "../../business-space/business-editor/section-location/business-location-editor/IBusinessLocationData";
import { IDesignTemplateAdModule } from "../design-templates/IDesignTemplateAdModule";
import { TImageData } from "../image-editor/TImageData";
import { ClassContentUnitStatus, EContentUnitStatus, IContentUnit, IContentUnitStatus } from "../content/IContentUnit";
import { v4 as uuidv4 } from "uuid";
import { IContentUnitImage } from "../content/content-image/IContentUnitImage";
import { IContentUnitText } from "../content/content-text/IContentUnitText";
import { IRequestRejectionReason } from "../../admin-console/moderator-console/checklist/checklist-node-reject/IRequestRejectionReason";

export type EAdModuleType = "Photoblock" | "Addressblock" | "SearchResultsModule" | "Contentblock";

export interface IAdModuleUsage {
  isBusinessPage: boolean;
  isHomepage: boolean;
}

export interface IAdModule extends IContentUnit {
  id: string;
  businessId: string;
  localeId: string;
  adModuleType: EAdModuleType;
  design?: IDesignTemplateAdModule;
  image?: IContentUnitImage;
  header?: IContentUnitText;
  description?: IContentUnitText;
  paragraphs?: IAdModuleParagraph[];
  usage: IAdModuleUsage;
  issues?: IContentUnit[]; // For additional checks related to ad module as a whole
  editorStatus: IAdModuleEditorStatus;
  businessLocationData?: IBusinessLocationData<IContentUnitText>;
  contacts?: Contact<IContentUnitText>[];
  isOrphan?: boolean;
}

export interface IAdModuleSpread {
  design?: IDesignTemplateAdModule;
  backgroundImage?: IContentUnitImage;
  header?: IContentUnitText;
  description?: IContentUnitText;
  businessLocationData?: IBusinessLocationData<IContentUnitText>;
  contacts?: Contact<IContentUnitText>[];
  paragraphs?: IAdModuleParagraph[];
  issues?: IContentUnit[];
  isOrphan?: boolean;
}

// Create ClassAdModule class only when ad module is edited, otherwise use IAdModule interface or create a new class
export class ClassAdModule implements IAdModule {
  id!: string;
  businessId!: string;
  localeId!: string;
  adModuleType!: EAdModuleType;
  design?: IDesignTemplateAdModule;
  image?: IContentUnitImage;
  header?: IContentUnitText;
  description?: IContentUnitText;
  paragraphs?: IAdModuleParagraph[];
  usage!: IAdModuleUsage;
  status?: IContentUnitStatus;
  issues?: IContentUnit[];
  editorStatus!: IAdModuleEditorStatus;
  businessLocationData?: IBusinessLocationData<IContentUnitText>;
  contacts?: Contact<IContentUnitText>[];
  isOrphan?: boolean; // Business editor should set it if ad module is not used in any ad. This makes it deletable
  //---------------------------------------------------------------------------
  constructor(source: IAdModule, refreshStatus: boolean = false) {
    // refreshStatus - if true, module status will be recalculated based on children items statuses
    // use this option for moderator review
    Object.assign(this, source);
    this.setup(refreshStatus);
  };
  //---------------------------------------------------------------------------
  setup(refreshStatus: boolean) {
    // Ad module status
    if (!this.status) throw "Ad module status is missing";
    //this.status = this.status ? new ClassContentUnitStatus(this.status.statusId) : undefined;
    let isRejected = false;
    let isApproved = true;
    let isCompleted = true;
    //-------------------------------------------------------------------------
    // Ad Module Header
    if (this.header) {
      if (!this.header.status)
        throw "Ad module header status is missing";
      this.header.status = new ClassContentUnitStatus(
        this.header.status.statusId,
        this.header.status.rejectionReason
      );
      isRejected = this.header.status.isInTrouble || isRejected;
      isApproved = this.header.status.isApproved && isApproved;
      isCompleted = isCompleted && this.header.status.isCompleted;
    };
    //-------------------------------------------------------------------------
    // Ad Module Description
    if (this.description) {
      if (!this.description.status)
        throw "Ad module description status is missing";
      this.description.status = new ClassContentUnitStatus(
        this.description.status.statusId,
        this.description.status.rejectionReason
      );
      isRejected = this.description.status.isInTrouble || isRejected;
      isApproved = this.description.status.isApproved && isApproved;
      isCompleted = isCompleted && this.description.status.isCompleted;
    };
    //-------------------------------------------------------------------------
    // Ad Module Background Image
    if (this.image?.imageData) // unlink this.image from source.image
      this.image = { ...this.image };
    if (this.image) {
      if (!this.image.status)
        throw "Ad module image status is missing";
      this.image.imageData = this.image?.imageData ? new TImageData(this.image.imageData) : undefined;
      this.image.status = new ClassContentUnitStatus(
        this.image.status.statusId,
        this.image.status.rejectionReason
      );
      isRejected = this.image.status.isInTrouble || isRejected;
      isApproved = this.image.status.isApproved && isApproved;
      isCompleted = isCompleted && this.image.status.isCompleted;
    };
    //-------------------------------------------------------------------------
    // Locations
    if (this.businessLocationData?.locations && this.businessLocationData.locations.length > 0) {
      this.businessLocationData.locations.forEach(location => {
        if (!location.status) throw "Location status is missing";
        let locationIsCompleted = true;
        //---------------------------------------------------------------------
        // Location address
        if (location.addressText) {
          if (!location.addressText.status) throw "Location addresstext status is missing";
          location.addressText.status = new ClassContentUnitStatus(location.addressText.status.statusId, location.addressText.status.rejectionReason);
          if (!location.addressText.status.isCompleted) {
            locationIsCompleted = false;
          };
        };
        //---------------------------------------------------------------------
        // Location name
        if (location.name) {
          if (!location.name.status) throw "Location name status is missing";
          location.name.status = new ClassContentUnitStatus(location.name.status.statusId, location.name.status.rejectionReason);
          if (!location.name.status.isApproved && !location.name.status.isInTrouble) {
            locationIsCompleted = false;
          };
        };
        //---------------------------------------------------------------------
        // Location status
        const locationIsRejected = locationIsCompleted && (location.addressText?.status?.isInTrouble || location.name?.status?.isInTrouble);
        const locationIsApproved = locationIsCompleted && location.addressText?.status?.isApproved && location.name?.status?.isApproved;
        const locationStatus = locationIsApproved ? "Approved" : locationIsRejected ? "Rejected" : "PendingApproval";
        location.status = new ClassContentUnitStatus(refreshStatus ? locationStatus : location.status.statusId);
        isRejected = location.status.isInTrouble || isRejected;
        isApproved = location.status.isApproved && isApproved;
        isCompleted = isCompleted && location.status.isCompleted;
      });
    };
    //-------------------------------------------------------------------------
    // Contacts
    if (this.contacts && this.contacts.length > 0) {
      this.contacts.forEach(contact => {
        if (!contact.status) throw "Contact status is missing";
        let descriptionIsApproved = true;
        let contactIsCompleted = true;
        //---------------------------------------------------------------------
        // Contact value
        if (contact.value) {
          if (!contact.value.status) throw "Contact value status is missing";
          contact.value.status = new ClassContentUnitStatus(contact.value.status.statusId, contact.value.status.rejectionReason);
          if (!contact.value.status.isCompleted) {
            contactIsCompleted = false;
          };
        } else;
        //---------------------------------------------------------------------
        // Contact description
        if (contact.description) {
          if (!contact.description.status) throw "Contact description status is missing";
          contact.description.status = new ClassContentUnitStatus(contact.description.status.statusId, contact.description.status.rejectionReason);
          descriptionIsApproved = contact.description.status.isApproved;
          if (!contact.description.status.isCompleted) {
            contactIsCompleted = false;
          };
        };
        //---------------------------------------------------------------------
        const contactIsRejected = contactIsCompleted && (contact.value?.status?.isInTrouble || !descriptionIsApproved);
        const contactIsApproved = contactIsCompleted && contact.value?.status?.isApproved && descriptionIsApproved;
        const contactStatus = contactIsApproved ? "Approved" : contactIsRejected ? "Rejected" : "PendingApproval";
        contact.status = new ClassContentUnitStatus(refreshStatus ? contactStatus : contact.status.statusId);
        isRejected = contact.status.isInTrouble || isRejected;
        isApproved = contact.status.isApproved && isApproved;
        isCompleted = isCompleted && contact.status.isCompleted;
      });
    };
    //-------------------------------------------------------------------------
    // Process paragraphs
    if (this.paragraphs) {
      this.paragraphs.forEach(paragraph => {
        let textIsApproved = true;
        let imageIsApproved = true;
        let paragraphIsCompleted = true;
        paragraph.status = new ClassContentUnitStatus(
          paragraph.status?.statusId ? paragraph.status.statusId : "Draft",
          paragraph.status?.rejectionReason
        );
        if (paragraph.text) {
          paragraph.text.status = new ClassContentUnitStatus(
            paragraph.text.status?.statusId ? paragraph.text.status.statusId : "Draft",
            paragraph.text.status?.rejectionReason
          );
          textIsApproved = paragraph.text.status.isApproved;
          paragraphIsCompleted = paragraph.text.status.isCompleted ? paragraphIsCompleted : false;
        };
        if (paragraph.image) {
          paragraph.image.imageData = paragraph.image.imageData ? new TImageData(paragraph.image.imageData) : undefined;
          paragraph.image.status = new ClassContentUnitStatus(
            paragraph.image.status?.statusId ? paragraph.image.status.statusId : "Draft",
            paragraph.image.status?.rejectionReason
          );
          imageIsApproved = paragraph.image.status.isApproved;
          paragraphIsCompleted = paragraph.image.status.isCompleted ? paragraphIsCompleted : false;
        };
        if (refreshStatus) {
          const paragraphIsRejected = paragraphIsCompleted && (!textIsApproved || !imageIsApproved);
          const paragraphIsApproved = paragraphIsCompleted && textIsApproved && imageIsApproved;
          const paragraphStatusId = paragraphIsApproved ? "Approved" : paragraphIsRejected ? "Rejected" : paragraph.status.statusId;
          paragraph.status = new ClassContentUnitStatus(paragraphStatusId);
        };
        isRejected = paragraph.status.isInTrouble || isRejected;
        isApproved = paragraph.status.isApproved && isApproved;
        isCompleted = isCompleted && paragraph.status.isCompleted;
      });
    };
    //-------------------------------------------------------------------------
    // Complex issues
    if (this.issues) {
      this.issues.forEach(issue => {
        if (!issue.status) throw "Issue status is missing";
        issue.status = new ClassContentUnitStatus(issue.status.statusId, issue.status.rejectionReason);
        isRejected = issue.status.isInTrouble || isRejected;
        isApproved = !!issue.status.isApproved && isApproved;
        isCompleted = isCompleted && issue.status.isCompleted;
      });
    };
    //-------------------------------------------------------------------------
    // Set ad module status
    let statusId: EContentUnitStatus = isCompleted ?
      isRejected ?
        "Rejected" :
        isApproved ? "Approved" : "PendingApproval" :
      this.status.statusId;
    this.status = new ClassContentUnitStatus(refreshStatus ? statusId : this.status.statusId);
  };
  //---------------------------------------------------------------------------
  updateReviewStatus() {
    // Update status based on children items statuses
    let isRejected = false;
    let isApproved = true;
    let isCompleted = true;
    //-------------------------------------------------------------------------
    // Ad Module Header
    if (this.header) {
      if (!this.header.status)
        throw "Ad module image status is missing";
      this.header.status = new ClassContentUnitStatus(this.header.status.statusId, this.header.status.rejectionReason);
      isRejected = this.header.status.isInTrouble || isRejected;
      isApproved = this.header.status.isApproved && isApproved;
      isCompleted = isCompleted && this.header.status.statusId !== "PendingApproval";
    };
    //-------------------------------------------------------------------------
    // Ad Module Image
    if (this.image) {
      if (!this.image.status)
        throw "Ad module image status is missing";
      isRejected = this.image.status.isInTrouble || isRejected;
      isApproved = this.image.status.isApproved && isApproved;
      isCompleted = isCompleted && this.image.status.statusId !== "PendingApproval";
    };
    //-------------------------------------------------------------------------
    // Locations
    if (this.businessLocationData?.locations) {
      this.businessLocationData.locations.forEach(location => {
        console.log("Location: ", JSON.stringify(location));
        // Location address (required)
        if (!location.addressText?.status) throw "Location address status is missing";
        let locationIsCompleted = location.addressText.status.statusId !== "PendingApproval";
        let locationIsRejected = location.addressText.status.isInTrouble;
        let locationIsApproved = location.addressText.status.isApproved;
        // Location name (optional)
        if (location.name) {
          if (!location.name?.status)
            throw "Location name status is missing";
          locationIsCompleted = locationIsCompleted && location.name.status.statusId !== "PendingApproval";
          locationIsRejected = locationIsRejected || location.name.status.isInTrouble;
          locationIsApproved = locationIsApproved && location.name.status.isApproved;
        };
        location.status = new ClassContentUnitStatus(locationIsRejected ? "Rejected" : locationIsApproved ? "Approved" : "PendingApproval");
        isRejected = locationIsRejected || isRejected;
        isApproved = locationIsApproved && isApproved;
        isCompleted = locationIsCompleted && isCompleted;
        console.log("Location: ", location);
      });
    };
    //-------------------------------------------------------------------------
    // Contacts
    if (this.contacts) {
      this.contacts.forEach(contact => {
        isRejected = contact.status?.isInTrouble || isRejected;
        isApproved = !!contact.status?.isApproved && isApproved;
        isCompleted = isCompleted && contact.status?.statusId !== "PendingApproval";
      });
    };
    //-------------------------------------------------------------------------
    // Paragraphs
    if (this.paragraphs) {
      // this.paragraphs.forEach(paragraph => {
      //   isRejected = paragraph.text?.status?.isInTrouble || paragraph.image?.status?.isInTrouble || isRejected;
      //   isApproved = !!paragraph.text?.status?.isApproved && !!paragraph.image?.status?.isApproved && isApproved;
      //   isCompleted = isCompleted && paragraph.text?.status?.statusId !== "PendingApproval" && paragraph.image?.status?.statusId !== "PendingApproval";
      // });
    };
    //-------------------------------------------------------------------------
    // Check complex issues
    if (this.issues) {
      this.issues.forEach(issue => {
        isRejected = issue.status?.isInTrouble || isRejected;
        isApproved = !!issue.status?.isApproved && isApproved;
        isCompleted = isCompleted && issue.status?.statusId !== "PendingApproval";
      });
    };
    //-------------------------------------------------------------------------
    let statusId: EContentUnitStatus = isCompleted ?
      isRejected ?
        "Rejected" :
        isApproved ? "Approved" : "PendingApproval" :
      "PendingApproval";
    this.status = new ClassContentUnitStatus(statusId);
    this.editorStatus = { isModified: true, isNew: false };
  };
  //---------------------------------------------------------------------------
  getCopy(localeId?: string): ClassAdModule {
    const adModuleCopy = new ClassAdModule(this);
    adModuleCopy.issues = undefined;
    //-------------------------------------------------------------------------
    // Update all ids
    adModuleCopy.id = uuidv4();
    //-------------------------------------------------------------------------
    // Update locale is if it's provided
    if (localeId)
      adModuleCopy.localeId = localeId;
    //-------------------------------------------------------------------------
    // Update paragraphs ids
    adModuleCopy.paragraphs = [];
    this.paragraphs?.forEach(paragraph => {
      const paragraphCopy = {
        ...paragraph,
        //id: uuidv4()
        // Commented out because in this case paragraph we started to edit, does not exist anymore
        // In case we need new id for paragraphs, we can update them in API once ad is saved
      };
      adModuleCopy.paragraphs?.push(paragraphCopy);
    });
    //-------------------------------------------------------------------------
    // Update status
    adModuleCopy.status = new ClassContentUnitStatus("Draft");
    adModuleCopy.editorStatus = {
      isModified: false,
      isNew: true
    };
    adModuleCopy.issues = undefined;
    //-------------------------------------------------------------------------
    return adModuleCopy;
  };
  //---------------------------------------------------------------------------
  static getReviewIssues(adModules: ClassAdModule[]): IAdLanguageSetReviewIssue[] | undefined {
    const issues: IAdLanguageSetReviewIssue[] = [];
    //-------------------------------------------------------------------------
    adModules?.forEach(adModule => {
      if (adModule.status?.isInTrouble) {
        //---------------------------------------------------------------------
        // Header
        if (adModule.header?.status?.isInTrouble) {
          issues.push({
            index: issues.length + 1,
            adModuleId: adModule.id,
            adUnitType: adModule.header.type,
            status: adModule.header.status
          });
        };
        //---------------------------------------------------------------------
        // Description
        if (adModule.description?.status?.isInTrouble) {
          issues.push({
            index: issues.length + 1,
            adModuleId: adModule.id,
            adUnitType: adModule.description.type,
            status: adModule.description.status
          });
        };
        //---------------------------------------------------------------------
        // Ad module background image
        if (adModule.image?.status?.isInTrouble) {
          issues.push({
            index: issues.length + 1,
            adModuleId: adModule.id,
            adUnitType: adModule.image.type,
            status: adModule.image.status
          });
        };
        //---------------------------------------------------------------------
        if (adModule.businessLocationData?.locations) {
          adModule.businessLocationData.locations.forEach(location => {
            if (location.status?.isInTrouble) {
              issues.push({
                index: issues.length + 1,
                adModuleId: adModule.id,
                adUnitType: "Location",
                status: location.status
              });
            };
          });
        };
        //---------------------------------------------------------------------
        if (adModule.contacts) {
          adModule.contacts.forEach(contact => {
            if (contact.status?.isInTrouble) {
              issues.push({
                index: issues.length + 1,
                adModuleId: adModule.id,
                adUnitType: "Contact",
                status: contact.status
              });
            };
          });
        };
        //---------------------------------------------------------------------
        // Paragraphs
        // NOTE: Process paragraphs here
      };
    });
    //-------------------------------------------------------------------------
    return issues.length > 0 ? issues : undefined;
  };
}

export interface IAdUnitReviewResult {
  status: EApprovalRequestUnitStatus;
  rejectionReason: IRequestRejectionReason;
};

export interface IAdModuleEditorStatus {
  isNew: boolean;
  isModified: boolean;
};

export const getUnitStatus = (unitStatus1: ClassContentUnitStatus, unitStatus2: ClassContentUnitStatus): ClassContentUnitStatus => {
  let result: EContentUnitStatus = "PendingApproval";
  if (unitStatus1.isApproved && unitStatus2.isApproved) result = "Approved";
  if (unitStatus1.isInTrouble || unitStatus2.isInTrouble) result = "Rejected";
  return new ClassContentUnitStatus(result);
};


export interface IAdModuleParagraph extends IContentUnit {
  id: string;
  text?: IContentUnitText;
  image?: IContentUnitImage;
}