export const apiBasePath = "/api";
export const appConfig = "/config/getConfig";
export const signUpSignInUri = "/user/getSignUpSignInUri";
export const identityService = "/user/getIdentityService";
export const getUiPublic = "/ui/getUiPublic";
export const getApplicationLayout = "/ui/getApplicationUi";
export const getApplicationLocale = "/user/getApplicationLocale/{0}";
//----------------------------------------------------------------------------- Home page
export const pathGetBusinessPage = "/homepage/GetBusinessPage/{0}/{1}";
export const getDefaultRegionalHomepage = "systemContent/GetRegionalHomepage/{0}";
export const getRegionalHomepage = "systemContent/GetRegionalHomepage/{0}?region={1}";
export const getAdForHome = "/homepage/GetBusinessAds/{0}?q={1}";
export const getAdForHomeWithCategory = "/homepage/GetBusinessAds/{0}?category={1}&q={2}";
export const searchBusiness = "/businessSearch/AdSearch";
export const searchBusinessPreliminary = "/businessSearch/AdSearchPreliminary";
export const searchBusinessDetails = "/businessSearch/AdSearchGetDetails";
export const pathGetLegalDocuments = "/homepage/getLegal/{0}";
//----------------------------------------------------------------------------- User
export const getUiUserConsole = "/ui/getUiUserConsole";
export const getUserApi = "/user/getUser";
export const updateUser = "/user/update";
export const updateUserPersonalInfo = "/user/updateUserPersonalInfo";
export const deleteAccount = "/user/deleteAccount";
export const getUserMenu = "/ui/getUserMenu";
export const addUserEmail = "/user/AddEmail/{0}";
//----------------------------------------------------------------------------- Customer Business
export const pathGetUserBusinessList = "/business/GetList";
export const pathGetNewBusiness = "/business/GetNew/{0}";
export const pathGetBusiness = "/business/Get/{0}";
export const pathCreateBusinessPath = "/business/CreatePath/{0}/{1}";
//----------------------------------------------------------------------------- Wizard
export const pathGetNewBusinessWithAd = "/businessWizard/GetNew";
export const pathSubmitBusinessWithAd = "/businessWizard/Submit";
//----------------------------------------------------------------------------- Customer Ad Language Set
export const pathAdLanguageSetCreate = "/ad/Create/{0}/{1}/{2}";
export const pathAdLanguageSetGet = "/ad/Get/{0}/{1}?getBusiness={2}";
export const pathAdLanguageSetDelete = "/ad/Delete/{0}/{1}/{2}";
export const pathAdLanguageSetUpdate = "/ad/Update/{0}/{1}";
export const pathAdLanguageSetSendForApproval = "/ad/SendForApproval/{0}/{1}/{2}";
export const pathAdLanguageSetActivate = "/ad/Activate/{0}/{1}/{2}/{3}";
export const pathAdLanguageSetDeactivate = "/ad/Deactivate/{0}/{1}/{2}/{3}";
//----------------------------------------------------------------------------- Customer Ad Modules
export const pathAdModuleGetList = "/adModule/GetList/{0}";
export const pathAdModuleDelete = "/adModule/Delete/{0}/{1}";

export const getBusinessWizard = "/ui/GetBusinessWizard";
export const getUiBusinessWizard = "/ui/GetUiBusinessWizard";
export const getUserBusinessEditorUI = "/ui/GetBusinessEditorUi";
export const pathGetUiBusinessConsole = "/ui/GetUiBusinessConsole";
export const getBusinessSearchUi = "/ui/GetBusinessSearchUi";
export const getUserBusiness = "/business/GetUserBusinesses";
export const updateBusiness = "/business/Update";
export const deleteBusiness = "/business/Delete/{0}";
export const pathGetNewBusinessWithDefaultContent = "/business/GetNewBusiness/{0}";
export const getNewAdPage = "/business/GetNewAdPage/{0}";
export const getnewAdPageAsCopy = "/business/GetNewAdPageAsCopy/{0}/{1}/{2}/{3}";
export const getBusinessByID = "/business/GetBusiness/{0}";
export const createFirstAdPage = "/business/CreateFirstAdpage/{0}/{1}/{2}";
export const createAdPageCopy = "/business/CreateAdPageCopy/{0}/{1}/{2}";
export const createAdPageCopyToTranslate = "/business/CreateAdPageCopy/{0}/{1}/{2}?locale={3}";
export const removeAdpage = "/business/RemoveAdpage/{0}/{1}/{2}";
export const pathCreateAdCampaign = "/business/CreateAdCampaign/{0}/{1}"; // Create new empty ad campaign with specified name and ad rank
export const createAdCampaignAsFullCopy = "/business/CreateAdCampaign/{0}?origin={1}";
export const pathDeleteAdCampaign = "/business/DeleteAdCampaign/{0}/{1}";
export const checkBusinessUrl = "/business/CheckBusinessUrlAvailable/{0}/{1}";
export const checkBusinessNameApi = "/business/CheckBusinessNameAvailable/{0}/{1}";
export const submitNewCategoryRequests = "/NewCategoryRequest/SubmitNewCategoryRequests/{0}";
export const getNewCategoryRequestsByAuthor = "/NewCategoryRequest/GetNewCategoryRequestsByAuthor";
//----------------------------------------------------------------------------- System Business Regional
export const pathGetListSystemBusinessRegional = "/SystemBusinessRegional/GetList";
export const pathCreateSystemBusinessRegional = "/SystemBusinessRegional/Create/{0}";
export const pathGetSystemBusinessRegional = "/SystemBusinessRegional/Get/{0}";
export const pathUpdateSystemBusinessRegional = "/SystemBusinessRegional/Update";
export const pathCreateSystemBusinessRegionalPath = "/SystemBusinessRegional/CreatePath/{0}/{1}";
export const pathDeleteSystemBusinessRegionalPath = "/SystemBusinessRegional/DeletePath/{0}/{1}";
export const pathCreateAdCampaignSystemBusinessRegional = "/SystemBusinessRegional/CreateAdCampaign/{0}/{1}"; // Create new empty campaign with specified name
export const pathDeleteAdCampaignSystemBusinessRegional = "/SystemBusinessRegional/DeleteAdCampaign/{0}/{1}"; // Delete system campaign with specified Id
//----------------------------------------------------------------------------- System Ads
export const pathGetListAdLanguageSetSystem = "/SystemAd/GetList/{0}";
export const pathGetAdLanguageSetSystem = "/SystemAd/Get/{0}/{1}?getBusiness={2}";
export const pathCreateAdLanguageSetSystem = "/SystemAd/Create/{0}/{1}/{2}";
export const pathDeleteAdLanguageSetSystem = "/SystemAd/Delete/{0}/{1}/{2}";
export const pathUpdateAdLanguageSetSystem = "/SystemAd/Update/{0}/{1}";
export const pathSendAdLanguageSetSystemForApproval = "/SystemAd/SendForApproval/{0}/{1}/{2}";
export const pathActivateAdLanguageSetSystem = "/SystemAd/Activate/{0}/{1}/{2}/{3}";
export const pathDeactivateAdLanguageSetSystem = "/SystemAd/Deactivate/{0}/{1}/{2}/{3}";
//----------------------------------------------------------------------------- System Ad Modules
export const pathGetListAdModuleSystem = "/SystemAdModules/GetList/{0}";
export const pathDeleteAdModuleSystem = "/SystemAdModules/Delete/{0}/{1}";
//----------------------------------------------------------------------------- Requests
export const pathGetListRequestRegional = "/RequestRegional/GetList";
export const pathGetRequestRegional = "/RequestRegional/Get/:requestId/:localeId";
export const pathCompleteRequestRegional = "/RequestRegional/Complete/{0}/{1}?approved={2}";


export const pathGetRegionalHomepageAdPage = "/regionalhomepages/GetSystemAdPage/{0}/{1}";



//----------------------------------------------------------------------------- Adminco
export const pathGetUiAdminConsole = "/ui/GetUiAdminConsole";
//----------------------------------------------------------------------------- Administrator
export const getAdministratorUi = "/admin/GetAdministratorUi";
export const pathGetUiAdministratorConsole = "/ui/GetUiAdministratorConsole";
//----------------------------------------------------------------------------- Administrator. Dictionaries
export const pathGetDictionaryDrafts = "/DictionaryDrafts/GetList";
export const pathGetDictionaryDraft = "/DictionaryDrafts/Get/{0}";
export const pathUpdateDictionaryDraft = "/DictionaryDrafts/Update/{0}";
export const pathPublishDictionaryDraft = "/DictionaryDrafts/Publish/{0}";
export const pathArchiveDictionaryDraft = "/DictionaryDrafts/Archive/{0}";
export const pathGetCultures = "/DictionaryDrafts/GetCultures";
export const pathGetRegions = "/DictionaryDrafts/GetRegions";
//----------------------------------------------------------------------------- Administrator. Business Tags
export const pathGetSubDictionariesList = "/SubDictionaries/GetList";
export const pathGetNewSubDictionaryDraft = "/SubDictionaries/GetNew";
export const pathSaveSubDictionaryDraft = "/SubDictionaries/Update/{0}";
export const pathGetSubDictionaryDraft = "/SubDictionaries/Get/{0}";
export const pathDeleteSubDictionaryDraft = "/SubDictionaries/Delete/{0}";
export const pathPublishSubDictionaryDraft = "/SubDictionaries/Publish/{0}";
export const pathGetBusinessTagsDictionaryDraft = "/BusinessTagsDictionaryDraft/Get";
export const pathSaveBusinessTagsDictionaryDraft = "/BusinessTagsDictionaryDraft/Update";
export const pathDeleteBusinessTagsDictionaryDraft = "/BusinessTagsDictionaryDraft/Delete";
export const pathPublishBusinessTagsDictionaryDraft = "/BusinessTagsDictionaryDraft/Publish";
//----------------------------------------------------------------------------- Administrator. Users
export const getStaffUserByEmail = "/admin/GetStaffUserByEmail/{0}";
export const getStaffUsersByRole = "/admin/GetStaffUsersByRole/{0}";
export const getStaffUserById = "/admin/GetStaffUserById/{0}";
export const assignUserRole = "/admin/AssignUserRole/{0}/{1}";
export const revokeUserRole = "/admin/RevokeUserRole/{0}/{1}";
export const patchStaffUser = "/admin/PatchStaffUser/{0}";
//----------------------------------------------------------------------------- Administrator. Documents
export const pathGetListDocuments = "/DocumentDrafts/GetList";
export const pathCreateDocumentDraft = "/DocumentDrafts/Create";
export const pathCreateDocumentTranslation = "/DocumentDrafts/Translate/{0}/{1}/{2}";
export const pathGetDocumentDraft = "/DocumentDrafts/Get/{0}/{1}";
export const pathDeleteDocumentDraft = "/DocumentDrafts/Delete/{0}/{1}";
export const pathUpdateDocumentDraft = "/DocumentDrafts/Update";
export const pathSubmitDocumentDraft = "/DocumentDrafts/Submit/{0}/{1}";
export const pathPublishDocumentDraft = "/DocumentDrafts/Publish/{0}/{1}";


export const getNewCategoryRequests = "/NewCategoryRequest/GetNewCategoryRequests";
export const pathGetApiKeys = "/apiKeys/get";
export const pathUpdateApiKey = "/apiKeys/update?isNew={0}";
export const pathGetApiKey = "/apiKeys/get/{0}";
//----------------------------------------------------------------------------- Moderator
export const getModeratorUi = "/admin/GetModeratorUi";
export const pathGetUiModeratorConsole = "/ui/GetUiModeratorConsole";
export const pathGetListCustomerAdRequest = "/requestCustomerAd/GetList";
export const pathGetCustomerAdRequest = "/requestCustomerAd/Get/{0}/{1}";
export const pathCompleteCustomerAdRequest = "/requestCustomerAd/Complete/{0}/{1}?approved={2}";
export const pathGetListDocumentRequests = "/requestDocument/GetList";
export const pathGetRequestDocument = "/requestDocument/Get/{0}/{1}";
export const pathCompleteRequestDocument = "/requestDocument/Complete/{0}/{1}/{2}";

export const approveAdPage = "/admin/ApproveAdPage/{0}/{1}/{2}";
export const rejectAdPage = "/admin/RejectAdPage/{0}/{1}/{2}/{3}";
//----------------------------------------------------------------------------- ContentManager
export const pathGetUiContentManagerConsole = "/ui/GetUiContentManagerConsole";
export const getSystemBusinesses = "/systemContent/GetSystemBusinesses";
export const getSystemBusiness = "/systemContent/GetSystemBusiness/{0}";
export const pathUpdateSystemBusiness = "/systemContent/UpdateSystemBusiness";
export const getNewSystemBusiness = "/systemContent/GetNewSystemBusiness";
export const deleteSystemBusiness = "/systemContent/DeleteSystemBusiness/{0}";
export const pathSendSystemAdPageForApproval = "/systemContent/SendSystemAdPageForApproval/{0}/{1}/{2}";
export const pathActivateSystemAdPage = "/systemContent/activateSystemAdpage/{0}/{1}/{2}";
export const publishSystemPage = "/systemContent/PublishSystemBusinessPage/{0}/{1}/{2}"; // remove
export const deactivateSystemAdPage = "/systemContent/deactivateSystemAdpage/{0}/{1}/{2}";
export const createFirstSystemAdPage = "/systemContent/CreateFirstSystemAdPage/{0}/{1}/{2}";
export const createSystemAdPageCopy = "/systemContent/createSystemAdPageCopy/{0}/{1}/{2}?locale={3}";
export const createSystemBusinessUrl = "/systemContent/CreateSystemBusinessUrl/{0}/{1}";
export const createSystemBusinessUrlWithMasterUrl = "/systemContent/CreateSystemBusinessUrl/{0}/{1}?masterUrl={2}";
export const removeSystemAdPage = "/systemContent/removeSystemAdPage/{0}/{1}/{2}";
//----------------------------------------------------------------------------- System Translator
export const pathGetUiSystemTranslatorConsole = "/ui/GetSystemTranslator";
export const pathGetDictionariesWithTranslations = "/systemTranslator/GetDictionaries";
export const pathGetDictionaryTranslationDrafts = "/systemTranslator/GetDictionaryTranslationDrafts";
export const pathGetDictionaryTranslationDraft = "/systemTranslator/GetDictionaryTranslationDraft/{0}/{1}";
export const pathSaveDictionaryTranslationDraft = "/systemTranslator/UpdateDictionaryTranslationDraft";
export const pathDeleteDictionaryTranslationDraft = "/systemTranslator/DeleteDictionaryTranslationDraft/{0}/{1}";
export const pathPublishDictionaryTranslationDraft = "/systemTranslator/PublishDictionaryTranslationDraft/{0}/{1}";
export const pathWidgetDirectoryDraftGetList = "/widgetDirectoryDraft/GetList";
export const pathWidgetDirectoryDraftGet = "/widgetDirectoryDraft/Get/{0}/{1}";
export const pathWidgetDirectoryDraftUpdate = "/widgetDirectoryDraft/Update";
export const pathWidgetDirectoryDraftDelete = "/widgetDirectoryDraft/Delete/{0}/{1}";
export const pathWidgetDirectoryDraftPublish = "/widgetDirectoryDraft/Publish/{0}/{1}";
export const pathGetListDefaultContentDirectoryDraft = "/contentDirectoryDraft/GetList";
export const pathGetContentDirectoryDraft = "/contentDirectoryDraft/Get/{0}/{1}";
export const pathUpdateContentDirectoryDraft = "/contentDirectoryDraft/Update";
export const pathDeleteContentDirectoryDraft = "/contentDirectoryDraft/Delete/{0}/{1}";
export const pathSubmitContentDirectoryDraft = "/contentDirectoryDraft/Submit/{0}/{1}";
export const pathGetListDocumentsForTranslator = "/TranslatorDocuments/GetList";
export const pathGetDocumentForTranslator = "/TranslatorDocuments/Get/{0}/{1}";
export const pathUpdateDocumentForTranslator = "/TranslatorDocuments/Update";
export const pathPublishDocumentForTranslator = "/TranslatorDocuments/Publish/{0}/{1}";
//---------------------------------------------------------------------- Images
export const getUserImages = "/images/GetUserImages";
export const getUserImageCount = "/images/getUserImageCount";
export const uploadImage = "/Images/uploadImage/{0}";
export const uploadImageBlock = "/Images/uploadImageBlock";
export const getImageLoader = "/Images/getImageLoader/{0}";
export const getPrivateImageUri = "/Images/getPrivateImageUri/{0}";
export const mergeImageBlocks = "/Images/mergeImageBlocks";
export const uploadImageSize = "/Images/uploadImageSize/{0}"; //{EImageSize}
export const addUserImageLibraryItems = "/Images/addUserImageLibraryItems";
export const updateImageLibraryItemsUsage = "/Images/updateImageLibraryItemsUsage";
export const deleteImage = "/Images/deleteImage/{0}/{1}";
//----------------------------------------------------------------------------- HealthCheck
export const getHealthStatus = "/HealthStatus/getHealthStatus";
export const getStorageHealthStatus = "/HealthStatus/getStorageHealthStatus";
export const getB2cHealthStatus = "/HealthStatus/getB2cHealthStatus";
export const getCosmosDbHealthStatus = "/HealthStatus/getCosmosDbHealthStatus";
//----------------------------------------------------------------------------- Locks
export const createLock = "/admin/lockObject/{0}/{1}/{2}";
export const releaseLock = "/admin/ReleaseLock/{0}";
//----------------------------------------------------------------------------- email
export const sendMailUtil = "/Email/sendmail";
//----------------------------------------------------------------------------- Design templates
export const updateAdDesignTemplate = "/adDesignTemplates/Apply/{0}/{1}";
//----------------------------------------------------------------------------- Tracker
export const pathGetUserBusinessesStats = "/Tracker/GetUserPageViews/{0}/{1}";
//----------------------------------------------------------------------------- Messages
export const pathGetUserMessages = "/Messages/Get";
export const pathGetUserUnreadMessageCount = "/Messages/GetCount";
export const pathUpdateUserMessages = "/Messages/Update";