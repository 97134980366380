import { useState } from 'react';
import CombineStyles from '../../../../utils/combineStyles';
import Icon from '../../../common/icon/Icon';
import { IUiMenuContent } from '../../../common/options/menus/IUiMenuContent';
import { ClassUserMessage } from '../../Messages';
import styles from './MessageHeader.module.css';
import PopupMenu from '../../../common/popup-v2/popup-menu/PopupMenu';
import { getTUiMenuContent } from '../../../common/menu/menu-content/TUiMenuContent';

interface IProps {
  ui?: IUiMenuContent;
  data: ClassUserMessage;
  onClick: () => void;
  onOptionSelect: (optionId: string) => void;
}

export default function MessageHeader(props: IProps) {
  const [contextMenuOpen, setContextMenuOpen] = useState(false);
  const contextMenuId = `${props.data.id}_menu`;
  //---------------------------------------------------------------------------
  const openContextMenu = () => {
    setContextMenuOpen(true);
  };
  //---------------------------------------------------------------------------
  const closeContextMenu = () => {
    setContextMenuOpen(false);
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (optionId: string) => {
    closeContextMenu();
    props.onOptionSelect(optionId);
  };
  //---------------------------------------------------------------------------
  return (
    <div className={CombineStyles([
      styles.container,
      props.data.isRead ? '' : styles.unread
    ])}>

      <div className={styles.datetime}
        onClick={props.onClick}>
        {props.data.created.toLocaleString()/* {2024.01.05 12:00} */}
      </div>

      <div className={styles.subject}
        onClick={props.onClick}>
        {props.data.getSubject()}
      </div>

      <div id={contextMenuId} className={styles.option}
        onClick={openContextMenu}>
        <Icon imageName='kebabButton' />
      </div>

      {props.ui && contextMenuOpen &&
        <PopupMenu
          anchorId={contextMenuId}
          ui={getTUiMenuContent(props.ui)}
          displayIcon={true}
          onSelect={onOptionSelect}
          onClose={closeContextMenu}
        />}

    </div>
  );
}