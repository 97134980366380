import styles from './PageViewCardRow.module.css';
import { ReactNode, useState } from "react";
import { TogglerWithCaption } from "../../../../common/toggler/TogglerWithCaption";
import { BusinessStatNumbers } from '../BusinessStatNumbers';

//--------------------------------------------------------------------------
interface IProps {
  text: string;
  views?: number;
  points?: number;
  children?: ReactNode;
}
//--------------------------------------------------------------------------
export function PageViewCardRow(props: IProps) {
  const [collapsed, setCollapsed] = useState(true);
  //------------------------------------------------------------------------
  const onClick = () => {
    setCollapsed(!collapsed);
  }
  //------------------------------------------------------------------------
  let children = props.children;
  //------------------------------------------------------------------------
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <TogglerWithCaption
          caption={props.text}
          showToggler={children != undefined}
          collapsed={collapsed}
          onClick={onClick}
          togglerPosition={"Left"}
        />
        {props.views != undefined && props.points != undefined &&
          <BusinessStatNumbers
            views={props.views}
            points={props.points}
          />
        }
      </div>
      {!collapsed && children != undefined &&
        <div className={styles.children}>
          {children}
        </div>
      }
    </div>
  );
}