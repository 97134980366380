import { EditFormSection } from '../../../common/console-layout/edit-form-sections/EditFormSection';
import styles from './SectionBranding.module.css';
import { useContext, useState } from 'react';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import { IUiSectionBranding } from './IUiSectionBranding';
import GetHint from '../../../common/functions/GetHint';
import InputText from '../../../common/input-v2/input-text/InputText';
import InputsGroupWrapper from '../../../common/input-v2/inputs-group-wrapper/InputsGroupWrapper';
import { BusinessContext } from '../../../../context/business-context/BusinessContextProvider';
import { ClassCustomerBusiness, ICustomerBusiness } from '../ICustomerBusiness';
import { IBusinessPaths } from '../../../../context/business-context/IBusiness';
import { useAbortController } from '../../../../hooks/useAbortController';
import { deleteBusinessAsync } from '../../functions/deleteBusinessAsync';
import { AppAuthContextStore } from '../../../../context/app-auth-context/AppAuthContext';
import { useMsal } from '@azure/msal-react';
import useNavigateWithContext from '../../../../hooks/useNavigateWithContext';
import { pathBusinessConsole, pathBusinesses } from '../../business-console/BusinessConsole';
import WikiSpinner from '../../../app-layout/spinner/wikiSpinner';
import { TImageData } from '../../../common/image-editor/TImageData';
import InputImage from '../../../common/input-v2/input-image/InputImage';

interface IProps {
  id: string;
  ui: IUiSectionBranding;
}

export default function SectionBranding(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { appUiContext } = useContext(AppUiContextStore);
  const [isLoading, setIsLoading] = useState(false);
  const { businessContext, businessContextDispatch } = useContext(BusinessContext);
  const abortController = useAbortController("BusinessEditor");
  const navigate = useNavigateWithContext();
  //---------------------------------------------------------------------------
  let business = businessContext.businessState?.business as ClassCustomerBusiness;
  let isDeletable = false;
  const readOnlyAds = businessContext.ads?.find(ad => ad.status.isReadonly);
  if (!readOnlyAds) isDeletable = true;
  //--------------------------------------------------------------------------- Popup test
  // const [tmpPopupOpen, setTmpPopupOpen] = useState(false);
  // const [tmpPopupFullWindow, setTmpPopupFullWindow] = useState(false);
  // const [tmpPopupFullWindowContent, setTmpPopupFullWindowContent] = useState(false);
  //---------------------------------------------------------------------------
  const onUpdateName = (updatedBusinessName: string, isValid: boolean) => {
    if (!business)
      return;
    business.name = updatedBusinessName;
    businessContextDispatch({ type: "UpdateBusiness", business, isValid });
    // NOTE: for now we do not update business name if it's not valid (instead of showing validation messages)
    //const businessName = business.name;
    // if (updatedBusinessName != businessName) {
    //   const updatedBusiness: Business = new Business(commonBusinessContext.businessContext?.business, { name: updatedBusinessName });
    //   commonBusinessContextDispatch({ type: "UpdateBusiness", value: updatedBusiness });
    // };
  };
  //---------------------------------------------------------------------------
  const onLogoUpdate = (imageData?: TImageData) => {
    if (!business)
      return;
    business.removedLogo = !imageData ? business.logo : undefined;
    business.logo = imageData;
    businessContextDispatch({ type: "UpdateBusiness", business });
  };
  //---------------------------------------------------------------------------
  const onUpdateBusinessPath = (updatedValue: IBusinessPaths) => {
    if (!businessContext.businessState) return;
    console.log(updatedValue)
    const masterPath = updatedValue.pathList && updatedValue.pathList.length > 0 ? updatedValue.pathList[0] : "";
    const updatedPaths: IBusinessPaths = {
      masterPath: masterPath,
      pathList: [masterPath]
    };
    const updatedBusiness = new ClassCustomerBusiness({
      ...business as ICustomerBusiness,
      paths: updatedPaths
    });
    if (businessContext.businessState.isNew) {
      businessContextDispatch({ type: "UpdateBusiness", business: updatedBusiness });
    } else {
      // We set business as saved object, because business is already updated
      businessContextDispatch({ type: "SetBusiness", business: updatedBusiness });
    };
  };
  //---------------------------------------------------------------------------
  const deleteBusiness = () => {
    if (!businessContext.businessState) return;
    setIsLoading(true);
    let controller = abortController.newController("saveBusiness");
    const businessToSave = businessContext.businessState.business as ClassCustomerBusiness;
    deleteBusinessAsync({
      msalInstanse: instance,
      authContext: appAuthContext,
      businessId: business.id,
      abortSignal: controller.signal
    }).then(response => {
      // Clear business context
      businessContextDispatch({ type: "ClearBusinessContext" });
      //-----------------------------------------------------------------------
      // Navigate to business list
      navigate(`${pathBusinessConsole}/${businessContext.userRole}/${pathBusinesses}`);
    }).catch(error => {
      console.error(`saveBusiness:`, error);
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  return (
    <EditFormSection
      id={props.id}
      caption={GetHint(appUiContext, props.ui.id, props.ui.hint)}
    >
      <div className={styles.container}>
        <WikiSpinner show={isLoading} />

        {business &&
          <InputsGroupWrapper>

            <InputText
              ui={{ ...props.ui.inputBusinessId, disabled: true }}
              data={business.id}
            />

            <InputText
              ui={props.ui.inputBusinessName}
              data={business.name}
              isFocused={true}
              onUpdate={onUpdateName}
            />

            {/* {props.ui.inputListBusinessPath && business &&
              <InputListBusinessPath
                ui={props.ui.inputListBusinessPath}
                business={business}
                businessIsNew={businessContext.businessState?.isNew}
                onUpdate={onUpdateBusinessPath}
              />} */}

            {business &&
              <InputImage
                ui={{ ...props.ui.inputBusinessLogo, imageEditor: props.ui.imageEditor }}
                data={business.logo}
                removedImage={business.removedLogo}
                objectId={business.id}
                useExternalLayout={true}
                stretch={false}
                hideHint={true}
                hideCaption={false}
                onImageUpdate={onLogoUpdate}
              />}

          </InputsGroupWrapper>}

        {isDeletable &&
          <button onClick={deleteBusiness}>
            Delete
          </button>}

        {/* <button onClick={() => setTmpPopupOpen(true)}>Open Popup</button>
        {tmpPopupOpen &&
          <PopupDialog
            id="tmpPopup"
            header="Tmp Dialog"
            isModal={true}
            fullWindow={tmpPopupFullWindow}
            fullWindowContent={tmpPopupFullWindowContent}
            isDraggable={false}
            animation="SlideFromRight"
            onClose={() => setTmpPopupOpen(false)}
          >
            <div style={{ padding: '1em', display: 'flex', flexFlow: 'column', gap: '1em' }}>
              <button onClick={() => setTmpPopupFullWindow(true)}>Set Full Window</button>
              <button onClick={() => setTmpPopupFullWindow(false)}>Exit Full Window</button>
              <br></br>
              <button onClick={() => setTmpPopupFullWindowContent(true)}>Set Full Window Content</button>
              <button onClick={() => setTmpPopupFullWindowContent(false)}>Exit Full Window Content</button>
            </div>
          </PopupDialog>} */}

      </div>

    </EditFormSection>
  );
}