import React, { useEffect, useState } from 'react';
import { IAdModuleParagraph } from '../../../../common/ad-modules/IAdModule';
import ChecklistItemWrapper from '../checklist-item-wrapper/ChecklistItemWrapper';
import ChecklistItemText from '../checklist-item-text/ChecklistItemText';
import ChecklistItemImage from '../checklist-item-image/ChecklistItemImage';
import { IContentUnitText } from '../../../../common/content/content-text/IContentUnitText';
import { IContentUnitImage } from '../../../../common/content/content-image/IContentUnitImage';
import { ClassContentUnitStatus } from '../../../../common/content/IContentUnit';
//import styles from './ChecklistItemParagraph.module.css';

interface IProps {
  data: IAdModuleParagraph;
  level?: number;
  isReadonly: boolean;
  isCollapsed: boolean;
  onHover?: (path: string[]) => void;
  onReview?: (updatedParagraph: IAdModuleParagraph) => void;
}

export default function ChecklistItemParagraph(props: IProps) {
  if (!props.data.status) return null;
  const [collapsed, setCollapsed] = useState(true);
  //---------------------------------------------------------------------------
  useEffect(() => {
    setCollapsed(props.isCollapsed);
  }, [props.isCollapsed]);
  //---------------------------------------------------------------------------
  const onToggle = () => {
    setCollapsed(!collapsed);
  };
  //---------------------------------------------------------------------------
  const onHover = (isHovered: boolean) => {
    if (!props.onHover) return;
    const path: string[] = isHovered ? [props.data.id] : [];
    props.onHover(path);
  };
  //---------------------------------------------------------------------------
  const onTextHover = (isHovered: boolean) => {
    if (!props.onHover) return;
    if (!props.data.text) return;
    const path: string[] = isHovered ? [props.data.id, props.data.text.type] : [];
    props.onHover(path);
  };
  //---------------------------------------------------------------------------
  const onImageHover = (isHovered: boolean) => {
    if (!props.onHover) return;
    if (!props.data.image) return;
    const path: string[] = isHovered ? [props.data.id, props.data.image.type] : [];
    props.onHover(path);
  };
  //---------------------------------------------------------------------------
  const onTextReview = (updatedTextUnit: IContentUnitText) => {
    if (!props.onReview) return;
    // if (!props.data.status) return;
    // if (!updatedTextUnit.status) return;
    // let paragraphStatus = props.data.status;
    // const imageStatus = props.data.image?.status ? props.data.image.status : new ClassContentUnitStatus("Approved");
    // if (updatedTextUnit.status.isCompleted && imageStatus.isCompleted) {
    //   const isApproved = updatedTextUnit.status.isApproved && imageStatus.isApproved;
    //   if (isApproved) {
    //     paragraphStatus = new ClassContentUnitStatus("Approved");
    //   } else {
    //     paragraphStatus = new ClassContentUnitStatus("Rejected");
    //   }
    // };
    const updatedParagraph: IAdModuleParagraph = {
      ...props.data,
      text: updatedTextUnit,
      //status: paragraphStatus
    };
    props.onReview(updatedParagraph);
  };
  //---------------------------------------------------------------------------
  const onImageReview = (updatedImageUnit: IContentUnitImage) => {

  };
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>
      <ChecklistItemWrapper
        unitType="ContentParagraph"
        level={props.level}
        status={props.data.status}
        isGroup={true}
        isReadonly={true} // NOTE: for now we can't review ad module as such (only its contents)
        onHover={onHover}
        caption={"Paragraph"}
        isCollapsed={true}
        onToggle={onToggle}
      />

      {/* Paragraph Text */}
      {props.data.text && !collapsed &&
        <ChecklistItemText
          data={props.data.text}
          level={2}
          isReadonly={props.isReadonly}
          onHover={onTextHover}
          onReview={onTextReview}
        />}

      {/* Paragraph Image */}
      {props.data.image && !collapsed &&
        <ChecklistItemImage
          data={props.data.image}
          level={2}
          isReadonly={props.isReadonly}
          onHover={onImageHover}
          onReview={onImageReview}
        />}

    </React.Fragment>
  );
}