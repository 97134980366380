import { useEffect, useState } from 'react';
import InlineFormWrapper from '../../../../../common/forms/inline-form-wrapper/InlineFormWrapper';
import { IUiOption } from '../../../../../common/options/IUiOption';
import { IUiEditformDocumentItem } from './IUiEditformDocumentItem';
import { DocumentDraftItem, EDocumentItemType } from '../IDocumentDraftItem';
import InputDropdown from '../../../../../common/input-v2/input-dropdown/InputDropdown';
import { IUiDictionaryItem } from '../../../../../common/dictionaries/IUiDictionaryItem';
import InputText from '../../../../../common/input-v2/input-text/InputText';
import InputCheckBox from '../../../../../common/input-v2/input-checkbox/InputCheckBox';
import InputImage from '../../../../../common/input-v2/input-image/InputImage';
import { TImageData } from '../../../../../common/image-editor/TImageData';
import { EditFormStateV2 } from '../../../../../common/edit-form/IEditFormStateV2';
import { IContentUnitText } from '../../../../../common/content/content-text/IContentUnitText';
import { ClassContentUnitStatus } from '../../../../../common/content/IContentUnit';
import { IContentUnitImage } from '../../../../../common/content/content-image/IContentUnitImage';

interface IProps {
  ui: IUiEditformDocumentItem;
  draftId: string;
  data: DocumentDraftItem;
  isReadonly: boolean;
  onUpdate: (updatedItem: DocumentDraftItem) => void;
  onClose: () => void;
}

export default function EditFormDocumentItem(props: IProps) {
  const [editorState, setEditorState] = useState<EditFormStateV2<DocumentDraftItem>>();
  const typeOptions = ["Section", "Paragraph"].map(type => ({
    id: type,
    name: { en: type }
  } as IUiDictionaryItem));
  //---------------------------------------------------------------------------
  useEffect(() => {
    const newState = new EditFormStateV2<DocumentDraftItem>({
      object: props.data
    });
    setEditorState(newState);
  }, [props.data]);
  //---------------------------------------------------------------------------
  const setUpdatedState = (updatedObject: DocumentDraftItem) => {
    if (!editorState?.object) return;
    const updatedState = new EditFormStateV2<DocumentDraftItem>(editorState, {
      object: updatedObject
    });
    setEditorState(updatedState);
  };
  //---------------------------------------------------------------------------
  const onTypeUpdate = (optionId?: string) => {
    if (!editorState?.object) return;
    const updatedItem = new DocumentDraftItem({
      ...editorState.object,
      type: optionId as EDocumentItemType
    });
    if (editorState.object.original) {
      if (editorState.object.original.type !== updatedItem.type)
        updatedItem.isEdited = true;
      else
        updatedItem.isEdited = false;
    };
    setUpdatedState(updatedItem);
  };
  //---------------------------------------------------------------------------
  const onIsNumberedUpdate = (updatedValue: boolean) => {
    if (!editorState?.object) return;
    const updatedItem = new DocumentDraftItem({
      ...editorState.object,
      isNumbered: updatedValue,
      isMarked: updatedValue ? false : editorState.object.isMarked
    });
    if (editorState.object.original) {
      if (editorState.object.original.isNumbered !== updatedItem.isNumbered)
        updatedItem.isEdited = true;
      else
        updatedItem.isEdited = false;
    };
    setUpdatedState(updatedItem);
  };
  //---------------------------------------------------------------------------
  const onIsMarkedUpdate = (updatedValue: boolean) => {
    if (!editorState?.object) return;
    const updatedItem = new DocumentDraftItem({
      ...editorState.object,
      isMarked: updatedValue,
      isNumbered: updatedValue ? false : editorState.object.isNumbered
    });
    if (editorState.object.original) {
      if (editorState.object.original.isMarked !== updatedItem.isMarked)
        updatedItem.isEdited = true;
      else
        updatedItem.isEdited = false;
    };
    setUpdatedState(updatedItem);
  };
  //---------------------------------------------------------------------------
  const onHeaderUpdate = (updatedValue: string) => {
    if (!editorState?.object) return;
    const updatedHeader: IContentUnitText = editorState.object.header ? {
      ...editorState.object.header as IContentUnitText,
      text: updatedValue
    } : {
      type: "Header",
      text: updatedValue,
      status: new ClassContentUnitStatus("Draft")
    };
    const updatedItem = new DocumentDraftItem({
      ...editorState.object,
      header: updatedHeader
    });
    if (editorState.object.original) {
      if (editorState.object.original.header?.text !== updatedItem.header?.text)
        updatedItem.isEdited = true;
      else
        updatedItem.isEdited = false;
    };
    setUpdatedState(updatedItem);
  };
  //---------------------------------------------------------------------------
  const onTextUpdate = (updatedValue: string) => {
    if (!editorState?.object) return;
    const updatedText: IContentUnitText = editorState.object.text ? {
      ...editorState.object.text as IContentUnitText,
      text: updatedValue
    } : {
      type: "Text",
      text: updatedValue,
      status: new ClassContentUnitStatus("Draft")
    };
    const updatedItem = new DocumentDraftItem({
      ...editorState.object,
      text: updatedText
    });
    if (editorState.object.original) {
      if (editorState.object.original.text?.text !== updatedItem.text?.text)
        updatedItem.isEdited = true;
      else
        updatedItem.isEdited = false;
    };
    setUpdatedState(updatedItem);
  };
  //---------------------------------------------------------------------------
  const onImageUpdate = (updatedImageData?: TImageData) => {
    if (!editorState?.object) return;
    const updatedImage: IContentUnitImage | undefined = updatedImageData ? {
      ...editorState.object.image as IContentUnitImage,
      type: "Image",
      imageData: updatedImageData
    } : undefined;
    const updatedItem = new DocumentDraftItem({ ...editorState.object, image: updatedImage });
    if (editorState.object.original) {
      if (editorState.object.original.image?.imageData !== updatedItem.image?.imageData)
        updatedItem.isEdited = true;
      else
        updatedItem.isEdited = false;
    };
    setUpdatedState(updatedItem);
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (optionId: string) => {
    switch (optionId) {
      case "OptionClose":
      case "OptionCancel":
        props.onClose();
        break;
      case "OptionOk":
        if (!editorState?.object) return;
        props.onUpdate(editorState.object);
        break;
    };
  };
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  props.ui.menuContent.options.forEach(option => {
    switch (option.id) {
      case "OptionClose":
        if (props.isReadonly)
          updatedOptions.push({ ...option, iconId: "close" });
        break;
      case "OptionCancel":
        if (!props.isReadonly)
          updatedOptions.push({ ...option, iconId: "close" });
        break;
      case "OptionOk":
        const isDisabled = !editorState?.isValid || !editorState?.isUpdated;
        updatedOptions.push({
          ...option,
          iconId: "check",
          isDisabled: isDisabled,
          isDefault: !isDisabled
        });
        break;
    };
  });
  //---------------------------------------------------------------------------
  console.log("props.data.id:", props.data.id);
  //---------------------------------------------------------------------------
  return (
    <InlineFormWrapper
      ui={{ ...props.ui.menuContent, options: updatedOptions }}
      isUpdated={false}
      onSelect={onOptionSelect}
      onClose={props.onClose}
    >
      <InputDropdown
        ui={{ ...props.ui.inputType, disabled: props.data.type === "Title" }}
        data={editorState?.object?.type}
        options={typeOptions}
        hideOptionDescription={true}
        onUpdate={onTypeUpdate}
      />

      {editorState?.object?.type !== "Title" &&
        <InputCheckBox
          ui={props.ui.inputIsNumbered}
          data={editorState?.object?.isNumbered}
          onChange={onIsNumberedUpdate}
        />}

      {editorState?.object?.type === "Paragraph" &&
        <InputCheckBox
          ui={props.ui.inputIsMarked}
          data={editorState?.object?.isMarked}
          onChange={onIsMarkedUpdate}
        />}

      {editorState?.object?.type !== "Paragraph" &&
        <InputText
          ui={props.ui.inputHeader}
          data={editorState?.object?.header?.text}
          onUpdate={onHeaderUpdate}
        />}

      <InputText
        ui={props.ui.inputText}
        linesCount={2}
        data={editorState?.object?.text?.text}
        onUpdate={onTextUpdate}
      />

      {editorState?.object?.type == "Paragraph" && //props.ui.imageEditor &&
        <InputImage
          ui={{...props.ui.inputImage, imageEditor: props.ui.imageEditor}}
          data={editorState.object.image?.imageData}
          objectId={props.draftId}
          useExternalLayout={true}
          hideCaption={false}
          hideHint={false}
          stretch={false}
          onImageUpdate={onImageUpdate}
        />}

    </InlineFormWrapper>
  );
}