import React, { useContext, useEffect } from "react";
import { Route, Routes } from "react-router";
import ImageEditForm from "./image-library/image-edit-form/ImageEditForm";
import ImageLibrary from "./image-library/ImageLibrary";
import { IUiUserImages } from "./IUiUserImages";
import { AppUiContextStore } from "../../../context/app-ui-context/AppUiContextProvider";
import { TUiBreadcrumb } from "../../common/breadcrumbs-trail/breadcrumb/TUiBreadcrumb";
import { pathUserConsole, pathUserImages, pathUserProfile } from "../UserConsole";
import { TUiMenuContent } from "../../common/menu/menu-content/TUiMenuContent";
import { TUiMenuOption } from "../../common/menu/menu-content/menu-option/TUiMenuOption";
import ConsoleLayout from "../../common/console-layout/ConsoleLayout";
import { IUiImageLibrary } from "./image-library/IUiImageLibrary";
import NavigateWithContext from "../../common/navigate-with-context/NavigateWithContext";
import { ImageEditorContextProvider } from "../../common/image-editor/image-editor-context/ImageEditorContextProvider";

export const pathImageLibrary = "library";

interface IProps {
  ui?: IUiUserImages;
}

export function UserImages(props: IProps) {
  const { appUiContextDispatch } = useContext(AppUiContextStore);
  //--------------------------------------------------------------------------- Breadcrumb
  useEffect(() => {
    console.log(props.ui)
    if (props.ui?.breadcrumb) {
      appUiContextDispatch({ type: "UpdateBreadcrumbsTrail", value: props.ui.breadcrumb });
    };
  }, []);
  //--------------------------------------------------------------------------- Breadcrumbs for child routes
  let breadcrumbImageLibrary: TUiBreadcrumb = {
    id: "UserImages.MenuContent.ImagesLibrary",
    caption: undefined,
    path: `/${pathUserConsole}/${pathUserImages}/${pathImageLibrary}`
  };
  //--------------------------------------------------------------------------- Update menu content
  let updatedMenuContent: TUiMenuContent | undefined = undefined;
  if (props.ui?.menuContent) {
    const mc = props.ui?.menuContent;
    const updatedOptions: TUiMenuOption[] = [];
    mc.options.forEach(option => {
      switch (option.id) {
        case "UserImages.MenuContent.ImagesLibrary":
          breadcrumbImageLibrary.caption = option.caption;
          updatedOptions.push({
            id: option.id,
            directoryId: option.directoryId,
            elementType: option.elementType,
            caption: option.caption,
            hint: option.hint,
            iconFile: "userImagesLibrary",
            isDefault: true,
            action: `/${pathUserConsole}/${pathUserProfile}/${pathUserImages}`,
            visible: true,
            disabled: false,
            index: 0,
            priorityLevel: 0,
            canHideCaption: false
          });
          break;
      };
    });
    //-------------------------------------------------------------------------
    updatedMenuContent = {
      id: mc.id,
      directoryId: mc.directoryId,
      elementType: mc.elementType,
      options: updatedOptions,
      visible: true,
      disabled: false,
      optionGroups: []
    };
  };
  //---------------------------------------------------------------------------
  return (
    <ImageEditorContextProvider imageEditorUi={props.ui?.imageEditor}>
      {updatedMenuContent &&
        <ConsoleLayout
          ui={{ header: "", menuContent: updatedMenuContent }}
          path={pathUserImages}
        >
          <Routes>

            <Route
              index
              element={<NavigateWithContext to={`/${pathUserConsole}/${pathUserImages}/${pathImageLibrary}`} />}
            />

            <Route
              path={`/${pathImageLibrary}/*`}
              element={
                <ImageLibrary ui={{
                  ...props.ui?.imageLibrary as IUiImageLibrary,
                  breadcrumb: breadcrumbImageLibrary,
                  imageEditor: props.ui?.imageEditor
                }} />}
            />

            {/* <Route path={`/:imageId`} element={
              <ImageEditForm ui={props.ui?.editFormUserImage} />}
            /> */}

          </Routes>
        </ConsoleLayout>}
    </ImageEditorContextProvider>
  );
}
