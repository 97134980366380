import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../../context/app-auth-context/AppAuthContext";
import { IDocumentRequest } from "./IDocumentRequest";
import { apiBasePath, pathGetRequestDocument } from "../../../../../utils/apiPathConstant";
import { stringFormatter } from "../../../../../utils/common";
import { apiGetPrivate, checkResponse } from "../../../../../utils/api";

interface IProps {
  msalInstanse: IPublicClientApplication;
  authConfig?: IAuthConfig;
  abortSignal?: AbortSignal;
  requestId?: string;
  localeId?: string;
}

export async function getDocumentRequestAsync(props: IProps): Promise<IDocumentRequest> {
  //---------------------------------------------------------------------------
  if (!props.authConfig) throw new Error("getDocumentRequestAsync: auth config is not provided");
  if (!props.requestId) throw new Error("getDocumentRequestAsync: request Id is not provided");
  if (!props.localeId) throw new Error("getDocumentRequestAsync: request Id is not provided");
  //---------------------------------------------------------------------------
  const uri = `${apiBasePath}${stringFormatter(
    pathGetRequestDocument,
    [props.requestId, props.localeId]
  )}`;
  const response = await apiGetPrivate(
    props.msalInstanse,
    props.authConfig,
    uri,
    props.abortSignal
  );
  //---------------------------------------------------------------------------
  checkResponse(response, "getDocumentRequestAsync", props.abortSignal?.aborted);
  const request: IDocumentRequest = response?.content;
  return request;
}