import { useContext, useEffect, useState } from 'react';
import { useAbortController } from '../../../../../hooks/useAbortController';
import { useMsal } from '@azure/msal-react';
import { AppAuthContextStore } from '../../../../../context/app-auth-context/AppAuthContext';
import { NotificationContext } from '../../../../../context/notification-context/NotificationContextProvider';
import { useParams } from 'react-router-dom';
import { IDirectoryEditorStateInitial } from '../../../../../context/directory-editor-context/IDirectoryEditorState';
import { pathAdminConsole, pathAdministratorConsole } from '../../../AdminConsole';
import { pathDocuments } from '../../AdministratorConsole';
import { directoryDraftStatesFullDetailsV2 } from '../../../../../context/directory-editor-context/directory-editor-settings/directoryDraftStatesFullDetailsV2';
import { DirectoryEditorContext } from '../../../../../context/directory-editor-context/DirectoryEditorContextProvider';
import DirectoryEditorWrapper from '../../../common/directory-editor/directory-editor-wrapper/DirectoryEditorWrapper';
import DocumentItemNode from './document-item-node/DocumentItemNode';
import { DocumentDraftItem } from './IDocumentDraftItem';
import EditFormDocumentItem from './document-item-editform/EditFormDocumentItem';
import { IDirectoryDraftAbstract } from '../../../../../context/directory-editor-context/IDirectoryDraftAbstract';
import { IDirectoryDraftItem } from '../../../../../context/directory-editor-context/IDirectoryDraftItem';
import { DocumentDraft, IDocumentDraft } from './IDocumentDraft';
import { TUser } from '../../../../../context/app-auth-context/TUser';
import { IUiDocumentEditor } from './IUiDocumentEditor';
import { getDocumentDraftAsync } from '../functions/getDocumentDraftAsync';
import { submitDocumentDraftAsync } from '../functions/submitDocumentDraftAsync';
import { updateDocumentDraftAsync } from '../functions/updateDocumentDraftAsync';
import PopupMessage from '../../../../common/popup-v2/popup-message/PopupMessage';
import { getTUiMenuContent } from '../../../../common/menu/menu-content/TUiMenuContent';
import GetCaption from '../../../../common/functions/GetCaption';
import { AppUiContextStore } from '../../../../../context/app-ui-context/AppUiContextProvider';
import GetHint from '../../../../common/functions/GetHint';
import DocumentViewerPopup from '../../../../document-viewer/document-viewer-popup/DocumentViewerPopup';
import { IUiOption } from '../../../../common/options/IUiOption';
import { deleteDocumentDraftAsync } from '../functions/deleteDocumentDraftAsync';
import useNavigateWithContext from '../../../../../hooks/useNavigateWithContext';
import { ImageEditorContext } from '../../../../common/image-editor/image-editor-context/ImageEditorContextProvider';

interface IProps {
  ui?: IUiDocumentEditor;
}

export default function DocumentEditor(props: IProps) {
  const { documentId, localeId } = useParams();
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { appUiContext } = useContext(AppUiContextStore);
  const { directoryEditorContext, directoryEditorContextDispatch } = useContext(DirectoryEditorContext);
  const { notificationContextDispatch } = useContext(NotificationContext);
  const { imageEditorContext } = useContext(ImageEditorContext);
  const abortController = useAbortController("DocumentEditor");
  const navigate = useNavigateWithContext();
  const [isLoading, setIsLoading] = useState(false);
  const [newRootItem, setNewRootItem] = useState<DocumentDraftItem>();
  const [editConfirmationOpen, setEditConfirmationOpen] = useState(false);
  const [previewPopupOpen, setPreviewPopupOpen] = useState(false);
  const user: TUser | undefined = appAuthContext.user ? appAuthContext.user : undefined;
  const basePath = `/${pathAdminConsole}/${pathAdministratorConsole}/${pathDocuments}`;
  const stateGroups = directoryDraftStatesFullDetailsV2;
  const documentDraft = directoryEditorContext.editorState?.draft as IDocumentDraft;
  const documentDraftItems = documentDraft?.items;
  const workflowRecord = documentDraft?.log?.getActualRecord();
  //-------------------------Header------Name--------------Badges------ContextMenu
  let gridTemplateColumns = 'min-content minmax(2em, 100%) min-content minmax(2em, 1%)'; // Id, Name, badges, context menu
  //--------------------------------------------------------------------------- Data
  useEffect(() => {
    getDraft(false);
    return (() => {
      abortController.abortOnUnmount();
    });
  }, []);
  //---------------------------------------------------------------------------
  const getDraft = (lockRequired: boolean) => {
    if (!documentId) return;
    if (!localeId) return;
    if (!user) return;
    let controller = abortController.newController("getDocumentDraftAsync");
    setIsLoading(true);
    getDocumentDraftAsync({
      msalInstance: instance,
      authConfig: appAuthContext.config,
      abortSignal: controller.signal,
      documentId,
      localeId,
      lockRequired
    }).then(draft => {
      const initialState: IDirectoryEditorStateInitial = {
        directoryType: "Document",
        basePath: `${basePath}/${documentId}/${localeId}`,
        returnPath: basePath,
        id: draft.id,
        name: draft.title,
        description: draft.description,
        isNew: false,
        editMode: lockRequired && draft.locker?.statusCode == 200,
        draft: draft,
        original: draft.original,
        stateGroups: stateGroups,
        user: user
      };
      directoryEditorContextDispatch({ type: "Initialize", data: initialState });
    }).catch(error => {
      !controller.aborted && notificationContextDispatch({ type: "SetError", header: "Document Editor", message: error });
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const saveDraft = () => {
    if (!user) return;
    let controller = abortController.newController("updateDocumentDraftAsync");
    setIsLoading(true);
    updateDocumentDraftAsync({
      msalInstance: instance,
      authContext: appAuthContext,
      imageEditorContext,
      abortSignal: controller.signal,
      documentDraft: directoryEditorContext.editorState.draft as DocumentDraft
    }).then(draft => {
      const initialState: IDirectoryEditorStateInitial = {
        directoryType: "Document",
        basePath: `${basePath}/${documentId}/${localeId}`,
        returnPath: basePath,
        id: draft.id,
        name: draft.title,
        description: draft.description,
        isNew: false,
        editMode: true,
        draft: draft,
        stateGroups: stateGroups,
        user: user
      };
      directoryEditorContextDispatch({ type: "Initialize", data: initialState });
    }).catch(error => {
      !controller.aborted && notificationContextDispatch({ type: "SetError", header: "Document Editor", message: error });
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const submitDraft = () => {
    if (!documentId) return;
    if (!localeId) return;
    if (!user) return;
    let controller = abortController.newController("submitDocumentDraftAsync");
    setIsLoading(true);
    submitDocumentDraftAsync({
      msalInstance: instance,
      authConfig: appAuthContext.config,
      abortSignal: controller.signal,
      documentId,
      locale: localeId,
      isNew: documentDraft.original ? false : true
    }).then(draft => {
      console.log("Submit draft returned");
      const initialState: IDirectoryEditorStateInitial = {
        directoryType: "Document",
        basePath: `${basePath}/${documentId}/${localeId}`,
        returnPath: basePath,
        id: draft.id,
        name: draft.title,
        description: draft.description,
        isNew: false,
        editMode: false,
        draft: draft,
        stateGroups: stateGroups,
        user: user
      };
      directoryEditorContextDispatch({ type: "Initialize", data: initialState });
    }).catch(error => {
      !controller.aborted && notificationContextDispatch({ type: "SetError", header: "Document Editor", message: error });
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const deleteDraft = () => {
    if (!documentId) return;
    if (!localeId) return;
    if (!user) return;
    let controller = abortController.newController("deleteDocumentDraftAsync");
    setIsLoading(true);
    deleteDocumentDraftAsync({
      msalInstance: instance,
      authConfig: appAuthContext.config,
      abortSignal: controller.signal,
      documentId,
      localeId
    }).then(draft => {
      if (draft) {
        const initialState: IDirectoryEditorStateInitial = {
          directoryType: "Document",
          basePath: `${basePath}/${documentId}/${localeId}`,
          returnPath: basePath,
          id: draft.id,
          name: draft.title,
          description: draft.description,
          isNew: false,
          editMode: true,
          draft: draft,
          stateGroups: stateGroups,
          user: user
        };
        directoryEditorContextDispatch({ type: "Initialize", data: initialState });
      } else {
        navigate(basePath);
      };
    }).catch(error => {
      !controller.aborted && notificationContextDispatch({ type: "SetError", header: "Document Editor", message: error });
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const onPreviewClose = () => {
    setPreviewPopupOpen(false);
  };
  //---------------------------------------------------------------------------
  const updateDraft = (updatedDraft: IDirectoryDraftAbstract, updatedName?: string, updatedDescription?: string) => {
    directoryEditorContextDispatch({
      type: "Update",
      data: {
        draft: updatedDraft,
        name: updatedName,
        description: updatedDescription
      }
    });
    setNewRootItem(undefined);
  };
  //---------------------------------------------------------------------------
  const onItemAdd = (newItem: DocumentDraftItem, position?: number) => {
    if (!directoryEditorContext?.editorState.draft) return;
    let updatedItems: IDirectoryDraftItem[];
    if (position == undefined)
      updatedItems = documentDraftItems.concat(newItem);
    else {
      updatedItems = documentDraftItems.map(item => item);
      updatedItems.splice(position, 0, newItem);
    };
    const updatedDraft = directoryEditorContext.editorState.draft.getUpdated({ items: updatedItems });
    updateDraft(updatedDraft);
  };
  //---------------------------------------------------------------------------
  const onItemUpdate = (updatedItem: DocumentDraftItem) => {
    if (!directoryEditorContext?.editorState.draft) return;
    const updatedItems = documentDraftItems.map(item => item.id == updatedItem.id ? updatedItem : item);
    const updatedDraft = directoryEditorContext.editorState.draft.getUpdated({
      items: updatedItems
    });
    if (updatedItem.type == "Title") {
      updatedDraft.name = updatedItem.header?.text;
      updatedDraft.description = updatedItem.text?.text;
      updateDraft(updatedDraft, updatedDraft.name, updatedDraft.description);
    } else {
      updateDraft(updatedDraft);
    };
  };
  //---------------------------------------------------------------------------
  const onItemDelete = (deletedItem: DocumentDraftItem) => {
    if (!directoryEditorContext?.editorState.draft) return;
    if (deletedItem.original) {
      const updatedItem = new DocumentDraftItem({ ...deletedItem });
      updatedItem.isDeleted = true;
      const updatedItems = documentDraftItems.map(item => item.fullPath == deletedItem.fullPath ? updatedItem : item);
      const updatedDraft = directoryEditorContext.editorState.draft.getUpdated({ items: updatedItems });
      updateDraft(updatedDraft);
    } else {
      const updatedItems = documentDraftItems.filter(item => item.fullPath !== deletedItem.fullPath);
      const updatedDraft = directoryEditorContext.editorState.draft.getUpdated({ items: updatedItems });
      updateDraft(updatedDraft);
    };
  };
  //---------------------------------------------------------------------------
  const onItemRestore = (restoredItem: DocumentDraftItem) => {
    if (!directoryEditorContext?.editorState.draft) return;
    console.log(restoredItem)
    const itemToRestore = new DocumentDraftItem({ ...restoredItem });
    itemToRestore.isDeleted = false;
    const updatedItems = documentDraftItems.map(item => item.fullPath == itemToRestore.fullPath ? itemToRestore : item);
    const updatedDraft = directoryEditorContext.editorState.draft.getUpdated({ items: updatedItems });
    updateDraft(updatedDraft);
  };
  //---------------------------------------------------------------------------
  const onEditConfirmationOptionSelect = (optionId: string) => {
    setEditConfirmationOpen(false);
    switch (optionId) {
      case "OptionOk":
        getDraft(true);
        break;
      case "OptionCancel":
        setEditConfirmationOpen(false);
        break;
    };
  };
  //---------------------------------------------------------------------------
  const onDraftOptionSelect = (optionId: string) => {
    switch (optionId) {
      case "OptionEditModeEnable":
        if (documentDraft.log?.actualRecordId) {
          setEditConfirmationOpen(true);
        } else
          getDraft(true);
        break;
      case "OptionPreview":
        setPreviewPopupOpen(true);
        break;
      case "OptionSave":
        saveDraft();
        break;
      case "OptionDelete":
        deleteDraft();
        break;
      case "OptionAdd":
        const newItem = DocumentDraftItem.getNewItem("Section");
        setNewRootItem(newItem);
        break;
      case "OptionSubmit":
        submitDraft();
        break;
    };
  };
  //---------------------------------------------------------------------------
  let i = 1;
  const documentItems = documentDraft?.items.filter(item => !item.path).map(item => {
    const documentItem = item as DocumentDraftItem;
    let number = '';
    if (documentItem.isNumbered) {
      number = i.toString();
      i++;
    };
    return (
      <DocumentItemNode
        key={documentItem.id}
        numberToDisplay={number}
        ui={props.ui?.node}
        draftId={documentDraft.id}
        data={documentItem}
        isReadonly={!directoryEditorContext.editMode}
        onAdd={onItemAdd}
        onUpdate={onItemUpdate}
        onDelete={onItemDelete}
        onRestore={onItemRestore}
      />
    );
  });
  //---------------------------------------------------------------------------
  let updatedMenuOptions: IUiOption[] = [];
  props.ui?.menuContent.options.forEach(option => {
    switch (option.id) {
      case "OptionSubmit":
        if (!workflowRecord || workflowRecord?.workflowTask.staffRoleName == "Administrator") {
          updatedMenuOptions.push(option);
        };
        break;
      default:
        updatedMenuOptions.push(option);
        break;
    };
  });
  const updatedMenuContent = props.ui ? { ...props.ui.menuContent, options: updatedMenuOptions } : undefined;
  //---------------------------------------------------------------------------
  console.log("directoryEditorContext.lastAction:", directoryEditorContext.lastAction);
  console.log("directoryEditorContext.editorState:", directoryEditorContext.editorState);
  console.log("directoryEditorContext.draft:", directoryEditorContext.editorState?.draft);
  //---------------------------------------------------------------------------
  if (props.ui && updatedMenuContent) return (
    <DirectoryEditorWrapper
      ui={{ ...props.ui, menuContent: updatedMenuContent }}
      gridTemplateColumns={gridTemplateColumns}
      isLoading={isLoading}
      //onSelectSourceLocale={onSelectSourceLocale}
      onOptionSelect={onDraftOptionSelect}
    >

      {documentItems}

      {newRootItem && directoryEditorContext.editMode &&
        <EditFormDocumentItem
          ui={props.ui.node.editForm}
          data={newRootItem}
          draftId={documentDraft.id}
          isReadonly={false}
          onUpdate={onItemAdd}
          onClose={() => setNewRootItem(undefined)}
        />}

      {previewPopupOpen &&
        <DocumentViewerPopup
          ui={props.ui.documentViewer}
          data={documentDraft}
          onClose={onPreviewClose}
        />}

      {editConfirmationOpen &&
        <PopupMessage
          id={props.ui.editConfirmationDialog.id}
          type='Confirmation'
          header={GetCaption(appUiContext, props.ui.editConfirmationDialog.id, props.ui.editConfirmationDialog.caption)}
          message={GetHint(appUiContext, props.ui.editConfirmationDialog.id, props.ui.editConfirmationDialog.hint)}
          optionsMenuContent={getTUiMenuContent(props.ui.editConfirmationDialog.menuContent)}
          show={editConfirmationOpen}
          onOptionSelect={onEditConfirmationOptionSelect}
          onCancel={() => setEditConfirmationOpen(false)}
        />}

    </DirectoryEditorWrapper>
  ); else return null;
}