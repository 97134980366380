import { useMsal } from "@azure/msal-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AppAuthContextStore } from "../../../../context/app-auth-context/AppAuthContext";
import { useAppScreenContext } from "../../../../context/app-screen-context/AppScreenProvider";
import { AppUiContextStore } from "../../../../context/app-ui-context/AppUiContextProvider";
import WikiSpinner from "../../../app-layout/spinner/wikiSpinner";
import { ImageEditorSession } from "../image-editor-context/ImageEditorSession";
import { EImageFlip, EImageRotation, ETransformType } from "../image-editor-history/ImageEditorHistory";
import { ImageEditorPlaceholder } from "../image-editor-placeholder/ImageEditorPlaceholder";
import { ImageCrop, Point, EImageEditorAction, CursorStrips } from "./Crop";
import styles from "./ImageEditorCanvas.module.css";
import { CanvasOverlay, ECropAction } from "./CanvasOverlay";
import { EImageEditorMode } from "../ImageEditorV2";

interface IProps {
  session?: ImageEditorSession,
  placeholderHint?: string;
  mode: EImageEditorMode;
  action: EImageEditorAction,
  animationEnd?: boolean;
  // image select is performed by separate callback,
  // not by EImageEditorAction.SelectImage because of <input> div complications
  onImageSelect?: () => void;
  onBlobReady?: () => void;
}


export default function ImageEditorCanvas(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { appUiContext } = useContext(AppUiContextStore);
  const screenType = useAppScreenContext();
  const backCanvas = useRef<HTMLCanvasElement>(null);
  const [cropIsActive, setCropIsActive] = useState(false);
  const [cropAction, setCropAction] = useState(ECropAction.None);
  const [canvasClientRect, setCanvasClientRect] = useState<DOMRect>();
  //---------------------------------------------------------------------------------------------
  useEffect(() => {
    let observer: ResizeObserver | undefined = undefined;
    if (backCanvas.current) {
      let rect = backCanvas.current?.getBoundingClientRect();
      setCanvasClientRect(rect);
      observer = new ResizeObserver(() => {
        let rect = backCanvas.current?.getBoundingClientRect();
        setCanvasClientRect(rect);
      });
      observer.observe(backCanvas.current);
    }
    return (() => {
      if (observer) {
        observer.disconnect();
      }
    });
  }, [backCanvas.current]);
  //---------------------------------------------------------------------------------------------
  useEffect(() => {
    // reset signal to none
    switch (cropAction) {
      case ECropAction.Confirm:
      case ECropAction.Discard:
        setCropAction(ECropAction.None);
        break;
    }
  }, [cropAction]);
  //---------------------------------------------------------------------------------------------
  useEffect(() => {
    //console.log("Canvas.useEffect[animationEnd]:", props.animationEnd);
    if (props.animationEnd === true) {
      //let rect = backCanvas.current?.getBoundingClientRect();
      //console.log("Canvas.useEffect[animationEnd].backRect", rect);
    }
  }, [props.animationEnd]);
  //---------------------------------------------------------------------------------------------
  //-[props.session?.transform]---------------------------------------------------------
  useEffect(() => {
    let transform = props.session?.transform;
    let cvs = backCanvas.current;
    if (!transform || !cvs)
      return;
    let boundRect = cvs.getBoundingClientRect();
    Point.imageRect = transform.cropRect;
    Point.clientWidth = Math.trunc(boundRect.width);
    Point.clientHeight = Math.trunc(boundRect.height);
    transform.drawImage(cvs, cropIsActive);
  }, [props.session?.transform, cropIsActive]);

  //-[props.mode]--------------------------------------------------------------
  useEffect(() => {
    if (props.mode == EImageEditorMode.Crop) {
      setCropIsActive(true);
    }
  }, [props.mode]);

  //---------------------------------------------------------------------------
  useEffect(() => {
    CursorStrips.screenType = screenType;
  }, [screenType]);

  //-[props.action]------------------------------------------------------------
  useEffect(() => {
    if (props.action == EImageEditorAction.None) {
      return;
    }
    let rotation: EImageRotation | undefined = props.session?.transform?.rotation;
    switch (props.action) {
      case EImageEditorAction.RotateClockwise:
        rotate(true);
        break;
      case EImageEditorAction.RotateCounterclockwise:
        rotate(false);
        break;
      case EImageEditorAction.FlipHorizontal:
        switch (rotation) {
          case EImageRotation.deg0:
          case EImageRotation.deg180:
            flip(EImageFlip.Horizontal);
            break;
          case EImageRotation.deg90:
          case EImageRotation.deg270:
            flip(EImageFlip.Vertical);
            break;
        }
        break;
      case EImageEditorAction.FlipVertical:
        switch (rotation) {
          case EImageRotation.deg0:
          case EImageRotation.deg180:
            flip(EImageFlip.Vertical);
            break;
          case EImageRotation.deg90:
          case EImageRotation.deg270:
            flip(EImageFlip.Horizontal);
            break;
        }
        break;
      case EImageEditorAction.CropStart:
        // setCropIsActive(true); // moved to useEffect[mode]
        break;
      case EImageEditorAction.CropConfirm:
        setCropAction(ECropAction.Confirm);
        break;
      case EImageEditorAction.CropDiscard:
        setCropAction(ECropAction.Discard);
        break;
      case EImageEditorAction.UrlAndBlobSave:
        startSaveChanges();
        break;
    }
  }, [props.action]);
  //---------------------------------------------------------------------------
  const rotate = (clockwise: boolean) => {
    let transform = props.session?.transform;
    if (!transform) {
      return;
    }
    let rotation: EImageRotation = transform.rotation;
    switch (rotation) {
      case EImageRotation.deg0:
        rotation = clockwise ? EImageRotation.deg90 : EImageRotation.deg270;
        break;
      case EImageRotation.deg90:
        rotation = clockwise ? EImageRotation.deg180 : EImageRotation.deg0;
        break;
      case EImageRotation.deg180:
        rotation = clockwise ? EImageRotation.deg270 : EImageRotation.deg90;
        break;
      case EImageRotation.deg270:
        rotation = clockwise ? EImageRotation.deg0 : EImageRotation.deg180;
        break;
    }
    if (rotation == transform.rotation) {
      return;
    }
    props.session?.addNewTransform(transform.newRotation(rotation))
  };
  //---------------------------------------------------------------------------
  const flip = (direction: EImageFlip) => {
    let transform = props.session?.transform;
    if (!transform) {
      return;
    }
    let oldFlip: EImageFlip = transform.flip;
    let newFlip: EImageFlip = oldFlip;
    switch (oldFlip) {
      case EImageFlip.None:
        newFlip = direction;
        break;
      case EImageFlip.Horizontal:
        switch (direction) {
          case EImageFlip.Horizontal:
            newFlip = EImageFlip.None;
            break;
          case EImageFlip.Vertical:
            newFlip = EImageFlip.Both;
            break;
          case EImageFlip.Both:
            newFlip = EImageFlip.Vertical;
            break;
        }
        break;
      case EImageFlip.Vertical:
        switch (direction) {
          case EImageFlip.Horizontal:
            newFlip = EImageFlip.Both;
            break;
          case EImageFlip.Vertical:
            newFlip = EImageFlip.None;
            break;
          case EImageFlip.Both:
            newFlip = EImageFlip.Horizontal;
            break;
        }
        break;
      case EImageFlip.Both:
        switch (direction) {
          case EImageFlip.Horizontal:
            newFlip = EImageFlip.Vertical;
            break;
          case EImageFlip.Vertical:
            newFlip = EImageFlip.Horizontal;
            break;
          case EImageFlip.Both:
            newFlip = EImageFlip.None;
            break;
        }
        break;
    }
    if (newFlip != oldFlip) {
      props.session?.addNewTransform(transform.newFlip(newFlip));
    }
  };
  //---------------------------------------------------------------------------
  const onCropAction = (crop?: ImageCrop) => {
    setCropIsActive(false);
    if (crop) {
      let transform = props.session?.transform;
      if (transform) {
        props.session?.addNewTransform(transform.newCrop(crop.imageRect));
      }
    }
  };
  //---------------------------------------------------------------------------
  const startSaveChanges = () => {
    let transform = props.session?.transform;
    if (!props.session || !transform)
      return;

    switch (transform.transformType) {
      case ETransformType.ImageFromLibraryAsCopy:
      case ETransformType.ImageFromLibraryAsLink:
        props.session.setToImageFromLibraryAsLink();
        break;
      default:
        backCanvas.current?.toBlob(onBlobReadyForSave, "image/webp", 0.975);
        //backCanvas.current?.toBlob(onBlobReadyForSave, transform.imageData.contentType, 1);
        //backCanvas.current?.toBlob(onBlobReadyForSave, "image/png", 1);
        //backCanvas.current?.toBlob(onBlobReadyForSave, "image/jpeg", 1);
        break;
    }
  }
  //---------------------------------------------------------------------------
  const onBlobReadyForSave = (image: Blob | null) => {
    //console.log("Canvas.onBlobReadyForSave");
    props.onBlobReady && props.onBlobReady();
    if (image) {
      props.session?.setBlobFromCanvas(
        image,
        appUiContext.sysSettings.storageEndpoint,
        appAuthContext.user?.userIdentity.id);
    }
  };

  switch (props.mode) {
    case EImageEditorMode.Image:
    case EImageEditorMode.Crop:
    case EImageEditorMode.Spinner:
    case EImageEditorMode.Description: {
      return (
        <div className={styles.canvasContainer}>
          <React.Fragment>
            <canvas ref={backCanvas} className={styles.backCanvas} />
            {cropIsActive && backCanvas.current && props.session?.transform && canvasClientRect &&
              <CanvasOverlay
                backCanvas={backCanvas.current}
                transform={props.session.transform}
                cropAction={cropAction}
                backCanvasClientRect={canvasClientRect}
                onCropAction={onCropAction}
              />
            }
            <WikiSpinner show={props.mode == EImageEditorMode.Spinner} />
          </React.Fragment>
        </div>
      );
    }
    case EImageEditorMode.Placeholder: {
      let placeholderHint = props.placeholderHint;
      if (props.session?.loadErrorString) {
        if (placeholderHint == undefined)
          placeholderHint = "";
        placeholderHint = `${placeholderHint}\n(${props.session.loadErrorString})`;
      }
      return (
        <div className={styles.canvasContainer}>
          <div className={styles.placeholder}>
            <ImageEditorPlaceholder onImageSelect={props.onImageSelect} />
            {placeholderHint &&
              <div className={styles.placeholderHint} onClick={props.onImageSelect}>
                {placeholderHint}
              </div>
            }
          </div>
        </div>
      );
    }
    default:
      return null;
  }
}

