import { IPublicClientApplication } from "@azure/msal-browser";
import { createContext, Dispatch, ReactNode, useEffect, useReducer } from "react";
import { IAppAuthContext, IAuthConfig } from "../../../../context/app-auth-context/AppAuthContext";
import { TImageData } from "../TImageData";
import { Actions, EImageEditorContextUpdate, ImageEditorContextReducer } from "./ImageEditorContextReducer";
import { ImageEditorSession } from "./ImageEditorSession";
import { apiGetPrivate, apiPostPrivate, checkResponse, IApiResponse } from "../../../../utils/api";
import { addUserImageLibraryItems, apiBasePath, getUserImages } from "../../../../utils/apiPathConstant";
import { TImageLibraryItem } from "../../../user-console/user-images/image-library/TImageLibrary";
import { EHttpStatusCode } from "../../../../utils/HttpStatusCodes";
import { IUiImageEditor } from "../IUiImageEditor";
//----------------------------------------------------------------------------------
export type TImageEditorDispatch = Dispatch<Actions>;
//----------------------------------------------------------------------------------
export class StateTest {
  prop: number = 0;
}
//----------------------------------------------------------------------------------
export class ClassImageEditorContext {
  //----------------------------------------------------------------------
  static imageEditorUi?: IUiImageEditor;
  //----------------------------------------------------------------------
  lastAction?: Actions;
  //----------------------------------------------------------------------
  sessions!: ImageEditorSession[];
  lastChangedSession?: ImageEditorSession;
  lastChangedSessionUpdate?: EImageEditorContextUpdate;
  //----------------------------------------------------------------------
  userImages?: TImageLibraryItem[];
  filteredImages?: TImageLibraryItem[];
  //----------------------------------------------------------------------
  stateTest!: StateTest;
  // constructor(sessions: ImageEditorSession[]) {
  //   this.sessions = sessions;
  //   this.stateTest = new StateTest();
  // }
  //----------------------------------------------------------------------
  constructor(source?: ClassImageEditorContext) {
    if (source) {
      Object.assign(this, source);
    }
    else {
      this.sessions = [];
      this.stateTest = new StateTest();
    }
  }
  //----------------------------------------------------------------------
  getSessionByImageId(id?: string, calledFrom?: string) {
    let session = this.sessions.find((item) => item.imageId == id);
    if (calledFrom) {
      console.log("getSessionByImageId.calledFrom:", calledFrom);
    }
    return session;
  }
  //----------------------------------------------------------------------
  addSession(value: ImageEditorSession) {
    let index = this.sessions.indexOf(value);
    if (index < 0) {
      this.sessions.push(value);
    }
  }
  //----------------------------------------------------------------------
  clone() {
    //return new TImageEditorContext(this.sessions);
    return new ClassImageEditorContext(this);
  }
  //----------------------------------------------------------------------
  isLoadingStateChanged(session: ImageEditorSession | undefined): boolean {
    if (!session)
      return false;
    if (session != this.lastChangedSession)
      return false;
    if (this.lastChangedSessionUpdate != EImageEditorContextUpdate.LoadingStateChanged)
      return false;
    return true;
  }
  //----------------------------------------------------------------------
  isThisSessionChanged(session?: ImageEditorSession): boolean {
    if (!session)
      return false;
    if (session != this.lastChangedSession)
      return false;
    return true;
  }
  //----------------------------------------------------------------------
  isTransformChanged(session: ImageEditorSession | undefined): boolean {
    if (!session)
      return false;
    if (session != this.lastChangedSession)
      return false;
    if (this.lastChangedSessionUpdate != EImageEditorContextUpdate.ImageTransformed)
      return false;
    return true;
  }
  //----------------------------------------------------------------------
  async uploadAllImagesForAsync(
    objectId: string,
    appAuthContext: IAppAuthContext | undefined,
    instance: IPublicClientApplication | undefined,
    removeSessions: boolean) {
    try {
      let sessionsForObject = this.sessions.filter(session => session.objectIds.includes(objectId));
      let libraryItemsToUpload: TImageData[] = [];
      let sessionsToUploadImage: ImageEditorSession[] = [];
      let lastModified = new Date();
      let userId = appAuthContext?.user?.userIdentity.id;
      let promises: Promise<boolean>[] = [];
      // fill the arrays with objects that need to be updated
      sessionsForObject.forEach(session => {
        session.getImageUpdates(
          libraryItemsToUpload,
          sessionsToUploadImage,
          lastModified, userId);
      });

      sessionsToUploadImage.forEach(session =>
        promises.push(session.uploadImageFromEditedBlobByBlocksAsync(false, appAuthContext, instance)));
      if (libraryItemsToUpload.length > 0)
        promises.push(TImageData.uploadImageLibraryItems(libraryItemsToUpload));

      let results = await Promise.all(promises);
      let successCount = 0;
      results.forEach(result => {
        if (result)
          successCount++;
      });
      if (successCount != results.length) {
        console.error('uploadAllImagesForAsync: some image uploads failed');
        return false;
      }
      if (removeSessions) {
        sessionsForObject.forEach(session => session.removeFromContext());
      }
      else {
        sessionsForObject.forEach(session => session.finishEditing());
      }
      // refresh updated images in context
      let updated: TImageData[] = [];//libraryItemsToUpdateUsage;
      if (this.userImages && this.userImages.length > 0) {
        // merge arrays first
        for (let uploaded of libraryItemsToUpload) {
          if (!updated.find(item => item.id == uploaded.id))
            updated.push(uploaded);
        }
        for (let item of updated) {
          let i = this.userImages.findIndex(image => image.id == item.id);
          if (i != undefined && i >= 0) {
            this.userImages[i] = new TImageLibraryItem(item);
          }
        }
      }
      return true;
    } catch (error) {
      console.error('uploadAllImagesForAsync.error:', error);
    }
    return false;
  }
  //----------------------------------------------------------------------
  removeAllSessionsFor(objectId: string) {
    this.sessions = this.sessions.filter(session => !session.objectIds.includes(objectId));
    return this.isLoadingStateChanged;
  }
  //-----------------------------------------------------------------------
  async loadUserImages(
    instance: IPublicClientApplication,
    config?: IAuthConfig | null,
    abortSignal?: AbortSignal
  ): Promise<TImageLibraryItem[]> {
    return new Promise((resolve, reject) => {
      //-------------------------------------------------------------------------
      // Retrieve Image list from API otherwise
      //console.log("Retrieve Image list from API");
      apiGetPrivate(
        instance,
        config,
        `${apiBasePath}${getUserImages}`,
        abortSignal
      )
        .then((response) => {
          checkResponse(response, "requestUserImageLibrary", abortSignal?.aborted, [EHttpStatusCode.NotFound]);
          let resp: IApiResponse = response as IApiResponse;
          if (resp.status == EHttpStatusCode.NotFound) {
            return resolve([]);
          }
          let images = TImageLibraryItem.fromArray(response?.content);
          images.sort((i1, i2) => i1.compareDate(i2));
          resolve(images);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }
}
//----------------------------------------------------------------------------------
//export const imageEditorContextDefaultValue: TImageEditorContext = new TImageEditorContext([]);
export const imageEditorContextDefaultValue: ClassImageEditorContext = new ClassImageEditorContext();
//----------------------------------------------------------------------------------

//-----------------------------------------------------------------------------
type TImageEditorContextStore = {
  imageEditorContext: ClassImageEditorContext;
  imageEditorDispatch: TImageEditorDispatch;
}
//----------------------------------------------------------------------------------
export const ImageEditorContext = createContext<TImageEditorContextStore>({} as TImageEditorContextStore);
//----------------------------------------------------------------------------------
interface IProps {
  imageEditorUi?: IUiImageEditor;
  children: ReactNode;
}
//----------------------------------------------------------------------------------
export function ImageEditorContextProvider(props: IProps) {
  const [imageEditorContext, imageEditorContextDispatch] =
    useReducer(ImageEditorContextReducer, imageEditorContextDefaultValue);
  useEffect(() => {
    //console.log("ImageEditorContextProvider.useEffect[]", imageEditorContext?.sessions?.length);
    return (() => {
      //console.log("ImageEditorContextProvider.useEffect[].return", imageEditorContext?.sessions?.length);
      if (imageEditorContext && imageEditorContext.sessions.length > 0)
        imageEditorContext.sessions = [];
      //console.log("ImageEditorContextProvider.useEffect[].return", imageEditorContext?.sessions?.length);
    });
  }, []);
  //---------------------------------------------------------------------------
  ClassImageEditorContext.imageEditorUi = props.imageEditorUi;
  //---------------------------------------------------------------------------
  return (
    <ImageEditorContext.Provider
      value={{ imageEditorContext, imageEditorDispatch: imageEditorContextDispatch }}
    //value={imageEditorContext}
    >
      {/*
        <ImageEditorContextDispatch.Provider
        //value={{ imageEditorContext, imageEditorContextDispatch }}
        value={imageEditorContextDispatch}
      >
        {props.children}
      </ImageEditorContextDispatch.Provider>
      */}
      {props.children}
    </ImageEditorContext.Provider>
  );
}