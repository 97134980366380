import { useContext } from 'react';
import Icon from '../../../../../../common/icon/Icon';
import DictionaryItemIcon from '../../../../business-tags-console/business-tags-editor/editor-node/DictionaryItemIcon';
import { IDocumentsListItemTranslation } from './IDocumentsListItemTranslation';
import styles from './TranslationsItem.module.css';
import { AppUiContextStore } from '../../../../../../../context/app-ui-context/AppUiContextProvider';
import GetName from '../../../../../../common/functions/GetName';
import CombineStyles from '../../../../../../../utils/combineStyles';

interface IProps {
  data: IDocumentsListItemTranslation;
  onSelect: (locale: string, isNew: boolean) => void;
}

export default function TranslationsItem(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const localeName = appUiContext.dictionaries?.supportedLocales?.find(l => l.id === props.data.locale)?.name;
  //---------------------------------------------------------------------------
  const onSelect = () => {
    const isNew = !props.data.originalExists && !props.data.draftExists;
    props.onSelect(props.data.locale, isNew);
  };
  //---------------------------------------------------------------------------
  return (
    <div
      className={styles.container}
      onClick={onSelect}>

      <div
        title={props.data.originalExists ?
          !props.data.access?.viewers ?
            'Not published' : 'Original exists' :
          'No original'}
        className={CombineStyles([
          styles.title,
          props.data.originalExists ? '' : styles.noData,
          props.data.originalExists && !props.data.access?.viewers ? styles.notPublished : ''
        ])}>
        {GetName(appUiContext, props.data.locale, localeName)}
      </div>

      <div
        title={props.data.currentState ? props.data.currentState : props.data.draftExists ? 'Draft exists' : 'No draft'}
        className={CombineStyles([
          styles.option,
          props.data.draftExists ? '' : styles.disabled,
          props.data.currentState ? styles.pending : ''
        ])}>
        <Icon key="pencil" imageName="pencil" />
      </div>

      <div
        title='Access to draft'
        className={styles.option}>
        <DictionaryItemIcon type="editors" enabled={props.data.access?.editors} />
      </div>

      <div
        title='Published'
        className={styles.option}>
        <DictionaryItemIcon type="viewers" enabled={props.data.access?.viewers} />
      </div>

    </div>
  );
}