import { IUiInteractiveForm } from "../common/forms/IUiInteractiveForm";

export interface IUiMessagesList extends IUiInteractiveForm {

}

export const tmpUiMessagesList: IUiMessagesList = {
  id: "tmpUiMessagesList",
  directoryId: "",
  elementType: "",
  caption: undefined,
  hint: undefined,
  menuContent: {
    id: "",
    directoryId: "",
    elementType: "",
    hint: undefined,
    options: [{
      id: "OptionMarkUnread",
      directoryId: "",
      elementType: "",
      caption: { en: "Mark as unread"},
      hint: undefined
    },{
      id: "OptionMarkRead",
      directoryId: "",
      elementType: "",
      caption: { en: "Mark as read"},
      hint: undefined
    },{
      id: "OptionDelete",
      directoryId: "",
      elementType: "",
      caption: { en: "Delete"},
      hint: undefined
    },{
      id: "OptionUndelete",
      directoryId: "",
      elementType: "",
      caption: { en: "Restore"},
      hint: undefined
    }]
  }
}