import { IUiInteractiveForm } from "../../../../common/forms/IUiInteractiveForm";
import { IUiInputDropdown } from "../../../../common/input-v2/input-dropdown/InputDropdown";
import { IUiInputText } from "../../../../common/input-v2/input-text/InputText";

export interface IUiEditformDocumentDetails extends IUiInteractiveForm {
  inputType: IUiInputDropdown;
  inputId: IUiInputText;
  inputTitle: IUiInputText;
  inputDescription: IUiInputText;
}

export const tmpUiEditformDocumentDetails: IUiEditformDocumentDetails = {
  id: "EditformDocumentDetails",
  directoryId: "",
  elementType: "",
  caption: { en: "Document Details" },
  hint: undefined,
  menuContent: {
    id: "EditformDocumentDetails.MenuContent",
    directoryId: "",
    elementType: "",
    hint: undefined,
    options: [{
      id: "OptionCancel",
      directoryId: "",
      elementType: "",
      caption: { en: "Cancel" },
      hint: undefined,
    },{
      id: "OptionCreate",
      directoryId: "",
      elementType: "",
      caption: { en: "Create" },
      hint: undefined,
    }]
  },
  inputType: {
    id: "EditformDocumentDetails.InputType",
    directoryId: "",
    elementType: "",
    caption: { en: "Type" },
    hint: undefined,
    placeholder: { en: "Select type" },
    dictionaryId: "",
    options: [{
      id: "Legal",
      name: { en: "Legal" },
      description: { en: "Legal document" }
    }, {
      id: "Manual",
      name: { en: "Users' Manual" },
      description: { en: "Users' Manual document" }
    }],
    validation: {
      required: {
        value: 1,
        message: { en: "Type is required" }
      }
    }
  },
  inputId: {
    id: "",
    directoryId: "",
    elementType: "",
    caption: { en: "Id" },
    hint: undefined,
    validation: {
      required: {
        value: 5,
        message: { en: "Id is required" }
      },
      unique: {
        message: { en: "Id must be unique" }
      }
    }
  },
  inputTitle: {
    id: "",
    directoryId: "",
    elementType: "",
    caption: { en: "Title" },
    hint: undefined,
  },
  inputDescription: {
    id: "",
    directoryId: "",
    elementType: "",
    caption: { en: "Description" },
    hint: undefined,
  }
}