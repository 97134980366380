import CombineStyles from '../../../../../../utils/combineStyles';
import Checkbox from '../../../../../common/checkbox/Checkbox';
import Icon from '../../../../../common/icon/Icon';
import { IUiMenuContent } from '../../../../../common/options/menus/IUiMenuContent';
import { IObjectLocation } from '../IBusinessLocationData';
import styles from './ListItemBusinessLocation.module.css';

export interface IHighlightedItem {
  path: string[];
  color?: string;
}

interface IProps {
  ui?: IUiMenuContent;
  data: IObjectLocation<string>;
  addressBlockMode?: boolean;
  isSelected?: boolean;
  isReadonly: boolean;
  highlightedItem?: IHighlightedItem;
  onOptionSelect?: (optionId: string, locationId: string) => void;
  onToggle?: (locationId: string) => void;
  onHover?: (locationId: string, isHovered: boolean) => void;
}

export default function ListItemBusinessLocation(props: IProps) {
  const isAddressBlockMode = props.addressBlockMode == undefined ? false : props.addressBlockMode;
  const isSelectable = props.onToggle === undefined ? false : true;
  const isSelected = props.isSelected === undefined ? false : props.isSelected;
  const isHighlighted = props.highlightedItem?.path.includes(props.data.id);
  const nameIsHighlighted = isHighlighted && props.highlightedItem?.path.includes("Name");
  const addressIsHighlighted = isHighlighted && props.highlightedItem?.path.includes("Address");
  //---------------------------------------------------------------------------
  const onRefresh = () => {
    if (!props.onOptionSelect) return;
    props.onOptionSelect("OptionRefresh", props.data.id);
  };
  //---------------------------------------------------------------------------
  const onEdit = () => {
    if (props.isReadonly) return;
    if (!props.onOptionSelect) return;
    props.onOptionSelect("OptionEdit", props.data.id);
  };
  //---------------------------------------------------------------------------
  const onAdd = () => {
    if (props.isReadonly) return;
    if (!props.onOptionSelect) return;
    props.onOptionSelect("OptionAdd", props.data.id);
  };
  //---------------------------------------------------------------------------
  const onDelete = () => {
    if (!props.onOptionSelect) return;
    props.onOptionSelect("OptionDelete", props.data.id);
  };
  //---------------------------------------------------------------------------
  const onToggle = () => {
    if (!props.onToggle) return;
    props.onToggle(props.data.id);
  };
  //---------------------------------------------------------------------------
  const onHoverStart = () => {
    if (!props.onHover) return;
    props.onHover(props.data.id, true);
  };
  //---------------------------------------------------------------------------
  const onHoverEnd = () => {
    if (!props.onHover) return;
    props.onHover(props.data.id, false);
  };
  //---------------------------------------------------------------------------
  return (
    <div
      className={CombineStyles([
        styles.container,
        props.isReadonly ? styles.readonly : '',
        isSelected ? styles.selected : '',
        isSelected && !props.isReadonly ? styles.border : ''
      ])}
      style={{
        border: isHighlighted ? `1px solid ${props.highlightedItem?.color}` : '',
        boxShadow: isHighlighted ? `0 0 3px 1px ${props.highlightedItem?.color}` : undefined,
        zIndex: isHighlighted ? 15 : undefined
      }}
      onMouseEnter={onHoverStart}
      onMouseLeave={onHoverEnd}
      onClick={isSelectable ? onToggle : undefined}>

      {isSelectable ?
        <div
          className={styles.icon}>
          <Checkbox checked={props.isSelected} />
        </div> :
        <div
          className={styles.icon}
          style={{ color: isHighlighted ? props.highlightedItem?.color : '' }}
          onClick={onEdit}
          >
          <Icon imageName='location' />
        </div>}

      <div
        className={styles.details}
        onClick={onEdit}
        >
        <div
          className={styles.name}
          style={{ color: nameIsHighlighted ? props.highlightedItem?.color : '' }}
        >
          {props.data.name}
        </div>
        <div
          className={styles.address}
          style={{ color: addressIsHighlighted ? props.highlightedItem?.color : '' }}
        >
          {props.data.addressText}
        </div>
      </div>

      {props.ui && !props.isReadonly && isAddressBlockMode &&
        <div
          className={styles.option}
          onClick={onRefresh}>
          <Icon imageName='refresh' />
        </div>}

      {props.ui && !props.isReadonly &&
        <div
          className={styles.option}
          onClick={onEdit}>
          <Icon imageName='pencil' />
        </div>}

      {props.ui && !props.isReadonly && !isAddressBlockMode &&
        <div
          className={styles.option}
          onClick={onAdd}>
          <Icon key={'plus'} imageName='plus' />
        </div>}

      {props.ui && !props.isReadonly &&
        <div
          className={CombineStyles([
            styles.option,
            isAddressBlockMode ? styles.addressBlock : ''
          ])}
          onClick={onDelete}>
          <Icon imageName={isAddressBlockMode ? 'close' : 'delete'} />
        </div>}

    </div>
  );
}