export interface IMeasurementValue {
  asString: string;
  asNumber?: number;
}
export interface IPopupState {
  top?: IMeasurementValue;
  bottom?: IMeasurementValue;
  left?: IMeasurementValue;
  right?: IMeasurementValue;
  width?: IMeasurementValue;
  maxWidth?: IMeasurementValue;
  height?: IMeasurementValue;
  maxHeight?: IMeasurementValue;
  transform?: string;
}

export interface IPopupStates {
  initial: IPopupState;
  final: IPopupState;
}

export type TAnimationType = "None" | "GrowY" | "GrowXY" | "SlideFromLeft" | "SlideFromRight" | "FadeIn";
export const popupMaxWidth = '60em';
const verticalAnchorMargin = 5;
const verticalScreenMargin = 10;

export const fullWindowState: IPopupState = {
  top: { asString: '50%' },
  left: { asString: '50%' },
  height: { asString: '100%' },
  width: { asString: '100%' },
  maxWidth: { asString: '100%' },
  maxHeight: { asString: '100%' },
  transform: 'translate(-50%, -50%)'
};

export const centeredState: IPopupState = {
  top: { asString: '50%' },
  left: { asString: '50%' },
  height: { asString: 'auto' },
  width: { asString: 'auto' },
  maxWidth: { asString: popupMaxWidth },
  maxHeight: { asString: '100%' },
  transform: 'translate(-50%, -50%)'
};

const str = (value: number) => {
  return `${value}px`;
}

interface IProps {
  popupRect: DOMRect;
  anchorRect?: DOMRect;
  animationType?: TAnimationType;
  fullWindow?: boolean;
  fixHeight?: boolean;
}

export default function GetPopupStates(props: IProps): IPopupStates {
  const anchorRect = props.anchorRect;
  const popupRect = props.popupRect;
  const fullWindow = props.fullWindow;
  const animationType = props.animationType;
  const fixHeight = props.fixHeight;
  //---------------------------------------------------------------------------
  // Final state
  let finalState: IPopupState = {};
  finalState.height = { asString: 'auto' };
  finalState.width = { asString: 'auto' };
  //---------------------------------------------------------------------------
  if (fullWindow) {
    // If it's a full-window, anchor does not matter even it's provided
    finalState = fullWindowState;
  } else if (anchorRect) {
    // There is an anchor, so popup state depends on it
    finalState.transform = undefined;
    //-------------------------------------------------------------------------
    // Vertical position
    if (anchorRect.top < window.innerHeight / 2) {
      // TOP half of the screen, draw popup under the anchor
      const top = anchorRect.bottom + verticalAnchorMargin;
      finalState.top = { asNumber: top, asString: str(top) };
      // Check height - it should not go outside the screen
      const maxHeight = window.innerHeight - anchorRect.bottom - verticalAnchorMargin - verticalScreenMargin;
      finalState.maxHeight = { asNumber: maxHeight, asString: str(maxHeight) };
    } else {
      // BOTTOM half of the screen, draw popup above the anchor
      const bottom = window.innerHeight - anchorRect.top + verticalAnchorMargin;
      finalState.top = { asString: 'auto' };
      finalState.bottom = { asNumber: bottom, asString: str(bottom) };
      // Check height - it should not go outside the screen
      //console.log(window.innerHeight, anchorRect.top)
      const maxHeight = anchorRect.top - verticalScreenMargin;
      finalState.maxHeight = { asNumber: maxHeight, asString: str(maxHeight) };
    };
    //-------------------------------------------------------------------------
    // Horizontal position
    if (anchorRect.left < window.innerWidth / 2) {
      // LEFT half of the screen, draw popup from the left bound of the anchor
      const left = anchorRect.left - 1;
      finalState.left = { asNumber: left, asString: str(left) };
      //const maxWidth = window.innerWidth - anchorRect.left;
    } else {
      // RIGHT half of the screen, draw popup from the right bound of the anchor
      const right = window.innerWidth - anchorRect.right;
      finalState.left = { asString: 'auto' };
      finalState.right = { asNumber: right, asString: str(right) };
      //const maxWidth = window.innerWidth - anchorRect.right;
    };
  } else {
    //-------------------------------------------------------------------------
    // Not a full-window, no anchor, so popup is just in the center of the screen
    finalState.top = { asString: '50%' };
    finalState.left = { asString: '50%' };
    finalState.maxWidth = { asString: popupMaxWidth };
    finalState.maxHeight = { asString: `calc(100% - ${verticalScreenMargin * 2}px)` }
    finalState.transform = 'translate(-50%, -50%)';
  };
  //---------------------------------------------------------------------------
  // Determine initial position. By default make is the same as final one
  const initialState: IPopupState = {
    ...finalState
  };
  //---------------------------------------------------------------------------
  // Calculate state based on animation type
  //---------------------------------------------------------------------------
  switch (animationType) {
    case "SlideFromLeft":
      console.log('from the left')
      // It should start from the right, so everything related to vertical poisiton is the same as final state
      // just horizontal position needs to be updated
      // and it depends on how we specified horizontal position
      if (finalState.left?.asNumber) {
        const left = finalState.left.asNumber - popupRect.width;
        initialState.left = { asNumber: left, asString: str(left) };
      } else if (finalState.right?.asNumber) {
        const right = finalState.right.asNumber - popupRect.width * 2;
        initialState.right = { asNumber: right, asString: str(right) };
      } else {
        // Window is centered or full-window, i.e. left is 50% percents of screen width
        initialState.left = { asString: `calc(50% - ${popupRect.width}px)` }
      };
      break;
    case "FadeIn":
      break;
    case "SlideFromRight":
    default:
      // It should start from the right, so everything related to vertical poisiton is the same as final state
      // just horizontal position needs to be updated
      // and it depends on how we specified horizontal position
      if (finalState.left?.asNumber) {
        const left = finalState.left.asNumber + popupRect.width;
        initialState.left = { asNumber: left, asString: str(left) };
      } else if (finalState.right?.asNumber) {
        const right = finalState.right.asNumber - popupRect.width;
        initialState.right = { asNumber: right, asString: str(right) };
      } else {
        // Window is centered or full-window, i.e. left is 50% percents of screen width
        initialState.left = { asString: `calc(50% + ${popupRect.width}px)` }
      };
      break;
  };
  //--------------------------------------------------------------------------- Handle fixed height
  if (fixHeight && !fullWindow) {
    const maxHeight = finalState.maxHeight?.asNumber;
    const heightToFix = Math.max(maxHeight ? maxHeight : 0, popupRect.height);
    finalState.height = { asNumber: heightToFix, asString: `${heightToFix}px` };
  };
  //---------------------------------------------------------------------------
  let popupRects: IPopupStates = {
    initial: initialState,
    final: finalState
  };
  //---------------------------------------------------------------------------
  return popupRects;
}