import React, { useContext, useState } from "react";
import CombineStyles from "../../../utils/combineStyles";
import styles from "./FlagIcon.module.css";
import PopupTest from "../popup-v2/popup/PopupTest";
import { AppUiContextStore } from "../../../context/app-ui-context/AppUiContextProvider";

//--------------------------------------------------------------------------
export class Flag {
  id!: string;
  locales!: string[];
  //------------------------------------------------------------------------
  constructor(locale: string, flagId: string) {
    this.locales = [locale];
    this.id = flagId;
  }
  //------------------------------------------------------------------------
  addLocale(locale: string) {
    if (!this.contains(locale))
      this.locales.push(locale);
  }
  //------------------------------------------------------------------------
  contains(locale: string) {
    return this.locales.findIndex(item => item == locale) >= 0;
  }
}
//--------------------------------------------------------------------------
export class Flags {
  flags: Flag[] = [];
  //------------------------------------------------------------------------
  constructor(locales: string[]) {
    this.checkLocales(locales);
  }
  //------------------------------------------------------------------------
  checkLocales(locales: string[]) {
    locales.forEach(locale => this.addLocale(locale));
  }
  //------------------------------------------------------------------------
  addLocale(locale: string) {
    let flagId = Flags.getFlagId(locale);
    let flags = this.flags.filter(item => item.id == flagId);
    if (flags.length > 0) {
      flags[0].addLocale(locale);
    }
    else {
      this.flags.push(new Flag(locale, flagId));
    }
  }
  //------------------------------------------------------------------------
  getFlagByLocale(locale: string) {
    return this.flags.find(item => item.contains(locale));
  }
  //------------------------------------------------------------------------
  getFlagById(flagId: string) {
    return this.flags.find(item => item.id == flagId);
  }
  //------------------------------------------------------------------------
  static getFlagId(locale: string): string {
    switch (locale) {
      //------------------------------------------------------------------------
      case "en":
        return "flagpack:gb-ukm";
      //------------------------------------------------------------------------
      case "en-AU":
      case "en-au":
        return "flagpack:au";
      //------------------------------------------------------------------------
      case "en-us":
      case "en-US":
      case "us":
        return "flagpack:us";
      //------------------------------------------------------------------------
      case "de":
      case "de-DE":
        return "flagpack:de";
      //------------------------------------------------------------------------
      case "ge": 
      case "ka": 
      case "ka-GE": 
        return "flagpack:ge";
      //------------------------------------------------------------------------
      case "uk": return "flagpack:ua";
      case "ru": return "flagpack:ru";
      //------------------------------------------------------------------------
      case "de-CH":
      case "en-CH":
      case "fr-CH":
      case "gsw-CH":
      case "it-CH":
      case "pt-CH":
      case "rm-CH":
      case "wae-CH":
        return "flagpack:ch";
      //------------------------------------------------------------------------
      case "en-CA":
      case "fr-CA":
      case "iu-CA":
      case "iu-Latn-CA":
      case "moh-CA":
        return "flagpack:ca";
      //------------------------------------------------------------------------
      case "fr":
      case "fr-FR":
        return "flagpack:fr";
      //------------------------------------------------------------------------
      case "fr-BE":
      case "de-BE":
      case "nl-BE":
          return "flagpack:be";
      //------------------------------------------------------------------------
      default:
        //console.log("getFlagId: returning default flag for:", locale)
        return "flagpack:gb-ukm";
    }
  }
}
//--------------------------------------------------------------------------
interface IProps {
  flag: Flag;
  selected: boolean;
  onClick?: (locale: string) => void;
  onMouseEnter?: (flagId: string) => void;
}
//--------------------------------------------------------------------------
export default function FlagIcon(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const [popupOpen, setPopupOpen] = useState<boolean>(false);
  //---------------------------------------------------------------------------
  const icon = <span className="iconify" data-icon={props.flag.id} />
  const containerStyles = CombineStyles([
    styles.container,
    props.selected ? "" : styles.notSelected
  ]);
  //---------------------------------------------------------------------------
  const onMouseEnter = () => {
    props.onMouseEnter && props.onMouseEnter(props.flag.id);
  }
  //---------------------------------------------------------------------------
  const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    console.log("onClick.e:", e);
    if (props.flag.locales.length == 1) {
      props.onClick && props.onClick(props.flag.locales[0]);
      console.log("onClick:", props.flag.locales[0]);
    }
    else if (props.flag.locales.length > 1) {
      setPopupOpen(true);
      //console.log("onClick: setPopupOpen(true)");
    }
  }
  //---------------------------------------------------------------------------
  const selectPopupLocale = (locale: string) => {
    setPopupOpen(false);
    props.onClick && props.onClick(locale);
    //console.log("selectPopupLocale:", locale);
  }
  //---------------------------------------------------------------------------
  let popupItems = props.flag.locales.map(localeId => {
    let localeName: string = localeId;
    if (localeId.endsWith("CH")) {
      localeName = localeId;
    }
    if (appUiContext.dictionaries?.locales) {
      let name = appUiContext.dictionaries?.locales.find(item => item.id == localeId)?.name;
      if (name && name["native"]) {
        localeName = name["native"];
      }
    }
    return <div
      className={styles.popupLocale}
      onClick={(e: React.MouseEvent<HTMLDivElement>) => {
        selectPopupLocale(localeId);
        e.stopPropagation();
      }}>
      {localeName}
    </div>
  }
  );
  //---------------------------------------------------------------------------
  //props.flag.id == "flagpack:ch" && console.log("render", popupOpen, props.flag.id);
  //---------------------------------------------------------------------------
  let divId = `flagIcon_${props.flag.id}`;
  //---------------------------------------------------------------------------
  return (
    <div
      id={divId}
      className={containerStyles}
      onClick={onClick}
      onMouseEnter={props.onMouseEnter ? onMouseEnter : undefined}
    >
      {icon}
      {popupOpen &&
        <PopupTest
          id={props.flag.id}
          anchorId={divId}
          isModal={false}
          closeOnClickOutside={true}
          onClose={() => setPopupOpen(false)}
        >
          {popupItems}
        </PopupTest>
      }
    </div>
  );
}
