import { useMsal } from "@azure/msal-react";
import React, { useContext, useState } from "react";
import { AppAuthContextStore } from "../../../../context/app-auth-context/AppAuthContext";
import { ScreenType, useAppScreenContext } from "../../../../context/app-screen-context/AppScreenProvider";
import { AppUiContextStore } from "../../../../context/app-ui-context/AppUiContextProvider";
import useNavigateWithContext from "../../../../hooks/useNavigateWithContext";
import { pathAdminConsole } from "../../../admin-console/AdminConsole";
import { getTUiMenuContent } from "../../../common/menu/menu-content/TUiMenuContent";
import PopupMenu from "../../../common/popup-v2/popup-menu/PopupMenu";
import { pathUserConsole } from "../../../user-console/UserConsole";
import UserMenuButton from "./user-menu-button/UserMenuButton";
import UserMenuOptionButton from "./user-menu-option-button/UserMenuOptionButton";
import styles from "./UserMenu.module.css";
import { IUiMenuContent } from "../../../common/options/menus/IUiMenuContent";
import GetName from "../../../common/functions/GetName";
import { getFromSessionStorage, saveToSessionStorage } from "../../../../utils/sessionStorage";
import { userSessionPreferencesKey } from "../../../../context/app-ui-context/IUserSessionPreferences";
import { pathSignInOut } from "../../../../App";
import { authActionParam, flagSignOut } from "../../../page-signin-signout/PageSignInOut";
import InputDropdownPopup from "../../../common/input-v2/input-dropdown/input-dropdown-popup/InputDropdownPopup";
import { IUiInputDropdown } from "../../../common/input-v2/input-dropdown/InputDropdown";
import { IUiDictionaryItem } from "../../../common/dictionaries/IUiDictionaryItem";
import { pathBusinessConsole, pathBusinessWizard } from "../../../business-space/business-console/BusinessConsole";
import { IUiOption } from "../../../common/options/IUiOption";

const optionsGroupUser = "user";
const optionsGroupCommon = "common";
const optionsGroupActions = "actions";

interface IProps {
  ui: IUiMenuContent;
}

export default function UserMenu(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext, appAuthContextDispatch } = useContext(AppAuthContextStore);
  const { appUiContext, appUiContextDispatch } = useContext(AppUiContextStore);
  const screenType = useAppScreenContext();
  const navigate = useNavigateWithContext();
  const [userMenuPopupOpen, setUserMenuPopupOpen] = useState(false);
  const [languagesPopupOpen, setLanguagesPopupOpen] = useState(false);
  const [themesPopupOpen, setThemesPopupOpen] = useState(false);
  //--------------------------------------------------------------------------- User menu button click
  const onUserMenuButtonSelect = () => {
    setUserMenuPopupOpen(!userMenuPopupOpen);
  };
  //---------------------------------------------------------------------------
  const signIn = () => {
    setUserMenuPopupOpen(false);
    appAuthContextDispatch({ type: "SetInProcess", value: true });
    if (appAuthContext.config?.loginScopes) {
      const loginRedirectRequest = {
        scopes: appAuthContext.config.loginScopes,
        extraQueryParameters: {
          ui_locales: appUiContext.locale.localeId,
          application_name: "rikiwiki",
          theme: "MenuColourThemes_" + appUiContext.theme.themeId.split("-").slice(-1)[0]
        }
      };
      // Redirect user to B2C authentication form
      instance.loginRedirect(loginRedirectRequest);
    } else {
      console.error("Can't handle sign in because appAuthContext.config.loginScopes value is not provided");
    };
  };
  //---------------------------------------------------------------------------
  const signOut = () => {
    setUserMenuPopupOpen(false);
    appAuthContextDispatch({ type: "SetInProcess", value: true });
    //console.log("Sign out option is selected");
    //TUserType.toLS(location.pathname, EUserType.Anonymous);
    //navigate(`${pathSignInOut}/${flagSignOut}`);
    navigate(`${pathSignInOut}?${authActionParam}=${flagSignOut}`);
    /*
    // Clear up user data in local storage
    removeUserLocally(appAuthContext.user?.userIdentity.id);
    removeUserLastSignIn(appAuthContext.user?.userIdentity.id);
    // Log out
    instance.logoutRedirect({ postLogoutRedirectUri: "/" });
    */
  };
  //--------------------------------------------------------------------------- Option selection handler
  const onOptionSelect = (selectedOptionId: string) => {
    console.log(selectedOptionId);
    switch (selectedOptionId) {
      //----------------------------------------------------------------------- Sign in
      case "UserMenuContent.SignIn":
        //TUserType.toLS(location.pathname, EUserType.Authenticated);
        signIn();
        break;
      //----------------------------------------------------------------------- Open profile
      case "UserMenuContent.OpenUserProfile":
        setUserMenuPopupOpen(false);
        navigate(pathUserConsole);
        break;
      //----------------------------------------------------------------------- Sign out
      case "UserMenuContent.SignOut":
        signOut();
        break;
      //----------------------------------------------------------------------- Change language
      case "UserMenuContent.ChangeLanguage":
        // Open popup with languages list and handle selection
        setLanguagesPopupOpen(true);
        break;
      //----------------------------------------------------------------------- Change theme
      case "UserMenuContent.ChangeColorTheme":
        // Open popup with themes list and handle selection
        setThemesPopupOpen(true);
        break;
      //----------------------------------------------------------------------- Place an Ad (start business wizard)
      case "UserMenuContent.StartBusinessWizard":
        setUserMenuPopupOpen(false);
        appUiContextDispatch({ type: "SetPathToRemember", value: location.pathname })
        //navigate(`${pathBusinessConsole}/${pathBusinessWizard}`);
        navigate(`${pathBusinessConsole}/owner/${pathBusinessWizard}`);
        break;
      //----------------------------------------------------------------------- Business Editor
      case "UserMenuContent.OpenBusinessConsole":
        setUserMenuPopupOpen(false);
        navigate(pathBusinessConsole);
        break;
      //----------------------------------------------------------------------- Admin Console
      case "UserMenuContent.OpenAdminConsole":
        setUserMenuPopupOpen(false);
        navigate(pathAdminConsole);
        break;
    };
  };
  //--------------------------------------------------------------------------- Handle language selection
  const onLanguageChange = (selectedOption?: IUiDictionaryItem) => {
    console.log("change language:", selectedOption);
    setLanguagesPopupOpen(false);
    setUserMenuPopupOpen(false);
    if (selectedOption) {
      const localeId = selectedOption.id;
      console.log(selectedOption);
      //---------------------------------------------------------------------
      // Update context
      let calledFrom = `userMenu.onLanguageChange: ${localeId}`;
      if (appAuthContext.user) {
        // This means user set locale based on profile but they want to override it temporarily
        appUiContextDispatch({ type: "SetLocale", localeId: localeId, isImportant: true, calledFrom });
      } else {
        appUiContextDispatch({ type: "SetLocale", localeId: localeId, calledFrom });
      };
      //---------------------------------------------------------------------
      // Store selected locale to LS to persist user's choice
      // setToLS(lsPreferredAppLocale, localeId);
      //---------------------------------------------------------------------
      // Store selected locale to session storage to persist user's choice for the session
      // If user wants the change to persist between session, they should update preferences in user profile
      const sessionStorageKey = appAuthContext.user ?
        `${userSessionPreferencesKey}.${appAuthContext.user.userIdentity.id}` :
        userSessionPreferencesKey;
      const currentPreferences = getFromSessionStorage(sessionStorageKey);
      saveToSessionStorage(sessionStorageKey, {
        ...currentPreferences,
        locale: localeId
      });
    }
  };
  //--------------------------------------------------------------------------- Handle theme selection
  const onThemeChange = (selectedOption?: IUiDictionaryItem) => {
    setThemesPopupOpen(false);
    setUserMenuPopupOpen(false);
    if (selectedOption) {
      // Update context
      appUiContextDispatch({ type: "SetTheme", themeId: selectedOption.id });
      //---------------------------------------------------------------------
      // Store selected theme to session storage to persist user's choice for the session
      // If user wants the change to persist between session, they should update preferences in user profile
      const sessionStorageKey = appAuthContext.user ?
        `${userSessionPreferencesKey}.${appAuthContext.user.userIdentity.id}` :
        userSessionPreferencesKey;
      const currentPreferences = getFromSessionStorage(sessionStorageKey);
      saveToSessionStorage(sessionStorageKey, {
        ...currentPreferences,
        theme: selectedOption.id
      });
    }
  };
  //--------------------------------------------------------------------------- Handle language/theme popup closing
  const onOptionsPopupClose = () => {
    setLanguagesPopupOpen(false);
    setThemesPopupOpen(false);
    setUserMenuPopupOpen(false);
  };
  //--------------------------------------------------------------------------- Handle language/theme popup closing
  const onOptionsPopupBack = () => {
    setLanguagesPopupOpen(false);
    setThemesPopupOpen(false);
  };
  //--------------------------------------------------------------------------- Sort out the options
  let headerOptions: IUiOption[] = []; // Visible only on desktop
  let popupOptions: IUiOption[] = [];
  let updatedOption: IUiOption;
  props.ui.options.forEach(option => {
    switch (option.id) {
      case "UserMenuContent.OpenUserProfile":
        if (appAuthContext.user) {
          popupOptions.push({
            ...option,
            iconId: "userProfile",
            group: optionsGroupUser
          });
        };
        break;
      //-----------------------------------------------------------------------
      case "UserMenuContent.Messages":
        /* if (appAuthContext.user) {
          popupOptions.push({
            ...option,
            iconId: "messages",
            count: appAuthContext.unreadMessageCount,
            group: optionsGroupUser
          });
        }; */
        break;
      //-----------------------------------------------------------------------
      case "UserMenuContent.SignIn":
        if (!appAuthContext.user && !appAuthContext.isInProcess) {
          updatedOption = {
            ...option,
            iconId: "signIn",
            isDefault: true,
            group: optionsGroupUser
          };
          if (screenType === ScreenType.Desktop)
            headerOptions.push(updatedOption);
          else
            popupOptions.push(updatedOption);
        };
        break;
      //-----------------------------------------------------------------------
      case "UserMenuContent.SignOut": {
        if (appAuthContext.user && !appAuthContext.isInProcess) {
          popupOptions.push({
            ...option,
            iconId: "signOut",
            group: optionsGroupUser
          });
        };
      } break;
      //-----------------------------------------------------------------------
      case "UserMenuContent.ChangeLanguage": {
        const localeItem = appUiContext.dictionaries?.supportedLocalesNative?.find(item => item.id == appUiContext.locale.localeId);
        const localeSystemId = appUiContext.locale.localeId == "i18n" ? GetName(appUiContext, "i18n", null) : undefined;
        updatedOption = {
          ...option,
          iconId: "changeLanguage",
          isDefault: false,
          customCaption: localeSystemId ?
            localeSystemId :
            GetName(appUiContext, appUiContext.locale.localeId, localeItem?.name, 'native'),
          isDropdown: true,
          group: optionsGroupCommon
        };
        if (screenType === ScreenType.Desktop)
          headerOptions.push(updatedOption);
        else
          popupOptions.push(updatedOption);
      } break;
      //-----------------------------------------------------------------------
      case "UserMenuContent.ChangeColorTheme":
        updatedOption = {
          ...option,
          iconId: "changeColourTheme",
          customCaption: screenType == ScreenType.Desktop ? '' : undefined,
          isDropdown: true,
          isDefault: false,
          group: optionsGroupCommon
        };
        if (screenType === ScreenType.Desktop)
          headerOptions.push(updatedOption);
        else
          popupOptions.push(updatedOption);
        break;
      //-----------------------------------------------------------------------
      case "UserMenuContent.StartBusinessWizard":
        updatedOption = {
          ...option,
          iconId: "magicWand",
          group: optionsGroupActions
        };
        popupOptions.push(updatedOption);
        break;
      //-----------------------------------------------------------------------
      case "UserMenuContent.OpenBusinessConsole":
        if (appAuthContext.user?.businessRoles) {
          updatedOption = {
            ...option,
            iconId: "businessEditor",
            count: appAuthContext.unreadMessageCount,
            group: optionsGroupActions
          };
          popupOptions.push(updatedOption);
        };
        break;
      //-----------------------------------------------------------------------
      case "UserMenuContent.OpenAdminConsole":
        if (appAuthContext.user?.staffRoles) {
          updatedOption = {
            ...option,
            iconId: "adminConsole",
            group: optionsGroupActions
          };
          popupOptions.push(updatedOption);
        };
        break;
    };
  });
  const popupMenuContent: IUiMenuContent = {
    ...props.ui,
    options: popupOptions
  };
  //--------------------------------------------------------------------------- Render header options
  const headerOptionElements = headerOptions.map(option => (
    <UserMenuOptionButton
      key={option.id}
      ui={option}
      clickHandler={onOptionSelect}
    />
  ));
  //--------------------------------------------------------------------------- Select language menu
  const preferredLanguageInput: IUiInputDropdown = {
    id: "UserMenuContent.ChangeLanguage",
    directoryId: "",
    elementType: "",
    dictionaryId: "",
    caption: undefined,
    hint: undefined,
    defaultValue: undefined,
    placeholder: undefined,
    validation: {
      required: {
        value: 1,
        message: "",
      }
    }
  };
  //--------------------------------------------------------------------------- Select theme menu
  const preferredThemesInput: IUiInputDropdown = {
    id: "UserMenuContent.ChangeColorTheme",
    directoryId: "",
    elementType: "",
    dictionaryId: "",
    caption: undefined,
    hint: undefined,
    defaultValue: appUiContext.theme.themeId,
    placeholder: undefined,
    validation: {
      required: {
        value: 1,
        message: ""
      }
    }
  };
  //---------------------------------------------------------------------------
  if (props.ui)
    return (
      <div id="UserMenu" className={styles.container}>
        {headerOptionElements}
        <UserMenuButton
          onClick={onUserMenuButtonSelect}
        />

        {userMenuPopupOpen && popupMenuContent.options.length > 0 &&
          <PopupMenu
            ui={getTUiMenuContent(popupMenuContent)}
            anchorId="UserMenuButton"
            displayIcon={true}
            onClose={() => setUserMenuPopupOpen(false)}
            onSelect={onOptionSelect}
          />}

        {languagesPopupOpen && appUiContext.dictionaries?.supportedLocalesNative &&
          <InputDropdownPopup
            ui={preferredLanguageInput}
            options={appUiContext.dictionaries.supportedLocalesNative}
            selectedOptionId={appUiContext.locale.localeId}
            sortOptions={true}
            hideDescription={false}
            locale="native"
            onClose={onOptionsPopupClose}
            onBack={onOptionsPopupBack}
            onSelect={onLanguageChange}
          />}

        {themesPopupOpen && appUiContext.dictionaries?.colourThemes &&
          <InputDropdownPopup
            ui={preferredThemesInput}
            options={appUiContext.dictionaries.colourThemes}
            selectedOptionId={appUiContext.theme.themeId}
            sortOptions={true}
            hideDescription={false}
            onClose={onOptionsPopupClose}
            onBack={onOptionsPopupBack}
            onSelect={onThemeChange} // Close just languages popup but leave user menu popup open
          />}

      </div>
    );
  else return null;
}