import React, { useContext, useEffect, useState } from 'react';
import styles from './AddressAndContacts.module.css';
import { ClassBusinessLocationData, IBusinessLocationData } from '../../../business-space/business-editor/section-location/business-location-editor/IBusinessLocationData';
import BusinessLocationEditor from '../../../business-space/business-editor/section-location/business-location-editor/BusinessLocationEditor';
import { tmpUiBusinessLocationEditor } from '../../../business-space/business-editor/section-location/business-location-editor/IUiBusinessLocationEditor';
import { Contact } from '../../../business-space/business-editor/section-contacts/contact/Contact';
import ContactsListItem from '../../../business-space/business-editor/section-contacts/contacts-editor/contact-type-card/contacts-list-item/ContactsListItem';
import { IUiAdModule } from '../IUiAdModule';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import OptionLink from '../../options/option-link/OptionLink';
import { EOptionsContact, EOptionsLocation } from '../../../ad-content-editor/ad-modules-editor/AdModuleOptions';
import { IHighlightedItem } from '../../../business-space/business-editor/section-location/business-location-editor/business-location-listItem/ListItemBusinessLocation';
import { getUnitColor } from '../AdModule';
import { IContentUnitText } from '../../content/content-text/IContentUnitText';

interface IProps {
  ui?: IUiAdModule;
  businessId: string;
  locationData?: IBusinessLocationData<IContentUnitText>;
  contacts?: Contact<IContentUnitText>[];
  isReadonly: boolean;
  highlightedPath?: string[];
  onLocationOptionSelect?: (optionId: EOptionsLocation, locationId?: string) => void;
  onLocationDataUpdate?: (updatedLocationData: IBusinessLocationData<string>) => void;
  onContactOptionSelect?: (optionId: EOptionsContact, contactId?: string) => void;
  onContactUpdate?: (updatedContact: Contact<string>) => void;
}

export default function AddressAndContacts(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const [highlightedLocation, setHighlightedLocation] = useState<IHighlightedItem>();
  const [highlightedContact, setHighlightedContact] = useState<IHighlightedItem>();
  const contactsToDisplay = Contact.createContacts(props.contacts);
  const optionContactSelect = props.ui?.inputListContacts?.menuContent?.options?.find(option => option.id === "InputListContacts.MenuContent.OptionSelect");
  //--------------------------------------------------------------------------- Highlighted items
  useEffect(() => {
    if (!props.highlightedPath) return;
    //-------------------------------------------------------------------------
    // Determine whether location is highlighted
    const selectedLocation = props.locationData?.locations.filter(location => props.highlightedPath?.includes(location.id));
    if (selectedLocation && selectedLocation.length > 0) {
      setHighlightedLocation({
        path: props.highlightedPath,
        color: getUnitColor(selectedLocation[0].status)
      });
    } else {
      setHighlightedLocation(undefined);
    };
    //-------------------------------------------------------------------------
    // Determine whether contact is highlighted
    const selectedContact = props.contacts?.filter(contact => props.highlightedPath?.includes(contact.id));
    if (selectedContact && selectedContact.length > 0) {
      setHighlightedContact({
        path: props.highlightedPath,
        color: getUnitColor(selectedContact[0].status)
      });
    } else {
      setHighlightedContact(undefined);
    };
  }, [props.highlightedPath]);
  //---------------------------------------------------------------------------
  const onContactsSelect = () => {
    if (!props.onContactOptionSelect) return;
    props.onContactOptionSelect("ContactSelect");
  };
  //---------------------------------------------------------------------------
  const contactElements = contactsToDisplay.map(contact => {
    const dictionaryItem = appUiContext.dictionaries?.contactTypes?.find(item => item.id == contact.contactType);
    if (!dictionaryItem) return (
      <div>
        {`Unknown contact type (${contact.value})`}
      </div>);
    return (
      <ContactsListItem
        key={contact.id}
        ui={props.ui?.inputListContacts}
        contactType={dictionaryItem}
        data={contact}
        contacts={contactsToDisplay}
        addressBlockMode={true}
        isReadonly={props.isReadonly}
        highlightedItem={highlightedContact}
        onOptionSelect={props.onContactOptionSelect}
        onUpdate={props.onContactUpdate}
      />);
  });
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>

      <div className={styles.locations}>
        <BusinessLocationEditor
          ui={tmpUiBusinessLocationEditor}
          businessId={props.businessId}
          data={new ClassBusinessLocationData(props.locationData, true)} // true: convert IAdUnitText to string
          addressBlockMode={true}
          isReadonly={props.isReadonly}
          highlightedItem={highlightedLocation}
          onLocationOptionSelect={props.onLocationOptionSelect}
          onUpdate={props.onLocationDataUpdate}
        />
      </div>

      <div className={styles.contacts}>
        {contactElements}
        {!props.isReadonly && optionContactSelect &&
          <div className={styles.option}>
            <OptionLink
              ui={optionContactSelect}
              onSelect={onContactsSelect}
            />
          </div>}
      </div>

    </React.Fragment>
  );
}

