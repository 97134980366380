import { createPortal } from 'react-dom';
import '../../../../assets/css/themes.css';
import '../../../../assets/css/common.css';
import '../../../../assets/css/font.css';
import styles from './PopupTest.module.css';
import { ReactNode, useContext, useEffect, useLayoutEffect, useRef, WheelEvent, TouchEvent } from 'react';
import GetPopupStates, { IPopupState, IPopupStates, TAnimationType } from './GetPopupStates_v3';
import applyAnimatedAppearance from './applyAnimatedAppearance';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import CombineStyles from '../../../../utils/combineStyles';
import { ScreenType, useAppScreenContext } from '../../../../context/app-screen-context/AppScreenProvider';
import React from 'react';
import maximise from './maximise';
import restore from './restore';
import useClickOutsideDiv from '../../../../hooks/useClickOutsideDiv';

interface IProps {
  id: string;
  key?: number;
  anchorId?: string; // Div id that should serve as anchor of the popup, as in case of dropdown menu; If not provided, window is centered on the screen
  isModal?: boolean; // If true, a backdrop obscuring the rest of the screen will be displayed
  animation?: TAnimationType;
  closeOnClickOutside?: boolean; // Makes sense only in case if anchor is provided
  fixHeight?: boolean; // Prevent resizing based on popup content
  fullWindow?: boolean; // Maximise popup window to the browser window (on desktop, mobile window is maximised by default unless ignoreFullWindowOnMobile = true) 
  ignoreFullWindowOnMobile?: boolean; // For popups that should not be full-screen on mobile (like informer popup)
  fadeAwayTimeoutMs?: number; // Timeout the popup should disappear after
  shadowColor?: string;
  allowPropagation?: boolean;
  children: ReactNode;
  onClose: () => void;
  onAnimationEnd?: () => void;
}

export default function PopupTest(props: IProps) {
  const { appUiContext, appUiContextDispatch } = useContext(AppUiContextStore);
  const screenType = useAppScreenContext(); // Mobile = 0, Desktop = 1
  const firstRenderFullWindowState = useRef(props.fullWindow);
  const anchorRef = useRef(props.anchorId ? document.getElementById(props.anchorId) as HTMLDivElement : null);
  const popupRef = useClickOutsideDiv({
    handler: props.onClose,
    noop: (screenType === ScreenType.Mobile || !props.closeOnClickOutside) ? true : false,
    excludeDivRef: anchorRef
  });
  const popupStates = useRef<IPopupStates>();
  const stateBeforeFullWindow = useRef<IPopupState>();
  const timeoutId = useRef<any>();
  const contentHeight = useRef<number>(-1);
  const contentIsReady = useRef(false);
  //--------------------------------------------------------------------------- Consts
  const fullWindowFromProps = props.fullWindow == undefined ? false : props.fullWindow;
  //console.log(firstRenderFullWindowState, fullWindowFromProps)
  const ignoreFullWindowOnMobile = props.ignoreFullWindowOnMobile == undefined ? false : props.ignoreFullWindowOnMobile;
  const fullWindowOnMobile = (screenType == ScreenType.Mobile) && !ignoreFullWindowOnMobile;
  const fullWindow = fullWindowOnMobile || fullWindowFromProps;
  const currentZetIndex = appUiContext.zetIndex;
  const popupZetIndex = appUiContext.zetIndex + 2;
  //console.log(screenType)
  //--------------------------------------------------------------------------- First render: Z-index
  useEffect(() => {
    // Update z-index in context so every next popup would render on the top
    appUiContextDispatch({ type: "UpdateZetIndex", value: popupZetIndex });
    //-------------------------------------------------------------------------
    return (() => {
      contentHeight.current = -1;
      appUiContextDispatch({ type: "UpdateZetIndex", value: currentZetIndex });
    });
  }, []);
  //----------------------------------------------- Disappearance / animation end
  useEffect(() => {
    if (popupRef.current) {
      if (props.fadeAwayTimeoutMs) {
        //-----------------------------------------------------------------------
        // Set up timeout if the popup should disappear on timeout
        //console.log('fadeaway!')
        timeoutId.current = setTimeout(() => {
          //console.log("it's time!")
          if (popupRef.current) {
            popupRef.current.ontransitionend = () => {
              props.onClose();
            };
            requestAnimationFrame(() => {
              if (popupRef.current) {
                popupRef.current.style.transition = `opacity 1s ease-out`;
                popupRef.current.style.opacity = '0';
              };
            });
          };
        }, props.fadeAwayTimeoutMs);
      } else {
        //-----------------------------------------------------------------------
        popupRef.current.ontransitionend = callAnimationEnd;
      };
    };
  }, [popupRef.current]);
  //------------------------------------------------  ---- Switch full-window mode
  useEffect(() => {
    if (firstRenderFullWindowState.current != fullWindow) {
      if (!popupStates.current) {
        console.error("Popup is being resized, but states are undefined!");
        return;
      }
      //-----------------------------------------------------------------------
      // Handle request to switch full-window mode on the run
      //console.log("A-ha! Changing window size...", screenType == ScreenType.Mobile)
      if (fullWindow) {
        //if (props.fullWindow) {
        //console.log('maximise!')
        // Cache current window state to use for restoring
        stateBeforeFullWindow.current = popupStates.current?.final;
        maximise(popupRef);
      } else {
        //console.log('restore!')
        // Use cached initial state
        restore(popupRef, stateBeforeFullWindow.current);
      };
      // Save current full-window state
      //firstRenderFullWindowState.current = props.fullWindow;
      firstRenderFullWindowState.current = fullWindow;
    };
  }, [fullWindow]);
  //--------------------------------------------------------------------------- Animation
  useLayoutEffect(() => {
    if (contentIsReady.current = true) {
      //console.log("uselayouteffect")
      // Calculate popup states (initial and final) for animation:
      // Current popup position is a final position for the appearance animation
      // Initial position have to be calculated based on animation type
      if (popupRef.current) {
        const anchorRect = anchorRef ? anchorRef.current?.getBoundingClientRect() : undefined;
        const popupRect = popupRef.current.getBoundingClientRect();
        //console.log(popupRect.height, popupRect.width)
        popupStates.current = GetPopupStates({
          popupRect: popupRect,
          anchorRect: anchorRect,
          animationType: props.animation,
          fixHeight: props.fixHeight,
          fullWindow: fullWindow // if true - window final position is maximised one
        });
        //console.log(popupStates.current);
      };
      //-----------------------------------------------------------------------
      applyAnimatedAppearance(popupRef, popupStates);
    }
    // Giving it an empty array acts like componentDidMount as in, it only runs once.
    // Giving it no second argument acts as both componentDidMount and componentDidUpdate, as in it runs first on mount and then on every re-render.
    // Giving it an array as second argument with any value inside, eg , [variable1] will only execute the code inside your useEffect hook ONCE on mount, as well as whenever that particular variable (variable1) changes.
  }, [contentIsReady.current]);
  //---------------------------------------------------------------------------
  const callAnimationEnd = (event: TransitionEvent) => {
    //console.log("animation end:", event);
    if (popupRef.current == event.target && event.propertyName == "transform") {
      //console.log("animation end: props.onAnimationEnd()", event.propertyName);
      props.onAnimationEnd && props.onAnimationEnd();
    }

  }
  //---------------------------------------------------------------------------
  const stopPropagation = (e: WheelEvent<HTMLDivElement> | TouchEvent<HTMLDivElement>) => {
    // We need to stop propagation so underlying components listening to wheel and touch events would not capture it
    if (props.allowPropagation !== true)
      e.stopPropagation();
  };
  //--------------------------------------------------------------------------- Catch moment to start animations
  if (popupRef.current && Object.keys(popupRef.current).length > 0) {
    // If popup is not empty, check its height
    const popupRect = popupRef.current.getBoundingClientRect();
    if (contentHeight.current > -1) {
      // It's not a first render, so check if height is changed
      if (popupRect.height == contentHeight.current) {
        // Height is not changed from the last time, so we can be pretty sure content is fully rendered
        // Give a signal to useLayoutEffect it could calculate and start animations
        contentIsReady.current = true;
      } else {
        // Height is changed again, so it seems content is not fully rendered yet
        // Update cached size and wait for the next render
        contentHeight.current = popupRect.height;
      };
    } else {
      // It's a first time, so just store current height to check on next render
      contentHeight.current = popupRect.height;
    };
  }
  //---------------------------------------------------------------------------
  const portal = document.getElementById('portal');
  if (portal)
    return createPortal(
      <React.Fragment>

        {/* Backdrop */}
        {props.isModal &&
          <div
            className={CombineStyles([styles.backdrop, `theme-${appUiContext.theme.themeId}`])}
            style={{ zIndex: `${popupZetIndex - 1}` }}
            onWheel={stopPropagation}
            onTouchStart={stopPropagation}
            onTouchMove={stopPropagation}
            onTouchEnd={stopPropagation}
          />}

        {/* Popup window */}
        < div
          id={props.id}
          ref={popupRef}
          onWheel={stopPropagation}
          onTouchStart={stopPropagation}
          onTouchMove={stopPropagation}
          onTouchEnd={stopPropagation}
          className={
            CombineStyles([
              styles.container,
              `theme-${appUiContext.theme.themeId}`
            ])
          }
          style={{
            zIndex: `${popupZetIndex}`,
            boxShadow: props.shadowColor ? `0 0 16px 2px ${props.shadowColor}` : undefined
          }}>

          <div className={styles.content}>
            {props.children}
          </div>

        </div >

      </React.Fragment>,
      portal
    );
  else {
    console.error("No portal to render popup window");
    return null;
  };
}