import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../../context/app-auth-context/AppAuthContext";
import { apiBasePath, pathGetDocumentDraft } from "../../../../../utils/apiPathConstant";
import { apiGetPrivate, checkResponse } from "../../../../../utils/api";
import { stringFormatter } from "../../../../../utils/common";
import { DocumentDraft } from "../document-editor/IDocumentDraft";

interface IProps {
  msalInstance: IPublicClientApplication;
  authConfig?: IAuthConfig;
  abortSignal?: AbortSignal;
  documentId: string;
  localeId: string;
  lockRequired?: boolean;
}

export async function getDocumentDraftAsync(props: IProps): Promise<DocumentDraft> {
  if (!props.authConfig) throw new Error("getDocumentDraftAsync: auth config is not provided");
  //---------------------------------------------------------------------------
  let uri = `${apiBasePath}${stringFormatter(pathGetDocumentDraft, [props.documentId, props.localeId])}`;
  if (props.lockRequired) uri += "?lockRequired=true";
  const response = await apiGetPrivate(
    props.msalInstance,
    props.authConfig,
    uri,
    props.abortSignal
  );
  //---------------------------------------------------------------------------
  checkResponse(response, "getDocumentDraftAsync", props.abortSignal?.aborted);
  const draft = new DocumentDraft().initialize(response?.content);
  return draft;
}