import { useContext } from "react";
import { IUiBusinessWizard } from "../IUiBusinessWizardV2";
import WizardStepWrapper from "../step-wrapper/WizardStepWrapper";
import { AppUiContextStore } from "../../../../context/app-ui-context/AppUiContextProvider";
import GetDescription from "../../../common/functions/GetDescription";
import { WizardState } from "../IWizardState";
import { IUiOption } from "../../../common/options/IUiOption";
import FormOptions from "../../../common/form-options-bar/FormOptions";
import { getTUiMenuContent } from "../../../common/menu/menu-content/TUiMenuContent";
import InputDropdown from "../../../common/input-v2/input-dropdown/InputDropdown";

interface IProps {
  state: WizardState;
  ui: IUiBusinessWizard;
  onUpdate: (state: WizardState) => void;
  onExit: () => void;
}

export default function StepLocale(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const stepUi = props.ui.stepsDictionary.find(item => item.id == props.state.selectedStep);
  const stepData = props.state.steps.find(item => item.stepId == props.state.selectedStep);
  const prompt = GetDescription(appUiContext, props.state.selectedStep, stepUi?.description);
  const stepIsCompleted = stepData?.isCompleted ? true : false;
  const localeId = props.state.data.localeId;
  //---------------------------------------------------------------------------
  const onUpdate = (optionId?: string) => {
    if (!optionId) return;
    const updatedState = props.state.updateLocale(optionId);
    props.onUpdate(updatedState);
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (optionId: string) => {
    let updatedState: WizardState;
    switch (optionId) {
      case "OptionNext":
        updatedState = props.state.moveToNextStep(!stepIsCompleted);
        break;
      default:
        throw new Error(`StepAdRank: Unknown optionId ${optionId}`);
    };
    props.onUpdate(updatedState);
  };
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  props.ui.menuContent.options.forEach(option => {
    switch (option.id) {
      case "OptionNext":
        updatedOptions.push({
          ...option,
          iconId: "doubleArrowRight",
          isDisabled: !localeId,
          isDefault: !!localeId
        });
        break;
    };
  });
  const formOptions =
    <FormOptions
      ui={getTUiMenuContent({ ...props.ui.menuContent, options: updatedOptions })}
      applyMobileStyle={false}
      alignLeft={true}
      onSelect={onOptionSelect}
    />;
  //---------------------------------------------------------------------------
  return (
    <WizardStepWrapper
      ui={props.ui}
      promptString={prompt}
      optionsElement={formOptions}
      onExit={props.onExit}
    >
      <InputDropdown
        ui={{ ...props.ui.inputLocale, hideCaption: true }}
        data={localeId}
        options={appUiContext.dictionaries?.supportedLocales}
        onUpdate={onUpdate}
      />
    </WizardStepWrapper>
  );
}