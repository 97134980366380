//import styles from './ChecklistItems.module.css';
import React from "react";
import { DocumentDraftItem } from "../../../../administrator-console/documents/document-editor/IDocumentDraftItem";
import ChecklistItemTitle from "../checklist-item/ChecklistItemTitle";
import ChecklistItemSection from "../checklist-item/ChecklistItemSection";
import ChecklistItemParagraph from "../checklist-item/ChecklistItemParagraph";
import { IHoveredItem } from "../../request-form/DocumentRequestForm";

interface IProps {
  path?: string;
  prefix?: string;
  data: DocumentDraftItem[];
  isReadonly: boolean;
  onHover: (item?: IHoveredItem) => void;
  onReview?: (updatedItems: DocumentDraftItem[]) => void;
}

export default function ChecklistItems(props: IProps) {
  const level = props.path ? props.path.split(".").length : 0;
  //---------------------------------------------------------------------------
  const onReview = (updatedItem: DocumentDraftItem) => {
    if (!props.onReview) return;
    props.onReview([updatedItem]);
  };
  //---------------------------------------------------------------------------
  let i = 0;
  const firstUncompletedItem = props.data?.filter(item =>
    props.path ? item.path == props.path : !item.path
  ).find(item => !item.status?.isCompleted);
  const checklistItems = props.data?.filter(item =>
    props.path ? item.path == props.path : !item.path
  ).map(item => {
    let currentNumber = undefined;
    if (item.isNumbered) {
      i++;
      currentNumber = props.prefix ? `${props.prefix}.${i}` : `${i}`;
    };
    //-------------------------------------------------------------------------
    const expanded = item.fullPath == firstUncompletedItem?.fullPath;
    //-------------------------------------------------------------------------
    let itemElement = null;
    const subItems = props.data?.filter(subItem => subItem.path?.startsWith(item.fullPath));
    switch (item.type) {
      case "Title":
        itemElement =
          <ChecklistItemTitle
            key={item.id}
            data={item}
            isReadonly={props.isReadonly}
            isExpanded={expanded}
            onHover={props.onHover}
            onReview={onReview}
          />;
        break;
      case "Section":
        itemElement =
          <ChecklistItemSection
            key={item.id}
            level={level}
            prefix={currentNumber}
            data={item}
            subItems={subItems}
            isReadonly={props.isReadonly}
            isExpanded={expanded}
            onHover={props.onHover}
            onReview={props.onReview}
          />;
        break;
      case "Paragraph":
        itemElement =
          <ChecklistItemParagraph
            key={item.id}
            level={level}
            prefix={currentNumber}
            data={item}
            isReadonly={props.isReadonly}
            isExpanded={expanded}
            onHover={props.onHover}
            onReview={onReview}
          />;
        break;
    };
    return itemElement;
  });
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>
      {checklistItems}
    </React.Fragment>
  );
}