import React, { useContext, useState } from "react";
import styles from "./BusinessSearchSorter.module.css";
import { ListManagerContext } from "../../../../context/list-manager-context/ListManagerContextProvider";
import PopupTest from "../../../common/popup-v2/popup/PopupTest";
import { BusinessSearchSortItem } from "./BusinessSearchSortItem";
import Icon, { IconSize } from "../../../common/icon/Icon";
import { IconSortFilter } from "../../../uielements/icons";
import { EManagerMode } from "../../../../context/list-manager-context/IListManagerContextData";
import Toggler from "../../../common/toggler/Toggler";
//--------------------------------------------------------------------------
interface IProps {
  numberTotal?: number;
  numberFiltered?: number;
}
//--------------------------------------------------------------------------
export function BusinessSearchSorter(props: IProps) {
  const { listManagerContext, listManagerContextDispatch } = useContext(ListManagerContext);
  const [popupOpen, setPopupOpen] = useState<boolean>(false);
  //---------------------------------------------------------------------------
  const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    setPopupOpen(!popupOpen);
  }
  //---------------------------------------------------------------------------
  const onModeSwitch = () => {
    listManagerContextDispatch({ type: "SetMode", mode: EManagerMode.Advanced });
  }
  //---------------------------------------------------------------------------
  let popupItems = listManagerContext.sortableColumns?.map(column =>
    <BusinessSearchSortItem
      key={column.id}
      listColumn={column}
      onSelect={() => setPopupOpen(false)}
    />
  );
  //---------------------------------------------------------------------------
  // const containerStyles = CombineStyles([
  //   styles.container,
  //   //highlighted ? styles.highlighted : selected ? styles.selected : ""
  // ]);
  //---------------------------------------------------------------------------
  let divId = "BusinessSearchSorterPopupAnchor";
  let sortColumn = listManagerContext.selectedSortColumn ?? listManagerContext.defaultSortColumn;
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>
      <div>
        {`Showing ${props.numberFiltered ? props.numberFiltered : 0} of ${props.numberTotal ? props.numberTotal : 0}`}
      </div>
      <div className={styles.sortIcon}>
        <Icon imageName={IconSortFilter.sortBasic} size={IconSize.Small} />
      </div>
      <span style={{ fontWeight: "bold" }}>Sort by:</span>
      <div className={styles.sortType} onClick={onClick}>
        <span id={divId} >
          {sortColumn?.name}
        </span>
        <div className={styles.toggler}>
          <Toggler collapsed={!popupOpen} />
        </div>
      </div>

      <div className={styles.modeSwitch} >
        <Icon
          imageName={IconSortFilter.modeAdvanced}
          onClick={onModeSwitch}
          size={IconSize.Small}
        />
      </div>
      {popupOpen && popupItems &&
        <PopupTest
          id={"BusinessSearchSorter"}
          anchorId={divId}
          isModal={false}
          closeOnClickOutside={true}
          onClose={() => setPopupOpen(false)}
        >
          {popupItems}
        </PopupTest>
      }
    </div>
  );
}
