import { useContext } from 'react';
import styles from './DocumentRequestsListItem.module.css';
import { AppUiContextStore } from '../../../../../../context/app-ui-context/AppUiContextProvider';
import GetName from '../../../../../common/functions/GetName';
import Icon from '../../../../../common/icon/Icon';
import ApprovalRequestDetails from '../../../approval-request-details/ApprovalRequestDetails';
import RequestStatus from '../../../approval-request-status/RequestStatus';
import { IDocumentRequestListItem } from './IDocumentRequestListItem';

interface IProps {
  data: IDocumentRequestListItem;
  onSelect: (selectedRequestId: string, localeId: string) => void;
}

export default function DocumentRequestsListItem(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const iconId = props.data.documentType === "Legal" ? "documentLegal" : "documentManual";
  const localeName = appUiContext.dictionaries?.supportedLocales?.find(l => l.id == props.data.localeId);
  //---------------------------------------------------------------------------
  const onClick = () => {
    props.onSelect(props.data.id, props.data.localeId);
  };
  //---------------------------------------------------------------------------
  return (
    <div
      className={styles.container}
      onClick={onClick}
    >

      <div
        title={props.data.documentType}
        className={styles.icon}>
        <Icon key={iconId} imageName={iconId} />
      </div>

      <div className={styles.requestCaption}>
        <span className={styles.title}>
          {props.data.title}
        </span>
        <div className={styles.locale}>
          <span className={styles.localeName}>
            {GetName(appUiContext, props.data.localeId, localeName?.name)}
          </span>
        </div>
      </div>

      <div className={styles.details}>
        <ApprovalRequestDetails
          data={props.data}
          assignedTo={props.data.actualLogRecord?.assignedTo}
        />
      </div>

      <div
        //title={props.data.documentType}
        className={styles.status}>
        <RequestStatus data={props.data.status} />
      </div>

    </div>
  );
}