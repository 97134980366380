import { useMsal } from "@azure/msal-react";
import React, { Dispatch, ReactNode, useContext, useEffect, useLayoutEffect, useReducer, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { TUiBreadcrumb } from "../../components/common/breadcrumbs-trail/breadcrumb/TUiBreadcrumb";
import { TUiMenuContent } from "../../components/common/menu/menu-content/TUiMenuContent";
import { apiGet, apiGetPrivate, checkResponse } from "../../utils/api";
import { apiBasePath, appConfig, pathGetUserMessages, } from "../../utils/apiPathConstant";
import { getFromLS, lsSystemSettings, setToLS, RemoveFromLS, lsSystemVersion } from "../../utils/storage";
import { AppAuthContextStore } from "../app-auth-context/AppAuthContext";
import { Actions, AppUiContextReducer, ISetLocale } from "./AppUiContextReducer";
import { IAppUiLocaleContext } from "./IAppUiLocaleContext";
import { TSysSettings } from "./ISysSettings";
import { GetLocaleContextFromQueryString } from "./query-string-functions";
import { AppUiCache } from "./AppUiCache";
import { IUiDictionaries } from "../../components/common/dictionaries/IUiDictionaries";
import { EHttpStatusCode } from "../../utils/HttpStatusCodes";
import { ELogLevel } from "../../utils/logging";
import { IUserSessionPreferences, userSessionPreferencesKey } from "./IUserSessionPreferences";
import { getFromSessionStorage } from "../../utils/sessionStorage";
import getAppLocale from "./getAppLocale";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { stringFormatter } from "../../utils/common";
import { ClassUserMessage } from "../../components/messages/Messages";
import { v4 as createNewGuid } from "uuid";

//----------------------------------------------------------------------------
const logLevel: ELogLevel = ELogLevel.Information;

//-----------------------------------------------------------------------------
// Context Interfaces

export interface IRgbColors {
  accentMain: string;
  accentWrong: string;
  accentAttention: string;
}

export interface IAppUiThemeContext {
  themeId: string;
}

export interface IMainMenuButtonContext {
  onClickAction: () => void;
}

export interface IAppUiContext {
  locale: IAppUiLocaleContext;  // Application locale: a) chosen by user manually, or b) nearest to the user locale, or c) specified in URL
  theme: IAppUiThemeContext;
  preferredLanguages: string[];
  sysSettings: TSysSettings;
  breadcrumbs: TUiBreadcrumb[];
  uiCache?: AppUiCache; // Move to data context?
  dictionaries?: IUiDictionaries; // Move to data context?
  zetIndex: number; // To keep latest popup on top (at least until we didn't get rid of layers of popups)
  rgbColors?: IRgbColors;
  currentPagePath?: string; // Path constructed from menu option identifiers
  pathToRemember?: string; // Path that we could want to return to
  // Remove
  mainMenuButtonContext?: IMainMenuButtonContext; // Remove after it's removed from components
  mainMenuContent?: TUiMenuContent; // Remove after it's removed from components
}

interface IAppUiContextStore {
  appUiContext: IAppUiContext;
  appUiContextDispatch: Dispatch<Actions>;
}

export const AppUiContextStore = React.createContext({} as IAppUiContextStore);

export const defaultAppUiTheme: IAppUiThemeContext = { themeId: "dark" };
export const defaultFallbackLevel: number = 0;
export const defaultQueryString: string = "";

const defaultUiContext: IAppUiContext = {
  locale: {
    localeId: "en",
    fallbackLevel: 0,
    isImportant: false
  },
  theme: defaultAppUiTheme,
  preferredLanguages: [],
  sysSettings: {} as TSysSettings,
  mainMenuButtonContext: undefined,
  breadcrumbs: [],
  //adBlockDesigns: defaultAdBlockDesigns,
  zetIndex: 21
}

export function GetSysSettings(settings: TSysSettings) {
  settings.sysLocalesList = [
    settings.sysLocales.i18nLocale,
    settings.sysLocales.nativeLocale,
    settings.sysLocales.pseudoLocale,
    settings.sysLocales.pseudoRtlLocale
  ];
  return new TSysSettings(settings);
}

//-----------------------------------------------------------------------------
interface Props {
  msalInProgress: boolean;
  children: ReactNode;
}

export default function AppUiContextProvider(props: Props) {
  const { instance } = useMsal();
  const { appAuthContext, appAuthContextDispatch } = useContext(AppAuthContextStore);
  const [appUiContext, appUiContextDispatch] = useReducer(AppUiContextReducer, defaultUiContext);
  const [localeIsSorted, setLocaleIsSorted] = useState(false);
  const [themeIsSorted, setThemeIsSorted] = useState(false);
  const [settingsAreSorted, setSettingsAreSorted] = useState(false);
  const location = useLocation();
  const abortController = useRef<AbortController>();
  const initStarted = useRef(false);
  //------------------------------------------------ Version + SysSettings + colors
  useEffect(() => {
    //-------------------------------------------------------------------------
    //abortController.current = new AbortController();
    // Retrieve current application version ----------------------------------- Version
    //-------------------------------------------------------------------------
    if (initStarted.current)
      return;
    initStarted.current = true;
    const promiseVersion = new Promise<string>((resolve) => {
      var myHeaders = new Headers();
      myHeaders.append('pragma', 'no-cache');
      myHeaders.append('cache-control', 'no-store');

      var myInit = {
        method: 'GET',
        headers: myHeaders,
      };
      fetch("version.json", myInit)
        .then(response => {
          return response.json();
        })
        .then(json => {
          const versionNumber = `${json.major}.${json.minor}.${json.version}`;
          resolve(versionNumber);
        });
      // We don't catch exceptions here because if app version is not provided, it's okay
    });
    //------------------------------------------------------------------------- SysSettings
    // Retrieve system settings from cache if available
    //LogInformation(logLevel, "AppWrapper: setting up sysSettings");
    const promiseSettings = new Promise<TSysSettings>((resolve, reject) => {
      // User cached system settings if it's possible
      //LogInformation(logLevel, "trying to get cached sys settings...")
      const cachedData = getFromLS(lsSystemSettings);
      //LogInformation(logLevel, cachedData)
      if (cachedData) {
        //LogInformation(logLevel, "cached sys settings are used")
        resolve(new TSysSettings(cachedData));
      } else {
        // If there are no cached system settings, call the API
        //LogInformation(logLevel, "trying to retrieve sys settings from API...")
        const url = `${apiBasePath}${appConfig}`;
        apiGet(url)
          .then((response) => {
            //LogInformation(logLevel, response)
            if (response && response.status === EHttpStatusCode.OK) {
              // Store "raw" settings provided by API "just in case"
              setToLS(lsSystemSettings, response.content);
              // Return structured set of system settings
              resolve(GetSysSettings(response.content));
            } else {
              reject(`Retrieving system settings failed with status ${response.status}`)
            }
          })
          .catch((error) => {
            // In this case application cannot start and a special page should be displayed
            reject(`AppWrapper.Could not retrieve system settings. Error: ${error}`);
          });
      }
    });
    //------------------------------------------------------------------------- Set things up
    Promise.all([promiseVersion, promiseSettings])
      .then((values) => {
        const newAppVersion = values[0];
        const sysSettings = values[1];
        sysSettings.version = newAppVersion;

        // Update context with sysytem settingss
        appUiContextDispatch({ type: "SetSysSettings", sysSettings: sysSettings });
        //---------------------------------------------------------------------
        // Handle application version update
        const storedAppVersion = getFromLS(lsSystemVersion);
        if (storedAppVersion && newAppVersion && (storedAppVersion !== newAppVersion)) {
          // Remove LS entries referencing older app version
          RemoveFromLS(newAppVersion);
          window.location.reload();
        }
        setToLS(lsSystemVersion, newAppVersion);
      })
      .catch(() => {
        // In this case application cannot start and a special page should be displayed
        console.error("AppWrapper.Could not retrieve system settings");
      }).finally(() => {
        setSettingsAreSorted(true);
      });
    //-------------------------------------------------------------------------
    return (() => {
      abortController.current?.abort();
    });
  }, []);
  //--------------------------------------------------------------------------- Get app UI from cache (local storage)
  useLayoutEffect(() => {
    if (!appUiContext.uiCache) {
      appUiContext.uiCache = new AppUiCache();
    };
    appUiContext.uiCache.instance = instance;
    appUiContext.uiCache.user = appAuthContext.user;
    appUiContext.uiCache.config = appAuthContext.config;
  }, [appUiContext.uiCache, appAuthContext]);
  //--------------------------------------------------------------------------- Sort user locale and preferred languages out
  async function getLocaleAndPreferredLanguages(currentUserSessionPreferences: IUserSessionPreferences): Promise<ISetLocale> {
    return new Promise((resolve, reject) => {
      //console.log("getLocaleAndPreferredLanguages")
      // ------------------------------------------------------------------------ Locale
      let setLocale: ISetLocale = {
        type: "SetLocale",
        localeId: "en",
        fallbackLevel: 0,
        calledFrom: createNewGuid()
      };
      //------preferredLanguages---------------------------------------------------------
      //console.log("appAuthContext.user.preferredLanguages:", appAuthContext.user?.preferredLanguages);
      //console.log("appUiContext.msalInProgress:", props.msalInProgress);
      if (appAuthContext.user && appAuthContext.user.preferredLanguages && appAuthContext.user.preferredLanguages.length > 0) {
        setLocale.preferredLanguages = appAuthContext.user.preferredLanguages;
        //console.log("UserPreferredLanguages:", setLocale.preferredLanguages);
      } else {
        setLocale.preferredLanguages = navigator.languages.map(s => s);
        //console.log("BrowserPreferredLanguages:", setLocale.preferredLanguages);
      };
      // 1. Forced locale from URL
      const localeContextFromUrl = GetLocaleContextFromQueryString(location.search);
      if (localeContextFromUrl) {
        // If locale is set in URL it will override all the settings
        // Get query string from the current location (URL),
        // check if it contains language settings and update current locale context if so
        //console.log("AppUiContextProvider: Locale context is set from URL", location);
        setLocale.localeId = localeContextFromUrl.localeId;
        setLocale.fallbackLevel = localeContextFromUrl.fallbackLevel;
        setLocale.queryString = localeContextFromUrl.queryString;
        return resolve(setLocale);
        //-----------------------------------------------------------------------
      } else if (currentUserSessionPreferences && currentUserSessionPreferences.locale) {
        // 2. Authenticated user session preferences
        console.log("getLocaleAndPreferredLanguages: Locale context is set from user session storage");
        setLocale.localeId = currentUserSessionPreferences.locale;
        setLocale.fallbackLevel = defaultFallbackLevel;
        resolve(setLocale);
        return;
        //-----------------------------------------------------------------------
      } else if (appAuthContext.user && appAuthContext.user.preferredLanguage) {
        // 3. Authenticated user preferences from profile
        console.log("getLocaleAndPreferredLanguages: Locale context is set from user profile");
        setLocale.localeId = appAuthContext.user.preferredLanguage;
        setLocale.fallbackLevel = defaultFallbackLevel;
        return resolve(setLocale);
        //-----------------------------------------------------------------------
      } else {
        // 5. Browser settings
        // As the last resort, get locale from browser and find nearest application locale from API
        getAppLocale(navigator.language, abortController.current?.signal)
          .then((nearestAppLocale) => {
            console.log("getLocaleAndPreferredLanguages: Locale context is set from browser settings");
            setLocale.localeId = nearestAppLocale;
          })
          .catch(() => {
            console.error("getLocaleAndPreferredLanguages: Could not retrieve application locale from API, setting English locale");
          })
          .finally(() =>
            resolve(setLocale)
          );
      };
    });
  }
  //--------------------------------------------------------------------------- Sort user preferences out
  useEffect(() => {
    if (props.msalInProgress) {
      return;
    }
    //console.log("location, appAuthContext.user, props.msalInProgress", location, appAuthContext.user, props.msalInProgress);
    // Get user UI preferences
    const sessionStorageKey = appAuthContext.user
      ? `${userSessionPreferencesKey}.${appAuthContext.user.userIdentity.id}`
      : userSessionPreferencesKey;
    const currentUserSessionPreferences: IUserSessionPreferences = getFromSessionStorage(sessionStorageKey);
    //-------------------------------- -----------------locale and preferred languages
    getLocaleAndPreferredLanguages(currentUserSessionPreferences)
      .then(locale => {
        //console.log("getLocaleAndPreferredLanguages.then");
        appUiContextDispatch(locale);
      })
      .catch(error => {
        console.error(error);
        appUiContextDispatch({
          type: "SetLocale",
          localeId: "en",
          fallbackLevel: 0,
          calledFrom: "AppUiContextProvider.useEffect"
        });
      })
      .finally(() => {
        setLocaleIsSorted(true);
      })
    // ---------------------------------------------------------------------- Theme
    let themeId: string;
    if (currentUserSessionPreferences && currentUserSessionPreferences.theme) {
      // 1. User session preferences
      console.log("AppUiContextProvider: Theme context is set from session storage");
      themeId = currentUserSessionPreferences.theme;
    } else if (appAuthContext.user && appAuthContext.user.preferredColourTheme) {
      // 2. Authenticated user preferences
      // User is authenticated, so get settings from their profile
      console.log("AppUiContextProvider: Theme context is set from user profile");
      themeId = appAuthContext.user.preferredColourTheme;
    } else {
      // 3. Browser settings
      // If user has light theme set in browser, use it
      const userPrefersLight =
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: light)").matches;
      if (userPrefersLight) {
        themeId = "light";
        console.log("AppUiContextProvider: Theme context is set from browser settings: light");
      } else {
        // 4. Otherwise just use default theme
        themeId = defaultAppUiTheme.themeId;
        console.log("AppUiContextProvider: Theme context is set from browser settings: default");
      }
    };
    appUiContextDispatch({ type: "SetTheme", themeId: themeId });
    setThemeIsSorted(true);
  }, [location, appAuthContext.user, props.msalInProgress]);
  //---------------------------------------------------------------------------
  return (
    <AppUiContextStore.Provider value={{ appUiContext, appUiContextDispatch }}>
      {appUiContext.sysSettings.reactAppInsightsPlugin &&
        <AppInsightsContext.Provider value={appUiContext.sysSettings.reactAppInsightsPlugin}>
          {localeIsSorted && themeIsSorted && settingsAreSorted &&
            props.children}
        </AppInsightsContext.Provider>
      }
      {!appUiContext.sysSettings.reactAppInsightsPlugin && localeIsSorted && themeIsSorted && settingsAreSorted &&
        props.children}
    </AppUiContextStore.Provider>
  );
}
