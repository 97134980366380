import styles from './CountIndicator.module.css';

interface IProps {
  count?: number;
}

export default function CountIndicator(props: IProps) {
  const title = props.count ?
    `You have ${props.count} unread messages` :
    undefined;
  //---------------------------------------------------------------------------
  return (
    <div
      title={title}
      className={styles.container}
      style={{opacity: props.count ? 1 : 0}}
    >
      {props.count}
    </div>
  );
}