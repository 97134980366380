import styles from './DocumentsList.module.css';
import { useContext, useEffect, useState } from "react";
import ConsoleContent from "../../../../common/console-layout/console-content/ConsoleContent";
import FormOptions from "../../../../common/form-options-bar/FormOptions";
import GetHint from "../../../../common/functions/GetHint";
import { getTUiMenuContent } from "../../../../common/menu/menu-content/TUiMenuContent";
import { IUiOption } from "../../../../common/options/IUiOption";
import { IUiDocumentsList } from "./IUiDocumentsList";
import { AppUiContextStore } from "../../../../../context/app-ui-context/AppUiContextProvider";
import { optionIdDocuments, pathDocuments } from '../../AdministratorConsole';
import { TUiBreadcrumb } from '../../../../common/breadcrumbs-trail/breadcrumb/TUiBreadcrumb';
import { pathAdminConsole, pathAdministratorConsole } from '../../../AdminConsole';
import { getDocumentsListAsync } from '../functions/getDocumentsListAsync';
import { useAbortController } from '../../../../../hooks/useAbortController';
import { useMsal } from '@azure/msal-react';
import { AppAuthContextStore } from '../../../../../context/app-auth-context/AppAuthContext';
import EditformDocumentDetails from '../document-details/EditformDocumentDetails';
import { tmpUiEditformDocumentDetails } from '../document-details/IUiEditformDocumentDetails';
import { IDocumentDetails } from '../document-details/IDocumentDetails';
import { createDocumentDraftAsync } from '../functions/createDocumentDraftAsync';
import DocumentsListItem from './documents-list-item/DocumentsListItem';
import useNavigateWithContext from '../../../../../hooks/useNavigateWithContext';
import { NotificationContext } from '../../../../../context/notification-context/NotificationContextProvider';
import { IDocumentsListItem } from './documents-list-item/IDocumentsListItem';
import { createDocumentTranslationAsync } from '../functions/createDocumentTranslationAsync';

interface IProps {
  ui?: IUiDocumentsList;
  optionsForBreadcrumb?: IUiOption[];
}

export default function DocumentsList(props: IProps) {
  if (!props.ui) return null;
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { appUiContext, appUiContextDispatch } = useContext(AppUiContextStore);
  const { notificationContextDispatch } = useContext(NotificationContext);
  const navigate = useNavigateWithContext();
  const abortController = useAbortController("DocumentsList");
  const [isLoading, setIsLoading] = useState(false);
  const [documentsListItems, setDocumentsListItems] = useState<IDocumentsListItem[]>();
  const [newItem, setNewItem] = useState(false);
  //--------------------------------------------------------------------------- Breadcrumb, Data
  useEffect(() => {
    console.log("DocumentsList");
    const option = props.optionsForBreadcrumb?.find(o => o.id == optionIdDocuments);
    if (option) {
      const breadcrumb: TUiBreadcrumb = {
        id: option.id,
        caption: option.caption,
        path: `/${pathAdminConsole}/${pathAdministratorConsole}/${pathDocuments}`
      };
      appUiContextDispatch({ type: "UpdateBreadcrumbsTrail", value: breadcrumb });
    };
    //-------------------------------------------------------------------------
    setIsLoading(true);
    let controller = abortController.newController("getDocumentsListAsync");
    getDocumentsListAsync({
      msalInstance: instance,
      authConfig: appAuthContext.config,
      abortSignal: controller.signal
    }).then(documentsListItems => {
      setDocumentsListItems(documentsListItems);
    }).catch(error => {
      !controller.aborted && notificationContextDispatch({ type: "SetError", header: "Business Wizard", message: error });
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
    //-------------------------------------------------------------------------
    return (() => {
      abortController.abortOnUnmount();
    });
  }, []);
  //---------------------------------------------------------------------------
  const onCreate = (documentDetails: IDocumentDetails) => {
    setNewItem(false);
    setIsLoading(true);
    let controller = abortController.newController("getDocumentsListAsync");
    createDocumentDraftAsync({
      msalInstance: instance,
      authConfig: appAuthContext.config,
      abortSignal: controller.signal,
      documentDetails
    }).then(response => {
      const documentsListItems = response as IDocumentsListItem[];
      setDocumentsListItems(documentsListItems);
    }).catch(error => {
      !controller.aborted && notificationContextDispatch({ type: "SetError", header: "Business Wizard", message: error });
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
    return (() => {
      abortController.abortOnUnmount();
    });
  };
  //---------------------------------------------------------------------------
  const onEdit = (documentId: string, localeId: string) => {
    navigate(`/${pathAdminConsole}/${pathAdministratorConsole}/${pathDocuments}/${documentId}/${localeId}`);
  };
  //---------------------------------------------------------------------------
  const onTranslate = (documentId: string, targetLocaleId: string, sourceLocaleId: string) => {
    setIsLoading(true);
    let controller = abortController.newController("createDocumentTranslationAsync");
    createDocumentTranslationAsync({
      msalInstance: instance,
      authConfig: appAuthContext.config,
      abortSignal: controller.signal,
      documentId,
      sourceLocale: sourceLocaleId,
      targetLocale: targetLocaleId
    }).then(response => {
      const documentsListItems = response as IDocumentsListItem[];
      setDocumentsListItems(documentsListItems);
    }).catch(error => {
      !controller.aborted && notificationContextDispatch({ type: "SetError", header: "Business Wizard", message: error });
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
    return (() => {
      abortController.abortOnUnmount();
    });
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (optionId: string) => {
    switch (optionId) {
      case "DocumentsList.MenuContent.OptionCreateDocument":
        setNewItem(true);
        break;
    };
  };
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  props.ui.menuContent.options.forEach(option => {
    switch (option.id) {
      case "DocumentsList.MenuContent.OptionCreateDocument":
        updatedOptions.push({ ...option, iconId: "plus" });
        break;
    };
  });
  const formOptions =
    <FormOptions
      ui={getTUiMenuContent({ ...props.ui.menuContent, options: updatedOptions })}
      onSelect={onOptionSelect}
    />;
  //---------------------------------------------------------------------------
  const listItems = documentsListItems?.map(item => (
    <DocumentsListItem
      key={item.documentId}
      data={item}
      onEdit={onEdit}
      onTranslate={onTranslate}
    />
  ));
  //---------------------------------------------------------------------------
  return (
    <ConsoleContent
      prompt={GetHint(appUiContext, props.ui.id, props.ui.hint)}
      formOptions={formOptions}
      containerWidthPercent={70}
      isLoading={isLoading}
    >
      <div className={styles.container}>

        {listItems}

        {newItem &&
          <EditformDocumentDetails
            ui={tmpUiEditformDocumentDetails}
            onClose={() => setNewItem(false)}
            onCreate={onCreate}
          />}

      </div>
    </ConsoleContent>
  );
}