import { EBusinessTagExtendedState, IListItem } from "../business-space/business-editor/Business";
import { Buffer } from 'buffer';
import { IUiListItem } from "../common/input/input-dropdown/InputDropdown";
import GetText from "../common/functions/GetText";
import { IAppUiContext } from "../../context/app-ui-context/AppUiContextProvider";
import { paramSearchCategories } from "./business-search-editor/IBusinessSearchParams";

class TNodeCounter {
  count: number = 0;
}

export type ETagFoundStatus = 'Found' | 'NotFound' | 'Other';
export type ETagSearchResult =
  "NotFound" |
  "ExactMatch" |
  "LevelMatch" |
  "CategoryMatch" |
  "AbstractCategoryMatch" |
  "NotSearchedButExists";

export class TagDesc {
  path: string = "";
  found?: boolean;
  searchResult!: ETagSearchResult;
  ui?: IUiListItem;

  constructor(source: TagDesc, ui?: IUiListItem) {
    this.searchResult = source.searchResult;
    this.found = source.found;
    this.path = source.path;
    this.ui = ui;
  }
  getName(appUiContext: IAppUiContext): string {
    if (!this.ui)
      return this.path;
    else {
      //UiTagTreeNode
      return GetText({
        appUiContext: appUiContext,
        elementId: this.path ? this.path : this.ui.id,
        elementPart: this.ui.id,
        text: this.ui.name,
      })
      //return this.ui.getName(appUiContext);
    }
  }
}

export class TagTreeNode implements IListItem {
  id: string = "";
  group?: boolean;
  extendedState?: EBusinessTagExtendedState;
  items?: TagTreeNode[];
  inDictionary: boolean = false;
  level?: number;
  //parent?: TagTreeNode;

  static fromUrlSearchParams(
    searchParams: URLSearchParams | undefined,
    uiTags: IUiListItem): TagTreeNode | undefined {
    //-----------------------------------------------------------------------
    if (!searchParams) {
      //console.log("TagTreeNode.parseFromUrlSearchParams: no search params. Default search");
      return undefined;
    }

    //----------------------------------------------------------------------- (1) Set tags tree
    // Check if category(ies) is(are) specified
    const tagsTreeString = searchParams.get(paramSearchCategories);
    //console.log("TagTreeNode.parseFromUrlSearchParams.tagsTreeString:", tagsTreeString);

    //-----------------------------------------------------------------------
    // Get tags tree from URL params
    if (tagsTreeString) {
      try {
        const tagsTreeStringDecoded = Buffer.from(tagsTreeString, 'base64').toString('utf8');
        //console.log("TagTreeNode.parseFromUrlSearchParams.tagsTreeStringDecoded:", tagsTreeStringDecoded);
        const listItem: TagTreeNode = JSON.parse(tagsTreeStringDecoded);
        //console.log("TagTreeNode.parseFromUrlSearchParams.tagsTreeJson:", listItem);
        let result: TagTreeNode = TagTreeNode.fromListItem(listItem);
        result.group = true;
        result.checkDictionaryFull(uiTags);
        return result;
      } catch (error) {
        console.error(error);
        // clean up URL in case of incorrect value
      };
    };
    return undefined;
  }

  static fromListItem(listItem: IListItem): TagTreeNode {
    let node: TagTreeNode = new TagTreeNode();
    node.assign(listItem);
    //node.setLevel(0);
    return node;
  }

  private assign(source: IListItem) {
    this.id = source.id;
    this.group = source.group;
    if (source.items) {
      this.items = [];
      source.items?.forEach(item => {
        let node = new TagTreeNode();
        this.items?.push(node);
        node.assign(item);
      });
    }
  }

  private setLevel(value: number) {
    this.level = value;
    this.items?.forEach(item => {
      //item.parent = this;
      item.setLevel(value + 1);
    });
  }

  private compareDictionaryItems(dictionaryItem: TagTreeNode, counter: TNodeCounter) {
    if (this.items && dictionaryItem.items) {
      for (let i = this.items.length - 1; i >= 0; i--) {
        let thisItem: TagTreeNode | undefined = this.items.at(i);
        if (!thisItem)
          continue;
        let target = dictionaryItem.items?.find(item => item.id == thisItem?.id);
        if (!target) {
          // item not found in dictionary - remove it from items list
          this.items = this.items?.filter(item => item.id != thisItem?.id);
          counter.count++;
        }
        else {
          // item found - compare its children to dictionary
          thisItem.compareDictionaryItems(target, counter);
        }
      }
    }
  }

  checkDictionaryFull(listDictionary: IListItem): boolean {
    try {
      let nodeDictionary = TagTreeNode.fromListItem(listDictionary);
      let counter = new TNodeCounter();
      this.compareDictionaryItems(nodeDictionary, counter);
      return counter.count == 0;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
}

export class UiTagTreeNode implements IUiListItem {
  id: string = "";
  group?: boolean;
  name?: any;
  description?: any;
  searchString?: any;
  items?: UiTagTreeNode[];
  selected: boolean = false;
  path?: string;
  parent?: UiTagTreeNode;
  isRoot?: boolean;

  private assign(source: IUiListItem, parent?: UiTagTreeNode): UiTagTreeNode {
    this.id = source.id;
    this.group = source.group;
    this.name = source.name;
    this.description = source.description;
    this.searchString = source.searchString;
    this.selected = source.selected;
    this.parent = parent;
    if (parent) {
      if (parent.isRoot)
        this.path = this.id;
      else if (parent.path)
        this.path = `${parent.path}.${this.id}`;
    }
    if (source.items) {
      this.items = [];
      for (let i = 0; i < source.items.length; i++) {
        let item = source.items[i];
        let node = new UiTagTreeNode();
        this.items?.push(node);
        node.assign(item, this);
      }
    }
    return this;
  }

  static fromUiListItem(source: IUiListItem): UiTagTreeNode {
    let result = new UiTagTreeNode();
    result.isRoot = true;
    result.assign(source);
    return result;
  }

  nodeByPath(path: string): UiTagTreeNode | undefined {
    let paths = path.split('.');
    let parent: UiTagTreeNode = this;
    let items = this.items;
    for (let i = 0; i < paths.length; i++) {
      let filtered = items?.filter(item => item.id == paths[i]);
      if (filtered && filtered.length == 1) {
        parent = filtered[0];
        items = parent.items;
      }
    }
    if (parent && parent.path == path)
      return parent;
    return undefined;
  }

  getName(appUiContext: IAppUiContext): string {
    return GetText({
      appUiContext: appUiContext,
      elementId: this.path ? this.path : this.id,
      elementPart: this.id,
      text: this.name,
    })
  }
}