//import styles from './ContentDirectoryTranslator.module.css';
import { useContext, useEffect, useState } from "react";
import { useAbortController } from "../../../../../hooks/useAbortController";
import { useParams } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { AppAuthContextStore } from "../../../../../context/app-auth-context/AppAuthContext";
import DirectoryEditorWrapper from "../../../common/directory-editor/directory-editor-wrapper/DirectoryEditorWrapper";
import { tmpUiDirectoryEditor } from "../../../common/directory-editor/IUiDirectoryEditor";
import { pathAdminConsole, pathSystemTranslator } from "../../../AdminConsole";
import { pathContent } from "../../SystemTranslatorConsole";
import ContentDirectoryNode from "../content-directory-node/ContentDirectoryNode";
import { NotificationContext } from "../../../../../context/notification-context/NotificationContextProvider";
import { DirectoryEditorContext } from "../../../../../context/directory-editor-context/DirectoryEditorContextProvider";
import { IDirectoryEditorStateInitial } from "../../../../../context/directory-editor-context/IDirectoryEditorState";
import { directoryDraftStatesFullDetailsV2 } from "../../../../../context/directory-editor-context/directory-editor-settings/directoryDraftStatesFullDetailsV2";
import { TUser } from "../../../../../context/app-auth-context/TUser";
import { ContentDirectoryDraft } from "./IContentDirectoryDraft";
import { ClassContentDirectoryDraftItem } from "./IContentDirectoryDraftItem";
import { IDirectoryDraftAbstract } from "../../../../../context/directory-editor-context/IDirectoryDraftAbstract";
import { saveContentDirectoryDraft } from "../functions/saveContentDirectoryDraft";
import { getContentDirectoryDraft } from "../functions/getContentDirectoryDraft";
import { IUiOption } from "../../../../common/options/IUiOption";
import { deleteContentDirectoryDraft } from "../functions/deleteContentDirectoryDraft";
import { submitContentDirectoryDraft } from "../functions/submitContentDirectoryDraft";

interface IProps {
}

export default function ContentDirectoryTranslator(props: IProps) {
  const { directoryId, localeId } = useParams();
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { notificationContextDispatch } = useContext(NotificationContext);
  const { directoryEditorContext, directoryEditorContextDispatch } = useContext(DirectoryEditorContext);
  const [isLoading, setIsLoading] = useState(true);
  const abortController = useAbortController("ContentDirectoryTranslator");
  const basePath = `/${pathAdminConsole}/${pathSystemTranslator}/${pathContent}`;
  let gridTemplateColumns = 'min-content minmax(2em, 100%) min-content minmax(2em, 1%)'; // Id, badges, context menu
  const stateGroups = directoryDraftStatesFullDetailsV2;
  const user: TUser | undefined = appAuthContext.user ? appAuthContext.user : undefined;
  const translationDraft = directoryEditorContext.editorState?.draft as ContentDirectoryDraft;
  const translationDraftItems = translationDraft?.items;
  //--------------------------------------------------------------------------- Abort controller
  useEffect(() => {
    getDraft(false);
    //-------------------------------------------------------------------------
    return (() => {
      abortController.abortOnUnmount();
    });
  }, []);
  //---------------------------------------------------------------------------
  const getDraft = (lockRequired: boolean) => {
    if (!directoryId) return;
    if (!localeId) return;
    if (!user) return;
    setIsLoading(true);
    let controller = abortController.newController("getContentDirectoryDraft");
    getContentDirectoryDraft({
      msalInstanse: instance,
      authConfig: appAuthContext.config,
      directoryId,
      localeId,
      lockRequired,
      abortSignal: controller.signal
    }).then(draft => {
      const initialState: IDirectoryEditorStateInitial = {
        directoryType: "Document",
        basePath: `${basePath}/${directoryId}/${localeId}`,
        returnPath: basePath,
        id: draft.id,
        name: draft.name?.value,
        description: draft.description?.value,
        isNew: false,
        editMode: lockRequired && draft.locker?.statusCode == 200,
        draft: draft,
        original: draft.directoryTranslation,
        stateGroups: stateGroups,
        user: user
      };
      directoryEditorContextDispatch({ type: "Initialize", data: initialState });
    }).catch(error => {
      !controller.aborted && notificationContextDispatch({ type: "SetError", header: "Directory Translation Editor", message: error });
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const saveDraft = () => {
    if (!translationDraft) return;
    if (!user) return;
    setIsLoading(true);
    let controller = abortController.newController("saveContentDirectoryDraft");
    saveContentDirectoryDraft({
      msalInstanse: instance,
      authConfig: appAuthContext.config,
      directoryContent: translationDraft,
      isNew: !translationDraft.summary.savedAt,
      abortSignal: controller.signal
    }).then(draft => {
      const initialState: IDirectoryEditorStateInitial = {
        directoryType: "Document",
        basePath: `${basePath}/${directoryId}/${localeId}`,
        returnPath: basePath,
        id: draft.id,
        name: draft.name?.value,
        description: draft.description?.value,
        isNew: false,
        editMode: draft.locker?.statusCode == 200,
        draft: draft,
        stateGroups: stateGroups,
        user: user
      };
      directoryEditorContextDispatch({ type: "Initialize", data: initialState });
    }).catch(error => {
      !controller.aborted && notificationContextDispatch({ type: "SetError", header: "Directory Translation Editor", message: error });
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const deleteDraft = () => {
    if (!translationDraft) return;
    if (!user) return;
    setIsLoading(true);
    let controller = abortController.newController("deleteContentDirectoryDraft");
    deleteContentDirectoryDraft({
      msalInstanse: instance,
      authConfig: appAuthContext.config,
      directoryId: translationDraft.directoryId,
      locale: translationDraft.locale,
      abortSignal: controller.signal
    }).then(draft => {
      const initialState: IDirectoryEditorStateInitial = {
        directoryType: "Document",
        basePath: `${basePath}/${directoryId}/${localeId}`,
        returnPath: basePath,
        id: draft.id,
        name: draft.name?.value,
        description: draft.description?.value,
        isNew: !draft.original,
        editMode: false,
        draft: draft,
        stateGroups: stateGroups,
        user: user
      };
      directoryEditorContextDispatch({ type: "Initialize", data: initialState });
    }).catch(error => {
      !controller.aborted && notificationContextDispatch({ type: "SetError", header: "Directory Translation Editor", message: error });
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const submitDraft = () => {
    if (!directoryId) return;
    if (!localeId) return;
    if (!user) return;
    setIsLoading(true);
    let controller = abortController.newController("submitContentDirectoryDraft");
    submitContentDirectoryDraft({
      msalInstanse: instance,
      authConfig: appAuthContext.config,
      directoryId: translationDraft.directoryId,
      localeId: translationDraft.locale,
      abortSignal: controller.signal
    }).then(draft => {
      const initialState: IDirectoryEditorStateInitial = {
        directoryType: "Document",
        basePath: `${basePath}/${directoryId}/${localeId}`,
        returnPath: basePath,
        id: draft.id,
        name: draft.name?.value,
        description: draft.description?.value,
        isNew: !draft.original,
        editMode: false,
        draft: draft,
        stateGroups: stateGroups,
        user: user
      };
      directoryEditorContextDispatch({ type: "Initialize", data: initialState });
    }).catch(error => {
      !controller.aborted && notificationContextDispatch({ type: "SetError", header: "Directory Translation Editor", message: error });
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const enableEditMode = () => {
    getDraft(true);
  };
  //---------------------------------------------------------------------------
  const disableEditMode = () => {
    // const updatedState = state?.getUpdatedState({ editMode: false });
    // console.log(updatedState);
    // setState(updatedState);
  };
  //---------------------------------------------------------------------------
  const onSelectSourceLocale = (localeId?: string) => {
    // const updatedState = state?.getUpdatedState({ sourceLocale: localeId });
    // setState(updatedState);
  };
  //---------------------------------------------------------------------------
  const updateDraft = (updatedDraft: IDirectoryDraftAbstract) => {
    directoryEditorContextDispatch({
      type: "Update",
      data: {
        draft: updatedDraft
      }
    });
  };
  //---------------------------------------------------------------------------
  const onItemUpdate = (updatedItem: ClassContentDirectoryDraftItem) => {
    if (!directoryEditorContext?.editorState.draft) return;
    const updatedItems = translationDraftItems.map(item => item.id == updatedItem.id ? updatedItem : item);
    const updatedDraft = directoryEditorContext.editorState.draft.getUpdated({ items: updatedItems });
    updateDraft(updatedDraft);
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (optionId: string) => {
    switch (optionId) {
      //-----------------------------------------------------------------------
      case "OptionEditModeEnable":
        enableEditMode();
        break;
      //-----------------------------------------------------------------------
      case "OptionEditModeDisable":
        disableEditMode();
        break;
      //-----------------------------------------------------------------------
      case "OptionSave":
        saveDraft();
        break;
      //-----------------------------------------------------------------------
      case "OptionDelete":
        deleteDraft();
        break;
      //-----------------------------------------------------------------------
      case "OptionSubmit":
        submitDraft();
        break;
    };
  };
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  tmpUiDirectoryEditor.menuContent.options.forEach(option => {
    switch (option.id) {
      case "OptionAdd":
        break;
      default:
        updatedOptions.push(option);
        break;
    }
  });
  //---------------------------------------------------------------------------
  const items = translationDraft?.items.map(item => (
    <ContentDirectoryNode
      key={item.id}
      draftLocale={translationDraft.locale}
      sourceLocale={"i18n"}
      data={item}
      isReadonly={!directoryEditorContext.editMode}
      onUpdate={onItemUpdate}
    />
  ))
  //---------------------------------------------------------------------------
  return (
    <DirectoryEditorWrapper
      ui={{
        ...tmpUiDirectoryEditor,
        menuContent: {
          ...tmpUiDirectoryEditor.menuContent,
          options: updatedOptions
        }
      }}
      gridTemplateColumns={gridTemplateColumns}
      isLoading={isLoading}
      onSelectSourceLocale={onSelectSourceLocale}
      onOptionSelect={onOptionSelect}
    >
      {items}
    </DirectoryEditorWrapper>
  );
}