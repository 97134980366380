import { IDictionaryItem } from "../../components/admin-console/administrator-console/dictionaries/common-dictionary/IDictionaryDraftItem";
import { IDictionariesListItem } from "../../components/admin-console/common/dictionary-draft-models/IDictionariesListItem";
import { ClassDirectoryItemStateGroup, IDirectoryDraftItemStateGroup } from "./directory-editor-settings/IDirectoryDraftItemStateGroup";
import { Actions } from "./DirectoryEditorContextReducer";
import { EDraftItemState } from "./EDraftItemState";
import { IDirectorySummary } from "./IDirectoryDraftAbstract";
import { DirectoryDraftItem, IDirectoryDraftItem } from "./IDirectoryDraftItem";
import { IDirectoryEditorState } from "./IDirectoryEditorState";

export type EDirectoryType = "Dictionary" | "Directory" | "Document";

export interface IDirectoryDraftFilter {
  keyword?: string;
  draftState: EDraftItemState; // current filter value
  stateGroups: ClassDirectoryItemStateGroup[];
  //searchById: boolean;
}

export interface IDirectoryEditorContextData {
  id: string;
  directoryType: EDirectoryType;
  basePath: string;
  returnPath?: string;
  name?: string;
  description?: string;
  sourceLocale?: string;
  isReadonly?: boolean;
  editMode: boolean;
  editorState: IDirectoryEditorState;
  filter?: IDirectoryDraftFilter;
  dictionariesList?: IDictionariesListItem[];
  dictionaryOriginals?: IDictionary[];
  isInitialized: boolean;
  stateGroups?: IDirectoryDraftItemStateGroup[];
}

export class ClassDirectoryEditorContext implements IDirectoryEditorContextData {
  id!: string;
  directoryType!: EDirectoryType;
  basePath!: string;
  returnPath?: string;
  name?: string;
  description?: string;
  sourceLocale?: string;
  editMode!: boolean;
  editorState!: IDirectoryEditorState;
  filter!: IDirectoryDraftFilter;
  dictionariesList?: IDictionariesListItem[];
  dictionaryOriginals?: IDictionary[];
  isInitialized: boolean = false;
  stateGroups!: ClassDirectoryItemStateGroup[];
  lastAction?: Actions;
  //---------------------------------------------------------------------------
  constructor(source: IDirectoryEditorContextData) {
    Object.assign(this, source);
  };
  //---------------------------------------------------------------------------
  updateStateGroups() {
    const directoryDraft = this.editorState.draft;
    const actualStateGroups: ClassDirectoryItemStateGroup[] = [];
    //-------------------------------------------------------------------------
    this.stateGroups.forEach(group => {
      // Count directory items satisfying the group draftstate
      const count = directoryDraft.items.filter(item => {
        return (item.draftState & group.draftState) != EDraftItemState.None;
      }).length;
      //console.log(group.id, groupDraftState, count);
      if (count > 0) {
        actualStateGroups.push(group);
      };
    });
    //-------------------------------------------------------------------------
    this.filter.stateGroups = actualStateGroups;
  };
  //---------------------------------------------------------------------------
  updateFilterDraftState() {
    this.filter.stateGroups.forEach(group => {
      if (group.enabled) {
        this.filter.draftState |= group.draftState;
      };
    });
  };
  //---------------------------------------------------------------------------
  getFilteredItems(): IDirectoryDraftItem[] {
    const directoryDraft = this.editorState?.draft;
    if (!directoryDraft) return [];
    console.log('getFilteredItems', directoryDraft.items);
    const filteredItems = directoryDraft.items.filter(item => {
      //let resultText = true;
      let resultState = true;
      //-----------------------------------------------------------------------
      // Filter by state
      if (this.filter.draftState !== EDraftItemState.All) {
        resultState = (item.draftState & this.filter.draftState) != EDraftItemState.None;
        //console.log(item.id, state.filter.draftState, item.draftState)
      };
      return resultState;
    });
    console.log('getFilteredItems', filteredItems);
    return filteredItems;
  };
  //---------------------------------------------------------------------------
  filterConditionIsMet(item: DirectoryDraftItem): boolean {
    let resultState = true;
    let resultKeyword = true;
    //-------------------------------------------------------------------------
    // Filter by state
    if (this.filter.draftState !== EDraftItemState.All) {
      resultState = (item.draftState & this.filter.draftState) != EDraftItemState.None;
      //console.log('filterConditionIsMet', item.id, this.filter.draftState, item.draftState, resultState);
    };
    //-------------------------------------------------------------------------
    // Filter by keyword
    if (this.filter.keyword) {
      resultKeyword = item.keywordFilterConditionIsMet(this.filter.keyword);
    };
    //-------------------------------------------------------------------------
    return resultState && resultKeyword;
  };
}

export interface IDictionary {
  dictionaryId: string;
  locale: string;
  name: string;
  description: string;
  isSortedManually: boolean;
  isHierarchical: boolean;
  rootIsLocked: boolean;
  items: IDictionaryItem<string>[];
  summary: IDirectorySummary;
}