import { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import styles from './DictionaryEditorWrapper.module.css';
import React from 'react';
import WikiSpinner from '../../../app-layout/spinner/wikiSpinner';
import ConsoleTabLayout from '../../../common/console-layout/console-tab-layout/ConsoleTabLayout';
import DictionaryEditorHeader from './dictionary-editor-header/DictionaryEditorHeader';
import { AppAuthContextStore } from '../../../../context/app-auth-context/AppAuthContext';
import DraftHistory from '../../administrator-console/dictionaries/common/draft-history/DraftHistory';
import DictionaryEditorFilter, { IDictionaryDraftFilter } from './dictionary-editor-filter/DictionaryEditorFilter';
import { IDictionaryEditorState } from '../dictionary-editor-models/IDictionaryEditorState';
import CombineStyles from '../../../../utils/combineStyles';
import { ScreenType, useAppScreenContext } from '../../../../context/app-screen-context/AppScreenProvider';
import FormOptions from '../../../common/form-options-bar/FormOptions';
import { TUiMenuOption } from '../../../common/menu/menu-content/menu-option/TUiMenuOption';
import useNavigateWithContext from '../../../../hooks/useNavigateWithContext';
import PopupMessage from '../../../common/popup-v2/popup-message/PopupMessage';
import { IDocumentDraftHistoryRecord } from '../dictionary-draft-models/IDocumentDraftHistoryRecord';
import { IDictionaryDraftCommon } from '../dictionary-draft-models/DictionaryDraft';
import { ELockType } from '../../../common/locks/TLocker';
import { useMsal } from '@azure/msal-react';
import { Locker } from '../../../common/locks/Locker';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import { dictionaryEditorWrapperUi } from './IUiDictionaryEditor';

interface IConfirmationDialog {
  type: "Delete" | "Discard";
  optionId?: "optionDisableEditMode" | "optionClose";
  message: string;
}

export interface IDictionaryEditorWrapperSetup {
  mode: "Editor" | "Translator";
  role: ELockType;
  entityType: "Dictionary" | "UI Directory";
  //entityId?: string;
}

// Wrapper inner state
export interface IDictionaryEditorWrapperState {
  id: string;
  name?: string;
  description?: string;
  historyRecords: IDocumentDraftHistoryRecord[];
  lastHistoryRecord: IDocumentDraftHistoryRecord;
  userPermissions: IUserPermissions;
}
interface IUserPermissions {
  canEdit: boolean;
  canPublish: boolean;
}

interface IProps {
  setup: IDictionaryEditorWrapperSetup;
  state?: IDictionaryEditorState<IDictionaryDraftCommon>;
  tableHeader?: ReactNode;
  children: ReactNode;
  isLoading: boolean;
  showItemAdditionalAttributes?: boolean; // If dictionary item needs to display additional attributes
  showItemStatus?: boolean; // If dictionary item needs to display dictionary item status (accessibility)
  onEnterEditMode: () => void;
  onFilterUpdate: (updatedFilter: IDictionaryDraftFilter) => void;
  onSourceLocaleUpdate?: (locale: string) => void;
  onOptionSelect: (selectedOptionId: string, releaseLockCallback?: () => void) => void;
  newUi?: boolean;
}

export default function DictionaryEditorWrapper(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { appUiContextDispatch } = useContext(AppUiContextStore);
  const screenType = useAppScreenContext();
  const navigate = useNavigateWithContext();
  const [state, setState] = useState<IDictionaryEditorWrapperState>();
  const [isLoading, setIsLoading] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState<IConfirmationDialog>();
  const wrapperDivRef = useRef<HTMLDivElement>(null);
  //---------------------------------------------------------------------------
  const propsLocker = props.state?.draft?.locker;
  //console.log("DictionaryEditorWrapper.props.state?.draft?.locker:", props.state?.draft?.locker);
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (props.state?.draft?.historyRecords) {
      // Find last history record
      const lastHistoryRecord = props.state.draft.historyRecords[props.state.draft.historyRecords.length - 1];
      //-----------------------------------------------------------------------
      // Decide whether user is allowed to edit/publish
      const userPermissions: IUserPermissions = {
        canEdit: true,
        canPublish: true
      };
      if (lastHistoryRecord?.step == "Draft") {
        userPermissions.canEdit = true;
        userPermissions.canPublish = true;
      } else {
        // Check previous steps down to the last draft step
        if (props.state.draft.historyRecords) {
          let i = props.state?.draft.historyRecords.length - 1;
          while (i >= 0) {
            let historyItem = props.state.draft.historyRecords[i];
            //console.log(i, historyItem)
            if (historyItem.staffUser && historyItem.staffUser.userId == appAuthContext.user?.userIdentity.id) {
              userPermissions.canEdit = false;
              userPermissions.canPublish = false;
            };
            i = (historyItem.step == "Draft") ? -1 : i - 1;
            //console.log(i);
          };
        };
      };
      //-----------------------------------------------------------------------
      setState({
        ...props.state,
        id: props.state.draft.dictionaryId,
        name: props.state.draft.name,
        description: props.state.draft.description,
        historyRecords: props.state.draft.historyRecords,
        lastHistoryRecord: lastHistoryRecord,
        userPermissions: userPermissions
      });
      //-----------------------------------------------------------------------      
      if (props.state.breadcrumb) {
        appUiContextDispatch({ type: "UpdateBreadcrumbsTrail", value: props.state.breadcrumb });
      };
    };
  }, [props.state]);
  //---------------------------------------------------------------------------
  useEffect(() => {
    setIsLoading(props.isLoading);
  }, [props.isLoading]);
  //---------------------------------------------------------------------------
  const onClose = () => {
    props.state?.basePath && navigate(props.state?.basePath);
  };
  //---------------------------------------------------------------------------
  const onConfirmationDialogOptionSelect = (optionId: string) => {
    setConfirmationDialog(undefined);
    switch (optionId) {
      case "optionOk":
        switch (confirmationDialog?.type) {
          case "Delete":
            props.onOptionSelect("optionDraftDelete");
            break;
          case "Discard":
            console.log(confirmationDialog.optionId)
            if (confirmationDialog.optionId == "optionDisableEditMode")
              props.onOptionSelect(confirmationDialog.optionId);
            else
              onClose();
            break;
        };
        break;
      case "optionCancel":
        break;
    };
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (selectedOptionId: string) => {
    switch (selectedOptionId) {
      case "optionClose":
        if (props.state?.isUpdated) {
          setConfirmationDialog({
            type: "Discard",
            optionId: selectedOptionId,
            message: 'Please confirm you are going to discard unsaved changes'
          });
        } else {
          onClose();
        };
        break;
      //-----------------------------------------------------------------------
      case "optionEnableEditMode":
        //console.log('enable edit mode!')
        props.onEnterEditMode();
        //tryToLockDocument();
        break;
      //-----------------------------------------------------------------------
      case "optionDisableEditMode":
        //console.log('disable edit mode!')
        if (props.state?.isUpdated) {
          setConfirmationDialog({
            type: "Discard",
            optionId: selectedOptionId,
            message: 'Please confirm you are going to discard unsaved changes'
          });
        } else {
          props.onOptionSelect(selectedOptionId);
        };
        break;
      //-----------------------------------------------------------------------
      case "optionDraftDelete":
        // We don't release lock here because we need this document lock until it deleted
        setConfirmationDialog({
          type: "Delete",
          message: 'Please confirm you are going to delete current draft version'
        });
        break;
      //-----------------------------------------------------------------------
      default:
        props.onOptionSelect(selectedOptionId);
        break;
    }
  };
  //--------------------------------------------------------------------------- Options
  var updatedOptions: TUiMenuOption[] = [];
  dictionaryEditorWrapperUi.optionsMenu.options.forEach(option => {
    switch (option.id) {
      //-----------------------------------------------------------------------
      case "optionClose":
        updatedOptions.push(option);
        break;
      //-----------------------------------------------------------------------
      case "optionEnableEditMode":
        if (state?.userPermissions.canEdit && !props.state?.editModeIsEnabled) {
          updatedOptions.push(option);
        };
        break;
      //-----------------------------------------------------------------------
      case "optionDisableEditMode":
        if (props.state?.editModeIsEnabled) {
          updatedOptions.push({ ...option, iconFile: "pencilOff" });
        };
        break;
      //-----------------------------------------------------------------------
      case "optionAdd":
        // This option only available if dictionary root is not locked
        if (!props.state?.draft?.rootIsLocked
          && props.setup.mode == "Editor"
          && state?.userPermissions.canEdit
          && props.state?.editModeIsEnabled
        ) {
          //const disabled = state?.lastHistoryRecord?.savedAt == "0001-01-01T00:00:00";
          updatedOptions.push({ ...option, disabled: false });
        };
        break;
      //-----------------------------------------------------------------------
      case "optionDraftDelete":
        if (state?.userPermissions.canEdit && props.state?.editModeIsEnabled) {
          const disabled = state?.lastHistoryRecord?.savedAt == "0001-01-01T00:00:00";
          updatedOptions.push({ ...option, disabled: disabled });
        };
        break;
      //-----------------------------------------------------------------------
      case "optionPublish":
        if (props.state?.editModeIsEnabled) {
          // Option Publish should be enabled only if:
          // - draft is saved to database
          // - has changes compared to published version
          // - has no errors
          const isNotSaved = ((state?.lastHistoryRecord.step == "Draft") && state?.lastHistoryRecord?.savedAt == "0001-01-01T00:00:00") ? true : false;
          const disabled = !props.state.isReadyToPublish || !state?.userPermissions.canPublish || isNotSaved || props.state.isUpdated;
          //console.log(!props.state.isReadyToPublish, !state?.userPermissions.canPublish, isNotSaved, props.state.isUpdatedInSession)
          //console.log(disabled)
          updatedOptions.push({ ...option, disabled: disabled });
        };
        break;
      //-----------------------------------------------------------------------
      case "optionSave":
        if (props.state?.editModeIsEnabled) {
          const disabled = !props.state.isUpdated;
          updatedOptions.push({ ...option, disabled: disabled });
        };
        break;
    }
  });
  //---------------------------------------------------------------------------
  const editorOptions =
    <FormOptions
      ui={{ ...dictionaryEditorWrapperUi.optionsMenu, options: updatedOptions }}
      optionIdVisibleOnMobile="optionClose"
      onSelect={onOptionSelect}
    />;
  //---------------------------------------------------------------------------
  // Define grid columns template based on props
  let gridTemplateColumns = 'minmax(2em, 30%) minmax(2em, 100%)'; // Id, Name
  if (props.showItemAdditionalAttributes) gridTemplateColumns = gridTemplateColumns + ' minmax(2em, 100%)'; // Additional attributes
  if (props.showItemStatus) gridTemplateColumns = gridTemplateColumns + ' min-content'; // Status
  gridTemplateColumns = gridTemplateColumns + ' min-content'; // Badges
  if (props.state?.editModeIsEnabled) gridTemplateColumns = gridTemplateColumns + ' 2em'; // Content menu
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>
      <WikiSpinner show={isLoading} />
      <ConsoleTabLayout
        toolbar={editorOptions}
        allowScroll={true}
      >
        <div ref={wrapperDivRef} className={styles.container}>
          <div className={styles.common}>

            <DictionaryEditorHeader
              setup={props.setup}
              ui={dictionaryEditorWrapperUi.headerUi}
              data={props.state?.draft}
              sourceLocale={props.state?.sourceLocale}
              onSourceLocaleUpdate={props.onSourceLocaleUpdate}
            />

            <Locker
              locker={propsLocker}
              inSession={props.state?.editModeIsEnabled}
            />

            {state?.historyRecords && state?.lastHistoryRecord && props.state?.numberOfApprovals &&
              <DraftHistory
                currentStatus={state.lastHistoryRecord}
                historyItems={state.historyRecords}
                numberOfApprovals={props.state.numberOfApprovals}
              />}

            <DictionaryEditorFilter
              ui={dictionaryEditorWrapperUi.filterUi}
              data={props.state?.filter}
              showGroupingsSelector={props.setup.mode == "Editor"}
              onUpdate={props.onFilterUpdate}
            />

          </div>

          {/* Content table */}
          {!props.newUi &&
            <div className={CombineStyles([
              styles.content,
              screenType == ScreenType.Mobile ? styles.mobile : ""
            ])}>
              <table className={styles.table}>
                {props.tableHeader &&
                  <thead>
                    {props.tableHeader}
                  </thead>}
                <tbody>
                  {props.children}
                </tbody>
              </table>
            </div>}

          {!!props.newUi &&
            <div
              className={styles.gridContainer}
              style={{ gridTemplateColumns: gridTemplateColumns }}
            >
              {props.children}
            </div>}

        </div>
      </ConsoleTabLayout>

      {confirmationDialog &&
        <PopupMessage
          id='DraftDeleteConfirmationMessage'
          type='Confirmation'
          header={`${props.setup.mode} Console. ${state?.name} ${props.setup.entityType} Editor`}
          message={confirmationDialog.message}
          optionsMenuContent={dictionaryEditorWrapperUi.confirmationDialogMenu}
          show={!!confirmationDialog}
          onOptionSelect={onConfirmationDialogOptionSelect}
          onCancel={() => onConfirmationDialogOptionSelect("optionCancel")}
        />}

    </React.Fragment>
  );
}