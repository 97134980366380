import { EDraftItemState } from "../../../../../context/directory-editor-context/EDraftItemState";
import { IDraftStateResult } from "../../../../../context/directory-editor-context/IDirectoryDraftAbstract";
import { DirectoryDraftItem, IDirectoryDraftItem } from "../../../../../context/directory-editor-context/IDirectoryDraftItem";
import { IEditable } from "../../../../common/edit-form/IEditFormStateV2";
import { IDirectoryTranslationDraftItem } from "../../../common/directory-translator/translation-editor/IDirectoryTranslationDraftItem";
import { ITranslation } from "../../../common/directory-translator/translation-editor/ITranslation";

export interface IContentDirectoryOriginalItem {
  id: string;
  parameters?: string[];
  text: string;
}

export interface IContentDirectoryTranslationItem {
  id: string;
  text: ITranslation;
}

export interface IContentDirectoryDraftItem extends IContentDirectoryTranslationItem, IDirectoryDraftItem, IDirectoryTranslationDraftItem {
  text: ITranslation;
  itemOriginal?: IContentDirectoryOriginalItem; // Item translation from translation doc in Edit database
  itemTranslation?: IContentDirectoryTranslationItem;     // Item translations published to CACTUS
}

export class ClassContentDirectoryDraftItem extends DirectoryDraftItem implements IContentDirectoryDraftItem, IEditable {
  id!: string;
  text!: ITranslation;
  draftState!: EDraftItemState;
  itemOriginal?: IContentDirectoryOriginalItem;
  itemTranslation?: IContentDirectoryTranslationItem;
  //itemPublished?: any;
  //---------------------------------------------------------------------------
  constructor(source: IContentDirectoryDraftItem) {
    super(source);
    Object.assign(this, source);
    this.init();
  };
  //---------------------------------------------------------------------------
  private init() {
    if (this.isTranslationEdited) {
      this.isNoTranslation = false;
    };
  };
  //---------------------------------------------------------------------------
  initialize(): IEditable {
    const item = new ClassContentDirectoryDraftItem(this);
    //item.init();
    return item;
  };
  //---------------------------------------------------------------------------
  isEqual(object: IEditable): boolean {
    let isEqual = true;
    const typedObject = object as ClassContentDirectoryDraftItem;
    if (this.text.fallbackAccepted !== typedObject.text.fallbackAccepted) {
      isEqual = false;
    };
    if (this.text.value !== typedObject.text.value) {
      isEqual = false;
    };
    return isEqual;
  };
  //---------------------------------------------------------------------------
  isValid(): boolean {
    let isValid = true;
    if (!this.text.fallbackAccepted && !this.text.value) {
      isValid = false;
    };
    return isValid;
  };
  //---------------------------------------------------------------------------
  checkChangesAndValidate(initial?: IContentDirectoryDraftItem): IDraftStateResult {
    let isUpdatedInSession = false;
    let isUpdated = false;
    let isValid = true;
    //-------------------------------------------------------------------------
    if (!initial) {
      // New item as compared to session initial state
      isUpdatedInSession = true;
    } else if (this.text.fallbackAccepted != initial.text.fallbackAccepted) {
      isUpdatedInSession = true;
    } else if (this.text.value != initial.text.value) {
      isUpdatedInSession = true;
    };
    //-------------------------------------------------------------------------
    if (!this.itemTranslation) {
      // New item as compared to existing translation
      isUpdated = true;
    } else {
      if (this.text?.fallbackAccepted != this.itemTranslation.text?.fallbackAccepted) {
        isUpdated = true;
      };
      if (this.text?.value != this.itemTranslation.text?.value) {
        isUpdated = true;
      };
    };
    //-------------------------------------------------------------------------
    this.isNothing = this.getIsNothing();
    //-------------------------------------------------------------------------
    return {
      isUpdatedInSession,
      isUpdated,
      isValid
    };
  };
  //---------------------------------------------------------------------------
  keywordFilterConditionIsMet(keyword: string): boolean {
    let result = this.id.toLowerCase().includes(keyword);
    if (this.text.value) {
      result = result || this.text.value.toLowerCase().includes(keyword);
    };
    return result;
  };
}