import CombineStyles from '../../../../utils/combineStyles';
import styles from './InputsGroupWrapper.module.css';
import { ReactNode, useEffect, useRef } from "react";

interface IProps {
  headers?: ReactNode;
  children: ReactNode;
  addPadding?: boolean;
  addBackground?: boolean;
}

export default function InputsGroupWrapper(props: IProps) {
  const resizeObserver = useRef<ResizeObserver>();
  const divRef = useRef<HTMLDivElement>(null);
  //--------------------------------------------------------------------------- ResizeObserver
  useEffect(() => {
    resizeObserver.current = new ResizeObserver(entries => {
      // console.log(entries.length)
      // for (let entry of entries) {
      //   //console.log(entry.target.firstElementChild?.clientWidth)
      //   // entry.target is the observed element
      //   // entry.contentRect contains the size information
      //   //console.log(          `New size: ${entry.contentRect.width}px by ${entry.contentRect.height}px`        );
      // };
    });
    //-------------------------------------------------------------------------
    return (() => {
      resizeObserver.current?.disconnect();
    });
  }, []);
  //--------------------------------------------------------------------------- Observe div
  useEffect(() => {
    if (!divRef.current) return;
    resizeObserver.current?.observe(divRef.current);
  }, [divRef.current]);
  //---------------------------------------------------------------------------
  return (
    <div
      ref={divRef}
      className={CombineStyles([
        styles.wrapper,
        props.addPadding ? styles.withPadding : '',
        props.addBackground ? styles.withBackground : ''
      ])}>
      <div className={styles.content}>
        {props.children}
      </div>
    </div>
  );
}