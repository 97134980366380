import { IUiInteractiveForm } from "../../../../common/forms/IUiInteractiveForm";

export interface IUiEditformDocumentRequest extends IUiInteractiveForm {

}

export const tmpUiEditformDocumentRequest: IUiEditformDocumentRequest = {
  id: "DocumentRequestForm",
  directoryId: "",
  elementType: "",
  caption: undefined,
  hint: undefined,
  menuContent: {
    id: "",
    directoryId: "",
    elementType: "",
    hint: undefined,
    options: [{
      id: "OptionClose",
      directoryId: "",
      elementType: "",
      caption: { en: "Close" },
      hint: undefined,
    }, {
      id: "OptionCancel",
      directoryId: "",
      elementType: "",
      caption: { en: "Cancel" },
      hint: undefined,
    }, {
      id: "OptionReset",
      directoryId: "",
      elementType: "",
      caption: { en: "Reset" },
      hint: undefined,
    }, {
      id: "OptionApprove",
      directoryId: "",
      elementType: "",
      caption: { en: "Approve" },
      hint: undefined,
    }, {
      id: "OptionReject",
      directoryId: "",
      elementType: "",
      caption: { en: "Reject" },
      hint: undefined,
    }]
  }
};