import { IRequestRejectionReason } from "../../admin-console/moderator-console/checklist/checklist-node-reject/IRequestRejectionReason";

export type EContentUnitStatus = "Draft" | "PendingApproval" | "Approved" | "Rejected" | "Invalid";

export interface IContentUnit {
  status?: ClassContentUnitStatus;
}

export interface IContentUnitStatus {
  statusId: EContentUnitStatus;
  rejectionReason?: IRequestRejectionReason;
  isReadonly: boolean;
  isApproved: boolean;
  isInTrouble: boolean;
  isCompleted: boolean;
}

export class ClassContentUnitStatus implements IContentUnitStatus {
  statusId!: EContentUnitStatus;
  rejectionReason?: IRequestRejectionReason;
  get isReadonly() {
    switch (this.statusId) {
      case "Draft":
      case "Invalid":
        return false;
      default:
        return true; // ?????????????
    };
  };
  get isApproved() {
    switch (this.statusId) {
      case "Approved":
        return true;
      default:
        return false;
    };
  };
  get isInTrouble() {
    switch (this.statusId) {
      case "Rejected":
      case "Invalid":
        return true;
      default:
        return false;
    };
  };
  get isCompleted() {
    switch (this.statusId) {
      case "Approved":
      case "Rejected":
        return true;
      default:
        return false;
    };
  };
  //---------------------------------------------------------------------------
  constructor(statusId: EContentUnitStatus, rejectionReason?: IRequestRejectionReason) {
    this.statusId = statusId;
    this.rejectionReason = rejectionReason;
  }; 
}