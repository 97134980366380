import { useState } from 'react';
import styles from './ContentImage.module.css';
import ImagePlaceholder from './image-placeholder/ImagePlaceholder';
import { SmoothImageLoader } from '../../image-editor/SmoothImageLoader';
import React from 'react';
import { IContentUnitImage } from './IContentUnitImage';

interface IProps {
  data?: IContentUnitImage;
  isReadonly: boolean;
}

export default function ContentImage(props: IProps) {
  const [imageUri, setImageUri] = useState<string>();
  const imageData = props.data?.imageData;
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>
      <SmoothImageLoader
        imageData={imageData}
        sourceSize={"Thumbnail"}
        targetSize={"Medium"}
        onLoad={setImageUri}
      />

      {imageData ?
        <img
          className={styles.image}
          src={imageUri}
        />
        : 
        <ImagePlaceholder />}

    </React.Fragment>
  );
}