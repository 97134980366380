import { useMsal } from "@azure/msal-react";
import React, { ReactNode, useEffect, useReducer, useRef } from "react";
import { AppAuthContextStore, IAppAuthContext } from "./AppAuthContext";
import { AppAuthContextReducer } from "./AppAuthContextReducer";
import { updateUserPromise } from "./user";
import { ServiceWorkerContextProvider } from "../service-worker-context/ServiceWorkerContextProvider";
import { useAbortController } from "../../hooks/useAbortController";
import getUserGeopositionAsync from "./getUserGeoPositionAsync";
import { AccountInfo } from "@azure/msal-browser";
import { getUserRemotelyAsync } from "./functions/getUserRemotelyAsync";
import { getUnreadMessagesCountAsync } from "./functions/getUnreadMessagesCountAsync";
import { TImageData } from "../../components/common/image-editor/TImageData";

interface Props {
  initialState: IAppAuthContext;
  children?: ReactNode;
}

export default function AppAuthContextProvider(props: Props) {
  const msalContext = useMsal();
  const [appAuthContext, appAuthContextDispatch] = useReducer(
    AppAuthContextReducer,
    props.initialState
  );
  const abortController = useAbortController("AppAuthContextProvider");
  const activeAccount = msalContext.instance.getActiveAccount();
  const accountRef = useRef<AccountInfo | null>();
  //const tokenChannel = useRef<MessageChannel>(); // for service worker
  const userIsBeingSet = useRef(false);
  //--------------------------------------------------------------------------- User geoposition
  useEffect(() => {
    let controller = abortController.newController("getCurrentGeoLocation");
    getUserGeopositionAsync({ abortController: controller }).then(position => {
      appAuthContextDispatch({ type: "SetUserGeoposition", position: position });
    });
    //-----------------------------------------------------------------------
    return (() => {
      abortController.abortOnUnmount();
    });
  }, []);
  //--------------------------------------------------------------------------- User
  useEffect(() => {
    //console.log("useffect.accounts", msalContext.inProgress, activeAccount, appAuthContext.user)
    // Do something here only if msal context is settled
    if (msalContext.inProgress != "none") return;

    if (!activeAccount) {
      accountRef.current = null;
      setUser(undefined);
      return;
    }
    // compare activeAccount with accountRef to eliminate unnecessary API calls for getUser
    if (!accountRef.current) {
      accountRef.current = activeAccount;
    }
    else if (accountRef.current.localAccountId == activeAccount.localAccountId) {
      return;
    }
    //-------------------------------------------------------------------------
    let userAccountInfo = undefined;
    if (activeAccount) {
      userAccountInfo = activeAccount;
    };
    setUser(userAccountInfo);
  }, [msalContext.inProgress]);
  //--------------------------------------------------------------------------- Service Worker
  /* useEffect(() => {
    //console.log("AppAuthContextProvider.useEffect[instance, config, user]:",
    //instance, appAuthContext.config, appAuthContext.user);
    if (!instance || !appAuthContext.config || !appAuthContext.user) {
      return;
    }
    //console.log("AppAuthContextProvider.useEffect[instance].activeAccount:", instance.getActiveAccount());
    TImageData.config = appAuthContext.config;
    TImageData.instance = instance;
    //https://felixgerschau.com/how-to-communicate-with-service-workers/
    // if (!tokenChannel.current) {
    //   //console.log("AppAuthContextProvider.useEffect[instance, config, user]: no token channel, create one...");
    //   tokenChannel.current = new MessageChannel();
    //   // First we initialize the channel by sending
    //   // the port to the Service Worker (this also
    //   // transfers the ownership of the port)
    //   navigator.serviceWorker.controller?.postMessage({
    //     messageType: 'messageChannelOpen',
    //   }, [tokenChannel.current.port2]);
    // }
    // else {
    //   //console.log("AppAuthContextProvider.useEffect[instance, config, user]: token channel exists:", tokenChannel.current);
    // }

    // // Listen to the response
    // tokenChannel.current.port1.onmessage = (event) => {
    //   // Print the result
    //   console.log("AppAuthContextProvider.tokenChannel. Message from SW. Event:", event);
    //   const userAccount = instance.getActiveAccount();
    //   if (userAccount && appAuthContext.config) {
    //     console.log("AppAuthContextProvider.tokenChannel. Requesting msal token...");
    //     const accessTokenRequest = {
    //       scopes: appAuthContext.config.apiScopes,
    //       account: userAccount,
    //       forceRefresh: true,
    //       redirectUri: "/" //https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/errors.md#block_iframe_reload
    //     };
    //     instance.acquireTokenSilent(accessTokenRequest as SilentRequest)
    //       .then((accessToken) => {
    //         console.log("AppAuthContextProvider.tokenChannel. Token acquired. Sending to SW...", accessToken.accessToken);
    //         tokenChannel.current?.port1.postMessage(accessToken.accessToken);
    //       })
    //   }
    //   else {
    //     console.log("userAccount or appAuthContext.config is undefined");
    //   }
    // }
    return (() => {
      //console.log("AppAuthContextProvider.useEffect[instance, appAuthContext.config].return");
      //navigator.serviceWorker.controller?.postMessage({ messageType: 'tokenChannelClose' });
      //tokenChannel.current?.port1.close();
    });
  }, [instance, appAuthContext.config, appAuthContext.user]); */
  //--------------------------------------------------------------------------- Unread messages
  useEffect(() => {
    if (!appAuthContext.user)
      return;
    TImageData.config = appAuthContext.config;
    TImageData.instance = msalContext.instance;
    let controller = abortController.newController("getUserUnreadMessageCount");
    getUnreadMessagesCountAsync({
      msalInstance: msalContext.instance,
      authConfig: appAuthContext.config,
      abortSignal: controller.signal
    }).then(messagesCount => {
      appAuthContextDispatch({ type: "UpdateUnreadUserMessageCount", count: messagesCount });
    }).catch(error => {
      !controller.aborted && console.error(error);
    });
  }, [appAuthContext.user]);
  //---------------------------------------------------------------------------
  const setUser = (accountInfo?: AccountInfo) => {
    if (accountInfo) {
      if (appAuthContext.user && appAuthContext.user.userIdentity.id == accountInfo.localAccountId) {
        // Do nothing user is in place
        console.log("User is authenticated, and all data is in place: do nothing");
      } else {
        // Get user data and set auth context
        console.log("User is authenticated, get user data");
        getUserData(accountInfo);
      };
    } else {
      console.log("User is not authenticated, clear auth context")
      appAuthContextDispatch({ type: "RemoveUser", value: appAuthContext.user?.userIdentity.id });
      appAuthContextDispatch({ type: "SetInProcess", value: false });
    };
  };
  //---------------------------------------------------------------------------
  const getUserData = (accountInfo: AccountInfo) => {
    if (!appAuthContext.config) throw "AppAuthContext: cannot get user, auth config is not set";
    let controller = abortController.newController("getUserRemotely");
    // NOTE: Use GetUserLocally if app is offline!
    getUserRemotelyAsync({
      msalInstance: msalContext.instance,
      authConfig: appAuthContext.config,
      abortSignal: controller.signal
    }).then(user => {
      let newUser = accountInfo.idTokenClaims?.["newUser"];
      if (newUser) {
        user.preferredLanguage = navigator.language;
        user.preferredLanguages = navigator.languages.map(x => x);
        updateUserPromise(msalContext.instance, user, props.initialState.config);
      };
      console.log("getUserRemotely.User:", user);
      !controller.aborted && appAuthContextDispatch({ type: "SetUser", value: user });
    }).catch((error) => {
      console.error("Cannot retrieve user profile remotely. Error: " + error);
      appAuthContextDispatch({ type: "RemoveUser", value: appAuthContext.user?.userIdentity.id });
    }).finally(() => {
      !controller.aborted && appAuthContextDispatch({ type: "SetInProcess", value: false });
    });
  };
  //---------------------------------------------------------------------------
  return (
    <AppAuthContextStore.Provider
      value={{ appAuthContext, appAuthContextDispatch }}
    >
      <ServiceWorkerContextProvider
        user={appAuthContext.user}
        config={appAuthContext.config}
        instance={msalContext.instance}
      >
        {props.children}
      </ServiceWorkerContextProvider>
    </AppAuthContextStore.Provider>
  );
}
