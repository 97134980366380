import styles from "./ConsoleMenuOption.module.css";
import { TUiMenuOption } from "../../../menu/menu-content/menu-option/TUiMenuOption";
import Icon, { IconSize } from "../../../icon/Icon";
import GetText from "../../../functions/GetText";
import {
  ScreenType,
  useAppScreenContext,
} from "../../../../../context/app-screen-context/AppScreenProvider";
import { AppUiContextStore } from "../../../../../context/app-ui-context/AppUiContextProvider";
import { useContext } from "react";
import CombineStyles from "../../../../../utils/combineStyles";
import GetHint from "../../../functions/GetHint";
import CountIndicator from "../../../count-indicator/CountIndicator";

interface IProps {
  ui: TUiMenuOption;
  isSelected: boolean;
  isClickable: boolean;
  isCollapsed: boolean;
  isBlinking?: boolean;
  onSelect: (optionId: TUiMenuOption) => void;
}

export default function SidebarMenuOption(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const screenType = useAppScreenContext();
  //---------------------------------------------------------------------------
  const toggleSelection = () => {
    props.isClickable && props.onSelect(props.ui);
  };
  //---------------------------------------------------------------------------
  return (
    <div
      title={GetHint(appUiContext, props.ui.id, props.ui.hint)}
      className={CombineStyles([
        screenType === ScreenType.Mobile ? styles.containerMobile : styles.containerDesktop,
        props.isSelected ? styles.selected : "",
        props.isClickable ? styles.clickable : "",
        props.isBlinking ? styles.blink : ''
      ])}
      onClick={toggleSelection}>

      {props.ui.iconFile &&
        <div className={styles.icon}>

          <Icon
            imageName={props.ui.iconFile}
            size={IconSize.Large}
            reserveSpace={true}
          />

          <div className={styles.iconIndicator}>
            <CountIndicator
              count={props.isCollapsed ? props.ui.count : undefined}
            />
          </div>

        </div>}

      {!props.isCollapsed &&
        <div className={styles.caption}>
          {GetText({
            appUiContext: appUiContext,
            elementId: props.ui.id,
            elementPart: "Caption",
            text: props.ui.caption,
          })}
        </div>}

      {!props.isCollapsed &&
        <div className={styles.captionIndicator}>
          <CountIndicator
            count={props.ui.count}
          />
        </div>}

    </div>
  );
}
