import React, { useContext } from 'react';
import GetCaption from '../../../../common/functions/GetCaption';
import InputWrapper from '../../../../common/input-v2/input-wrapper/InputWrapper';
import { ITranslation } from './ITranslation';
import { IUiTranslationEditor } from './IUiTranslationEditor';
import styles from './TranslationEditor.module.css';
import { AppUiContextStore } from '../../../../../context/app-ui-context/AppUiContextProvider';
import InputsGroupWrapper from '../../../../common/input-v2/inputs-group-wrapper/InputsGroupWrapper';
import SourceValue from './source-value/SourceValue';
import TranslationEditorInput from './translation-editor-input/TranslationEditorInput';
import GetTranslationWithLocale from '../../../../common/functions/GetTranslationWithLocale';

export interface ITranslationEditorData {
  elementId: string;
  elementPart: string;
  draftLocale: string;
  draftValue?: string;
  draftFallbackAccepted: boolean;
  sourceLocale?: string;
  sourceValue?: string;
  publishedTranslations?: any; //UiTextProperty
}

interface IProps {
  ui: IUiTranslationEditor;
  data?: ITranslationEditorData;
  parameters?: string[];
  editModeIsEnabled: boolean;
  onUpdate: (updatedTranslation: ITranslation) => void;
}

export default function TranslationEditor(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const parameters = props.parameters?.map(p => `[${p}]`).join(', ');
  //const pattern = ".*\[User\.Name\].*";
  //var re = new RegExp(pattern);
  const regexps = getRegexes(props.parameters);
  //---------------------------------------------------------------------------
  const onSwitch = (fallbackAccepted: boolean) => {
    if (!props.editModeIsEnabled) return;
    props.onUpdate({
      value: props.data?.draftValue,
      fallbackAccepted: fallbackAccepted
    });
  };
  //---------------------------------------------------------------------------
  const onUpdate = (updatedValue?: string) => {
    let valueIsValid = true;
    const valueToTest = updatedValue ? updatedValue : '';
    regexps.forEach(regexp => {
      valueIsValid = valueIsValid && regexp.test(valueToTest);
    });
    valueIsValid && props.onUpdate({
      value: updatedValue,
      fallbackAccepted: !!props.data?.draftFallbackAccepted
    });
  };
  //---------------------------------------------------------------------------
  const onSourceValueCopy = () => {
    if (!props.editModeIsEnabled) return;
    if (props.data?.sourceValue && !props.data?.draftValue) {
      props.onUpdate({
        value: props.data.sourceValue,
        fallbackAccepted: !!props.data?.draftFallbackAccepted
      });
    };
  };
  //---------------------------------------------------------------------------
  // Get fallback value and locale
  let fallbackValue = undefined;
  if (props.data) {
    const updatedTranslations = props.data.publishedTranslations ? JSON.parse(JSON.stringify(props.data.publishedTranslations)) : {};
    if (updatedTranslations)
      delete updatedTranslations[props.data.draftLocale];
    fallbackValue = GetTranslationWithLocale(
      appUiContext.sysSettings,
      props.data.elementId,
      updatedTranslations,
      props.data.elementPart,
      props.data.draftLocale
    );
  };
  //---------------------------------------------------------------------------
  return (
    <InputWrapper caption={GetCaption(appUiContext, props.ui.id, props.ui.caption)}>
      <div className={styles.container}>
        <InputsGroupWrapper>

          <SourceValue
            caption={GetCaption(appUiContext, props.ui.inputSource.id, props.ui.inputSource.caption)}
            data={props.data?.sourceValue}
            disabled={!props.editModeIsEnabled}
            onClick={onSourceValueCopy}
          />

          {props.parameters &&
            <React.Fragment>
              <div>
                Parameters
              </div>
              <div className={styles.parameters}>
                {parameters}
              </div>
            </React.Fragment>}

          <TranslationEditorInput
            ui={{
              groupId: props.ui.id,
              id: "ValueInCurrentLocale",
              caption: `Text in  [${props.data?.draftLocale}]`,
              placeholder: { en: "Type name here..." }
            }}
            data={props.data?.draftValue}
            isSelected={!props.data?.draftFallbackAccepted}
            isEditable={props.editModeIsEnabled}
            onSelect={() => onSwitch(false)}
            onUpdate={onUpdate}
          />

          <TranslationEditorInput
            ui={{
              groupId: props.ui.id,
              id: "FallbackValue",
              caption: `Fallback to [${fallbackValue?.locale}]`,
              placeholder: { en: "Here should be a value" }
            }}
            data={fallbackValue?.value}
            isSelected={props.data?.draftFallbackAccepted == undefined ? false : props.data.draftFallbackAccepted}
            isEditable={false}
            onSelect={() => onSwitch(true)}
          />

        </InputsGroupWrapper>
      </div>
    </InputWrapper>
  );
}

const getRegexes = (parameters?: string[]) => {
  const regexes: RegExp[] = [];
  if (parameters) {
    parameters.forEach(param => {
      let pattern = param.replace('.', '\\.');
      pattern = `.*\\[${pattern}\\].*`;
      const re = new RegExp(pattern);
      regexes.push(re);
    });
  };
  return regexes;
} 