import { ILockable, ILocker } from "../../../common/locks/TLocker";
import { IDictionaryDraftItem } from "./DictionaryDraftItem";
import { IDocumentDraftHistoryRecord } from "./IDocumentDraftHistoryRecord";


// ATTENTION: Refactor to make it common

export interface IDictionaryDraftCommon extends ILockable {
  dictionaryId: string;
  locale?: string; // English in case of dictionary editor (as opposed to translator)
  name?: any;
  description?: any;
  historyRecords: IDocumentDraftHistoryRecord[];
  isHierarchical: boolean;
  isManuallyOrdered: boolean;
  rootIsLocked: boolean;
}

export interface IDictionaryDraft<T> extends IDictionaryDraftCommon {
  name?: string;
  description?: string;
  items: T[];
  dictionaryDraftInitialStringified: string;
  publishedVersion: any; // Published version with all necessary translations
}

export class DictionaryDraft<T> implements IDictionaryDraft<T> {
  dictionaryId!: string; // Rename to make universal???
  locale: string = "en";
  name?: any; //text
  description?: any;
  historyRecords!: IDocumentDraftHistoryRecord[];
  items!: T[];
  isHierarchical!: boolean;
  isManuallyOrdered!: boolean;
  rootIsLocked!: boolean;
  //-----------------------------------
  isUpdated!: boolean;
  dictionaryDraftInitialStringified!: string;
  publishedVersion: any;
  locker?: ILocker;
  //---------------------------------------------------------------------------
  constructor(source: IDictionaryDraft<T>) {
    Object.assign(this, source);
    if (source.historyRecords) {
      this.historyRecords = [];
      source.historyRecords.forEach(item => this.historyRecords.push(item));
    }
    if (this.items && this.publishedVersion) {
      this.preparePublishedVersion();
    }
  }
  //---------------------------------------------------------------------------
  private preparePublishedVersion() {
    let published = this.publishedVersion as IDictionaryDraft<T>;
    if (!published.items) {
      return;
    }
    this.items.forEach(i1 => {
      let draftItem = (i1 as unknown) as IDictionaryDraftItem;
      draftItem.publishedVersion = published.items.find(i2 => {
        let publishedItem = (i2 as unknown) as IDictionaryDraftItem;
        return publishedItem.id == draftItem.id;
      });
    })
  }
  //---------------------------------------------------------------------------
  static getSiblings<T>(
    source: T[],
    directSiblings: T[],
    otherSiblings: T[],
    path?: string) {
    //---------------------------------------------------------------------------
    source.forEach(item => {
      let itemPath = ((item as unknown) as IDictionaryDraftItem).path;
      if (itemPath === path)
        directSiblings.push(item);
      else if (!path || itemPath?.startsWith(path))
        otherSiblings.push(item);
    });
  }

  //---------------------------------------------------------------------------
  // static buildHierarchy<T>(source: T[]): T {
  //   let root: T | undefined = undefined;
  //   let childs: (T | undefined)[] = [];
  //   // separate root and childs
  //   for (let i = 0; i < source.length; i++) {
  //     let item = (source[i] as unknown) as IDictionaryEditorDraftItem;
  //     if (item.path) {
  //       childs.push((item as unknown) as T);
  //       continue;
  //     }
  //     if (!root)
  //       root = (item as unknown) as T;
  //     else
  //       throw "Multiple roots found in hierarchy dictionary draft";
  //   }
  //   if (!root) 
  //     throw "Root item not found in hierarchy dictionary draft";
  //   DictionaryDraft.collectItems(root, childs);
  //   return root;
  // }
  // //---------------------------------------------------------------------------
  // static collectItems<T>(
  //   dest: T,
  //   source: (T | undefined)[]) {
  //   //---------------------------------------------------------------------------
  //   let d = (dest as unknown) as IDictionaryEditorDraftItem;
  //   let childPath = (d.path ? `${d.path}.${d.id}` : d.id);
  //   for (let i = 0; i < source.length; i++) {
  //     let item = (source[i] as unknown) as IDictionaryEditorDraftItem;
  //     if (!item) continue;
  //     if (item.path === childPath) {
  //       if (!d.items) 
  //         d.items = [item];
  //       else 
  //         d.items.push(item);
  //       source[i] = undefined;
  //       DictionaryDraft.collectItems((item as unknown) as T, source);
  //     }
  //   }
  // }
  // //-------------------------------------------------------------------------------------
  // private unwrapItem(item: T, dest: T[]) {
  //   dest.push(item);
  //   let ddi = (item as unknown) as IDictionaryEditorDraftItem;
  //   ddi.items?.forEach(i => this.unwrapItem((i as unknown) as T, dest));
  //   ddi.items = undefined;
  // }
  // //-------------------------------------------------------------------------------------
  // protected unwrapItems() {
  //   let dest: T[] = [];
  //   this.items?.forEach(i => this.unwrapItem((i as unknown) as T, dest));
  //   this.items = dest;
  // }
  // //-------------------------------------------------------------------------------------
  // toJSON() {
  //   if (this.isHierarchical)
  //     this.unwrapItems();
  //   return { ...this };
  // }
}