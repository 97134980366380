import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../../context/app-auth-context/AppAuthContext";
import { IDocumentRequestListItem } from "./requests-list-item/IDocumentRequestListItem";
import { apiGetPrivate, checkResponse } from "../../../../../utils/api";
import { apiBasePath, pathGetListDocumentRequests } from "../../../../../utils/apiPathConstant";

interface IProps {
  msalInstanse: IPublicClientApplication;
  authConfig?: IAuthConfig;
  abortSignal?: AbortSignal;
}

export async function getListDocumentsAsync(props: IProps): Promise<IDocumentRequestListItem[]> {
  if (!props.authConfig) throw new Error("getListDocumentsAsync: auth config is not provided");
  //---------------------------------------------------------------------------
  const response = await apiGetPrivate(
    props.msalInstanse,
    props.authConfig,
    `${apiBasePath}${pathGetListDocumentRequests}`,
    props.abortSignal
  );
  //---------------------------------------------------------------------------
  checkResponse(response, "getListDocumentsAsync", props.abortSignal?.aborted);
  const requests = response?.content as IDocumentRequestListItem[];
  return requests;
} 