import React from "react";
import { createRoot } from 'react-dom/client';
import AppWrapper from "./AppWrapper";
import { BrowserRouter } from "react-router-dom";
import { IAuthConfig } from "./context/app-auth-context/AppAuthContext";
import { MsalProvider } from "@azure/msal-react";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import getIdentityServiceConfig from "./context/app-auth-context/identity-service/getIdentityServiceConfig";
//import TagManager from "react-gtm-module"
import ReactGA from 'react-ga4';
//------------------------------------------------------------------
//import registerServiceWorker from "./registerServiceWorker";
//import * as serviceWorkerRegistration from './serviceWorkerRegistration';
//------------------------------------------------------------------

interface IProps {
  config?: IAuthConfig;
  pca: PublicClientApplication;
}

//-----------------------------------------------------------------------------
function Index(props: IProps) {
  return (
    //<React.StrictMode>
      <BrowserRouter>
        <MsalProvider instance={props.pca}>
          <AppWrapper authConfig={props.config} />
        </MsalProvider>
      </BrowserRouter>
    //</React.StrictMode >
  );
}

//-----------------------------------------------------------------------------
console.log("start");
//console.log("location:", location.href);
//registerSW();
const containerId = 'GTM-5MKR9SP7';
const measurementId = 'G-RLNR6D9BK5';
// ReactGA.initialize([
//   {
//     trackingId: containerId,
//     gtagOptions: { send_page_view: false }
//   },
//   {
//     trackingId: measurementId,
//     gtagOptions: { send_page_view: false }
//   }],
//   {gtagOptions: { send_page_view: false }});
//ReactGA.initialize(containerId, {gtagOptions: { send_page_view: false }});
ReactGA.initialize(containerId);
//ReactGA.gtag("config", gtmId, { send_page_view: false });
// const tagManagerArgs = {
//   gtmId: 'GTM-5MKR9SP7'
//   //gtmId: 'G-RLNR6D9BK5'
// }
// TagManager.initialize(tagManagerArgs);

//-----------------------------------------------------------------------------
// https://github.com/Azure-Samples/ms-identity-javascript-react-tutorial/blob/main/1-Authentication/2-sign-in-b2c/SPA/src/index.js
// MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
// For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
getIdentityServiceConfig()
  .then((authConfig: IAuthConfig | undefined) => {
    //console.log("Index.getIdentityServiceConfig(): auth config sorted out ", authConfig);
    // Auth config can be undefined in case if backend did not respond
    // In this case empty PCA object is created, it won't be able to login users
    // NOTE: open question - when to clear cached identity service data?
    const msalInstance = new PublicClientApplication(authConfig?.msalConfig as Configuration);
    const container = document.getElementById('root');
    const root = createRoot(container!);
    //-------------------------------------------------------------------------
    root.render(
      <Index
        config={authConfig}
        pca={msalInstance} />
    );
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

//serviceWorkerRegistration.register();

//registerServiceWorker();
