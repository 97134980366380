import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../context/app-auth-context/AppAuthContext";
import { apiBasePath, pathGetUserMessages } from "../../utils/apiPathConstant";
import { stringFormatter } from "../../utils/common";
import { apiGetPrivate, checkResponse } from "../../utils/api";
import { EHttpStatusCode } from "../../utils/HttpStatusCodes";
import { IAppUiContext } from "../../context/app-ui-context/AppUiContextProvider";
import { GetParentLocale } from "../common/functions/GetTranslation";
import { MessageDelivery } from "./MessageDelivery";

export type EMessageType =
  'Notification' |
  'Other';

export type EMessageSubType =
  'AdReviewed' |
  "Other";

export type EUserGroupNames =
  "RikiWiki Administrators" |
  "RikiWiki Moderators" |
  "RikiWiki Super Moderators" |
  "RikiWiki Content Managers" |
  "RikiWiki System Translators" |
  "RikiWiki Customers" |
  "RikiWiki Business Owners" |
  "RikiWiki Registered Users";

export type EMessageDirection = 'In' | 'Out';

export type EMessageParameters =
  "User.Id" |
  "User.Name" |
  "Business.Id" |
  "Business.Name" |
  "Ad.Id" |
  "Ad.Name" |
  "Ad.Url";


type MessageParameters = {
  [key in EMessageParameters]: string;
}

export interface IUiTextProperty {
  [key: string]: string;
}

export class UiTextProperty {
  private _translations!: IUiTextProperty;
  readonly keys: string[];
  //-----------------------------------------
  constructor(source?: IUiTextProperty) {
    if (source) {
      this._translations = source;
      this.keys = Object.keys(source);
    }
    else {
      this.keys = [];
      this._translations = {};
    }
  }
  //-----------------------------------------
  toJSON() {
    return this._translations;
  }
  //---------------------------------------------------------------------------
  getExactLocale(locale: string) {
    return this._translations[locale];
  }
  //---------------------------------------------------------------------------
  translateWithContext(context: IAppUiContext, defaultResult: string) {
    //---------------------------------------------------------------------------
    // Get locale settings from context
    const locale = context.locale.localeId;

    if (this._translations[locale])
      return this._translations[locale];

    if (locale === context.sysSettings.sysLocales.i18nLocale || this.keys.length == 0)
      return defaultResult;

    //---------------------------------------------------------------------------
    // Apply fallback logic
    // Set fallback level to max value in case if it's system locale (i.e. no need to fallback from system locale)
    let fallbackLevel: number;
    if (context.sysSettings.sysLocalesList.includes(locale))
      fallbackLevel = 2;
    else
      fallbackLevel = context.locale.fallbackLevel;
    let fallbackLocale = GetParentLocale(locale);
    for (let i = 2; i >= 0; i--) {
      if (this._translations[fallbackLocale]) return this._translations[fallbackLocale];
      if (i === fallbackLevel) return defaultResult;
      else {
        fallbackLocale = GetParentLocale(fallbackLocale);
      }
    }
  }
  //---------------------------------------------------------------------------
  translateToLocale(locale: string, fallbackLevel: number = 0, defaultResult: string = "") {
    if (this.keys.length == 0)
      return defaultResult;

    if (this._translations[locale])
      return this._translations[locale];

    //---------------------------------------------------------------------------
    // Apply fallback logic
    let fallbackLocale = GetParentLocale(locale);
    for (let i = 2; i >= 0; i--) {
      if (this._translations[fallbackLocale]) return this._translations[fallbackLocale];
      if (i === fallbackLevel) return defaultResult;
      else {
        fallbackLocale = GetParentLocale(fallbackLocale);
      }
    }
  }
  //---------------------------------------------------------------------------
  firstNonEn() {
    let key = this.keys.find(item => item != "en");
    if (key)
      return this._translations[key];
    return undefined;
  }
}

interface IMessageContent {
  localeId: string;
  greeting: string;
  signature: string;
  body: string[];
}

export class ClassMessageContent {
  localeId!: string;
  greeting!: string;
  signature!: string;
  body!: string[];
  //----------------------------------------
  constructor(source: any) {
    Object.assign(this, source);
  }
  //----------------------------------------
  static fromArray(source: any[]) {
    return source?.map(item => new ClassMessageContent(item));
  }
  //----------------------------------------
  asString(): string {
    if (!this.body || this.body.length == 0)
      return "";
    let body = this.body[0];
    this.body.forEach(item => body += ` ${item}`);
    return `${this.greeting}. ${body}. ${this.signature}`;
  }
}

export class ClassUserMessage {
  id!: string;
  messageType!: EMessageType;
  messageSubType!: EMessageSubType;
  //templateVersion!: string;
  targetGroup!: EUserGroupNames;
  direction!: EMessageDirection;
  from!: string;
  isRead?: boolean;
  isDeleted?: boolean;
  parameters!: MessageParameters;
  //-------------------------------------------
  private _created!: Date;
  get created(): Date {
    return this._created;
  }
  set created(value: any) {
    if (value)
      this._created = new Date(value);
  }
  //-------------------------------------------
  private _subject!: UiTextProperty;
  get subject(): UiTextProperty {
    return this._subject;
  }
  set subject(value: any) {
    this._subject = new UiTextProperty(value);
  }
  //-------------------------------------------
  private _content!: ClassMessageContent[];
  get content(): ClassMessageContent[] {
    return this._content;
  }
  set content(value: any[]) {
    if (value) {
      this._content = ClassMessageContent.fromArray(value);
    }
    else {
      this._content = [];
    }
  }
  //-------------------------------------------
  //body?: string;
  //signature?: string;
  //-------------------------------------------
  private _messageDeliveries!: MessageDelivery[];
  get messageDeliveries(): MessageDelivery[] {
    return this._messageDeliveries;
  }
  set messageDeliveries(value: any[]) {
    this._messageDeliveries = value?.map(item => new MessageDelivery(item));
  }
  //--------------------------------------------------------------------------
  constructor(source: any) {
    Object.assign(this, source);
  }
  //--------------------------------------------------------------------------
  static fromArray(source: any) {
    let result: ClassUserMessage[] = source?.map((item: any) => new ClassUserMessage(item));
    return result;
  }
  //-------------------------------------------------------------------------------------
  toJSON() {
    this._subject
    let result = {
      ...this,
      content: this._content,
      _content: null,
      created: this._created,
      _created: null,
      messageDeliveries: this._messageDeliveries,
      _messageDeliveries: null,
      subject: this._subject,
      _subject: null,
    };
    delete result["_subject"];
    delete result["_messageDeliveries"];
    delete result["_content"];
    delete result["_created"];
    return result;
  }
  //--------------------------------------------------------------------------
  static GetUnreadUserMessages(
    instance: IPublicClientApplication,
    config?: IAuthConfig,
    abortSignal?: AbortSignal
  ): Promise<ClassUserMessage[]> {
    return new Promise<ClassUserMessage[]>((resolve, reject) => {
      //let uri = `${apiBasePath}${stringFormatter(pathGetUserMessages, [localeId])}`;
      let uri = `${apiBasePath}${pathGetUserMessages}`;
      apiGetPrivate(instance, config, uri, abortSignal)
        .then(response => {
          checkResponse(response, "getUserMessages", false, [EHttpStatusCode.NotFound]);
          if (response) {
            let result = response.status != EHttpStatusCode.NotFound
              ? ClassUserMessage.fromArray(response.content)
              : [];
            resolve(result);
          }
        })
        .catch(error => {
          reject(error);
        })
    })
  }
  //--------------------------------------------------------------------------
  getSubject() {
    let key = this._subject.keys.find(item => item != "en");
    let nonEn = key ? this._subject.getExactLocale(key) : undefined;
    let en = this._subject.getExactLocale("en");
    if (nonEn)
      return `${nonEn} (${en})`;
    return en;
  }
  //--------------------------------------------------------------------------
  getBody() {
    let nonEn = this.content.find(item => item.localeId != "en")?.asString();
    let en = this.content.find(item => item.localeId == "en")?.asString();
    return nonEn ? `${nonEn} (${en})` : en;
  }
}