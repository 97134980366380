import { useContext, useEffect, useState } from 'react';
import ConsoleContent from '../../../../common/console-layout/console-content/ConsoleContent';
import styles from './BusinessOwnerMessages.module.css';
import { AppUiContextStore } from '../../../../../context/app-ui-context/AppUiContextProvider';
import GetHint from '../../../../common/functions/GetHint';
import { IUiMessagesList } from '../../../../messages/IUiMessagesList';
import { ClassUserMessage } from '../../../../messages/Messages';
import { IUiOption } from '../../../../common/options/IUiOption';
import { optionIdMessages } from '../BusinessOwnerConsole';
import { TUiBreadcrumb } from '../../../../common/breadcrumbs-trail/breadcrumb/TUiBreadcrumb';
import { pathBusinessConsole, pathBusinessOwnerConsole, pathMessages } from '../../BusinessConsole';
import { useAbortController } from '../../../../../hooks/useAbortController';
import { NotificationContext } from '../../../../../context/notification-context/NotificationContextProvider';
import { getUserMessagesAsync } from './functions/getUserMessagesAsync';
import { useMsal } from '@azure/msal-react';
import { AppAuthContextStore } from '../../../../../context/app-auth-context/AppAuthContext';
import MessagesListItem from '../../../../messages/messages-list-item/MessagesListItem';
import { updateUserMessagesAsync } from './functions/updateUserMessagesAsync';

interface IProps {
  ui?: IUiMessagesList;
  optionsForBreadcrumb?: IUiOption[];
}

export default function BusinessOwnerMessages(props: IProps) {
  if (!props.ui) return null;
  const { instance } = useMsal();
  const { appAuthContext, appAuthContextDispatch } = useContext(AppAuthContextStore);
  const { appUiContext, appUiContextDispatch } = useContext(AppUiContextStore);
  const { notificationContextDispatch } = useContext(NotificationContext);
  //useBeforeUnload(true);
  const abortController = useAbortController("BusinessOwnerMessages");
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState<ClassUserMessage[]>([]);
  //--------------------------------------------------------------------------- Breadcrumb, Data, cleanup
  useEffect(() => {
    const option = props.optionsForBreadcrumb?.find(o => o.id == optionIdMessages);
    if (option) {
      const breadcrumb: TUiBreadcrumb = {
        id: option.id,
        path: `/${pathBusinessConsole}/${pathBusinessOwnerConsole}/${pathMessages}`,
        caption: option.caption
      };
      appUiContextDispatch({ type: "UpdateBreadcrumbsTrail", value: breadcrumb });
    };
    //-------------------------------------------------------------------------
    setIsLoading(true);
    let controller = abortController.newController("getUserMessagesAsync");
    getUserMessagesAsync({
      msalInstance: instance,
      authConfig: appAuthContext.config,
      abortSignal: controller.signal
    }).then(messages => {
      setMessages(messages);
    }).catch(error => {
      !controller.aborted && notificationContextDispatch({ type: "SetError", header: "Business Owner Messages", message: error });
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
    //-------------------------------------------------------------------------
    return (() => {
      abortController.abortOnUnmount();
    });
  }, []);
  //---------------------------------------------------------------------------
  const onMessageUpdate = (messageId: string, isRead?: boolean, isDeleted?: boolean) => {
    if (isRead == undefined && isDeleted == undefined)
      return;
    //console.log(messageId, isRead, isDeleted)
    const messageToUpdate = messages.find(message => message.id == messageId);
    const updatedMessage = new ClassUserMessage(messageToUpdate);
    updatedMessage.isRead = isRead != undefined ? isRead : undefined;
    updatedMessage.isDeleted = isDeleted != undefined ? isDeleted : undefined;
    //-------------------------------------------------------------------------
    setIsLoading(true);
    let controller = abortController.newController("updateUserMessagesAsync");
    updateUserMessagesAsync({
      msalInstance: instance,
      authConfig: appAuthContext.config,
      abortSignal: controller.signal,
      updatedMessages: [updatedMessage]
    }).then(response => {
      appAuthContextDispatch({ type: "UpdateUnreadUserMessageCount", count: response.unreadMessageCount });
      setMessages(response.messages);
    }).catch(error => {
      !controller.aborted && notificationContextDispatch({ type: "SetError", header: "Business Owner Messages", message: error });
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const messageItems = messages.map(message => (
    <MessagesListItem
      key={message.id}
      ui={props.ui?.menuContent}
      data={message}
      onUpdate={onMessageUpdate}
    />
  ));
  //---------------------------------------------------------------------------
  return (
    <ConsoleContent
      prompt={GetHint(appUiContext, props.ui.id, props.ui.hint)}
      //formOptions={formOptions}
      containerWidthPercent={70}
      isLoading={isLoading}
    >
      <div className={styles.container}>
        {messageItems}
      </div>
    </ConsoleContent>
  );
}