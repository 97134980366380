import styles from './PageViewCard.module.css';
import { BusinessPageViews } from '../BusinessPageViews';
import BusinessLogo from '../../../business-logo/BusinessLogo';
import { BusinessName } from '../business-name/BusinessName';
//----------------------------------------------------------------------
interface IProps {
  data: BusinessPageViews;
}
//----------------------------------------------------------------------
export function PageViewCard(props: IProps) {
  //----------------------------------------------------------------------
  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <BusinessLogo
          data={props.data.logo}
          useDefaultLogo={false}
        />
      </div>
      <div className={styles.statContainer}>
        <BusinessName
          businessName={props.data.businessName}
          businessId={props.data.businessId}
        />
        {props.data.uiChildren}
      </div>
    </div>
  );
}