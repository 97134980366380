//import styles from './BusinessTagsDictionaryEditor.module.css';
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAbortController } from "../../../../../hooks/useAbortController";
import { getNewCustomDictionaryDraftAsync } from "../functions/getNewCustomDictionaryDraftAsync";
import { useMsal } from "@azure/msal-react";
import { AppAuthContextStore } from "../../../../../context/app-auth-context/AppAuthContext";
import { pathAdminConsole, pathAdministratorConsole, pathBusinessTagsConsole } from "../../../AdminConsole";
import { pathDictionaries } from "../BusinessTagsConsole";
import DirectoryEditorWrapper from "../../../common/directory-editor/directory-editor-wrapper/DirectoryEditorWrapper";
import { tmpUiDirectoryEditor } from "../../../common/directory-editor/IUiDirectoryEditor";
import CommonDictionaryItemEditForm from "../../dictionaries/common-dictionary/common-dictionary-item-editform/CommonDictionaryItemEditForm";
import CommonDictionaryEditorNode from "../../dictionaries/common-dictionary/common-dictionary-editor-node/CommonDictionaryEditorNode";
import { tmpUiCommonDictionaryEditorNode } from "../../dictionaries/common-dictionary/common-dictionary-editor-node/IUiDictionaryEditorNode";
import { DirectoryEditorContext } from "../../../../../context/directory-editor-context/DirectoryEditorContextProvider";
import { EDirectoryType } from "../../../../../context/directory-editor-context/IDirectoryEditorContextData";
import { DictionaryDraftItem } from "../../dictionaries/common-dictionary/IDictionaryDraftItem";
import PopupDictionaryDetails from "../../dictionaries/common-dictionary/popup-dictionary-details/PopupDictionaryDetails";
import { tmpUiEditformDictionaryDetails } from "../../dictionaries/common-dictionary/popup-dictionary-details/IUiEditformDictionaryDetails";
import { saveCustomDictionaryDraftAsync } from "../functions/saveCustomDictionaryDraftAsync";
import { CustomDictionaryDraft } from "../../dictionaries/common-dictionary/IDictionaryDraft";
import { getCustomDictionaryDraftAsync } from "../functions/getCustomDictionaryDraftAsync";
import { deleteCustomDictionaryDraftAsync } from "../functions/deleteCustomDictionaryDraftAsync";
import useNavigateWithContext from "../../../../../hooks/useNavigateWithContext";
import { IDirectoryEditorStateInitial } from "../../../../../context/directory-editor-context/IDirectoryEditorState";
import { publishCustomDictionaryDraftAsync } from "../functions/publishCustomDictionaryDraftAsync";
import { NotificationContext } from "../../../../../context/notification-context/NotificationContextProvider";
import { directoryDraftStatesFullDetailsV2 } from "../../../../../context/directory-editor-context/directory-editor-settings/directoryDraftStatesFullDetailsV2";
import { TUser } from "../../../../../context/app-auth-context/TUser";
import { IDirectoryDraftAbstract } from "../../../../../context/directory-editor-context/IDirectoryDraftAbstract";

export const pathDictionaryNew = "new";

interface IProps {
}

export default function SubDictionaryEditor(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { notificationContextDispatch } = useContext(NotificationContext);
  const { directoryEditorContext, directoryEditorContextDispatch } = useContext(DirectoryEditorContext);
  const navigate = useNavigateWithContext();
  const { dictionaryId } = useParams();
  const abortController = useAbortController("BusinessTagsDictionaryEditor");
  const [isLoading, setIsLoading] = useState(false);
  const [detailsPopupOpen, setDetailsPopupOpen] = useState(false);
  const [newRootItem, setNewRootItem] = useState<DictionaryDraftItem>();
  const directoryType: EDirectoryType = "Dictionary";
  const stateGroups = directoryDraftStatesFullDetailsV2;
  const user: TUser | undefined = appAuthContext.user ? appAuthContext.user : undefined;
  const basePath = `/${pathAdminConsole}/${pathAdministratorConsole}/${pathBusinessTagsConsole}/${pathDictionaries}`;
  let dictionaryItems: DictionaryDraftItem[] = directoryEditorContext?.editorState?.draft?.items ?
    directoryEditorContext.editorState.draft.items as DictionaryDraftItem[] :
    [];
  //-------------------------Id----------Name--------------------------------------Status------Badges------ContextMenu
  let gridTemplateColumns = 'min-content minmax(2em, 100%) min-content min-content min-content min-content minmax(2em, 1%)'; // Id, Name, badges, context menu
  //--------------------------------------------------------------------------- Data, cleanup
  useEffect(() => {
    if (dictionaryId && user) {
      if (dictionaryId === pathDictionaryNew) {
        // New dictionary
        let controller = abortController.newController("getNewDictionaryDraftAsync");
        setIsLoading(true);
        getNewCustomDictionaryDraftAsync({
          msalInstance: instance,
          authConfig: appAuthContext.config,
          abortSignal: controller.signal
        }).then(draft => {
          const initialState: IDirectoryEditorStateInitial = {
            directoryType,
            basePath: `${basePath}/${pathDictionaryNew}`,
            id: draft.id,
            name: draft.name,
            description: draft.description,
            isNew: true,
            editMode: true,
            draft: draft,
            stateGroups: stateGroups,
            user
          };
          directoryEditorContextDispatch({ type: "Initialize", data: initialState });
          setDetailsPopupOpen(true);
        }).catch(error => {
          !controller.aborted && notificationContextDispatch({ type: "SetError", header: "Business Wizard", message: error });
        }).finally(() => {
          !controller.aborted && setIsLoading(false);
        });
      } else {
        // Existing dictionary
        getDraft(dictionaryId, false);
      };
    };
    //-------------------------------------------------------------------------
    return (() => {
      abortController.abortOnUnmount();
      directoryEditorContextDispatch({ type: "Cleanup" })
    });
  }, []);
  //--------------------------------------------------------------------------- Draft
  const getDraft = (dictionaryId: string, lockRequired: boolean) => {
    if (!user) return;
    let controller = abortController.newController("getDictionaryDraftAsync");
    setIsLoading(true);
    getCustomDictionaryDraftAsync({
      msalInstance: instance,
      authConfig: appAuthContext.config,
      abortSignal: controller.signal,
      dictionaryId,
      lockRequired
    }).then(draft => {
      const initialState: IDirectoryEditorStateInitial = {
        directoryType,
        basePath: `${basePath}/${dictionaryId}`,
        id: draft.id,
        name: draft.name,
        description: draft.description,
        isNew: false,
        editMode: draft.locker?.statusCode == 200 ? true : false,
        draft: draft,
        stateGroups: stateGroups,
        user,
        original: draft.original
      };
      directoryEditorContextDispatch({ type: "Initialize", data: initialState });
    }).catch(error => {
      !controller.aborted && notificationContextDispatch({ type: "SetError", header: "Business Wizard", message: error });
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const saveDraft = () => {
    if (!user) return;
    if (!directoryEditorContext.editorState.draft) return;
    setIsLoading(true);
    let controller = abortController.newController("saveDictionaryDraftAsync");
    const draft = directoryEditorContext.editorState.draft as CustomDictionaryDraft;
    saveCustomDictionaryDraftAsync({
      msalInstance: instance,
      authConfig: appAuthContext.config,
      abortSignal: controller.signal,
      draft: draft,
      isNew: !draft.summary.savedAt
    }).then(draft => {
      const initialState: IDirectoryEditorStateInitial = {
        directoryType: "Dictionary",
        basePath: `${basePath}/${draft.documentId}`,
        id: draft.id,
        name: draft.name,
        description: draft.description,
        isNew: false,
        editMode: true,
        draft: draft,
        stateGroups: stateGroups,
        user
      };
      directoryEditorContextDispatch({ type: "Initialize", data: initialState });
    }).catch(error => {
      !controller.aborted && notificationContextDispatch({ type: "SetError", header: "Business Wizard", message: error });
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const deleteDraft = () => {
    if (!user) return;
    if (!directoryEditorContext) return;
    setIsLoading(true);
    const dictionaryDraftId = (directoryEditorContext.editorState.draft as CustomDictionaryDraft).documentId;
    let controller = abortController.newController("deleteDictionaryDraftAsync");
    deleteCustomDictionaryDraftAsync({
      msalInstance: instance,
      authConfig: appAuthContext.config,
      abortSignal: controller.signal,
      dictionaryId: dictionaryDraftId
    }).then(draft => {
      if (draft) {
        const initialState: IDirectoryEditorStateInitial = {
          directoryType: "Dictionary",
          basePath: `${basePath}/${draft.id}`,
          id: draft.id,
          name: draft.name,
          description: draft.description,
          isNew: directoryEditorContext.editorState.isNew,
          editMode: false,
          draft: draft,
          stateGroups: stateGroups,
          user
        };
        directoryEditorContextDispatch({ type: "Initialize", data: initialState });
      } else {
        // Navigate to dictionaries list
        navigate(`${pathAdminConsole}/${pathAdministratorConsole}/${pathBusinessTagsConsole}/${pathDictionaries}`);
      };
    }).catch(error => {
      !controller.aborted && notificationContextDispatch({ type: "SetError", header: "Business Wizard", message: error });
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const publishDraft = () => {
    if (!user) return;
    if (!directoryEditorContext) return;
    setIsLoading(true);
    const dictionaryDraftId = (directoryEditorContext.editorState.draft as CustomDictionaryDraft).documentId;
    let controller = abortController.newController("deleteDictionaryDraftAsync");
    publishCustomDictionaryDraftAsync({
      msalInstance: instance,
      authConfig: appAuthContext.config,
      abortSignal: controller.signal,
      dictionaryId: dictionaryDraftId,
      isNew: directoryEditorContext.editorState.draft.original ? false : true
    }).then(draft => {
      if (draft) {
        const initialState: IDirectoryEditorStateInitial = {
          directoryType: "Dictionary",
          basePath: `${basePath}/${draft.id}`,
          id: draft.id,
          name: draft.name,
          description: draft.description,
          isNew: directoryEditorContext.editorState.isNew,
          editMode: true,
          draft: draft,
          stateGroups: stateGroups,
          user
        };
        directoryEditorContextDispatch({ type: "Initialize", data: initialState });
      } else {
        // Navigate to dictionaries list
        navigate(`${pathAdminConsole}/${pathAdministratorConsole}/${pathBusinessTagsConsole}/${pathDictionaries}`);
      };
    }).catch(error => {
      !controller.aborted && notificationContextDispatch({ type: "SetError", header: "Business Wizard", message: error });
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const onDraftOptionSelect = (optionId: string) => {
    switch (optionId) {
      case "OptionEditModeEnable":
        const dictionaryDraftId = (directoryEditorContext.editorState.draft as CustomDictionaryDraft).documentId;
        getDraft(dictionaryDraftId, true);
        break;
      case "OptionSave":
        saveDraft();
        break;
      case "OptionDelete":
        deleteDraft();
        break;
      case "OptionAdd":
        const newItem = DictionaryDraftItem.getNew({
          id: "",
          status: {
            editors: true,
            viewers: false
          }
        });
        setNewRootItem(newItem);
        break;
      case "OptionSubmit":
        publishDraft();
        break;
    };
  };
  //--------------------------------------------------------------------------- Items
  const addNewRootItem = (newItem: DictionaryDraftItem) => {
    if (!directoryEditorContext?.editorState.draft) return;
    const updatedItems = dictionaryItems.concat(newItem);
    const updatedDraft = directoryEditorContext.editorState.draft.getUpdated({ items: updatedItems });
    directoryEditorContextDispatch({
      type: "Update",
      data: {
        draft: updatedDraft
      }
    });
    setNewRootItem(undefined);
  };
  //---------------------------------------------------------------------------
  const addNewSubItem = (
    newItem: DictionaryDraftItem,
    parentItem: DictionaryDraftItem,
    shift: number
  ) => {
    if (!directoryEditorContext?.editorState.draft) return;
    //-------------------------------------------------------------------------
    let updatedItems = dictionaryItems.concat(newItem);
    validateChildren(parentItem, updatedItems);
    //-------------------------------------------------------------------------
    const updatedDraft = directoryEditorContext.editorState.draft.getUpdated({
      items: updatedItems
    });
    //-------------------------------------------------------------------------
    directoryEditorContextDispatch({
      type: "Update",
      data: {
        draft: updatedDraft
      }
    });
  };
  //---------------------------------------------------------------------------
  const updateItem = (updatedItem: DictionaryDraftItem, replaceId?: string, itemPathToCheck?: string) => {
    if (!directoryEditorContext?.editorState.draft) return;
    const idToFind = replaceId ? replaceId : updatedItem.id;
    const updatedItems = dictionaryItems.map(item => item.id === idToFind ? updatedItem : item);
    //-------------------------------------------------------------------------
    checkParent(updatedItem, updatedItems);
    if (itemPathToCheck) {
      const itemToCheck = updatedItems.find(item => item.fullPath === itemPathToCheck);
      itemToCheck && validateChildren(itemToCheck, updatedItems);
    };
    //-------------------------------------------------------------------------
    const updatedDraft = directoryEditorContext.editorState.draft.getUpdated({ items: updatedItems });
    directoryEditorContextDispatch({
      type: "Update",
      data: {
        draft: updatedDraft
      }
    });
  };
  //---------------------------------------------------------------------------
  const deleteItem = (deletedItemFullPath: string) => {
    if (!directoryEditorContext?.editorState.draft) return;
    const itemToDelete = dictionaryItems.find(item => item.fullPath === deletedItemFullPath);
    if (!itemToDelete) return;
    const updatedItems = dictionaryItems.filter(item => item.fullPath !== deletedItemFullPath);
    //-------------------------------------------------------------------------
    // Check if it's a sub-item
    checkParent(itemToDelete, updatedItems);
    //-------------------------------------------------------------------------
    const updatedDraft = directoryEditorContext.editorState.draft.getUpdated({ items: updatedItems });
    directoryEditorContextDispatch({
      type: "Update",
      data: {
        draft: updatedDraft
      }
    });
  };
  //---------------------------------------------------------------------------
  const repositionItem = (newItem: DictionaryDraftItem, oldItem: DictionaryDraftItem) => {
    if (!directoryEditorContext?.editorState.draft) return;
    const itemsToUpdate = dictionaryItems;
    //-------------------------------------------------------------------------
    // Filter out previous versions of the repositioned item
    let updatedItems: DictionaryDraftItem[] = itemsToUpdate.filter(item => !(item.id == newItem.id && item.isRepositionedNew));
    let realOldItem: DictionaryDraftItem;
    //-------------------------------------------------------------------------
    let firstOldItem = updatedItems.find(item => item.id == newItem.id && item.isRepositionedOld);
    if (firstOldItem) {
      realOldItem = firstOldItem;
    } else {
      realOldItem = new DictionaryDraftItem(oldItem);
      updatedItems = updatedItems.map(item => item.id === oldItem.id ? realOldItem : item);
    };
    realOldItem.newPath = oldItem.path;
    realOldItem.draftState = oldItem.draftState;
    checkParent(realOldItem, updatedItems);
    //-------------------------------------------------------------------------
    // Check if new item is returned to its original position
    if (newItem.path == realOldItem.path) {
      newItem.isRepositionedNew = false;
      updatedItems = updatedItems.map(item => item.fullPath == realOldItem.fullPath ? newItem : item);
    } else {
      updatedItems.push(newItem);
    };
    checkParent(newItem, updatedItems);
    //-------------------------------------------------------------------------
    if (realOldItem.fullPath != oldItem.fullPath) {
      // Check intermediate parent as well
      checkParent(oldItem, updatedItems);
    };
    //-------------------------------------------------------------------------
    const updatedDraft = directoryEditorContext.editorState.draft.getUpdated({ items: updatedItems });
    directoryEditorContextDispatch({
      type: "Update",
      data: {
        draft: updatedDraft
      }
    });
  };
  //---------------------------------------------------------------------------
  const checkParent = (itemToUpdate: DictionaryDraftItem, itemsToUpdate: DictionaryDraftItem[]) => {
    const parentItem = dictionaryItems.find(item => item.fullPath === itemToUpdate?.path);
    if (parentItem) {
      validateChildren(parentItem, itemsToUpdate);
    };
  };
  //---------------------------------------------------------------------------
  const validateChildren = (parentItem: DictionaryDraftItem, itemsToUpdate: DictionaryDraftItem[]) => {
    // NOTE: this would update both item and dictionaryItems in place
    if (!directoryEditorContext?.editorState.draft.isHierarchical) return;
    // Check number of active children. Inactive (unavailable to all users or repositioned) items are not counted
    const numberOfChildren = itemsToUpdate.filter(item => item.path === parentItem.fullPath && !item.isInactive).length;
    if (numberOfChildren == 1) {
      parentItem.hasOnlyChild = true;
      itemsToUpdate = itemsToUpdate.map(item => item.fullPath === item.fullPath ? parentItem : item);
    } else if (parentItem.hasOnlyChild) {
      parentItem.hasOnlyChild = false;
      itemsToUpdate = itemsToUpdate.map(item => item.fullPath === parentItem.fullPath ? parentItem : item);
    };
  };
  //---------------------------------------------------------------------------
  const onItemOptionSelect = (draftItemFullPath: string, optionId: string) => {
    switch (optionId) {
      case "OptionDelete":
        deleteItem(draftItemFullPath);
        break;
    };
  };
  //---------------------------------------------------------------------------
  // Display only root items
  const items = dictionaryItems.filter(item => !item.path).map(item => {
      return (
        <CommonDictionaryEditorNode
          key={item.fullPath}
          ui={tmpUiCommonDictionaryEditorNode}
          data={item as DictionaryDraftItem}
          onUpdate={updateItem}
          onAdd={addNewSubItem}
          onReposition={repositionItem}
          onOptionSelect={onItemOptionSelect}
        />
      );
    });
  //---------------------------------------------------------------------------
  return (
    <DirectoryEditorWrapper
      ui={tmpUiDirectoryEditor}
      gridTemplateColumns={gridTemplateColumns}
      isLoading={isLoading}
      //onSelectSourceLocale={onSelectSourceLocale}
      onDetailsEdit={() => setDetailsPopupOpen(true)}
      onOptionSelect={onDraftOptionSelect}
    >

      {items}

      {newRootItem &&
        <CommonDictionaryItemEditForm
          ui={tmpUiCommonDictionaryEditorNode.editForm}
          data={newRootItem}
          isNew={true}
          onUpdate={addNewRootItem}
          onClose={() => setNewRootItem(undefined)}
        />}

      {detailsPopupOpen &&
        <PopupDictionaryDetails
          ui={tmpUiEditformDictionaryDetails}
          onClose={() => setDetailsPopupOpen(false)}
        />}

    </DirectoryEditorWrapper>
  );
}