import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../../context/app-auth-context/AppAuthContext";
import { IRegionalHomepageRequest } from "../request-form/IRegionalHomepageRequest";
import { apiBasePath, pathGetRequestRegional } from "../../../../../utils/apiPathConstant";
import { apiGetPrivate, checkResponse } from "../../../../../utils/api";
import { generatePath } from "react-router";
import { AdLanguageSet } from "../../../../ad-content-editor/IAdLanguageSet";
import { EHttpStatusCode } from "../../../../../utils/HttpStatusCodes";

export function getRegionalHomepageRequest(
  regionalHomepageRequestId: string,
  localeId: string,
  msalInstanse: IPublicClientApplication,
  authConfig?: IAuthConfig | null,
  abortSignal?: AbortSignal
): Promise<IRegionalHomepageRequest> {
  return new Promise((resolve, reject) => {
    const uri = `${apiBasePath}${generatePath(
      pathGetRequestRegional, {
      requestId: regionalHomepageRequestId,
      localeId: localeId
    })}`;
    //-------------------------------------------------------------------------
    apiGetPrivate(
      msalInstanse,
      authConfig,
      uri,
      abortSignal
    ).then(response => {
      checkResponse(response, "getRegionalHomepageRequest", abortSignal?.aborted, [EHttpStatusCode.Conflict]);
      const request: IRegionalHomepageRequest = response?.content;
      request.adLanguageSet = new AdLanguageSet(request.adLanguageSet);
      if (request.locker) {
        request.locker.errorPrefix = "Request";
      }
      resolve(request);
    }).catch(error =>
      reject(error)
    );
  });
}