import { IEditable } from "../../../../common/edit-form/IEditFormStateV2";

export interface IRequestRejectionReason {
  reasonId: string;
  reasonDetails?: string;
}

export class ClassRequestRejectionReason implements IRequestRejectionReason, IEditable {
  reasonId!: string;
  reasonDetails?: string;
  //---------------------------------------------------------------------------
  constructor(source?: IRequestRejectionReason) {
    Object.assign(this, source);
  };
  //---------------------------------------------------------------------------
  initialize(): IEditable {
    return this;
  };
  //---------------------------------------------------------------------------
  isEqual(object: IEditable): boolean {
    const typedObject = object as ClassRequestRejectionReason;
    let isEqual = true;
    if (this.reasonId !== typedObject.reasonId) {
      isEqual = false;
    };
    if (this.reasonDetails !== typedObject.reasonDetails) {
      isEqual = false;
    };
    return isEqual;
  };
  //---------------------------------------------------------------------------
  isValid(): boolean {
    let isValid = true;
    if (!this.reasonId) {
      isValid = false;
    };
    return isValid;
  };
}