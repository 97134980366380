import { IAdLanguageSet, IAdLanguageSetListItem } from "../../components/ad-content-editor/IAdLanguageSet";
import { IBusiness } from "./IBusiness";
import { ClassBusinessState, IBusinessState } from "./IBusinessState";
import { businessContextDefaultValue, EUserRole, IBusinessContext, IBusinessTagRequest } from "./IBusinessContext";
import { ClassAdLanguageSetState, IAdLanguageSetState } from "./IAdLanguageSetState";
import { ClassAdModule } from "../../components/common/ad-modules/IAdModule";
import { IUiListItem } from "../../components/common/input/input-dropdown/InputDropdown";
import { GetUpdatedBusinessTagsDictionary } from "./functions/UpdateBusinessTags";
import { pathBusinessNew } from "../../components/business-space/business-console/BusinessConsole";

interface ISetContext {
  type: "SetContext";
  business: IBusiness;
  adLanguageSet: IAdLanguageSet;
  isNew?: boolean;
}
interface ISetBusinessState {
  type: "SetBusinessState";
  userRole: EUserRole;
  basePath: string;
  business: IBusiness;
  ads?: IAdLanguageSetListItem[];
  isNew?: boolean;
};
interface ISetBusiness {
  type: "SetBusiness";
  business: IBusiness;
};
interface IUpdateBusiness {
  type: "UpdateBusiness";
  business: IBusiness;
  isValid?: boolean;
};
interface IClearBusinessContext {
  type: "ClearBusinessContext";
}
interface ISetAdLanguageSet {
  type: "SetAdLanguageSet";
  adLanguageSet: IAdLanguageSet;
}
interface IUpdateAdLanguageSet {
  type: "UpdateAdLanguageSet";
  adLanguageSet?: IAdLanguageSet;
  isValid?: boolean;
}
interface IClearAdLanguageSetState {
  type: "ClearAdLanguageSetState"
}
interface ISetAds {
  type: "SetAds";
  ads: IAdLanguageSetListItem[];
}
interface ISetAdModules {
  type: "SetAdModules";
  adModules: ClassAdModule[];
}
interface ISetBusinessTagRequests {
  type: "SetBusinessTagRequests";
  requests: IBusinessTagRequest[];
}
interface ISetBusinessTagsDictionary {
  type: "SetBusinessTagsDictionary";
  dictionary: IUiListItem[];
}

export type Actions = ISetContext |
  ISetBusinessState | ISetBusiness | IUpdateBusiness | IClearBusinessContext |
  ISetAdLanguageSet | IUpdateAdLanguageSet | IClearAdLanguageSetState |
  ISetAds | ISetAdModules |
  ISetBusinessTagRequests | ISetBusinessTagsDictionary;

export const BusinessContextReducer = (state: IBusinessContext, action: Actions) => {
  let updatedState: IBusinessContext;
  let businessStateData: IBusinessState;
  let adLanguageSetStateData: IAdLanguageSetState;
  let updatedAds: IAdLanguageSetListItem[] | undefined;
  //---------------------------------------------------------------------------
  switch (action.type) {
    case "SetContext":
      businessStateData = ClassBusinessState.initializeContext(action.business, action.isNew);
      adLanguageSetStateData = ClassAdLanguageSetState.initializeContext(action.adLanguageSet, action.isNew);
      updatedAds = getUpdatedAds(state, adLanguageSetStateData.object);
      //-----------------------------------------------------------------------
      updatedState = {
        ...state,
        businessState: new ClassBusinessState(businessStateData),
        adLanguageSetState: new ClassAdLanguageSetState(adLanguageSetStateData),
        ads: updatedAds
      };
      break;
    //-------------------------------------------------------------------------
    case "SetBusinessState":
      console.log("SetBusinessState:", action.business);
      businessStateData = ClassBusinessState.initializeContext(action.business, action.isNew);
      updatedState = {
        ...state,
        userRole: action.userRole,
        basePath: action.basePath,
        businessState: new ClassBusinessState(businessStateData),
        ads: action.ads
      };
      break;
    //-------------------------------------------------------------------------
    case "SetBusiness":
      console.log("SetBusiness:", action.business);
      businessStateData = ClassBusinessState.initializeContext(action.business);
      let path = state.basePath;
      if (path) {
        let newId = `/${pathBusinessNew}`;
        if (path.endsWith(newId)) {
          path = path.replace(newId, `/${action.business.id}`)
        }
        else {
          newId = `${newId}/`;
          if (path.includes(newId)) {
            path = path.replace(newId, `/${action.business.id}/`)
          }
        }
      }
      console.log(state.basePath, path);
      //pathBusinessNew
      updatedState = {
        ...state,
        businessState: new ClassBusinessState(businessStateData),
        basePath: path,
      };
      break;
    //-------------------------------------------------------------------------
    case "UpdateBusiness":
      if (!state.businessState) {
        console.error(`${action.type}: business state is not set`);
        return state;
      };
      //console.log("UpdateBusiness.getUpdatedContext.action.business:", action.business)
      const updatedBusinessState = state.businessState.getUpdatedContext(action.business, action.isValid);
      updatedState = {
        ...state,
        businessState: new ClassBusinessState(updatedBusinessState)
      };
      break;
    //-------------------------------------------------------------------------
    case "ClearBusinessContext":
      updatedState = {
        ...businessContextDefaultValue,
        businessTags: state.businessTags,
        businessTagRequests: state.businessTagRequests,
        basePath: state.basePath,
        userRole: state.userRole,
      };
      break;
    //-------------------------------------------------------------------------
    case "SetAdLanguageSet":
      adLanguageSetStateData = ClassAdLanguageSetState.initializeContext(action.adLanguageSet);
      //-----------------------------------------------------------------------
      // Check if it's a new ad and update ads list if needed
      updatedAds = getUpdatedAds(state, action.adLanguageSet);
      /* const ad = state.ads?.find(ad => ad.id == action.adLanguageSet.id);
      let updatedAds = state.ads;
      if (!ad) {
        updatedAds = updatedAds ? updatedAds : [];
        updatedAds.push({
          id: action.adLanguageSet.id,
          businessId: action.adLanguageSet.businessId,
          adCampaignId: action.adLanguageSet.adCampaignId,
          localeId: action.adLanguageSet.localeId,
          status: action.adLanguageSet.status,
          adModules: action.adLanguageSet.adModules?.map(adModule => adModule.id) || []
        });
      }; */
      //-----------------------------------------------------------------------
      updatedState = {
        ...state,
        adLanguageSetState: new ClassAdLanguageSetState(adLanguageSetStateData),
        ads: updatedAds
      };
      break;
    //-------------------------------------------------------------------------
    case "UpdateAdLanguageSet":
      if (!action.adLanguageSet && action.isValid == undefined) {
        console.error(`${action.type}: invalid input`);
        return state;
      }
      if (!state.adLanguageSetState) {
        console.error(`${action.type}: ad language state is not set`);
        return state;
      };
      const data = action.adLanguageSet ? action.adLanguageSet : state.adLanguageSetState.object;
      const updatedAdState = state.adLanguageSetState.getUpdatedContext(data, action.isValid);
      updatedState = {
        ...state,
        adLanguageSetState: new ClassAdLanguageSetState(updatedAdState)
      };
      break;
    //-------------------------------------------------------------------------
    case "ClearAdLanguageSetState":
      updatedState = {
        ...state,
        adLanguageSetState: undefined
      }
      break;
    //-------------------------------------------------------------------------
    case "SetAds":
      updatedState = {
        ...state,
        ads: action.ads
      };
      break;
    //-------------------------------------------------------------------------
    case "SetAdModules":
      // Update ad modules list checking orphaned modules
      action.adModules.forEach(adModule => {
        const ad = state.ads?.find(ad => ad.adModules.includes(adModule.id));
        if (!ad) {
          adModule.isOrphan = true;
        } else {
          adModule.isOrphan = false;
        };
      });
      //-----------------------------------------------------------------------
      updatedState = {
        ...state,
        adModules: action.adModules
      };
      break;
    //-------------------------------------------------------------------------
    case "SetBusinessTagRequests":
      // Refresh business tags dictionary stored in business context
      const updatedDictionary = state.businessTags;
      if (updatedDictionary) {
        // If business tag dictionary is set, update it
        //console.log("action:", action);
        action.requests?.forEach(tagRequest => {
          GetUpdatedBusinessTagsDictionary(updatedDictionary, tagRequest);
        });
      };
      //-----------------------------------------------------------------------
      updatedState = {
        ...state,
        businessTagRequests: action.requests,
        businessTags: updatedDictionary
      };
      break;
    //-------------------------------------------------------------------------
    case "SetBusinessTagsDictionary":
      updatedState = {
        ...state,
        businessTags: action.dictionary
      };
      break;
  };
  //---------------------------------------------------------------------------
  updatedState.lastAction = action;
  return updatedState;
}

const getUpdatedAds = (state: IBusinessContext, adLanguageSet: IAdLanguageSet): IAdLanguageSetListItem[] | undefined => {
  const ad = state.ads?.find(ad => ad.id == adLanguageSet.id);
  let updatedAds = state.ads;
  if (!ad) {
    updatedAds = updatedAds ? updatedAds : [];
    updatedAds.push({
      id: adLanguageSet.id,
      businessId: adLanguageSet.businessId,
      adCampaignId: adLanguageSet.adCampaignId,
      localeId: adLanguageSet.localeId,
      status: adLanguageSet.status,
      adModules: adLanguageSet.adModules?.map(adModule => adModule.id) || []
    });
  };
  return updatedAds;
};