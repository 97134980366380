import { InputValidator } from "../../../../common/input-v2/input-validation/IInputValidator";
import { IValidationState } from "../../../../common/input-v2/input-validation/IUiValidation";
import { ValidateUnique } from "../../../../common/input-v2/input-validation/ValidateInput";
import { IDocumentDetails } from "./IDocumentDetails";
import { IUiEditformDocumentDetails } from "./IUiEditformDocumentDetails";

export interface IDocumentDetailsValidationState {
  id: IValidationState;
  title: IValidationState;
  description: IValidationState;
  isValid: boolean;
}

export class DocumentDetailsValidator {
  id!: InputValidator;
  title!: InputValidator;
  description!: InputValidator;
  //---------------------------------------------------------------------------
  constructor(ui: IUiEditformDocumentDetails) {
    this.id = new InputValidator({
      elementId: ui.inputId.id,
      inputType: "InputText", // we could get from ui elementType
      validation: ui.inputId.validation
    });
    this.title = new InputValidator({
      elementId: ui.inputTitle.id,
      inputType: "InputText",
      validation: ui.inputTitle.validation
    });
    this.description = new InputValidator({
      elementId: ui.inputDescription.id,
      inputType: "InputText",
      validation: ui.inputDescription.validation
    });
  };
  //---------------------------------------------------------------------------
  initialize(data?: IDocumentDetails): IDocumentDetailsValidationState {
    const validationStateId: IValidationState = this.id.validate(data?.documentId, true);
    const validationStateTitle: IValidationState = this.title.validate(data?.title, true);
    const validationStateDescription: IValidationState = this.description.validate(data?.description, true);
    //-------------------------------------------------------------------------
    return this.getValidationState(validationStateId, validationStateTitle, validationStateDescription);
  };
  //---------------------------------------------------------------------------
  getValidationState(id: IValidationState, title: IValidationState, description: IValidationState): IDocumentDetailsValidationState {
    return {
      id: id,
      title: title,
      description: description,
      isValid: id.isValid && title.isValid && description.isValid
    };
  };
  //---------------------------------------------------------------------------
  validateId(id: string, value: string, validationState: IDocumentDetailsValidationState, items: IDocumentDetails[]): IDocumentDetailsValidationState {
    let idIsUnique = true;
    const validationStateId: IValidationState = this.id.validate(value, idIsUnique);
    if (validationStateId.isValid && this.id.validation?.unique && items) {
      const duplicate = items.find(item => item.documentId.toLowerCase() === value.toLowerCase() && item.documentId != id);
      ValidateUnique({
        elementId: this.id.elementId,
        validation: this.id.validation,
        data: value,
        isUnique: !duplicate,
        validationState: validationStateId
      });
    };
    //-------------------------------------------------------------------------
    return this.getValidationState(validationStateId, validationState.title, validationState.description);
  };
  //-------------------------------------------------------------------------
  validateTitle(id: string, value: string, validationState: IDocumentDetailsValidationState, items: IDocumentDetails[]): IDocumentDetailsValidationState {
    let isUnique = true;
    const validationStateTitle: IValidationState = this.title.validate(value, isUnique);
    if (validationStateTitle.isValid && this.title.validation?.unique && items) {
      const duplicate = items.find(item => item.documentId.toLowerCase() === value.toLowerCase() && item.documentId != id);
      ValidateUnique({
        elementId: this.id.elementId,
        validation: this.id.validation,
        data: value,
        isUnique: !duplicate,
        validationState: validationStateTitle
      });
    };
    //-------------------------------------------------------------------------
    return this.getValidationState(validationState.id, validationStateTitle, validationState.description);
  };
  //-------------------------------------------------------------------------
  validateDescription(id: string, value: string, validationState: IDocumentDetailsValidationState, items: IDocumentDetails[]): IDocumentDetailsValidationState {
    let isUnique = true;
    const validationStateDescription: IValidationState = this.title.validate(value, isUnique);
    if (validationStateDescription.isValid && this.title.validation?.unique && items) {
      const duplicate = items.find(item => item.documentId.toLowerCase() === value.toLowerCase() && item.documentId != id);
      ValidateUnique({
        elementId: this.id.elementId,
        validation: this.id.validation,
        data: value,
        isUnique: !duplicate,
        validationState: validationStateDescription
      });
    };
    //-------------------------------------------------------------------------
    return this.getValidationState(validationState.id, validationState.title, validationStateDescription);
  };
}