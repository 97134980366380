import React, { ReactNode } from "react";
import { ScreenType, useAppScreenContext } from "../../../context/app-screen-context/AppScreenProvider";
import CombineStyles from "../../../utils/combineStyles";
import BreadcrumbsTrail from "../breadcrumbs-trail/BreadcrumbsTrail";
import ConsoleHeader from "./console-header/ConsoleHeader";
import ConsoleMenu from "./console-menu/ConsoleMenu";
import styles from "./ConsoleLayout.module.css";
import { TUiConsoleLayout } from "./TUiConsoleLayout";

interface IProps {
  ui: TUiConsoleLayout;
  path?: string;
  hash?: boolean;
  displayHeader?: boolean;
  headerContent?: ReactNode;
  hideSidebar?: boolean;
  children: ReactNode;
  blinkingOptionPath?: string;
  showTopLine?: boolean;
  hideMenuOnMobile?: boolean;
  onOptionSelect?: (optionId: string) => void;
}

export default function ConsoleLayout(props: IProps) {
  const screenType = useAppScreenContext();
  const hideMenu = screenType == ScreenType.Mobile && props.hideMenuOnMobile;
  const displayHeader = props.displayHeader == undefined ? true : props.displayHeader;
  const hideSidebar = props.hideSidebar == undefined ? false : props.hideSidebar;
  //---------------------------------------------------------------------------
  const contentStyles = screenType === ScreenType.Desktop ? styles.contentDesktop : styles.contentMobile;
  //--------------------------------------------------------------------------- <div className={styles.header}>{props.ui?.header}</div>
  return (
    <div className={CombineStyles([
      styles.container,
      props.showTopLine ? styles.topline : ""
    ])}>

      {displayHeader &&
        <ConsoleHeader>
          <BreadcrumbsTrail />
        </ConsoleHeader>}

      <div className={contentStyles}>

        {props.ui.menuContent && !hideMenu &&
          <ConsoleMenu
            ui={props.ui.menuContent}
            defaultPath={props.path}
            hash={props.hash}
            blinkingOptionPath={props.blinkingOptionPath}
            onOptionSelect={props.onOptionSelect}
          />}

        <div
          className={CombineStyles([
            styles.client,
            screenType == ScreenType.Mobile ? styles.mobile : ""
          ])}>
          {props.children}
        </div>

        {screenType == ScreenType.Desktop && !hideSidebar &&
          <div className={styles.sidebarright}>

          </div>}

      </div>

      <div className={styles.footer}></div>

    </div>
  );
}
