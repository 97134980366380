import { IPublicClientApplication } from "@azure/msal-browser";
import { TUser } from "../TUser";
import { apiBasePath, getUserApi } from "../../../utils/apiPathConstant";
import { IAuthConfig } from "../AppAuthContext";
import { apiGetPrivate, checkResponse } from "../../../utils/api";

interface IProps {
  msalInstance: IPublicClientApplication;
  authConfig?: IAuthConfig;
  abortSignal?: AbortSignal;
}

export async function getUserRemotelyAsync(props: IProps): Promise<TUser> {
  if (!props.authConfig) throw new Error("getUserRemotelyAsync: auth config is not provided");
  //---------------------------------------------------------------------------
  const response = await apiGetPrivate(
    props.msalInstance,
    props.authConfig,
    `${apiBasePath}${getUserApi}`,
    props.abortSignal
  );
  //---------------------------------------------------------------------------
  checkResponse(response, "getUserRemotelyAsync", props.abortSignal?.aborted);
  const user = response?.content as TUser;
  return user;
}