import { IPublicClientApplication } from "@azure/msal-browser";
import { IAppAuthContext, IAuthConfig } from "../../../context/app-auth-context/AppAuthContext";
import { ImageEditorSession } from "./image-editor-context/ImageEditorSession";
import { EImageSize, TImageData } from "./TImageData";
import { apiGetPrivate, apiPostPrivate } from "../../../utils/api";
import { apiBasePath, getImageLoader, mergeImageBlocks, uploadImageBlock, uploadImageSize } from "../../../utils/apiPathConstant";
import { stringFormatter } from "../../../utils/common";
import { IApiResponse } from "../../../utils/api";
import { swPostPrivate } from "../../../service-worker/ServiceWorker";
import { EHttpStatusCode } from "../../../utils/HttpStatusCodes";
//----------------------------------------------------------------------
export type BlockUploadEndCallback = (
  imageBlock: ImageBlock,
  session: ImageEditorSession,
  config: IAuthConfig,
  instance: IPublicClientApplication) => void;
//----------------------------------------------------------------------
export type EImageBlockType = "Block" | "Thumbnail" | "Medium";
//----------------------------------------------------------------------
export interface IImageBlock {
  imageId: string;
  blockId?: string;
  blockType: EImageSize;
  imageSize: number;
  blockIndex: number;
  blockStart: number;
  blockEnd: number;
  blob?: Blob;
  onUploadEnd?: BlockUploadEndCallback;
}
//----------------------------------------------------------------------
export class ImageBlock implements IImageBlock {
  imageId: string = "";
  blockId?: string;
  blockType!: EImageSize;
  originalFileName: string = "";
  targetFileName: string = "";
  contentType: string = "";
  blockIndex: number = -1;
  imageSize: number = -1;
  blockStart: number = -1;
  blockEnd: number = -1;
  blockMd5?: string;
  imageMd5?: string;
  blockRange: string = "";
  data?: ArrayBuffer;
  blob?: Blob;
  // imagedata used only to send block to backend, 
  // after sending it must be set to undefined to avoid circular references
  imageData?: TImageData;
  downloadStatus?: number;
  uploaded: boolean = false;
  onUploadEnd?: BlockUploadEndCallback;

  constructor(source: IImageBlock) {
    if (source)
      Object.assign(this, source);
  }
  //-------------------------------------------------------------------------------------
  static createResizedBlock(
    blockType: EImageSize,
    imageId: string,
    blob: Blob
  ) {
    return new ImageBlock({
      blockType,
      imageSize: blob.size,
      imageId,
      blob,
      blockIndex: -1,
      blockStart: 0,
      blockEnd: blob.size
    });
  }
  //-------------------------------------------------------------------------------------
  toJSON() {
    let result = {
      ...this,
      uploaded: null,
      blob: null,
      originalFileName: null,
      targetFileName: null,
    };
    delete result["uploaded"];
    delete result["blob"];
    delete result["originalFileName"];
    delete result["targetFileName"];
    return result;
  }
  get blockSize(): number {
    return this.blockEnd - this.blockStart;
  }
  set blockSize(value: number) { }
  //-----------------------------------------------------------------------------------
  upload(
    blob: Blob,
    session: ImageEditorSession,
    config: IAuthConfig,
    instance: IPublicClientApplication,
    imageData?: TImageData) {
    //--------------------------------------------------------------------------------
    const extension = blob.type.split("/")[1];
    const file: any = new File([blob], `image.${extension}`, { type: blob.type });
    let formData: FormData = new FormData();
    formData.append("data", file, `image.${extension}`);
    formData.append("properties", JSON.stringify(this));
    if (imageData)
      formData.append("imageData", JSON.stringify(imageData));

    apiPostPrivate(
      instance, config,
      `${apiBasePath}${stringFormatter(uploadImageBlock, [this.imageId])}`,
      formData,
      "image"
    )
      .then((response) => {
        if (response && response.status === 200) {
          let imageBlock: ImageBlock = response.content;
          this.blockId = imageBlock.blockId;
          console.log("ImageBlock.upload succeded. Result:", imageBlock);
          this.onUploadEnd && this.onUploadEnd(this, session, config, instance);
        } else {
          console.error(response);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
      });
  }
  //-----------------------------------------------------------------------------------
  async uploadAsync(
    config: IAuthConfig,
    instance: IPublicClientApplication,
    imageData?: TImageData,
    session?: ImageEditorSession): Promise<ImageBlock> {
    //--------------------------------------------------------------------------------
    if (!this.blob/* || this.blockIndex == 3*/) {
      this.uploaded = false;
      return this;
    }
    this.imageData = imageData;
    let stringThis = JSON.stringify(this);
    this.imageData = undefined;
    //console.log("ImageBlock.stringify:", stringThis);

    let formData: FormData = new FormData();
    //let fileName = imageData?.fileName ? imageData.fileName : `image.${this.blob.type.split("/")[1]}`;
    let fileName = `image.${this.blob.type.split("/")[1]}`;

    //const extension = blob.type.split("/")[1];
    //const file: any = new File([blob], `image.${extension}`, { type: blob.type });
    //formData.append("data", file, `image.${extension}`);
    formData.append("data", this.blob, fileName);
    formData.append("block", stringThis);
    //if (imageData)
    //formData.append("imageData", JSON.stringify(imageData));
    try {
      console.log(`uploading ImageBlock:`, formData);
      this.blockId = undefined;
      let response = await apiPostPrivate(
        instance, config,
        `${apiBasePath}${stringFormatter(uploadImageBlock, [this.imageId])}`,
        formData,
        "image"
      );
      if (response && response.status === 200) {
        let imageBlock: ImageBlock = response.content;
        this.blockId = imageBlock.blockId;
        console.log("ImageBlock.upload succeded. Result:", imageBlock);
        this.uploaded = true;
        session && this.onUploadEnd && this.onUploadEnd(this, session, config, instance);
      } else {
        console.error("ImageBlock.upload response:", response);
        this.uploaded = false;
      }
    } catch (error) {
      console.error(error);
      this.uploaded = false;
    }
    return this;
  }
  //-----------------------------------------------------------------------------------
  async uploadInServiceWorkerAsync(imageData: TImageData, token?: string): Promise<ImageBlock> {
    //--------------------------------------------------------------------------------
    if (!this.blob) {
      this.uploaded = false;
      return this;
    }
    //--------------------------------------------------------------------------------
    // stringify block with imageData, then drop imageData to eliminate circular references
    this.imageData = imageData;
    let stringThis = JSON.stringify(this);
    this.imageData = undefined;
    //console.log("ImageBlock.stringify.this:", this);
    //console.log("ImageBlock.stringify:", stringThis);
    //--------------------------------------------------------------------------------

    let formData: FormData = new FormData();
    //let fileName = imageData.fileName ? imageData.fileName : `image.${this.blob.type.split("/")[1]}`;
    let fileName = `image.${this.blob.type.split("/")[1]}`;
    formData.append("data", this.blob, fileName);
    formData.append("block", stringThis);
    try {
      console.log(`uploading ImageBlock:`, formData);
      this.blockId = undefined;
      let response = await swPostPrivate(
        `${apiBasePath}${stringFormatter(uploadImageBlock, [this.imageId])}`,
        token,
        formData,
        "image"
      );
      if (response && response.status === EHttpStatusCode.OK) {
        let imageBlock: ImageBlock = response.content;
        this.blockId = imageBlock.blockId;
        console.log("ImageBlock.upload succeded. Result:", imageBlock);
        this.uploaded = true;
      } else {
        console.error("ImageBlock.upload response:", response);
        this.uploaded = false;
      }
    } catch (error) {
      console.error(error);
      this.uploaded = false;
    }
    return this;
  }
  //-----------------------------------------------------------------------------------
  async uploadAgainAsync(
    imageData: TImageData,
    config: IAuthConfig,
    instance: IPublicClientApplication,
    session?: ImageEditorSession): Promise<ImageBlock> {
    //--------------------------------------------------------------------------------
    if (!this.blob) {
      this.uploaded = false;
      return this;
    }

    this.imageData = imageData;
    let stringThis = JSON.stringify(this);
    this.imageData = undefined;

    //let fileName = imageData?.fileName ? imageData.fileName : `image.${this.blob.type.split("/")[1]}`;
    let fileName = `image.${this.blob.type.split("/")[1]}`;

    let formData: FormData = new FormData();
    //const extension = this.blob.type.split("/")[1];
    //const file: any = new File([this.blob], `image.${extension}`, { type: this.blob.type });
    //formData.append("data", file, `image.${extension}`);
    formData.append("data", this.blob, fileName);
    formData.append("block", stringThis);
    try {
      console.log(`uploading ImageBlock:`, formData);
      this.blockId = undefined;
      let response = await apiPostPrivate(
        instance, config,
        `${apiBasePath}${stringFormatter(uploadImageBlock, [this.imageId])}`,
        formData,
        "image"
      );
      if (response && response.status === EHttpStatusCode.OK) {
        let imageBlock: ImageBlock = response.content;
        this.blockId = imageBlock.blockId;
        console.log("ImageBlock.upload succeded. Result:", imageBlock);
        this.uploaded = true;
        session && this.onUploadEnd && this.onUploadEnd(this, session, config, instance);
      } else {
        console.error("ImageBlock.upload response:", response);
        this.uploaded = false;
      }
    } catch (error) {
      console.error(error);
      this.uploaded = false;
    }
    return this;
  }
  //-----------------------------------------------------------------------------------
  async download(uri: string) {
    try {
      this.data = undefined;
      this.downloadStatus = undefined;
      var headers = new Headers();
      headers.append("content-Type", "arraybuffer");
      headers.append("x-ms-version", "2020-12-06");
      headers.append("range", this.blockRange);
      //---------------------------------------------------------------------------
      let options: RequestInit = {
        method: "GET",
        headers: headers,
      };
      // options.mode = "cors";
      // options.cache = "no-cache";
      // options.redirect = "follow";
      // options.referrerPolicy = "no-referrer";
      //---------------------------------------------------------------------------
      const response = await fetch(uri, options);
      if (response) {
        let status = response.status;
        if (status == 200 || status == 206) {
          this.data = await response.arrayBuffer();
        }
        this.downloadStatus = status;
      }
    }
    catch (error) {
      console.log(error);
      if (!this.downloadStatus)
        this.downloadStatus = 500;
    }
    return this;
  }
}
//--------------------------------------------------------------------------------------
export class TImageLoader extends TImageData {
  blob!: Blob;
  //--------------------------------------------------------------------------------------
  static blockSize = 524288; // bytes
  //--------------------------------------------------------------------------------------
  largeUploaded: boolean = false;
  thumbnailUploaded: boolean = false;
  mediumUploaded: boolean = false;
  blocksMerged: boolean = false;
  //--------------------------------------------------------------------------------------
  private _blocks: ImageBlock[] = [];
  get blocks(): ImageBlock[] {
    return this._blocks;
  }
  set blocks(source: any) {
    this._blocks = [];
    if (source) {
      source.forEach((item: any) => this._blocks.push(new ImageBlock(item)));
    }
  }
  constructor(source: any) {
    super(undefined);
    if (source !== undefined)
      Object.assign(this, source);
  }
  //--------------------------------------------------------------------------------------
  static create(source: TImageData, blob: Blob) {
    let result = new TImageLoader(undefined);
    Object.assign(result, source, { blob: blob });
    return result;
  }
  //--------------------------------------------------------------------------------------
  toJSON() {
    let result = {
      ...this,
      _blocks: null,
      blocks: this.blocks,
    };
    delete result["_blocks"];
    return result;
  }
  //---------------------------------------------------------------------------
  uploadImageBySize(imageSize: EImageSize, token?: string) {
    // Maximum size of any dimension.
    let maxPixels: number | undefined = 512;
    switch (imageSize) {
      case 'Medium':
        maxPixels = 512;
        break;
      case 'Thumbnail':
        maxPixels = 100;
        break;
      default: {
        console.error("uploadImageBySize error: incorrect image size:", imageSize);
        return;
      }
    }
    console.log("uploadImageBySize.size:", imageSize);
    // Width and height.
    let originalWidth = this.widthPx as number;
    let originalHeight = this.heightPx as number;
    // Compute best factor to scale entire image based on larger dimension.
    let factor: number;
    if (originalWidth > originalHeight)
      factor = maxPixels / originalWidth;
    else
      factor = maxPixels / originalHeight;

    console.log("uploadImageBySize.resizeFactor:", factor);

    if (factor >= 1) {
      console.log(`uploadImageBySize: resize factor is ${factor} - uploading image as is (without resizing)`);
      this.uploadImageAsIs(this.blob, imageSize, token);
      return;
    }

    let targetWidth = Math.round(originalWidth * factor);
    let targetHeight = Math.round(originalHeight * factor);

    // get it back as a Blob
    let options: ImageBitmapOptions = {
      resizeWidth: targetWidth,
      resizeHeight: targetHeight,
      resizeQuality: "high"
    };
    createImageBitmap(this.blob, options)
      .then((bmp: ImageBitmap) => {
        let canvas = new OffscreenCanvas(bmp.width, bmp.height);//new HTMLCanvasElement();// document.createElement('canvas');
        //canvas.width = originalWidth;
        //canvas.height = originalHeight;
        let ctx = canvas.getContext('bitmaprenderer');
        ctx?.transferFromImageBitmap(bmp);
        const imageType = this.blob.type;//'image.png';
        canvas.convertToBlob({ quality: 1, type: imageType })
          .then((image: Blob | null) => {
            console.log("uploadImageBySize.size:", image?.size);
            if (!image)
              return;
            this.uploadImageAsIs(image, imageSize, token);
          });
      }); //.then
  }
  //---------------------------------------------------------------------------
  uploadImageAsIs(blob: Blob, imageSize: EImageSize, token?: string) {
    console.log("uploadImageAsIs.blob.size:", blob.size);
    //let fileName = this.fileName ? this.fileName : `image.${blob.type.split("/")[1]}`;
    let fileName = `image.${blob.type.split("/")[1]}`;
    let formData: FormData = new FormData();
    formData.append("image", blob, fileName);
    formData.append("imageData", JSON.stringify(this));
    let api = `${apiBasePath}${stringFormatter(uploadImageSize, [imageSize])}`;
    swPostPrivate(api, token, formData, "image")
      .then((response) => {
        console.log("swPostPrivate.then.response:", response);
        if (response && response.status === EHttpStatusCode.OK) {
          console.log("uploadImageAsIs succeded. Result:", response.content);
          switch (imageSize) {
            case 'Medium':
              this.mediumUploaded = true;
              break;
            case 'Thumbnail':
              this.thumbnailUploaded = true;
              break;
            case 'Large':
              this.largeUploaded = true;
              break;
          }
        } else {
          console.error(response);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
      });
  }
  //--------------------------------------------------------------------------------------
  createBlocks() {
    let imageId = this.id as string;
    let imageSize = this.blob.size;
    let blockCount = Math.ceil(imageSize / TImageLoader.blockSize);
    let start = 0;
    let blockIndex = 0;
    while (start < imageSize) {
      let end = start + TImageLoader.blockSize;
      if (end > imageSize)
        end = imageSize;
      let block: ImageBlock = new ImageBlock({
        blockType: "Large",
        blob: this.blob.slice(start, end, this.blob.type),
        imageSize: imageSize,
        imageId: imageId,
        blockIndex: blockIndex,
        blockStart: start,
        blockEnd: end
      });
      this.blocks.push(block);
      start = end;
      blockIndex++;
    }
  }
  //--------------------------------------------------------------------------------------
  async upload(token?: string) {
    //--------------------------------------------------------------------------------------
    // blocks must be already created by createBlocks()
    //--------------------------------------------------------------------------------------
    let imageId = this.id as string;
    try {
      console.log("TImageLoader.uploadByBlocks:", this);
      let imageSize = this.blob.size;
      if (!this.mediumUploaded) {
        this.uploadImageBySize('Medium', token);
      }
      if (!this.thumbnailUploaded) {
        this.uploadImageBySize('Thumbnail', token);
      }
      let promises: Promise<ImageBlock>[] = [];
      this._blocks.forEach(block => {
        if (!block.uploaded) {
          promises.push(block.uploadInServiceWorkerAsync(this, token))
        }
      });
      if (promises.length == 0) {
        console.log(`uploadImageByBlocks[${imageId}]: all blocks [${this._blocks.length}] already uploaded`);
      }
      console.log(`uploadImageByBlocks[${imageId}].start, blockCount: [${promises.length}]`);
      await Promise.all(promises);
      let blockIds: string[] = [];
      this.blocks.forEach(block => {
        if (block.blockId) {
          blockIds.push(block.blockId);
        }
      });
      // if all block ids assigned: merge blocks to final blob
      if (blockIds.length != this.blocks.length)
        throw Error(`uploadImageByBlocks[${imageId}]: total blocks: [${this.blocks.length}], uploaded: [${blockIds.length}]`);

      console.log(`uploadImageByBlocks[${imageId}], blocks uploaded: merge blocks`);

      this.blockIds = blockIds;
      let response = await swPostPrivate(
        `${apiBasePath}${mergeImageBlocks}`,
        token,
        this
      );
      if (!response)
        throw Error(`uploadImageByBlocks[${imageId}] no response from mergeImageBlocksAsync`);
      if (response.status != 200)
        throw Error(`uploadImageByBlocks[${imageId}] merge response is not success: [${response}]`);
      this.blocksMerged = true;
      console.log(`uploadImageByBlocks[${imageId}]: blocks merged`);
      return true;
    }
    catch (error) {
      console.error(`uploadImageFromEditedUrlByBlocks[${imageId}].catch:`, error);
    }
    return false;
  }
  //--------------------------------------------------------------------------------------
  static async getLoader(
    imageId: string,
    imageSize: EImageSize,
    config?: IAuthConfig | null,
    instance?: IPublicClientApplication | null) {
    //--------------------------------------------------------------------------------------
    if (!instance || !config)
      return undefined;
    //--------------------------------------------------------------------------------------
    try {
      let response = await apiGetPrivate(
        instance, config,
        `${apiBasePath}${stringFormatter(getImageLoader, [imageId])}`
      );
      if (response && response.status === 200) {
        let imageLoader = new TImageLoader(response.content);
        console.log("ImageLoader:", imageLoader);
        return imageLoader;
      } else {
        console.error(response);
      }
    }
    catch (error) {
      console.error(error);
      return undefined;
    }
    finally {

    }
  }
  //--------------------------------------------------------------------------------------
  async download() {
    let promises: Promise<ImageBlock>[] = [];
    //this._blocks.forEach(block => promises.push(block.download(this.uri)));
    Promise.all(promises)
      .then((blocks: ImageBlock[]) => {
        console.log("blocks:", blocks);
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
