import { IAdCampaign } from "../../../context/business-context/IAdCampaign";
import { apiPost, checkResponse } from "../../../utils/api";
import { apiBasePath, getAdForHome, getAdForHomeWithCategory } from "../../../utils/apiPathConstant";
import { stringFormatter } from "../../../utils/common";
import { ITrackable } from "../../../utils/tracker";
import { IAdModule } from "../../common/ad-modules/IAdModule";
import { ClassAdModulePublished } from "../ClassAdLanguageSetPublished";

export class HomePageBlock implements ITrackable {
  adLanguageSetId!: string;
  adCampaign!: IAdCampaign;
  //--------------------------------------------------------------------
  private _photoblock!: ClassAdModulePublished;
  get photoblock(): ClassAdModulePublished {
    return this._photoblock;
  }
  set photoblock(value: IAdModule) {
      this._photoblock = new ClassAdModulePublished(value);
  }
  //--ITrackable implementation----------------------------------------
  get businessId(): string {
    return this._photoblock.businessId;
  }
  get localeId(): string {
    return this._photoblock.localeId;
  }
  get text(): string {
    return this._photoblock.header?.text ? this._photoblock.header?.text : "";
  }
  get adCampaignId(): string | undefined {
    return this.adCampaign?.id;
  }
  get adModuleId(): string {
    return this._photoblock.id;
  }
  //--------------------------------------------------------------------
  constructor(source: any) {
    Object.assign(this, source);
  }
  //--------------------------------------------------------------------
  static fromArray(source: any) {
    let result: HomePageBlock[] = [];
    source?.forEach((item: any) => {
      result.push(new HomePageBlock(item));
    });
    return result;
  }
}

interface IResponse {
  ads: HomePageBlock[];
  continuationToken?: string;
}

interface IGetAdsProps {
  localeId: string;
  category?: string;
  numberOfAds: number;
  continuationToken?: string;
}

export class GetAdsProps implements IGetAdsProps {
  localeId!: string;
  category?: string;
  numberOfAds!: number;
  continuationToken?: string;
  constructor(localeId: string, numberOfAds: number, category?: string, token?: string) {
    this.localeId = localeId;
    this.numberOfAds = numberOfAds;
    this.category = category;
    this.continuationToken = token;
  }
  //-----------------------------------------------------------------------------
  equal(other?: GetAdsProps) {
    if (!other) return false;
    return (
      this.localeId == other.localeId &&
      this.numberOfAds == other.numberOfAds &&
      this.category == other.category &&
      this.continuationToken == other.continuationToken
    );
  }
}

export async function getAdsAsync(props: IGetAdsProps): Promise<IResponse> {
  const apiUri = props.category ?
    stringFormatter(getAdForHomeWithCategory, [props.localeId, props.category, props.numberOfAds.toString()])
    :
    stringFormatter(getAdForHome, [props.localeId, props.numberOfAds.toString()]);
  //---------------------------------------------------------------------------
  const response = await apiPost(
    `${apiBasePath}${apiUri}`,
    props.continuationToken,
    undefined);
  //---------------------------------------------------------------------------, props.abortSignal?.aborted
  checkResponse(response, "getAdLanguageSetAsync");
  return {
    ads: HomePageBlock.fromArray(response.content.ads),
    continuationToken: response.content.continuationToken
  };
  // const ads = response.content.ads as IAdModule[];
  // const result = ads?.map(ad => new ClassAdModulePublished(ad));
  // return {
  //   ads: result,
  //   continuationToken: response.content.continuationToken
  // };
}