import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { AppUiContextStore } from "../../context/app-ui-context/AppUiContextProvider";
import WikiSpinner from "../app-layout/spinner/wikiSpinner";
import HomePageLayout from "./HomePageLayout";
import usePageTitle from "../../hooks/usePageTitle";
import { defaultPagePath } from "./search-panel/SearchPanelFunctions";
import { IUiHomepage } from "./IUiHomepage";
import { ELogLevel } from "../../utils/logging";
import { useAbortController } from "../../hooks/useAbortController";
import { pathRedirect } from "../../App";
import { getBusinessPageAsync } from "./functions/getBusinessPageAsync";
import useNavigateWithContext from "../../hooks/useNavigateWithContext";
import { ClassAdLanguageSetPublished } from "./ClassAdLanguageSetPublished";
import BusinessPage from "../business-page/BusinessPage";
import { tmpUiBusinessPage } from "../business-page/IUiBusinessPage";
import Homepage from "./Homepage";
//----------------------------------------------------------------------------
class ClassBusinessPage {
  id!: string;
  locale!: string;
  constructor(id: string, locale: string) {
    this.id = id;
    this.locale = locale;
  }
  //----------------------------------------------------------------------------
  equal(id: string, locale: string) {
    return this.id == id && this.locale == locale;
  }
}
//----------------------------------------------------------------------------
const logLevel: ELogLevel = ELogLevel.None;
//----------------------------------------------------------------------------
interface IProps {
  //ui?: TUiApplicationLayout;
  ui?: IUiHomepage;
}

export default function HomepageWrapper(props: IProps) {
  //---------------------------------------------------------------------------
  // This component should determine what page is requested based on API response
  // It can be Regional home page (system business ad page) or Business page (user's business ad page)
  // Whatever the type if API do not respond with success, Not Found page must be displayed
  //---------------------------------------------------------------------------
  const { appUiContext } = useContext(AppUiContextStore);
  const { businessPath, locale } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [regionalHomepage, setRegionalHomepage] = useState<ClassAdLanguageSetPublished>();
  const [userBusinessPage, setUserBusinessPage] = useState<ClassAdLanguageSetPublished>();
  const navigate = useNavigateWithContext();
  const updateTitle = usePageTitle();
  const abortController = useAbortController("HomepageWrapper");
  const businessPage = useRef<ClassBusinessPage>();
  //--------------------------------------------------------------------------- Abortcontroller
  useEffect(() => {
    //TUserType.toLS(location.pathname, EUserType.Anonymous, "HomepageWrapper");
    return () => {
      abortController.abortOnUnmount();
    }
  }, []);
  //--------------------------------------------------------------------------- Catch login redirect, Data
  useEffect(() => {
    updateTitle("Home");
    const pagePath = businessPath ? businessPath : defaultPagePath;
    const pageLocale = locale ? locale : appUiContext.locale.localeId;
    if (pagePath == pathRedirect || appUiContext.sysSettings.sysLocalesList.find(value => value == pageLocale)) {
      setIsLoading(false);
      return;
    };
    //-------------------------------------------------------------------------
    // Try to retrieve business page - business can be user's or system (regional homepage)
    getBusinessPage(pagePath, pageLocale);
  }, [businessPath, locale, appUiContext.locale.localeId]);
  //---------------------------------------------------------------------------
  const getBusinessPage = (businessPath: string, localeId: string) => {
    //console.log("getBusinessPage.businessPage.current:", businessPage.current);
    //console.log("getBusinessPage.businessPage.new:", businessPath, localeId);
    if (businessPage.current && businessPage.current.equal(businessPath, localeId)) {
      return;
    }
    businessPage.current = new ClassBusinessPage(businessPath, localeId);
    setIsLoading(true);
    let controller = abortController.newController("getBusinessPage");
    getBusinessPageAsync({
      businessPath: businessPath,
      localeId: localeId,
      //abortSignal: controller.signal
    }).then(response => {
      setRegionalHomepage(response.regionalHomepage);
      setUserBusinessPage(response.userBuisnessPage);
    }).catch(error => {
      console.error(error);
      if (!controller.aborted) {
        navigate(`/notfound/${businessPath}/${localeId}`);
      };
    }).finally(() => {
      //!controller.aborted && setIsLoading(false);
      setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  if (props.ui) return (
    <React.Fragment>

      <WikiSpinner show={isLoading} />

      {regionalHomepage && !isLoading &&
        <HomePageLayout
          ui={props.ui}
          regionalHomepage={regionalHomepage}
        />}

      {/* {regionalHomepage && !isLoading &&
          <Homepage
          />} */}

      {userBusinessPage && !isLoading &&
        <BusinessPage
          ui={tmpUiBusinessPage}
          data={userBusinessPage}
        />}

    </React.Fragment>
  );
  else return null;
} 