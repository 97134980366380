import { CSSProperties, useState } from 'react';
import { IAdModuleParagraph } from '../../ad-modules/IAdModule';
import styles from './ContentParagraph.module.css';
import ContentImage from '../content-image/ContentImage';
import IconInline from '../../icon/icon-inline/IconInline';
import CombineStyles from '../../../../utils/combineStyles';
import { EOptionsParagraph } from '../../../ad-content-editor/ad-modules-editor/AdModuleOptions';
import React from 'react';
import PopupMenu from '../../popup-v2/popup-menu/PopupMenu';
import { IUiMenuContent } from '../../options/menus/IUiMenuContent';
import { getTUiMenuContent } from '../../menu/menu-content/TUiMenuContent';
import { IUiOption } from '../../options/IUiOption';
import Icon from '../../icon/Icon';
import { getUnitColor } from '../../ad-modules/AdModule';

interface IProps {
  data: IAdModuleParagraph;
  isReadonly: boolean;
  highlightedPath?: string[];
  onOptionSelect?: (optionId: EOptionsParagraph, paragraphId: string) => void;
}

export default function ContentParagraph(props: IProps) {
  const [menuOpen, setMenuOpen] = useState(false);
  const contextMenuOptionId = `${props.data.id}_ContextMenu`;
  let containerFlex = false;
  let imageAtTheEnd = false;
  let textOnTheLeft = false;
  let textOnTheRight = false;
  const paragraphIsHighlighted = props.highlightedPath && props.highlightedPath[props.highlightedPath.length - 1] == props.data.id;
  const textIsHighlighted = props.data.text ? props.highlightedPath?.includes(props.data.id) && props.highlightedPath?.includes(props.data.text.type) : false;
  const imageIsHighlighted = props.data.image ? props.highlightedPath?.includes(props.data.id) && props.highlightedPath?.includes(props.data.image.type) : false;
  //---------------------------------------------------------------------------
  const dynamicStylesImage: CSSProperties = {};
  if (props.data.image) {
    if (props.data.image?.positionV == "Bottom") {
      if (props.data.image?.positionH) {
        // NOTE: experimental styles - it does not work properly, because text is going outside the container
        // https://www.geeksforgeeks.org/how-to-float-an-element-to-the-bottom-corner-in-css/
        // https://css-tricks.com/float-an-element-to-the-bottom-corner/
        dynamicStylesImage.height = "80%";
        dynamicStylesImage.alignItems = "flex-end";
        dynamicStylesImage.shapeOutside = "inset(calc(100% - 9.5em) 0 0)";
        // 100% - X => X here should be the actual height of the image, for now it's placeholder height (8em) + 1.5em
      } else {
        imageAtTheEnd = true;
      };
    };
    if (props.data.image?.positionH == "Left") {
      if (props.data.image?.positionV) {
        dynamicStylesImage.float = "left";
      } else {
        containerFlex = true;
        textOnTheLeft = true;
      };
    };
    if (props.data.image?.positionH == "Right") {
      if (props.data.image?.positionV) {
        dynamicStylesImage.float = "right";
      } else {
        containerFlex = true;
        imageAtTheEnd = true;
        textOnTheRight = true;
      };
    };
    if (imageIsHighlighted || paragraphIsHighlighted) {
      dynamicStylesImage.boxShadow = `0 0 5px 5px ${getUnitColor(props.data.image.status)}`;
    };
  };
  //---------------------------------------------------------------------------
  const onClickText = () => {
    if (props.isReadonly || !props.onOptionSelect) return;
    props.onOptionSelect("TextEdit", props.data.id);
  };
  //---------------------------------------------------------------------------
  const onContextMenuOpen = () => {
    if (props.isReadonly || !props.onOptionSelect) return;
    setMenuOpen(true);
  };
  //---------------------------------------------------------------------------
  const onContextMenuOptionSelect = (optionId: string) => {
    if (props.isReadonly || !props.onOptionSelect) return;
    setMenuOpen(false);
    // Convert UI option id to actual option id
    switch (optionId) {
      case "OptionSelectTemplate":
        props.onOptionSelect("SelectTemplate", props.data.id);
        break;
      case "OptionEditText":
        props.onOptionSelect("TextEdit", props.data.id);
        break;
      case "OptionEditImage":
        props.onOptionSelect("ImageEdit", props.data.id);
        break;
      case "OptionSelectImage":
        props.onOptionSelect("ImageSelect", props.data.id);
        break;
      case "OptionDelete":
        props.onOptionSelect("Delete", props.data.id);
        break;
    };
  };
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  if (!props.isReadonly && props.onOptionSelect) {
    tmpUiParagraphMenu.options.forEach(option => {
      switch (option.id) {
        case "OptionSelectTemplate":
          updatedOptions.push({ ...option, iconId: "template" });
          break;
        case "OptionEditText":
          if (props.data.text) {
            updatedOptions.push({ ...option, iconId: "textEdit" });
          };
          break;
        case "OptionEditImage":
          if (props.data.image && props.data.image.imageData) {
            updatedOptions.push({ ...option, iconId: "imageEdit" });
          };
          break;
        case "OptionSelectImage":
          if (props.data.image) {
            updatedOptions.push({ ...option, iconId: "imageSelect" });
          };
          break;
        case "OptionDelete":
          updatedOptions.push({ ...option, iconId: "delete" });
          break;
      };
    });
  };
  //---------------------------------------------------------------------------
  return (
    <div
      id={props.data.id}
      className={CombineStyles([
        styles.container,
        containerFlex ? styles.flex : '',
        props.isReadonly ? styles.readonly : ''
      ])}
    >

      {props.data.image && !imageAtTheEnd &&
        <div
          className={styles.image}
          style={dynamicStylesImage}
        >
          <ContentImage
            data={props.data.image}
            isReadonly={props.isReadonly}
          />
        </div>}

      {props.data.text &&
        <div
          className={CombineStyles([
            styles.text,
            textOnTheLeft ? styles.left : '',
            textOnTheRight ? styles.right : ''
          ])}
          style={{ color: textIsHighlighted || paragraphIsHighlighted ? getUnitColor(props.data.text?.status) : undefined }}
        >
          <span
            onClick={onClickText}>
            {props.data.text?.text}
            &nbsp;
            {!props.isReadonly && props.data.text &&
              <IconInline imageName="pencil" />}
          </span>
        </div>}

      {props.data.image && imageAtTheEnd &&
        <div
          className={styles.image}
          style={dynamicStylesImage}
        >
          <ContentImage
            data={props.data.image}
            isReadonly={props.isReadonly}
          />
        </div>}

      {!props.isReadonly &&
        <div
          id={contextMenuOptionId}
          className={styles.contextMenuOption}
          onClick={onContextMenuOpen}
        >
          <Icon imageName="kebabButton" />
        </div>}

      {menuOpen &&
        <PopupMenu
          anchorId={contextMenuOptionId}
          ui={getTUiMenuContent({ ...tmpUiParagraphMenu, options: updatedOptions })}
          displayIcon={true}
          onClose={() => setMenuOpen(false)}
          onSelect={onContextMenuOptionSelect}
        />}

    </div>
  );
}

//"TextEdit" | "ImageEdit" | "ImageSelect" | "Delete" | "SelectTemplate"
const tmpUiParagraphMenu: IUiMenuContent = {
  id: "",
  directoryId: "",
  elementType: "",
  hint: undefined,
  options: [{
    id: "OptionSelectTemplate",
    directoryId: "",
    elementType: "",
    caption: { en: "Select Template..." },
    hint: undefined
  }, {
    id: "OptionEditText",
    directoryId: "",
    elementType: "",
    caption: { en: "Edit Text..." },
    hint: undefined
  }, {
    id: "OptionEditImage",
    directoryId: "",
    elementType: "",
    caption: { en: "Edit Image..." },
    hint: undefined
  }, {
    id: "OptionSelectImage",
    directoryId: "",
    elementType: "",
    caption: { en: "Select Image..." },
    hint: undefined
  }, {
    id: "OptionDelete",
    directoryId: "",
    elementType: "",
    caption: { en: "Delete Paragraph" },
    hint: undefined
  }]
};