import React, { useContext } from "react";
import Icon from "../../icon/Icon";
import styles from "./ImageEditorOption.module.css";
import { IUiOption } from "../../options/IUiOption";
import { AppUiContextStore } from "../../../../context/app-ui-context/AppUiContextProvider";
import GetHint from "../../functions/GetHint";
import CombineStyles from "../../../../utils/combineStyles";

interface IProps {
  ui: IUiOption;
  onSelect: (optionId: string) => void;
}

export default function ImageEditorOption(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  //---------------------------------------------------------------------------
  const onSelect = () => {
    if (props.ui.isDisabled) return;
    props.onSelect(props.ui.id);
  };
  //--------------------------------------------------------------------------- transform="scale(1,-1)"
  return (
    <div
      title={GetHint(appUiContext, props.ui.id, props.ui.hint)}
      className={CombineStyles([
        styles.container,
        props.ui.isDisabled ? styles.disabled : ''
      ])}
      style={{ color: props.ui.isDisabled ? undefined : props.ui.customColor }}
      onClick={onSelect}
    >
      <Icon imageName={props.ui.iconId} />
    </div>
  );
}

export const optionSeparator: IUiOption = {
  id: '',
  directoryId: '',
  elementType: '',
  iconId: 'separator',
  caption: undefined,
  hint: undefined,
  isDisabled: true
}