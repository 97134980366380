import { useContext, useRef, useState } from 'react';
import DirectoryNodeAttribute from '../../../../common/directory-node-attribute/DirectoryNodeAttribute';
import DirectoryNodeWrapper from '../../../../common/directory-node-wrapper/DirectoryNodeWrapper';
import styles from '../../../../common/directory-node-wrapper/DirectoryNodeWrapper.module.css';
import { DocumentDraftItem } from '../IDocumentDraftItem';
import EditFormDocumentItem from '../document-item-editform/EditFormDocumentItem';
import { IUiOption } from '../../../../../common/options/IUiOption';
import { IUiDocumentItemNode } from './IUiDocumentItemNode';
import { DirectoryEditorContext } from '../../../../../../context/directory-editor-context/DirectoryEditorContextProvider';
import React from 'react';
import TextEditorPopup from '../../../../../common/text-editor/text-editor-popup/TextEditorPopup';
import { IUiMenuContent } from '../../../../../common/options/menus/IUiMenuContent';
import { IContentUnitText } from '../../../../../common/content/content-text/IContentUnitText';
import { ClassContentUnitStatus } from '../../../../../common/content/IContentUnit';

const maxNestLevel = 2;

interface IProps {
  ui?: IUiDocumentItemNode;
  draftId: string;
  numberToDisplay?: string;
  data: DocumentDraftItem;
  isReadonly: boolean;
  onAdd: (newItem: DocumentDraftItem, position?: number) => void;
  onUpdate: (updatedItem: DocumentDraftItem) => void;
  onDelete: (deletedItem: DocumentDraftItem) => void;
  onRestore: (restoredItem: DocumentDraftItem) => void;
}

export default function DocumentItemNode(props: IProps) {
  const { directoryEditorContext } = useContext(DirectoryEditorContext);
  const [isExpanded, setIsExpanded] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [newItem, setNewItem] = useState<DocumentDraftItem>();
  const newItemPosition = useRef(0);
  const [textEditorState, setTextEditorState] = useState<string>();
  const filterConditionIsMet = directoryEditorContext.filterConditionIsMet(props.data);
  const documentDraftItems = directoryEditorContext.editorState?.draft.items as DocumentDraftItem[];
  const subItems = documentDraftItems.filter(item =>
    item.path === props.data.fullPath &&
    item.id !== props.data.id &&
    directoryEditorContext.filterConditionIsMet(item)
  );
  //---------------------------------------------------------------------------
  const onUpdate = (updatedItem: DocumentDraftItem) => {
    props.onUpdate(updatedItem);
    setEditMode(false);
  };
  //---------------------------------------------------------------------------
  const onAdd = (newItem: DocumentDraftItem) => {
    let position = undefined;
    if (newItemPosition.current < 0) {
      // Add before
      const currentItemIndex = documentDraftItems.findIndex(item => item.id == props.data.id);
      if (currentItemIndex)
        position = currentItemIndex;
    } else if (newItemPosition.current > 0) {
      // Add after
      const currentItemIndex = documentDraftItems.findIndex(item => item.id == props.data.id);
      if (currentItemIndex)
        position = currentItemIndex + 1;
    };
    props.onAdd(newItem, position);
    setNewItem(undefined);
  };
  //---------------------------------------------------------------------------
  const openTextEditor = () => {
    setTextEditorState(props.data.text?.text ? props.data.text.text : '');
  };
  //---------------------------------------------------------------------------
  const closeTextEditor = () => {
    setTextEditorState(undefined);
  };
  //---------------------------------------------------------------------------
  const onTextUpdate = (updatedValue: string) => {
    const updatedText: IContentUnitText = props.data.text ? {
      ...props.data.text as IContentUnitText,
      text: updatedValue
    } : {
      type: "Text",
      text: updatedValue,
      status: new ClassContentUnitStatus("Draft")
    };
    const updatedItem = new DocumentDraftItem({
      ...props.data,
      text: updatedText
    });
    if (updatedItem.original) {
      if (updatedItem.original.text?.text === updatedValue)
        updatedItem.isEdited = false;
      else
        updatedItem.isEdited = true;
    };
    props.onUpdate(updatedItem);
    setTextEditorState(undefined);
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (selectedOptionId: string) => {
    switch (selectedOptionId) {
      case "OptionEdit":
        setEditMode(true);
        break;
      case "OptionEditText":
        openTextEditor();
        break;
      case "OptionDelete":
        props.onDelete(props.data);
        break;
      case "OptionRestore":
        props.onRestore(props.data);
        break;
      case "OptionAddBefore":
        newItemPosition.current = -1;
        const newItemBefore = DocumentDraftItem.getNewItem(props.data.type === "Title" ? "Section" : props.data.type);
        newItemBefore.path = props.data.path;
        newItemBefore.isNumbered = props.data.isNumbered;
        newItemBefore.isMarked = props.data.isMarked;
        setNewItem(newItemBefore);
        break;
      case "OptionAddAfter":
        newItemPosition.current = 1;
        const newItemAfter = DocumentDraftItem.getNewItem(props.data.type === "Title" ? "Section" : props.data.type);
        newItemAfter.path = props.data.path;
        newItemAfter.isNumbered = props.data.isNumbered;
        newItemAfter.isMarked = props.data.isMarked;
        setNewItem(newItemAfter);
        break;
      case "OptionAddSub":
        newItemPosition.current = 0;
        const newSubItem = DocumentDraftItem.getNewItem("Paragraph");
        newSubItem.path = props.data.fullPath;
        setNewItem(newSubItem);
        break;
    };
  };
  //--------------------------------------------------------------------------- Node options
  let updatedMenuContent;
  if (!props.isReadonly) {
    const updatedOptions: IUiOption[] = [];
    props.ui?.menuContent.options.forEach(option => {
      switch (option.id) {
        case "OptionEdit":
          updatedOptions.push({ ...option, iconId: "pencil" });
          break;
        case "OptionEditText":
          updatedOptions.push({ ...option, iconId: "textEdit" });
          break;
        case "OptionDelete":
          if (props.data.type !== "Title" && !props.data.isDeleted)
            updatedOptions.push({ ...option, iconId: "delete" });
          break;
        case "OptionRestore":
          if (props.data.isDeleted)
            updatedOptions.push({ ...option, iconId: "delete" });
          break;
        case "OptionAddBefore":
          if (props.data.type !== "Title")
            updatedOptions.push({ ...option, iconId: "plus" });
          break;
        case "OptionAddAfter":
          updatedOptions.push({ ...option, iconId: "plus" });
          break;
        case "OptionAddSub":
          if (props.data.type !== "Title" && props.data.type !== "Paragraph" && props.data.level < maxNestLevel)
            updatedOptions.push({ ...option, iconId: "plus" });
          break;
      };
    });
    updatedMenuContent = { ...props.ui?.menuContent as IUiMenuContent, options: updatedOptions };
  } else {
    updatedMenuContent = { ...props.ui?.menuContent as IUiMenuContent, options: [] };
  };
  //---------------------------------------------------------------------------
  let firstColumn = <DirectoryNodeAttribute />;
  switch (props.data.type) {
    case "Title":
      firstColumn =
        <DirectoryNodeAttribute
          isFirstColumn={true}
          level={props.data.level}
          hideToggerIfNotExpandable={true}
          customStyles={{ color: props.data.header?.status?.isInTrouble ? 'var(--clr_accent_wrong)' : undefined }}
        >
          {props.data.header?.text}
        </DirectoryNodeAttribute>;
      break;
    case "Section":
      firstColumn =
        <DirectoryNodeAttribute
          isFirstColumn={true}
          level={props.data.level}
          isExpandable={subItems.length > 0}
          onToggle={() => setIsExpanded(!isExpanded)}
          isExpanded={isExpanded}
          customStyles={{ color: props.data.header?.status?.isInTrouble ? 'var(--clr_accent_wrong)' : undefined }}
        >
          {props.numberToDisplay ? `${props.numberToDisplay}. ${props.data.header?.text}` : props.data.header?.text}
        </DirectoryNodeAttribute>;
      break;
    case "Paragraph":
      firstColumn =
        <DirectoryNodeAttribute
          isFirstColumn={true}
          level={props.data.level}
          hideToggerIfNotExpandable={true}
        >
          {props.numberToDisplay ?
            <React.Fragment>
              <span>
                {`${props.numberToDisplay}.`}
              </span>
            </React.Fragment> :
            ""}
          {props.data.isMarked &&
            <span>
              ·
            </span>}
        </DirectoryNodeAttribute>;
      break;
  };
  //---------------------------------------------------------------------------
  let i = 1;
  const subItemNodes = subItems.map(subItem => {
    const documentItem = subItem as DocumentDraftItem;
    let number = '';
    if (documentItem.isNumbered) {
      number = props.numberToDisplay ? `${props.numberToDisplay}.${i}` : i.toString();
      i++;
    };
    return (
      <DocumentItemNode
        key={subItem.id}
        numberToDisplay={number}
        ui={props.ui}
        draftId={props.draftId}
        data={documentItem}
        isReadonly={!directoryEditorContext.editMode}
        onAdd={props.onAdd}
        onUpdate={props.onUpdate}
        onDelete={props.onDelete}
        onRestore={props.onRestore}
      />
    );
  });
  //---------------------------------------------------------------------------
  if (props.ui && props.data && (filterConditionIsMet || subItems.length > 0)) {
    if (editMode) return (
      <EditFormDocumentItem
        ui={props.ui.editForm}
        draftId={props.draftId}
        data={props.data}
        isReadonly={props.isReadonly}
        onUpdate={onUpdate}
        onClose={() => setEditMode(false)}
      />
    ); else return (
      <React.Fragment>

        {newItem && newItemPosition.current === -1 &&
          <EditFormDocumentItem
            ui={props.ui.editForm}
            draftId={props.draftId}
            data={newItem}
            isReadonly={props.isReadonly}
            onUpdate={onAdd}
            onClose={() => setNewItem(undefined)}
          />}

        <DirectoryNodeWrapper
          ui={{ ...props.ui as IUiDocumentItemNode, menuContent: updatedMenuContent }}
          data={props.data}
          onOptionSelect={onOptionSelect}
        >

          {firstColumn}

          <DirectoryNodeAttribute>
            <span
              title={props.data.text?.status?.rejectionReason?.reasonDetails}
              className={styles.link}
              style={{ color: props.data.text?.status?.isInTrouble ? 'var(--clr_accent_wrong)' : undefined }}
              onClick={openTextEditor}
            >
              {props.data.text?.text ? props.data.text?.text : "+"}
            </span>
          </DirectoryNodeAttribute>

        </DirectoryNodeWrapper>

        {isExpanded && subItemNodes}

        {newItem && newItemPosition.current > -1 &&
          <EditFormDocumentItem
            ui={props.ui.editForm}
            draftId={props.draftId}
            data={newItem}
            isReadonly={props.isReadonly}
            onUpdate={onAdd}
            onClose={() => setNewItem(undefined)}
          />}

        {textEditorState != undefined &&
          <TextEditorPopup
            data={textEditorState}
            isReadonly={!directoryEditorContext.editMode}
            onUpdate={onTextUpdate}
            onClose={closeTextEditor}
          />}

      </React.Fragment>
    );
  } else return null;
}