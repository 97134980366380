export interface IEditable {
  initialize(): IEditable;
  isEqual(object: IEditable): boolean;
  isValid(): boolean;
}

export interface IEditFormStateV2<T extends IEditable> {
  object: T;
  initialState?: T;
  isNew?: boolean;
  isUpdated?: boolean;
  isValid?: boolean;
}

export class EditFormStateV2<T extends IEditable> implements IEditFormStateV2<T> {
  object!: T;
  initialState?: T;
  isNew!: boolean;
  isUpdated!: boolean;
  isValid!: boolean;
  //---------------------------------------------------------------------------
  constructor(source: IEditFormStateV2<T>, spread?: Partial<IEditFormStateV2<T>>) {
    Object.assign(this, source, spread);
    if (spread == undefined) {
      // State is being initialized
      this.object = source.object.initialize() as T;
      this.isUpdated = false;
      if (this.isNew) {
        // If it's a new object, initial state is undefined
        this.initialState = undefined;
      } else {
        // It's an existing object
        this.initialState = source.object.initialize() as T;
        this.isUpdated = false;
      };
      this.isValid = this.object.isValid();
    } else {
      //-----------------------------------------------------------------------
      // State is being updated
      //console.log("update", this.object, this.initialState)
      if (this.object.isEqual(this.initialState as T)) {
        // Nothing's changed
        //console.log("no changes")
        this.isUpdated = false;
      } else {
        // There are changes
        //console.log("changed")
        this.isUpdated = true;
      };
      this.isValid = this.object.isValid();
    };
  };
}