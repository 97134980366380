import React, { useEffect, useState } from 'react';
import CombineStyles from '../../../../../utils/combineStyles';
import ButtonCaption from '../../../button-caption/ButtonCaption';
import { ButtonType } from '../../../button/ButtonType';
import { TUiMenuOption } from '../../../menu/menu-content/menu-option/TUiMenuOption';
import styles from './PopupMenuItem.module.css';
import { TUiMenuContent } from '../../../menu/menu-content/TUiMenuContent';
import PopupMenu from '../PopupMenu';

interface IProps {
  ui: TUiMenuOption;
  displayIcon: boolean;
  showDividerBefore?: boolean;
  showDividerAfter?: boolean;
  onSelect?: (selectedOptionId: string) => void;
  onClose: () => void;
}

export default function PopupMenuItem(props: IProps) {
  const [subMenu, setSubMenu] = useState<TUiMenuContent>();
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const isDropdown = props.ui.showDropdown == undefined ? false : props.ui.showDropdown;
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (props.ui.action) {
      var subMenu = props.ui.action as TUiMenuContent;
      if (!subMenu.id) return;
      if (subMenu && ("id" in subMenu)) {
        setSubMenu(subMenu);
      };
    };
  }, [props.ui.action]);
  //---------------------------------------------------------------------------
  const onSelect = () => {
    if (!props.ui.disabled) {
      if (subMenu) {
        // Need to show submenu
        setSubMenuOpen(true);
      } else {
        // If it's not a submenu
        if (props.onSelect) {
          // If callback is provided, just call it
          props.onSelect(props.ui.id);
        } else {
          // If there are no callback, then action must be a func
          //console.error("Option selection handler is not provided!");
          const handler = props.ui.action as () => void;
          handler();
          props.onClose();
        };
      };
    };
  };
  //---------------------------------------------------------------------------
  const onSubmenuSelect = (selectedOptionId: string) => {
    setSubMenuOpen(false);
    props.onSelect && props.onSelect(`${props.ui.id}/${selectedOptionId}`);
  }
  //---------------------------------------------------------------------------
  const onSubMenuClose = () => {
    // This mean we need to close current popup and all popups in the stack, i.e. get back all the way
    setSubMenuOpen(false);
    props.onClose();
  };
  //---------------------------------------------------------------------------
  const onSubMenuBack = () => {
    // This mean we need to close only current popup, i.e. get back one step
    setSubMenuOpen(false);
  };
  //---------------------------------------------------------------------------
  const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSelect();
  };
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>
      <div
        id={props.ui.id}
        className={CombineStyles([
          styles.container,
          props.displayIcon ? "" : styles.withoutIcon,
          props.ui.disabled ? styles.disabled : "",
          props.showDividerBefore ? styles.dividerBefore : "",
          props.showDividerAfter ? styles.dividerAfter : "",
        ])}
        //onClick={onSelect}
        onClick={onClick}
      >
        <ButtonCaption
          type={ButtonType.Popup}
          ui={props.ui}
          customCaption={props.ui.customCaption}
          displayIcon={props.displayIcon}
          isDropdown={isDropdown || !!subMenu}
          count={props.ui.count}
        />
      </div>

      {subMenu && subMenuOpen &&
        <PopupMenu
          ui={subMenu}
          displayIcon={true}
          onClose={onSubMenuClose}
          onBack={onSubMenuBack}
          onSelect={onSubmenuSelect}
        />}

    </React.Fragment>
  );
}