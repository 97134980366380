import { pathRedirect } from "../../App";
import { getFromLS, setToLS } from "../../utils/storage";

export type TUserIdentity = {
  id: string;
  displayName?: string;
  firstName?: string;
  lastName?: string;
  country?: string;
  mail?: string;
  otherMails?: string[];
  signInType?: "emailAddress" | "phoneNumber" | "federated";
  userPrincipalName?: string;
  abn?: string;
  userGroups?: string[];
}

export type TUserBusinessRoles = {
  businessEditorMode: boolean,
  owner: object | null,
  translator: object | null
}

type TModerator = {
  locales?: Array<string>
}

export type TStaffRoles = {
  administrator: object | null;
  moderator: TModerator | null;
  superModerator: object | null;
  contentManager: object | null;
  systemTranslator: object | null;
}

export type TUser = {
  userIdentity: TUserIdentity;
  preferredColourTheme?: string;
  preferredLanguage?: string;
  preferredLanguages?: string[];
  userLocale?: string;
  abnIsRequired?: boolean;
  rememberMe?: boolean;
  businessRoles?: TUserBusinessRoles;
  staffRoles?: TStaffRoles;
};

type StaffRolesKey = keyof TStaffRoles;

export class ClassUser implements TUser {
  userIdentity!: TUserIdentity;
  preferredColourTheme?: string;
  preferredLanguage?: string;
  preferredLanguages?: string[];
  userLocale?: string;
  abnIsRequired?: boolean;
  rememberMe?: boolean;
  businessRoles?: TUserBusinessRoles;
  staffRoles?: TStaffRoles;
  //---------------------------------------------------------------------------
  constructor(source: TUser) {
    Object.assign(this, source);
  };
  //---------------------------------------------------------------------------
  checkStaffRole(role: string): boolean {
    let hasRole = false;
    if (this.staffRoles) {
      Object.keys(this.staffRoles).forEach(userRole => {
        if (userRole.toLowerCase() == role.toLowerCase()) {
          hasRole = this.staffRoles && this.staffRoles[userRole as StaffRolesKey] != undefined ? true : false;
        };
      });
    };
    return hasRole;
  };
}


//----------------------------------------------------------------------------------------
export enum EUserType {
  Anonymous = 0,
  Authenticated = 1,
}
//----------------------------------------------------------------------------------------
export const keyUserType = 'userType';
//----------------------------------------------------------------------------------------
export class TUserType {
  url?: string;
  userType?: EUserType;
  loginFailed?: boolean;
  //----------------------------------------------------------------------------------------
  constructor(source: any) {
    if (source) {
      Object.assign(this, source);
    }
  }
  //----------------------------------------------------------------------------------------
  static fromLS(caller?: string): TUserType {
    let source = getFromLS(keyUserType);
    let result = new TUserType(source);
    console.log(`TUserType.fromLS[${caller}]:`, result);
    return result;
  }
  //----------------------------------------------------------------------------------------
  static toLS(url: string, userType: EUserType, caller?: string): TUserType {
    let result = new TUserType({url: url, userType: userType});
    setToLS(keyUserType, result);
    console.log(`TUserType.toLS[${caller}]:`, result);
    return result;
  }
  //----------------------------------------------------------------------------------------
  static check(url: string) {
    console.log("TUserType.check.location:", location);
    if (location.pathname.includes(pathRedirect)) {
      if (location.hash && location.hash.includes("error")) {
        let userType = new TUserType({
          url: "/", 
          userType: EUserType.Anonymous, 
          loginFailed: true
        });
        setToLS(keyUserType, userType);
      }
      return;
    }
    let userType = TUserType.fromLS();
    if (userType.userType === EUserType.Authenticated && location.pathname != userType.url) {
      TUserType.toLS(location.pathname, EUserType.Anonymous, "TUserType.check");
    }
  }
  //----------------------------------------------------------------------------------------
  static newUrl(url: string, caller?: string) {
    let userType = TUserType.fromLS();
    userType.url = url;
    setToLS(keyUserType, userType);
  }
  //----------------------------------------------------------------------------------------
  static newUserType(userType: EUserType, caller?: string) {
    let ut = TUserType.fromLS();
    ut.userType = userType;
    setToLS(keyUserType, ut);
  }
}
