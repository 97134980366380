import React, { useContext, useEffect, useState } from "react";
import styles from "./BusinessPage.module.css";
import { ClassAdLanguageSetPublished } from "../homepage/ClassAdLanguageSetPublished";
import AdModule from "../common/ad-modules/AdModule";
import { useTracker } from "../../utils/tracker";
import { IUiBusinessPage } from "./IUiBusinessPage";
import ConsoleLayout from "../common/console-layout/ConsoleLayout";
import { IUiOption } from "../common/options/IUiOption";
import { getTUiMenuContent } from "../common/menu/menu-content/TUiMenuContent";
import { TUiBreadcrumb } from "../common/breadcrumbs-trail/breadcrumb/TUiBreadcrumb";
import { AppUiContextStore } from "../../context/app-ui-context/AppUiContextProvider";
import Languages from "./languages/Languages";
import GetName from "../common/functions/GetName";
import Share from "./share/Share";
import { ScreenType, useAppScreenContext } from "../../context/app-screen-context/AppScreenProvider";
import FormOptions from "../common/form-options-bar/FormOptions";

type OptionId = "OptionHome" | "OptionReviews" | "OptionTags" | "OptionLanguages" | "OptionShare" | "OptionLists" | "OptionReportAbuse";

interface IMode {
  optionId: OptionId;
  path: string;
}
const modes: IMode[] = [{
  optionId: "OptionHome",
  path: "home"
}, {
  optionId: "OptionReviews",
  path: "reviews"
}, {
  optionId: "OptionTags",
  path: "tags"
}, {
  optionId: "OptionLanguages",
  path: "languages"
}, {
  optionId: "OptionShare",
  path: "share"
}, {
  optionId: "OptionLists",
  path: "lists"
}, {
  optionId: "OptionReportAbuse",
  path: "abuse"
}];

interface IProps {
  ui: IUiBusinessPage;
  data: ClassAdLanguageSetPublished;
}

export default function BusinessPage(props: IProps) {
  const { appUiContext, appUiContextDispatch } = useContext(AppUiContextStore);
  const screenType = useAppScreenContext();
  const [localeId, setLocaleId] = useState(props.data.localeId);
  const currentLocaleName = appUiContext.dictionaries?.supportedLocales?.find(item => item.id == localeId)?.name;
  const [mode, setMode] = useState<IMode>();
  const tracker = useTracker();
  const businessId = props.data.businessId;
  const rootPath = `/${businessId}`;
  //--------------------------------------------------------------------------- 
  useEffect(() => {
    tracker?.track("BusinessPage", props.data);
    //-------------------------------------------------------------------------
    const breadcrumb: TUiBreadcrumb = {
      id: businessId,
      //path: `/${businessId}/${localeId}`,
      path: `/${businessId}`,
      caption: { en: props.data.name }
    };
    appUiContextDispatch({ type: "UpdateBreadcrumbsTrail", value: breadcrumb });
  }, []);
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (mode) {
      const option = props.ui.menuContent.options.find(o => o.id == mode.optionId);
      const optionName = GetName(appUiContext, mode.optionId, option?.caption);
      const caption = mode.optionId == "OptionHome" ?
        `${optionName} (${GetName(appUiContext, localeId, currentLocaleName)})` :
        optionName;
      const breadcrumb: TUiBreadcrumb = {
        id: businessId,
        path: `/${businessId}/${mode.path}`,
        caption: { en: caption }
      };
      appUiContextDispatch({ type: "UpdateBreadcrumbsTrail", value: breadcrumb });
      window.history.replaceState(null, '', `${rootPath}/${mode.path}`);
    } else {
      const currentPath = location.pathname.split(`${rootPath}/`)[1];
      let mode = modes.find(mode => mode.path == currentPath);
      mode = mode ? mode : modes[0];
      setMode(mode);
      window.history.replaceState(null, '', `${rootPath}/${mode.path}`);
    };
  }, [mode]);
  //---------------------------------------------------------------------------
  const onLocaleSelect = (localeId: string) => {
    setLocaleId(localeId);
    setMode(modes[0]);

    // Request business page in selected language

  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (optionId: string) => {
    const mode = modes.find(item => item.optionId == optionId);
    setMode(mode);
  };
  //---------------------------------------------------------------------------
  let content;
  switch (mode?.optionId) {
    case "OptionHome":
      content = props.data.adModules?.map(adModule => (
        <AdModule
          key={adModule.id}
          adModule={adModule}
          isReadonly={true}
          isClickable={false}
          useExternalLayout={false}
          minHeightEm={20}
        />
      ));
      break;
    case "OptionLanguages":
      content =
        <Languages
          currentLocale={localeId}
          availableLocales={['en', 'tt', 'uk']}
          onSelect={onLocaleSelect}
        />;
      break;
    case "OptionShare":
      content =
        <Share
          path={rootPath}
        />;
      break;
  };
  //---------------------------------------------------------------------------
  const updatedOptionsSidebar: IUiOption[] = [];
  props.ui.menuContent.options.forEach(option => {
    const modePath = modes.find(item => item.optionId == (option.id as OptionId))?.path;
    const path = `${rootPath}/${modePath}`;
    switch (option.id) {
      case "OptionHome":
        updatedOptionsSidebar.push({
          ...option,
          iconId: "businessPage",
          action: path
        });
        break;
      case "OptionReviews":
        updatedOptionsSidebar.push({
          ...option,
          iconId: "review",
          action: path
        });
        break;
      case "OptionTags":
        updatedOptionsSidebar.push({
          ...option,
          iconId: "tags",
          action: path
        });
        break;
      case "OptionLanguages":
        updatedOptionsSidebar.push({
          ...option,
          iconId: "translator",
          action: path
        });
        break;
      case "OptionShare":
        updatedOptionsSidebar.push({
          ...option,
          iconId: "share",
          action: path
        });
        break;
      case "OptionLists":
        updatedOptionsSidebar.push({
          ...option,
          iconId: "favorite",
          action: path
        });
        break;
      case "OptionFavoritesAdd":
        /* updatedOptionsSidebar.push({
          ...option,
          iconId: "favorite",
          action: path
        }); */
        break;
      case "OptionFavoritesRemove":
        /* updatedOptionsSidebar.push({
          ...option,
          iconId: "favoriteOff",
          action: path
        }); */
        break;
      case "OptionReportAbuse":
        updatedOptionsSidebar.push({
          ...option,
          iconId: "reportAbuse",
          action: path
        });
        break;
    };
  });
  //---------------------------------------------------------------------------
  return (
    <ConsoleLayout
      ui={{ header: "", menuContent: getTUiMenuContent({ ...props.ui.menuContent, options: updatedOptionsSidebar }) }}
      path={`${props.data.id}`}
      showTopLine={true}
      hideMenuOnMobile={true}
      onOptionSelect={onOptionSelect}
    >

      <div className={styles.container}>

        <div className={styles.content}>
          {content}
        </div>

      </div>

      {screenType == ScreenType.Mobile &&
        <FormOptions
          ui={getTUiMenuContent({
            ...props.ui.menuContent,
            hint: { en: props.data.name },
            options: updatedOptionsSidebar
          })}
          onSelect={onOptionSelect}
        />}

    </ConsoleLayout>
  );
}