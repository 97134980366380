import { IPublicClientApplication } from "@azure/msal-browser";
import { TUserBusinessRoles } from "../../app-auth-context/TUser";
import { IAppAuthContext } from "../../app-auth-context/AppAuthContext";
import { apiBasePath, updateBusiness } from "../../../utils/apiPathConstant";
import { apiPostPrivate, checkResponse } from "../../../utils/api";
import { ClassCustomerBusiness } from "../../../components/business-space/business-editor/ICustomerBusiness";
import { ClassImageEditorContext } from "../../../components/common/image-editor/image-editor-context/ImageEditorContextProvider";
import submitBusinessTagRequestsAsync from "../../../components/business-space/functions/submitBusinessTagRequestsAsync";
import { IBusinessTagRequest } from "../IBusinessContext";

export interface ISaveBusinessResponse {
  updatedBusiness: ClassCustomerBusiness;
  updatedUserRoles: TUserBusinessRoles;
  updatedBusinessTagRequests?: IBusinessTagRequest[];
}

interface IProps {
  msalInstance: IPublicClientApplication;
  authContext?: IAppAuthContext;
  imageEditorContext?: ClassImageEditorContext;
  business: ClassCustomerBusiness;
  businessTagRequests?: IBusinessTagRequest[];
  abortSignal?: AbortSignal;
}

export default async function saveBusinessAsync(props: IProps): Promise<ISaveBusinessResponse> {
  if (!props.authContext) throw new Error("saveBusinessAsync: auth context is not provided");
  if (!props.imageEditorContext) throw new Error("saveBusinessAsync: image editor context is not provided");
  //---------------------------------------------------------------------------
  // Upload business images
  let imageUploadResult = await props.imageEditorContext.uploadAllImagesForAsync(
    props.business.id,
    props.authContext,
    props.msalInstance,
    true
  );
  if (!imageUploadResult) {
    throw "Something went wrong with image uploading. Please, try again later.";
  }
  //---------------------------------------------------------------------------
  // Save business data
  const businessResponse = await apiPostPrivate(
    props.msalInstance,
    props.authContext.config,
    `${apiBasePath}${updateBusiness}`,
    props.business,
    undefined,
    //props.abortSignal
  );
  checkResponse(businessResponse, "saveBusinessAsync", props.abortSignal?.aborted);
  //---------------------------------------------------------------------------
  // Submit business tags requests
  let updatedRequests: IBusinessTagRequest[] | undefined = undefined;
  if (props.businessTagRequests) {
    const requestsResponse = await submitBusinessTagRequestsAsync({
      msalInstanse: props.msalInstance,
      authConfig: props.authContext.config,
      businessId: props.business.id,
      businessTagRequests: props.businessTagRequests,
      //abortSignal: props.abortSignal
    });
    checkResponse(businessResponse, "submitBusinessTagRequests", props.abortSignal?.aborted);
    updatedRequests = requestsResponse;
  }
  //---------------------------------------------------------------------------
  // Submit business tags requests
  const responseData: ISaveBusinessResponse = {
    updatedBusiness: businessResponse?.content.updatedBusiness,
    updatedUserRoles: businessResponse?.content.updatedUserBusinessRoles,
    updatedBusinessTagRequests: updatedRequests
  };
  return responseData;
};

