import { useContext } from "react";
import { AppUiContextStore } from "../../../../context/app-ui-context/AppUiContextProvider";
import { BusinessSearchListItem } from "./BusinessSearchListItem";
import styles from "./TagChain.module.css";
import { TagChainElement } from "./TagChainElement";
import { ETagSearchResult } from "../../TagTree";

interface IProps {
  data: BusinessSearchListItem;
  searchResult: ETagSearchResult;
}

export function TagChain(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  //---------------------------------------------------------------------
  let tags = props.data.getTags(appUiContext, props.searchResult);
  let chain: string = "";
  const items: JSX.Element[] = [];
  let i = 0;
  while (i < tags.length) {
    if (i == 0)
      chain = tags[i];
    else
      chain += `, ${tags[i]}`;
    //items.push(<TagChainElement found={props.found} name={tags[i]} comma={i < (tags.length - 1)} />);
    items.push(<TagChainElement key={tags[i]} searchResult={props.searchResult} name={tags[i]} comma={false} />);
    i++;
  }

  return (
    <div className={styles.container}>
      {items}
    </div>
/*     <div className={props.found ? styles.containerFound : styles.containerNotFound}>
      {chain}
    </div>
 */  );
}