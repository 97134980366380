import { useContext, useEffect, useState } from 'react';
import { IUiInteractiveForm } from '../../../../../common/forms/IUiInteractiveForm';
import GetCaption from '../../../../../common/functions/GetCaption';
import PopupDialog from '../../../../../common/popup-v2/popup-dialog/PopupDialog';
import styles from './BusinessLocationsListPopup.module.css';
import { AppUiContextStore } from '../../../../../../context/app-ui-context/AppUiContextProvider';
import { BusinessContext } from '../../../../../../context/business-context/BusinessContextProvider';
import { IUiOption } from '../../../../../common/options/IUiOption';
import { EditFormState } from '../../../../../common/edit-form/IEditFormState';
import { IObjectLocation } from '../IBusinessLocationData';
import { getTUiMenuContent } from '../../../../../common/menu/menu-content/TUiMenuContent';
import FormOptions from '../../../../../common/form-options-bar/FormOptions';
import { ClassCustomerBusiness } from '../../../ICustomerBusiness';
import ListItemBusinessLocation from '../business-location-listItem/ListItemBusinessLocation';
import { ClassContentUnitStatus } from '../../../../../common/content/IContentUnit';
import { IContentUnitText } from '../../../../../common/content/content-text/IContentUnitText';

export interface IUiBusinessLocationsListPopup extends IUiInteractiveForm {

}

export const tmpUiBusinessLocationsListPopup: IUiBusinessLocationsListPopup = {
  id: "BusinessLocationsListPopup",
  directoryId: "",
  elementType: "",
  caption: { en: "Business locations" },
  hint: { en: "List of business locations" },
  menuContent: {
    id: "BusinessLocationsListPopup.MenuContent",
    directoryId: "",
    elementType: "",
    hint: undefined,
    options: [{
      id: "OptionOk",
      directoryId: "",
      elementType: "",
      caption: { en: "Ok" },
      hint: undefined
    }, {
      id: "OptionCancel",
      directoryId: "",
      elementType: "",
      caption: { en: "Cancel" },
      hint: undefined
    }]
  }
};

interface IProps {
  ui: IUiBusinessLocationsListPopup;
  data?: IObjectLocation<string | IContentUnitText>[];
  onUpdate: (updatedLocations: IObjectLocation<string>[]) => void;
  onClose: () => void;
}

export default function BusinessLocationsListPopup(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const { businessContext } = useContext(BusinessContext);
  const [state, setState] = useState<EditFormState<string[]>>();
  const business = businessContext.businessState?.business as ClassCustomerBusiness;
  //--------------------------------------------------------------------------- State
  useEffect(() => {
    const initialState = new EditFormState<string[]>({
      object: props.data ? props.data.map(location => location.id) : [],
      isValid: true
    });
    setState(initialState);
  }, [props.data]);
  //---------------------------------------------------------------------------
  const onToggle = (locationId: string) => {
    if (!state?.object) return;
    let updatedLocations = state.object;
    if (state.object.includes(locationId)) {
      // Exclude selected location
      updatedLocations = updatedLocations.filter(item => item != locationId);
    } else {
      //-----------------------------------------------------------------------
      // Include selected location
      const updatedList = state.object.concat(locationId);
      // Create new list to keep the order
      updatedLocations = [];
      business.locationData?.locations.forEach(location => {
        if (updatedList.includes(location.id)) {
          updatedLocations.push(location.id);
        };
      });
    };
    const updatedState = new EditFormState<string[]>(state, {
      object: updatedLocations,
      isValid: true
    });
    setState(updatedState);
  };
  //---------------------------------------------------------------------------
  const onConfirm = () => {
    const selectedLocations = business.locationData?.locations?.filter(location => state?.object?.includes(location.id));
    const updatedLocations: IObjectLocation<string>[] = [];
    selectedLocations?.forEach(location => {
      const locationToAdd: IObjectLocation<string> = {
        ...location,
        status: new ClassContentUnitStatus("Draft")
      };
      updatedLocations.push(locationToAdd);
    });
    props.onUpdate(updatedLocations ? updatedLocations : []);
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (optionId: string) => {
    switch (optionId) {
      case "OptionOk":
        onConfirm();
        break;
      case "OptionCancel":
        props.onClose();
        break;
    };
  };
  //---------------------------------------------------------------------------
  const locationsListItems = business.locationData?.locations?.map(location => {
    const isSelected = state?.object ? state.object.includes(location.id) : false;
    return (
      <ListItemBusinessLocation
        key={location.id}
        data={location}
        isSelected={isSelected}
        isReadonly={true}
        onToggle={onToggle}
      />);
  });
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  props.ui.menuContent.options.forEach(option => {
    switch (option.id) {
      case "OptionOk":
        updatedOptions.push({
          ...option,
          isDisabled: !state?.isUpdated,
          isDefault: state?.isUpdated
        });
        break;
      case "OptionCancel":
        updatedOptions.push(option);
        break;
    };
  });
  const formOptions =
    <FormOptions
      ui={getTUiMenuContent({ ...props.ui.menuContent, options: updatedOptions })}
      applyMobileStyle={false}
      onSelect={onOptionSelect}
    />;
  //---------------------------------------------------------------------------
  return (
    <PopupDialog
      id={props.ui.id}
      header={GetCaption(appUiContext, props.ui.id, props.ui.caption)}
      toolbarContent={formOptions}
      isModal={true}
      isDraggable={false}
      onClose={props.onClose}
    >
      <div className={styles.container}>
        {locationsListItems}
      </div>
    </PopupDialog>
  );
}