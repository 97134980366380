import { ClassUser, TUser } from "../../../../context/app-auth-context/TUser";
import { IUserReference } from "./IUserReference";

export interface IWorkflowLog {
  workflowId: string;
  actualRecordId: string;
  records: IWorkflowLogRecord[];
}

export interface IWorkflowLogRecord {
  id: string;
  workflowTask: IWorkflowTask;
  assignedTo: IUserReference;
  startedAt: string;
  updatedAt: string;
  completedAt: string;
  excludeUsers: string[];
  isCancelled: boolean;
  isRejected: boolean;
}

export interface IWorkflowTask {
  id: string;
  name: string;
  staffRoleName: string;
}

export class WorkflowLog implements IWorkflowLog {
  workflowId!: string;
  actualRecordId!: string;
  records!: IWorkflowLogRecord[];
  //---------------------------------------------------------------------------
  constructor(source?: IWorkflowLog) {
    source && Object.assign(this, source);
  };
  //---------------------------------------------------------------------------
  getActualRecord(): IWorkflowLogRecord | undefined {
    let actualRecord = undefined;
    if (this.actualRecordId) {
      actualRecord = this.records.find(r => r.id == this.actualRecordId);
    };
    return actualRecord;
  };
  //---------------------------------------------------------------------------
  getDraftStateString(): string | undefined {
    let draftStateString = undefined;
    if (this.actualRecordId) {
      const actualRecords = this.records.filter(r => !r.isCancelled);
      const currentRecordIndex = actualRecords.findIndex(r => r.id == this.actualRecordId);
      if (currentRecordIndex > -1) {
        const currentRecord = actualRecords[currentRecordIndex];
        if (currentRecord.isRejected) {
          draftStateString = `${currentRecord.workflowTask.name}: Rejected`;
        } else
          draftStateString = `Pending ${currentRecord.workflowTask.name}`;
      };
    };
    return draftStateString;
  };
  //---------------------------------------------------------------------------
  getUserCanSubmit(user: TUser): boolean | undefined {
    let canPublish = undefined;
    if (this.actualRecordId) {
      const currentRecord = this.records.find(r => r.id == this.actualRecordId);
      if (currentRecord) {
        const taskRole = currentRecord.workflowTask.staffRoleName.toLowerCase();
        const usr = new ClassUser(user);
        canPublish = usr.checkStaffRole(taskRole) && !currentRecord.excludeUsers.includes(user.userIdentity.id);
      } else {
        canPublish = false;
      };
    };
    return canPublish;
  };
}