import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../../context/app-auth-context/AppAuthContext";
import { apiPostPrivate, checkResponse } from "../../../../../utils/api";
import { apiBasePath, pathCompleteRequestDocument } from "../../../../../utils/apiPathConstant";
import { stringFormatter } from "../../../../../utils/common";
import { IDocumentDraft } from "../../../administrator-console/documents/document-editor/IDocumentDraft";

interface IProps {
  msalInstanse: IPublicClientApplication;
  authConfig?: IAuthConfig;
  abortSignal?: AbortSignal;
  requestId: string;
  documentDraft: IDocumentDraft;
  isApproved: boolean;
}

export async function completeDocumentRequestAsync(props: IProps): Promise<void> {
  //---------------------------------------------------------------------------
  if (!props.authConfig) throw new Error("completeDocumentRequestAsync: auth config is not provided");
  //---------------------------------------------------------------------------
  const uri = `${apiBasePath}${stringFormatter(
    pathCompleteRequestDocument,
    [props.requestId, props.documentDraft.locale, `${props.isApproved}`]
  )}`;
  const response = await apiPostPrivate(
    props.msalInstanse,
    props.authConfig,
    uri,
    props.documentDraft,
    undefined,
    props.abortSignal
  );
  //---------------------------------------------------------------------------
  checkResponse(response, "completeDocumentRequestAsync", props.abortSignal?.aborted);
}