import { IUiInputListContacts } from "../../business-space/business-editor/section-contacts/contacts-editor/IUiInputListContacts";
import { IUiImageEditor } from "../image-editor/IUiImageEditor";
import { IUiMenuContent } from "../options/menus/IUiMenuContent";

export interface IUiAdModule {
  menuContent: IUiMenuContent; // All possible context menu options for all types of blocks
  imageEditor?: IUiImageEditor;
  inputListContacts?: IUiInputListContacts;
}

export const tmpAdModuleMenuContent: IUiMenuContent = {
  hint: "Ad Module menu content",
  options: [
    {
      caption: { en: "Edit header..." },
      hint: null,
      id: "AdModule.MenuContent.OptionEditHeader",
      iconId: "pencil",
      directoryId: "UiBusinessConsole",
      elementType: "MenuOption"
    },
    {
      caption: { en: "Edit description..." },
      hint: null,
      id: "AdModule.MenuContent.OptionEditDescription",
      iconId: "pencil",
      directoryId: "UiBusinessConsole",
      elementType: "MenuOption"
    },
    {
      caption: { en: "Edit text..." },
      hint: null,
      id: "AdModule.MenuContent.OptionEditText",
      iconId: "pencil",
      directoryId: "UiBusinessConsole",
      elementType: "MenuOption"
    },
    {
      caption: { en: "Edit Image..." },
      hint: null,
      id: "AdModule.MenuContent.OptionImageEdit",
      iconId: "pencil",
      directoryId: "UiBusinessConsole",
      elementType: "MenuOption"
    },
    {
      caption: { en: "Select Image..." },
      hint: null,
      id: "AdModule.MenuContent.OptionImageSelect",
      iconId: "images",
      directoryId: "UiBusinessConsole",
      elementType: "MenuOption"
    },
    {
      caption: { en: "Select Template..." },
      hint: null,
      id: "AdModule.MenuContent.OptionSelectTemplate",
      iconId: "template",
      directoryId: "UiBusinessConsole",
      elementType: "MenuOption"
    },
    {
      caption: { en: "Add Paragraph" },
      hint: null,
      id: "AdModule.MenuContent.OptionAddParagraph",
      iconId: "plus",
      directoryId: "UiBusinessConsole",
      elementType: "MenuOption"
    },
    // {
    //   caption: { en: "Refresh locations..." },
    //   hint: null,
    //   id: "AdModule.MenuContent.OptionRefreshLocations",
    //   directoryId: "UiBusinessConsole",
    //   elementType: "MenuOption"
    // },
    // {
    //   caption: { en: "Refresh contacts..." },
    //   hint: null,
    //   id: "AdModule.MenuContent.OptionRefreshContacts",
    //   directoryId: "UiBusinessConsole",
    //   elementType: "MenuOption"
    // }
    {
      caption: { en: "Delete module" },
      hint: null,
      id: "AdModule.MenuContent.OptionDelete",
      iconId: "delete",
      directoryId: "UiBusinessConsole",
      elementType: "MenuOption"
    },
    {
      caption: { en: "Exclude module" },
      hint: null,
      id: "AdModule.MenuContent.OptionExclude",
      iconId: "close",
      directoryId: "UiBusinessConsole",
      elementType: "MenuOption"
    },
  ],
  id: "AdModule.MenuContent",
  directoryId: "UiBusinessConsole",
  elementType: "MenuContent"
}

export const tmpUiInputListContacts: IUiInputListContacts = {
  id: "InputListContacts",
  directoryId: "",
  elementType: "",
  caption: { en: "Contacts" },
  hint: undefined,
  menuContent: {
    id: "InputListContacts.MenuContent",
    directoryId: "",
    elementType: "",
    hint: undefined,
    options: [
      {
        id: "InputListContacts.MenuContent.OptionRefresh",
        directoryId: "",
        elementType: "",
        caption: { en: "Refresh" },
        hint: { en: "Refresh contact data" }
      },
      {
        id: "InputListContacts.MenuContent.OptionSelect",
        directoryId: "",
        elementType: "",
        caption: { en: "Select contacts..." },
        hint: { en: "Add or remove contacts" }
      },
      {
        id: "InputListContacts.MenuContent.OptionEdit",
        directoryId: "",
        elementType: "",
        caption: { en: "Edit" },
        hint: { en: "Edit" }
      },
      {
        id: "InputListContacts.MenuContent.OptionDelete",
        directoryId: "",
        elementType: "",
        caption: { en: "Delete" },
        hint: { en: "Delete" }
      }
    ]
  },
  editForm: {
    id: "InputListContacts.EditForm",
    directoryId: "",
    elementType: "",
    caption: { en: "Edit contact" },
    hint: undefined,
    menuContent: {
      id: "InputListContacts.EditForm.MenuContent",
      directoryId: "",
      elementType: "",
      hint: undefined,
      options: [
        {
          id: "OptionOk",
          directoryId: "",
          elementType: "",
          caption: { en: "Ok" },
          hint: undefined
        },
        {
          id: "OptionCancel",
          directoryId: "",
          elementType: "",
          caption: { en: "Cancel" },
          hint: undefined
        }
      ]
    },
    inputContactData: {
      id: "InputContactValue",
      directoryId: "",
      elementType: "",
      caption: { en: "Contact" },
    },
    inputContactDescription: {
      id: "InputContactDescription",
      directoryId: "",
      elementType: "",
      caption: { en: "Description" }
    }
  }
};

/* export const tmpUiAdModule: IUiAdModule = {
  menuContent: tmpAdModuleMenuContent,
  inputListContacts: 
}; */