import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../../context/app-auth-context/AppAuthContext";
import { apiBasePath, pathDeleteDocumentDraft } from "../../../../../utils/apiPathConstant";
import { apiDeletePrivate, checkResponse } from "../../../../../utils/api";
import { stringFormatter } from "../../../../../utils/common";
import { DocumentDraft } from "../document-editor/IDocumentDraft";

interface IProps {
  msalInstance: IPublicClientApplication;
  authConfig?: IAuthConfig;
  abortSignal?: AbortSignal;
  documentId: string;
  localeId: string;
}

export async function deleteDocumentDraftAsync(props: IProps): Promise<DocumentDraft | null> {
  if (!props.authConfig) throw new Error("deleteDocumentDraftAsync: auth config is not provided");
  //---------------------------------------------------------------------------
  const uri = `${apiBasePath}${stringFormatter(pathDeleteDocumentDraft, [props.documentId, props.localeId])}`;
  const response = await apiDeletePrivate(
    props.msalInstance,
    props.authConfig,
    uri,
    props.abortSignal
  );
  //---------------------------------------------------------------------------
  checkResponse(response, "deleteDocumentDraftAsync", props.abortSignal?.aborted);
  // It could be null if original does not exist
  const draft = response?.content ? new DocumentDraft().initialize(response?.content) : null;
  return draft;
}