import Icon from '../../../common/icon/Icon';
import styles from './BusinessStatNumbers.module.css';
//--------------------------------------------------------------------------
interface IProps {
  views: number;
  points: number;
  align?: boolean;
}
//--------------------------------------------------------------------------
export function BusinessStatNumbers(props: IProps) {
  //------------------------------------------------------------------------
  return (
    //<div className={props.align ? styles.containerAligned : styles.container}>
    <div className={styles.container}>
      <div className={styles.numberContainer}>
        <Icon imageName="eyeOpened" />
        <div className={styles.number}>
          {` : ${props.views}`}
        </div>
      </div>
      <div className={styles.numberContainer}>
        <Icon imageName="wallet" />
        <div className={styles.number}>
          {` : ${props.points}`}
        </div>
      </div>
    </div>
  );
}