import { TGeoPoint } from "../../map/geo-json/GeoJson";

interface IUserMapSettings {
  center: google.maps.LatLngLiteral;
  zoom: number;
}

export class UserMapSettings {
  settings?: IUserMapSettings;
  private _map: google.maps.Map;
  private _listener: google.maps.MapsEventListener;
  //----------------------------------------------------------
  private _id!: string;
  private _key!: string;
  get id(): string {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value ? value : "anonymous";
    this._key = `usr.${this._id}.mapSettings`;
    let s = localStorage.getItem(this._key);
    if (s) {
      this.settings = JSON.parse(s);
    }
    else {
      this.settings = undefined;
    }
  }
  //----------------------------------------------------------
  constructor(map: google.maps.Map, id?: string) {
    this._map = map;
    //this._listener = map.addListener("bounds_changed", this.setPosition.bind(this));
    this._listener = map.addListener("idle", this.setPosition.bind(this));    
    this.id = id ? id : "anonymous";
  }
  //----------------------------------------------------------
  idle() {
    console.log("UserMapSettings.idle");
  }
  //----------------------------------------------------------
  unmount() {
    console.log("UserMapSettings.unmount");
    this._listener?.remove();
    localStorage.removeItem(this._key);
  }
  //----------------------------------------------------------
  setPosition() {
    //console.log("UserMapSettings.setPosition:", this);
    if (!this._map)
      return;
    let center = this._map.getCenter()?.toJSON();
    let zoom = this._map.getZoom();
    if (center && zoom) {
      if (this.settings && this.settings.zoom == zoom && TGeoPoint.equals(center, this.settings.center)) {
        console.log("UserMapSettings.setPosition: nothing is changed");
        return;
      }
      this.settings = { center, zoom };
      localStorage.setItem(this._key, JSON.stringify(this.settings));
    }
    else {
      this.settings = undefined;
      localStorage.removeItem(this._key);
    }
    console.log("UserMapSettings.setPosition.this.settings:", this.settings);
  }
  //----------------------------------------------------------
  static getSettings(userId?: string): IUserMapSettings | undefined {
    let key = `usr.${userId ? userId : "anonymous"}.mapSettings`;
    let s = localStorage.getItem(key);
    return s != null ? JSON.parse(s) : undefined;
  }
  //----------------------------------------------------------
  static setSettings(settings: IUserMapSettings, userId?: string) {
    let key = `usr.${userId ? userId : "anonymous"}.mapSettings`;
    localStorage.setItem(key, JSON.stringify(settings));
  }
  //----------------------------------------------------------
  static getGeoPoint(userId?: string): TGeoPoint | undefined {
    let settings = UserMapSettings.getSettings(userId);
    if (!settings || !settings.center)
      return undefined;
    return TGeoPoint.fromLatLng(settings.center.lat, settings.center.lng);
  }
}