import styles from "./UserMenuButton.module.css";
import UserMenuButtonCaption from "./user-menu-button-caption/UserMenuButtonCaption";
import CombineStyles from "../../../../../utils/combineStyles";
import { useContext } from "react";
import { AppAuthContextStore } from "../../../../../context/app-auth-context/AppAuthContext";
import CountIndicator from "../../../../common/count-indicator/CountIndicator";

interface IProps {
  onClick: () => void;
}

export default function UserMenuButton(props: IProps) {
  const { appAuthContext } = useContext(AppAuthContextStore);
  const isAuthenticated = appAuthContext.user != undefined;
  const userInitial = appAuthContext.user?.userIdentity.displayName?.substring(0, 1).toLocaleUpperCase();
  const title = appAuthContext.user?.userIdentity.displayName;
  const inProgress = appAuthContext.isInProcess;
  const unreadMessagesCount = appAuthContext.unreadMessageCount;
  //---------------------------------------------------------------------------
  return (
    <div
      className={styles.container}
      onClick={props.onClick}
    >
      <div
        title={title}
        id="UserMenuButton"
        className={CombineStyles([
          styles.roundedButton,
          !userInitial && !inProgress ? styles.anonymous : ""
        ])}
      >
        <UserMenuButtonCaption
          key={inProgress.toString()}
          isAuthenticated={isAuthenticated}
          letter={userInitial}
          inProgress={inProgress}
        />

        <div className={styles.indicator}>
          <CountIndicator
            count={unreadMessagesCount}
          />
        </div>
  
      </div>
    </div>
  );
}