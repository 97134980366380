import React, { useContext, useState } from "react";
import Icon, { IconSize } from "../../../../common/icon/Icon";
import styles from "./MainMenuButton.module.css";
import mainMenuBarStyles from "../main-menu-bar/MainMenuBar.module.css";
import { AppUiContextStore } from "../../../../../context/app-ui-context/AppUiContextProvider";
import { ScreenType, useAppScreenContext } from "../../../../../context/app-screen-context/AppScreenProvider";
import MainMenuPopup from "../main-menu-popup/MainMenuPopup";
import { IUiOption } from "../../../../common/options/IUiOption";
import { IUiMenuContent } from "../../../../common/options/menus/IUiMenuContent";
import useNavigateWithContext from "../../../../../hooks/useNavigateWithContext";

interface IProps {
  //ui: IUiOption;
  onOptionSelect?: (selectedOptionId: string) => void;
  //clickHandler?: () => void;
}

export default function MainMenuButton(props: IProps) {
  // Main menu button controls state of main menu which is context-specific
  // Current main menu options set is stored in AppUiContext and can be updated by other components (consoles)
  const { appUiContext } = useContext(AppUiContextStore);
  const screenType = useAppScreenContext();
  const navigate = useNavigateWithContext();
  const [menuBarCollapsed, setMenuBarCollapsed] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  //---------------------------------------------------------------------------
  // On click collapse/expand main menu bar below the header
  const handleClick = () => {
    if (screenType == ScreenType.Desktop) {
      // On Desktop just toggle main menu bar visibility
      var mainMenuBar = document.querySelector('#mainMenuBar');
      if (menuBarCollapsed) {
        mainMenuBar?.classList.remove(mainMenuBarStyles.collapsed);
        setMenuBarCollapsed(false);
      } else {
        mainMenuBar?.classList.add(mainMenuBarStyles.collapsed);
        setMenuBarCollapsed(true);
      };
    } else {
      // On Mobile - open popup menu instead
      setPopupOpen(true);
    };
  }
  //---------------------------------------------------------------------------
  const onSelect = () => {
    console.log("onSelect", appUiContext.mainMenuContent);
    setPopupOpen(true);
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (selectedOptionId: string) => {
    console.log(selectedOptionId);
    setPopupOpen(false);
    //props.onOptionSelect && props.onOptionSelect(selectedOptionId);
    if (selectedOptionId.startsWith("OptionHomepage")) {
      const currentOptionId = selectedOptionId.split(".").pop();
      navigate(`?cat=${currentOptionId}`);
    } else if (selectedOptionId.startsWith("OptionBusiness")) {

    };
  };
  //---------------------------------------------------------------------------
  const onClose = () => {
    setPopupOpen(false);
  };
  //---------------------------------------------------------------------------
  // Construct options tree based on current user roles
  const updatedOptions: IUiOption[] = [];
  tmpUiMainMenuContent.options.forEach(option => {
    switch (option.id) {
      case "MainMenuContent.OptionHomepage":
        updatedOptions.push({
          ...option,
          iconId: "home",
          isDropdown: true
        });
        break;
      case "MainMenuContent.OptionBusiness":
        updatedOptions.push({
          ...option,
          iconId: "businessEditor",
          isDropdown: true
        });
        break;
      case "MainMenuContent.OptionAdmin":
        updatedOptions.push({
          ...option,
          iconId: "adminConsole",
          isDropdown: true
        });
        break;
      case "MainMenuContent.OptionPlaceAnAd":
        updatedOptions.push({
          ...option,
          iconId: "magicWand",
          isDropdown: false
        });
        break;
    };
  });
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>
      <div
        id="MainMenuButton"
        className={styles.container}
        //onClick={appUiContext.mainMenuButtonContext?.onClickAction ? appUiContext.mainMenuButtonContext.onClickAction : handleClick}
        onClick={onSelect}
      >
        <Icon
          imageName="hamburgerButton"
          size={IconSize.Large}
          reserveSpace={true}
        />
      </div>

      {popupOpen && //appUiContext.mainMenuContent &&
        <MainMenuPopup
          show={/* appUiContext.mainMenuContent &&  */popupOpen}
          ui={{ ...tmpUiMainMenuContent, options: updatedOptions }}
          selectedOptionPath={appUiContext.currentPagePath}
          onSelect={onOptionSelect}
          onClose={onClose}
        />}

    </React.Fragment>);
}



const tmpUiMainCategories: IUiMenuContent = {
  id: "",
  directoryId: "",
  elementType: "",
  hint: undefined,
  options: [{
    id: "Accommodation",
    directoryId: "",
    elementType: "",
    caption: { en: "Stay" },
    hint: undefined,
    iconId: "accommodation"
  }, {
    id: "Entertainment",
    directoryId: "",
    elementType: "",
    caption: { en: "See + Do" },
    hint: undefined,
    iconId: "entertainment"
  }, {
    id: "Food",
    directoryId: "",
    elementType: "",
    caption: { en: "Eat + Drink" },
    hint: undefined,
    iconId: "food"
  }, {
    id: "Job",
    directoryId: "",
    elementType: "",
    caption: { en: "Job" },
    hint: undefined,
    iconId: "job"
  }, {
    id: "Transport",
    directoryId: "",
    elementType: "",
    caption: { en: "Go" },
    hint: undefined,
    iconId: "transport"
  }, {
    id: "Extra",
    directoryId: "",
    elementType: "",
    caption: { en: "Extra" },
    hint: undefined,
    iconId: "extra"
  }]
};

const tmpUiBusinessEditorMenu: IUiMenuContent = {
  id: "",
  directoryId: "",
  elementType: "",
  hint: undefined,
  options: [{
    id: "Owner",
    directoryId: "",
    elementType: "",
    caption: { en: "Owner" },
    hint: undefined,
    iconId: "businessOwner",
    isDropdown: true
  }, {
    id: "Translator",
    directoryId: "",
    elementType: "",
    caption: { en: "Translator" },
    hint: undefined,
    iconId: "translator"
  }]
};

const tmpUiAdministratorMenu: IUiMenuContent = {
  id: "",
  directoryId: "",
  elementType: "",
  hint: undefined,
  options: [{
    id: "Users",
    directoryId: "",
    elementType: "",
    caption: { en: "Users" },
    hint: undefined,
    iconId: "users"
  }, {
    id: "Dictionaries",
    directoryId: "",
    elementType: "",
    caption: { en: "Dictionaries" },
    hint: undefined,
    iconId: "books"
  }, {
    id: "RegionalHomepages",
    directoryId: "",
    elementType: "",
    caption: { en: "Regional Homepages" },
    hint: undefined,
    iconId: "mapLocation"
  }, {
    id: "BusinessTagRequests",
    directoryId: "",
    elementType: "",
    caption: { en: "Business Tag Requests" },
    hint: undefined,
    iconId: "tagRequests"
  }, {
    id: "ApiKeys",
    directoryId: "",
    elementType: "",
    caption: { en: "API Keys" },
    hint: undefined,
    iconId: "keys"
  }]
}

const tmpUiAdminMenu: IUiMenuContent = {
  id: "",
  directoryId: "",
  elementType: "",
  hint: undefined,
  options: [{
    id: "Administrator",
    directoryId: "",
    elementType: "",
    caption: { en: "Administrator" },
    hint: undefined,
    iconId: "administrator",
    isDropdown: true,
    action: tmpUiAdministratorMenu
  }, {
    id: "Moderator",
    directoryId: "",
    elementType: "",
    caption: { en: "Moderator" },
    hint: undefined,
    iconId: "moderator",
    isDropdown: true
  }, {
    id: "ContentManager",
    directoryId: "",
    elementType: "",
    caption: { en: "Content Manager" },
    hint: undefined,
    iconId: "systemContent",
    isDropdown: true
  }, {
    id: "Translator",
    directoryId: "",
    elementType: "",
    caption: { en: "Translator" },
    hint: undefined,
    iconId: "translator",
    isDropdown: true
  }]
};

export const tmpUiMainMenuContent: IUiMenuContent = {
  id: "",
  directoryId: "",
  elementType: "",
  hint: undefined,
  options: [{
    id: "MainMenuContent.OptionHomepage",
    directoryId: "",
    elementType: "",
    caption: { en: "Homepage" },
    hint: undefined,
    action: tmpUiMainCategories
  }, {
    id: "MainMenuContent.OptionBusiness",
    directoryId: "",
    elementType: "",
    caption: { en: "Business Editor" },
    hint: undefined,
    action: tmpUiBusinessEditorMenu // if user has only one role, this submenu should be replaces with role menu
  }, {
    id: "MainMenuContent.OptionAdmin",
    directoryId: "",
    elementType: "",
    caption: { en: "Admin Console" },
    hint: undefined,
    action: tmpUiAdminMenu // if user has only one role, this submenu should be replaces with role menu
  }, {
    id: "MainMenuContent.OptionPlaceAnAd",
    directoryId: "",
    elementType: "",
    caption: { en: "Place your own Ad!" },
    hint: undefined
  }]
}