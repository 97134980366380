import { IAppUiContext } from "../../../../context/app-ui-context/AppUiContextProvider";
import { ITrackable } from "../../../../utils/tracker";
import { GetTranslationForSearch, GetTranslationLocale } from "../../../common/functions/GetTranslation";
import { IBusinessMarker } from "../../../common/google-maps/business-markers/BusinessMarkers";
import { TImageData } from "../../../common/image-editor/TImageData";
import { IUiListItem } from "../../../common/input/input-dropdown/InputDropdown";
import { ISortStep, TSortType } from "../../../common/lists/sorter-editor/ISorterItem";
import { TGeoPoint } from "../../../common/map/geo-json/GeoJson";
import { ETagSearchResult, TagDesc, UiTagTreeNode } from "../../TagTree";
import { EBusinessSortType, TBusinessSortType } from "../Filters";
//------------------------------------------------------------------------------------
export enum EBusinessSortDirection {
  None = 0,
  Ascend = 1,
  Descend = 2,
}
//------------------------------------------------------------------------------------
export interface ISort {
  propertyName: TBusinessSortType;
  sortType: TSortType;
  order: number;
}
//-----------------------------------------------------------------------------------
export interface ISearchListItemBase {
  businessId: string;
  foundTagCount: number;
  distance?: number;
}
//-----------------------------------------------------------------------------------
export interface IBusinessSearchAd {
  id: string;
  localeId: string;
  adCampaignId: string;
  searchModuleId: string;
}
//-----------------------------------------------------------------------------------
export interface ISearchListItemDetails {
  detailsLoaded: boolean;
  businessId: string;
  ads: IBusinessSearchAd[];
  businessName: any;
  description: any;
  locales: string[];
  foundTagPaths: string[];
  allTagPaths: string[];
  tagDescs: TagDesc[];
  tagRate: string;
  tagRatePercent: number;
  score?: number;
  location?: TGeoPoint;
  logo?: TImageData;
}
export type TBusinessSearchListItemKey = keyof BusinessSearchListItem;
//-----------------------------------------------------------------------------------
export class BusinessSearchListItem implements 
  ISearchListItemBase, ISearchListItemDetails, ITrackable {
  // preliminary-----------------
  businessId!: string;
  ads!: IBusinessSearchAd[];  
  foundTagCount!: number;
  distance?: number;
  // details-----------------
  detailsLoaded: boolean = false;
  businessName: any;
  description: any;
  locales: string[] = [];
  localesFiltered: string[] = [];
  selectedLocale: string = "en";
  foundTagPaths: string[] = [];
  allTagPaths: string[] = [];
  tagDescs: TagDesc[] = [];
  // tag rate-----------------
  static tagsInQuery: number = 0;
  static appUiContext?: IAppUiContext;
  //-----------------------------------------------------------------
  private get tagLeafCount() {
    let result = 0;
    this.foundTagPaths.forEach(tag => {
      if (tag.includes("."))
        result++;
    });
    return result;
  }
  get tagRate(): string  {
    return BusinessSearchListItem.tagsInQuery != 0 
      //? `${this.foundTagCount}/${BusinessSearchListItem.tagsInQuery}`
      ? `${this.tagLeafCount}/${BusinessSearchListItem.tagsInQuery}`
      : "0";
  }
  get tagRatePercent(): number {
    if (BusinessSearchListItem.tagsInQuery == 0)
      return 0;
    return Math.round(this.foundTagCount / BusinessSearchListItem.tagsInQuery * 100);
  }
  score?: number;
  private _location?: TGeoPoint;
  private _logo?: TImageData;
  get location(): TGeoPoint | undefined {
    return this._location;
  }
  set location(value: any | undefined) {
    if (value)
      this._location = new TGeoPoint(value);
    else
      this._location = undefined;
  }
  //------------------------------------------------
  // merged from multiple items if business has multiple locations
  locations?: TGeoPoint[]; 
  //------------------------------------------------
  get logo(): TImageData | undefined {
    return this._logo;
  }
  set logo(value: any) {
    if (value)
      this._logo = new TImageData(value);
    else
      this._logo = undefined;
  }
  //------------------------------------------------------------------------
  get uiId() {
    if (!this.locations) {
      if (this._location)
        return `${this.businessId}_${this.location?.getId()}`;
    }
    return this.businessId;
  }
  //------------------------------------------------------------------------
  get minDistance(): number | undefined {
    if (this.locations && this.locations.length > 0)
      return this.locations[0].distance;
    return this.distance;
  }
  //------------------------------------------------------------------------
  // ITrackable
  get adCampaignId(): string | undefined {
    if (!this.selectedLocale) return undefined;
    return this.ads?.find(ad => ad.localeId == this.selectedLocale)?.adCampaignId;
  }
  get adLanguageSetId(): string | undefined {
    if (!this.selectedLocale) return undefined;
    return this.ads?.find(ad => ad.localeId == this.selectedLocale)?.id;
  }
  get adModuleId(): string | undefined {
    if (!this.selectedLocale) return undefined;
    return this.ads?.find(ad => ad.localeId == this.selectedLocale)?.searchModuleId;
  }
  get localeId(): string {
    return this.selectedLocale;
  }
  get text(): string {
    if (!BusinessSearchListItem.appUiContext || !this.businessName)
      return "";
    return GetTranslationForSearch(
      this.businessName,
      this.selectedLocale,
      BusinessSearchListItem.appUiContext
    );
  }
  //------------------------------------------------------------------------
  constructor(source: any, ui?: IUiListItem[]) {
    Object.assign(this, source);
    this.tagDescs?.forEach((tagDesc, i) => {
      let tagUi = ui?.find(item => `${item.path}.${item.id}` == tagDesc.path);
      this.tagDescs[i] = new TagDesc(tagDesc, tagUi);
    })
    // if (source.tagDescs != undefined) {
    //   for (let i = 0; i < source.tagDescs.length; i++) {
    //     //let ui = uiTagTree?.nodeByPath(source.tagDescs[i].path);
    //     let tagUi = ui?.find(item => item.path == source.tagDescs[i].path);
    //     this.tagDescs[i] = new TagDesc(source.tagDescs[i], tagUi);
    //   }
    // }
    this.locales = this.ads?.map(ad => ad.localeId);
    this.localesFiltered = this.locales;
    // if (source.fullSearchTags != undefined)
    //   for (let i = 0; i < source.fullSearchTags.length; i++) {
    //     let ui = uiTagTree?.nodeByPath(source.fullSearchTags[i]);
    //     this.tagDescs[i] = new TagDesc(source.tagDescs[i], ui);
    //   }
  }
  //------------------------------------------------------------------------
  toJSON() {
    let result = {
      ...this,
      location: this._location,
      logo: this._logo,
      _location: null,
      _logo: null
    };
    delete result["_logo"];
    delete result["_location"];
    return result;
  }
  //------------------------------------------------------------------------
  static fromArray(source: any, ui?: IUiListItem[]): BusinessSearchListItem[] {
    let result: BusinessSearchListItem[] = [];
    if (source)
      source.forEach((item: any, index: number) => {
        //console.log(index);
        result.push(new BusinessSearchListItem(item, ui))
      });
    return result;
  }
  //------------------------------------------------------------------------
  getBusinessMarker(appUiContext: IAppUiContext): IBusinessMarker {
    //console.log("getBusinessMarker.this:", this);
    let name = GetTranslationLocale(appUiContext, this.businessName, "", undefined, this.locales[0]);
    return {
      businessId: this.businessId,
      locationId: this.businessId,
      name: name,
      geolocation: this._location
    }
  }
  //------------------------------------------------------------------------
  pushBusinessMarkers(appUiContext: IAppUiContext, dest: IBusinessMarker[]) {
    //console.log("getBusinessMarker.this:", this);
    let name = GetTranslationLocale(appUiContext, this.businessName, "", undefined, this.locales[0]);
    if (this.locations && this.locations.length > 0) {
      this.locations.forEach(location => dest.push({
        businessId: this.businessId,
        locationId: location?.id ?? this.businessId,
        name: name,
        geolocation: location
      }))
    }
    else if (this._location) {
      dest.push({
        businessId: this.businessId,
        locationId: this._location.id ?? this.businessId,
        name: name,
        geolocation: this._location
      })
    }
  }
  //------------------------------------------------------------------------
  getTags(appUiContext: IAppUiContext, searchResult: ETagSearchResult): string[] {
    let result: string[] = [];
    this.tagDescs.forEach(item => {
      if (searchResult === item.searchResult) {
        result.push(item.getName(appUiContext));
      }
    })
    return result;
  }
  //------------------------------------------------------------------------
  private compareDistance(other: BusinessSearchListItem, direction: EBusinessSortDirection | TSortType): number {
    if (direction == EBusinessSortDirection.Descend || direction == "Desc") {
      return TGeoPoint.compareDistance(other.minDistance, this.minDistance);
    }
    return TGeoPoint.compareDistance(this.minDistance, other.minDistance);
  }
  //------------------------------------------------------------------------
  private compareTags(other: BusinessSearchListItem, direction: EBusinessSortDirection | TSortType): number {
    let b1: BusinessSearchListItem = this;
    let b2: BusinessSearchListItem = other;
    if (direction == EBusinessSortDirection.Ascend || direction == "Asc") {
      b1 = other;
      b2 = this;
    }
    //return b2.foundTagPaths.length - b1.foundTagPaths.length;
    return b2.foundTagCount - b1.foundTagCount;
  }
  //------------------------------------------------------------------------
  compareTo(other: BusinessSearchListItem, sortType: EBusinessSortType | TBusinessSortType, direction: EBusinessSortDirection | TSortType): number {
    switch (sortType) {
      case EBusinessSortType.Distance: 
      case "Distance": 
      {
        let result = this.compareDistance(other, direction);
        if (result == 0)
          return this.compareTags(other, EBusinessSortDirection.Descend);
        return result;
      }
      case EBusinessSortType.Tags: 
      case "Relevance": 
      {
        let result = this.compareTags(other, direction);
        if (result == 0)
          return this.compareDistance(other, EBusinessSortDirection.Ascend);
        return result;
      }
      default:
        return 0;
    }
  }
  //------------------------------------------------------------------------
  compareToV2(other: BusinessSearchListItem, sortStep: ISortStep): number {
    let v: number = this[sortStep.propertyName as TBusinessSearchListItemKey];
    let otherV: number = other[sortStep.propertyName as TBusinessSearchListItemKey];
    let result = Math.sign(sortStep.sortType == 'Asc' ? v - otherV : otherV - v);
    //console.log("compareToV2:", v, otherV, result);
    return result;
  }
  //------------------------------------------------------------------------
  fillDetails(source: ISearchListItemDetails) {
    this.detailsLoaded = true;
    this.businessName = source.businessName;
    //this.ads = source.ads;
    this.description = source.description;
    this.logo = source.logo;
    this.score = source.score;

    // this.locales = source.locales;
    // this.foundTagPaths = source.foundTagPaths;
    // this.allTagPaths = source.allTagPaths;
    // this.tagDescs = source.tagDescs;
    //this.location = source.location;
  }
  //------------------------------------------------------------------------
  filterLocales(filter: string[]) {
    //let intersection = arrA.filter(a => arrB.includes(a));
    this.localesFiltered = this.locales.filter(locale => filter.includes(locale));
  }
}
