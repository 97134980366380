import { EDraftItemState } from "../../../../../context/directory-editor-context/EDraftItemState";
import { IDraftStateResult } from "../../../../../context/directory-editor-context/IDirectoryDraftAbstract";
import { DirectoryDraftItem, IDirectoryDraftItem } from "../../../../../context/directory-editor-context/IDirectoryDraftItem";
import { TImageData } from "../../../../common/image-editor/TImageData";
import { v4 as uuidv4 } from "uuid";
import { IReadableDocumentItem } from "../../../../document-viewer/IReadableDocument";
import { IEditable } from "../../../../common/edit-form/IEditFormStateV2";
import { ClassContentUnitStatus, IContentUnit } from "../../../../common/content/IContentUnit";
import { IContentUnitText } from "../../../../common/content/content-text/IContentUnitText";
import { IContentUnitImage } from "../../../../common/content/content-image/IContentUnitImage";

export type EDocumentItemType = "Title" | "Section" | "Paragraph";

export interface IDocumentDraftItem extends IReadableDocumentItem, IDirectoryDraftItem, IContentUnit {
  id: string;
  type: EDocumentItemType;
  path?: string;
  isNumbered: boolean;
  isMarked: boolean;
  header?: IContentUnitText;
  text?: IContentUnitText;
  image?: IContentUnitImage;
}

export class DocumentDraftItem extends DirectoryDraftItem implements IDocumentDraftItem, IEditable {
  id!: string;
  type!: EDocumentItemType;
  path?: string;
  isNumbered!: boolean;
  isMarked!: boolean;
  header?: IContentUnitText;
  text?: IContentUnitText;
  image?: IContentUnitImage;
  draftState!: EDraftItemState;
  status?: ClassContentUnitStatus;
  get level(): number {
    return this.path ? this.path.split(".").length : 0;
  };
  original?: DocumentDraftItem;
  //---------------------------------------------------------------------------
  constructor(source: IDocumentDraftItem) {
    super(source);
    Object.assign(this, source);
    this.init();
  };
  //---------------------------------------------------------------------------
  private init() {
    this.status = this.status ?
      new ClassContentUnitStatus(this.status.statusId, this.status.rejectionReason) :
      undefined;
    this.original = this.original ? new DocumentDraftItem(this.original) : undefined;
    if (this.header) {
      this.header.status = this.header.status ?
        new ClassContentUnitStatus(this.header.status.statusId, this.header.status.rejectionReason) :
        new ClassContentUnitStatus("Invalid");
    };
    if (this.text) {
      this.text.status = this.text.status ?
        new ClassContentUnitStatus(this.text.status.statusId, this.text.status.rejectionReason) :
        new ClassContentUnitStatus("Invalid");
    };
    if (this.image) {
      this.image.imageData = new TImageData(this.image?.imageData);
      this.image.status = this.image.status ?
        new ClassContentUnitStatus(this.image.status.statusId, this.image.status.rejectionReason) :
        new ClassContentUnitStatus("Invalid");
    };
  };
  //---------------------------------------------------------------------------
  // getUpdated(update: Partial<IDocumentDraftItem>): DocumentDraftItem {
  //   const updatedItem = new DocumentDraftItem(this);
  //   Object.assign(updatedItem, update);
  //   return updatedItem;
  // };
  //---------------------------------------------------------------------------
  initialize(): IEditable {
    const documentItem = new DocumentDraftItem(this);
    documentItem.init();
    return documentItem;
  };
  //---------------------------------------------------------------------------
  isEqual(object: IEditable): boolean {
    let isEqual = true;
    const typedObject = object as DocumentDraftItem;
    if (this.id !== typedObject.id) {
      //console.log("isEqual: id");
      isEqual = false;
    };
    if (this.type !== typedObject.type) {
      //console.log("isEqual: type");
      isEqual = false;
    };
    if (this.path != typedObject.path) {
      //console.log("isEqual: path");
      isEqual = false;
    };
    if (this.isNumbered != typedObject.isNumbered) {
      //console.log("isEqual: isNumbered");
      isEqual = false;
    };
    if (this.isMarked != typedObject.isMarked) {
      //console.log("isEqual: isMarked");
      isEqual = false;
    };
    if (this.header !== typedObject.header) {
      //console.log("isEqual: header", this.header, typedObject.header);
      isEqual = false;
    };
    if (this.text != typedObject.text) {
      //console.log("isEqual: text");
      isEqual = false;
    };
    if (JSON.stringify(this.image) != JSON.stringify(typedObject.image)) {
      //console.log("isEqual: image");
      isEqual = false;
    };
    return isEqual;
  }
  //---------------------------------------------------------------------------
  isValid(): boolean {
    return true;
  };
  //---------------------------------------------------------------------------
  checkChangesAndValidate(initial?: IDocumentDraftItem): IDraftStateResult {
    let isUpdatedInSession = false;
    let isUpdated = false;
    let isValid = true;
    //this.draftState = EDraftItemState.None;
    //-------------------------------------------------------------------------
    if (!initial) {
      // New item as compared to session initial state
      isUpdatedInSession = true;
    } else if (this.id !== initial.id) {
      isUpdatedInSession = true;
    } else if (this.type !== initial.type) {
      isUpdatedInSession = true;
    } else if (this.path != initial.path) {
      isUpdatedInSession = true;
    } else if (this.isNumbered != initial.isNumbered) {
      isUpdatedInSession = true;
    } else if (this.isMarked != initial.isMarked) {
      isUpdatedInSession = true;
    } else if (this.header?.text != initial.header?.text) {
      isUpdatedInSession = true;
    } else if (this.text?.text != initial.text?.text) {
      isUpdatedInSession = true;
    } else if (JSON.stringify(this.image?.imageData) != JSON.stringify(initial.image?.imageData)) {
      isUpdatedInSession = true;
    }
    //-------------------------------------------------------------------------
    if (!this.original) {
      // New item as compared to original
      isUpdated = true;
    } else {
      if (this.id !== this.original.id) {
        // Is it possible at all?
        //console.log(`DocumentDraftItem [${this.fullPath}] id`);
        isUpdated = true;
      };
      if (this.type != this.original.type) {
        //console.log(`DocumentDraftItem [${this.fullPath}] type`);
        isUpdated = true;
      };
      if (this.path != this.original.path) {
        //console.log(`DocumentDraftItem [${this.fullPath}] path`);
        isUpdated = true;
      };
      if (this.isNumbered != this.original.isNumbered) {
        //console.log(`DocumentDraftItem [${this.fullPath}] isNumbered`);
        isUpdated = true;
      };
      if (this.isMarked != this.original.isMarked) {
        //console.log(`DocumentDraftItem [${this.fullPath}] isMarked`);
        isUpdated = true;
      };
      if (this.header?.text !== this.original.header?.text) {
        //console.log(`DocumentDraftItem [${this.fullPath}] header`, this.header?.text, this.original.header?.text);
        isUpdated = true;
      };
      if (this.text?.text !== this.original.text?.text) {
        //console.log(`DocumentDraftItem [${this.fullPath}] text`, this.text?.text, this.original.text?.text);
        isUpdated = true;
      };
      if (JSON.stringify(this.image?.imageData) != JSON.stringify(this.original.image?.imageData)) {
        isUpdated = true;
      };
    };
    //-------------------------------------------------------------------------
    if (this.isNoTranslation || this.hasOnlyChild) {
      isValid = false;
    };
    //-------------------------------------------------------------------------
    this.isNothing = this.getIsNothing();
    //-------------------------------------------------------------------------
    if (this.original && isUpdated) {
      //console.log(`DocumentDraftItem [${this.fullPath}]`, this, this.original, isUpdated);
      //this.status = new ClassContentUnitStatus("Draft");
    };
    //-------------------------------------------------------------------------
    return {
      isUpdatedInSession,
      isUpdated,
      isValid
    };
  };
  //---------------------------------------------------------------------------
  static getNewItem(type: EDocumentItemType): DocumentDraftItem {
    return new DocumentDraftItem({
      id: uuidv4(),
      type: type,
      //header: `New ${type}`,
      isNumbered: false,
      isMarked: false,
      draftState: EDraftItemState.IsNew,
      status: new ClassContentUnitStatus("Draft")
    });
  };
  //---------------------------------------------------------------------------
  keywordFilterConditionIsMet(keyword: string): boolean {
    let result = this.id.toLowerCase().includes(keyword);
    if (this.header?.text) {
      result = result || this.header.text.toLowerCase().includes(keyword);
    };
    if (this.text?.text) {
      result = result || this.text.text.toLowerCase().includes(keyword);
    };
    return result;
  };
  //---------------------------------------------------------------------------
  updateStatus(subItems?: DocumentDraftItem[]): void {
    let isCompleted = this.status?.isApproved || this.status?.isInTrouble;
    let isRejected = this.status?.isInTrouble || false;
    let isApproved = this.status?.isApproved || true;
    //-------------------------------------------------------------------------
    const headerState = new ClassItemPropertyState(this.header);
    const textState = new ClassItemPropertyState(this.text);
    const imageState = new ClassItemPropertyState(this.image);
    isCompleted = headerState.isCompleted && textState.isCompleted && imageState.isCompleted;
    isRejected = headerState.isRejected || textState.isRejected || imageState.isRejected;
    isApproved = headerState.isApproved && textState.isApproved && imageState.isApproved;
    //-------------------------------------------------------------------------
    if (subItems) {
      subItems?.forEach(subItem => {
        //console.log(subItem.fullPath);
        if (this.status) {
          if (subItem.status) {
            isCompleted = isCompleted && subItem.status.isCompleted;
            isRejected = isRejected || subItem.status.isInTrouble;
            isApproved = isApproved && subItem.status.isApproved
          } else console.error(`DocumentDraftItem [${subItem.fullPath}] updateStatus: status is not defined`);
        } else console.error(`DocumentDraftItem [${this.fullPath}] updateStatus: status is not defined`);
      });
    };
    //-------------------------------------------------------------------------
    if (isApproved) {
      this.status = new ClassContentUnitStatus("Approved");
    } else if (isCompleted && isRejected) {
      this.status = new ClassContentUnitStatus("Rejected");
    };
  };
}

export interface IContentImage {
  // NOTE: Make it the same as ad unit images
  imageData: TImageData;
  positionH: "Left" | "Center" | "Right";
  positionV: "Top" | "Center" | "Bottom";
}

class ClassItemPropertyState {
  isRejected: boolean = false;
  isApproved: boolean = true;
  isCompleted: boolean = true;
  //---------------------------------------------------------------------------
  constructor(source?: IContentUnit) {
    if (source) {
      if (source.status) {
        this.isRejected = source.status.isInTrouble;
        this.isApproved = source.status.isApproved;
      } else console.error("ClassItemPropertyState: status is not defined");
      this.isCompleted = this.isRejected || this.isApproved;
    };
  }
}
