import { useState } from 'react';
import { ClassUserMessage } from '../Messages';
import styles from './MessagesListItem.module.css';
import MessageHeader from './message-header/MessageHeader';
import MessageBody from './message-body/MessageBody';
import { IUiMenuContent } from '../../common/options/menus/IUiMenuContent';
import { IUiOption } from '../../common/options/IUiOption';

interface IProps {
  ui?: IUiMenuContent;
  data: ClassUserMessage;
  onUpdate: (messageId: string, isRead?: boolean, isDeleted?: boolean) => void;
}

export default function MessagesListItem(props: IProps) {
  const [expanded, setExpanded] = useState(false);
  //---------------------------------------------------------------------------
  const markRead = (isRead: boolean) => {
    props.onUpdate(props.data.id, isRead, props.data.isDeleted);
  };
  //---------------------------------------------------------------------------
  const markDeleted = (isDeleted: boolean) => {
    props.onUpdate(props.data.id, props.data.isRead, isDeleted);
  };
  //---------------------------------------------------------------------------
  const onToggle = () => {
    if (!expanded && !props.data.isRead)
      markRead(true);
    setExpanded(!expanded);
  };
  //---------------------------------------------------------------------------
  const onMessageOptionSelect = (optionId: string) => {
    switch (optionId) {
      case "OptionMarkUnread":
        markRead(false);
        break;
      case "OptionMarkRead":
        markRead(true);
        break;
      case "OptionDelete":
        markDeleted(true);
        break;
      case "OptionUndelete":
        markDeleted(false);
        break;
    };
  };
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  props.ui?.options.forEach(option => {
    switch (option.id) {
      case "OptionMarkUnread":
        if (props.data.isRead) {
          updatedOptions.push({
            ...option,
            iconId: 'markUnread'
          });
        };
        break;
      case "OptionMarkRead":
        if (!props.data.isRead) {
          updatedOptions.push({
            ...option,
            iconId: 'check'
          });
        };
        break;
      case "OptionDelete":
        if (!props.data.isDeleted) {
          updatedOptions.push({
            ...option,
            iconId: 'delete'
          });
        };
        break;
      case "OptionUndelete":
        if (props.data.isDeleted) {
          updatedOptions.push({
            ...option,
            iconId: 'undelete'
          });
        };
        break;
    };
  });
  //---------------------------------------------------------------------------
  const bodies = props.data.content.map(body => (
    <MessageBody
      key={body.localeId}
      data={body}
    />
  ));
  //---------------------------------------------------------------------------
  return (
    <div className={styles.wrapper}>

      <MessageHeader
        ui={props.ui ? { ...props.ui, options: updatedOptions } : undefined}
        data={props.data}
        onClick={onToggle}
        onOptionSelect={onMessageOptionSelect}
      />

      <div className={styles.content}>
        {expanded && bodies}
      </div>

    </div>
  );
}