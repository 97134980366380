import { IPublicClientApplication } from "@azure/msal-browser";
import { IAppAuthContext } from "../../../../../context/app-auth-context/AppAuthContext";
import { apiBasePath, pathUpdateSystemBusinessRegional } from "../../../../../utils/apiPathConstant";
import { apiPostPrivate, checkResponse } from "../../../../../utils/api";
import { ClassSystemBusinessRegional } from "../ISystemBusinessRegional";
import { ClassImageEditorContext } from "../../../../common/image-editor/image-editor-context/ImageEditorContextProvider";

interface IProps {
  msalInstance: IPublicClientApplication,
  authContext?: IAppAuthContext,
  imageEditorContext?: ClassImageEditorContext,
  business: ClassSystemBusinessRegional,
  abortSignal?: AbortSignal
}

export default async function saveRegionalHomepageBusinessAsync(props: IProps): Promise<ClassSystemBusinessRegional> {
  if (!props.authContext) throw new Error("saveRegionalHomepageBusinessAsync: auth context is not provided");
  if (!props.authContext.config) throw new Error("saveRegionalHomepageBusinessAsync: auth config is not set");
  if (!props.business) throw new Error("saveRegionalHomepageBusinessAsync: business is not provided");
  if (!props.imageEditorContext) throw new Error("saveRegionalHomepageBusinessAsync: Image editor context is not provided");
  //---------------------------------------------------------------------------
  let saveImageResult = await props.imageEditorContext.uploadAllImagesForAsync(
    props.business.id,
    props.authContext,
    props.msalInstance,
    true
  );
  if (!saveImageResult) {
    throw `There was an error uploading images for business. Please try again later.`;
  }
  //-----------------------------------------------------------------------
  const uri = `${apiBasePath}${pathUpdateSystemBusinessRegional}`;
  const response = await apiPostPrivate(
    props.msalInstance,
    props.authContext.config,
    uri,
    props.business
  );
  //---------------------------------------------------------------------------
  checkResponse(response, "saveRegionalHomepageBusinessAsync", props.abortSignal?.aborted);
  const business = new ClassSystemBusinessRegional(response?.content);
  return business;
};