import { useContext, useEffect, useState } from 'react';
import WikiSpinner from '../../../../app-layout/spinner/wikiSpinner';
import { TUiBreadcrumb } from '../../../../common/breadcrumbs-trail/breadcrumb/TUiBreadcrumb';
import { TUiCaption } from '../../../../common/captions/TUiCaption';
import styles from './DirectoryList.module.css';
import { useMsal } from '@azure/msal-react';
import { AppUiContextStore } from '../../../../../context/app-ui-context/AppUiContextProvider';
import { AppAuthContextStore } from '../../../../../context/app-auth-context/AppAuthContext';
import getDirectoriesListAsync from './getDirectoriesListAsync';
import DirectoryListItem from './directory-list-item/DirectoryListItem';
import { EDirectoryId } from '../Widgets';
import { useAbortController } from '../../../../../hooks/useAbortController';
import ConsoleContent from '../../../../common/console-layout/console-content/ConsoleContent';
import GetHint from '../../../../common/functions/GetHint';

export type EDirectoryType = "Widgets" | "Defaults";

export interface IUiDirectoryList {
  breadcrumb: TUiBreadcrumb;
  prompt: TUiCaption;
  //inputLocale: IUiInputDropdown;
}

export interface IDirectoryListItem {
  directoryId: EDirectoryId;
  name: string;
  description: string;
  translations: IDirectoryTranslationsListItem[];
}

export interface IDirectoryTranslationsListItem {
  locale: string;
  translationExists: boolean;
  translationDraftExists: boolean;
}

interface IProps {
  directoryType: EDirectoryType;
  onSelect: (directoryId: EDirectoryId, locale?: string) => void;
}

export default function DirectoryList(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { appUiContext } = useContext(AppUiContextStore);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<IDirectoryListItem[]>();
  const abortController = useAbortController("DirectoryList");
  //--------------------------------------------------------------------------- Data
  useEffect(() => {
    // Get data: i.e. list of existing directory originals
    let controller = abortController.newController("getDirectories");
    getDirectoriesListAsync({
      msalInstanse: instance, 
      authConfig: appAuthContext.config,
      abortSignal: controller.signal,
      directoryType: props.directoryType
    }).then(response => {
      !controller.aborted && setData(response);
    }).catch(error => {
      console.error(error);
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
    return (() => {
      abortController.abortOnUnmount();
    });
  }, []);
  //---------------------------------------------------------------------------
  const listElements = data?.map(item =>
    <DirectoryListItem
      key={item.directoryId}
      data={item}
      onSelect={props.onSelect}
    />
  );
  //---------------------------------------------------------------------------
  return (
    <ConsoleContent
      prompt={GetHint(appUiContext, "Directory", { en: "Translate Directory" })}
      containerWidthPercent={70}
    >
      <WikiSpinner show={isLoading} />
      <div className={styles.container}>
        {listElements}
      </div>
    </ConsoleContent>
  );
}