import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../AppAuthContext";
import { apiGetPrivate, checkResponse, IPrimitiveContent } from "../../../utils/api";
import { apiBasePath, pathGetUserUnreadMessageCount } from "../../../utils/apiPathConstant";

interface IProps {
  msalInstance: IPublicClientApplication;
  authConfig?: IAuthConfig;
  abortSignal?: AbortSignal;
}

export async function getUnreadMessagesCountAsync(props: IProps): Promise<number> {
  if (!props.authConfig) throw new Error("getUnreadMessagesCountAsync: auth config is not provided");
  //---------------------------------------------------------------------------
  const response = await apiGetPrivate(
    props.msalInstance,
    props.authConfig,
    `${apiBasePath}${pathGetUserUnreadMessageCount}`,
    props.abortSignal
  );
  //---------------------------------------------------------------------------
  checkResponse(response, "getUnreadMessagesCountAsync", props.abortSignal?.aborted);
  let count: number = (response?.content as IPrimitiveContent).value as number;
  return count;
}