import { IUiInteractiveForm } from "../../../../common/forms/IUiInteractiveForm";
import { IUiDocumentViewer, tmpUiDocumentViewer } from "../../../../document-viewer/IUiDocumentViewer";
import { IUiDirectoryEditorHeader } from "../../../common/directory-editor/directory-editor-wrapper/directory-editor-header/IUiDirectoryEditorHeader";
import { IUiDocumentItemNode, tmpUiDocumentItemNode } from "./document-item-node/IUiDocumentItemNode";

export interface IUiDocumentEditor extends IUiInteractiveForm {
  directoryEditorHeader: IUiDirectoryEditorHeader;
  node: IUiDocumentItemNode;
  documentViewer: IUiDocumentViewer;
  unsavedChangesDialog: IUiInteractiveForm;
  editConfirmationDialog: IUiInteractiveForm;
}

export const tmpUiDocumentEditor: IUiDocumentEditor = {
  id: "",
  directoryId: "",
  elementType: "",
  caption: undefined,
  hint: undefined,
  menuContent: {
    id: "",
    directoryId: "",
    elementType: "",
    hint: undefined,
    options: [{
      id: "OptionClose",
      directoryId: "",
      elementType: "",
      caption: { en: "Close" },
      hint: undefined
    },{
      id: "OptionDelete",
      directoryId: "",
      elementType: "",
      caption: { en: "Delete" },
      hint: undefined
    },{
      id: "OptionPreview",
      directoryId: "",
      elementType: "",
      caption: { en: "Preview" },
      hint: undefined
    },{
      id: "OptionEditModeEnable",
      directoryId: "",
      elementType: "",
      caption: { en: "Edit Mode" },
      hint: undefined
    },{
      id: "OptionEditModeDisable",
      directoryId: "",
      elementType: "",
      caption: { en: "Exit Edit Mode" },
      hint: undefined
    },{
      id: "OptionAdd",
      directoryId: "",
      elementType: "",
      caption: { en: "Add Root Item" },
      hint: undefined
    },{
      id: "OptionSave",
      directoryId: "",
      elementType: "",
      caption: { en: "Save" },
      hint: undefined
    },{
      id: "OptionSubmit",
      directoryId: "",
      elementType: "",
      caption: { en: "Submit" },
      hint: undefined
    },{
      id: "OptionPublish",
      directoryId: "",
      elementType: "",
      caption: { en: "Publish" },
      hint: undefined
    }]
  },
  directoryEditorHeader: {
    inputDirectoryId: {
      id: "",
      directoryId: "",
      elementType: "",
      caption: { en: "Directory ID" },
      hint: undefined
    }
  },
  node: tmpUiDocumentItemNode,
  documentViewer: tmpUiDocumentViewer,
  unsavedChangesDialog: {
    id: "DirectoryEditorWrapper.UnsavedChangesDialog",
    directoryId: "",
    elementType: "",
    caption: {en: "Unsaved Changes"},
    hint: {en: "The draft has unsaved changes. You are about to discard them"},
    menuContent: {
      id: "",
      directoryId: "",
      elementType: "",
      hint: undefined,
      options: [{
        id: "OptionOk",
        directoryId: "",
        elementType: "",
        caption: { en: "Ok" },
        hint: null
      }, {
        id: "OptionCancel",
        directoryId: "",
        elementType: "",
        caption: { en: "Cancel" },
        hint: null
      }]
    }
  },
  editConfirmationDialog: {
    id: "DirectoryEditorWrapper.EditConfirmationDialog",
    directoryId: "",
    elementType: "",
    caption: {en: "Document Editor"},
    hint: {en: "This document is going through approval process: making changes will cancel it and return document to draft state. Are you sure you want to continue?"},
    menuContent: {
      id: "",
      directoryId: "",
      elementType: "",
      hint: undefined,
      options: [{
        id: "OptionOk",
        directoryId: "",
        elementType: "",
        caption: { en: "Ok" },
        hint: null
      }, {
        id: "OptionCancel",
        directoryId: "",
        elementType: "",
        caption: { en: "Cancel" },
        hint: null
      }]
    }
  },
};