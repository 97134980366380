import React, { ReactNode, useState } from 'react';
import styles from './ChecklistNodeWrapper.module.css';
import ChecklistNodeStatus from '../checklist-node-status/ChecklistNodeStatus';
import { ClassContentUnitStatus } from '../../../../common/content/IContentUnit';
import ChecklistNodeReject from '../checklist-node-reject/ChecklistNodeReject';
import CombineStyles from '../../../../../utils/combineStyles';
import { tmpUiEditformReject } from '../checklist-node-reject/IUiEditformReject';
import { IRequestRejectionReason } from '../checklist-node-reject/IRequestRejectionReason';

interface IProps {
  data: ClassContentUnitStatus;
  isReadonly: boolean;
  children?: ReactNode; // Must contain one or more DirectoryNodeAttribute components
  onHover?: () => void;
  onHoverEnd?: () => void;
  onReview?: (updatedStatus: ClassContentUnitStatus) => void;
}

export default function ChecklistNodeWrapper(props: IProps) {
  const [rejectEditMode, setRejectEditMode] = useState(false);
  const isRejected = props.data.isInTrouble || rejectEditMode;
  //---------------------------------------------------------------------------
  const onApprove = () => {
    setRejectEditMode(false);
    const updatedStatus = new ClassContentUnitStatus("Approved");
    props.onReview && props.onReview(updatedStatus);
  };
  //---------------------------------------------------------------------------
  const onReject = () => {
    setRejectEditMode(true);
  };
  //---------------------------------------------------------------------------
  const onRejectionReasonUpdate = (reason: IRequestRejectionReason) => {
    setRejectEditMode(false);
    const updatedStatus = new ClassContentUnitStatus("Rejected", reason);
    props.onReview && props.onReview(updatedStatus);
  };
  //---------------------------------------------------------------------------
  const onCancelRejection = () => {
    console.log('onCancelRejection', rejectEditMode);
    rejectEditMode && setRejectEditMode(false);
  };
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>

      <div
        className={CombineStyles([
          styles.row,
          isRejected && props.data.rejectionReason ? styles.rejected : '',
        ])}
        onMouseOver={props.onHover}
        onMouseLeave={props.onHoverEnd}
      >
        {props.children}

        <ChecklistNodeStatus
          data={props.data}
          isReadonly={props.isReadonly}
          onApprove={onApprove}
          onReject={onReject}
        />

      </div>

      {isRejected &&
        <ChecklistNodeReject
          ui={tmpUiEditformReject}
          data={props.data.rejectionReason}
          isReadonly={props.isReadonly}
          editMode={rejectEditMode}
          onUpdate={onRejectionReasonUpdate}
          onCancel={onCancelRejection}
        />}

    </React.Fragment>
  );
}