import { BusinessStatNumbers } from '../BusinessStatNumbers';
import styles from './BusinessName.module.css';
//-----------------------------------------------------------------------------
interface IProps {
  businessName: string;
  businessId?: string;
  views?: number;
  points?: number;
  //userRoles?: string[];
}
//-----------------------------------------------------------------------------
export function BusinessName(props: IProps) {
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>
      <div className={styles.value}>
        <div className={styles.name}>
          {props.businessName}
        </div>
        {props.views != undefined && props.points != undefined &&
          <BusinessStatNumbers
            views={props.views}
            points={props.points}
          />
        }
      </div>
      {/*props.userRoles && <div className={styles.system}>
        {props.userRoles}
      </div>*/}
      {props.businessId && <div className={styles.system}>
        {props.businessId}
      </div>}
    </div>
  );
}