import { ReactNode } from 'react';
import { AdLanguageSet } from '../../ad-content-editor/IAdLanguageSet';
import { EImageSize } from '../image-editor/TImageData';
import { IAdModule } from './IAdModule';
import AdModuleContainer from './ad-module-container/AdModuleContainer';
import AdModuleHeader from './ad-module-header/AdModuleHeader';
import { IUiAdModule } from './IUiAdModule';
import { IUiOption } from '../options/IUiOption';
import AddressAndContacts from './address-n-contacts/AddressAndContacts';
import AdModuleSearch from './ad-module-search/AdModuleSearch';
import { IBusinessLocationData } from '../../business-space/business-editor/section-location/business-location-editor/IBusinessLocationData';
import { Contact } from '../../business-space/business-editor/section-contacts/contact/Contact';
import { EOptionsAdModule, EOptionsContact, EOptionsLocation, EOptionsParagraph } from '../../ad-content-editor/ad-modules-editor/AdModuleOptions';
import { ClassContentUnitStatus } from '../content/IContentUnit';
import { IDesignTemplateAdModule } from '../design-templates/IDesignTemplateAdModule';
import ContentParagraph from '../content/content-paragraph/ContentParagraph';

const optionGroupText = "text";
const optionGroupImage = "image";
const optionGroupModule = "module";

export const getUnitColor = (status?: ClassContentUnitStatus) => {
  if (status?.isApproved)
    return 'var(--clr_accent_main)';
  else if (status?.isInTrouble)
    return 'var(--clr_accent_wrong)';
  else if (status?.statusId === 'PendingApproval')
    return 'var(--clr_accent_attention)';
  else return undefined;
};

interface IProps {
  ui?: IUiAdModule;
  externalDesignTemplate?: IDesignTemplateAdModule;
  adLanguageSet?: AdLanguageSet;
  adModule: IAdModule;
  isReadonly: boolean;
  isClickable: boolean;
  useExternalLayout: boolean;
  maxImageSize?: EImageSize;
  minHeightEm?: number;
  highlightedPath?: string[];
  onClick?: (businessId: string, localeId: string) => void;
  onModuleOptionSelect?: (optionId: EOptionsAdModule, adModuleId: string) => void;
  onLocationOptionSelect?: (optionId: EOptionsLocation, adModuleId: string, locationId?: string) => void;
  onLocationDataUpdate?: (adModuleId: string, updatedLocationData: IBusinessLocationData<string>) => void;
  onContactOptionSelect?: (optionId: EOptionsContact, adModuleId: string, contactId?: string) => void;
  onParagrapOptionSelect?: (optionId: EOptionsParagraph, adModuleId: string, paragraphId: string) => void;
  //---------------------------------------------------------------------------
  onContactUpdate?: (adModuleId: string, updatedContact: Contact<string>) => void;
  onUpdate?: (updatedAdModule: IAdModule) => void;
}

export default function AdModule(props: IProps) {
  //---------------------------------------------------------------------------
  const canBeDeleted = (): boolean => {
    let result = true;
    if (props.adModule.status?.isReadonly)
      result = false;
    else if (props.adLanguageSet) {
      // Means we are in editor
      if (props.adLanguageSet.status.isReadonly)
        result = false;
      else {
        // Look at design template and actual modules in the ad
        // if module is required and it's the only one, it can't be deleted
        result = false; // For now
      };
    } else {
      result = props.adModule.isOrphan == true;
    };
    return result;
  };
  //---------------------------------------------------------------------------
  const canBeExcluded = (): boolean => {
    let result = true;
    if (!props.adModule.status?.isReadonly) // We can exclude only read-only modules
      result = false;
    else if (props.adLanguageSet) {
      if (props.adLanguageSet.status.isReadonly)
        result = false;


    } else {
      result = false;
    };
    return result;
  };
  //--------------------------------------------------------------------------- Ad Module
  const onModuleSelect = () => {
    if (!props.isClickable) return;
    if (!props.onClick) return;
    if (!props.adModule.businessId) return;
    props.onClick(props.adModule.businessId, props.adModule.localeId);
  };
  //---------------------------------------------------------------------------
  const onLocationOptionSelect = (optionId: EOptionsLocation, locationId?: string) => {
    if (!props.onLocationOptionSelect) return;
    props.onLocationOptionSelect(optionId, props.adModule.id, locationId);
  };
  //---------------------------------------------------------------------------
  const onLocationDataUpdate = (updatedLocationData: IBusinessLocationData<string>) => {
    if (!props.onLocationDataUpdate) return;
    props.onLocationDataUpdate(props.adModule.id, updatedLocationData);
  };
  //---------------------------------------------------------------------------
  const onContactOptionSelect = (optionId: EOptionsContact, contactId?: string) => {
    if (!props.onContactOptionSelect) return;
    props.onContactOptionSelect(optionId, props.adModule.id, contactId);
  };
  //---------------------------------------------------------------------------
  const onContactUpdate = (updatedContact: Contact<string>) => {
    if (!props.onContactUpdate) return;
    props.onContactUpdate(props.adModule.id, updatedContact);
  };
  //---------------------------------------------------------------------------
  const onParagrapOptionSelect = (optionId: EOptionsParagraph, paragraphId: string) => {
    if (!props.onParagrapOptionSelect) return;
    props.onParagrapOptionSelect(optionId, props.adModule.id, paragraphId);
  };
  //--------------------------------------------------------------------------- Render module options
  // NOTE: actual available options are determined by caller component
  const updatedOptions: IUiOption[] = [];
  props.ui?.menuContent.options.forEach(option => {
    switch (option.id) {
      case "AdModule.MenuContent.OptionEditHeader":
        if (props.adLanguageSet?.status.isReadonly) break;
        if (props.adModule.adModuleType === "Addressblock" || props.adModule.adModuleType === "Contentblock") {
          updatedOptions.push({
            ...option,
            iconId: "pencil",
            isDisabled: false,
            group: optionGroupText
          });
        };
        break;
      case "AdModule.MenuContent.OptionEditText":
        if (props.adLanguageSet?.status.isReadonly) break;
        if ((props.adModule.adModuleType === "Photoblock" && props.adModule.usage.isHomepage) || props.adModule.adModuleType === "SearchResultsModule") {
          updatedOptions.push({
            ...option,
            iconId: "textEdit",
            isDisabled: false,
            group: optionGroupText
          });
        };
        break;
      case "AdModule.MenuContent.OptionImageEdit":
        if (props.adLanguageSet?.status.isReadonly) break;
        if (props.adModule.adModuleType === "Photoblock") {
          updatedOptions.push({
            ...option,
            iconId: "imageEdit",
            isDisabled: !props.adModule.image?.imageData,
            group: optionGroupImage
          });
        };
        break;
      case "AdModule.MenuContent.OptionImageSelect":
        if (props.adLanguageSet?.status.isReadonly) break;
        if (props.adModule.adModuleType === "Photoblock") {
          updatedOptions.push({
            ...option,
            iconId: "imageSelect", // "imageAdd" / "images",
            group: optionGroupImage
          });
        };
        break;
      case "AdModule.MenuContent.OptionSelectTemplate":
        if (props.adLanguageSet?.status.isReadonly) break;
        //updatedOptions.push({ ...option, iconId: "template", isDisabled: true });
        break;
      case "AdModule.MenuContent.OptionAddParagraph":
        if (props.adLanguageSet?.status.isReadonly) break;
        if (props.adModule.adModuleType === "Contentblock") {
          updatedOptions.push({
            ...option,
            iconId: "plus",
            isDisabled: props.isReadonly,
            group: optionGroupText
          });
        };
        break;
      case "AdModule.MenuContent.OptionRefreshLocations":
        /* if (props.adModule.adModuleType === "Addressblock") {
          updatedOptions.push({ ...option, iconId: "location" });
        }; */
        break;
      case "AdModule.MenuContent.OptionRefreshContacts":
        /* if (!props.isReadonly && props.adModule.adModuleType === "Addressblock") {
          updatedOptions.push({ ...option, iconId: "phone", isDisabled: props.isReadonly });
        }; */
        break;
      case "AdModule.MenuContent.OptionDelete":
        //if (props.adModule.status?.isReadonly) break;
        // NOTE: Parent component can decide based on design template and actual number of modules if module can be deleted
        //const canBeDeleted = props.adModule.isOrphan && !props.adModule.status?.isReadonly;
        updatedOptions.push({
          ...option,
          iconId: "delete",
          isDisabled: !canBeDeleted(),
          group: optionGroupModule
        });
        break;
      case "AdModule.MenuContent.OptionExclude":
        break;
    };
  });
  //--------------------------------------------------------------------------- Ad Module content
  let content: ReactNode = null;
  switch (props.adModule.adModuleType) {
    case "Contentblock":
      content = props.adModule.paragraphs?.map(paragraph =>
        <ContentParagraph
          key={paragraph.id}
          data={paragraph}
          isReadonly={props.isReadonly}
          highlightedPath={props.highlightedPath}
          onOptionSelect={onParagrapOptionSelect}
        />
      );
      break;
    case "Addressblock":
      content =
        <AddressAndContacts
          ui={props.ui}
          businessId={props.adModule.businessId}
          locationData={props.adModule.businessLocationData}
          contacts={props.adModule.contacts}
          isReadonly={props.isReadonly}
          highlightedPath={props.highlightedPath} //{['722ba730-8564-4bf1-8d30-601fafae01e7']} //
          onLocationOptionSelect={onLocationOptionSelect}
          onLocationDataUpdate={onLocationDataUpdate}
          onContactOptionSelect={onContactOptionSelect}
          onContactUpdate={onContactUpdate}
        />;
      break;
  };
  //---------------------------------------------------------------------------
  // Meaning business owners would edit it via context menu option (if it's not readonly of course)
  const headerIsReadonly = props.isReadonly || (props.adModule.adModuleType === "Photoblock" && props.adModule.usage.isHomepage);
  //---------------------------------------------------------------------------
  if (props.adModule.adModuleType === "SearchResultsModule") return (
    <AdModuleContainer
      adModule={props.adModule}
      useExternalLayout={props.useExternalLayout}
      isReadonly={props.isReadonly}
      highlightedPath={props.highlightedPath}
      minHeightEm={props.minHeightEm}
      onClick={props.isClickable ? onModuleSelect : undefined}
    >
      <AdModuleSearch
        ui={props.ui ? {
          ...props.ui?.menuContent,
          options: updatedOptions
        } : undefined}
        adModule={props.adModule}
        isReadonly={true}
        highlightedPath={props.highlightedPath}
        onModuleOptionSelect={props.onModuleOptionSelect}
      />
    </AdModuleContainer>
  ); else return (
    <AdModuleContainer
      adModule={props.adModule}
      externalDesignTemplate={props.externalDesignTemplate}
      useExternalLayout={props.useExternalLayout}
      maxImageSize={props.maxImageSize}
      minHeightEm={props.minHeightEm}
      isReadonly={props.isReadonly}
      highlightedPath={props.highlightedPath}
      onClick={props.isClickable ? onModuleSelect : undefined}
    >
      <AdModuleHeader
        ui={props.ui ? {
          ...props.ui?.menuContent,
          options: updatedOptions
        } : undefined}
        adModule={props.adModule}
        externalDesignTemplate={props.externalDesignTemplate}
        isReadonly={headerIsReadonly}
        isClickable={props.isClickable}
        highlightedPath={props.highlightedPath}
        onModuleOptionSelect={props.onModuleOptionSelect}
      />

      {content}

    </AdModuleContainer>
  );
}