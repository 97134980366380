import { useContext, useRef, useState } from 'react';
import useRefDimensions from '../../../../../hooks/useRefDimensions';
import { IUiDictionaryItem } from '../../../dictionaries/IUiDictionaryItem';
import GetName from '../../../functions/GetName';
import { IUiInputDropdown } from '../InputDropdown';
import InputDropdownItem from '../input-dropdown-item/InputDropdownItem';
import styles from './InputDropdownPopup.module.css';
import InputText from '../../input-text/InputText';
import GetPlaceholder from '../../../functions/GetPlaceholder';
import { ScreenType, useAppScreenContext } from '../../../../../context/app-screen-context/AppScreenProvider';
import PopupHeader from '../../../popup-v2/popup-header/PopupHeader';
import GetHint from '../../../functions/GetHint';
import { AppUiContextStore } from '../../../../../context/app-ui-context/AppUiContextProvider';
import PopupTest from '../../../popup-v2/popup/PopupTest';

interface IProps {
  ui: IUiInputDropdown;
  options: IUiDictionaryItem[];
  selectedOptionId?: string;
  sortOptions: boolean;
  hideDescription: boolean;
  anchorId?: string;
  isAutoComplete?: boolean;
  isMultiselect?: boolean;
  selectedOptions?: string[]; // for multiselect
  locale?: string; // to display options in certain locale, e.g. 'native'
  onClose: () => void;
  onBack?: () => void;
  onSelect?: (selectedOption?: IUiDictionaryItem) => void;
}

export default function InputDropdownPopup(props: IProps) {
  const screenType = useAppScreenContext();
  const { appUiContext } = useContext(AppUiContextStore);
  const [filterValue, setFilterValue] = useState("");
  const availableOptions = useRef<IUiDictionaryItem[]>(props.options);
  const anchorRef = useRef(props.anchorId ? document.getElementById(props.anchorId) as HTMLDivElement : document.getElementById(props.ui.id) as HTMLDivElement);
  const anchorDimensions = useRefDimensions(anchorRef);
  const isAutoComplete = props.isAutoComplete == undefined ? false : props.isAutoComplete;
  const valueIsRequired = props.ui.validation?.required == undefined ? false : props.ui.validation.required;
  const selectedOptions = props.selectedOptions ? props.selectedOptions : props.selectedOptionId ? [props.selectedOptionId] : [];
  //---------------------------------------------------------------------------
  const onInputSearchUpdate = (updatedString: string) => {
    const filteredOptions = getFilteredOptions(updatedString);
    if (filteredOptions.length > 0) {
      setFilterValue(updatedString.toLocaleLowerCase());
      availableOptions.current = filteredOptions;
    };
  };
  //---------------------------------------------------------------------------
  const getFilteredOptions = (filterString: string): IUiDictionaryItem[] => {
    const filteredOptions = props.options.filter(item => {
      if (filterString == "")
        return true;
      else {
        return item.searchString && item.searchString.findIndex(x => x.includes(filterString)) > -1;
      }
    });
    return filteredOptions;
  };
  //---------------------------------------------------------------------------
  const onSelect = (selectedItem?: IUiDictionaryItem) => {
    if (selectedItem?.isDisabled) return;
    props.onSelect && props.onSelect(selectedItem);
  };
  //---------------------------------------------------------------------------
  // we sort here in render logic because of using GetName (it cannot be used in useffect)
  const options = availableOptions.current?.sort((a, b) =>
    props.sortOptions ? GetName(appUiContext, a.id, a.name).localeCompare(GetName(appUiContext, b.id, b.name)) : 0
  ).map((item: IUiDictionaryItem) => (
    <InputDropdownItem
      key={item.id}
      ui={item}
      isMultiselect={props.isMultiselect}
      isSelected={selectedOptions.includes(item.id)}
      isDisabled={item.isDisabled}
      hideDescription={props.hideDescription}
      locale={props.locale}
      onSelect={() => onSelect(item)}
    />
  ));
  //---------------------------------------------------------------------------
  return (
    <PopupTest
      id={props.ui.id}
      anchorId={props.ui.id}
      closeOnClickOutside={true}
      fixHeight={props.isAutoComplete} // Set fixed height, so window would not shrink while user types search string
      onClose={props.onClose}>

      {screenType == ScreenType.Mobile &&
        <PopupHeader
          header={GetHint(appUiContext, props.ui.id, props.ui.hint)}
          onClose={props.onClose}
          onBack={props.onBack ? props.onBack : props.onClose}
        />}

      <div
        className={styles.container}
        style={{
          minWidth: screenType == ScreenType.Mobile ?
            undefined : `${anchorDimensions?.width}px`
        }} // To make popup of the same width as input
      >

        {isAutoComplete && props.ui.inputSearch &&
          <InputText
            ui={{ ...props.ui.inputSearch, hideCaption: true, hideHint: true }}
            data={filterValue}
            onUpdate={onInputSearchUpdate}
          />}

        {!valueIsRequired &&
          <div
            className={styles.emptyValueOption}
            onClick={() => onSelect()}
          >
            {GetPlaceholder(appUiContext, props.ui.id, props.ui.placeholder)}
          </div>}

        <div className={styles.list}>
          {options}
        </div>

      </div>
    </PopupTest>
  );
}