import React, { useEffect, useState } from "react";
import AppUiContextProvider from "./context/app-ui-context/AppUiContextProvider";
import { useMsal } from "@azure/msal-react";
import { IAuthConfig } from "./context/app-auth-context/AppAuthContext";
import AppScreenContextProvider from "./context/app-screen-context/AppScreenProvider";
import AppAuthContextProvider from "./context/app-auth-context/AppAuthContextProvider";
import { AuthenticationResult, EventError, EventMessage, EventType, InteractionStatus, InteractionType } from "@azure/msal-browser";
import { setUserLastSignIn } from "./context/app-auth-context/user";
import App from "./App";
import { ELogLevel, LogInformation } from "./utils/logging";
import { useNavigate } from "react-router";
import WikiSpinner from "./components/app-layout/spinner/wikiSpinner";
import ListManagerContextProvider from "./context/list-manager-context/ListManagerContextProvider";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorBoundaryDialog, LogError } from "./components/common/errors/ErrorBoundaryDialog";
import NotificationContextProvider from "./context/notification-context/NotificationContextProvider";
//----------------------------------------------------------------------------
const logLevel: ELogLevel = ELogLevel.None;
//----------------------------------------------------------------------------

interface IProps {
  authConfig?: IAuthConfig;
}

export default function AppWrapper(props: IProps) {
  const { instance, inProgress } = useMsal();
  const [inProcess, setInProcess] = useState(true); // This is because we don't want app to render while MSAL does not sorted auth status out
  const navigate = useNavigate();
  //---------------------------------------------------------------------------
  // Subscribe to MSAL events
  // (this is necessary in case of redirect sign in type and in order to set active account after successful sign in)
  // This will be run on component mount
  // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/events.md
  useEffect(() => {
    //LogInformation(logLevel, "Registering MSAL event callback...");
    const callbackId = instance.addEventCallback(
      (eventMessage: EventMessage) => {
        // This will be run every time an event is emitted after registering this callback
        //LogInformation(logLevel, "msalEvent:", eventMessage);
        //console.log("msalEvent:", eventMessage);
        switch (eventMessage.eventType) {
          //-------------------------------------------------------------------
          case EventType.LOGIN_START:
            console.warn("msalEvent: LoginStart");
            break;
          //-------------------------------------------------------------------
          case EventType.LOGIN_SUCCESS:
            console.warn("msalEvent: LoginSuccess");
            console.log(eventMessage.payload);
            if (eventMessage.payload) {
              const eventPayload = eventMessage.payload as AuthenticationResult;
              //---------------------------------------------------------------
              // Handle Reset Password flow
              // We need to reject ID tokens that were not issued with the default sign-in policy.
              // After the user resets her password and signs in again, we will force a logout and prompt for login again (with the default sign-in policy).
              if (props.authConfig?.authorities.forgotPassword) {
                // before it was eventMessage.payload.idTokenClaims["acr"]
                const resetPasswordAuthority = props.authConfig.authorities.forgotPassword.toLowerCase() + "/";
                const eventPayloadAuthority = eventPayload.authority.toLowerCase();
                if (eventPayloadAuthority === resetPasswordAuthority) {
                  console.log("User has their password reset");
                  window.alert(
                    "Password has been reset successfully. \nPlease sign-in with your new password"
                  );
                  return instance.logout();
                };
              };
              //---------------------------------------------------------------
              // Handle usual login flow
              //eventPayload.idTokenClaims
              LogInformation(logLevel, "AppWrapper.User is logged in");
              const userAccount = eventPayload.account;
              if (userAccount) {
                instance.setActiveAccount(userAccount);
                let newUser = userAccount.idTokenClaims?.["newUser"];
                console.log("LoginSuccess.idTokenClaims.newUser:", newUser);
                LogInformation(logLevel,
                  "User with Id=" +
                  userAccount.localAccountId +
                  " just authenticated"
                );
                //---------------------------------------------------------------
                // Store last signed in datetime (temporary code to control how user session is handled)
                setUserLastSignIn(userAccount.localAccountId);
              };
            } else {
              console.warn(`Login is success but event payload is not provided`);
            };
            break;
          //-------------------------------------------------------------------
          case EventType.LOGIN_FAILURE:
            // When a user clicks on the 'Forgot your password?' link during sign-in, Azure AD B2C will throw an error.
            // To initiate the password reset user flow, we need to catch this error and handle it by sending another login request
            // with the corresponding password reset authority string.
            // Source: https://github.com/Azure-Samples/ms-identity-javascript-react-tutorial/tree/main/1-Authentication/2-sign-in-b2c#integrating-user-flows
            console.warn("msalEvent: LoginFail");
            console.error(eventMessage.error); // added this in attempt to catch intermittent bug #3901
            if (
              eventMessage.error &&
              eventMessage.error.message.indexOf("AADB2C90118") > -1
            ) {
              LogInformation(logLevel, "Forgot password!");
              if (props.authConfig?.authorities.forgotPassword) {
                if (eventMessage.interactionType === InteractionType.Redirect) {
                  instance.loginRedirect({
                    scopes: props.authConfig.loginScopes,
                    authority: props.authConfig?.authorities.forgotPassword,
                  });
                } else if (eventMessage.interactionType === InteractionType.Popup) {
                  instance
                    .loginPopup({
                      scopes: props.authConfig.loginScopes,
                      authority: props.authConfig?.authorities.forgotPassword,
                    })
                    .catch((error: EventError) => {
                      console.error(error);
                      return;
                    });
                };
              };
            }
            else {
              //TUserType.toLS("/", EUserType.Anonymous);
              console.warn("Login failed. Navigating to home page...");
              navigate("/");
            }
            break;
          //-------------------------------------------------------------------
          case EventType.INITIALIZE_START:
            //console.log("INITIALIZE_START")
            break;
          //-------------------------------------------------------------------
          case EventType.INITIALIZE_END:
            //console.log("INITIALIZE_END")
            //setInProcess(false);
            break;
        }
      }
    );
    return () => {
      // This will be run on component unmount
      //LogInformation(logLevel, "Unregistering MSAL event callback...");
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, []);
  //---------------------------------------------------------------------------
  useEffect(() => {
    //console.log("MSAL.inProgress:", inProgress);
    if (inProgress !== InteractionStatus.Startup) {
      setInProcess(false);
    } else {
      setInProcess(true);
    };
  }, [inProgress]);
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>
      <AppAuthContextProvider
        initialState={{
          user: undefined,
          config: props.authConfig,
          isHealthy: true,
          isInProcess: true
        }}
      >
        <AppUiContextProvider msalInProgress={inProgress != InteractionStatus.None}>
          <ErrorBoundary FallbackComponent={ErrorBoundaryDialog} onError={LogError}>
            <WikiSpinner show={inProcess} />
            <AppScreenContextProvider>
              <NotificationContextProvider>
                <ListManagerContextProvider>
                  {inProcess ? undefined :
                    <App />}
                </ListManagerContextProvider>
              </NotificationContextProvider>
            </AppScreenContextProvider>
          </ErrorBoundary>
        </AppUiContextProvider>
      </AppAuthContextProvider>
    </React.Fragment>
  );
}
