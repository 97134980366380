import { EContentUnitImageType } from "../content/content-image/IContentUnitImage";
import { TImageData } from "./TImageData";

//-------------------------------------------------------------------------
export interface IImageUsage {
  usageType: EContentUnitImageType;
  businessId: string;
  adModuleId?: string;
  paragraphId?: string;
  isSystem?: boolean;
}
//-------------------------------------------------------------------------
export class TImageUsage implements IImageUsage {
  usageType!: EContentUnitImageType;
  businessId!: string;
  adModuleId?: string;
  paragraphId?: string;
  isSystem?: boolean;
  //-----------------------------------------------------------------------
  constructor(source: IImageUsage) {
    Object.assign(this, source);
  }
  //-----------------------------------------------------------------------
  get displayType(): string {
    switch (this.usageType) {
      case "Background":
        return "Background";
      case "Image":
        return "Embedded image";
      case "Logo":
        return "Logo";
    }
  }
  //-----------------------------------------------------------------------
  get displayPreview(): string {
    switch (this.usageType) {
      case "Logo":
        return "Preview Business Logo";
      case "Background":
        return "Preview Ad Module";
      case "Image":
        return "Preview Paragpaph";
    }
  }
  //-----------------------------------------------------------------------
  get displayEdit(): string {
    switch (this.usageType) {
      case "Logo":
        return "Open Business Editor";
      case "Background":
      case "Image":
        return "Open Ad Module Editor";
    }
  }
  //-----------------------------------------------------------------------
  get displayName(): string {
    switch (this.usageType) {
      case "Logo":
        return this.adModuleId ? `Ad Module [${this.adModuleId}] (Logo)` : "Logo";
      case "Background":
        return `Ad Module [${this.adModuleId}] (Background)`;
      case "Image":
        return `Ad Module/Paragraph [${this.adModuleId}/${this.paragraphId}] (Embedded Image)`;
    }
  }
  //-----------------------------------------------------------------------
  get displayTarget(): string {
    switch (this.usageType) {
      case "Logo":
        return `Business [${this.businessId}]`;
      case "Background":
        return `Ad module [${this.adModuleId}]`;;
      case "Image":
        return `Ad module/paragraph [${this.adModuleId}/${this.paragraphId}]`;
    }
  }
  //-----------------------------------------------------------------------
  get displayFull(): string {
    switch (this.usageType) {
      case "Logo":
        return `Business [${this.businessId}] (Logo)`;
      case "Background":
        return `Ad module [${this.adModuleId}] (Background)`;
      case "Image":
        return `Ad module/paragraph [${this.adModuleId}/${this.paragraphId}] (Embedded image)`;
    }
  }
  //-----------------------------------------------------------------------
  get divId() {
    let result = this.usageType + this.businessId;
    if (this.adModuleId)
      result += this.adModuleId;
    if (this.paragraphId)
      result += this.paragraphId;
    return result;
  }
  //-----------------------------------------------------------------------
  static newLogo(businessId: string, isSystem?: boolean) {
    return new TImageUsage({
      usageType: "Logo",
      businessId: businessId,
      isSystem: isSystem,
    });
  }
  //-----------------------------------------------------------------------
  static newBackground(businessId: string, adModuleId: string, isSystem?: boolean) {
    return new TImageUsage({
      usageType: "Background",
      businessId: businessId,
      adModuleId: adModuleId,
      isSystem: isSystem,
    });
  }
  //-----------------------------------------------------------------------
  static newEmbedded(businessId: string, adModuleId: string, paragraphId: string, isSystem?: boolean) {
    return new TImageUsage({
      usageType: "Image",
      businessId: businessId,
      adModuleId: adModuleId,
      paragraphId: paragraphId,
      isSystem: isSystem,
    });
  }
  //-----------------------------------------------------------------------
  equals(other?: IImageUsage) {
    return (
      other != undefined &&
      this.usageType == other.usageType &&
      this.businessId == other.businessId &&
      this.adModuleId == other.adModuleId &&
      this.paragraphId == other.paragraphId
    );
  }
  //-----------------------------------------------------------------------
  static fromArray(source: IImageUsage[]) {
    return source.map(item => new TImageUsage(item));
  }
}
//--------------------------------------------------------------------------
export class UsageBusiness {
  businessId!: string;
  usages: TImageUsage[] = [];
  //------------------------------------------------------------------------
  constructor(source: TImageData, businessId: string) {
    this.businessId = businessId;
    source.imageUsage?.forEach(usage => {
      if (usage.businessId == businessId)
        this.usages.push(usage);
    });
  }
}

