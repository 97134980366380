import React, { useContext, useEffect, useRef, useState } from "react";
import { Routes, Route } from "react-router";
import "./assets/css/themes.css";
import "./assets/css/common.css";
import "./assets/css/font.css";
import styles from "./App.module.css";
import { AppUiContextStore } from "./context/app-ui-context/AppUiContextProvider";
import ApplicationHeader from "./components/app-layout/app-header/ApplicationHeader";
import ApplicationFooter from "./components/app-layout/app-footer/ApplicationFooter";
import WikiSpinner from "./components/app-layout/spinner/wikiSpinner";
import AdminConsole, { pathAdminConsole } from "./components/admin-console/AdminConsole";
import UserConsole, { pathUserConsole } from "./components/user-console/UserConsole";
import TestUtils from "./components/test-utils/TestUtils";
import SearchConsole from "./components/search-console/SearchConsole";
import HomepageWrapper from "./components/homepage/HomepageWrapper";
import NotFoundPage from "./components/not-found-page/NotFoundPage";
import { StatusPage } from "./components/status-page/StatusPage";
import { RemoveLocaleContextFromQueryString } from "./context/app-ui-context/query-string-functions";
import ServWorkerTest from "./components/service-worker-test/ServiceWorker";
import { TUiNames } from "./context/app-ui-context/AppUiCache";
import { IUiPublic } from "./components/app-layout/IUiPublic";
import BusinessConsole, { pathBusinessConsole } from "./components/business-space/business-console/BusinessConsole";
import { PageSignInOut } from "./components/page-signin-signout/PageSignInOut";
import { PageRedirect } from "./components/page-redirect/PageRedirect";
import TestComponent from "./components/test-component/TestComponent";
import TestList from "./components/test-component/test-list/TestList";
import CombineStyles from "./utils/combineStyles";
import DocumentViewer from "./components/document-viewer/DocumentViewer";
import { tmpUiDocumentViewer } from "./components/document-viewer/IUiDocumentViewer";

export const pathNotFound = "notfound";
export const pathSearchConsole = "search";
export const pathSignInOut = "signinout";
export const pathRedirect = "redirect";
export const pathLegalDocuments = "legal";

const uiName: TUiNames = "appLayout";

export default function App() {
  const { appUiContext, appUiContextDispatch } = useContext(AppUiContextStore);
  const [publicUi, setPublicUi] = useState<IUiPublic>();
  const [isLoading, setIsLoading] = useState(false);
  const initStarted = useRef(false);
  //--------------------------------------------------------------------------- Get UI
  useEffect(() => {
    if (initStarted.current) return;
    initStarted.current = true;
    if (!publicUi && appUiContext.uiCache?.checkAuthorization("public")) {
      setIsLoading(true);
      const setUI = (ui?: IUiPublic) => {
        if (ui && ui.searchConsole) {
          ui.searchConsole.businessTags = ui.dictionaries.businessTags
        };
        setPublicUi(ui);
      };
      appUiContext.uiCache.getUiCallback<IUiPublic>("public", setUI, () => setIsLoading(false));
    };
  }, []);
  //--------------------------------------------------------------------------- UI Dictionaries
  useEffect(() => {
    if (publicUi?.dictionaries) {
      appUiContextDispatch({ type: "UpdateDictionaries", value: publicUi.dictionaries });
    };
  }, [publicUi]);
  //--------------------------------------------------------------------------- Query string
  useEffect(() => {
    if (!appUiContext.locale.queryString) {
      const locationPath = location.pathname;
      const locationQueryString = RemoveLocaleContextFromQueryString(location.search);
      const queryString = (locationQueryString && locationQueryString.length > 0) ? locationQueryString : '';
      window.history.replaceState(null, '', locationPath + queryString);
    };
  }, [appUiContext.locale.queryString]);
  //---------------------------------------------------------------------------
  return (
    <div
      id="app"
      className={CombineStyles([
        styles.container,
        `theme-${appUiContext.theme.themeId}`])}>

      <WikiSpinner show={isLoading} />

      <ApplicationHeader ui={publicUi?.applicationLayout.applicationHeader} />

      <div
        id="appContent"
        className={styles.content}>
        <Routes>
          <Route path={`${pathNotFound}/*`} element={<NotFoundPage />} />
          <Route path={`${pathSignInOut}/*`} element={<PageSignInOut />} />
          <Route path={`${pathRedirect}/*`} element={<PageRedirect />} />
          <Route path={`${pathSearchConsole}/*`} element={<SearchConsole ui={publicUi?.searchConsole} />} />
          <Route path={`${pathBusinessConsole}/*`} element={<BusinessConsole />} />
          <Route path={`${pathAdminConsole}/*`} element={<AdminConsole />} />
          <Route path={`${pathUserConsole}/*`} element={<UserConsole />} />
          <Route path={`${pathLegalDocuments}/*`} element={<DocumentViewer ui={tmpUiDocumentViewer} />} />
          <Route path={`${pathLegalDocuments}/:documentId`} element={<DocumentViewer ui={tmpUiDocumentViewer} />} />

          <Route path="status/" element={<StatusPage />} />
          <Route path="test/*" element={<TestUtils />} />
          <Route path="tests/*" element={<TestUtils />} />
          <Route path="util/*" element={<TestUtils />} />
          <Route path="utils/*" element={<TestUtils />} />
          <Route path="sw/" element={<ServWorkerTest />} />
          <Route path="scroll/" element={<ServWorkerTest />} />
          {/*------------------------------------------------------------------*/}
          <Route path="/" element={<HomepageWrapper ui={publicUi?.homepage} />} />
          <Route path="/:businessPath" element={<HomepageWrapper ui={publicUi?.homepage} />} />
          <Route path="/:businessPath/:locale" element={<HomepageWrapper ui={publicUi?.homepage} />} />
          {/*------------------------------------------------------------------*/}
          <Route path="/testcomponent" element={<TestComponent />} />
          <Route path="/testlist" element={
            <TestList />} />
        </Routes>
      </div>

      <ApplicationFooter ui={undefined} />

    </div>
  );
}
