import { IUiInteractiveForm } from "../common/forms/IUiInteractiveForm";

export interface IUiBusinessPage extends IUiInteractiveForm {

}

export const tmpUiBusinessPage: IUiBusinessPage = {
  id: "",
  directoryId: "",
  elementType: "",
  caption: undefined,
  hint: undefined,
  menuContent: {
    id: "BusinessPage.MenuContent",
    directoryId: "",
    elementType: "",
    hint: undefined,
    options: [{
      id: "OptionHome",
      directoryId: "",
      elementType: "",
      caption: { en: "Home"},
      hint: undefined
    }, {
      id: "OptionReviews",
      directoryId: "",
      elementType: "",
      caption: { en: "Reviews (5)"},
      hint: undefined
    }, {
      id: "OptionLanguages",
      directoryId: "",
      elementType: "",
      caption: { en: "Other Languages (3)"},
      hint: undefined
    }, {
      id: "OptionTags",
      directoryId: "",
      elementType: "",
      caption: { en: "Tags"},
      hint: undefined
    }, {
      id: "OptionShare",
      directoryId: "",
      elementType: "",
      caption: { en: "Share"},
      hint: undefined
    }, {
      id: "OptionLists",
      directoryId: "",
      elementType: "",
      caption: { en: "Lists"},
      hint: undefined
    },/* {
      id: "OptionFavoritesAdd",
      directoryId: "",
      elementType: "",
      caption: { en: "Add to Favorites"},
      hint: undefined
    }, {
      id: "OptionFavoritesRemove",
      directoryId: "",
      elementType: "",
      caption: { en: "Remove from Favorites"},
      hint: undefined
    }, */ {
      id: "OptionReportAbuse",
      directoryId: "",
      elementType: "",
      caption: { en: "Report Abuse"},
      hint: undefined
    }]
  }
}