import { useContext } from 'react';
import { IUiDocumentViewer } from '../IUiDocumentViewer';
import { IReadableDocument } from '../IReadableDocument';
import { AppUiContextStore } from '../../../context/app-ui-context/AppUiContextProvider';
import { ScreenType, useAppScreenContext } from '../../../context/app-screen-context/AppScreenProvider';
import { IUiOption } from '../../common/options/IUiOption';
import FormOptions from '../../common/form-options-bar/FormOptions';
import { getTUiMenuContent } from '../../common/menu/menu-content/TUiMenuContent';
import PopupDialog from '../../common/popup-v2/popup-dialog/PopupDialog';
import GetCaption from '../../common/functions/GetCaption';
import CombineStyles from '../../../utils/combineStyles';
import styles from './DocumentViewerPopup.module.css';
import DocumentContent from '../document-content/DocumentContent';

interface IProps {
  ui: IUiDocumentViewer;
  data: IReadableDocument;
  onClose: () => void;
}

export default function DocumentViewerPopup(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const screenType = useAppScreenContext();
  //---------------------------------------------------------------------------
  const onOptionSelect = (optionId: string) => {
    switch (optionId) {
      case "OptionOk":
        props.onClose();
        break;
    };
  };
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  props.ui.menuContent.options.forEach(option => {
    switch (option.id) {
      case "OptionOk":
        updatedOptions.push({ ...option, iconId: "check", isDefault: true });
        break;
    };
  });
  const formOptions =
    <FormOptions
      ui={getTUiMenuContent({ ...props.ui.menuContent, options: updatedOptions })}
      applyMobileStyle={false}
      onSelect={onOptionSelect}
    />;
  //---------------------------------------------------------------------------
  return (
    <PopupDialog
      id={props.ui.id}
      header={`${GetCaption(appUiContext, props.ui.id, props.ui.caption)}: ${props.data.title}`}
      toolbarContent={formOptions}
      isModal={true}
      isDraggable={false}
      animation={"FadeIn"}
      onClose={props.onClose}
    >
      <div className={CombineStyles([
        styles.container,
        screenType == ScreenType.Mobile ? styles.mobile : ""
      ])}>
        <DocumentContent
          ui={props.ui}
          data={props.data}
        />
      </div>
    </PopupDialog>
  );
}