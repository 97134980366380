import { ReactPlugin, useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { ClassAdLanguageSetPublished, ClassAdModulePublished } from "../components/homepage/ClassAdLanguageSetPublished";
import { v4 as createNewGuid } from "uuid";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import ReactGA from 'react-ga4';
import test from "node:test";
//----------------------------------------------------------------------------
export class TrackPageViewNames {
  static businessPage = "BusinessPage";
  static homePage = "HomePage";
  static searchResultsCard = "SearchResultsCard";
  static banner = "Banner";
}
//----------------------------------------------------------------------------
export type EPageViewType = 'Banner' | "HomePage" | "SearchResults" | "BusinessPage";
//----------------------------------------------------------------------------
export interface ITrackable {
  businessId: string;
  localeId: string;
  text: string;
  adCampaignId?: string;
  adLanguageSetId?: string;
  adModuleId?: string;
}
//----------------------------------------------------------------------------
interface IPageViewKey {
  businessId: string;
  localeId: string;
  text: string;
}
//---------------------------------------------------------------------
export interface IPageViewProperties extends IPageViewKey {
  pageViewId: string;
  adCampaignId?: string;
  adLanguageSetId?: string;
  adModuleId?: string;
  refUri: string;
  pageViewType: EPageViewType;
  chargePoints?: number;
}
//---------------------------------------------------------------------
export class PageViewProperties implements IPageViewProperties {
  pageViewId!: string;
  businessId!: string;
  localeId!: string;
  adCampaignId?: string;
  adLanguageSetId?: string;
  adModuleId?: string;
  text!: string;
  refUri!: string;
  pageViewType!: EPageViewType;
  chargePoints?: number;
  //---------------------------------------------------------------------
  private _browserTime?: Date;
  get browserTime() {
    return this._browserTime;
  }
  set browserTime(value: any) {
    if (value)
      this._browserTime = new Date(value);
    else
      this._browserTime = undefined;
  }
  //---------------------------------------------------------------------
  private _registerTimeUtc?: Date;
  get registerTimeUtc() {
    return this._registerTimeUtc;
  }
  set registerTimeUtc(value: any) {
    if (value)
      this._registerTimeUtc = new Date(value);
    else
      this._registerTimeUtc = undefined;
  }
  //---------------------------------------------------------------------
  get eventNameByPageViewType(): string {
    switch (this.pageViewType) {
      case "Banner":
        return "bannerView";
      case "BusinessPage":
        return "businessPageView";
      case "HomePage":
        return "homePageView";
      case "SearchResults":
        return "searchResultsView";
      default:
        return "undefinedPageView";
    }
  }
  //---------------------------------------------------------------------
  clientCity!: string;
  clientCountryOrRegion!: string;
  clientStateOrProvince!: string;
  clientType!: string;
  //---------------------------------------------------------------------
  toJSON() {
    let result = {
      ...this,
      registerTimeUtc: null,
      clientCity: null,
      clientCountryOrRegion: null,
      clientStateOrProvince: null,
      clientType: null,
      browserTime: this._browserTime?.toISOString(),
      _browserTime: null,
      _registerTimeUtc: null,
    };
    delete result["registerTimeUtc"];
    delete result["_registerTimeUtc"];
    delete result["clientCity"];
    delete result["clientCountryOrRegion"];
    delete result["clientStateOrProvince"];
    delete result["clientType"];
    delete result["_browserTime"];
    if (!this._browserTime)
      delete result["browserTime"];
    return result;
  }
  //---------------------------------------------------------------------
  toGA4PageView() {
    let location = `${this.refUri}${this.pageViewType}/${this.businessId}`;
    if (this.pageViewType == "SearchResults")
      location = `/${location}`
    let result = {
      //hitType: "pageview",
      send_to: "G-RLNR6D9BK5",//"GTM-5MKR9SP7",
      pageViewId: this.pageViewId,
      page_referrer: this.refUri,
      page_location: location,
      page_title: this.text,
      //browserTime: this._browserTime,
      businessId: this.businessId,
      localeId: this.localeId,
      adCampaignId: this.adCampaignId,
      adLanguageSetId: this.adLanguageSetId,
      pageViewType: this.pageViewType,
      chargePoints: this.chargePoints
    };
    //if (!this._browserTime) delete result["browserTime"];
    if (!this.adCampaignId) delete result["adCampaignId"];
    if (!this.adLanguageSetId) delete result["adLanguageSetId"];
    if (!this.chargePoints) delete result["chargePoints"];
    return result;
  }
  //---------------------------------------------------------------------
  static fromITrackable(source: ITrackable, trackType: EPageViewType) {
    let result = new PageViewProperties();
    result.browserTime = new Date();//.toISOString();
    result.pageViewId = createNewGuid();
    result.pageViewType = trackType;
    result.businessId = source.businessId;
    result.adCampaignId = source.adCampaignId;
    result.adLanguageSetId = source.adLanguageSetId;
    result.adModuleId = source.adModuleId;
    result.localeId = source.localeId;
    result.text = source.text;
    result.refUri = location.href;
    switch (result.pageViewType) {
      case "BusinessPage":
        result.chargePoints = 10;//source.adRank?.pointsPerEngagement;
        break;
      case "Banner":
        result.chargePoints = 1;//source.adRank?.pointsPerView;
        break;
      case "HomePage":
        result.chargePoints = 2;//source.adRank?.pointsPerView;
        break;
      case "SearchResults":
        result.chargePoints = 0;//source.adRank?.pointsPerView;
        break;
    }
    if (result.chargePoints == undefined) {
      result.chargePoints = 0;
    }
    return result;
  }
  //------------------------------------------------------------------
  static fromArray(source?: any) {
    let result: PageViewProperties[] = [];
    source?.forEach((item: any) => {
      let pvp = new PageViewProperties();
      Object.assign(pvp, item);
      result.push(pvp);
    });
    return result;
  }
  //------------------------------------------------------------------
  equal(source: ITrackable, trackType: EPageViewType) {
    return (
      this.businessId == source.businessId &&
      this.localeId == source.localeId &&
      this.pageViewType == trackType
    );
  }
  //------------------------------------------------------------------
  equalKey(source: IPageViewKey) {
    return (
      this.businessId == source.businessId &&
      this.localeId == source.localeId &&
      this.text == source.text
    );
  }
}
//----------------------------------------------------------------------------
class PageViewTypeStatistics {
  pageViewType!: EPageViewType;
  count: number = 0;
  chargePoints: number = 0;
  //------------------------------------------------------------------------
  constructor(source: PageViewProperties) {
    this.pageViewType = source.pageViewType;
    this.add(source);
  }
  //------------------------------------------------------------------------
  add(source: PageViewProperties) {
    this.count++;
    if (source.chargePoints)
      this.chargePoints += source.chargePoints;
  }
}
//----------------------------------------------------------------------------
export class PageViewStatistics implements IPageViewKey {
  businessId!: string;
  localeId!: string;
  text!: string;
  //adCampaignId?: string;
  //adLanguageSetId?: string;
  statistics: PageViewTypeStatistics[] = [];
  //---------------------------------------------------------------------
  constructor(source: PageViewProperties) {
    this.businessId = source.businessId;
    this.localeId = source.localeId;
    this.text = source.text;
    this.statistics.push(new PageViewTypeStatistics(source));
  }
  //---------------------------------------------------------------------
  private add(pageView: PageViewProperties) {
    let st = this.statistics.find(item => item.pageViewType == pageView.pageViewType);
    if (!st) {
      this.statistics.push(new PageViewTypeStatistics(pageView));
    }
    else {
      st.add(pageView);
    }
  }
  //---------------------------------------------------------------------
  static fromArray(source?: PageViewProperties[]) {
    if (!source || source.length == 0)
      return undefined;
    let result: PageViewStatistics[] = [];
    source.forEach(pageView => {
      let stat = result.find(s => pageView.equalKey(s));
      if (!stat) 
        result.push(new PageViewStatistics(pageView));
      else
        stat.add(pageView);
    });
    result.sort((i1, i2) => i1.compareBusinessId(i2));
    return result;
  }
  //---------------------------------------------------------------------
  compareBusinessId(other: PageViewStatistics) {
    if (this.businessId < other.businessId)
      return -1;
    if (this.businessId > other.businessId)
      return 1;
    return 0;
  }
}
//----------------------------------------------------------------------------
export class Tracker {
  appInsights!: ReactPlugin;
  pageViewProperties?: PageViewProperties;
  //--------------------------------------------------------------------------
  constructor(appInsights: ReactPlugin) {
    //console.log("Tracker.constructor", appInsights);
    this.appInsights = appInsights;
  }
  //--------------------------------------------------------------------------
  track(pageViewType: EPageViewType, source?: ITrackable) {
    if (!source) return;
    if (this.pageViewProperties?.equal(source, pageViewType))
      return;
    if (!this.appInsights)
      return console.error("Tracker.track: appInsights is undefined");
    this.pageViewProperties = PageViewProperties.fromITrackable(source, pageViewType);
    //console.log("Tracker.trackPageView:", this.pageViewProperties);

    this.appInsights.trackPageView({
      name: this.pageViewProperties.pageViewType,
      pageType: this.pageViewProperties.pageViewType,
      properties: this.pageViewProperties.toJSON(),
    });
    //------------------------------------------------------------------------
    //ReactGA.send(this.pageViewProperties.toGA4PageView());
    ReactGA.event(
      this.pageViewProperties.eventNameByPageViewType,
      this.pageViewProperties.toGA4PageView()
    );
    //ReactGA.send(this.pageViewProperties.toGA4PageView());
    //------------------------------------------------------------------------
    // window.dataLayer.push({
    //   event: 'pageview',
    //   page: this.trackPageView.toJSON()
    // });    
  }
}
//----------------------------------------------------------------------------
export function useTracker(): Tracker | undefined {
  const appInsights = useAppInsightsContext();
  const tracker = useRef<Tracker>();
  if (!tracker.current) {
    tracker.current = new Tracker(appInsights);
  }
  //--------------------------------------------------------------------------
  return tracker.current;
}