import { useMsal } from "@azure/msal-react";
import { useContext, useEffect, useLayoutEffect, useRef } from "react";
import { useState } from "react";
import { AppAuthContextStore } from "../../../context/app-auth-context/AppAuthContext";
import { AppUiContextStore } from "../../../context/app-ui-context/AppUiContextProvider";
import InputText from "../input/input-text/InputText";
import { ClassImageEditorContext, ImageEditorContext } from "./image-editor-context/ImageEditorContextProvider";
import { ImageEditorSession } from "./image-editor-context/ImageEditorSession";
import styles from "./ImageEditor.module.css";
import ImageEditorToolbar from "./ImageEditorToolbar";
import { EImageLoadingState, TImageData } from "./TImageData";
import { TUiImageEditor } from "./TUiImageEditor";
import { TUiInputText } from "../input/input-text/TUiInputText";
import { EImageEditorContextUpdate } from "./image-editor-context/ImageEditorContextReducer";
import { v4 as createNewGuid } from "uuid";
import React from "react";
import CombineStyles from "../../../utils/combineStyles";
import { TImageLibraryItem } from "../../user-console/user-images/image-library/TImageLibrary";
import ImageSelector from "./image-selector-popup/ImageSelector";
import { EEnterImageEditMode, ImageEditorUsagePopup } from "./ImageEditorUsagePopup";
import { useAbortController } from "../../../hooks/useAbortController";
import ImageEditorCanvas from "./image-editor-canvas/ImageEditorCanvas";
import { EImageEditorAction } from "./image-editor-canvas/Crop";
import { ScreenType, useAppScreenContext } from "../../../context/app-screen-context/AppScreenProvider";
import UnsavedChangesDialog from "./unsaved-changes-dialog/UnsavedChangesDialog";
import { TImageUsage } from "./TImageUsage";
import { EImageEditorMode } from "./ImageEditorV2";

const defaultDescription: string = "No Description";
export const copyImagePrefix = "copy_of_";

const uiInputText: TUiInputText = {
  id: "uiInputText",
  directoryId: "uiInputText",
  elementType: "uiInputText",
  disabled: false,
  visible: true,
  caption: { en: "Image description" },
  hint: { en: "Image description will appear in your image gallery" },
  placeholder: { en: "Type image description here" },
  defaultValue: "Image1",
  validation: undefined
}

export enum EImageEditorCloseCommand {
  None = 0,
  Save = 1,
  Discard = 2,
  Dialog = 3,
}

// to enter crop and image library from normal window mode we need to enter in fullwindow mode first
// this enum provided to support this two phase entering process
// normal window -> full window -> crop or image library
enum EFullWindowTarget {
  None = 0,
  Crop = 1,
  ImageLibrary = 2,
}

export interface IImageEditorProps {
  objectIds: string[];
  imageData?: TImageData;
  targetUsage: TImageUsage;
  isRequired: boolean;
  placeholderHint?: string;
  removeImageDisabled: boolean;
  closeCommand?: EImageEditorCloseCommand; // command from parent component to start upload (for "Save" button)
  fullWindow: boolean;
  animationEnd?: boolean;
  onSessionOpened?: (session: ImageEditorSession) => void;
  onUrlAndBlobReady?: (imageData?: TImageData) => void;
  onUnsavedChangesDiscard?: (imageDataToRestore?: TImageData) => void;
  onToggleFullWindow?: () => void;
}

export default function ImageEditor(props: IImageEditorProps) {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { appUiContext } = useContext(AppUiContextStore);
  const { imageEditorContext, imageEditorDispatch } = useContext(ImageEditorContext);
  const screenType = useAppScreenContext();
  const [unsavedChangesDialogOpen, setUnsavedChangesDialogOpen] = useState(false);
  const [action, setAction] = useState<EImageEditorAction>(EImageEditorAction.None);
  const [mode, setModeState] = useState<EImageEditorMode>(EImageEditorMode.Image);
  const [description, setDescription] = useState<string | undefined>(props.imageData?.description);
  const [imageSessionId, setImageSessionId] = useState<string>(/*props.imageData?.id*/);
  const idRef = useRef<string | undefined>(undefined);
  const inputRef = useRef<HTMLInputElement>(null);
  const fullscreenRef = useRef<HTMLDivElement>(null);
  const fullWindowBeforeCrop = useRef(false);
  const [fullWindowTarget, setFullWindowTarget] = useState<EFullWindowTarget>(EFullWindowTarget.None);
  const [userImageCount, setUserImageCount] = useState<number>(0);
  const [editEnabled, setEditEnabled] = useState(false);
  //const [enterEditModeMessage, setEnterEditModeMessage] = useState<string | undefined>(undefined);
  const [showEnterEditModeMessage, setShowEnterEditModeMessageMessage] = useState(false);
  const prevModeRef = useRef<EImageEditorMode>();
  const newGuid = useRef(false);
  const abortController = useAbortController("ImageEditorNew");
  const imageEditorUi = ClassImageEditorContext.imageEditorUi;
  //-local session from context---------------------------------------
  let session = imageEditorContext.getSessionByImageId(imageSessionId);
  console.log("IE.GetSessionById:", imageSessionId, session);
  //-[]--------------------------------------------------------------
  useEffect(() => {
    let controller = abortController.newController("getUserImageCount");
    TImageData.getUserImageCount("", controller.signal)
      .then(count => {
        //console.log("IE.userImageCount:", count);
        !controller.aborted && setUserImageCount(count)
      });
    return (() => {
      abortController.abortOnUnmount();
      if (imageEditorContext && idRef.current) {
        session = imageEditorContext.getSessionByImageId(idRef.current);
        if (session && !session.imageData.id)
          session.removeFromContext();
      }
    });
  }, []);
  //-[props.imageData?.id]-----------------------------------------------------
  useEffect(() => {
    console.log("IE.useEffect[props.imageData.id].props.imageData:", props.imageData);
    let id: string;
    newGuid.current = false;
    //if (props.imageData?.id && !TImageData.isSystemImage(props.imageData.id)) {
    if (props.imageData?.id) {
      id = props.imageData.id;
      console.log("IE.useEffect[props.imageData.id].Id from imageData:", id);
    }
    else {
      id = createNewGuid();
      newGuid.current = true;
      console.log("IE.useEffect[props.imageData.id].New Id:", id);
    }
    setImageSessionId(id);
  }, [props.imageData?.id]);
  //-[imageSessionId]-----------------------------------------------------------
  useEffect(() => {
    if (!imageSessionId)
      return;
    idRef.current = imageSessionId;
    console.log("ImageEditor.useEffect[imageSessionId].session:", session);
    if (!session) {
      let imageData: TImageData | undefined = undefined;
      console.log("ImageEditor.useEffect[imageSessionId].props.imageData:", props.imageData);
      if (props.imageData?.id == imageSessionId)
        imageData = props.imageData;
      else if (props.imageData) {
        imageData = new TImageData(props.imageData, { id: imageSessionId });
        console.log("IE.useEffect[imageSessionId].NewImageData:", imageData);
      }
      console.log("ImageEditor.useEffect[imageSessionId]: imageData for NewImageSession:", imageData);
      session = ImageEditorSession.NewSession(
        props.objectIds,
        props.targetUsage,
        imageSessionId,
        newGuid.current,
        imageData
      );
      session.addToContext(imageEditorDispatch);
      console.log("ImageEditor.NewSession:", session);
    }
    else {
      // session exists - signal, that image editor is opened with this session
      //console.log("ImageEditor.ImageEditorOpened.props.objectId:", props.objectId);
      //console.log("ImageEditor.ImageEditorOpened.session.objectId:", session.objectId);
      session.objectIds = props.objectIds;
      session.initialImageData = props.imageData;
      imageEditorDispatch({ type: "ImageEditorOpened", session: session });
    }
  }, [imageSessionId]);
  //-[imageEditorContext]-------------------------------------------------------
  useEffect(() => {
    if (!imageEditorContext || !session || imageEditorContext.lastChangedSession != session)
      return;
    let localMode = EImageEditorMode.Image;
    switch (imageEditorContext.lastChangedSessionUpdate) {
      case EImageEditorContextUpdate.SessionAdded:
      case EImageEditorContextUpdate.ImageEditorOpened:
        setDescription(session.imageData.description);
        props.onSessionOpened && props.onSessionOpened(session);
        break;
      case EImageEditorContextUpdate.ImageTransformed:
        if (session.imageData.description && session.imageData.description != description)
          setDescription(session.imageData.description);
        break;
      case EImageEditorContextUpdate.ImageRemoved:
        setDescription(undefined);
        localMode = EImageEditorMode.Placeholder;
        //console.log("IE.useEffect[context]: image removed - setting mode to placeholder. session.loadingState:", session.loadingState);
        break;
    }
    switch (session.loadingState) {
      case undefined:
      case EImageLoadingState.Selected: {
        let controller = abortController.newController("getUrlCallback");
        console.log("IE.useEffect[imageEditorContext].EImageLoadingState.Selected: getUrlCallback")
        session.imageData.getUrlCallback(
          "Large",
          (uri) => {
            if (uri && !controller.aborted) {
              setMode(EImageEditorMode.Spinner);
              //session?.loadImageFromUrl(uri);
              session?.loadImage();
            }
            else if (!controller.aborted) {
              localMode = EImageEditorMode.Placeholder;
              //setMode(EImageEditorMode.Placeholder);
              //setEditEnabled(true);
            }
          },
          controller.signal);
      } break;
      //--------------------------------------------------------------------------
      case EImageLoadingState.Downloading:
      case EImageLoadingState.ImageUploading:
      case EImageLoadingState.DescriptionUploading:
        localMode = EImageEditorMode.Spinner;
        break;
      //--------------------------------------------------------------------------
      case EImageLoadingState.DownloadError:
        localMode = EImageEditorMode.Placeholder;
        break;
      //--------------------------------------------------------------------------
      case EImageLoadingState.ImageUploaded:
        //console.log("IE.useEffect[imageData]: status is Uploaded");
        //session.setLoadingState(EImageLoadingState.Opened);
        break;
      //--------------------------------------------------------------------------
      // case EImageLoadingState.Downloaded:
      //   //console.log("IE.useEffect[imageData]: status is Downloaded");
      //   if (session?.bitmap) {
      //     let transform = ImageTransformation.getInitialTransformation(session.bitmap);
      //     session.addNewTransform(transform);
      //     session.setLoadingState(EImageLoadingState.Opened);
      //   }
      //   if (mode == EImageEditorMode.Spinner)
      //     localMode = EImageEditorMode.Image;
      //   break;
      //--------------------------------------------------------------------------
      case EImageLoadingState.Opened:
        if (mode == EImageEditorMode.Spinner)
          localMode = EImageEditorMode.Image;
        console.log("IE.ImageOpened.session:", session);
        break;
      //--------------------------------------------------------------------------
      case EImageLoadingState.DescriptionUploaded:
        if (mode == EImageEditorMode.Spinner)
          localMode = EImageEditorMode.Image;
        //session.setLoadingState(EImageLoadingState.Opened);
        break;
    };
    //console.log("IE.useEffect[context]: setting mode to localMode:", localMode);
    setMode(localMode);
  }, [imageEditorContext]);
  //-[props.closeCommand]------------------------------------------------------
  useEffect(() => {
    switch (props.closeCommand) {
      case EImageEditorCloseCommand.Save:
        startUrlAndBlobSave();
        break;
      case EImageEditorCloseCommand.Discard:
        session?.discardChanges();
        props.onUnsavedChangesDiscard && props.onUnsavedChangesDiscard(undefined);
        break;
      case EImageEditorCloseCommand.Dialog:
        setUnsavedChangesDialogOpen(true);
        break;
    }
  }, [props.closeCommand]);
  //-[fullWindow]--------------------------------------------------------------
  useLayoutEffect(() => {
    // this combination says that crop was requested from normal window mode,
    // so after entering in fullwindow we need to start crop

    //if (props.animationEnd != undefined)
    //return;
    if (screenType == ScreenType.Desktop)
      return;
    if (props.fullWindow) {
      switch (fullWindowTarget) {
        case EFullWindowTarget.Crop:
          //console.log("IE.useLayoutEffect[fullWindow]: setMode(EImageEditorMode.Crop)");
          setMode(EImageEditorMode.Crop);
          break;
        case EFullWindowTarget.ImageLibrary:
          setMode(EImageEditorMode.ImageLibrary);
          break;
      }
      //setFullWindowTarget(EFullWindowTarget.None);
    }
  }, [props.fullWindow]);
  //-[fullWindowTarget]----------------------------------------------------------
  useLayoutEffect(() => {
    // this effect supports two phase crop entering process: 
    // normal window -> full window -> crop
    switch (fullWindowTarget) {
      case EFullWindowTarget.Crop:
        if (!props.fullWindow)
          toggleFullWindowMode();
        else
          setMode(EImageEditorMode.Crop);
        break;
      case EFullWindowTarget.ImageLibrary:
        if (!props.fullWindow)
          toggleFullWindowMode();
        else
          setMode(EImageEditorMode.ImageLibrary);
        break;
    }
  }, [fullWindowTarget]);
  //-[props.animationEnd]----------------------------------------------------------
  useLayoutEffect(() => {
    if (!props.animationEnd)
      return;
    switch (fullWindowTarget) {
      case EFullWindowTarget.Crop:
        if (props.fullWindow) {
          setMode(EImageEditorMode.Crop);
        }
        break;
      case EFullWindowTarget.ImageLibrary:
        if (props.fullWindow) {
          setMode(EImageEditorMode.ImageLibrary);
        }
        break;
      default:
        //setMode(EImageEditorMode.Image);
        break;
    }
  }, [props.animationEnd]);
  //-[mode]------------------------------------------------------------------
  useEffect(() => {
    //console.log("useEffect[mode]:", mode);
    switch (mode) {
      case EImageEditorMode.ImageLibrary:
      case EImageEditorMode.Crop: {
        setFullWindowTarget(EFullWindowTarget.None);
      }
    }
  }, [mode]);
  //-[action]------------------------------------------------------------------
  useEffect(() => {
    switch (action) {
      case EImageEditorAction.CropConfirm:
      case EImageEditorAction.CropDiscard:
        // this resides in useEffect because canvas must handle these actions first
        if (props.fullWindow != fullWindowBeforeCrop.current) {
          toggleFullWindowMode();
        }
        setMode(EImageEditorMode.Image);
        break;
    }
    setAction(EImageEditorAction.None);
  }, [action]);
  //---------------------------------------------------------------------------
  const setMode = (value: EImageEditorMode) => {
    prevModeRef.current = mode;
    setModeState(value);
  }
  //---------------------------------------------------------------------------
  const tryEnterEditMode = () => {
    let imageData = session?.transform?.imageData;
    if (!imageData) {
      setEditEnabled(false);
      return;
    }

    if (imageData.isSystemImage) {
      setMode(EImageEditorMode.Placeholder);
      setEditEnabled(true);
      return;
    }
    if (!imageData.isPublic && !imageData.isUsedElsewhere(props.targetUsage)) {
      setEditEnabled(true);
      return;
    }
    setShowEnterEditModeMessageMessage(true);
  }
  //---------------------------------------------------------------------------
  const editModePopupResponse = (editMode: EEnterImageEditMode) => {
    switch (editMode) {
      case EEnterImageEditMode.EditThisImage:
        setEditEnabled(true);
        break;
      case EEnterImageEditMode.CreateCopy:
        let imageData = session?.transform?.imageData;
        if (imageData) {
          session?.loadOtherImage(imageData, false);
          setEditEnabled(true);
        }
        break;
    }
    setShowEnterEditModeMessageMessage(false);
  }
  //---------------------------------------------------------------------------
  const onActionStart = (action: EImageEditorAction) => {
    // some actions require re-rendering and some don't
    // the latter are handled here and returned, 
    // while others set the "action" state to re-render.
    // in most cases it is a signal to canvas to do some transformations
    switch (action) {
      //-----------------------------------------------------------------------
      case EImageEditorAction.EnterEditMode:
        tryEnterEditMode();
        return;
      //-----------------------------------------------------------------------
      case EImageEditorAction.SelectImage:
        onImageSelectTrigger();
        return;
      //-----------------------------------------------------------------------
      case EImageEditorAction.SelectImageFromGallery:
        fullWindowBeforeCrop.current = props.fullWindow;
        setFullWindowTarget(EFullWindowTarget.ImageLibrary);
        return;
      //-----------------------------------------------------------------------
      case EImageEditorAction.ImageFromGalleryDiscard:
        if (prevModeRef.current)
          setMode(prevModeRef.current);
        else
          setMode(EImageEditorMode.Image);
        if (props.fullWindow != fullWindowBeforeCrop.current) {
          toggleFullWindowMode();
        }
        return;
      //-----------------------------------------------------------------------
      case EImageEditorAction.Undo:
      case EImageEditorAction.Redo:
        if (session) {
          let index = action == EImageEditorAction.Undo ? session.transformIndex - 1 : session.transformIndex + 1;
          session?.setTransformIndex(index);
        }
        setAction(action);
        break;
      //-----------------------------------------------------------------------
      case EImageEditorAction.CropStart:
        fullWindowBeforeCrop.current = props.fullWindow;
        //console.log("IE.onActionStart.fullWindowBeforeCrop:", props.fullWindow);
        setFullWindowTarget(EFullWindowTarget.Crop);
        setAction(action);
        break;
      //-----------------------------------------------------------------------
      case EImageEditorAction.CropConfirm:
      case EImageEditorAction.CropDiscard:
        setAction(action);
        // moved to useEffect[action]
        break;
      //-----------------------------------------------------------------------
      case EImageEditorAction.ImageDescriptionStart:
        setMode(EImageEditorMode.Description);
        return;
      //break;
      //-----------------------------------------------------------------------
      case EImageEditorAction.ImageDescriptionConfirm:
        session?.setDescription(description);
        setMode(EImageEditorMode.Image);
        return;
      //break;
      //-----------------------------------------------------------------------
      case EImageEditorAction.ImageDescriptionDiscard:
        if (session?.imageData)
          setDescription(session.imageData.description);
        setMode(EImageEditorMode.Image);
        return;
      //break;
      //-----------------------------------------------------------------------
      case EImageEditorAction.UrlAndBlobSave:
        startUrlAndBlobSave();
        return;
      //-----------------------------------------------------------------------
      case EImageEditorAction.ToggleFullScreenMode:
        toggleFullWindowMode();
        return;
      //-----------------------------------------------------------------------
      case EImageEditorAction.RemoveImage:
        if (session) {
          session.removeImage(undefined);
          setMode(EImageEditorMode.Placeholder);
          if (imageSessionId != session.imageId) {
            //console.log("IE.onActionStart: imageSessionId != session.imageId", imageSessionId, session.imageId);
            setImageSessionId(session.imageId);
          }
        }
        return;
      default:
        setAction(action);
        break;
    }
  }
  //---------------------------------------------------------------------------
  const onUnsavedChangesDialogSave = () => {
    // If user selected save changes option in unsaved changes dialog
    // we need to start uploading process (passing updated imageEditorState to canvas component)
    // Once is completed canvas component will update image status, which will be catch by 'onImageEditorStateChange'
    //console.log(`IE.onUnsavedChangesDialogSave`);
    setUnsavedChangesDialogOpen(false);
    startUrlAndBlobSave();
  }
  //---------------------------------------------------------------------------
  const onUnsavedChangesDialogDiscard = () => {
    //console.log("discard");
    setUnsavedChangesDialogOpen(false);
    session?.discardChanges();
    // If user decided to discard changes, respond to caller with last uploaded image if there is one
    props.onUnsavedChangesDiscard && props.onUnsavedChangesDiscard(session?.initialImageData);
  }
  //---------------------------------------------------------------------------
  const startUrlAndBlobSave = () => {
    console.log("startUrlAndBlobSave:", session);
    if (!session)
      return;
    if (session.imageRemoved == true) {
      props.onUrlAndBlobReady && props.onUrlAndBlobReady(undefined);
    }
    session.onUrlAndBlobReady = props.onUrlAndBlobReady;
    if (session.imageIsChanged) {
      setAction(EImageEditorAction.UrlAndBlobSave); // canvas will start to create blob and url
      setMode(EImageEditorMode.Spinner);
    }
    else if (session.descriptionIsChanged) {
      session.saveCurrentDescription();
    }
  }
  //---------------------------------------------------------------------------
  const onBlobReady = () => {
    setMode(EImageEditorMode.Image);
  }
  //---------------------------------------------------------------------------
  const selectLocalUrl = (localUrl: string, fileName: string, contentType: string) => {
    if (session) {
      let newGuid = session.imageData.isSystemImage;
      session.loadImageFromUrl(localUrl, newGuid, fileName, contentType);
    }
  }
  //---------------------------------------------------------------------------
  const onImageLibraryItemSelected = (item: TImageLibraryItem, asLink: boolean) => {
    if (props.fullWindow != fullWindowBeforeCrop.current) {
      toggleFullWindowMode();
    }
    session?.loadOtherImage(item, asLink);
    if (asLink) {
      setDescription(item.description);
      setEditEnabled(false);
    }
  }
  //---------------------------------------------------------------------------
  const onImageLibraryItemCancel = () => {
    onActionStart(EImageEditorAction.ImageFromGalleryDiscard);
  }
  //---------------------------------------------------------------------------
  const onDescriptionClick = () => {
    onActionStart(EImageEditorAction.ImageDescriptionStart)
  }
  //---------------------------------------------------------------------------
  const onDescriptionUpdate = (value: string) => {
    if (value == "")
      setDescription(undefined);
    else
      setDescription(value);
  }
  //---------------------------------------------------------------------------
  const onImageSelectTrigger = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.value = '';
      inputRef.current.click();
    };
  };
  //---------------------------------------------------------------------------
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let file = event.target.files?.item(0);
    file && selectLocalUrl(URL.createObjectURL(file), file.name, file.type);
  };
  //---------------------------------------------------------------------------
  const toggleFullWindowMode = () => {
    // if (!props.fullWindow)
    //   setFullWindowTarget(EFullWindowTarget.FullWindow);
    // else
    //   setFullWindowTarget(EFullWindowTarget.NormalWindow);
    //console.log("IE.toggleFullWindowMode:props.onToggleFullWindow", props.onToggleFullWindow);
    props.onToggleFullWindow && props.onToggleFullWindow();
  }
  //---------------------------------------------------------------------------
  let descriptionText: string = description ?? defaultDescription;
  let currentTransformation = session?.transform;
  let showDescriptionInput = currentTransformation
    && currentTransformation.bitmap
    && !currentTransformation.imageData.isSystemImage;
  //let onDescriptionClickHandler = mode == EImageEditorMode.Image ? onDescriptionClick : undefined;
  //let descriptionCursorStyle = mode == EImageEditorMode.Image ? { cursor: 'pointer' } : { cursor: '' };
  let onDescriptionClickHandler = undefined;
  let descriptionCursorStyle = { cursor: '' };
  if (mode == EImageEditorMode.Image && editEnabled) {
    onDescriptionClickHandler = onDescriptionClick;
    descriptionCursorStyle = { cursor: 'pointer' };
  }
  //---------------------------------------------------------------------------
  let containerStyle = props.fullWindow ? CombineStyles([styles.fullWindow, appUiContext.theme.themeId]) : styles.container;
  //---------------------------------------------------------------------------
  let toolbarCanvasInput =
    <React.Fragment>
      <ImageEditorCanvas
        session={session}
        placeholderHint={props.placeholderHint}
        mode={mode}
        action={action}
        animationEnd={props.animationEnd}
        onImageSelect={onImageSelectTrigger}
        onBlobReady={onBlobReady} />
      <input
        title="ImageFileInput"
        type='file'
        inputMode="none"
        accept="image/*"
        ref={inputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange} />
    </React.Fragment>;

  const getImageLybrary = () => {
    return <React.Fragment>
      <div className={containerStyle} ref={fullscreenRef}>
        <ImageSelector
          onSelectImage={onImageLibraryItemSelected}
          onCancel={onImageLibraryItemCancel}
        />
      </div>
    </React.Fragment>
  }
  //------------------------------------------------------------------------
  //console.log("IE.Render.props.fullWindow:", props.fullWindow);
  //------------------------------------------------------------------------
  if (mode == EImageEditorMode.ImageLibrary)
    return getImageLybrary();
  return (
    <div
      ref={fullscreenRef}
      className={containerStyle}>

      {toolbarCanvasInput}

      {!props.fullWindow &&
        <React.Fragment>

          {mode == EImageEditorMode.Description &&
            <div className={styles.descriptionContainer}>
              <InputText
                ui={uiInputText}
                data={description}
                focused={true}
                onUpdate={onDescriptionUpdate}
              />
            </div>}

          {mode != EImageEditorMode.Description
            && mode != EImageEditorMode.Placeholder
            //&& mode != EImageEditorMode.Crop
            && showDescriptionInput && !props.fullWindow &&
            <div
              className={styles.descriptionPlaceholder}
              style={descriptionCursorStyle}
              onClick={onDescriptionClickHandler}
            >
              <div onClick={onDescriptionClickHandler}>
                Image description:
              </div>
              <div
                className={styles.descriptionValue}
                onClick={onDescriptionClickHandler}
              >
                {descriptionText}
              </div>
            </div>}

          {/*
            imageEditorUi?.unsavedChangesDialog &&
            <UnsavedChangesDialog
              ui={imageEditorUi.unsavedChangesDialog}
              show={unsavedChangesDialogOpen}
              onDiscard={onUnsavedChangesDialogDiscard}
              onSave={onUnsavedChangesDialogSave}
              onClose={() => setUnsavedChangesDialogOpen(false)}
            />*/
          }

          {/* {imageEditorUi &&
            <LeavePageModal
              ui={imageEditorUi.confirmationDialog}
              showDialog={unsavedChangesDialogOpen}
              saveAction={onUnsavedChangesDialogSave}
              discardAction={onUnsavedChangesDialogDiscard}
            />} */}

          <ImageEditorUsagePopup
            show={showEnterEditModeMessage}
            imageData={session?.transform?.imageData}
            onResponse={editModePopupResponse}
          />

        </React.Fragment>}
    </div>
  );
}