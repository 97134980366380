import { IUiImageEditor } from "../../../../../common/image-editor/IUiImageEditor";
import { IUiMenuContent } from "../../../../../common/options/menus/IUiMenuContent";
import { IUiEditformDocumentItem, tmpUiEditformDocumentItem } from "../document-item-editform/IUiEditformDocumentItem";

export interface IUiDocumentItemNode {
  menuContent: IUiMenuContent;
  editForm: IUiEditformDocumentItem;
  imageEditor?: IUiImageEditor;
}

export const tmpUiDocumentItemNode: IUiDocumentItemNode = {
  menuContent: {
    id: "",
    directoryId: "",
    elementType: "",
    hint: undefined,
    options: [{
      id: "OptionEdit",
      directoryId: "",
      elementType: "",
      caption: { en: "Edit" },
      hint: undefined
    }, {
      id: "OptionEditText",
      directoryId: "",
      elementType: "",
      caption: { en: "Edit Text" },
      hint: undefined
    }, {
      id: "OptionAddBefore",
      directoryId: "",
      elementType: "",
      caption: { en: "Add Item Before" },
      hint: undefined
    }, {
      id: "OptionAddAfter",
      directoryId: "",
      elementType: "",
      caption: { en: "Add Item After" },
      hint: undefined
    }, {
      id: "OptionAddSub",
      directoryId: "",
      elementType: "",
      caption: { en: "Add Sub-Item" },
      hint: undefined
    }, {
      id: "OptionDelete",
      directoryId: "",
      elementType: "",
      caption: { en: "Delete Item" },
      hint: undefined
    }, {
      id: "OptionRestore",
      directoryId: "",
      elementType: "",
      caption: { en: "Restore Item" },
      hint: undefined
    }]
  },
  editForm: tmpUiEditformDocumentItem
};