import { IUiInteractiveForm } from "../../../../common/forms/IUiInteractiveForm";
import { IUiInputDropdown } from "../../../../common/input-v2/input-dropdown/InputDropdown";
import { IUiInputText } from "../../../../common/input-v2/input-text/InputText";

export interface IUiEditformReject extends IUiInteractiveForm {
  inputRejectionReason: IUiInputDropdown;
  inputRejectionReasonDetails: IUiInputText;
}

export const tmpUiEditformReject: IUiEditformReject = {
  id: "",
  directoryId: "",
  elementType: "",
  caption: undefined,
  hint: undefined,
  menuContent: {
    id: "",
    directoryId: "",
    elementType: "",
    hint: undefined,
    options: [{
      id: "OptionClose",
      directoryId: "",
      elementType: "",
      caption: { en: "Close" },
      hint: undefined
    }, {
      id: "OptionCancel",
      directoryId: "",
      elementType: "",
      caption: { en: "Cancel" },
      hint: undefined
    }, {
      id: "OptionOk",
      directoryId: "",
      elementType: "",
      caption: { en: "Ok" },
      hint: undefined
    }]
  },
  inputRejectionReason: {
    id: "InputRejectionReason",
    directoryId: "",
    elementType: "",
    dictionaryId: "RejectionReasons",
    caption: { en: "Rejection reason" },
    hint: undefined,
    placeholder: { en: "Select rejection reason..." },
    validation: {
      required: {
        value: 1,
        message: { en: "Rejection reason is required" }
      }
    }
  },
  inputRejectionReasonDetails: {
    id: "",
    directoryId: "",
    elementType: "",
    caption: { en: "Details" },
    hint: undefined,
    placeholder: { en: "Provide some details..." }
  }
}