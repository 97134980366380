import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../../context/app-auth-context/AppAuthContext";
import { ContentDirectoryDraft, IContentDirectoryDraftInitial } from "../content-directory-translator/IContentDirectoryDraft";
import { apiBasePath, pathDeleteContentDirectoryDraft } from "../../../../../utils/apiPathConstant";
import { apiDeletePrivate, checkResponse } from "../../../../../utils/api";
import { stringFormatter } from "../../../../../utils/common";

interface IProps {
  msalInstanse: IPublicClientApplication;
  authConfig?: IAuthConfig;
  directoryId: string;
  locale: string;
  abortSignal?: AbortSignal;
}

export async function deleteContentDirectoryDraft(props: IProps): Promise<ContentDirectoryDraft> {
  if (!props.authConfig) throw new Error("deleteContentDirectoryDraft: auth context is not provided");
  //---------------------------------------------------------------------------
  const uri = `${apiBasePath}${stringFormatter(pathDeleteContentDirectoryDraft, [props.directoryId, props.locale])}`;
  const response = await apiDeletePrivate(
    props.msalInstanse,
    props.authConfig,
    uri,
    props.abortSignal
  );
  checkResponse(response, "deleteContentDirectoryDraft");
  //---------------------------------------------------------------------------
  const translationDraft = new ContentDirectoryDraft().initialize(response?.content as IContentDirectoryDraftInitial);
  return (translationDraft);
}