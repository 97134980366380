import { ReactNode, useEffect, useRef } from "react";
import styles from "./EditFormSection.module.css"
import { EditFormSectionHeader } from "./EditFormSectionHeader";
import { TUiCaption } from "../../captions/TUiCaption";
import { ScreenType, useAppScreenContext } from "../../../../context/app-screen-context/AppScreenProvider";
import CombineStyles from "../../../../utils/combineStyles";

export interface IEditFormSection {
  id: string;
  caption?: TUiCaption | string;
  height?: number;
  children: ReactNode;
}

export function EditFormSection(props: IEditFormSection) {
  const screenType = useAppScreenContext();
  const sectionRef = useRef<HTMLDivElement>(null);
  const height = useRef<string>();
  //--------------------------------------------------------------------------
  useEffect(() => {
    if (!props.height || !sectionRef.current) {
      return;
    };
    let h = sectionRef.current.clientHeight;
    if (h < props.height) {
      height.current = `${props.height}px`;
    } else {
      height.current = undefined;
    };
  }, [sectionRef, props.height]);
  //--------------------------------------------------------------------------
  return (
    <div
      ref={sectionRef}
      id={props.id}
      className={CombineStyles([
        styles.container,
        screenType == ScreenType.Mobile ? styles.mobile : ''
      ])}
      style={{ minHeight: height.current }}
    >
      {props.caption &&
        <EditFormSectionHeader caption={props.caption} />}

      <div className={styles.content}>
        {props.children}
      </div>

    </div>
  );
}