import { IDirectoryDraftAbstract, IDirectorySummary, IDraftStateResult } from "../../../../../context/directory-editor-context/IDirectoryDraftAbstract";
import { IDirectoryOriginalAbstract } from "../../../../../context/IDirectoryOriginalAbstract";
import { ILocker } from "../../../../common/locks/TLocker";
import { ITranslation } from "../../../common/directory-translator/translation-editor/ITranslation";
import { IWorkflowLog, WorkflowLog } from "../../../common/workflows/IWorkflowLog";
import { ClassContentDirectoryDraftItem, IContentDirectoryDraftItem } from "./IContentDirectoryDraftItem";

export interface IContentDirectoryDraft extends IDirectoryDraftAbstract {
  directoryId: string;
  locale: string;
  items: IContentDirectoryDraftItem[];
}

export class ContentDirectoryDraft implements IContentDirectoryDraft {
  id!: string;
  directoryId!: string;
  locale!: string;
  items!: ClassContentDirectoryDraftItem[];
  name?: ITranslation;
  description?: ITranslation;
  log?: WorkflowLog;
  locker?: ILocker;
  summary!: IDirectorySummary;
  directoryTranslation?: IDirectoryOriginalAbstract;
  original?: IDirectoryOriginalAbstract;
  rootIsLocked?: boolean;
  isHierarchical?: boolean;
  isSortedManually?: boolean;
  //---------------------------------------------------------------------------
  initialize(source: IContentDirectoryDraftInitial): ContentDirectoryDraft {
    const draft = new ContentDirectoryDraft();
    Object.assign(draft, source);
    draft.id = source.directoryId;
    //-------------------------------------------------------------------------
    draft.items = source.items.map(item => new ClassContentDirectoryDraftItem(item));
    //-------------------------------------------------------------------------
    draft.log = new WorkflowLog(source.log);
    return draft;
  };
  //---------------------------------------------------------------------------
  getUpdated(update: IContentDirectoryDraftUpdate): IDirectoryDraftAbstract {
    const updatedDraft = new ContentDirectoryDraft();
    Object.assign(updatedDraft, this, update);
    return updatedDraft;
  };
  //---------------------------------------------------------------------------
  checkChangesAndValidate(initial: IDirectoryDraftAbstract): IDraftStateResult {
      let isUpdatedInSession = false;
      let isUpdated = false;
      let isValid = true;
      //-------------------------------------------------------------------------
      if (this.items.length !== initial.items?.length) {
        isUpdatedInSession = true;
      };
      //-------------------------------------------------------------------------
      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i];
        const initialItem = initial.items.find(initialItem => initialItem.id === item.id) as IContentDirectoryDraftItem;
        const draftStateResult: IDraftStateResult = item.checkChangesAndValidate(initialItem);
        isUpdatedInSession = isUpdatedInSession || draftStateResult.isUpdatedInSession;
        isUpdated = isUpdated || draftStateResult.isUpdated;
        isValid = isValid && draftStateResult.isValid;
      };
      //-------------------------------------------------------------------------
      return {
        isUpdatedInSession,
        isUpdated,
        isValid
      };
  };
  //---------------------------------------------------------------------------
  // getCopyForSaving(): IContentDirectoryDraft {
  //   const draft = new ContentDirectoryDraft();
  //   Object.assign(draft, this);
  //   const draftForSaving: IContentDirectoryDraft = draft as IContentDirectoryDraft;
  //   const items = this.items.map(item => ({...item, itemOriginal: undefined} as IContentDirectoryDraftItem));
  //   draftForSaving.items = items;
  //   return draftForSaving;
  // }
  //---------------------------------------------------------------------------
  clone(): IDirectoryDraftAbstract {
    throw new Error("Method not implemented.");
  };
}


export interface IContentDirectoryDraftInitial {
  directoryId: string;
  locale: string;
  name?: ITranslation;
  description?: ITranslation;
  items: IContentDirectoryDraftItem[];
  //access?: IAccess;
  //original?: IDocumentOriginal;
  log: IWorkflowLog;
}

export interface IContentDirectoryDraftUpdate {
  //name?: string | ITranslation;
  //description?: string | ITranslation;
  items?: ClassContentDirectoryDraftItem[];
};