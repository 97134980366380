import { IDirectoryDraftAbstract, IDirectorySummary, IDraftStateResult } from "../../../../../context/directory-editor-context/IDirectoryDraftAbstract";
import { ILocker } from "../../../../common/locks/TLocker";
import { IAccess } from "../../../common/input-access/IAccess";
import { IWorkflowLog, WorkflowLog } from "../../../common/workflows/IWorkflowLog";
import { IReadableDocument } from "../../../../document-viewer/IReadableDocument";
import { DocumentDraftItem, IDocumentDraftItem } from "./IDocumentDraftItem";

export type EDocumentType = "Legal" | "Manual";

export interface IDocumentDraft extends IReadableDocument, IDirectoryDraftAbstract {
  documentType: EDocumentType;
  documentId: string;
  locale: string;
  title?: string;
  description?: string;
  access?: IAccess;
  items: DocumentDraftItem[];
}

export class DocumentDraft implements IDocumentDraft {
  id!: string;
  documentType!: EDocumentType;
  documentId!: string;
  locale!: string;
  title?: string;
  description?: string;
  name?: string;
  log?: WorkflowLog;
  locker?: ILocker;
  summary!: IDirectorySummary;
  access?: IAccess;
  original?: any;
  //rootIsLocked?: boolean;
  isHierarchical: boolean = true;
  //isSortedManually?: boolean;
  items!: DocumentDraftItem[];
  //---------------------------------------------------------------------------
  initialize(source: IDocumentDraftInitial): DocumentDraft {
    const draft = new DocumentDraft();
    Object.assign(draft, source);
    draft.id = source.documentId;
    draft.name = source.title;
    //-------------------------------------------------------------------------
    draft.items = source.items.map(item => new DocumentDraftItem(item));
    //-------------------------------------------------------------------------
    draft.log = new WorkflowLog(source.log);
    return draft;
  };
  //---------------------------------------------------------------------------
  getUpdated(update: IDocumentDraftUpdate): DocumentDraft {
    const updatedDraft = new DocumentDraft();
    Object.assign(updatedDraft, this, update);
    updatedDraft.updateTitle();
    
    return updatedDraft;
  };
  //---------------------------------------------------------------------------
  checkChangesAndValidate(initial: IDirectoryDraftAbstract): IDraftStateResult {
    let isUpdatedInSession = false;
    let isUpdated = false;
    let isValid = true;
    //-------------------------------------------------------------------------
    if (this.items.length !== initial.items?.length) {
      isUpdatedInSession = true;
    };
    //-------------------------------------------------------------------------
    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i];
      const initialItem = initial.items.find(initialItem => initialItem.id === item.id) as IDocumentDraftItem;
      const draftStateResult: IDraftStateResult = item.checkChangesAndValidate(initialItem);
      isUpdatedInSession = isUpdatedInSession || draftStateResult.isUpdatedInSession;
      isUpdated = isUpdated || draftStateResult.isUpdated;
      isValid = isValid && draftStateResult.isValid;
    };
    //-------------------------------------------------------------------------
    return {
      isUpdatedInSession,
      isUpdated,
      isValid
    };
  };
  //---------------------------------------------------------------------------
  clone(): DocumentDraft {
    throw new Error("Method not implemented.");
  };
  //---------------------------------------------------------------------------
  updateTitle(): void {
    const titleItem = this.items.find(item => item.type === "Title");
    this.title = titleItem?.header?.text;
    this.description = titleItem?.text?.text;
  };
}

export interface IDocumentDraftInitial {
  documentType: EDocumentType;
  documentId: string;
  localeId: string;
  title?: string;
  description?: string;
  items: DocumentDraftItem[];
  access?: IAccess;
  //original?: IDocumentOriginal;
  log: IWorkflowLog;
}

export interface IDocumentDraftUpdate {
  title?: string;
  description?: string;
  access?: IAccess;
}