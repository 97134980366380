import React, { ReactNode } from 'react';
import { ClassMessageContent } from '../../Messages';
import styles from './MessageBody.module.css';

interface IProps {
  data: ClassMessageContent;
}

//Regex to test on regex101: \[.[^\[\]]*\]\(.[^\(\)]*\)
const regexReference = new RegExp("\\[.[^\\[\\]]*\\]\\(.[^\\(\\)]*\\)", "g");
const regexLinkText = new RegExp("\\[.[^\\[\\]]*\\]");
const regexLinkRef = new RegExp("\\(.[^\\(\\)]*\\)");

const replaceReference = (text: string): ReactNode => {
  const references = text.match(regexReference);
  let content: ReactNode[] = [];
  let stringToProcess = text;
  let i = 0;
  references?.forEach(reference => {
    const textParts = stringToProcess.split(reference);
    stringToProcess = textParts[1];
    //-------------------------------------------------------------------------
    const linkText = reference.match(regexLinkText)?.[0].replace('[', '').replace(']', '');
    const linkRef = reference.match(regexLinkRef)?.[0].replace('(', '').replace(')', '');
    //console.log(reference, textParts, linkText, linkRef)
    content.push(
      <span key={i}>
        {textParts[0]}
      </span>
    );
    i++;
    content.push(
      <a key={i} href={linkRef}>
        {linkText}
      </a>
    );
    i++;
  });
  content.push(
    <span key={i}>
      {stringToProcess}
    </span>
  );
  return content;
}

export default function MessageBody(props: IProps) {
  //console.log(props.data.body)
  //---------------------------------------------------------------------------
  const paragraphs = props.data.body.map((item, index) => (
    <p
      key={index}
      className={styles.paragraph}>
      {replaceReference(item)}
    </p>
  ));
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>

      <p className={styles.greeting}>
        {props.data.greeting}
      </p>

      {paragraphs}

      <p className={styles.signature}>
        {props.data.signature}
      </p>

    </div>
  );
}