import { ReactNode } from "react";
import { IAdCampaign } from "../../../../context/business-context/IAdCampaign";
import { EPageViewType } from "../../../../utils/tracker";
import { TImageData } from "../../../common/image-editor/TImageData";
import { PageViewCardRow } from "./pageview-card/PageViewCardRow";
import { IListColumn } from "../../../common/lists/IListColumn";
import { IFilterDynamicOptions } from "../../../../context/list-manager-context/IListManagerContextData";
import { IFilterItemOption } from "../../../common/lists/filter-editor/IFilterItem";

//----------------------------------------------------------------------------
export interface IPageViewRow {
  id: string;
  caption: string;
  enabled: boolean;
  views?: number;
  points?: number;
  children?: IPageViewRow[];
  uiChildren?: ReactNode;
  optionId: string;
  option: IFilterItemOption;
  filterColumnId: string;
  localeId?: string;
  filteredChildrenCount: number;
}
//--------------------------------------------------------------------------
function getPageViewRowsUi(source?: IPageViewRow[]) {
  return source?.map(item => {
    return item.enabled && item.filteredChildrenCount != 0 ? <PageViewCardRow
      key={item.id}
      text={item.caption}
      points={item.points}
      views={item.views}
      children={item.uiChildren}
    />
      : undefined;
  });
}
//--------------------------------------------------------------------------
function filteredChildrenCount(source: IPageViewRow[]): number {
  let n = 0;
  source.forEach(item => {
    if (item.enabled && item.filteredChildrenCount != 0)
      n++;
  });
  return n;
}
//----------------------------------------------------------------------------
export class PageViewFilter {
  flatArray: IPageViewRow[] = [];
  locales: LanguagePageViews[] = [];
  //--------------------------------------------------------------------------
  constructor(source?: BusinessPageViews[]) {
    if (source) {
      this.fillFlatArray(source);
      this.flatArray.forEach(item => {
        if (!item.localeId) 
          return;
        if (this.locales.findIndex(l => l.localeId == item.localeId) >= 0)
          return;
        let locale = new LanguagePageViews();
        locale.localeId = item.localeId;
        this.locales.push(locale);
      });
    } 
  }
  //--------------------------------------------------------------------------
  clone(): PageViewFilter {
    let result = new PageViewFilter();
    result.flatArray = this.flatArray;
    result.locales = this.locales;
    return result;
  }
  //--------------------------------------------------------------------------
  private fillFlatArray(source: IPageViewRow[]) {
    source.forEach(item => {
      this.flatArray.push(item);
      item.children && this.fillFlatArray(item.children);
    })
  }
  //--------------------------------------------------------------------------
  getDynamicOptions(): IFilterDynamicOptions[] {
    let dest: IFilterDynamicOptions[] = [];
    this.flatArray.forEach(item => {
      let dynamicOptions = dest.find(x => x.columnId == item.filterColumnId);
      if (!dynamicOptions) {
        dynamicOptions = {
          columnId: item.filterColumnId,
          options: [],
        };
        dest.push(dynamicOptions);
      }
      if (dynamicOptions.options.findIndex(o => o.id == item.optionId) < 0)
        dynamicOptions.options.push(item.option);
    });
    if (this.locales.length > 0) {
      let dynamicOptions: IFilterDynamicOptions = {
        columnId: LanguagePageViews.columnId,
        options: this.locales.map(l => l.option)
      }
      dest.push(dynamicOptions);
    }
    return dest;
  }
  //--------------------------------------------------------------------------
  enableAll() {
    this.flatArray.forEach(item => item.enabled = true);
  }
  //--------------------------------------------------------------------------
  setFilter(columnId: string, selectedOptions: IFilterItemOption[]) {
    if (columnId == LanguagePageViews.columnId) {
      this.flatArray.forEach(item => {
        if (item.localeId)
          item.enabled = selectedOptions.findIndex(option => option.id == item.localeId) >= 0;
      });
    }
    else {
      this.flatArray.forEach(item => {
        if (item.filterColumnId == columnId)
          item.enabled = selectedOptions.findIndex(option => option.id == item.id) >= 0;
      });
    }
  }
}
//----------------------------------------------------------------------------
export class PageViewType implements IPageViewRow {
  pageViewType!: EPageViewType;
  text!: string;
  enabled: boolean = true;
  filteredChildrenCount = -1;
  //--------------------------------------------------------------------------
  constructor(source: any) {
    Object.assign(this, source);
  }
  //------------------------------------------
  private _views!: number;
  get views(): number {
    return this.enabled ? this._views : 0;
  }
  set views(value: number) {
    this._views = value;
  }
  //------------------------------------------
  private _points!: number;
  get points() {
    return this.enabled ? this._points : 0;
  }
  set points(value: number) {
    this._points = value;
  }
  //--------------------------------------------------------------------------
  get id(): string {
    return this.pageViewType;
  }
  //--------------------------------------------------------------------------
  get caption(): string {
    return this.pageViewType;
  }
  //--------------------------------------------------------------------------
  static columnId = "pageViewTypes";
  static columnName = "Pageview types";
  get filterColumnId(): string {
    return PageViewType.columnId;
  }
  static initialColumn: IListColumn = {
    id: PageViewType.columnId,
    name: PageViewType.columnName,
    filter: {
      type: "Options"
    }
  };
  //--------------------------------------------------------------------------
  get optionId(): string {
    return this.pageViewType;
  }
  //--------------------------------------------------------------------------
  get option(): IFilterItemOption {
    return {
      id: this.pageViewType,
      name: this.pageViewType,
      isDynamic: true,
    };
  }
}

//----------------------------------------------------------------------------
export class AdLanguageSetPageViews implements IPageViewRow {
  adLanguageSetId!: string;
  localeId!: string;
  enabled: boolean = true;
  //--------------------------------------------------------------------------
  constructor(source: any) {
    Object.assign(this, source);
  }
  //--------------------------------------------------------------------------
  private _pageViewTypes!: PageViewType[];
  get pageViewTypes(): PageViewType[] {
    return this._pageViewTypes;
  }
  set pageViewTypes(value: any[]) {
    this._pageViewTypes = value?.map(item => new PageViewType(item));
  }
  //--------------------------------------------------------------------------
  get views(): number {
    let result = 0;
    this.enabled && this._pageViewTypes?.forEach(item => result += item.views);
    return result;
  }
  //--------------------------------------------------------------------------
  get points(): number {
    let result = 0;
    this.enabled && this._pageViewTypes?.forEach(item => result += item.points);
    return result;
  }
  //--------------------------------------------------------------------------
  get id(): string {
    return this.adLanguageSetId;
  }
  //--------------------------------------------------------------------------
  get caption(): string {
    return this.localeId;
  }
  //--------------------------------------------------------------------------
  get children(): IPageViewRow[] | undefined {
    return this._pageViewTypes;
  }
  //--------------------------------------------------------------------------
  get uiChildren(): ReactNode | undefined {
    return getPageViewRowsUi(this._pageViewTypes);
  }
  //--------------------------------------------------------------------------
  get filteredChildrenCount(): number {
    return filteredChildrenCount(this._pageViewTypes);
  }
  //--------------------------------------------------------------------------
  static columnId = "Ads"
  static columnName = AdLanguageSetPageViews.columnId;
  get filterColumnId(): string {
    return AdLanguageSetPageViews.columnId;
  }
  static initialColumn: IListColumn = {
    id: AdLanguageSetPageViews.columnId,
    name: AdLanguageSetPageViews.columnName,
    filter: {
      type: "Options"
    }
  };
  //--------------------------------------------------------------------------
  get optionId(): string {
    return this.adLanguageSetId;
  }
  //--------------------------------------------------------------------------
  get option(): IFilterItemOption {
    return {
      id: this.adLanguageSetId,
      name: this.adLanguageSetId,
      isDynamic: true,
    };
  }
}
//----------------------------------------------------------------------------
export class LanguagePageViews implements IPageViewRow {
  localeId!: string;
  enabled: boolean = true;
  filteredChildrenCount: number = -1;
  private _pageViewTypes: PageViewType[] = [];
  addPageViewTypes(pageViewTypes: PageViewType[]) {
    pageViewTypes.forEach(item => {
      let thisType = this._pageViewTypes.find(x => x.pageViewType == item.pageViewType);
      if (!thisType) {
        this._pageViewTypes.push(new PageViewType(item));
      }
      else {
        thisType.points += item.points;
        thisType.views += item.views;
      }
    })
  }
  //--------------------------------------------------------------------------
  get views(): number {
    let result = 0;
    this.enabled && this._pageViewTypes?.forEach(item => result += item.views);
    return result;
  }
  //--------------------------------------------------------------------------
  get points(): number {
    let result = 0;
    this.enabled && this._pageViewTypes?.forEach(item => result += item.points);
    return result;
  }
  //--------------------------------------------------------------------------
  get id(): string {
    return this.localeId;
  }
  //--------------------------------------------------------------------------
  get caption(): string {
    return this.localeId;
  }
  //--------------------------------------------------------------------------
  get children(): IPageViewRow[] | undefined {
    return this._pageViewTypes;
  }
  //--------------------------------------------------------------------------
  get uiChildren(): ReactNode | undefined {
    return undefined;//getPageViewRowsUi(this._pageViewTypes);
  }
  //--------------------------------------------------------------------------
  static columnId = "Languages"
  static columnName = LanguagePageViews.columnId;
  get filterColumnId(): string {
    return LanguagePageViews.columnId;
  }
  static initialColumn: IListColumn = {
    id: LanguagePageViews.columnId,
    name: LanguagePageViews.columnName,
    filter: {
      type: "Options"
    }
  };
  //--------------------------------------------------------------------------
  get optionId(): string {
    return this.localeId;
  }
  //--------------------------------------------------------------------------
  get option(): IFilterItemOption {
    return {
      id: this.localeId,
      name: this.localeId,
      isDynamic: true,
    };
  }

}
//----------------------------------------------------------------------------
export class AdCampaignPageViews implements IPageViewRow {
  adCampaignId!: string;
  adCampaign!: IAdCampaign;
  enabled: boolean = true;
  //--------------------------------------------------------------------------
  constructor(source: any) {
    Object.assign(this, source);
  }
  //--------------------------------------------------------------------------
  private _adLanguageSetPageViews!: AdLanguageSetPageViews[];
  get adLanguageSetPageViews(): AdLanguageSetPageViews[] {
    return this._adLanguageSetPageViews;
  }
  set adLanguageSetPageViews(value: any[]) {
    this._adLanguageSetPageViews = value?.map(item => new AdLanguageSetPageViews(item));
  }
  //--------------------------------------------------------------------------
  get views(): number {
    let result = 0;
    this.enabled && this._adLanguageSetPageViews?.forEach(item => result += item.views);
    return result;
  }
  //--------------------------------------------------------------------------
  get points(): number {
    let result = 0;
    this.enabled && this._adLanguageSetPageViews?.forEach(item => result += item.points);
    return result;
  }
  //--------------------------------------------------------------------------
  get id(): string {
    return this.adCampaignId;
  }
  //--------------------------------------------------------------------------
  get caption(): string {
    return this.adCampaign?.name ?? this.adCampaignId;
  }
  //--------------------------------------------------------------------------
  get children(): IPageViewRow[] | undefined {
    return this._adLanguageSetPageViews;
  }
  //--------------------------------------------------------------------------
  get uiChildren(): ReactNode | undefined {
    return getPageViewRowsUi(this._adLanguageSetPageViews);
  }
  //--------------------------------------------------------------------------
  get filteredChildrenCount(): number {
    return filteredChildrenCount(this._adLanguageSetPageViews);
  }
  //--------------------------------------------------------------------------
  static columnId = "adCampaigns";
  static columnName = "Ad campaigns";
  get filterColumnId(): string {
    return AdCampaignPageViews.columnId;
  }
  static initialColumn: IListColumn = {
    id: AdCampaignPageViews.columnId,
    name: AdCampaignPageViews.columnName,
    filter: {
      type: "Options"
    }
  };
  //--------------------------------------------------------------------------
  get optionId(): string {
    return this.adCampaignId;
  }
  //--------------------------------------------------------------------------
  get option(): IFilterItemOption {
    return {
      id: this.adCampaignId,
      name: this.caption,
      isDynamic: true,
    };
  }
}

//----------------------------------------------------------------------------
export class BusinessPageViews implements IPageViewRow {
  businessId!: string;
  businessName!: string;
  enabled: boolean = true;
  //--------------------------------------------------------------------------
  constructor(source: any) {
    Object.assign(this, source);
    let newPageViews: AdCampaignPageViews[] = [];
    this._adCampaignPageViews?.forEach(item => {
      //if (item.adCampaign?.name)
      if (item.caption)
        newPageViews.push(item);
    });
    this._adCampaignPageViews = newPageViews;
  }
  //--------------------------------------------------------------------------
  private _logo!: TImageData;
  get logo(): TImageData {
    return this._logo;
  }
  set logo(value: any) {
    if (value)
      this._logo = new TImageData(value);
  }
  //--------------------------------------------------------------------------
  private _adCampaignPageViews!: AdCampaignPageViews[];
  get adCampaignPageViews(): AdCampaignPageViews[] {
    return this._adCampaignPageViews;
  }
  set adCampaignPageViews(value: any[]) {
    this._adCampaignPageViews = value?.map(item => new AdCampaignPageViews(item));
  }
  //--------------------------------------------------------------------------
  get views(): number {
    let result = 0;
    this.enabled && this._adCampaignPageViews?.forEach(item => result += item.views);
    return result;
  }
  //--------------------------------------------------------------------------
  get points(): number {
    let result = 0;
    this.enabled && this._adCampaignPageViews?.forEach(item => result += item.points);
    return result;
  }
  //--------------------------------------------------------------------------
  get id(): string {
    return this.businessId;
  }
  //--------------------------------------------------------------------------
  get caption(): string {
    return "Ad Campaigns";
  }
  //--------------------------------------------------------------------------
  static columnId = "Businesses";
  static columnName = BusinessPageViews.columnId;
  get filterColumnId(): string {
    return BusinessPageViews.columnId;
  }
  static initialColumn: IListColumn = {
    id: BusinessPageViews.columnId,
    name: BusinessPageViews.columnName,
    filter: {
      type: "Options"
    }
  };
  //--------------------------------------------------------------------------
  get optionId(): string {
    return this.businessId;
  }
  //--------------------------------------------------------------------------
  get option(): IFilterItemOption {
    return {
      id: this.businessId,
      name: this.businessName,
      isDynamic: true,
    };
  }
  //--------------------------------------------------------------------------
  get children(): IPageViewRow[] | undefined {
    return this._adCampaignPageViews;
  }
  //--------------------------------------------------------------------------
  get uiChildren(): ReactNode | undefined {
    return (
      this.filteredChildrenCount > 0 ? <PageViewCardRow
        text={`Ad Campaigns (${this.filteredChildrenCount})`}
        points={this.points}
        views={this.views}
        children={getPageViewRowsUi(this._adCampaignPageViews)}
      />
        : undefined
    );
  }
  //--------------------------------------------------------------------------
  get filteredChildrenCount(): number {
    return filteredChildrenCount(this._adCampaignPageViews);
  }
  //--------------------------------------------------------------------------
  static fromArray(source: any[]): BusinessPageViews[] {
    return source?.map(item => new BusinessPageViews(item));
  }
}
