import { IAppUiContext } from "../../../context/app-ui-context/AppUiContextProvider";
import { IListManagerContextData } from "../../../context/list-manager-context/IListManagerContextData";
import { apiPost, checkResponse } from "../../../utils/api";
import { apiBasePath, searchBusinessDetails } from "../../../utils/apiPathConstant";
import { IListItem } from "../../business-space/business-editor/Business";
import { IBusinessMarker } from "../../common/google-maps/business-markers/BusinessMarkers";
import { IUiListItem } from "../../common/input/input-dropdown/InputDropdown";
import { IListColumn } from "../../common/lists/IListColumn";
import { ISortStep } from "../../common/lists/sorter-editor/ISorterItem";
import { TGeoPoint, TPolygonArea } from "../../common/map/geo-json/GeoJson"
import { UiTagTreeNode } from "../TagTree";
import { IBusinessSearchParam, IBusinessSearchParams } from "../business-search-editor/IBusinessSearchParams";
import { SearchColumnIds, initialSearchListColumns } from "./Filters";
import { BusinessSearchCard } from "./business-search-card/BusinessSearchCard";
import { BusinessSearchListItem } from "./business-search-card/BusinessSearchListItem"

const logging = false;

export interface ILocationAreaParam extends IBusinessSearchParam {
  locationArea?: TPolygonArea;
}

export class BusinessSearchQuery {
  businessIds?: string[];
  tags: string[] = ["mainCategory"];
  tagTree?: IListItem;
  locale?: string;
  resultsPerPage: number = 10;
  populateMetrics: boolean = true;
  populateIndexMetrics: boolean = false;
  area?: TPolygonArea;
  center?: TGeoPoint;
  pointForDistance?: TGeoPoint;
  searchRadius?: number;
  //-----------------------------------------------------------------
  constructor(params?: IBusinessSearchParams, locale?: string, businessIds?: string[]) {
    this.locale = locale;
    if (params) {
      //this.tagTree = params.tagTree;
      if (params.locationArea && params.useLocationArea)
        this.area = params.locationArea;
      this.center = this.area?.center;
      if (this.center) {
        this.searchRadius = 2000000;
      }
      this.tags = params.tags;
    }
    this.businessIds = businessIds;
  }
  //-----------------------------------------------------------------
  get tagLeafCount() {
    let result = 0;
    this.tags.forEach(tag => {
      if (tag.includes("."))
        result++;
    });
    return result;
  }
}
//-----------------------------------------------------------------
export class BusinessSearchResult {
  metrics: any;
  tagsInQuery!: number;
  query!: BusinessSearchQuery;
  showMarkersForPageOnly: boolean = true;
  businessesPerPage: number = 5;
  locales: string[] = [];
  //-----------------------------------------------------------------
  private _ui?: IUiListItem[];
  private _frame?: TPolygonArea;
  private _items: BusinessSearchListItem[] = [];
  private _itemsFiltered: BusinessSearchListItem[] = [];
  //-----------------------------------------------------------------
  private _pageIndex: number = 0;
  private _pageStartIndex: number = 0;
  private _pageEndIndex: number = this._pageStartIndex + this.businessesPerPage - 1;
  get currentPageIndex() { return this._pageIndex; }
  set currentPageIndex(value: number) {
    let i1 = value * this.businessesPerPage;
    if (i1 >= this._itemsFiltered.length) {
      //logging && console.log("i1 >= this._items.length:", i1, this._itemsFiltered.length);
      return;
    }
    let i2 = i1 + this.businessesPerPage - 1;
    if (i2 >= this._itemsFiltered.length)
      i2 = this._itemsFiltered.length - 1;
    this._pageStartIndex = i1;
    this._pageEndIndex = i2;
    this._pageIndex = value;
    //logging && console.log(`pageIndex: ${this._pageIndex}, start: ${i1}, end: ${i2}`);
  }
  //-----------------------------------------------------------------
  constructor(
    source: any,
    query: BusinessSearchQuery,
    ui?: IUiListItem[]
  ) {
    this._ui = ui;
    this.query = query;
    this.tagsInQuery = query.tagLeafCount;
    BusinessSearchListItem.tagsInQuery = this.tagsInQuery;
    Object.assign(this, source);
    this.updateLocales();
  }
  //-----------------------------------------------------------------
  private updateLocales() {
    this.locales = [];
    this._items.forEach(item => {
      item.locales?.forEach(locale => {
        if (this.locales.indexOf(locale) < 0)
          this.locales.push(locale);
      })
    })
  }
  //------------------------------------------------------------------------
  mergeLocations() {
    if (this._items.length == 0)
      return;
    let newItems: BusinessSearchListItem[] = [];
    for (let i = 0; i < this._items.length; i++) {
      let item = this._items[i];
      if (newItems.find(newItem => newItem.businessId == item.businessId))
        continue;
      item.locations = [];
      if (item.location) {
        item.locations.push(item.location);
      }
      newItems.push(item);
      for (let j = i + 1; j < this._items.length; j++) {
        let newItem = this._items[j];
        if (newItem.businessId == item.businessId && newItem.location) {
          item.locations.push(newItem.location);
        }
      }
      item.locations.sort((l1, l2) => l1.compareDistance(l2))
    }
    this.items = newItems;
  }
  //-----------------------------------------------------------------
  get frame(): TPolygonArea | undefined {
    return this._frame;
  }
  set frame(value: TPolygonArea | undefined) {
    if (value) {
      this._frame = new TPolygonArea(value);
    }
    else {
      this._frame = undefined;
    }
  }
  //-----------------------------------------------------------------
  get items(): BusinessSearchListItem[] {
    return this._items;
  }
  set items(value: any) {
    if (value) {
      this._items = BusinessSearchListItem.fromArray(value, this._ui)
    }
    else {
      this.items = [];
    }
    this._itemsFiltered = this._items;
  }
  //-----------------------------------------------------------------
  get itemsFiltered(): BusinessSearchListItem[] {
    return this._itemsFiltered;
  }
  //----------------------------------------------------------------------------
  get businessesFrame(): google.maps.LatLngBoundsLiteral | undefined {
    if (this._items.length == 0) {
      return undefined;
    }
    let east: number | undefined = undefined;
    let west: number | undefined = undefined;
    let north: number | undefined = undefined;
    let south: number | undefined = undefined;
    this._items.forEach(item => {
      let locations = item.locations;
      if ((!locations || locations.length == 0) && item.location)
        locations = [item.location];
      locations?.forEach(p => {
        if (!north || p.lat > north)
          north = p.lat;
        if (!south || p.lat < south)
          south = p.lat;
        if (!east || p.lng > east)
          east = p.lng;
        if (!west || p.lng < west)
          west = p.lng;
      });
    });
    if (east && west && north && south)
      return { east, west, north, south }
    return undefined;
  }
  //------------------------------------------------------------------------
  getBusinessMarkers(appUiContext: IAppUiContext): IBusinessMarker[] {
    if (!this.itemsFiltered)
      return [];
    let result: IBusinessMarker[] = [];
    let i1: number;
    let i2: number;
    if (this.showMarkersForPageOnly) {
      i1 = this._pageStartIndex;
      i2 = this._pageEndIndex;
    }
    else {
      i1 = 0;
      i2 = this.itemsFiltered.length - 1;
    }
    for (let i = i1; i <= i2; i++)
      //result.push(this.itemsFiltered[i].getBusinessMarker(appUiContext));
      this.itemsFiltered[i].pushBusinessMarkers(appUiContext, result);
    return result;
  }
  //------------------------------------------------------------------------
  addBusinessDetails(details: BusinessSearchResult) {
    details._items.forEach(itemDetails => {
      let thisItems = this.items.filter(item => item.businessId == itemDetails.businessId);
      thisItems?.forEach(thisItem => thisItem.fillDetails(itemDetails));
    });
    // details._items.forEach(itemDetails => {
    //   let thisItem = this.items.find(item => item.businessId == itemDetails.businessId);
    //   if (thisItem) {
    //     thisItem.fillDetails(itemDetails);
    //   }
    // });
    this.updateLocales();
  }
  //------------------------------------------------------------------------
  sort(sortSteps: ISortStep[]) {
    for (let sortStep of sortSteps) {
      this._itemsFiltered.sort((i1, i2) => i1.compareToV2(i2, sortStep));
    }
  }
  //------------------------------------------------------------------------
  sortBasic(column: IListColumn) {
    console.log("BusinessSearchResult.sortBasic");
    let initialColumn = initialSearchListColumns.find(item => item.id == column.id);
    if (!initialColumn?.sorter?.sort) {
      return;
    }
    let sortStep: ISortStep = {
      propertyName: column.id,
      sortType: initialColumn.sorter?.sort
    };
    this._itemsFiltered.sort((i1, i2) => i1.compareToV2(i2, sortStep));
  }
  //------------------------------------------------------------------------
  sortBasicV2(sortSteps: ISortStep[], column: IListColumn) {
    console.log("BusinessSearchResult.sortBasic");
    if (!column.sorter?.sort)
      return;
    let initialColumn = initialSearchListColumns.find(item => item.id == column.id);
    if (!initialColumn?.sorter?.sort) {
      return;
    }
    let sortStepIndex = sortSteps.findIndex(item => item.propertyName == column.id);
    if (sortStepIndex < 0) {
      sortSteps.push({
        propertyName: column.id,
        sortType: column.sorter.sort
      });
    }
    else if (sortStepIndex < sortSteps.length - 1) {
      // move sort step to last position
      let tmp = sortSteps[sortSteps.length - 1];
      sortSteps[sortSteps.length - 1] = sortSteps[sortStepIndex];
      sortSteps[sortStepIndex] = tmp;
    }
    this.sort(sortSteps);
    this.currentPageIndex = 0;
    logging && console.log("BusinessSearchResult.sortBasicV2 completed");
  }
  //------------------------------------------------------------------------
  filter(columns: IListColumn[]) {
    this._itemsFiltered = this._items;
    columns.forEach(column => {
      const selectedOptions = column.filter?.options?.filter(fi => fi.isSelected);
      if (!selectedOptions || selectedOptions.length == 0) {
        return;
      }
      logging && console.log("filter.selectedOptions:", selectedOptions);
      switch (column.id) {
        //---------------------------------------------------------------------
        case SearchColumnIds.locale.id:
          const locales = selectedOptions.map(o => o.id);
          logging && console.log("filter.locales:", locales);
          if (locales.length > 0) {
            this._itemsFiltered = this._itemsFiltered.filter(item =>
              locales.filter(locale => item.locales.includes(locale)).length > 0);
            this._itemsFiltered.forEach(item => item.filterLocales(locales));
          }

          //logging && console.log("filter._itemsFiltered:", this._itemsFiltered);
          break;
      };
    });
  }
  //------------------------------------------------------------------------
  filterRemove() {
    this._itemsFiltered = this._items;
    this._items.forEach(item => item.filterLocales(item.locales));
  }
  //------------------------------------------------------------------------
  filterAndSort(context: IListManagerContextData) {
    this.filter(context.filterableColumns);
    this.sort(context.sortSteps);
    this.currentPageIndex = 0;
    logging && console.log("BusinessSearchResult.filterAndSort completed");
  }
  //------------------------------------------------------------------------
  getBusinessesForPage() {
    let result: BusinessSearchListItem[] = [];
    if (this._itemsFiltered.length > 0) {
      for (let i = this._pageStartIndex; i <= this._pageEndIndex; i++) {
        let item = this._itemsFiltered[i];
        if (!item.detailsLoaded) {
          continue;
        }
        result.push(item);
      }
    }
    //logging && console.log("getBusinessesForPage:", this._pageStartIndex, this._pageEndIndex, result);
    return result;
  }
  //------------------------------------------------------------------------
  getBusinessSearchCards(percents: boolean, onHover?: (id: string) => void) {
    //----------------------------------------------------------------------
    let result: JSX.Element[] = [];
    for (let i = this._pageStartIndex; i <= this._pageEndIndex; i++) {
      let item = this._itemsFiltered[i];
      if (!item.detailsLoaded) {
        continue;
      }
      result.push(<BusinessSearchCard
        key={item.uiId}
        data={item}
        percents={percents}
        onHover={onHover}
      />
      )
    }
    return result;
  }
  //------------------------------------------------------------------------
  getBusinessIdsForPageWithoutDetails(
    pageIndex: number, perPage: number): string[] | undefined {
    //----------------------------------------------------------------------
    logging && console.log("getBusinessIdsForPageWithoutDetails:", pageIndex, perPage);
    //----------------------------------------------------------------------
    let i1 = pageIndex * perPage;
    if (i1 >= this._itemsFiltered.length) {
      return undefined;
    }
    let i2 = i1 + perPage - 1;
    if (i2 >= this._itemsFiltered.length)
      i2 = this._itemsFiltered.length - 1;

    let result: string[] = [];
    for (let i = i1; i <= i2; i++) {
      let item = this._itemsFiltered[i];
      if (!item.detailsLoaded && result.indexOf(item.businessId) < 0)
        result.push(item.businessId);
    }
    return result.length > 0 ? result : undefined;
  }
  //------------------------------------------------------------------------
  getPageCount(perPage: number): number {
    let n = 0;
    let c = 0;
    this._itemsFiltered.forEach(() => {
      c++;
      if (c == perPage) {
        n++;
        c = 0;
      }
    });
    if (n * perPage < this._itemsFiltered.length)
      n++;
    return n;
  }
  //------------------------------------------------------------------------
  async queryBusinessDetails(businessIds: string[]): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (!this.query) {
        return reject("queryBusinessDetails: Trying to get business details while query is undefined");
      }
      this.query.businessIds = businessIds;
      //logging && console.log("queryBusinessDetails.query:", this.query);
      //-------------------------------------------------------------------------
      apiPost(
        `${apiBasePath}${searchBusinessDetails}`, this.query
      )
        .then((response) => {
          logging && console.log("queryBusinessDetails.response:", response);
          checkResponse(response, "queryBusinessDetails");
          //logging && console.log("queryBusinessDetails.response:", response.content);
          const details = new BusinessSearchResult(response.content, this.query);
          this.addBusinessDetails(details);
          resolve();
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }
  //------------------------------------------------------------------------
  isLocalesDiffer(locales?: string[]) {
    // both are empty
    if (this.locales.length == 0 && (!locales || locales.length == 0))
      return false;
    // length is different
    if (this.locales.length != locales?.length)
      return true;
    for (let i = 0; i < this.locales.length; i++) {
      let index = locales?.indexOf(this.locales[i]);
      if (index == undefined || index < 0)
        return true;
    }
    return false;
  }
}

function saveSearchParamsToLs(arg0: string) {
  throw new Error("Function not implemented.");
}

