import { ReadableDocumentItem } from '../IReadableDocument';
import ContentImage from '../../common/content/content-image/ContentImage';
import styles from './DocumentItem.module.css';
import { IHighlightedItem } from '../document-content/DocumentContent';
import { useRef } from 'react';

interface IProps {
  data: ReadableDocumentItem;
  numberToDisplay?: string;
  highligtedItem?: IHighlightedItem;
}

export default function DocumentParagraph(props: IProps) {
  const divRef = useRef<HTMLDivElement>(null);
  const isHighlighted = props.highligtedItem?.path == props.data.fullPath;
  const textIsHighlighted = props.highligtedItem?.path == `${props.data.fullPath}.text`;
  const imageIsHighlighted = props.highligtedItem?.path == `${props.data.fullPath}.image`;
  const color = props.highligtedItem?.color ? props.highligtedItem.color : '';
  if (isHighlighted) {
    divRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
  };
  //---------------------------------------------------------------------------
  let imageElement = undefined;
  if (props.data.image) {
    const dynamicStyles = imageIsHighlighted ? {
      border: `2px solid ${color}`,
    } : undefined;
    imageElement =
      <div
        className={styles.image}
        style={dynamicStyles}
      >
        <ContentImage
          data={{
            type: "Image",
            imageData: props.data.image.imageData
          }}
          isReadonly={true}
        />
      </div>;
  };
  //---------------------------------------------------------------------------
  let textElement = undefined;
  const text = props.numberToDisplay ?
    `${props.numberToDisplay} ${props.data.text?.text}` :
    props.data.text?.text;
  if (text) {
    textElement =
      <span style={{ color: textIsHighlighted ? color : '' }}>
        {text}
      </span>
  };
  //---------------------------------------------------------------------------
  if (props.data.isMarked) return (
    <div
      ref={divRef}
      className={styles.marked}
      style={{ color: isHighlighted ? color : '' }}
    >
      <div className={styles.marker}>
        ·
      </div>
      <div>
        {textElement}
        {imageElement}
      </div>
    </div>
  ); else return (
    <div
      ref={divRef}
      className={styles.container}
      style={{ color: isHighlighted ? color : '' }}
    >
      {textElement}
      {imageElement}
    </div>
  );
}