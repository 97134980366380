import { useContext, useState } from 'react';
import DirectoryNodeWrapper from '../../../common/directory-node-wrapper/DirectoryNodeWrapper';
import DirectoryNodeAttribute from '../../../common/directory-node-attribute/DirectoryNodeAttribute';
import ContentItemEditform from '../content-item-editform/ContentItemEditform';
import { tmpUiContentItemEditform } from '../content-item-editform/IUiContentItemEditform';
import { ClassContentDirectoryDraftItem } from '../content-directory-translator/IContentDirectoryDraftItem';
import { DirectoryEditorContext } from '../../../../../context/directory-editor-context/DirectoryEditorContextProvider';

interface IProps {
  data: ClassContentDirectoryDraftItem;
  draftLocale: string;
  sourceLocale?: string;
  isReadonly: boolean;
  onUpdate: (updatedContentItem: ClassContentDirectoryDraftItem) => void;
}

export default function ContentDirectoryNode(props: IProps) {
  const { directoryEditorContext } = useContext(DirectoryEditorContext);
  const [editMode, setEditMode] = useState(false);
  const filterConditionIsMet = directoryEditorContext.filterConditionIsMet(props.data);
  //---------------------------------------------------------------------------
  const onSelect = () => {
    setEditMode(true);
  };
  //---------------------------------------------------------------------------
  const onUpdate = (updatedContentItem: ClassContentDirectoryDraftItem) => {
    console.log(updatedContentItem)
    setEditMode(false);
    props.onUpdate(updatedContentItem);
  };
  //---------------------------------------------------------------------------
  if (filterConditionIsMet) {
    if (editMode) return (
      <ContentItemEditform
        ui={tmpUiContentItemEditform}
        data={props.data}
        draftLocale={props.draftLocale}
        sourceLocale={props.sourceLocale}
        isReadonly={props.isReadonly}
        onUpdate={onUpdate}
        onClose={() => setEditMode(false)}
      />
    );
    else return (
      <DirectoryNodeWrapper
        data={props.data}
      >
        <DirectoryNodeAttribute onClick={onSelect}>
          {props.data.id}
        </DirectoryNodeAttribute>
        
        <DirectoryNodeAttribute onClick={onSelect}>
          {props.data.text.value}
        </DirectoryNodeAttribute>

      </DirectoryNodeWrapper>
    );
  } else return null;
}