import React, { useContext, useEffect, useState } from "react";
import { ScreenType, useAppScreenContext } from "../../../../context/app-screen-context/AppScreenProvider";
import CombineStyles from "../../../../utils/combineStyles";
import PopupDialog from "../../popup-v2/popup-dialog/PopupDialog";
//import { ImageEditorContext } from "../image-editor-context/ImageEditorContextProvider";
import { ImageEditorSession } from "../image-editor-context/ImageEditorSession";
import { EImageEditorCloseCommand } from "../ImageEditor";
import { TImageData } from "../TImageData";
import styles from "./ImageEditorPopup.module.css";
import ImageEditorV2 from "../ImageEditorV2";
//import { ButtonIds, EOkCancelDialogButtons, OkCancelToolbar } from "../../popup-v2/ok-cancel-toolbar/OkCancelToolbar";
import GetCaption from "../../functions/GetCaption";
import { AppUiContextStore } from "../../../../context/app-ui-context/AppUiContextProvider";
import { IUiOption } from "../../options/IUiOption";
import FormOptions from "../../form-options-bar/FormOptions";
import { getTUiMenuContent } from "../../menu/menu-content/TUiMenuContent";
import { EOkCancelDialogButtons } from "../../popup-v2/ok-cancel-toolbar/OkCancelToolbar";
import { ClassImageEditorContext, ImageEditorContext } from "../image-editor-context/ImageEditorContextProvider";

interface IProps {
  imageId: string;
  //command from parent component to close popup with save/discard/dialog options  
  closeCommand?: EImageEditorCloseCommand;
  dialogButtons: EOkCancelDialogButtons;
  onSessionOpened?: (session: ImageEditorSession) => void;
  onUrlAndBlobReady?: (imageData?: TImageData) => void;
  onUnsavedChangesDiscard?: (imageDataToRestore?: TImageData) => void;
  onClose: () => void;
}

export default function ImageEditorPopupV2(props: IProps) {
  let ui = ClassImageEditorContext.imageEditorUi;
  //if (!props.ui) {
    if (!ui) {
    console.error("ImageEditorPopupV2: no UI provided");
    return null;
  };
  const { appUiContext } = useContext(AppUiContextStore);
  const { imageEditorContext } = useContext(ImageEditorContext);
  const screenType = useAppScreenContext();
  const [animationEnd, setAnimationEnd] = useState(false);
  const [imageEditorSession, setImageEditorSession] = useState<ImageEditorSession>();
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [imageEditorCloseCommand, setImageEditorCloseCommand] = useState<EImageEditorCloseCommand>(EImageEditorCloseCommand.None);
  const [fullWindow, setFullWindow] = useState(false);
  //---------------------------------------------------------------------------
   useEffect(() => {
    if (imageEditorSession && imageEditorSession == imageEditorContext.lastChangedSession)
      setSaveEnabled(imageEditorSession.saveNeeded);
    else
      setSaveEnabled(false);
  }, [imageEditorContext, imageEditorSession]); 
  //---------------------------------------------------------------------------
  useEffect(() => {
    setImageEditorCloseCommand(EImageEditorCloseCommand.None);
  }, [imageEditorCloseCommand]);
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (animationEnd)
      setAnimationEnd(false);
  }, [animationEnd]);
  //---------------------------------------------------------------------------
  const onImageEditorSessionOpened = (session: ImageEditorSession) => {
    //console.log("IEPopup.onImageEditorSessionOpened.session:", session);
    setImageEditorSession(session);
    props.onSessionOpened && props.onSessionOpened(session);
  };
  //---------------------------------------------------------------------------
  const onUrlAndBlobReady = (imageData?: TImageData) => {
    console.log("IEPopup.onUrlAndBlobReady");
    props.onUrlAndBlobReady && props.onUrlAndBlobReady(imageData);
  };
  //---------------------------------------------------------------------------
  const onPopupConfirm = () => {
    //console.log("IEPopup.onPopupConfirm");
    if (imageEditorSession && imageEditorSession.saveNeeded) {
      console.log("IEPopup.onPopupConfirm.setImageEditorCloseCommand(EImageEditorCloseCommand.Save)");
      setImageEditorCloseCommand(EImageEditorCloseCommand.Save);
    }
    else {
      //console.log("IEPopup.onPopupConfirm.props.onClose()");
      props.onClose && props.onClose();
    }
  };
  //---------------------------------------------------------------------------
  const onPopupCancel = () => {
    if (imageEditorSession) {
      //console.log("onPopupCancel: setImageEditorCloseCommand(Discard)")
      setImageEditorCloseCommand(EImageEditorCloseCommand.Discard);
    }
    else {
      //console.log("onPopupCancel: props.onClose && props.onClose()")
      props.onClose && props.onClose();
    }
  };
  //---------------------------------------------------------------------------
  const onPopupClose = () => {
    if (imageEditorSession && imageEditorSession.saveNeeded) {
      setImageEditorCloseCommand(EImageEditorCloseCommand.Dialog);
    }
    else
      props.onClose && props.onClose();
  };
  //---------------------------------------------------------------------------
  const onToggleFullWindow = () => {
    setFullWindow(!fullWindow);
  };
  //---------------------------------------------------------------------------
  const onAnimationEnd = () => {
    setAnimationEnd(true);
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (optionId: string) => {
    switch (optionId) {
      case "OptionOk":
      case "OptionSave":
        onPopupConfirm();
        break;
      case "OptionCancel":
        onPopupCancel();
        break;
    };
  };
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  ui.menuContent.options.forEach(option => {
    switch (option.id) {
      case "OptionCancel":
        updatedOptions.push({ ...option, iconId: "close" });
        break;
      case "OptionOk":
        if (props.dialogButtons == 'OkCancel')
          updatedOptions.push({
            ...option,
            iconId: "check",
            isDisabled: !saveEnabled
          });
        break;
      case "OptionSave":
        if (props.dialogButtons == 'SaveCancel')
          updatedOptions.push({
            ...option,
            iconId: "check",
            isDisabled: !saveEnabled
          });
        break;
    };
  });
  const formOptions =
    <FormOptions
      ui={getTUiMenuContent({ ...ui.menuContent, options: updatedOptions })}
      applyMobileStyle={false}
      onSelect={onOptionSelect}
    />;
  //---------------------------------------------------------------------------
  return (
    <PopupDialog
      id='ImageEditor'
      header={GetCaption(appUiContext, ui.id, ui?.caption)}
      toolbarContent={formOptions}
      isDraggable={false}
      isModal={true}
      fullWindow={fullWindow}
      onClose={onPopupClose}
      onAnimationEnd={onAnimationEnd}
    >
      <div className={CombineStyles([
        fullWindow ? styles.fullWindow : styles.container,
        screenType == ScreenType.Mobile ? styles.mobile : ""
      ])}>
        <ImageEditorV2
          imageId={props.imageId}
          closeCommand={imageEditorCloseCommand}
          fullWindow={fullWindow}
          animationEnd={animationEnd}
          onSessionOpened={onImageEditorSessionOpened}
          onUrlAndBlobReady={onUrlAndBlobReady}
          onUnsavedChangesDiscard={props.onUnsavedChangesDiscard}
          onToggleFullWindow={onToggleFullWindow}
        />
      </div>
    </PopupDialog>
  );
}