import { useMsal } from '@azure/msal-react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { AppAuthContextStore } from '../../../../../context/app-auth-context/AppAuthContext';
import WikiSpinner from '../../../../app-layout/spinner/wikiSpinner';
import InputText from '../../../../common/input/input-text/InputText';
import { ImageListCard, ImageHandler } from '../image-list-card/ImageListCard';
import { requestUserImageLibrary2, TImageLibraryItem, TImageLibraryItems } from '../TImageLibrary';
import styles from "./ImageList.module.css";
import { IUiUserImagesList } from './IUiUserImagesList';
import { useAbortController } from '../../../../../hooks/useAbortController';
import { ImageEditorContext } from '../../../../common/image-editor/image-editor-context/ImageEditorContextProvider';
import { TImageData } from '../../../../common/image-editor/TImageData';
import { apiPostPrivate, checkResponse } from '../../../../../utils/api';
import { apiBasePath, deleteImage } from '../../../../../utils/apiPathConstant';
import { stringFormatter } from '../../../../../utils/common';
import { ImageEditorSession } from '../../../../common/image-editor/image-editor-context/ImageEditorSession';
import ImageEditorPopupV2 from '../../../../common/image-editor/image-editor-popup/ImageEditorPopupV2';
import { EImageEditorContextUpdate } from '../../../../common/image-editor/image-editor-context/ImageEditorContextReducer';
//-----------------------------------------------------------------------------------
type ActionType = 'New' | 'Copy' | 'Edit';
//-----------------------------------------------------------------------------------
class ImageEditState {
  action?: ActionType = undefined;
  //---------------------------------------------------------------------------------
  private _session?: ImageEditorSession;
  get session(): ImageEditorSession | undefined {
    return this._session;
  }
  set session(value: ImageEditorSession | undefined) {
    if (this._session) {
      if (this._session == value) {
        console.log("onSessionOpened: session.current == openedSession");
        return;
      }
      this._session.inLibrary = false;
    }
    this._session = value;
    if (this._session)
      this._session.inLibrary = true;
  }
  //---------------------------------------------------------------------------------
  setState(session?: ImageEditorSession, action?: ActionType) {
    this.session = session;
    this.action = action;
  }
  //---------------------------------------------------------------------------------
  discard() {
    switch (this.action) {
      case 'Copy':
      case 'New':
        this._session?.removeFromContext();
        break;
    }
  }
}
//-----------------------------------------------------------------------------------
function useImageEditState() {
  const state = useRef<ImageEditState>();
  if (!state.current) {
    state.current = new ImageEditState();
  }
  return state.current;
}
//-----------------------------------------------------------------------------------
interface IProps {
  ui: IUiUserImagesList;
  canSelectItem: boolean;
  selectedItemId?: string;
  itemIdToHide?: string; // if image list is shown to replace this image
  clearContextOnUnmount: boolean;
  addImageSignal: boolean;
  onSelectImage?: ImageHandler;
  onPreSelectedImageFound?: ImageHandler;
}
//-----------------------------------------------------------------------------------
export function ImageList(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { imageEditorContext, imageEditorDispatch } = useContext(ImageEditorContext);
  const [isLoading, setIsLoading] = useState(false);
  const [fullImageList, setFullImageList] = useState<TImageLibraryItems>();
  const [filteredImageList, setFilteredImageList] = useState<TImageLibraryItems>();
  const [filter, setFilter] = useState<string>();
  const abortController = useAbortController("ImageList");
  const [imageCopyRerender, setImageCopyRerender] = useState(0);
  const [imageEditorOpen, setImageEditorOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const imageEditState = useImageEditState();
  //-[]------------------------------------------------------------------------
  useEffect(() => {
    if (imageEditorContext.userImages) {
      return setFullImageList(imageEditorContext.userImages);
    }
    setIsLoading(true);
    let controller = abortController.newController("requestUserImageLibrary");
    requestUserImageLibrary2(
      appAuthContext.config ? appAuthContext.config : null,
      instance,
      controller.signal
    )
      .then(images => {
        // if (props.selectedItemId && props.onPreSelectedImageFound) {
        //   let image = images.find(item => item.id == props.selectedItemId);
        //   image && props.onPreSelectedImageFound(image);
        // }
        setFullImageList(images);
      }).catch(error =>
        console.error(error)
      ).finally(() =>
        !controller.aborted && setIsLoading(false)
      );
    //-------------------------------------------------------------------------
    return () => {
      abortController.abortOnUnmount();
      props.clearContextOnUnmount && imageEditorDispatch({ type: "ClearSessions" });
    };
  }, []);
  //-[props.addImageSignal]----------------------------------------------------
  useEffect(() => {
    if (props.addImageSignal) {
      if (inputRef.current) {
        inputRef.current.value = '';
        inputRef.current.click();
      }
    }
  }, [props.addImageSignal]);
  //-[fullImageList, filter]---------------------------------------------------
  useEffect(() => {
    //---------------------------------------------------------------------------
    // Compose Image list
    if (!fullImageList)
      return;
    let list: TImageLibraryItems | undefined = undefined;
    if (!filter) {
      list = fullImageList?.sort((i1, i2) => i1.compareDate(i2));
    }
    else {
      list = fullImageList?.filter(item => item.description?.toLowerCase().includes(filter));
      list = list?.sort((i1, i2) => i1.compareDescription(i2, filter));
    }
    if (props.itemIdToHide) {
      list = list?.filter(item => item.id != props.itemIdToHide);
    }
    setFilteredImageList(list);
  }, [fullImageList, filter, props.selectedItemId]);
  //-[imageEditorContext]------------------------------------------------------
  useEffect(() => {
    if (!imageEditorContext.lastChangedSession ||
      imageEditorContext.lastChangedSession != imageEditState.session)
      return;
    //--respond only on session added or activated-----------------------------
    switch (imageEditorContext.lastChangedSessionUpdate) {
      case EImageEditorContextUpdate.SessionAdded:
      case EImageEditorContextUpdate.SessionActivated:
        setImageEditorOpen(true);
        break;
      // default:
      //   return;
    }
    // let imageId = imageCopy.current?.id ?? props.imageLibraryItem.id;
    // if (imageId && imageEditorContext.lastChangedSession.imageId == imageId) {
    //   setImageEditorOpen(true);
    // }
  }, [imageEditorContext]);
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (!props.selectedItemId || !filteredImageList)
      return;
    // if (props.selectedItemId && props.onPreSelectedImageFound) {
    //   let image = filteredImageList.find(item => item.id == props.selectedItemId);
    //   image && props.onPreSelectedImageFound(image);
    // }
    let divId = `ImageListCard_${props.selectedItemId}`;
    document.getElementById(divId)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [filteredImageList]);
  //---------------------------------------------------------------------------
  const onFilter = (value: string, isValid: boolean) => {
    if (value !== "") {
      setFilter(value.toLowerCase());
    }
    else {
      setFilter(undefined);
    }
  }
  //---------------------------------------------------------------------------
  const onImageAddNew = (event: React.ChangeEvent<HTMLInputElement>) => {
    let file = event.target.files?.item(0);
    if (!file)
      return;
    let controller = abortController.newController("copyImage");
    setIsLoading(true);
    ImageEditorSession.createForLocalImage(TImageData.fromFile(file), true, undefined, controller.signal)
      .then(session => {
        if (controller.aborted) {
          throw "Aborted";
        }
        imageEditState.setState(session, 'New');
        session.addToContext(imageEditorDispatch);
      })
      .catch(error => {
        console.error(`ImageList.addNewImage:`, error);
      })
      .finally(() => {
        !controller.aborted && setIsLoading(false);
        controller.remove();
      })
  };
  //---------------------------------------------------------------------------
  const onImageCopy = (imageToCopy: TImageLibraryItem) => {
    let controller = abortController.newController("copyImage");
    setIsLoading(true);
    ImageEditorSession.createForImageCopy(imageToCopy, true, undefined, controller.signal)
      .then(session => {
        if (controller.aborted) {
          throw "Aborted";
        }
        session.canLoadNewImage = false;
        imageEditState.setState(session, 'Copy');
        session.addToContext(imageEditorDispatch);
      })
      .catch(error => {
        console.error(`ImageList.copyImage[${imageToCopy.id}]:`, error);
      })
      .finally(() => {
        !controller.aborted && setIsLoading(false);
        controller.remove();
      })
  }
  //---------------------------------------------------------------------------
  const onImageEdit = (imageToEdit: TImageLibraryItem) => {
    let session = imageEditorContext.getSessionByImageId(imageToEdit.id);
    if (session) {
      imageEditState.action = 'Edit';
      imageEditState.session = session;
      return imageEditorDispatch({ type: "ActivateSession", session: session });
    }
    setIsLoading(true);
    let controller = abortController.newController("editImage");
    ImageEditorSession.createForImageEdit(imageToEdit, true, undefined, controller.signal)
      .then(session => {
        if (controller.aborted) {
          throw "Aborted";
        }
        session.canLoadNewImage = true;
        imageEditState.setState(session, 'Edit');
        session.addToContext(imageEditorDispatch);
      })
      .catch(error => {
        console.error(`ImageList.editImage[${imageToEdit.id}]:`, error);
      })
      .finally(() => {
        !controller.aborted && setIsLoading(false);
        controller.remove();
      })
  }
  //---------------------------------------------------------------------------
  const onImageDelete = (imageData: TImageLibraryItem) => {
    if (!imageData.userId) {
      return console.error("onImageDelete: userId is undefined");
    }
    let controller = abortController.newController(`deleteImage_${imageData.id}`);
    setIsLoading(true);
    apiPostPrivate(
      instance,
      appAuthContext.config,
      `${apiBasePath}${stringFormatter(deleteImage, [imageData.id, imageData.userId])}`,
      controller.signal
    )
      .then(response => {
        checkResponse(response, "deleteImage", controller.aborted);
        if (!fullImageList) return;
        let newItems = fullImageList.filter(i => i.id != imageData.id);
        setFullImageList(newItems);
      })
      .catch(error => {
        console.error("onImageDelete.catch:", error);
      })
      .finally(() => {
        setIsLoading(false);
      })
  }
  //---------------------------------------------------------------------------
  const closeImageEditor = () => {
    setImageEditorOpen(false);
    imageEditState.setState(undefined, undefined);
  };
  //---------------------------------------------------------------------------
  const onSessionOpened = (openedSession: ImageEditorSession) => {
    imageEditState.session = openedSession;
  }
  //---------------------------------------------------------------------------
  const onUrlAndBlobReady = (imageData?: TImageData) => {
    if (!imageData || !imageEditState.session) {
      return;
    }
    closeImageEditor();
    console.log("onUrlAndBlobReady.imageData.editedImageUrl:", imageData.editedImageUrl);
    setIsLoading(true);
    imageEditorContext.uploadAllImagesForAsync(imageData.id, appAuthContext, instance, false)
      .then(bool => {
        let session = imageEditorContext.getSessionByImageId(imageData.id);
        if (session && fullImageList) {
          session.imageData.clearSas();
          let newItem = new TImageLibraryItem(session.imageData);
          let existingItemIndex = fullImageList.findIndex(item => item.id == imageData.id);
          let newItems: TImageLibraryItem[];
          if (existingItemIndex < 0) {
            newItems = [newItem].concat(fullImageList);
            if (props.canSelectItem) {
              props.onSelectImage?.(newItem);
              props.onPreSelectedImageFound?.(newItem);
            }
          }
          else {
            newItems = fullImageList.map(item => item.id == imageData.id ? newItem : item);
          }
          setFullImageList(newItems);
        }
        setImageCopyRerender(imageCopyRerender + 1);
      })
      .catch(error => {
        console.error("onImageSave:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  //---------------------------------------------------------------------------
  const onDescriptionChanged = (imageData?: TImageData) => {
    if (!imageData) {
      return;
    }
    console.log("onDescriptionChanged.imageData.description:", imageData.description);
    setIsLoading(true);
    TImageData.uploadImageLibraryItems([imageData])
      .then(bool => {
        setImageCopyRerender(imageCopyRerender + 1);
      })
      .catch(error => {
        console.error("onDescriptionChanged:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  //---------------------------------------------------------------------------
  const onUnsavedChangesDiscard = (imageData?: TImageData) => {
    console.log("onUnsavedChangesDiscard");
    imageEditState.discard();
    closeImageEditor();
  }
  //---------------------------------------------------------------------------
  let items = filteredImageList?.map((item: TImageLibraryItem, index: number) => (
    <ImageListCard
      key={`${item.id}_${index}`}
      index={index}
      imageLibraryItem={item}
      selected={props.selectedItemId != undefined && props.selectedItemId == item.id}
      showToolbar={!props.canSelectItem}
      onImageSelect={props.onSelectImage}
      onImageCopy={onImageCopy}
      onImageEdit={onImageEdit}
      onImageDelete={onImageDelete}
      onDescriptionChanged={onDescriptionChanged}
    />
  ));
  //---------------------------------------------------------------------------
  const inputImageSearch = props.ui.inputImageSearch;
  return (
    <React.Fragment>
      <WikiSpinner show={isLoading}></WikiSpinner>
      {//!isLoading &&
        <div className={styles.container}>
          <InputText
            ui={{
              id: inputImageSearch.id,
              directoryId: inputImageSearch.directoryId,
              elementType: inputImageSearch.elementType,
              caption: inputImageSearch.caption,
              hint: inputImageSearch.hint,
              placeholder: inputImageSearch.placeholder,
              defaultValue: undefined,
              visible: true,
              disabled: false,
              validation: undefined
            }}
            focused={false}
            hideCaption={true}
            hideHint={true}
            onUpdate={onFilter}
          />
          {items && items}
        </div>
      }
      <input
        title="ImageFileInput"
        type='file'
        inputMode="none"
        accept="image/*"
        ref={inputRef}
        style={{ display: 'none' }}
        onChange={onImageAddNew}
      />

      {imageEditorOpen && imageEditState.session &&
        <ImageEditorPopupV2
          imageId={imageEditState.session.imageId}
          dialogButtons='SaveCancel'
          onSessionOpened={onSessionOpened}
          onUrlAndBlobReady={onUrlAndBlobReady}
          onUnsavedChangesDiscard={onUnsavedChangesDiscard}
          onClose={closeImageEditor}
        />}

    </React.Fragment>
  );
}