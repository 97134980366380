import { useMsal } from '@azure/msal-react';
import ConsoleContent from '../../../../common/console-layout/console-content/ConsoleContent';
import { Locker } from '../../../../common/locks/Locker';
import RequestDetails from '../../regional-homepages/request-form/request-details/RequestDetails';
import styles from './CustomerAdRequestEditForm.module.css';
import { useContext, useEffect, useRef, useState } from 'react';
import { AppAuthContextStore } from '../../../../../context/app-auth-context/AppAuthContext';
import { AppUiContextStore } from '../../../../../context/app-ui-context/AppUiContextProvider';
import { useParams } from 'react-router-dom';
import useNavigateWithContext from '../../../../../hooks/useNavigateWithContext';
import { useAbortController } from '../../../../../hooks/useAbortController';
import { AdLanguageSetReviewState } from '../../regional-homepages/request-form/AdLanguageSetReviewState';
import GetHint from '../../../../common/functions/GetHint';
import { IUiEditFormRegionalHomepageRequest } from '../../regional-homepages/request-form/IUiEditFormRegionalHomepageRequest';
import { ICustomerAdRequest } from './ICustomerAdRequest';
import AdModule from '../../../../common/ad-modules/AdModule';
import FormOptions from '../../../../common/form-options-bar/FormOptions';
import { getTUiMenuContent } from '../../../../common/menu/menu-content/TUiMenuContent';
import { IUiOption } from '../../../../common/options/IUiOption';
import { pathAdminConsole, pathModeratorConsole } from '../../../AdminConsole';
import { pathApprovalRequests } from '../../ModeratorConsole';
import ChecklistAdLanguageSet from '../../checklist-adlanguageset/ChecklistAdLanguageSet';
import { AdLanguageSet } from '../../../../ad-content-editor/IAdLanguageSet';
import { getCustomerAdRequestAsync } from '../../functions/getCustomerAdRequestAsync';
import GetName from '../../../../common/functions/GetName';
import { TUiBreadcrumb } from '../../../../common/breadcrumbs-trail/breadcrumb/TUiBreadcrumb';
import { completeCustomerAdRequestAsync } from '../../functions/completeCustomerAdRequestAsync';
import { IWorkflowLogRecord } from '../../../common/workflows/IWorkflowLog';
import PopupError, { IErrorMessage, tmpUiPopupNotification } from '../../../../common/popup-v2/popup-error/PopupError';
import { RemoveUIRelatedDataFromLS } from '../../../../../utils/storage';

interface IProps {
  ui: IUiEditFormRegionalHomepageRequest;
}

export default function CustomerAdRequestEditForm(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { appUiContext, appUiContextDispatch } = useContext(AppUiContextStore);
  const { requestId, localeId } = useParams();
  const navigate = useNavigateWithContext();
  const abortController = useAbortController("CustomerAdRequestEditForm");
  const [isLoading, setIsLoading] = useState(false);
  const [request, setRequest] = useState<ICustomerAdRequest>(); // For now
  const [state, setState] = useState<AdLanguageSetReviewState>();
  const [hoveredItemPath, setHoveredItemPath] = useState<string[]>();
  const [adRank, setAdRank] = useState<string>();
  const [errorNotification, setErrorNotification] = useState<IErrorMessage>();
  const requestLocaleName = useRef<string>();
  const actualLogRecord = useRef<IWorkflowLogRecord>();
  //--------------------------------------------------------------------------- Data, AbortController, Colors
  useEffect(() => {
    if (!requestId) return;
    if (!localeId) return;
    setIsLoading(true);
    let controller = abortController.newController("getCustomerAdRequest");
    getCustomerAdRequestAsync({
      msalInstanse: instance,
      authConfig: appAuthContext.config,
      requestId: requestId,
      localeId: localeId,
      abortSignal: controller.signal
    }).then(request => {
      setRequest(request);
    }).catch(error => {
      console.error(error);
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
    //-------------------------------------------------------------------------
    return () => {
      abortController.abortOnUnmount();
    };
  }, []);
  //--------------------------------------------------------------------------- Checklist, Breadcrumb, Actual workflow record, Current item
  useEffect(() => {
    if (!request) return;
    //-------------------------------------------------------------------------
    const locale = appUiContext.dictionaries?.supportedLocales?.find(l => l.id == request.localeId);
    const localeName = GetName(appUiContext, request.localeId, locale?.name);
    requestLocaleName.current = localeName;
    const requestName = `${request.adLanguageSet.name} (${localeName})`;
    const userBreadcrumb: TUiBreadcrumb = {
      id: request.id,
      caption: { en: requestName },
      path: `/${pathAdminConsole}/${pathModeratorConsole}/${pathApprovalRequests}/${request.id}`
    };
    appUiContextDispatch({ type: "UpdateBreadcrumbsTrail", value: userBreadcrumb });
    //-------------------------------------------------------------------------
    // Set state
    const adLanguageSet = new AdLanguageSet(request.adLanguageSet);
    const state = new AdLanguageSetReviewState({
      source: adLanguageSet
    });
    setState(state);
    //-------------------------------------------------------------------------
    // Find and set actual workflow record
    actualLogRecord.current = request.log.records.find(r => r.id == request.log.actualRecordId);
    //-------------------------------------------------------------------------
    // Determine if ad rank needs to be displayed
    // this is the case when ad rank is included in request rejection reason(s)
    const dictionaryItem = appUiContext.dictionaries?.adRanks?.find(r =>
      r.id == request.adRankId);
    if (dictionaryItem) {
      setAdRank(GetName(appUiContext, request.adRankId, dictionaryItem.name));
    } else {
      setAdRank(undefined);
    };
  }, [request]);
  //---------------------------------------------------------------------------
  const onHover = (path: string[]) => {
    setHoveredItemPath(path);
  };
  //---------------------------------------------------------------------------
  const onAdLanguageSetReview = (updatedAdLanguageSet: AdLanguageSet) => {
    if (!state) return;
    const updatedState = new AdLanguageSetReviewState({
      update: {
        state: state,
        updatedData: updatedAdLanguageSet
      }
    });
    setState(updatedState);
  };
  //---------------------------------------------------------------------------
  const onReset = () => {
    if (!state) return;
    const updatedState = state?.getInitialState();
    setState(updatedState);
  };
  //---------------------------------------------------------------------------
  const onComplete = (isApproved: boolean) => {
    if (!request) return;
    if (!state?.adLanguageSet) return;
    //-------------------------------------------------------------------------
    setIsLoading(true);
    let controller = abortController.newController("completeCustomerAdRequestAsync");
    completeCustomerAdRequestAsync({
      msalInstanse: instance,
      authConfig: appAuthContext.config,
      abortSignal: controller.signal,
      requestId: request.id,
      localeId: request.localeId,
      isApproved: isApproved,
      adLanguageSet: state?.adLanguageSet
    }).then(response => {
      if (response.dictionary) {
        //console.log(response.dictionary)
        // If dictionary is provided, it's changed so we need to update it in UI
        appUiContextDispatch({
          type: "UpdateDictionaries", value: {
            requestRejectionReasons: response.dictionary
          }
        });
        setErrorNotification({
          ui: {
            ...tmpUiPopupNotification,
            caption: { en: "Complete approval request" },
            hint: { en: "Rejection reasons dictionary is updated, please re-review the request" }
          },
          onClose: () => {
            //RemoveUIRelatedDataFromLS();
            const updatedState = state?.getInitialState();
            setState(updatedState);
            setErrorNotification(undefined);
          }
        });
      };
      //-----------------------------------------------------------------------
      // Reset review results
      //setRequest(response.request);
    }).catch(error => {
      console.error(error);
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (selectedOptionId: string) => {
    switch (selectedOptionId) {
      case "OptionClose":
      case "OptionCancel":
        navigate(`/${pathAdminConsole}/${pathModeratorConsole}/${pathApprovalRequests}`);
        break;
      case "OptionReset":
        onReset();
        break;
      case "OptionApprove":
        onComplete(true);
        break;
      case "OptionReject":
        onComplete(false);
        break;
    };
  };
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  props.ui.menuContent.options.forEach(option => {
    switch (option.id) {
      case "OptionClose":
        state?.isReadonly && updatedOptions.push({
          ...option,
          iconId: "close"
        });
        break;
      case "OptionCancel":
        !state?.isReadonly && updatedOptions.push({
          ...option,
          iconId: "close"
        });
        break;
      case "OptionReset":
        !state?.isReadonly && updatedOptions.push({
          ...option,
          iconId: "refresh",
          isDisabled: !state?.isModified
        });
        break;
      case "OptionApprove":
        const approveIsDisabled = !(state?.isCompleted && state.isApproved);
        state && !state.isReadonly && updatedOptions.push({
          ...option,
          iconId: "check",
          customColor: approveIsDisabled ? undefined : 'var(--clr_accent_main)',
          isDisabled: approveIsDisabled
        });
        break;
      case "OptionReject":
        const rejectIsDisabled = !(state?.isCompleted && !state.isApproved);
        state && !state.isReadonly && updatedOptions.push({
          ...option,
          iconId: "ban",
          customColor: rejectIsDisabled ? undefined : 'var(--clr_accent_wrong)',
          isDisabled: rejectIsDisabled
        });
        break;
    };
  });
  const menuContent = getTUiMenuContent({ ...props.ui.menuContent, options: updatedOptions });
  const formOptions =
    <FormOptions
      ui={menuContent}
      optionIdVisibleOnMobile={"OptionCancel"}
      onSelect={onOptionSelect}
    />;
  //--------------------------------------------------------------------------- Ad Page Viewer
  const adModules = state?.adLanguageSet.adModules?.map(adModule => (
    <AdModule
      key={adModule.id}
      adLanguageSet={state?.adLanguageSet}
      adModule={adModule}
      isReadonly={true}
      isClickable={false}
      useExternalLayout={false}
      highlightedPath={hoveredItemPath}
    />
  ));
  //---------------------------------------------------------------------------
  const sidebarContent =
    <div className={styles.adpage}>
      {adModules}
    </div>;
  //---------------------------------------------------------------------------
  let isReadonly = state ? state.isReadonly : true;
  //---------------------------------------------------------------------------
  return (
    <ConsoleContent
      prompt={GetHint(appUiContext, props.ui.id, props.ui.hint)}
      formOptions={formOptions}
      sidebarChildren={sidebarContent}
      isLoading={isLoading}
    >
      <Locker locker={request?.locker} />

      <RequestDetails
        data={request}
        businessName={request?.businessName}
        adRankName={adRank}
        localeName={requestLocaleName.current}
      />

      <div className={styles.container}>
        <ChecklistAdLanguageSet
          data={state?.adLanguageSet}
          isReadonly={isReadonly}
          onHover={onHover}
          onReview={onAdLanguageSetReview}
        />
      </div>

      {errorNotification &&
        <PopupError
          ui={errorNotification.ui}
          show={true}
          onClose={errorNotification.onClose}
        />}

    </ConsoleContent>
  );
}