import { useMsal } from '@azure/msal-react';
import ConsoleContent from '../../../../common/console-layout/console-content/ConsoleContent';
import { IUiOption } from '../../../../common/options/IUiOption';
import styles from './DocumentsList.module.css';
import { useContext, useEffect, useState } from 'react';
import { AppAuthContextStore } from '../../../../../context/app-auth-context/AppAuthContext';
import { AppUiContextStore } from '../../../../../context/app-ui-context/AppUiContextProvider';
import { NotificationContext } from '../../../../../context/notification-context/NotificationContextProvider';
import useNavigateWithContext from '../../../../../hooks/useNavigateWithContext';
import { useAbortController } from '../../../../../hooks/useAbortController';
import { IDocumentsListItem } from '../../../administrator-console/documents/documents-list/documents-list-item/IDocumentsListItem';
import DocumentsListItem from '../../../administrator-console/documents/documents-list/documents-list-item/DocumentsListItem';
import { getDocumentsListAsync } from './getDocumentsListAsync';
import { pathAdminConsole, pathSystemTranslator } from '../../../AdminConsole';
import { optionIdDocuments, pathDocuments } from '../../SystemTranslatorConsole';
import { TUiBreadcrumb } from '../../../../common/breadcrumbs-trail/breadcrumb/TUiBreadcrumb';

interface IProps {
  //ui?: IUiDocumentsList;
  optionsForBreadcrumb?: IUiOption[];
}

export default function DocumentsList(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { appUiContextDispatch } = useContext(AppUiContextStore);
  const { notificationContextDispatch } = useContext(NotificationContext);
  const navigate = useNavigateWithContext();
  const abortController = useAbortController("DocumentsList");
  const [isLoading, setIsLoading] = useState(false);
  const [documentsListItems, setDocumentsListItems] = useState<IDocumentsListItem[]>();
  //--------------------------------------------------------------------------- Data
  useEffect(() => {
    setIsLoading(true);
    let controller = abortController.newController("getDocumentsListAsync");
    getDocumentsListAsync({
      msalInstance: instance,
      authConfig: appAuthContext.config,
      abortSignal: controller.signal
    }).then(documentsListItems => {
      setDocumentsListItems(documentsListItems);
    }).catch(error => {
      !controller.aborted && notificationContextDispatch({ type: "SetError", header: "Business Wizard", message: error });
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
    //-------------------------------------------------------------------------
    return (() => {
      abortController.abortOnUnmount();
    });
  }, []);
  //--------------------------------------------------------------------------- Breadcrumb
  useEffect(() => {
    const option = props.optionsForBreadcrumb?.find(o => o.id == optionIdDocuments);
    if (option) {
      const breadcrumb: TUiBreadcrumb = {
        id: option.id,
        caption: option.caption,
        path: `/${pathAdminConsole}/${pathSystemTranslator}/${pathDocuments}`
      };
      appUiContextDispatch({ type: "UpdateBreadcrumbsTrail", value: breadcrumb });
    };
  }, [props.optionsForBreadcrumb]);
  //---------------------------------------------------------------------------
  const onSelect = (documentId: string, localeId: string) => {
    navigate(`/${pathAdminConsole}/${pathSystemTranslator}/${pathDocuments}/${documentId}/${localeId}`);
  };
  //---------------------------------------------------------------------------
  /* const listItems = documentsListItems?.map(item => (
    <DocumentsListItem
      key={item.documentId}
      data={item}
      onSelect={onSelect}
    />
  )); */
  //---------------------------------------------------------------------------
  return (    
    <ConsoleContent
      prompt="Document content editor"
      containerWidthPercent={70}
      isLoading={isLoading}
    >
      <div className={styles.container}>
        {/* {listItems} */}
      </div>
    </ConsoleContent>
  );
}