import CombineStyles from '../../../../../utils/combineStyles';
import { IContentUnitStatus } from '../../../../common/content/IContentUnit';
import IconBan from '../../../../common/icons/IconBan';
import IconCheck from '../../../../common/icons/IconCheck';
import ChecklistNodeAttribute from '../checklist-node-attribute/ChecklistNodeAttribute';
import styles from './ChecklistNodeStatus.module.css';

interface IProps {
  data: IContentUnitStatus;
  isReadonly: boolean;
  onApprove?: () => void;
  onReject?: () => void;
}

export default function ChecklistNodeStatus(props: IProps) {
  //---------------------------------------------------------------------------
  const onApprove = () => {
    if (props.isReadonly) return;
    if (!props.onApprove) return;
    props.onApprove();
  };
  //---------------------------------------------------------------------------
  const onReject = () => {
    if (props.isReadonly) return;
    if (!props.onReject) return;
    props.onReject();
  };
  //---------------------------------------------------------------------------
  return (
    <ChecklistNodeAttribute>
      <div
        className={CombineStyles([
          styles.container,
          props.isReadonly ? styles.readonly : ''
        ])}>

        <div
          className={CombineStyles([
            styles.option,
            props.data.isApproved ? styles.approved : ''
          ])}
          onClick={onApprove}
        >
          <IconCheck />
        </div>

        <div
          className={CombineStyles([
            styles.option,
            props.data.isInTrouble ? styles.rejected : ''
          ])}
          onClick={onReject}
        >
          <IconBan />
        </div>

      </div>
    </ChecklistNodeAttribute>
  );
}