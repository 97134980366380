import React, { ReactNode, useEffect, useState } from 'react';
import { DocumentDraftItem } from '../../../../administrator-console/documents/document-editor/IDocumentDraftItem';
import ChecklistNodeAttribute from '../../../checklist/checklist-node-attribute/ChecklistNodeAttribute';
import ChecklistNodeWrapper from '../../../checklist/checklist-node-wrapper/ChecklistNodeWrapper';
import ChecklistItemText from './ChecklistItemText';
import { IHoveredItem } from '../../request-form/DocumentRequestForm';
import { IContentUnitText } from '../../../../../common/content/content-text/IContentUnitText';

interface IProps {
  data: DocumentDraftItem;
  isReadonly: boolean;
  isExpanded: boolean;
  onHover: (item?: IHoveredItem) => void;
  onReview?: (updatedItem: DocumentDraftItem) => void;
}

export default function ChecklistItemTitle(props: IProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const headerPath = `${props.data.fullPath}.header`;
  const annotationPath = `${props.data.fullPath}.text`;
  //---------------------------------------------------------------------------
  useEffect(() => {
    setIsExpanded(props.isExpanded);
  }, [props.isExpanded]);
  //---------------------------------------------------------------------------
  const onHover = () => {
    props.onHover({
      path: props.data.fullPath,
      status: props.data.status
    });
  };
  //---------------------------------------------------------------------------
  const onHoverEnd = () => {
    props.onHover();
  };
  //---------------------------------------------------------------------------
  const onHeaderHover = (hovered: boolean) => {
    if (hovered)
      props.onHover({
        path: headerPath,
        status: props.data.header?.status
      });
    else
      props.onHover();
  };
  //---------------------------------------------------------------------------
  const onTextHover = (hovered: boolean) => {
    if (hovered)
      props.onHover({
        path: annotationPath,
        status: props.data.text?.status
      });
    else
      props.onHover();
  };
  //---------------------------------------------------------------------------
  const onHeaderReview = (updatedTextUnit: IContentUnitText) => {
    const updatedItem = new DocumentDraftItem({
      ...props.data,
      header: updatedTextUnit
    });
    updatedItem.updateStatus();
    props.onReview?.(updatedItem);
  };
  //---------------------------------------------------------------------------
  const onTextReview = (updatedTextUnit: IContentUnitText) => {
    const updatedItem = new DocumentDraftItem({
      ...props.data,
      text: updatedTextUnit
    });
    updatedItem.updateStatus();
    props.onReview?.(updatedItem);
  };
  //---------------------------------------------------------------------------
  const propSubItems: ReactNode[] = [];
  if (props.data.header) {
    const headerNode =
      <ChecklistItemText
        key={`${props.data.id}_header`}
        level={1}
        data={props.data.header}
        isReadonly={props.isReadonly}
        onHover={onHeaderHover}
        onReview={onHeaderReview}
      />;
    propSubItems.push(headerNode);
  };
  if (props.data.text) {
    const annotationNode =
      <ChecklistItemText
        key={`${props.data.id}_text`}
        level={1}
        data={props.data.text}
        isReadonly={props.isReadonly}
        onHover={onTextHover}
        onReview={onTextReview}
      />;
    propSubItems.push(annotationNode);
  };
  //---------------------------------------------------------------------------
  if (props.data.status) return (
    <React.Fragment>
      <ChecklistNodeWrapper
        data={props.data.status}
        isReadonly={true}
        onHover={onHover}
        onHoverEnd={onHoverEnd}
      >

        <ChecklistNodeAttribute
          customStyles={{ gridColumn: '1 / span 2' }}
          isExpanded={isExpanded}
          onToggle={() => setIsExpanded(!isExpanded)}
        >
          {props.data.type}
        </ChecklistNodeAttribute>

      </ChecklistNodeWrapper>

      {isExpanded && propSubItems}

    </React.Fragment>
  ); else return (
    <div>
      No status
    </div>
  );
}