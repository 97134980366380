import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../../context/app-auth-context/AppAuthContext";
import { apiBasePath, pathGetListDocumentsForTranslator } from "../../../../../utils/apiPathConstant";
import { apiGetPrivate, checkResponse } from "../../../../../utils/api";
import { IDocumentsListItem } from "../../../administrator-console/documents/documents-list/documents-list-item/IDocumentsListItem";

interface IProps {
  msalInstance: IPublicClientApplication;
  authConfig?: IAuthConfig;
  abortSignal?: AbortSignal;
}

export async function getDocumentsListAsync(props: IProps): Promise<IDocumentsListItem[]> {
  if (!props.authConfig) throw new Error("getDocumentsListAsync: auth config is not provided");
  //---------------------------------------------------------------------------
  const response = await apiGetPrivate(
    props.msalInstance,
    props.authConfig,
    `${apiBasePath}${pathGetListDocumentsForTranslator}`,
    props.abortSignal
  );
  //---------------------------------------------------------------------------
  checkResponse(response, "getDocumentsListAsync", props.abortSignal?.aborted);
  const documentListItems = response?.content as IDocumentsListItem[];
  return documentListItems;
}