import { IAppUiContext } from "../../context/app-ui-context/AppUiContextProvider";
import { ClassAdModule } from "../common/ad-modules/IAdModule";
import { IContentUnitText } from "../common/content/content-text/IContentUnitText";
import { ClassContentUnitStatus, IContentUnit } from "../common/content/IContentUnit";
import GetName from "../common/functions/GetName";

export type EAdLanguageSetDesignTemplateId = "RegionalHomepageDefault" | "CustomerBusinessDefault" | "SystemBusinessRegionalDefault";

export interface IAdStatus {
  statusId: string;
  isAutoActivated: boolean;
  isReadonly: boolean;
  isApproved: boolean;
  isInTrouble: boolean;
  isPublished: boolean;
  // create a class with calculated properties
}

export interface IAdLanguageSetListItem {
  id: string;
  businessId: string;
  adCampaignId: string;
  localeId: string;
  status: IAdStatus;
  adModules: string[];
}

export interface IAdLanguageSet {
  id: string;
  businessId: string;
  adCampaignId: string;
  localeId: string;
  name?: string;
  description?: string;
  datetimeCreated: string;
  designTemplateId?: EAdLanguageSetDesignTemplateId;
  adModules?: ClassAdModule[];
  status: IAdStatus;
  issues?: IContentUnit[];
}

export class AdLanguageSet implements IAdLanguageSet {
  id!: string;
  businessId!: string;
  adCampaignId!: string;
  localeId!: string;
  name?: string;
  description?: string;
  datetimeCreated!: string;
  designTemplateId?: EAdLanguageSetDesignTemplateId;
  adModules?: ClassAdModule[];
  status!: IAdStatus;
  issues?: IContentUnit[];
  //-----------------------------------
  isLocked!: boolean; // e.g. business region is not available for staff users
  //---------------------------------------------------------------------------
  constructor(source: IAdLanguageSet) {
    Object.assign(this, source);
    this.setup();
  };
  //---------------------------------------------------------------------------
  setup() {
    const adModuleInstances: ClassAdModule[] = [];
    this.adModules?.forEach(adModule => {
      const adModuleInstance = new ClassAdModule(adModule);
      adModuleInstances.push(adModuleInstance);
    });
    this.adModules = adModuleInstances;
    //-------------------------------------------------------------------------
    // Complex issues
    if (this.issues) {
      this.issues.forEach(issue => {
        if (!issue.status) throw ("AdLanguageSet: Issue status is missing");
        issue.status = new ClassContentUnitStatus(issue.status.statusId, issue.status.rejectionReason);
      });
    };
  };
  //---------------------------------------------------------------------------
  getReviewIssues(appUiContext: IAppUiContext): IAdLanguageSetReviewIssue[] | undefined {
    if (this.status.isApproved) return;
    // Get rejected ad units from ad language set
    let issues: IAdLanguageSetReviewIssue[] = [];
    //-------------------------------------------------------------------------
    // Ad Language set level issues
    this.issues?.forEach(issue => {
      if (issue.status) {
        issues.push({
          index: 0,
          adUnitType: "Ad",
          status: issue.status
        });
      };
    });
    //-------------------------------------------------------------------------
    // Ad module level issues
    if (this.adModules) {
      const adModulesIssues = ClassAdModule.getReviewIssues(this.adModules);
      if (adModulesIssues) {
        issues = issues.concat(adModulesIssues);
      };
    };
    //-------------------------------------------------------------------------
    // Update issues with readable reasons
    if (issues.length > 0) {
      let i = 0;
      issues.forEach(issue => {
        issue.index = i;
        if (issue.status.rejectionReason) {
          let reasonString = "";
          switch (issue.status.statusId) {
            case "Invalid":
              reasonString = `${GetName(appUiContext, issue.status.rejectionReason.reasonId, undefined)}`;
              break;
            case "Rejected":
              const reasonDictionaryItem = appUiContext.dictionaries?.requestRejectionReasons?.find(item => item.id == issue.status.rejectionReason?.reasonId);
              reasonString = `${GetName(appUiContext, issue.status.rejectionReason.reasonId, reasonDictionaryItem?.name)}`;
              if (issue?.status.rejectionReason.reasonDetails) {
                reasonString = `${reasonString}: ${issue?.status.rejectionReason.reasonDetails}`;
              };
              break;
          };
          issue.reasonString = reasonString;
        };
        i++;
      });
      //-----------------------------------------------------------------------
      return issues;
    };
    return undefined;
  };
  //---------------------------------------------------------------------------
  getAdModuleToUpdate(adModuleId: string): ClassAdModule | undefined {
    const adModule = this.adModules?.find(item => item.id === adModuleId);
    if (!adModule) return;
    //-------------------------------------------------------------------------
    if (adModule.status?.isReadonly) {
      // This means we need to create a copy of the module (with new ids)
      return adModule.getCopy(this.localeId);
    } else {
      // It's editable copy, so just create a new instance for editing
      const updatedAdModule = new ClassAdModule(adModule);
      updatedAdModule.status = new ClassContentUnitStatus("Draft");
      return updatedAdModule;
    };
  };
  //---------------------------------------------------------------------------
  getTitleUnit(): IContentUnitText {
    return this.name ? {
      type: "Header",
      text: this.name,
      status: new ClassContentUnitStatus("Draft")
    } : {
      type: "Header",
      status: new ClassContentUnitStatus("Draft")
    };
  };
  //---------------------------------------------------------------------------
  getDescriptionUnit(): IContentUnitText {
    return this.description ? {
      type: "Description",
      text: this.description,
      status: new ClassContentUnitStatus("Draft")
    } : {
      type: "Description",
      status: new ClassContentUnitStatus("Draft")
    };
  };
  //---------------------------------------------------------------------------
  getSearchResultsModuleToEdit(): IAdModuleToEdit | undefined {
    const sourceModule = this.adModules?.find(adModule => adModule.adModuleType === "SearchResultsModule");
    if (sourceModule) {
      const adModuleToEdit = this.getAdModuleToUpdate(sourceModule.id);
      if (adModuleToEdit) {
        return {
          adModule: adModuleToEdit,
          sourceAdModuleId: sourceModule.id == adModuleToEdit.id ? undefined : sourceModule.id
        };
      };
    };
  };
  //---------------------------------------------------------------------------
  getHomepageBannerToEdit(): IAdModuleToEdit | undefined {
    const sourceModule = this.adModules?.find(adModule => adModule.adModuleType === "Photoblock" && adModule.usage.isHomepage);
    if (sourceModule) {
      const adModuleToEdit = this.getAdModuleToUpdate(sourceModule.id);
      if (adModuleToEdit) {
        return {
          adModule: adModuleToEdit,
          sourceAdModuleId: sourceModule.id == adModuleToEdit.id ? undefined : sourceModule.id
        };
      };
    };
  };
  //---------------------------------------------------------------------------
  validate(): boolean {
    // Check issues and unit statuses
    let isValid = true;
    return isValid;
  };
}

export interface IAdLanguageSetReviewIssue {
  index: number;
  adModuleId?: string;
  adModuleParagraphId?: string;
  adUnitType: string;
  status: ClassContentUnitStatus;
  reasonString?: string;
}

export interface IAdModuleToEdit {
  adModule: ClassAdModule;
  sourceAdModuleId?: string;
}