import { IReadableDocument, ReadableDocument } from '../IReadableDocument';
import styles from './DocumentContent.module.css';
import { IUiDocumentViewer } from '../IUiDocumentViewer';
import DocumentItems from '../document-items/DocumentItems';

export interface IHighlightedItem {
  path: string;
  color: string;
}

interface IProps {
  ui?: IUiDocumentViewer;
  data: IReadableDocument;
  highligtedItem?: IHighlightedItem;
}

export default function DocumentContent(props: IProps) {
  const document = new ReadableDocument(props.data, true);
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>
      <DocumentItems
        items={document.items}
        highligtedItem={props.highligtedItem}
      />
    </div>
  );
}