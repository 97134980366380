import { AdLanguageSet } from "../../ad-content-editor/IAdLanguageSet";
import { TImageData } from "../../common/image-editor/TImageData";
import { ClassCustomerBusiness } from "../business-editor/ICustomerBusiness";
import { Contact } from "../business-editor/section-contacts/contact/Contact";
import { ClassBusinessLocationData } from "../business-editor/section-location/business-location-editor/IBusinessLocationData";
import { IWizardStep } from "./IWizardStep";
import { v4 as uuidv4 } from "uuid";

export type EWizardStep =
  "StepLocale" |
  "StepAdRank" |
  "StepName" |
  "StepDescription" |
  "StepLogo" |
  "StepTags" |
  "StepLocation" |
  "StepContacts" |
  "StepBackgroundImage" |
  "StepPreviewSearchResults" |
  "StepPreviewHomepageBanner" |
  "StepPreviewBusinessPage" |
  "StepPublish";

export const businessWizardSteps: EWizardStep[] = [
  "StepLocale",
  "StepAdRank",
  "StepName",
  "StepDescription",
  "StepLogo",
  "StepTags",
  "StepLocation",
  "StepContacts",
  "StepBackgroundImage",
  "StepPreviewSearchResults",
  "StepPreviewHomepageBanner",
  "StepPreviewBusinessPage",
  "StepPublish"
];

export type EWizardDirection = "Next" | "Back";

export interface IWizardState {
  steps: IWizardStep[];
  lastCompletedStepIndex: number;
  selectedStep: EWizardStep;
  data: IBusinessWizardData;
  business?: ClassCustomerBusiness;
  adLanguageSet?: AdLanguageSet;
}

export class WizardState implements IWizardState {
  steps!: IWizardStep[];
  lastCompletedStepIndex!: number;
  selectedStep!: EWizardStep;
  data!: IBusinessWizardData;
  business?: ClassCustomerBusiness;
  adLanguageSet?: AdLanguageSet;
  //---------------------------------------------------------------------------
  constructor(source: IWizardState) {
    Object.assign(this, source);
    this.updateLastCompletedStepIndex();
  };
  //---------------------------------------------------------------------------
  static initialize(initial: IWizardStateInitial): WizardState {
    const state: IWizardState = {
      steps: initial.steps.map((stepId, index) => ({
        stepId: stepId,
        index: index,
        isCompleted: false,
        isFirst: index == 0,
        isFirstDisplayed: index == 0,
        isLast: index == initial.steps.length - 1,
        isLastDisplayed: index == initial.steps.length - 1,
      })),
      lastCompletedStepIndex: -1,
      selectedStep: initial.steps[0],
      data: WizardState.getInitialBusinessData(initial)
    };
    state.data.email = initial.email;
    return new WizardState(state);
  };
  //---------------------------------------------------------------------------
  static restore(state: IWizardState): WizardState {
    const restoredState = new WizardState(state);
    restoredState.data.logo = restoredState.data.logo ? new TImageData(restoredState.data.logo) : undefined;
    restoredState.data.backgroundImage = restoredState.data.backgroundImage ? new TImageData(restoredState.data.backgroundImage) : undefined;
    restoredState.business = restoredState.business ? new ClassCustomerBusiness(restoredState.business) : undefined;
    restoredState.adLanguageSet = restoredState.adLanguageSet ? new AdLanguageSet(restoredState.adLanguageSet) : undefined;
    return restoredState;
  }
  //---------------------------------------------------------------------------
  static getInitialBusinessData(initial: IWizardStateInitial): IBusinessWizardData {
    return {
      businessId: uuidv4(),
      adLanguageSetId: uuidv4(),
      localeId: initial.locale,
      adRankId: initial.adRankId,
      tags: [],
      contacts: []
    };
  };
  //---------------------------------------------------------------------------
  private updateLastCompletedStepIndex() {
    const firstUncompletedStepIndex = this.steps.findIndex(step => !step.isCompleted);
    this.lastCompletedStepIndex = firstUncompletedStepIndex > 0 ? firstUncompletedStepIndex - 1 : -1;
  };
  //---------------------------------------------------------------------------
  private setStep(direction: EWizardDirection) {
    const nextStepIndex = direction == "Next" ?
      this.steps.findIndex(step => step.stepId == this.selectedStep) + 1 :
      this.steps.findIndex(step => step.stepId == this.selectedStep) - 1;
    //console.log(this.selectedStep, nextStepIndex)
    this.selectedStep = this.steps[nextStepIndex].stepId;
  };
  //---------------------------------------------------------------------------
  private getUpdatedSteps(): IWizardStep[] {
    const stepToUpdate = this.steps.find(step => step.stepId == this.selectedStep);
    if (!stepToUpdate) throw new Error(`Step ${this.selectedStep} not found`);
    stepToUpdate.isCompleted = true;
    const updatedSteps = this.steps.map(step => step.stepId == this.selectedStep ? stepToUpdate : step);
    return updatedSteps;
  };
  //---------------------------------------------------------------------------
  private setStateUncompleted() {
    this.business = undefined;
    this.adLanguageSet = undefined;
    const previewSearch = this.steps.find(step => step.stepId == "StepPreviewSearchResults");
    if (previewSearch) previewSearch.isCompleted = false;
    const previewHomepage = this.steps.find(step => step.stepId == "StepPreviewHomepageBanner");
    if (previewHomepage) previewHomepage.isCompleted = false;
    const previewBusinessPage = this.steps.find(step => step.stepId == "StepPreviewBusinessPage");
    if (previewBusinessPage) previewBusinessPage.isCompleted = false;
    //this.selectedStep = this.steps[this.lastCompletedStepIndex].stepId; // Bug #7925 confusing navigation
  };
  //---------------------------------------------------------------------------
  moveToPreviousStep(): WizardState {
    const updatedState = new WizardState({
      ...this
    });
    updatedState.setStep("Back");
    return updatedState;
  };
  //---------------------------------------------------------------------------
  moveToNextStep(completeSelectedStep?: boolean): WizardState {
    //console.log("moveToNextStep", completeSelectedStep ? "Update" : "Just move");
    const updatedSteps = completeSelectedStep == true ? this.getUpdatedSteps() : this.steps;
    const updatedState = new WizardState({
      ...this,
      steps: updatedSteps
    });
    updatedState.setStep("Next");
    return updatedState;
  };
  //---------------------------------------------------------------------------
  setStepById(stepId: EWizardStep): WizardState {
    const updatedState = new WizardState({
      ...this,
      selectedStep: stepId
    });
    return updatedState;
  };
  //---------------------------------------------------------------------------
  setNewBusinessData(business: ClassCustomerBusiness, adLanguageSet: AdLanguageSet): WizardState {
    const updatedState = new WizardState({
      ...this,
      business: business,
      adLanguageSet: adLanguageSet
    });
    return updatedState;
  };
  //---------------------------------------------------------------------------
  updateLocale(localeId: string): WizardState {
    const updatedSteps = this.getUpdatedSteps();
    const updatedData: IBusinessWizardData = {
      ...this.data,
      localeId: localeId
    };
    const updatedState = new WizardState({
      ...this,
      steps: updatedSteps,
      data: updatedData
    });
    updatedState.setStateUncompleted();
    return updatedState;
  };
  //---------------------------------------------------------------------------
  updateAdRank(adRankId: string): WizardState {
    const updatedSteps = this.getUpdatedSteps();
    const updatedData: IBusinessWizardData = {
      ...this.data,
      adRankId: adRankId
    };
    const updatedState = new WizardState({
      ...this,
      steps: updatedSteps,
      data: updatedData
    });
    updatedState.setStateUncompleted();
    return updatedState;
  };
  //---------------------------------------------------------------------------
  updateName(name: string): WizardState {
    const updatedSteps = this.getUpdatedSteps();
    const updatedData: IBusinessWizardData = {
      ...this.data,
      name: name
    };
    const updatedState = new WizardState({
      ...this,
      steps: updatedSteps,
      data: updatedData
    });
    updatedState.setStateUncompleted();
    return updatedState;
  };
  //---------------------------------------------------------------------------
  updateDescription(description: string): WizardState {
    const updatedSteps = this.getUpdatedSteps();
    const updatedData: IBusinessWizardData = {
      ...this.data,
      description: description
    };
    const updatedState = new WizardState({
      ...this,
      steps: updatedSteps,
      data: updatedData
    });
    updatedState.setStateUncompleted();
    return updatedState;
  };
  //---------------------------------------------------------------------------
  updateLogo(imageData?: TImageData): WizardState {
    const updatedSteps = this.getUpdatedSteps();
    const updatedData: IBusinessWizardData = {
      ...this.data,
      logo: imageData ? imageData : undefined
    };
    const updatedState = new WizardState({
      ...this,
      steps: updatedSteps,
      data: updatedData
    });
    updatedState.setStateUncompleted();
    return updatedState;
  };
  //---------------------------------------------------------------------------
  updateTags(tags: string[]): WizardState {
    const updatedSteps = this.getUpdatedSteps();
    const updatedData: IBusinessWizardData = {
      ...this.data,
      tags: tags
    };
    const updatedState = new WizardState({
      ...this,
      steps: updatedSteps,
      data: updatedData
    });
    updatedState.setStateUncompleted();
    return updatedState;
  };
  //---------------------------------------------------------------------------
  updateLocationData(locationData: ClassBusinessLocationData): WizardState {
    const updatedSteps = this.getUpdatedSteps();
    const updatedData: IBusinessWizardData = {
      ...this.data,
      locationData: locationData
    };
    const updatedState = new WizardState({
      ...this,
      steps: updatedSteps,
      data: updatedData
    });
    updatedState.setStateUncompleted();
    return updatedState;
  };
  //---------------------------------------------------------------------------
  updateContacts(contacts: Contact<string>[]): WizardState {
    const updatedSteps = this.getUpdatedSteps();
    const updatedData: IBusinessWizardData = {
      ...this.data,
      contacts: contacts
    };
    const updatedState = new WizardState({
      ...this,
      steps: updatedSteps,
      data: updatedData
    });
    updatedState.setStateUncompleted();
    return updatedState;
  };
  //---------------------------------------------------------------------------
  updateBackgroundImage(imageData?: TImageData): WizardState {
    const updatedSteps = this.getUpdatedSteps();
    const updatedData: IBusinessWizardData = {
      ...this.data,
      backgroundImage: imageData
    };
    const updatedState = new WizardState({
      ...this,
      steps: updatedSteps,
      data: updatedData
    });
    updatedState.setStateUncompleted();
    return updatedState;
  };
}

export interface IWizardStateInitial {
  steps: EWizardStep[];
  locale: string;
  adRankId: string;
  email?: string;
}

export interface IWizardStateUpdate {
  steps: EWizardStep[];
  locale: string;
}

export interface IBusinessWizardData {
  businessId: string;
  adLanguageSetId: string;
  localeId: string;
  adRankId?: string;
  name?: string;
  description?: string;
  logo?: TImageData;
  removedLogo?: TImageData;
  tags: string[];
  locationData?: ClassBusinessLocationData;
  contacts: Contact<string>[];
  backgroundImage?: TImageData;
  removedBackgroundImage?: TImageData;
  email?: string;
}