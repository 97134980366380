import React from 'react';
import styles from './ChecklistDocument.module.css';
import { DocumentDraftItem } from '../../../administrator-console/documents/document-editor/IDocumentDraftItem';
import ChecklistItems from './checklist-items/ChecklistItems';
import { IHoveredItem } from '../request-form/DocumentRequestForm';

interface IProps {
  data?: DocumentDraftItem[];
  isReadonly: boolean;
  onHover: (item?: IHoveredItem) => void;
  onReview?: (updatedItems: DocumentDraftItem[]) => void;
}

export default function ChecklistDocument(props: IProps) {
  //---------------------------------------------------------------------------
  if (props.data) return (
    <div className={styles.wrapper}>
      <ChecklistItems
        data={props.data}
        isReadonly={props.isReadonly}
        onHover={props.onHover}
        onReview={props.onReview}
      />
    </div>
  ); else return null;
}