import { useContext } from 'react';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import { WizardState } from '../IWizardState';
import { IUiBusinessWizard } from '../IUiBusinessWizardV2';
import GetDescription from '../../../common/functions/GetDescription';
import WizardStepWrapper from '../step-wrapper/WizardStepWrapper';
import { IUiOption } from '../../../common/options/IUiOption';
import FormOptions from '../../../common/form-options-bar/FormOptions';
import { getTUiMenuContent } from '../../../common/menu/menu-content/TUiMenuContent';
import InputText from '../../../common/input-v2/input-text/InputText';

interface IProps {
  state: WizardState;
  ui: IUiBusinessWizard;
  onUpdate: (state: WizardState) => void;
  onExit: () => void;
}

export default function StepName(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const stepUi = props.ui.stepsDictionary.find(item => item.id == props.state.selectedStep);
  const stepData = props.state.steps.find(item => item.stepId == props.state.selectedStep);
  const prompt = GetDescription(appUiContext, props.state.selectedStep, stepUi?.description);
  const stepIsCompleted = stepData?.isCompleted ? true : false;
  const name = props.state.data.name;
  //---------------------------------------------------------------------------
  const onUpdate = (value: string) => {
    // NOTE: use throttling here
    const updatedState = props.state.updateName(value);
    props.onUpdate(updatedState);
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (optionId: string) => {
    let updatedState: WizardState;
    switch (optionId) {
      case "OptionBack":
        updatedState = props.state.moveToPreviousStep();
        break;
      case "OptionNext":
        if (!name) return;
        updatedState = props.state.moveToNextStep(!stepIsCompleted);
        break;
      default:
        throw new Error(`StepName: Unknown optionId ${optionId}`);
    };
    props.onUpdate(updatedState);
  };
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  props.ui.menuContent.options.forEach(option => {
    switch (option.id) {
      case "OptionBack":
        updatedOptions.push({
          ...option,
          iconId: "doubleArrowLeft",
          isDisabled: false
        });
        break;
      case "OptionNext":
        updatedOptions.push({
          ...option,
          iconId: "doubleArrowRight",
          isDisabled: !name,
          isDefault: !!name
        });
        break;
    };
  });
  const formOptions =
    <FormOptions
      ui={getTUiMenuContent({ ...props.ui.menuContent, options: updatedOptions })}
      applyMobileStyle={false}
      alignLeft={true}
      onSelect={onOptionSelect}
    />;
  //---------------------------------------------------------------------------
  return (
    <WizardStepWrapper
      ui={props.ui}
      promptString={prompt}
      optionsElement={formOptions}
      onExit={props.onExit}
    >
      <InputText
        ui={{ ...props.ui.inputName, hideCaption: true }}
        data={name}
        isFocused={true}
        onUpdate={onUpdate}
      />
    </WizardStepWrapper>
  );
}