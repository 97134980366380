import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../../context/app-auth-context/AppAuthContext";
import { ContentDirectoryDraft, IContentDirectoryDraftInitial } from "../content-directory-translator/IContentDirectoryDraft";
import { apiBasePath, pathUpdateContentDirectoryDraft } from "../../../../../utils/apiPathConstant";
import { apiPostPrivate, checkResponse } from "../../../../../utils/api";

interface IProps {
  msalInstanse: IPublicClientApplication;
  authConfig?: IAuthConfig;
  directoryContent: ContentDirectoryDraft;
  isNew: boolean;
  abortSignal?: AbortSignal;
}

export async function saveContentDirectoryDraft(props: IProps): Promise<ContentDirectoryDraft> {
  if (!props.authConfig) throw new Error("saveContentDirectoryDraft: auth context is not provided");
  //---------------------------------------------------------------------------
  let docToSave = { ...props.directoryContent, locker: undefined };
  //---------------------------------------------------------------------------
  const uri = `${apiBasePath}${pathUpdateContentDirectoryDraft}?isNew=${props.isNew}`;
  const response = await apiPostPrivate(
    props.msalInstanse,
    props.authConfig,
    uri,
    docToSave,
    undefined,
    props.abortSignal
  );
  checkResponse(response, "saveContentDirectoryDraft");
  //---------------------------------------------------------------------------
  const translationDraft = new ContentDirectoryDraft().initialize(response?.content as IContentDirectoryDraftInitial);
  return (translationDraft);
}