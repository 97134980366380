import styles from './ChecklistItemAdModule.module.css';
import React, { useEffect, useState } from 'react';
import { ClassAdModule, IAdModuleParagraph } from '../../../../common/ad-modules/IAdModule';
import ChecklistItemWrapper from '../checklist-item-wrapper/ChecklistItemWrapper';
import ChecklistItemText from '../checklist-item-text/ChecklistItemText';
import ChecklistItemImage from '../checklist-item-image/ChecklistItemImage';
import { IContentUnit } from '../../../../common/content/IContentUnit';
import CheckListItemComplexIssue from '../checklist-item-complex-issue/CheckListItemComplexIssue';
import ChecklistItemParagraph from '../checklist-item-paragraph/ChecklistItemParagraph';
import ChecklistItemLocation from '../checklist-item-location/ChecklistItemLocation';
import ChecklistItemContact from '../checklist-item-contact/ChecklistItemContact';
import { IBusinessLocationData, IObjectLocation } from '../../../../business-space/business-editor/section-location/business-location-editor/IBusinessLocationData';
import { Contact } from '../../../../business-space/business-editor/section-contacts/contact/Contact';
import { IContentUnitImage } from '../../../../common/content/content-image/IContentUnitImage';
import { IContentUnitText } from '../../../../common/content/content-text/IContentUnitText';

interface IProps {
  data: ClassAdModule;
  isReadonly: boolean;
  isCollapsed: boolean;
  onHover?: (path: string[]) => void;
  onReview?: (updatedAdModule: ClassAdModule) => void;
}

export default function ChecklistItemAdModule(props: IProps) {
  if (!props.data.status) return null;
  const [collapsed, setCollapsed] = useState(true);
  const hideImage = !props.data.image?.imageData && props.data.image?.status?.statusId == "Approved";
  //--------------------------------------------------------------------------- Collapsed
  useEffect(() => {
    setCollapsed(props.isCollapsed || !!props.data.status?.isCompleted);
  }, [props.isCollapsed, props.data.status]);
  //---------------------------------------------------------------------------
  const onToggle = () => {
    setCollapsed(!collapsed);
  };
  //---------------------------------------------------------------------------
  const onHover = (isHovered: boolean) => {
    if (!props.onHover) return;
    const path: string[] = isHovered ? [props.data.id] : [];
    props.onHover(path);
  };
  //---------------------------------------------------------------------------
  const onHeaderHover = (isHovered: boolean) => {
    if (!props.onHover) return;
    if (!props.data.header) return;
    const path: string[] = isHovered ? [props.data.id, props.data.header.type] : [];
    props.onHover(path);
  };
  //---------------------------------------------------------------------------
  const onDescriptionHover = (isHovered: boolean) => {
    if (!props.onHover) return;
    if (!props.data.description) return;
    const path: string[] = isHovered ? [props.data.id, props.data.description.type] : [];
    props.onHover(path);
  };
  //---------------------------------------------------------------------------
  const onBackgroundHover = (isHovered: boolean) => {
    if (!props.onHover) return;
    if (!props.data.image) return;
    const path: string[] = isHovered ? [props.data.id, props.data.image.type] : [];
    props.onHover(path);
  };
  //---------------------------------------------------------------------------
  const onParagraphHover = (path: string[]) => {
    if (!props.onHover) return;
    let paragraphPath = path;
    if (path.length > 0) {
      paragraphPath = [props.data.id].concat(path);
    };
    props.onHover(paragraphPath);
  };
  //---------------------------------------------------------------------------
  const onHeaderReview = (updatedTextUnit: IContentUnitText) => {
    if (props.isReadonly) return;
    const updatedAdModule = new ClassAdModule({
      ...props.data,
      header: updatedTextUnit
    }, true);
    props.onReview?.(updatedAdModule);
  };
  //---------------------------------------------------------------------------
  const onDescriptionReview = (updatedTextUnit: IContentUnitText) => {
    if (props.isReadonly) return;
    const updatedAdModule = new ClassAdModule({
      ...props.data,
      description: updatedTextUnit
    }, true);
    props.onReview?.(updatedAdModule);
  };
  //---------------------------------------------------------------------------
  const onBackgroundImageReview = (updatedImageUnit: IContentUnitImage) => {
    if (props.isReadonly) return;
    console.log("ChecklistItemAdModule.onBackgroundImageReview");
    const updatedAdModule = new ClassAdModule({
      ...props.data,
      image: updatedImageUnit
    }, true);
    //updatedAdModule.updateReviewStatus();
    props.onReview?.(updatedAdModule);
  };
  //---------------------------------------------------------------------------
  const onLocationReview = (updatedLocationUnit: IObjectLocation<IContentUnitText>) => {
    if (props.isReadonly) return;
    if (!props.data.businessLocationData) return;
    const updatedLocations = props.data.businessLocationData?.locations?.map(location =>
      location.id == updatedLocationUnit.id ? updatedLocationUnit : location);
    const updatedLocationData: IBusinessLocationData<IContentUnitText> = {
      ...props.data.businessLocationData as IBusinessLocationData<IContentUnitText>,
      locations: updatedLocations ? updatedLocations : []
    };
    const updatedAdModule = new ClassAdModule({
      ...props.data,
      businessLocationData: updatedLocationData
    }, true);
    props.onReview?.(updatedAdModule);
  };
  //---------------------------------------------------------------------------
  const onContactReview = (updatedContactUnit: Contact<IContentUnitText>) => {
    if (props.isReadonly) return;
    if (!props.data.contacts) return;
    const updatedContacts = props.data.contacts.map(contact =>
      contact.id == updatedContactUnit.id ? updatedContactUnit : contact);
    const updatedAdModule = new ClassAdModule({
      ...props.data,
      contacts: updatedContacts ? updatedContacts : []
    }, true);
    //updatedAdModule.updateReviewStatus();
    props.onReview?.(updatedAdModule);
  };
  //---------------------------------------------------------------------------
  const onParagraphReview = (updatedParagraph: IAdModuleParagraph) => {
    if (props.isReadonly) return;
    if (!props.data) return;
    //console.log("ChecklistItemAdModule.onComplexIssueReview");
    const updatedParagraphs = props.data.paragraphs?.map(paragraph =>
      paragraph.id == updatedParagraph.id ? updatedParagraph : paragraph);
    const updatedAdModule = new ClassAdModule({
      ...props.data,
      paragraphs: updatedParagraphs
    }, true);
    props.onReview?.(updatedAdModule);
  };
  //---------------------------------------------------------------------------
  const onComplexIssueReview = (updatedUnit: IContentUnit) => {
    if (props.isReadonly) return;
    if (!props.data) return;
    //console.log("ChecklistItemAdModule.onComplexIssueReview");
    const updatedIssues = props.data.issues?.map(issue =>
      issue.status?.rejectionReason?.reasonId == updatedUnit.status?.rejectionReason?.reasonId ?
        updatedUnit :
        issue);
    const updatedAdModule = new ClassAdModule({
      ...props.data,
      issues: updatedIssues
    }, true);
    props.onReview?.(updatedAdModule);
  };
  //--------------------------------------------------------------------------- Paragraphs
  const paragraphItems = props.data.paragraphs?.map(paragraph => (
    <ChecklistItemParagraph
      key={paragraph.id}
      level={1}
      data={paragraph}
      isReadonly={props.isReadonly}
      isCollapsed={collapsed}
      onHover={onParagraphHover}
      onReview={onParagraphReview}
    />));
  //--------------------------------------------------------------------------- Complex issues
  const complexIssueItems = props.data.issues?.map(complexIssue =>
    <CheckListItemComplexIssue
      key={`${props.data?.id}_${complexIssue.status?.rejectionReason?.reasonId}`}
      unitType="AdModule"
      data={complexIssue}
      level={1}
      isReadonly={props.isReadonly}
      onHover={onHover}
      onReview={onComplexIssueReview}
    />
  );
  //--------------------------------------------------------------------------- Locations
  const locationItems = props.data.businessLocationData?.locations?.map(location => (
    <ChecklistItemLocation
      adModuleId={props.data.id}
      key={location.id}
      data={location}
      isCollapsed={collapsed}
      isReadonly={props.isReadonly}
      onHover={props.onHover}
      onReview={onLocationReview}
    />
  ));
  //--------------------------------------------------------------------------- Contacts
  const contactItems = props.data.contacts?.map(contact => (
    <ChecklistItemContact
      adModuleId={props.data.id}
      key={contact.id}
      data={contact}
      isCollapsed={collapsed}
      isReadonly={props.isReadonly}
      onHover={props.onHover}
      onReview={onContactReview}
    />
  ));
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>

      {/* Ad Module Checklist item */}
      <ChecklistItemWrapper
        unitType="AdModule"
        status={props.data.status}
        isGroup={true}
        isReadonly={true} // NOTE: for now we can't review ad module as such (only its contents)
        onHover={onHover}
        caption={props.data.adModuleType}
        isCollapsed={collapsed}
        onToggle={onToggle}
      />

      {/* Ad Module Header */}
      {props.data.header && !collapsed &&
        <ChecklistItemText
          data={props.data.header}
          level={1}
          isReadonly={props.isReadonly}
          onHover={onHeaderHover}
          onReview={onHeaderReview}
        />}

      {/* Ad Module Description */}
      {props.data.description && !collapsed &&
        <ChecklistItemText
          data={props.data.description}
          level={1}
          isReadonly={props.isReadonly}
          onHover={onDescriptionHover}
          onReview={onDescriptionReview}
        />}

      {/* Ad Module image */}
      {props.data.image && props.data.image.status && !collapsed && !hideImage &&
        <ChecklistItemImage
          data={props.data.image}
          level={1}
          isReadonly={props.isReadonly}
          onHover={onBackgroundHover}
          onReview={onBackgroundImageReview}
        />}

      {/* Addressblock Locations */}
      {!collapsed && locationItems}

      {/* Addressblock Contacts */}
      {!collapsed && contactItems}

      {/* Paragraphs */}
      {!collapsed && paragraphItems}

      {/* Complex issues */}
      {!collapsed && complexIssueItems}

    </div>
  );
}