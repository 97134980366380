import React, { useContext, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { AppAuthContextStore } from "../../../context/app-auth-context/AppAuthContext";
import { AppUiContextStore } from "../../../context/app-ui-context/AppUiContextProvider";
import usePageTitle from "../../../hooks/usePageTitle";
import WikiSpinner from "../../app-layout/spinner/wikiSpinner";
import { TUiBreadcrumb } from "../../common/breadcrumbs-trail/breadcrumb/TUiBreadcrumb";
import ConsoleLayout from "../../common/console-layout/ConsoleLayout";
import { optionIdModeratorConsole, pathAdminConsole, pathModeratorConsole, pathModeratorConsolePathFull } from "../AdminConsole";
import { TUiModeratorConsole } from "./TUiModeratorConsole";
import { TUiNames } from "../../../context/app-ui-context/AppUiCache";
import { AbortControllerWrapper, useAbortController } from "../../../hooks/useAbortController";
import { IUiOption } from "../../common/options/IUiOption";
import { IUiApplication_ModeratorConsole, IUiModeratorConsole } from "./IUiModeratorConsole";
import { getTUiMenuContent } from "../../common/menu/menu-content/TUiMenuContent";
import RegionalHomepageRequestsList from "./regional-homepages/requests-list/RegionalHomepageRequestsList";
import RegionalHomepageRequestEditForm from "./regional-homepages/request-form/RegionalHomepageRequestEditForm";
import NavigateWithContext from "../../common/navigate-with-context/NavigateWithContext";
import CustomerAdRequestsList from "./customer-ads/requests-list/CustomerAdRequestsList";
import CustomerAdRequestEditForm from "./customer-ads/request-form/CustomerAdRequestEditForm";
import DocumentRequestsList from "./documents/requests-list/DocumentRequestsList";
import DocumentRequestForm from "./documents/request-form/DocumentRequestForm";
import { tmpUiEditformDocumentRequest } from "./documents/request-form/IUiEditformDocumentRequest";

export const pathApprovalRequests = "requests";
export const optionIdApprovalRequests = "ModeratorConsole.MenuContent.OptionAdPages";
export const pathRegionalHomepageRequests = "regionalhomepagerequests";
export const optionIdRegionalHomepageRequests = "ModeratorConsole.MenuContent.OptionRegionalHomepages";
export const pathDocuments = "documents";
export const optionIdDocuments = "AdministratorConsole.MenuContent.OptionDocuments";

const uiNameOld: TUiNames = "moderatorConsole_old";
const uiName: TUiNames = "moderatorConsole";

interface IProps {
  optionsForBreadcrumb?: IUiOption[];
}

export default function ModeratorConsole(props: IProps) {
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { appUiContext, appUiContextDispatch } = useContext(AppUiContextStore);
  const [uiOld, setUiOld] = useState<TUiModeratorConsole>();
  const [ui, setUi] = useState<IUiModeratorConsole>();
  const [isLoading, setIsLoading] = useState(true);
  const updateTitle = usePageTitle();
  const abortController = useAbortController("ModeratorConsole");
  //--------------------------------------------------------------------------- Title, abortController
  useEffect(() => {
    updateTitle("Moderator");
    return () => {
      abortController.abortOnUnmount();
    }
  }, []);
  //---------------------------------------------------------------------------
  useEffect(() => {
    // Old UI
    if (uiOld || !appUiContext.uiCache?.checkAuthorization(uiNameOld)) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    let controllerOld = abortController.newController("getUiCallback<TUiModeratorConsole>");
    appUiContext.uiCache.getUiCallback<TUiModeratorConsole>(
      uiNameOld,
      (ui) => !controllerOld.aborted && setUiOptions(ui),
      () => !controllerOld.aborted && setIsLoading(false)
    );
    //------------------------------------------------------------------------
    // New UI    
    if (ui || !appUiContext.uiCache?.checkAuthorization(uiName)) {
      setIsLoading(false);
      return;
    };
    setIsLoading(true);
    let controller = abortController.newController("GetUi");
    appUiContext.uiCache.getUiCallback<IUiApplication_ModeratorConsole>(
      uiName,
      (ui) => !controller.aborted && setConsoleUi(ui, controller),
      () => !controller.aborted && setIsLoading(false)
    );
  }, [appAuthContext]);
  //--------------------------------------------------------------------------- Breadcrumb
  useEffect(() => {
    const option = props.optionsForBreadcrumb?.find(o => o.id == optionIdModeratorConsole);
    if (option) {
      const breadcrumb: TUiBreadcrumb = {
        id: option.id,
        caption: option.caption,
        path: `/${pathAdminConsole}/${pathModeratorConsole}`
      };
      appUiContextDispatch({ type: "UpdateBreadcrumbsTrail", value: breadcrumb });
    };
  }, [props.optionsForBreadcrumb]);
  //---------------------------------------------------------------------------
  function setConsoleUi(consoleUi: IUiApplication_ModeratorConsole, controller: AbortControllerWrapper) {
    if (!controller.aborted) {
      // Set dictionaries
      if (consoleUi?.dictionaries) {
        appUiContextDispatch({ type: "UpdateDictionaries", value: consoleUi.dictionaries });
      };
      //-------------------------------------------------------------------------
      setUi(consoleUi.moderatorConsole);
    };
  };
  //---------------------------------------------------------------------------
  function setUiOptions(consoleUi: TUiModeratorConsole) {
    setUiOld(consoleUi);
  }
  //--------------------------------------------------------------------------- Menu options
  const updatedMenuContent = ui ? getTUiMenuContent(ui.menuContent) : undefined;
  updatedMenuContent?.options.forEach(option => {
    switch (option.id) {
      case optionIdApprovalRequests:
        option.iconFile = "moderator";
        option.action = `/${pathAdminConsole}/${pathModeratorConsole}/${pathApprovalRequests}`;
        break;
      case optionIdRegionalHomepageRequests:
        option.iconFile = "mapLocation";
        option.action = `/${pathAdminConsole}/${pathModeratorConsole}/${pathRegionalHomepageRequests}`;
        break;
      case optionIdDocuments:
        option.iconFile = "document";
        option.action = `/${pathAdminConsole}/${pathModeratorConsole}/${pathDocuments}`;
        break;
    };
  });
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>
      {uiOld && ui && updatedMenuContent &&
        <ConsoleLayout
          ui={{ header: "", menuContent: updatedMenuContent }}
          path={pathModeratorConsolePathFull}
          hideSidebar={true}
        >
          <WikiSpinner show={isLoading} />
          <Routes>

            <Route
              index
              element={<NavigateWithContext to={`/${pathAdminConsole}/${pathModeratorConsole}/${pathApprovalRequests}`} />} />
            <Route
              path={pathApprovalRequests}
              element={
                <CustomerAdRequestsList
                  ui={ui.listAdPageRequests}
                  optionsForBreadcrumb={ui.menuContent.options}
                />} />

            <Route
              path={`${pathApprovalRequests}/:requestId/:localeId`}
              element={
                <CustomerAdRequestEditForm
                  ui={ui.editFormRegionalHomepageRequest}
                />} />

            {/* <Route
              path={`${pathApprovalRequests}/:adPageId/:locale`}
              element={
                <ApprovalRequestForm ui={{
                  ...uiOld.approvalRequestEditForm,
                  requestStatuses: uiOld.requestStatuses,
                  requestRejectionReasons: uiOld.requestRejectionReasons
                }} />} /> */}

            <Route
              path={pathRegionalHomepageRequests}
              element={
                <RegionalHomepageRequestsList
                  ui={ui.listRegionalHomepageRequests}
                  optionsForBreadcrumb={ui.menuContent.options}
                />} />

            <Route
              path={`${pathRegionalHomepageRequests}/:requestId/:localeId`}
              element={
                <RegionalHomepageRequestEditForm
                  ui={ui.editFormRegionalHomepageRequest}
                />} />

            <Route
              path={pathDocuments}
              element={
                <DocumentRequestsList
                  //ui={ui.listRegionalHomepageRequests}
                  optionsForBreadcrumb={ui.menuContent.options}
                />} />

            <Route
              path={`${pathDocuments}/:requestId/:localeId`}
              element={
                <DocumentRequestForm
                  ui={tmpUiEditformDocumentRequest}
                />} />

          </Routes>
        </ConsoleLayout>}
    </React.Fragment>
  );
}
