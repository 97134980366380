import { IPublicClientApplication } from "@azure/msal-browser";
import { IAppAuthContext } from "../../app-auth-context/AppAuthContext";
import { IApiResponse, apiPostPrivate, checkResponse } from "../../../utils/api";
import { apiBasePath, pathAdLanguageSetUpdate, pathUpdateAdLanguageSetSystem } from "../../../utils/apiPathConstant";
import { stringFormatter } from "../../../utils/common";
import { AdLanguageSet, IAdLanguageSet } from "../../../components/ad-content-editor/IAdLanguageSet";
import { ClassImageEditorContext } from "../../../components/common/image-editor/image-editor-context/ImageEditorContextProvider";

interface IProps {
  msalInstance: IPublicClientApplication,
  authContext?: IAppAuthContext,
  isSystem: boolean,
  imageEditorContext?: ClassImageEditorContext,
  businessId?: string,
  adCampaignId?: string,
  adLanguageSet?: IAdLanguageSet,
  abortSignal?: AbortSignal
}

export async function updateAdLanguageSetAsync(props: IProps): Promise<AdLanguageSet> {
  //---------------------------------------------------------------------------
  if (!props.authContext) throw new Error("updateAdLanguageSetAsync: authcontext is not provided");
  if (!props.authContext.config) throw new Error("updateAdLanguageSetAsync: auth config is not set");
  if (!props.businessId) throw new Error("updateAdLanguageSetAsync: Business Id is not provided");
  if (!props.adCampaignId) throw new Error("updateAdLanguageSetAsync: Ad campaign Id is not provided");
  if (!props.adLanguageSet) throw new Error("updateAdLanguageSetAsync: Ad language Set object is not provided");
  if (!props.imageEditorContext) throw new Error("updateAdLanguageSetAsync: Image editor context is not provided");
  //---------------------------------------------------------------------------
  let saveImageResult = await props.imageEditorContext.uploadAllImagesForAsync(
    props.adLanguageSet.id,
    props.authContext,
    props.msalInstance,
    true
  );
  if (!saveImageResult) {
    throw `There was an error uploading images for Ad language set. Please try again later.`;
  }
  //---------------------------------------------------------------------------
  let response: IApiResponse | void = undefined;
  const apiUri = props.isSystem ? pathUpdateAdLanguageSetSystem : pathAdLanguageSetUpdate;
  response = await apiPostPrivate(
    props.msalInstance,
    props.authContext.config,
    `${apiBasePath}${stringFormatter(apiUri, [props.businessId, props.adCampaignId])}`,
    props.adLanguageSet,
    undefined,
    props.abortSignal
  );
  //---------------------------------------------------------------------------
  checkResponse(response, "updateAdLanguageSetAsync", props.abortSignal?.aborted);
  const newAdLanguageSet = new AdLanguageSet(response?.content as IAdLanguageSet);
  return newAdLanguageSet;
}