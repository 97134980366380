import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../../../context/app-auth-context/AppAuthContext";
import { apiGetPrivate, checkResponse } from "../../../../../../utils/api";
import { apiBasePath, pathGetUserMessages } from "../../../../../../utils/apiPathConstant";
import { ClassUserMessage } from "../../../../../messages/Messages";

interface IProps {
  msalInstance: IPublicClientApplication;
  authConfig?: IAuthConfig;
  abortSignal?: AbortSignal;
}

export async function getUserMessagesAsync(props: IProps): Promise<ClassUserMessage[]> {
  if (!props.authConfig) throw new Error("getUserMessagesAsync: auth config is not provided");
  //---------------------------------------------------------------------------
  const response = await apiGetPrivate(
    props.msalInstance,
    props.authConfig,
    `${apiBasePath}${pathGetUserMessages}`,
    props.abortSignal
  );
  //---------------------------------------------------------------------------
  let messages: ClassUserMessage[] = [];
  if (response?.status !== 404) {
    checkResponse(response, "getUserMessagesAsync", props.abortSignal?.aborted);
    messages = ClassUserMessage.fromArray(response?.content);
  };
  return messages;
}