import { useContext, useEffect, useLayoutEffect, useRef } from "react";
import { useState } from "react";
import { AppUiContextStore } from "../../../context/app-ui-context/AppUiContextProvider";
import { ImageEditorContext, ClassImageEditorContext } from "./image-editor-context/ImageEditorContextProvider";
import { ImageEditorSession } from "./image-editor-context/ImageEditorSession";
import styles from "./ImageEditor.module.css";
import ImageEditorToolbar from "./ImageEditorToolbar";
import { EImageLoadingState, TImageData } from "./TImageData";
import { EImageEditorContextUpdate } from "./image-editor-context/ImageEditorContextReducer";
import React from "react";
import CombineStyles from "../../../utils/combineStyles";
import { TImageLibraryItem } from "../../user-console/user-images/image-library/TImageLibrary";
import ImageSelector from "./image-selector-popup/ImageSelector";
import { useAbortController } from "../../../hooks/useAbortController";
import ImageEditorCanvas from "./image-editor-canvas/ImageEditorCanvas";
import { EImageEditorAction } from "./image-editor-canvas/Crop";
import { ScreenType, useAppScreenContext } from "../../../context/app-screen-context/AppScreenProvider";
import UnsavedChangesDialog from "./unsaved-changes-dialog/UnsavedChangesDialog";
import InputDescription from "./input-description/InputDescription";
import { IUiOption } from "../options/IUiOption";
import { optionSeparator } from "./image-editor-option/ImageEditorOption";

const defaultDescription: string = "No Description";
export const copyImagePrefix = "copy_of_";

export enum EImageEditorMode {
  Image = 0,
  Placeholder = 1,
  Spinner = 2,
  Crop = 3,
  Description = 4,
  ImageLibrary = 5,
}

export enum EImageEditorCloseCommand {
  None = 0,
  Save = 1,
  Discard = 2,
  Dialog = 3,
}

// to enter crop and image library from normal window mode we need to enter in fullwindow mode first
// this enum provided to support this two phase entering process
// normal window -> full window -> crop or image library
enum EFullWindowTarget {
  None = 0,
  Crop = 1,
  ImageLibrary = 2,
}

function useImageEditorSession(id: string, imageEditorContext: ClassImageEditorContext) {
  const session = useRef<ImageEditorSession>();
  if (!session.current || session.current.imageId != id) {
    session.current = imageEditorContext.getSessionByImageId(id)
  }
  //console.log("useImageEditorSession:", imageEditorContext.lastChangedSessionUpdate);
  return session.current;
}


export interface IImageEditorProps {
  imageId: string;
  closeCommand?: EImageEditorCloseCommand; // command from parent component to start upload (for "Save" button)
  fullWindow: boolean;
  animationEnd?: boolean;
  onSessionOpened?: (session: ImageEditorSession) => void;
  onUrlAndBlobReady?: (imageData?: TImageData) => void;
  onUnsavedChangesDiscard?: (imageDataToRestore?: TImageData) => void;
  onToggleFullWindow?: () => void;
}

export default function ImageEditorV2(props: IImageEditorProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const { imageEditorContext, imageEditorDispatch } = useContext(ImageEditorContext);
  const screenType = useAppScreenContext();
  const [unsavedChangesDialogOpen, setUnsavedChangesDialogOpen] = useState(false);
  const [action, setAction] = useState<EImageEditorAction>(EImageEditorAction.None);
  const [mode, setModeState] = useState<EImageEditorMode>(EImageEditorMode.Image);
  const [description, setDescription] = useState<string>();
  const [editEnabled, setEditEnabled] = useState(false);
  const session = useImageEditorSession(props.imageId, imageEditorContext);
  const inputRef = useRef<HTMLInputElement>(null);
  const fullscreenRef = useRef<HTMLDivElement>(null);
  const fullWindowBeforeCrop = useRef(false);
  const [fullWindowTarget, setFullWindowTarget] = useState<EFullWindowTarget>(EFullWindowTarget.None);
  const [userImageCount, setUserImageCount] = useState<number>(0);
  const prevModeRef = useRef<EImageEditorMode>();
  const abortController = useAbortController("ImageEditorNew");
  const ui = ClassImageEditorContext.imageEditorUi;
  const removeImageDisabled = session?.canRemoveImage != undefined ? session.canRemoveImage : true;
  const optionsToolbar: IUiOption[] = [];
  const optionsInputDescription: IUiOption[] = [];
  //---------------------------------------------------------------------------
  useEffect(() => {
    let controller = abortController.newController("getUserImageCount");
    TImageData.getUserImageCount("", controller.signal)
      .then(count => {
        //console.log("IE.userImageCount:", count);
        !controller.aborted && setUserImageCount(count)
      });
    return (() => {
      abortController.abortOnUnmount();
    });
  }, []);
  //---------------------------------------------------------------------------
  useEffect(() => {
    //console.log("ImageEditor.useEffect[session]:", session);
    if (session) {
      //-------------------------------------------------------------
      // Old approach: edit enabled if image opened from library
      // let inLibrary = session.inLibrary;
      // console.log("ImageEditor.useEffect[session].inLibrary:", inLibrary);
      // if (inLibrary != undefined) {
      //   console.log("ImageEditor.useEffect[session].setEditEnabled(session.inLibrary):", session.inLibrary);
      //   setEditEnabled(inLibrary);
      // }
      //-------------------------------------------------------------
      // New approach: edit enabled due to the very fact of the session existence
      setEditEnabled(true);
      imageEditorDispatch({ type: "ImageEditorOpened", session: session });
      return;
    }
  }, [session]);
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (!imageEditorContext || !session || imageEditorContext.lastChangedSession != session)
      return;
    console.log("imageEditorContext.lastChangedSessionUpdate:", imageEditorContext.lastChangedSessionUpdate);
    let localMode = EImageEditorMode.Image;
    switch (imageEditorContext.lastChangedSessionUpdate) {
      case EImageEditorContextUpdate.SessionAdded:
      case EImageEditorContextUpdate.SessionActivated:
      case EImageEditorContextUpdate.ImageEditorOpened:
        console.log("imageEditorContext.lastChangedSessionUpdate:", imageEditorContext.lastChangedSessionUpdate);
        console.log("session.imageData.description:", session);
        setDescription(session.imageData.description);
        props.onSessionOpened && props.onSessionOpened(session);
        break;
      case EImageEditorContextUpdate.ImageTransformed:
        if (session.imageData.description && session.imageData.description != description)
          setDescription(session.imageData.description);
        break;
      case EImageEditorContextUpdate.ImageRemoved:
        setDescription(undefined);
        localMode = EImageEditorMode.Placeholder;
        //console.log("IE.useEffect[context]: image removed - setting mode to placeholder. session.loadingState:", session.loadingState);
        break;
    }
    switch (session.loadingState) {
      case undefined:
      case EImageLoadingState.Selected: {
        let controller = abortController.newController("getUrlCallback");
        console.log("IE.useEffect[imageEditorContext].EImageLoadingState.Selected: getUrlCallback")
        session.imageData.getUrlCallback(
          "Large",
          (uri) => {
            if (uri && !controller.aborted) {
              setMode(EImageEditorMode.Spinner);
              //session?.loadImageFromUrl(uri);
              session?.loadImage();
            }
            else if (!controller.aborted) {
              localMode = EImageEditorMode.Placeholder;
              //setMode(EImageEditorMode.Placeholder);
              //setEditEnabled(true);
            }
          },
          controller.signal);
      } break;
      //--------------------------------------------------------------------------
      case EImageLoadingState.Downloading:
      case EImageLoadingState.ImageUploading:
      case EImageLoadingState.DescriptionUploading:
        localMode = EImageEditorMode.Spinner;
        break;
      //--------------------------------------------------------------------------
      case EImageLoadingState.DownloadError:
        localMode = EImageEditorMode.Placeholder;
        break;
      //--------------------------------------------------------------------------
      case EImageLoadingState.ImageUploaded:
        //console.log("IE.useEffect[imageData]: status is Uploaded");
        //session.setLoadingState(EImageLoadingState.Opened);
        break;
      //--------------------------------------------------------------------------
      // case EImageLoadingState.Downloaded:
      //   //console.log("IE.useEffect[imageData]: status is Downloaded");
      //   if (session?.bitmap) {
      //     let transform = ImageTransformation.getInitialTransformation(session.bitmap);
      //     session.addNewTransform(transform);
      //     session.setLoadingState(EImageLoadingState.Opened);
      //   }
      //   if (mode == EImageEditorMode.Spinner)
      //     localMode = EImageEditorMode.Image;
      //   break;
      //--------------------------------------------------------------------------
      case EImageLoadingState.Opened:
        if (mode == EImageEditorMode.Spinner)
          localMode = EImageEditorMode.Image;
        //console.log("IE.ImageOpened.session:", session);
        break;
      //--------------------------------------------------------------------------
      case EImageLoadingState.DescriptionUploaded:
        if (mode == EImageEditorMode.Spinner)
          localMode = EImageEditorMode.Image;
        //session.setLoadingState(EImageLoadingState.Opened);
        break;
    };
    //console.log("IE.useEffect[context]: setting mode to localMode:", localMode);
    setMode(localMode);
  }, [imageEditorContext]);
  //---------------------------------------------------------------------------
  useEffect(() => {
    switch (props.closeCommand) {
      case EImageEditorCloseCommand.Save:
        startUrlAndBlobSave();
        break;
      case EImageEditorCloseCommand.Discard:
        session?.discardChanges();
        props.onUnsavedChangesDiscard && props.onUnsavedChangesDiscard(undefined);
        break;
      case EImageEditorCloseCommand.Dialog:
        setUnsavedChangesDialogOpen(true);
        break;
    }
  }, [props.closeCommand]);
  //---------------------------------------------------------------------------
  useLayoutEffect(() => {
    // this combination says that crop was requested from normal window mode,
    // so after entering in fullwindow we need to start crop

    //if (props.animationEnd != undefined)
    //return;
    if (screenType == ScreenType.Desktop)
      return;
    if (props.fullWindow) {
      switch (fullWindowTarget) {
        case EFullWindowTarget.Crop:
          //console.log("IE.useLayoutEffect[fullWindow]: setMode(EImageEditorMode.Crop)");
          setMode(EImageEditorMode.Crop);
          break;
        case EFullWindowTarget.ImageLibrary:
          setMode(EImageEditorMode.ImageLibrary);
          break;
      }
      //setFullWindowTarget(EFullWindowTarget.None);
    }
  }, [props.fullWindow]);
  //---------------------------------------------------------------------------
  useLayoutEffect(() => {
    // this effect supports two phase crop entering process: 
    // normal window -> full window -> crop
    switch (fullWindowTarget) {
      case EFullWindowTarget.Crop:
        if (!props.fullWindow)
          toggleFullWindowMode();
        else
          setMode(EImageEditorMode.Crop);
        break;
      case EFullWindowTarget.ImageLibrary:
        if (!props.fullWindow)
          toggleFullWindowMode();
        else
          setMode(EImageEditorMode.ImageLibrary);
        break;
    }
  }, [fullWindowTarget]);
  //---------------------------------------------------------------------------
  useLayoutEffect(() => {
    if (!props.animationEnd)
      return;
    switch (fullWindowTarget) {
      case EFullWindowTarget.Crop:
        if (props.fullWindow) {
          setMode(EImageEditorMode.Crop);
        }
        break;
      case EFullWindowTarget.ImageLibrary:
        if (props.fullWindow) {
          setMode(EImageEditorMode.ImageLibrary);
        }
        break;
      default:
        //setMode(EImageEditorMode.Image);
        break;
    }
  }, [props.animationEnd]);
  //---------------------------------------------------------------------------
  useEffect(() => {
    //console.log("useEffect[mode]:", mode);
    switch (mode) {
      case EImageEditorMode.ImageLibrary:
      case EImageEditorMode.Crop: {
        setFullWindowTarget(EFullWindowTarget.None);
      }
    }
  }, [mode]);
  //---------------------------------------------------------------------------
  useEffect(() => {
    switch (action) {
      case EImageEditorAction.CropConfirm:
      case EImageEditorAction.CropDiscard:
        // this resides in useEffect because canvas must handle these actions first
        if (props.fullWindow != fullWindowBeforeCrop.current) {
          toggleFullWindowMode();
        }
        setMode(EImageEditorMode.Image);
        break;
    }
    setAction(EImageEditorAction.None);
  }, [action]);
  //---------------------------------------------------------------------------
  useEffect(() => {
    console.log("useEffect[description]:", description);
    session?.setDescription(description);
  }, [description]);
  //---------------------------------------------------------------------------
  const setMode = (value: EImageEditorMode) => {
    prevModeRef.current = mode;
    setModeState(value);
  };
  //---------------------------------------------------------------------------
  const onActionStart = (action: EImageEditorAction) => {
    // some actions require re-rendering and some don't
    // the latter are handled here and returned, 
    // while others set the "action" state to re-render.
    // in most cases it is a signal to canvas to do some transformations
    switch (action) {
      //-----------------------------------------------------------------------
      case EImageEditorAction.EnterEditMode:
        //tryEnterEditMode();
        return;
      //-----------------------------------------------------------------------
      case EImageEditorAction.SelectImage:
        onImageSelectTrigger();
        return;
      //-----------------------------------------------------------------------
      case EImageEditorAction.SelectImageFromGallery:
        fullWindowBeforeCrop.current = props.fullWindow;
        setFullWindowTarget(EFullWindowTarget.ImageLibrary);
        return;
      //-----------------------------------------------------------------------
      case EImageEditorAction.ImageFromGalleryDiscard:
        if (prevModeRef.current)
          setMode(prevModeRef.current);
        else
          setMode(EImageEditorMode.Image);
        if (props.fullWindow != fullWindowBeforeCrop.current) {
          toggleFullWindowMode();
        }
        return;
      //-----------------------------------------------------------------------
      case EImageEditorAction.Undo:
      case EImageEditorAction.Redo:
        if (session) {
          let index = action == EImageEditorAction.Undo ? session.transformIndex - 1 : session.transformIndex + 1;
          session?.setTransformIndex(index);
        }
        setAction(action);
        break;
      //-----------------------------------------------------------------------
      case EImageEditorAction.CropStart:
        fullWindowBeforeCrop.current = props.fullWindow;
        //console.log("IE.onActionStart.fullWindowBeforeCrop:", props.fullWindow);
        setFullWindowTarget(EFullWindowTarget.Crop);
        setAction(action);
        break;
      //-----------------------------------------------------------------------
      case EImageEditorAction.CropConfirm:
      case EImageEditorAction.CropDiscard:
        setAction(action);
        // moved to useEffect[action]
        break;
      //-----------------------------------------------------------------------
      case EImageEditorAction.ImageDescriptionStart:
        setMode(EImageEditorMode.Description);
        return;
      //-----------------------------------------------------------------------
      case EImageEditorAction.ImageDescriptionConfirm:
        session?.setDescription(description);
        setMode(EImageEditorMode.Image);
        return;
      //-----------------------------------------------------------------------
      case EImageEditorAction.ImageDescriptionDiscard:
        if (session?.imageData)
          setDescription(session.imageData.description);
        setMode(EImageEditorMode.Image);
        return;
      //-----------------------------------------------------------------------
      case EImageEditorAction.UrlAndBlobSave:
        startUrlAndBlobSave();
        return;
      //-----------------------------------------------------------------------
      case EImageEditorAction.ToggleFullScreenMode:
        toggleFullWindowMode();
        return;
      //-----------------------------------------------------------------------
      case EImageEditorAction.RemoveImage:
        if (session) {
          session.removeImage(undefined);
          setMode(EImageEditorMode.Placeholder);
          // if (imageSessionId != session.imageId) {
          //   //console.log("IE.onActionStart: imageSessionId != session.imageId", imageSessionId, session.imageId);
          //   setImageSessionId(session.imageId);
          // }
        }
        return;
      default:
        setAction(action);
        break;
    }
  };
  //---------------------------------------------------------------------------
  const onUnsavedChangesDialogSave = () => {
    // If user selected save changes option in unsaved changes dialog
    // we need to start uploading process (passing updated imageEditorState to canvas component)
    // Once is completed canvas component will update image status, which will be catch by 'onImageEditorStateChange'
    //console.log(`IE.onUnsavedChangesDialogSave`);
    setUnsavedChangesDialogOpen(false);
    startUrlAndBlobSave();
  };
  //---------------------------------------------------------------------------
  const onUnsavedChangesDialogDiscard = () => {
    //console.log("discard");
    setUnsavedChangesDialogOpen(false);
    session?.discardChanges();
    // If user decided to discard changes, respond to caller with last uploaded image if there is one
    props.onUnsavedChangesDiscard && props.onUnsavedChangesDiscard(session?.initialImageData);
  };
  //---------------------------------------------------------------------------
  const startUrlAndBlobSave = () => {
    if (!session)
      return;
    if (session.imageRemoved == true) {
      props.onUrlAndBlobReady && props.onUrlAndBlobReady(undefined);
    }
    session.onUrlAndBlobReady = props.onUrlAndBlobReady;
    if (session.imageIsChanged) {
      setAction(EImageEditorAction.UrlAndBlobSave); // canvas will start to create blob and url
      setMode(EImageEditorMode.Spinner);
    }
    else if (session.descriptionIsChanged) {
      session.saveCurrentDescription();
    }
  };
  //---------------------------------------------------------------------------
  const onBlobReady = () => {
    setMode(EImageEditorMode.Image);
  };
  //---------------------------------------------------------------------------
  const selectLocalUrl = (localUrl: string, fileName: string, contentType: string) => {
    if (session) {
      let newGuid = session.imageData.isSystemImage;
      session.loadImageFromUrl(localUrl, newGuid, fileName, contentType);
    }
  };
  //---------------------------------------------------------------------------
  const onImageLibraryItemSelected = (item: TImageLibraryItem, asLink: boolean) => {
    if (props.fullWindow != fullWindowBeforeCrop.current) {
      toggleFullWindowMode();
    };
    session?.loadOtherImage(item, asLink);
    if (asLink) {
      setDescription(item.description);
      console.log("onImageLibraryItemSelected.setEditEnabled(false)");
      setEditEnabled(false);
    };
  };
  //---------------------------------------------------------------------------
  const onImageLibraryItemCancel = () => {
    onActionStart(EImageEditorAction.ImageFromGalleryDiscard);
  };
  //---------------------------------------------------------------------------
  const onDescriptionClick = () => {
    onActionStart(EImageEditorAction.ImageDescriptionStart)
  };
  //---------------------------------------------------------------------------
  const onDescriptionUpdate = (value: string) => {
    console.log("onDescriptionUpdate:", value);
    if (value == "")
      setDescription(undefined);
    else
      setDescription(value);
  };
  //---------------------------------------------------------------------------
  const onImageSelectTrigger = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.value = '';
      inputRef.current.click();
    };
  };
  //---------------------------------------------------------------------------
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let file = event.target.files?.item(0);
    file && selectLocalUrl(URL.createObjectURL(file), file.name, file.type);
  };
  //---------------------------------------------------------------------------
  const toggleFullWindowMode = () => {
    // if (!props.fullWindow)
    //   setFullWindowTarget(EFullWindowTarget.FullWindow);
    // else
    //   setFullWindowTarget(EFullWindowTarget.NormalWindow);
    //console.log("IE.toggleFullWindowMode:props.onToggleFullWindow", props.onToggleFullWindow);
    props.onToggleFullWindow && props.onToggleFullWindow();
  };
  //---------------------------------------------------------------------------
  const onToolbarOptionSelect = (optionId: string) => {
    let action: EImageEditorAction = EImageEditorAction.None;
    switch (optionId) {
      case "ImageEditor.OptionUndo":
        action = EImageEditorAction.Undo;
        break;
      case "ImageEditor.OptionRedo":
        action = EImageEditorAction.Redo;
        break;
      case "ImageEditor.OptionFlipHorz":
        action = EImageEditorAction.FlipHorizontal;
        break;
      case "ImageEditor.OptionFlipVert":
        action = EImageEditorAction.FlipVertical;
        break;
      case "ImageEditor.OptionRotateLeft":
        action = EImageEditorAction.RotateCounterclockwise;
        break;
      case "ImageEditor.OptionRotateRight":
        action = EImageEditorAction.RotateClockwise;
        break;
      case "ImageEditor.OptionCrop":
        action = EImageEditorAction.CropStart;
        break;
      case "ImageEditor.OptionFullscreen":
      case "ImageEditor.OptionUndoFullscreen":
        action = EImageEditorAction.ToggleFullScreenMode;
        break;
      case "OptionOk":
        action = EImageEditorAction.CropConfirm;
        break;
      case "OptionCancel":
        action = EImageEditorAction.CropDiscard;
        break;
    };
    onActionStart(action);
  };
  //---------------------------------------------------------------------------
  //let imageIsReadOnly = session?.isCurrentStateReadOnly;
  let allOptionsDisabled = false;
  switch (session?.loadingState) {
    case EImageLoadingState.Downloading:
    case EImageLoadingState.ImageUploading:
    case EImageLoadingState.DescriptionUploading:
      allOptionsDisabled = true;
      break;
  };
  ui?.menuContent.options.forEach(option => {
    switch (option.id) {
      case "ImageEditor.OptionUndo":
        if (mode != EImageEditorMode.Crop) {
          optionsToolbar.push({
            ...option,
            iconId: "undo",
            isDisabled: allOptionsDisabled || !session?.undoEnabled
          });
        };
        break;
      case "ImageEditor.OptionRedo":
        if (mode != EImageEditorMode.Crop) {
          optionsToolbar.push({
            ...option,
            iconId: "redo",
            isDisabled: allOptionsDisabled || !session?.redoEnabled
          });
        };
        break;
      case "ImageEditor.OptionFlipHorz":
        if (mode != EImageEditorMode.Crop) {
          optionsToolbar.push({
            ...option,
            iconId: "flipHorz",
            isDisabled: allOptionsDisabled
          });
        };
        break;
      case "ImageEditor.OptionFlipVert":
        if (mode != EImageEditorMode.Crop) {
          optionsToolbar.push({
            ...option,
            iconId: "flipVert",
            isDisabled: allOptionsDisabled
          });
        };
        break;
      case "ImageEditor.OptionRotateLeft":
        if (mode != EImageEditorMode.Crop) {
          optionsToolbar.push({
            ...option,
            iconId: "rotateLeft",
            isDisabled: allOptionsDisabled
          });
        };
        break;
      case "ImageEditor.OptionRotateRight":
        if (mode != EImageEditorMode.Crop) {
          optionsToolbar.push({
            ...option,
            iconId: "rotateRight",
            isDisabled: allOptionsDisabled
          });
        };
        break;
      case "ImageEditor.OptionCrop":
        if (mode != EImageEditorMode.Crop) {
          optionsToolbar.push({
            ...option,
            iconId: "crop",
            isDisabled: allOptionsDisabled
          });
          optionsToolbar.push(optionSeparator);
        };
        break;
      case "ImageEditor.OptionFullscreen":
        if (!props.fullWindow && mode != EImageEditorMode.Crop) {
          optionsToolbar.push({
            ...option,
            iconId: "fullScreenEnter",
            isDisabled: allOptionsDisabled
          });
        };
        break;
      case "ImageEditor.OptionUndoFullscreen":
        if (props.fullWindow && mode != EImageEditorMode.Crop) {
          optionsToolbar.push({
            ...option,
            iconId: "fullScreenExit",
            isDisabled: allOptionsDisabled
          });
        };
        break;
      case "OptionOk":
        optionsInputDescription.push(option);
        if (mode == EImageEditorMode.Crop) {
          optionsToolbar.push({
            ...option,
            iconId: "check",
            customColor: 'var(--clr_accent_main)',
            isDisabled: allOptionsDisabled
          });
        };
        break;
      case "OptionCancel":
        optionsInputDescription.push(option);
        if (mode == EImageEditorMode.Crop) {
          optionsToolbar.push({
            ...option,
            iconId: "close",
            customColor: 'var(--clr_accent_wrong)',
            isDisabled: allOptionsDisabled
          });
        };
    };
  });
  //---------------------------------------------------------------------------
  const descriptionText: string = description ?? defaultDescription;
  let currentTransformation = session?.transform;
  let showDescriptionInput = currentTransformation
    && currentTransformation.bitmap
    && !currentTransformation.imageData.isSystemImage
    && mode != EImageEditorMode.Placeholder
    && !props.fullWindow;
  // let onDescriptionClickHandler = undefined;
  // let descriptionCursorStyle = { cursor: '' };
  // if (mode == EImageEditorMode.Image && editEnabled) {
  //   onDescriptionClickHandler = onDescriptionClick;
  //   descriptionCursorStyle = { cursor: 'pointer' };
  // }
  //---------------------------------------------------------------------------
  let containerStyle = props.fullWindow ? CombineStyles([styles.fullWindow, appUiContext.theme.themeId]) : styles.container;
  //------------------------------------------------------------------------
  if (mode == EImageEditorMode.ImageLibrary) return (
    <div ref={fullscreenRef} className={containerStyle}>
      {ui &&
        <ImageEditorToolbar
          ui={ui.menuContent}
          onOptionSelect={onToolbarOptionSelect}

          session={session}
          removeImageDisabled={removeImageDisabled}
          mode={mode}
          fullWindow={props.fullWindow}
          userImageCount={userImageCount}
          editEnabled={editEnabled}
          onImageSelect={onImageSelectTrigger}
          onImageEditorAction={onActionStart}
        />}
      <ImageSelector
        onSelectImage={onImageLibraryItemSelected}
        onCancel={onImageLibraryItemCancel}
      />
    </div>);
  else return (
    <div
      ref={fullscreenRef}
      className={containerStyle}>

      {ui &&
        <ImageEditorToolbar
          ui={{ ...ui.menuContent, options: optionsToolbar }}
          onOptionSelect={onToolbarOptionSelect}

          session={session}
          removeImageDisabled={removeImageDisabled}
          mode={mode}
          fullWindow={props.fullWindow}
          userImageCount={userImageCount}
          editEnabled={editEnabled}
          onImageSelect={onImageSelectTrigger}
          onImageEditorAction={onActionStart}
        />}

      <ImageEditorCanvas
        session={session}
        placeholderHint={session?.placeholderHint}
        mode={mode}
        action={action}
        animationEnd={props.animationEnd}
        onImageSelect={onImageSelectTrigger}
        onBlobReady={onBlobReady} />

      <input
        title="ImageFileInput"
        type='file'
        inputMode="none"
        accept="image/*"
        ref={inputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange} />

      {
      // ui && showDescriptionInput &&
      //   <InputDescription
      //     ui={ui.inputDescription}
      //     data={descriptionText}
      //     options={optionsInputDescription}
      //     onUpdate={onDescriptionUpdate}
      //   />
        }

      {/* {!props.fullWindow &&
        <React.Fragment>

          {mode == EImageEditorMode.Description &&
            <div className={styles.descriptionContainer}>
              <InputText
                ui={uiInputText}
                data={description}
                focused={true}
                onUpdate={onDescriptionUpdate}
              />
            </div>}

          {mode != EImageEditorMode.Description
            && mode != EImageEditorMode.Placeholder
            //&& mode != EImageEditorMode.Crop
            && showDescriptionInput && !props.fullWindow &&
            <div
              className={styles.descriptionPlaceholder}
              style={descriptionCursorStyle}
              onClick={onDescriptionClickHandler}
            >
              <div onClick={onDescriptionClickHandler}>
                Image description:
              </div>
              <div
                className={styles.descriptionValue}
                onClick={onDescriptionClickHandler}
              >
                {descriptionText}
              </div>
            </div>}

        </React.Fragment>} */}

    </div>
  );
}