import { IDictionariesListItem } from "../../components/admin-console/common/dictionary-draft-models/IDictionariesListItem";
import { TUser } from "../app-auth-context/TUser";
import { IDirectoryOriginalAbstract } from "../IDirectoryOriginalAbstract";
import { IDirectoryDraftItemStateGroup } from "./directory-editor-settings/IDirectoryDraftItemStateGroup";
import { IDirectoryDraftAbstract, IDraftStateResult } from "./IDirectoryDraftAbstract";
import { EDirectoryType, IDictionary } from "./IDirectoryEditorContextData";

export interface IDirectoryEditorState {
  draft: IDirectoryDraftAbstract;
  draftInitial: IDirectoryDraftAbstract;
  original?: IDirectoryOriginalAbstract;
  //-----------------------------------
  isNew: boolean;
  isUpdatedInSession: boolean; // To know if we can save draft
  isUpdated: boolean;  // As compared to existing translation
  isValid: boolean;
  isReadonly: boolean;
  //historyRecords: IDocumentDraftHistoryRecord[];
  //lastHistoryRecord: IDocumentDraftHistoryRecord;
  //userPermissions: IUserPermissions;
}

export class DirectoryEditorState implements IDirectoryEditorState {
  //id!: string;
  //directoryType!: EDirectoryType;
  //basePath!: string; // Must include directory id
  //name?: string;
  //description?: string;
  //editMode!: boolean;
  //filter?: IDirectoryDraftFilter;
  //sourceLocale?: string;
  //dictionariesList?: IDictionariesListItem[];
  //-----------------------------------
  draft!: IDirectoryDraftAbstract;
  draftInitial!: IDirectoryDraftAbstract;
  original?: IDirectoryOriginalAbstract; // Each editor should decide what is the original in that particular case
  //-----------------------------------
  isNew!: boolean;
  isUpdatedInSession!: boolean; // To know if we can save draft
  isUpdated!: boolean;  // As compared to existing translation
  isValid!: boolean;
  isReadonly!: boolean;
  //---------------------------------------------------------------------------
  constructor(source: IDirectoryEditorState) {
    Object.assign(this, source);
  };
  //---------------------------------------------------------------------------
  static getInitialState(data: IDirectoryEditorStateInitial): DirectoryEditorState {
    const state = new DirectoryEditorState({
      //id: data.id,
      //directoryType: data.directoryType,
      //basePath: data.basePath,
      //name: data.name,
      //description: data.description,
      //editMode: data.editMode,
      // filter: {
      //   stateGroups: data.stateGroups
      // },
      draft: data.draft,
      draftInitial: data.draft,
      original: data.original,
      //dictionariesList: data.dictionariesList,
      isNew: data.isNew,
      isUpdatedInSession: false,
      isUpdated: false,
      isValid: true, // ???
      isReadonly: data.isReadonly ? data.isReadonly : false
    });
    // Get changes and validate
    const result: IDraftStateResult = state.draft.checkChangesAndValidate(state.draftInitial);
    state.isUpdatedInSession = result.isUpdatedInSession;
    state.isUpdated = result.isUpdated;
    state.isValid = result.isValid;
    return state;
  };
  //---------------------------------------------------------------------------
  static getUpdatedState(state: IDirectoryEditorState, update: IDirectoryEditorStateUpdate): IDirectoryEditorState {
    const updatedState = new DirectoryEditorState(state);
    Object.assign(updatedState, update);
    if (update.draft != undefined) {
      updatedState.draft = update.draft;
      // Get changes and validate
      const result: IDraftStateResult = updatedState.draft.checkChangesAndValidate(updatedState.draftInitial);
      updatedState.isUpdatedInSession = result.isUpdatedInSession;
      updatedState.isUpdated = result.isUpdated;
      updatedState.isValid = result.isValid;
    };
    return updatedState;
  };
  //---------------------------------------------------------------------------
  static restoreInitialState(state: IDirectoryEditorState): DirectoryEditorState {
    // Restore initial state and set edit mode to false
    const updatedState = new DirectoryEditorState(state);
    Object.assign(updatedState, {
      editMode: false,
      draft: state.draftInitial
    });
    return updatedState;
  };
}

export interface IDirectoryEditorStateInitial {
  id: string;
  directoryType: EDirectoryType;
  basePath: string;
  returnPath?: string;
  name?: string;
  description?: string;
  editMode: boolean;
  draft: IDirectoryDraftAbstract;
  original?: IDirectoryOriginalAbstract;
  stateGroups: IDirectoryDraftItemStateGroup[];
  isNew: boolean;
  dictionariesList?: IDictionariesListItem[];
  dictionaryOriginals?: IDictionary[];
  user: TUser;
  isReadonly?: boolean;
}

export interface IDirectoryEditorStateUpdate {
  id?: string;
  name?: string;
  description?: string;
  editMode?: boolean;
  draft?: IDirectoryDraftAbstract;
  //stateGroups: IDirectoryDraftItemStateGroup[];
  //dictionariesList?: IDictionariesListItem[];
}