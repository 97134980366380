import { IPublicClientApplication } from "@azure/msal-browser";
import { IAppAuthContext, IAuthConfig } from "../../../../../context/app-auth-context/AppAuthContext";
import { apiBasePath, pathUpdateDocumentDraft } from "../../../../../utils/apiPathConstant";
import { apiPostPrivate, checkResponse } from "../../../../../utils/api";
import { DocumentDraft } from "../document-editor/IDocumentDraft";
import { ClassImageEditorContext } from "../../../../common/image-editor/image-editor-context/ImageEditorContextProvider";

interface IProps {
  msalInstance: IPublicClientApplication;
  authContext?: IAppAuthContext;
  imageEditorContext?: ClassImageEditorContext;
  abortSignal?: AbortSignal;
  documentDraft: DocumentDraft;
}

export async function updateDocumentDraftAsync(props: IProps): Promise<DocumentDraft> {
  if (!props.authContext) throw new Error("updateDocumentDraftAsync: auth context is not provided");
  if (!props.imageEditorContext) throw new Error("updateDocumentDraftAsync: image editor context is not provided");
  //---------------------------------------------------------------------------
  let draftCopy = { ...props.documentDraft, locker: undefined };
  let uri = `${apiBasePath}${pathUpdateDocumentDraft}`;
  if (!draftCopy.summary.savedAt) {
    uri += `?isNew=true`;
  };
  if (!draftCopy.log?.actualRecordId) {
    draftCopy.log = undefined;
  };
  //---------------------------------------------------------------------------
  // Upload business images
  let saveImageResult = await props.imageEditorContext.uploadAllImagesForAsync(
    props.documentDraft.id,
    props.authContext,
    props.msalInstance,
    true
  );
  if (!saveImageResult) {
    throw `There was an error uploading images for business. Please try again later.`;
  }
  const response = await apiPostPrivate(
    props.msalInstance,
    props.authContext.config,
    uri,
    draftCopy,
    undefined,
    props.abortSignal
  );
  //---------------------------------------------------------------------------
  checkResponse(response, "updateDocumentDraftAsync", props.abortSignal?.aborted);
  const draft = new DocumentDraft().initialize(response?.content);
  return draft;
}