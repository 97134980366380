import { useEffect, useState } from "react";
import { TUiMenuContent } from "../menu/menu-content/TUiMenuContent";
import { TUiMenuOption } from "../menu/menu-content/menu-option/TUiMenuOption";
import styles from "./FormOptions.module.css";
import { ScreenType, useAppScreenContext } from "../../../context/app-screen-context/AppScreenProvider";
import FormOptionV2 from "./form-option/FormOptionV2";
import { ButtonType } from "../button/ButtonType";
import CombineStyles from "../../../utils/combineStyles";

// Component returns id of selected option
// If it's a sub-option, id would be composed using option hierarchy

interface IProps {
  ui: TUiMenuContent; // one option should be dots button to hide additional options on mobile
  applyMobileStyle?: boolean;
  optionIdVisibleOnMobile?: string; // option id that should be visible on mobile (the one on the left)
  alignLeft?: boolean;
  onSelect: (optionId: string) => void;
}

export default function FormOptions(props: IProps) {
  const screenType = useAppScreenContext();
  const [visibleOptions, setVisibleOptions] = useState<TUiMenuOption[]>();
  const applyMobileStyle = props.applyMobileStyle == undefined ? true : props.applyMobileStyle;
  //---------------------------------------------------------------------------
  useEffect(() => {
    const optionsProvided = props.ui.options;
    const dotsOption = props.ui.options.find(o => o.id == "DotsButton");
    if (!dotsOption) {
      optionsProvided.push({
        id: "DotsButton",
        directoryId: "",
        index: 0,
        //iconFile: "meatballsButton",
        iconFile: "kebabButton",
        elementType: "",
        priorityLevel: 0,
        caption: null,
        hint: null,
        isDefault: true,
        disabled: false,
        visible: true,
        canHideCaption: false,
        action: null
      })
    };
    //-------------------------------------------------------------------------
    var visibleOptions: TUiMenuOption[] = [];
    var hiddenOptions: TUiMenuOption[] = [];
    if (screenType == ScreenType.Desktop || !applyMobileStyle) {
      //-----------------------------------------------------------------------
      // Desktop options
      visibleOptions = props.ui.options.filter(option => option.visible && option.id != "DotsButton");
    } else {
      //-----------------------------------------------------------------------
      // Mobile options: display only two options - specified in props and dots one
      // if 'optionIdVisibleOnMobile' is not provided, only 'dots' option will be visible
      // other options will be visible in menu opened by dots button
      optionsProvided.forEach(option => {
        if (option.visible) {
          if (option.id == props.optionIdVisibleOnMobile || option.id == "DotsButton") {
            visibleOptions.push(option);
          } else {
            hiddenOptions.push(option);
          };
        };
      });
    };
    //-------------------------------------------------------------------------
    if (hiddenOptions.length <= 1) {
      // If the hidden options contain only one item, do not display 'dots' option
      const vo = visibleOptions.filter(option => option.id != "DotsButton");
      visibleOptions = vo.concat(hiddenOptions);
    } else {
      // Provide submenu for 'dots' option
      const dotsOption = visibleOptions.find(o => o.id == "DotsButton");
      if (dotsOption) {
        //---------------------------------------------------------------------------
        const hiddenOptionsMenuContent: TUiMenuContent | undefined = hiddenOptions ? {
          id: props.ui.id,
          directoryId: props.ui.directoryId,
          elementType: props.ui.elementType,
          hint: props.ui.hint,
          visible: true,
          disabled: false,
          options: hiddenOptions,
          optionGroups: []
        } : undefined;
        dotsOption.action = hiddenOptionsMenuContent;
      };
    };
    //-------------------------------------------------------------------------
    setVisibleOptions(visibleOptions);
    //setHiddenOptions(hiddenOptions);
  }, [props.ui.options, screenType]);
  //---------------------------------------------------------------------------
  const onOptionSelect = (optionId: string) => {
    if (optionId != "DotsButton")
      props.onSelect(optionId);
  };
  //---------------------------------------------------------------------------
  const visibleOptionElements = visibleOptions?.map(option =>
    <FormOptionV2
      key={option.id}
      ui={option}
      type={ButtonType.Toolbar}
      applyMobileStyle={applyMobileStyle}
      onSelect={onOptionSelect}
    />
  );
  //---------------------------------------------------------------------------
  return (
    <div
      id={props.ui.id}
      className={CombineStyles([
        styles.container,
        props.alignLeft && screenType == ScreenType.Desktop ? styles.alignLeft : "",
        (screenType == ScreenType.Mobile && applyMobileStyle) ? styles.mobileLayout : "",
        (screenType == ScreenType.Mobile) && applyMobileStyle ? styles.mobileOptions : ""
      ])}
    >
      {visibleOptionElements}
    </div>
  );
}