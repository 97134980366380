import { CSSProperties, ReactNode } from 'react';
import styles from './ChecklistNodeAttribute.module.css';
import Toggler from '../../../../common/toggler/Toggler';
import { v4 as uuidv4 } from "uuid";
import CombineStyles from '../../../../../utils/combineStyles';

interface IProps {
  children?: ReactNode;
  customStyles?: CSSProperties;
  level?: number;
  isExpanded?: boolean;
  paddingRight?: boolean;
  paddingLeft?: boolean;
  onToggle?: () => void;
}

export default function ChecklistNodeAttribute(props: IProps) {
  const showToggler = props.onToggle !== undefined;
  //---------------------------------------------------------------------------
  const indent = props.level ?
    // repeat indent div accordingly to the current level
    [...Array(props.level)].map(() => {
      return <div key={uuidv4()} className={styles.indent} />
    }) :
    <></>;
  //---------------------------------------------------------------------------
  return (
    <div
      className={CombineStyles([
        styles.container,
        props.paddingRight ? styles.paddingRight : '',
        props.paddingLeft ? styles.paddingLeft : ''
      ])}
      style={props.customStyles}
    >

      {indent}

      {showToggler &&
        <div
          className={styles.toggler}
          onClick={props.onToggle}
        >
          <Toggler collapsed={!props.isExpanded} />
        </div>}

      {props.children}

    </div>
  );
}