import { IContentUnitText } from "../../../../../common/content/content-text/IContentUnitText";
import { ClassContentUnitStatus } from "../../../../../common/content/IContentUnit";
import ChecklistNodeAttribute from "../../../checklist/checklist-node-attribute/ChecklistNodeAttribute";
import ChecklistNodeWrapper from "../../../checklist/checklist-node-wrapper/ChecklistNodeWrapper";

interface IProps {
  data: IContentUnitText;
  level?: number;
  isReadonly: boolean;
  onHover?: (hovered: boolean) => void;
  onReview?: (updatedTextUnit: IContentUnitText) => void;
}

export default function ChecklistItemText(props: IProps) {
  //---------------------------------------------------------------------------
  const onHover = () => {
    props.onHover && props.onHover(true);
  };
  //---------------------------------------------------------------------------
  const onHoverEnd = () => {
    props.onHover && props.onHover(false);
  };
  //---------------------------------------------------------------------------
  const onReview = (updatedStatus: ClassContentUnitStatus) => {
    const updatedUnit: IContentUnitText = {
      ...props.data,
      status: updatedStatus
    };
    props.onReview?.(updatedUnit);
  };
  //---------------------------------------------------------------------------
  if (props.data.status) return (
    <ChecklistNodeWrapper
      data={props.data.status}
      isReadonly={props.isReadonly}
      onHover={onHover}
      onHoverEnd={onHoverEnd}
      onReview={onReview}
    >

      <ChecklistNodeAttribute
        level={props.level}
        paddingRight
        customStyles={{ color: 'var(--clr_font_system)' }}
      >
        {props.data.type}
      </ChecklistNodeAttribute>

      <ChecklistNodeAttribute>
        {props.data.text}
      </ChecklistNodeAttribute>

    </ChecklistNodeWrapper>
  ); else return (
    <div>
      No status
    </div>
  );
}