import React, { CSSProperties, useState } from 'react';
import { IAdModule } from '../IAdModule';
import styles from './AdModuleHeader.module.css';
import CombineStyles from '../../../../utils/combineStyles';
import { IUiMenuContent } from '../../options/menus/IUiMenuContent';
import Icon from '../../icon/Icon';
import PopupMenu from '../../popup-v2/popup-menu/PopupMenu';
import { getTUiMenuContent } from '../../menu/menu-content/TUiMenuContent';
import { EOptionsAdModule } from '../../../ad-content-editor/ad-modules-editor/AdModuleOptions';
import IconInline from '../../icon/icon-inline/IconInline';
import { getUnitColor } from '../AdModule';
import { ITextAnimation } from '../../design-templates/IAnimation';
import { IDesignTemplateAdModule } from '../../design-templates/IDesignTemplateAdModule';
import { Property } from 'csstype';

interface IProps {
  ui?: IUiMenuContent;
  adModule: IAdModule;
  externalDesignTemplate?: IDesignTemplateAdModule;
  isReadonly: boolean;
  isClickable: boolean;
  highlightedPath?: string[];
  onHeaderClick?: EOptionsAdModule; // run onModuleOptionSelect callback with specified option id
  onModuleOptionSelect?: (optionId: EOptionsAdModule, adModuleId: string) => void;
}

export default function AdModuleHeader(props: IProps) {
  const [popupOpen, setPopupOpen] = useState(false);
  const headerText = props.adModule.header?.text;
  const descriptionText = props.adModule.description?.text;
  const moduleIsHighlighted = props.highlightedPath?.includes(props.adModule.id);
  const headerIsHighlighted = moduleIsHighlighted && props.highlightedPath?.includes("Header");
  const descriptionIsHighlighted = moduleIsHighlighted && props.highlightedPath?.includes("Description");
  //---------------------------------------------------------------------------
  const designTemplate: IDesignTemplateAdModule | undefined = props.externalDesignTemplate ? props.externalDesignTemplate : props.adModule.design;
  const animationTemplate: ITextAnimation | undefined = designTemplate?.header?.animation;
  // Do not show description if it's empty and it's readonly mode
  const hideDescription = props.isReadonly && !props.adModule.description?.text;
  const noWrap = designTemplate?.header?.noWrap ? true : false;
  //---------------------------------------------------------------------------
  let contextMenuFloating: boolean = props.adModule.adModuleType == "Photoblock" ? true : false;
  const menuElementId = `${props.adModule.id}_AdModuleContextMenu`;
  let showContextMenu = props.ui == undefined ? false : true;
  const contextMenu = props.ui?.options && props.ui.options.length > 0;
  if (!contextMenu) showContextMenu = false;
  const menuContent = props.ui ? getTUiMenuContent(props.ui) : undefined;
  //---------------------------------------------------------------------------
  const onHeaderClick = () => {
    if (props.isReadonly) return;
    if (!props.onModuleOptionSelect) return;
    const optionId = props.onHeaderClick ? props.onHeaderClick : "ModuleHeaderEdit";
    props.onModuleOptionSelect(optionId, props.adModule.id);
  };
  //---------------------------------------------------------------------------
  const onPhotoblockDescriptionClick = () => {
    if (props.isReadonly) return;
    if (!props.onModuleOptionSelect) return;
    const optionId = props.onHeaderClick ? props.onHeaderClick : "ModuleDescriptionEdit";
    props.onModuleOptionSelect(optionId, props.adModule.id);
  };
  //---------------------------------------------------------------------------
  const onContextMenuOpen = () => {
    setPopupOpen(true);
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (selectedOptionId: string) => {
    setPopupOpen(false);
    if (!props.onModuleOptionSelect) return;
    // Convert UI options to ad module options
    switch (selectedOptionId) {
      case "AdModule.MenuContent.OptionEditHeader":
        props.onModuleOptionSelect("ModuleHeaderEdit", props.adModule.id);
        break;
      case "AdModule.MenuContent.OptionEditText":
        props.onModuleOptionSelect("PhotoblockTextEdit", props.adModule.id);
        break;
      case "AdModule.MenuContent.OptionImageEdit":
        props.onModuleOptionSelect("ModuleBackgroundImageEdit", props.adModule.id);
        break;
      case "AdModule.MenuContent.OptionImageSelect":
        props.onModuleOptionSelect("ModuleBackgroundImageSelect", props.adModule.id);
        break;
      case "AdModule.MenuContent.OptionSelectTemplate":
      case "AdModule.MenuContent.OptionRefreshLocations":
      case "AdModule.MenuContent.OptionRefreshContacts":
        break;
      case "AdModule.MenuContent.OptionAddParagraph":
        props.onModuleOptionSelect("ModuleParagraphAdd", props.adModule.id);
        break;
      case "AdModule.MenuContent.OptionDelete":
        props.onModuleOptionSelect("ModuleDelete", props.adModule.id);
        break;
    };
  };
  //--------------------------------------------------------------------------- Set up styles based on template
  let dynamicClasses: string = CombineStyles([
    styles.container,
    designTemplate?.designTemplateId == "PhotoblockRegionalHomepage" ? styles.regionalHomepage : '',
    designTemplate?.header?.background ? styles[designTemplate?.header?.background.toLowerCase()] : '',
  ]);
  const dynamicStyles: CSSProperties = {
    cursor: !props.isReadonly || props.isClickable ? 'pointer' : 'default',
    marginTop: designTemplate?.header?.positionV == "Bottom" ? 'auto' : undefined,
    textAlign: designTemplate?.header?.positionH?.toLowerCase() as Property.TextAlign,
  };
  //--------------------------------------------------------------------------- Set up animation based on template
  if (animationTemplate) {
    switch (animationTemplate.type) {
      //-------------------------------------------------------------------
      case "ShowThrough":
        dynamicStyles.opacity = 1;
        dynamicStyles.animation = `showThrough ${animationTemplate.duration} ${animationTemplate.delay} ${animationTemplate.repeat}`;
        break;
      //-------------------------------------------------------------------
      case "SlideFromLeft":
        dynamicStyles.animation = `slideFromLeft ${animationTemplate.duration} ${animationTemplate.delay} ${animationTemplate.repeat}`;
        break;
    };
  };
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>
      <div
        className={dynamicClasses}
        style={dynamicStyles}
      >
        <div
          className={CombineStyles([
            styles.caption,
            contextMenuFloating ? '' : styles.default
          ])}>

          {/* Header */}
          <div
            //title={props.adModule?.header?.text}
            className={CombineStyles([
              noWrap ? styles.noWrap : ''
            ])}
            style={{
              fontSize: designTemplate?.header?.fontSizeEm ? `${designTemplate?.header?.fontSizeEm}em` : undefined,
              fontWeight: 'bold', // designTemplate?.header?.fontWeight == "Bold" ? 'bold' : undefined,
              color: headerIsHighlighted ? getUnitColor(props.adModule?.header?.status) : undefined,
              textShadow: headerIsHighlighted ? `0 0 5px black, 0 0 5px black` : undefined
            }}
            onClick={onHeaderClick}
          >
            <span>
              {headerText}
            </span>
            &nbsp;
            {!props.isReadonly &&
              <IconInline imageName="pencil" />}
          </div>


          {/* Description */}
          {props.adModule.adModuleType == "Photoblock" && !hideDescription &&
            <div
              //title={props.adModule.description?.text}
              className={CombineStyles([
                styles.description,
                noWrap ? styles.noWrap : ''
              ])}
              style={{
                fontSize: designTemplate?.description?.fontSizeEm ? `${designTemplate?.description?.fontSizeEm}em` : undefined,
                color: descriptionIsHighlighted ? getUnitColor(props.adModule?.description?.status) : undefined
              }}
              onClick={onPhotoblockDescriptionClick}
            >
              <span>
                {descriptionText}
              </span>
              &nbsp;
              {!props.isReadonly &&
                <IconInline imageName="pencil" />}
            </div>}

        </div>

        {/* Context menu: usual (kebab menu as part of the header) */}
        {!contextMenuFloating && showContextMenu &&
          <div
            id={menuElementId}
            className={styles.contextMenu}
            onClick={onContextMenuOpen}>
            <Icon imageName="kebabButton" />
          </div>}

      </div>

      {/* Context menu: floating (kebab menu in top right corner of photoblock) */}
      {contextMenuFloating && showContextMenu &&
        <div
          id={menuElementId}
          className={CombineStyles([styles.contextMenu, styles.floating])}
          onClick={onContextMenuOpen}>
          <Icon imageName="kebabButton" />
        </div>}

      {popupOpen && menuContent &&
        <PopupMenu
          anchorId={menuElementId}
          ui={menuContent}
          displayIcon={true}
          onClose={() => setPopupOpen(false)}
          onSelect={onOptionSelect}
        />}

    </React.Fragment>
  );
}