import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../../context/app-auth-context/AppAuthContext";
import { apiBasePath, pathCreateDocumentTranslation } from "../../../../../utils/apiPathConstant";
import { apiPostPrivate, checkResponse } from "../../../../../utils/api";
import { IDocumentsListItem } from "../documents-list/documents-list-item/IDocumentsListItem";
import { stringFormatter } from "../../../../../utils/common";

interface IProps {
  msalInstance: IPublicClientApplication;
  authConfig?: IAuthConfig;
  abortSignal?: AbortSignal;
  documentId: string;
  sourceLocale: string;
  targetLocale: string;
}

export async function createDocumentTranslationAsync(props: IProps): Promise<IDocumentsListItem[]> {
  if (!props.authConfig) throw new Error("createDocumentTranslationAsync: auth config is not provided");
  //---------------------------------------------------------------------------
  let uri = `${apiBasePath}${stringFormatter(pathCreateDocumentTranslation, [props.documentId, props.targetLocale, props.sourceLocale])}`;
  const response = await apiPostPrivate(
    props.msalInstance,
    props.authConfig,
    uri,
    undefined,
    undefined,
    props.abortSignal
  );
  //---------------------------------------------------------------------------
  checkResponse(response, "createDocumentTranslationAsync", props.abortSignal?.aborted);
  const documentListItems = response?.content as IDocumentsListItem[];
  return documentListItems;
}