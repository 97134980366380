import React, { useContext, useEffect, useRef, useState } from 'react';
import DocumentContent from './document-content/DocumentContent';
import { ReadableDocument } from './IReadableDocument';
import { IUiDocumentViewer } from './IUiDocumentViewer';
import { useParams } from 'react-router-dom';
import WikiSpinner from '../app-layout/spinner/wikiSpinner';
import ConsoleLayout from '../common/console-layout/ConsoleLayout';
import { pathLegalDocuments } from '../../App';
import { useAbortController } from '../../hooks/useAbortController';
import { getLegalDocumentsAsync } from './getLegalDocumentsAsync';
import { IUiOption } from '../common/options/IUiOption';
import { IUiMenuContent } from '../common/options/menus/IUiMenuContent';
import { getTUiMenuContent } from '../common/menu/menu-content/TUiMenuContent';
import { AppUiContextStore } from '../../context/app-ui-context/AppUiContextProvider';
import { TUiBreadcrumb } from '../common/breadcrumbs-trail/breadcrumb/TUiBreadcrumb';
import { NotificationContext } from '../../context/notification-context/NotificationContextProvider';
import useNavigateWithContext from '../../hooks/useNavigateWithContext';
import styles from './DocumentViewer.module.css';

interface IProps {
  ui: IUiDocumentViewer;
}

export default function DocumentViewer(props: IProps) {
  const { documentId } = useParams();
  const { appUiContext, appUiContextDispatch } = useContext(AppUiContextStore);
  const { notificationContextDispatch } = useContext(NotificationContext);
  const navigate = useNavigateWithContext();
  const [isLoading, setIsLoading] = useState(false);
  const [docs, setDocs] = useState<ReadableDocument[]>();
  const [doc, setDoc] = useState<ReadableDocument>();
  const abortController = useAbortController("DocumentViewer");
  //--------------------------------------------------------------------------- Abort controller
  useEffect(() => {
    return (() => {
      abortController.abortOnUnmount();
    });
  }, []);
  //---------------------------------------------------------------------------
  useEffect(() => {
    let controller = abortController.newController("getLegalDocumentsAsync");
    setIsLoading(true);
    getLegalDocumentsAsync({
      abortSignal: controller.signal,
      locale: appUiContext.locale.localeId
    }).then(docs => {
      setDocs(docs);
      if (documentId) {
        const doc = docs?.find(d => d.documentId.toLowerCase() == documentId.toLowerCase());
        setDoc(doc);
        navigate(`/${pathLegalDocuments}/${documentId}`);
      } else {
        console.log("naviate to");
        if (docs.length > 0)
          navigate(`/${pathLegalDocuments}/${docs[0].documentId}`);
        else
          navigate(`/${pathLegalDocuments}`);
      };
    }).catch(error => {
      !controller.aborted && notificationContextDispatch({ type: "SetError", header: "Document Viewer", message: error });
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
    //-------------------------------------------------------------------------
    const breadcrumb: TUiBreadcrumb = {
      id: "LegalDocs",
      caption: { en: "Legal Documents" },
      path: `/${pathLegalDocuments}`
    };
    appUiContextDispatch({ type: "UpdateBreadcrumbsTrail", value: breadcrumb });
  }, [appUiContext.locale.localeId]);
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (!docs) return;
    if (documentId) {
      const doc = docs.find(d => d.documentId.toLowerCase() == documentId.toLowerCase());
      setDoc(doc);
    } else {
      navigate(`/${pathLegalDocuments}/${docs[0].documentId}`);
    };
  }, [documentId]);
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (doc) {
      const breadcrumb: TUiBreadcrumb = {
        id: doc.documentId,
        caption: { en: doc.title },
        path: `/${pathLegalDocuments}/${doc.documentId}`
      };
      appUiContextDispatch({ type: "UpdateBreadcrumbsTrail", value: breadcrumb });
    };
  }, [doc]);
  //---------------------------------------------------------------------------
  const updatedMenuOptions = docs?.map(doc => {
    const menuItem: IUiOption = {
      id: doc.documentId,
      directoryId: "",
      elementType: "",
      iconId: "documentLegal",
      caption: { en: doc.title },
      hint: doc.description,
      action: `/${pathLegalDocuments}/${doc.documentId}`
    };
    return menuItem;
  });
  const updatedMenuContent: IUiMenuContent = {
    id: "",
    directoryId: "",
    elementType: "",
    hint: undefined,
    options: updatedMenuOptions ? updatedMenuOptions : []
  }
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>
      <WikiSpinner show={isLoading} />
      <ConsoleLayout
        ui={{ header: "", menuContent: getTUiMenuContent(updatedMenuContent) }}
        path={pathLegalDocuments}
        hideSidebar
        showTopLine={true}
      >


        {doc &&
          <div className={styles.container}>
            <DocumentContent
              ui={props.ui}
              data={doc}
            />
          </div>}

        {documentId && !doc && !isLoading &&
          <div style={{ flex: '1', display: 'flex', justifyContent: "center", alignItems: "center" }}>
            Document(s) not found
          </div>}

      </ConsoleLayout>
    </React.Fragment>
  );
}