import { useContext, useEffect, useRef, useState } from 'react';
import styles from './CustomerAdRequestsList.module.css';
import { useMsal } from '@azure/msal-react';
import { AppAuthContextStore } from '../../../../../context/app-auth-context/AppAuthContext';
import { AppUiContextStore } from '../../../../../context/app-ui-context/AppUiContextProvider';
import { ListManagerContext } from '../../../../../context/list-manager-context/ListManagerContextProvider';
import useNavigateWithContext from '../../../../../hooks/useNavigateWithContext';
import { useAbortController } from '../../../../../hooks/useAbortController';
import useFaviconAlert from '../../../../../hooks/useFaviconAlert';
import { EManagerMode, IFilterDynamicOptions } from '../../../../../context/list-manager-context/IListManagerContextData';
import { approvalRequestsListColumns } from './listSettings';
import { getListCustomerAdRequestAsync } from '../../functions/getListCustomerAdRequestAsync';
import { IUiListAdPageRequests } from '../../user-adpages/requests-list/IUiListAdPageRequests';
import { IUiOption } from '../../../../common/options/IUiOption';
import { TUiBreadcrumb } from '../../../../common/breadcrumbs-trail/breadcrumb/TUiBreadcrumb';
import { optionIdApprovalRequests, pathApprovalRequests } from '../../ModeratorConsole';
import { pathAdminConsole, pathModeratorConsole } from '../../../AdminConsole';
import ConsoleContent from '../../../../common/console-layout/console-content/ConsoleContent';
import GetHint from '../../../../common/functions/GetHint';
import WikiSpinner from '../../../../app-layout/spinner/wikiSpinner';
import ListColumnsManager from '../../../../common/lists/list-columns-manager/ListColumnsManager';
import CustomerAdRequestsListItem from './requests-list-item/CustomerAdRequestsListItem';
import { ICustomerAdRequestsListItem } from './requests-list-item/ICustomerAdRequestsListItem';
import GetName from '../../../../common/functions/GetName';
import { compareCustomerAdRequests, TCustomerAdRequestsListItemKey } from '../../functions/compareRequests';
import { generatePath } from 'react-router-dom';


interface IProps {
  ui: IUiListAdPageRequests;
  optionsForBreadcrumb?: IUiOption[];
}

export default function CustomerAdRequestsList(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { appUiContext, appUiContextDispatch } = useContext(AppUiContextStore);
  const { listManagerContext, listManagerContextDispatch } = useContext(ListManagerContext);
  const navigate = useNavigateWithContext();
  const abortController = useAbortController("RegionalHomepagesList");
  const [isLoading, setIsLoading] = useState(false);
  const [listItems, setListItems] = useState<ICustomerAdRequestsListItem[]>();
  const totalCount = useRef<number>(0);
  const setAlert = useFaviconAlert();
  //--------------------------------------------------------------------------- Data, ListManagerContext, AbortController
  useEffect(() => {
    if (listItems) return;
    setIsLoading(true);
    //-------------------------------------------------------------------------
    // Initialize list manager context
    listManagerContextDispatch({
      type: "Initialize",
      configuration: {
        listId: "RegionalHomepageRequests",
        useManagerModeSwitch: false,
        managerMode: EManagerMode.Advanced,
        listColumns: approvalRequestsListColumns
      }
    });
    //-------------------------------------------------------------------------
    // Get requests list data
    let controller = abortController.newController("getListCustomerAdRequest");
    getListCustomerAdRequestAsync({
      msalInstanse: instance,
      authConfig: appAuthContext.config,
      abortSignal: controller.signal
    }).then(items => {
      setListItems(items);
      totalCount.current = items.length;
    }).catch(error => {
      console.error(error);
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
    //-------------------------------------------------------------------------
    // Clean up
    return () => {
      abortController.abortOnUnmount();
    };
  }, []);
  //--------------------------------------------------------------------------- Breadcrumb
  useEffect(() => {
    const option = props.optionsForBreadcrumb?.find(o => o.id == optionIdApprovalRequests);
    if (option) {
      const breadcrumb: TUiBreadcrumb = {
        id: option.id,
        caption: option.caption,
        path: `/${pathAdminConsole}/${pathModeratorConsole}/${pathApprovalRequests}`
      };
      appUiContextDispatch({ type: "UpdateBreadcrumbsTrail", value: breadcrumb });
    };
  }, [props.optionsForBreadcrumb]);
  //--------------------------------------------------------------------------- Filter dynamic options
  useEffect(() => {
    if (!listItems) return;
    //-------------------------------------------------------------------------
    // Set alert if there are uncompleted requests
    const uncompletedRequests = listItems.filter(r => !r.dateTimeResolved);
    setAlert(uncompletedRequests.length > 0);
    //-------------------------------------------------------------------------
    const moderators: string[] = [];
    const locales: string[] = [];
    listItems?.forEach(request => {
      const username = request.actualLogRecord.assignedTo?.displayName;
      if (username && !moderators.includes(username)) {
        moderators.push(username);
      };
      if (!locales.includes(request.localeId)) {
        locales.push(request.localeId);
      };
    });
    //-------------------------------------------------------------------------
    const dynamicOptions: IFilterDynamicOptions[] = [{
      columnId: "moderator",
      options: moderators.map(m => ({ id: m, isDynamic: true }))
    }, {
      columnId: "locale",
      options: locales.map(localeId => {
        const dictionaryItem = appUiContext.dictionaries?.supportedLocales?.find(sl => sl.id == localeId);
        return ({
          id: localeId,
          name: GetName(appUiContext, localeId, dictionaryItem?.name),
          isDynamic: true
        });
      })
    }];
    listManagerContextDispatch({ type: "AddDynamicFilterOptions", dynamicOptions: dynamicOptions });
  }, [listItems]);
  //---------------------------------------------------------------------------
  const onSelect = (requestId: string, localeId: string) => {
    const pagePath = generatePath(
      `/${pathAdminConsole}/${pathModeratorConsole}/${pathApprovalRequests}/:requestId/:localeId`,
      { requestId: requestId, localeId: localeId });
    navigate(pagePath);
  };
  //---------------------------------------------------------------------------
  let filteredItems = listItems ? listItems : [];
  // Run through filter items
  listManagerContext.filterableColumns.forEach(column => {
    //-------------------------------------------------------------------------
    const selectedOptions = column.filter?.options?.filter(fi => fi.isSelected);
    if (selectedOptions) {
      switch (column.id) {
        //---------------------------------------------------------------------
        case "moderator":
          // If there are selected options in the group, run through them
          const dynamicOptions: string[] = [];
          selectedOptions.forEach(option => {
            switch (option.id) {
              case "Assigned":
                filteredItems = filteredItems.filter(r => r.actualLogRecord.assignedTo != undefined && !r.dateTimeResolved);
                break;
              case "Unassigned":
                filteredItems = filteredItems.filter(r => r.actualLogRecord.assignedTo == undefined);
                break;
              default:
                dynamicOptions.push(option.id);
                break;
            };
          });
          //-------------------------------------------------------------------
          // Apply dynamic filters
          if (dynamicOptions.length > 0) {
            filteredItems = filteredItems.filter(r => dynamicOptions.includes(r.actualLogRecord.assignedTo?.displayName));
          };
          break;
        //---------------------------------------------------------------------
        case "status":
          selectedOptions.forEach(option => {
            switch (option.id) {
              case "New":
                filteredItems = filteredItems.filter(r => r.status == "New");
                break;
              case "Uncompleted":
                filteredItems = filteredItems.filter(r => r.dateTimeResolved === undefined || r.dateTimeResolved == null);
                break;
              case "Completed":
                filteredItems = filteredItems.filter(r => r.dateTimeResolved !== undefined && r.dateTimeResolved !== null);
                break;
              case "Approved":
                filteredItems = filteredItems.filter(r => r.status == "Approved");
                break;
              case "Rejected":
                filteredItems = filteredItems.filter(r => r.status == "Rejected");
                break;
            };
          });
          break;
        //---------------------------------------------------------------------
        case "locale":
          const locales = selectedOptions.map(o => o.id);
          if (locales.length > 0)
            filteredItems = filteredItems.filter(r => locales.includes(r.localeId));
          break;
        //---------------------------------------------------------------------
        // case "Rank":
        //   // const freeRank = selectedOptions.find(o => o.id == "Free");
        //   // console.log(freeRank?.isSelected)
        //   // filteredRequests = filteredRequests.filter(r => !!r.isFreeAdCampaignRank);
        //   break;
      };
    };
    //-------------------------------------------------------------------------
    const keyword = column.filter?.keyword?.toLocaleLowerCase();
    if (keyword) {
      switch (column.id) {
        //---------------------------------------------------------------------
        case "userId":
          filteredItems = filteredItems.filter(request =>
            keyword ?
              request.createdBy.userId?.toString().toLocaleLowerCase().includes(keyword) :
              true);
          break;
        //---------------------------------------------------------------------
        default:
          filteredItems = filteredItems.filter(request =>
            keyword ?
              request[column.id as TCustomerAdRequestsListItemKey]?.toString().toLocaleLowerCase().includes(keyword) :
              true);
          break;
      };
    };
    //-------------------------------------------------------------------------
    if (column.filter?.type == "DatetimeRange") {
      filteredItems = filteredItems.filter(request => {
        const datetimeStart = column.filter?.dateTimeStart;
        const datetimeEnd = column.filter?.dateTimeEnd;
        const datetime = request[column.id as TCustomerAdRequestsListItemKey]?.toString();
        if (datetimeStart && datetimeEnd) {
          if (!datetime) return false;
          if (datetime >= datetimeStart && datetime <= datetimeEnd) {
            return true;
          } else
            return false;
        } else return true;
      });
    };
  });
  //--------------------------------------------------------------------------- Sort
  listManagerContext.sortableColumns.forEach(column => {
    filteredItems.sort((a, b) => column.sorter?.sort ?
    compareCustomerAdRequests(a, b, column.id, column.sorter.sort) :
      0
    );
  });
  //---------------------------------------------------------------------------
  const listItemElements = filteredItems.map(listItem =>
    <CustomerAdRequestsListItem
      key={listItem.id}
      data={listItem}
      onSelect={onSelect}
    />);
    console.log("listManagerContext:", listManagerContext);
  //---------------------------------------------------------------------------
  return (
    <ConsoleContent
      prompt={GetHint(appUiContext, props.ui.id, props.ui.hint)}
      containerWidthPercent={70}
    >
      <WikiSpinner show={isLoading} />

      <div className={styles.filter}>
        <ListColumnsManager
          numberTotal={listItems?.length}
          numberFiltered={filteredItems.length}
          showBasicModeSwitch={true}
        />
      </div>

      <div className={styles.container}>
        {listItemElements}
      </div>

    </ConsoleContent>
  );
}