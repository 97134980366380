import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import AdModule from "../../common/ad-modules/AdModule";
import { IDesignTemplateAdModule } from "../../common/design-templates/IDesignTemplateAdModule";
import { ClassAdModulePublished } from "../ClassAdLanguageSetPublished";
import styles from "./AdModuleWrapper.module.css";
import { PageViewProperties, TrackPageViewNames, useTracker } from "../../../utils/tracker";
import { useEffect, useRef } from "react";
import { HomePageBlock } from "./getAdsAsync";

declare global {
  interface Window {
    dataLayer:any;
  }
}
interface IProps {
  ui?: IDesignTemplateAdModule;
  homePageBlock: HomePageBlock;
  targetRef: any;
  onClick?: (businessId: string, localeId: string) => void;
}
//--------------------------------------------------------------------------
export function AdOnHomePage(props: IProps) {
  const tracker = useTracker();
  //------------------------------------------------------------------------
  useEffect(() => {
    tracker?.track("HomePage", props.homePageBlock);
  }, [props.homePageBlock]);
  //------------------------------------------------------------------------
  return (
    <div
      ref={props.targetRef}
      className={styles.item}>
      <AdModule
        externalDesignTemplate={props.ui}
        adModule={props.homePageBlock.photoblock}
        isReadonly={true}
        isClickable={true}
        useExternalLayout={true}
        onClick={props.onClick}
      />
    </div>
  );
}