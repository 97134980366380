import React, { useContext, useEffect, useState } from 'react';
import BusinessWizardWrapper from './business-wizard-wrapper/BusinessWizardWrapper';
import { businessWizardSteps, IWizardState, WizardState } from './IWizardState';
import { IUiBusinessWizard } from './IUiBusinessWizardV2';
import StepLocale from './steps/StepLocale';
import { AppUiContextStore } from '../../../context/app-ui-context/AppUiContextProvider';
import StepAdRank from './steps/StepAdRank';
import StepName from './steps/StepName';
import StepDescription from './steps/StepDescription';
import StepLogo from './steps/StepLogo';
import StepTags from './steps/StepTags';
import StepLocation from './steps/StepLocation';
import StepContacts from './steps/StepContacts';
import StepBackgroundImage from './steps/StepBackgroundImage';
import StepPreviewSearch from './steps/StepPreviewSearch';
import StepPreviewHomepage from './steps/StepPreviewHomepage';
import StepPreviewBusinessPage from './steps/StepPreviewBusinessPage';
import useBeforeUnload from '../../../hooks/useBeforeUnload';
import { NotificationContext } from '../../../context/notification-context/NotificationContextProvider';
import useNavigateWithContext from '../../../hooks/useNavigateWithContext';
import { INotificationContextData } from '../../../context/notification-context/INotificationContextData';
import { clearFromLS, getFromLS, setToLS } from '../../../utils/storage';
import { AppAuthContextStore } from '../../../context/app-auth-context/AppAuthContext';
import PopupMessage from '../../common/popup-v2/popup-message/PopupMessage';
import GetCaption from '../../common/functions/GetCaption';
import GetHint from '../../common/functions/GetHint';
import { getTUiMenuContent } from '../../common/menu/menu-content/TUiMenuContent';
import { BusinessContext } from '../../../context/business-context/BusinessContextProvider';
import { useParams } from 'react-router-dom';
import { pathAds, pathBusinessConsole, pathBusinesses } from '../business-console/BusinessConsole';
import StepPublish from './steps/StepPublish';
import { useAbortController } from '../../../hooks/useAbortController';
import { submitBusinessWithAdAsync } from './functions/submitBusinessWithAdAsync';
import { useMsal } from '@azure/msal-react';
import { ImageEditorContext } from '../../common/image-editor/image-editor-context/ImageEditorContextProvider';
import WikiSpinner from '../../app-layout/spinner/wikiSpinner';

interface IProps {
  ui: IUiBusinessWizard;
}

export default function BusinessWizard(props: IProps) {
  const { instance } = useMsal();
  const { appUiContext } = useContext(AppUiContextStore);
  const { appAuthContext, appAuthContextDispatch } = useContext(AppAuthContextStore);
  const { imageEditorContext } = useContext(ImageEditorContext);
  const { notificationContextDispatch } = useContext(NotificationContext);
  const { businessContextDispatch } = useContext(BusinessContext);
  const [state, setState] = useState<WizardState>();
  useBeforeUnload(state && state?.lastCompletedStepIndex > -1);
  const navigate = useNavigateWithContext();
  const abortController = useAbortController("BusinessWizard");
  const { role } = useParams();
  const [savedWizardPopupOpen, setSaveWizardPopupOpen] = useState(false);
  const [unsavedChangesPopupOpen, setUnsavedChangesPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const lsKey = `usr.${appAuthContext.user?.userIdentity.id}.wizard`;
  //--------------------------------------------------------------------------- State, cleanup
  useEffect(() => {
    // Hide main menu
    const mainMenuDiv = document.getElementById("mm");
    if (mainMenuDiv) mainMenuDiv.style.display = "none";
    //-------------------------------------------------------------------------
    // Check if there is saved wizard data in local storage
    const savedData = getFromLS(lsKey);
    if (savedData) {
      setSaveWizardPopupOpen(true);
    } else {
      initializeState();
    };
    //-------------------------------------------------------------------------
    return (() => {
      // Cleanup notification context
      notificationContextDispatch({ type: "Cleanup" });
      //appUiContextDispatch({ type: "SetPathToRemember", value: undefined });
      if (mainMenuDiv) mainMenuDiv.style.display = "flex";
    });
  }, []);
  //--------------------------------------------------------------------------- Notification context
  useEffect(() => {
    if (!state) return;
    // Once user completed a step, set notification context
    // so confirmation would be displayed if user tries to exit wizard, navigating to some other route
    if (state.lastCompletedStepIndex > -1) {
      // Set notification context
      notificationContextDispatch({
        type: "UnsavedChangesInitialize", data: {
          //ui: props.ui.unsavedChangesDialog,
          ui: props.ui.unsavedChangesDialog,
          notificationType: "Confirmation",
          pathUnsavedChanges: location.pathname,
          confirmOptionId: 'OptionDiscard',
          callback: saveStateForLaterUse
        }
      });
    };
  }, [state?.lastCompletedStepIndex]);
  //---------------------------------------------------------------------------
  const saveStateForLaterUse = (selectedOptionId: string, notificationState: INotificationContextData) => {
    if (selectedOptionId === "OptionSaveForLater") {
      //console.log("callbackFunction", notificationState.pathDestination)
      // Save current state to local storage and navigate to pathDestination, if provided
      setToLS(lsKey, state);
      //console.log("callbackFunction: navigating to", notificationState.pathDestination)
      notificationState?.unsavedChanges?.pathDestination && navigate(notificationState.unsavedChanges.pathDestination, undefined, true);
    };
  };
  //---------------------------------------------------------------------------
  const initializeState = () => {
    if (!appUiContext.dictionaries?.adRanks) {
      throw new Error("Business Wizard: adRanks dictionary is not provided");
    };
    let userEmail = appAuthContext.user?.userIdentity.mail;
    if (!userEmail) {
      userEmail = appAuthContext.user?.userIdentity.otherMails?.[0];
    };
    const initialState = WizardState.initialize({
      steps: businessWizardSteps,
      locale: appUiContext.locale.localeId,
      adRankId: appUiContext.dictionaries.adRanks[0].id,
      email: userEmail
    });
    setState(initialState);
  };
  //---------------------------------------------------------------------------
  const onSavedWizardDataDialogOptionSelect = (selectedOptionId: string) => {
    setSaveWizardPopupOpen(false);
    switch (selectedOptionId) {
      case 'OptionRestore':
        const savedData = getFromLS(lsKey) as IWizardState;
        const initialState = WizardState.restore(savedData);
        setState(initialState);
        //clearFromLS(lsKey);
        break;
      case 'OptionNew':
        initializeState();
        break;
    };
  };
  //---------------------------------------------------------------------------
  const onUnsavedChangesDialogOptionSelect = (selectedOptionId: string) => {
    setUnsavedChangesPopupOpen(false);
    switch (selectedOptionId) {
      case 'OptionDiscard':
        navigate(appUiContext.pathToRemember ? appUiContext.pathToRemember : "/", undefined, true);
        break;
      case 'OptionSaveForLater':
        setToLS(lsKey, state);
        navigate(appUiContext.pathToRemember ? appUiContext.pathToRemember : "/", undefined, true);
        break;
      default:
        break;
    };
  };
  //---------------------------------------------------------------------------
  const onStateUpdate = (state: WizardState) => {
    setState(state);
  };
  //---------------------------------------------------------------------------
  const onExit = () => {
    if (state?.lastCompletedStepIndex == -1) {
      // No steps completed, just exit wizard
      navigate(appUiContext.pathToRemember ? appUiContext.pathToRemember : "/");
    } else {
      // Ask user if they want to save data for later
      setUnsavedChangesPopupOpen(true);
    };
  };
  //---------------------------------------------------------------------------
  const onSubmit = () => {
    if (!state) return;
    if (!state.business) throw new Error("BusinessWizard: business is not set");
    if (!state.adLanguageSet) throw new Error("BusinessWizard: adLanguageSet is not set");
    //-------------------------------------------------------------------------   
    setIsLoading(true);
    let controller = abortController.newController("submitBusinessWithAdAsync");
    submitBusinessWithAdAsync({
      msalInstance: instance,
      authContext: appAuthContext,
      imageEditorContext: imageEditorContext,
      abortSignal: controller.signal,
      business: state.business,
      adLanguageSet: state.adLanguageSet,
      publish: true
    }).then(response => {
      businessContextDispatch({
        type: "SetContext",
        business: response.updatedBusiness,
        adLanguageSet: response.updatedAdLanguageSet,
        isNew: false
      });
      //-----------------------------------------------------------------------
      // Update user profile
      if (response.updatedUserRoles)
        appAuthContextDispatch({ type: "UpdateUserRoles", value: response.updatedUserRoles });
      //-----------------------------------------------------------------------
      // Update business tag requests, if there are any
      if (response.updatedBusinessTagRequests) {
        businessContextDispatch({ type: "SetBusinessTagRequests", requests: response.updatedBusinessTagRequests });
      };
      //-----------------------------------------------------------------------
      // Clear up local storage
      clearFromLS(lsKey);
      //-----------------------------------------------------------------------
      // Clear up notification context
      notificationContextDispatch({ type: "Cleanup" });
      //-----------------------------------------------------------------------
      // Navigate to business list
      navigate(`${pathBusinessConsole}/${role}/${pathBusinesses}`, undefined, true);
    }).catch(error => {
      notificationContextDispatch({ type: "SetError", header: "Business Wizard", message: error });
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const onOpenInEditor = () => {
    if (!state) return;
    if (!state.business) throw new Error("BusinessWizard: business is not set");
    if (!state.adLanguageSet) throw new Error("BusinessWizard: adLanguageSet is not set");
    //-------------------------------------------------------------------------
    // Set business context
    businessContextDispatch({
      type: "SetContext",
      business: state.business,
      adLanguageSet: state.adLanguageSet,
      isNew: true
    });
    //-------------------------------------------------------------------------
    // Navigate to ad content editor
    const adContentEditorPath = `${pathBusinessConsole}/${role}/${pathBusinesses}/${state.business.id}/${pathAds}/${state.adLanguageSet.id}`;
    navigate(adContentEditorPath, undefined, true);
  };
  //---------------------------------------------------------------------------
  let stepElement = null;
  switch (state?.selectedStep) {
    case "StepLocale":
      stepElement = <StepLocale state={state} ui={props.ui} onUpdate={onStateUpdate} onExit={onExit} />;
      break;
    case "StepAdRank":
      stepElement = <StepAdRank state={state} ui={props.ui} onUpdate={onStateUpdate} onExit={onExit} />;
      break;
    case "StepName":
      stepElement = <StepName state={state} ui={props.ui} onUpdate={onStateUpdate} onExit={onExit} />;
      break;
    case "StepDescription":
      stepElement = <StepDescription state={state} ui={props.ui} onUpdate={onStateUpdate} onExit={onExit} />;
      break;
    case "StepLogo":
      stepElement = <StepLogo state={state} ui={props.ui} onUpdate={onStateUpdate} onExit={onExit} />;
      break;
    case "StepTags":
      stepElement = <StepTags state={state} ui={props.ui} onUpdate={onStateUpdate} onExit={onExit} />;
      break;
    case "StepLocation":
      stepElement = <StepLocation state={state} ui={props.ui} onUpdate={onStateUpdate} onExit={onExit} />;
      break;
    case "StepContacts":
      stepElement = <StepContacts state={state} ui={props.ui} onUpdate={onStateUpdate} onExit={onExit} />;
      break;
    case "StepBackgroundImage":
      stepElement = <StepBackgroundImage state={state} ui={props.ui} onUpdate={onStateUpdate} onExit={onExit} />;
      break;
    case "StepPreviewSearchResults":
      stepElement = <StepPreviewSearch state={state} ui={props.ui} onUpdate={onStateUpdate} onExit={onExit} />;
      break;
    case "StepPreviewHomepageBanner":
      stepElement = <StepPreviewHomepage state={state} ui={props.ui} onUpdate={onStateUpdate} onExit={onExit} />;
      break;
    case "StepPreviewBusinessPage":
      stepElement = <StepPreviewBusinessPage state={state} ui={props.ui}
        onUpdate={onStateUpdate}
        onExit={onExit}
        onOpenEditor={onOpenInEditor}
      />;
      break;
    case "StepPublish":
      stepElement = <StepPublish state={state} ui={props.ui}
        onUpdate={onStateUpdate}
        onExit={onExit}
        onSubmit={onSubmit}
      />;
      break;
  };
  //---------------------------------------------------------------------------
  if (!state) return (
    <div>
      {savedWizardPopupOpen &&
        <PopupMessage
          id={props.ui.savedWizardDataDialog.id}
          type="Confirmation"
          header={GetCaption(appUiContext, props.ui.savedWizardDataDialog.id, props.ui.savedWizardDataDialog.caption)}
          message={GetHint(appUiContext, props.ui.savedWizardDataDialog.id, props.ui.savedWizardDataDialog.hint)}
          optionsMenuContent={getTUiMenuContent(props.ui.savedWizardDataDialog.menuContent)}
          show={true}
          onOptionSelect={onSavedWizardDataDialogOptionSelect}
          onCancel={() => setSaveWizardPopupOpen(false)}
        />}
    </div>
  ); else return (
    <React.Fragment>
      <WikiSpinner show={isLoading} />
      <BusinessWizardWrapper
        ui={props.ui}
        wizardState={state}
        onUpdate={onStateUpdate}
      >
        {stepElement}

        {unsavedChangesPopupOpen &&
          <PopupMessage
            id={props.ui.unsavedChangesDialog.id}
            type="Confirmation"
            header={GetCaption(appUiContext, props.ui.unsavedChangesDialog.id, props.ui.unsavedChangesDialog.caption)}
            message={GetHint(appUiContext, props.ui.unsavedChangesDialog.id, props.ui.unsavedChangesDialog.hint)}
            optionsMenuContent={getTUiMenuContent(props.ui.unsavedChangesDialog.menuContent)}
            show={true}
            onOptionSelect={onUnsavedChangesDialogOptionSelect}
            onCancel={() => setUnsavedChangesPopupOpen(false)}
          />}

      </BusinessWizardWrapper>
    </React.Fragment>
  );
}