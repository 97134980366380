import { useState } from 'react';
import Icon from '../../common/icon/Icon';
import styles from './Share.module.css';
import PopupInformer from '../../common/popup-v2/popup-informer/PopupInformer';

interface IProps {
  path: string;
}

export default function Share(props: IProps) {
  const [popupOpen, setPopupOpen] = useState(false);
  const linkAddress = `${location.protocol}//${location.host}${props.path}`;
  //---------------------------------------------------------------------------
  const copyLink = () => {
    navigator.clipboard.writeText(linkAddress);
    setPopupOpen(true);
  };
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>

      <div
        className={styles.item}
        onClick={copyLink}
      >
        <div className={styles.icon}>
          <Icon imageName='link' />
        </div>
        <div className={styles.content}>
          {linkAddress}
        </div>
        <div
          id="copyLink"
          className={styles.icon}
        >
          <Icon imageName='copy' />
        </div>
      </div>

      <div className={styles.item}>
        <div className={styles.icon}>
          <Icon imageName='facebook' />
        </div>
        <div className={styles.content}>
          Facebook
        </div>
        <div className={styles.icon}>
          <Icon imageName='share' />
        </div>
      </div>

      <div className={styles.item}>
        <div className={styles.icon}>
          <Icon imageName='linkedin' />
        </div>
        <div className={styles.content}>
          LinkedIn
        </div>
        <div className={styles.icon}>
          <Icon imageName='share' />
        </div>
      </div>

      <PopupInformer
        show={popupOpen}
        anchorId={"copyLink"}
        onClose={() => setPopupOpen(false)}
      >
        {/* GetCaption(appUiContext, props.ui.notificationCopied.id, props.ui.notificationCopied.caption) */}
        Link copied!
      </PopupInformer>

    </div>
  );
}