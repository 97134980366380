import { IAdCampaign, IAdRank } from "../../context/business-context/IAdCampaign";
import { ITrackable } from "../../utils/tracker";
import { EAdLanguageSetDesignTemplateId, IAdLanguageSet, IAdStatus } from "../ad-content-editor/IAdLanguageSet";
import { Contact } from "../business-space/business-editor/section-contacts/contact/Contact";
import { IBusinessLocationData } from "../business-space/business-editor/section-location/business-location-editor/IBusinessLocationData";
import { ClassAdModule, EAdModuleType, IAdModule, IAdModuleEditorStatus, IAdModuleParagraph, IAdModuleUsage } from "../common/ad-modules/IAdModule";
import { IContentUnitImage } from "../common/content/content-image/IContentUnitImage";
import { IContentUnitText } from "../common/content/content-text/IContentUnitText";
import { IContentUnit, IContentUnitStatus } from "../common/content/IContentUnit";
import { IDesignTemplateAdModule } from "../common/design-templates/IDesignTemplateAdModule";
import { TImageData } from "../common/image-editor/TImageData";

// These classes are different from ClassAdLanguageSet and ClassAdModule
// because we don't need here all the code handling drafts (statuses etc.)

export class ClassAdLanguageSetPublished implements IAdLanguageSet, ITrackable {
  id!: string;
  businessId!: string;
  localeId!: string;
  name?: string;
  description?: string;
  datetimeCreated!: string;
  designTemplateId?: EAdLanguageSetDesignTemplateId;
  adModules?: ClassAdModule[];
  status!: IAdStatus;
  adRank!: IAdRank;
  adCampaign!: IAdCampaign;
  get adCampaignId(): string {
    return this.adCampaign?.id;
  }
  set adCampaignId(value: string) {
  }
  //---------------------------------------------------------------------------
  constructor(source: IAdLanguageSet) {
    Object.assign(this, source);
    this.setup();
  };
  //---------------------------------------------------------------------------
  setup() {
    this.adModules?.map(adModule => new ClassAdModulePublished(adModule));
  };
  //-ITrackable----------------------------------------------------------------
  get text(): string {
    return this.name ? this.name : "";
  }
  get adLanguageSetId(): string | undefined {
    return this.id;
  }
};

export class ClassAdModulePublished implements IAdModule, ITrackable {
  id!: string;
  businessId!: string;
  localeId!: string;
  adModuleType!: EAdModuleType;
  design?: IDesignTemplateAdModule;
  image?: IContentUnitImage;
  header?: IContentUnitText;
  description?: IContentUnitText;
  paragraphs?: IAdModuleParagraph[];
  usage!: IAdModuleUsage;
  status?: IContentUnitStatus;
  issues?: IContentUnit[];
  editorStatus!: IAdModuleEditorStatus;
  businessLocationData?: IBusinessLocationData<IContentUnitText>;
  contacts?: Contact<IContentUnitText>[];
  //---------------------------------------------------------------------------
  constructor(source: IAdModule, refreshStatus: boolean = false) {
    // refreshStatus - if true, module status will be recalculated based on children items statuses
    // use this option for moderator review
    Object.assign(this, source);
    this.setup();
  };
  //---------------------------------------------------------------------------
  setup() {
    this.status = undefined;
    this.issues = undefined;
    // Ad Module Background Image
    if (this.image) {
      this.image.imageData = this.image?.imageData ? new TImageData(this.image.imageData) : undefined;
    };
    if (this.paragraphs) {
      this.paragraphs.forEach(paragraph => {
        if (paragraph.image) {
          paragraph.image.imageData = paragraph.image?.imageData ? new TImageData(paragraph.image.imageData) : undefined
        };
      });
    };
  };
  //-ITrackable----------------------------------------------------------------
  get text(): string {
    return this.header?.text ? this.header.text : "";
  }
  get adLanguageSetId(): string | undefined {
    return undefined;
  }
  get adCampaignId(): string | undefined {
    return undefined;
  }
  get adModuleId(): string | undefined {
    return this.id;
  }
}