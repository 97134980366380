import { useContext } from 'react';
import InputWrapper from '../../common/input-v2/input-wrapper/InputWrapper';
import InputsGroupWrapper from '../../common/input-v2/inputs-group-wrapper/InputsGroupWrapper';
import styles from './Languages.module.css';
import { AppUiContextStore } from '../../../context/app-ui-context/AppUiContextProvider';
import GetName from '../../common/functions/GetName';
import CombineStyles from '../../../utils/combineStyles';
import Icon from '../../common/icon/Icon';

interface IProps {
  currentLocale: string;
  availableLocales: string[];
  onSelect: (locale: string) => void;
}

export default function Languages(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  //---------------------------------------------------------------------------
  const dictionaryItems = appUiContext.dictionaries?.supportedLocales?.filter(item => props.availableLocales.includes(item.id));
  const localeNames = dictionaryItems?.map(item => {
    const isSelected = item.id == props.currentLocale;
    return (
      <div
        key={item.id}
        className={CombineStyles([
          styles.item,
          isSelected ? styles.selected : ''
        ])}
        onClick={!isSelected ?
          () => props.onSelect(item.id) :
          undefined}
      >

        <div className={styles.caption}>
          {GetName(appUiContext, item.id, item.name)}
        </div>

        {isSelected &&
          <div className={styles.icon}>
            <Icon imageName='check' />
          </div>}

      </div>
    )
  });
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>
      {localeNames}
    </div>
  );
}