import { useRef } from 'react';
import { IHighlightedItem } from '../document-content/DocumentContent';
import { ReadableDocumentItem } from '../IReadableDocument';
import styles from './DocumentItem.module.css';

interface IProps {
  data: ReadableDocumentItem;
  highligtedItem?: IHighlightedItem;
}

export default function DocumentTitle(props: IProps) {
  const divRef = useRef<HTMLDivElement>(null);
  const isHighlighted = props.highligtedItem?.path == props.data.fullPath;
  const headerIsHighlighted = props.highligtedItem?.path == `${props.data.fullPath}.header`;
  const annotationIsHighlighted = props.highligtedItem?.path == `${props.data.fullPath}.text`;
  const color = props.highligtedItem?.color ? props.highligtedItem.color : '';
  if (isHighlighted) {
    divRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
  };
  //---------------------------------------------------------------------------
  return (
    <header
      ref={divRef}
      className={styles.container}
      style={{ color: isHighlighted ? color : '' }}
    >
      <div
        className={styles.title}
        style={{ color: headerIsHighlighted ? color : '' }}
      >
        {props.data.header?.text}
      </div>
      <div
        style={{ color: annotationIsHighlighted ? color : '' }}
      >
        {props.data.text?.text}
      </div>
    </header>
  );
}