import { ReadableDocumentItem } from '../IReadableDocument';
import styles from './DocumentItem.module.css';
import DocumentItems from '../document-items/DocumentItems';
import { IHighlightedItem } from '../document-content/DocumentContent';
import { useRef } from 'react';

interface IProps {
  data: ReadableDocumentItem;
  subItems?: ReadableDocumentItem[];
  numberToDisplay?: string;
  highligtedItem?: IHighlightedItem;
}

export default function DocumentSection(props: IProps) {
  const divRef = useRef<HTMLDivElement>(null);
  const isHighlighted = props.highligtedItem?.path == props.data.fullPath;
  const headerIsHighlighted = props.highligtedItem?.path == `${props.data.fullPath}.header`;
  const annotationIsHighlighted = props.highligtedItem?.path == `${props.data.fullPath}.text`;
  const color = props.highligtedItem?.color ? props.highligtedItem.color : '';
  if (isHighlighted) {
    divRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
  };
  //---------------------------------------------------------------------------
  const header = props.data.header ?
    props.numberToDisplay ?
      `${props.numberToDisplay} ${props.data.header.text}` :
      props.data.header.text :
    '';
  //---------------------------------------------------------------------------
  return (
    <section
      ref={divRef}
      id={props.data.id}
      className={styles.container}
      style={{ color: isHighlighted ? color : '' }}
    >

      {header &&
        <div
          className={styles.sectionHeader}
          style={{ color: headerIsHighlighted ? color : '' }}
        >
          {header}
        </div>}

      {props.data.text &&
        <div
          style={{ color: annotationIsHighlighted ? color : '' }}
        >
          {props.data.text.text}
        </div>}

      {props.subItems &&
        <div className={styles.subItems}>
          <DocumentItems
            items={props.subItems}
            path={props.data.fullPath}
            prefix={props.numberToDisplay}
            highligtedItem={props.highligtedItem}
          />
        </div>}

    </section>
  );
}