import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../../context/app-auth-context/AppAuthContext";
import { apiBasePath, pathSubmitDocumentDraft } from "../../../../../utils/apiPathConstant";
import { apiPostPrivate, checkResponse } from "../../../../../utils/api";
import { DocumentDraft } from "../../../administrator-console/documents/document-editor/IDocumentDraft";
import { stringFormatter } from "../../../../../utils/common";

interface IProps {
  msalInstance: IPublicClientApplication;
  authConfig?: IAuthConfig;
  abortSignal?: AbortSignal;
  documentId: string;
  locale: string;
  isNew: boolean;
}

export async function submitDocumentDraftAsync(props: IProps): Promise<DocumentDraft> {
  if (!props.authConfig) throw new Error("submitDocumentDraftAsync: auth config is not provided");
  //---------------------------------------------------------------------------
  let uri = `${apiBasePath}${stringFormatter(pathSubmitDocumentDraft, [props.documentId, props.locale])}`;
  if (props.isNew) uri += "?isNew=true";
  const response = await apiPostPrivate(
    props.msalInstance,
    props.authConfig,
    uri,
    props.abortSignal
  );
  //---------------------------------------------------------------------------
  checkResponse(response, "submitDocumentDraftAsync", props.abortSignal?.aborted);
  const draft = new DocumentDraft().initialize(response?.content);
  return draft;
}