import styles from './BusinessStats.module.css';
import { useContext, useEffect, useState } from "react";
import { AppAuthContextStore } from "../../../../context/app-auth-context/AppAuthContext";
import { useMsal } from "@azure/msal-react";
import { apiGetPrivate, checkResponse } from "../../../../utils/api";
import { apiBasePath, pathGetUserBusinessesStats } from "../../../../utils/apiPathConstant";
import { stringFormatter } from "../../../../utils/common";
import { useAbortController } from "../../../../hooks/useAbortController";
import ConsoleContent from "../../../common/console-layout/console-content/ConsoleContent";
import WikiSpinner from "../../../app-layout/spinner/wikiSpinner";
import { PageViewCard } from "./pageview-card/PageViewCard";
import { AdCampaignPageViews, AdLanguageSetPageViews, BusinessPageViews, LanguagePageViews, PageViewFilter, PageViewType } from './BusinessPageViews';
import { ListManagerContext } from '../../../../context/list-manager-context/ListManagerContextProvider';
import { EManagerMode } from '../../../../context/list-manager-context/IListManagerContextData';
import ListColumnsManager from '../../../common/lists/list-columns-manager/ListColumnsManager';
import { IListColumn } from '../../../common/lists/IListColumn';
import { EHttpStatusCode } from '../../../../utils/HttpStatusCodes';
import { NotificationContext } from '../../../../context/notification-context/NotificationContextProvider';
import PopupMessage from '../../../common/popup-v2/popup-message/PopupMessage';
import { getTUiMenuContent } from '../../../common/menu/menu-content/TUiMenuContent';
//-----------------------------------------------------------------------------
const componentName = "Business statistics";
//-----------------------------------------------------------------------------
interface IProps {

}
//-----------------------------------------------------------------------------
const periodListColumn: IListColumn = {
  id: "Period",
  name: "Period",
  filter: {
    type: "DatetimeRange",
  },
}
//-------------------------------------------------------------
const initialListColumns: IListColumn[] = [
  periodListColumn,
  BusinessPageViews.initialColumn,
  AdCampaignPageViews.initialColumn,
  AdLanguageSetPageViews.initialColumn,
  LanguagePageViews.initialColumn,
  PageViewType.initialColumn,
];
//-----------------------------------------------------------------------------
export function BusinessStats(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { listManagerContext, listManagerContextDispatch } = useContext(ListManagerContext);
  const { notificationContextDispatch } = useContext(NotificationContext);
  const [isLoading, setIsLoading] = useState(false);
  const [pageViews, setPageViews] = useState<BusinessPageViews[]>();
  const [filter, setFilter] = useState<PageViewFilter>();
  const [message, setMessage] = useState<string>();
  const abortController = useAbortController("BusinessStats");
  //---------------------------------------------------------------------------
  useEffect(() => {
    //-------------------------------------------------------
    let endDate = new Date();
    let startDate = new Date(endDate);
    startDate.setDate(endDate.getDate() - 2);
    if (periodListColumn.filter) {
      periodListColumn.filter.dateTimeEnd = endDate.toISOString().split("T")[0];
      periodListColumn.filter.dateTimeStart = startDate.toISOString().split("T")[0];
    }
    console.log("initialListColumns:", initialListColumns);
    listManagerContextDispatch({
      type: "Initialize",
      configuration: {
        listId: "BusinessStatList",
        useManagerModeSwitch: false,
        managerMode: EManagerMode.Advanced,
        listColumns: initialListColumns
      }
    });
    //-------------------------------------------------------
    return () => {
      abortController.abortOnUnmount();
    }
  }, []);
  //---------------------------------------------------------------------------
  useEffect(() => {
    console.log("listManagerContext:", listManagerContext);
    if (!filter)
      return;
    switch (listManagerContext.lastAction?.type) {
      case 'ClearFilter':
      case 'AddDynamicFilterOptions':
      case 'UpdateFilterDatetimeEnd':
      case 'UpdateFilterDatetimeStart':
      case 'RestoreDefaults':
      case 'RestoreFilter':
      case 'UpdateFilterOption': {
        filter.enableAll();
        listManagerContext.filterableColumns.forEach(column => {
          const selectedOptions = column.filter?.options?.filter(fi => fi.isSelected);
          if (selectedOptions && selectedOptions.length > 0) {
            filter.setFilter(column.id, selectedOptions);
          }
        });
        setFilter(filter.clone());
      } break;
    }
  }, [listManagerContext]);
  //---------------------------------------------------------------------------
  const getStats = () => {
    let controller = abortController.newController("getStats");
    setIsLoading(true);
    setPageViews(undefined);
    setFilter(undefined);
    setMessage(undefined);
    let filter = listManagerContext.listColumns.find(item => item.id == "Period")?.filter;
    if (!filter || !filter.dateTimeStart || !filter.dateTimeEnd)
      return;
    let uri = `${apiBasePath}${stringFormatter(pathGetUserBusinessesStats, [
      filter.dateTimeStart, filter.dateTimeEnd])}`;
    console.log(uri);
    apiGetPrivate(
      instance, appAuthContext.config,
      uri,
      controller.signal
    )
      .then(response => {
        checkResponse(response, "getStats", controller.signal?.aborted, [EHttpStatusCode.NotFound]);
        if (response && response.status == EHttpStatusCode.NotFound) {
          return setMessage("No page views found. Try setting a different time period.")
        }
        let pageViews = BusinessPageViews.fromArray(response?.content);
        let filter = new PageViewFilter(pageViews);
        let dynamicOptions = filter.getDynamicOptions();
        console.log("BusinessStats.getStats:", pageViews);
        setPageViews(pageViews);
        setFilter(filter);
        listManagerContextDispatch({
          type: "AddDynamicFilterOptions",
          dynamicOptions: dynamicOptions
        });
      })
      .catch(error => {
        !controller.aborted && notificationContextDispatch({ type: "SetError", header: componentName, message: error });
      })
      .finally(() => {
        !controller.aborted && setIsLoading(false);
      });
  }
  //---------------------------------------------------------------------------  
  const pageViewCards = message == undefined
    ? pageViews?.map(item =>
      item.enabled ? <PageViewCard
        key={item.businessId}
        data={item}
      />
        : undefined
    )
    : <div className={styles.message}>
      {message}
    </div>;
  //---------------------------------------------------------------------------
  return (
    <ConsoleContent
      prompt={"Business Statistics"}
      containerWidthPercent={70}
    >
      <WikiSpinner show={isLoading} />
      <div className={styles.filter}>
        {listManagerContext.managerMode == EManagerMode.Advanced &&
          <ListColumnsManager
            numberTotal={0}
            numberFiltered={0}
            expandFilters={true}
            expandSorters={true}
            showBasicModeSwitch={true}
          />
        }
      </div>
      <div className={styles.list} key="list">
        <button type="button" key="button" onClick={getStats}>
          Get statistics
        </button>
        {pageViewCards}
      </div>
    </ConsoleContent>
  );
}