//import styles from './DocumentItems.module.css';

import React, { ReactNode } from "react";
import { ReadableDocumentItem } from "../IReadableDocument";
import DocumentTitle from "../document-item/DocumentTitle";
import DocumentSection from "../document-item/DocumentSection";
import DocumentParagraph from "../document-item/DocumentParagraph";
import { IHighlightedItem } from "../document-content/DocumentContent";

interface IProps {
  items?: ReadableDocumentItem[];
  path?: string;
  prefix?: string;
  highligtedItem?: IHighlightedItem;
}

export default function DocumentItems(props: IProps) {
  //---------------------------------------------------------------------------
  let i = 1;
  const items = props.items?.filter(item => item.path == props.path).map(documentItem => {
    let element: ReactNode;
    let number = '';
    if (documentItem.isNumbered) {
      number = `${i.toString()}.`;
      if (props.prefix) {
        number = `${props.prefix}${number}`;
      };
      i++;
    };
    //-------------------------------------------------------------------------
    const subItems = props.items?.filter(item => item.path?.startsWith(documentItem.fullPath));
    //-------------------------------------------------------------------------
    switch (documentItem.type) {
      case "Title":
        element =
          <DocumentTitle
            key={documentItem.id}
            data={documentItem}
            highligtedItem={props.highligtedItem}
          />;
        break;
      case "Section":
        element =
          <DocumentSection
            key={documentItem.id}
            data={documentItem}
            subItems={subItems}
            numberToDisplay={number}
            highligtedItem={props.highligtedItem}
          />;
        break;
      case "Paragraph":
        element =
          <DocumentParagraph
            key={documentItem.id}
            data={documentItem}
            numberToDisplay={number}
            highligtedItem={props.highligtedItem}
          />;
        break;
      default:
        element = null;
        break;
    };
    return element;
  });
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>
      {items}
    </React.Fragment>
  );
}