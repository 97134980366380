import React, { useContext, useState } from 'react';
import styles from './DirectoryEditorHeader.module.css';
import { AppUiContextStore } from '../../../../../../context/app-ui-context/AppUiContextProvider';
import GetName from '../../../../../common/functions/GetName';
import { IUiDictionaryItem } from '../../../../../common/dictionaries/IUiDictionaryItem';
import InputDropdownPopup from '../../../../../common/input-v2/input-dropdown/input-dropdown-popup/InputDropdownPopup';
import CombineStyles from '../../../../../../utils/combineStyles';
import { DirectoryEditorContext } from '../../../../../../context/directory-editor-context/DirectoryEditorContextProvider';
import { getDateTimeString } from '../../../../../../utils/getDateTimeString';

interface IProps {
  onSelectSourceLocale?: (selectedLocale?: string) => void;
  onDetailsEdit?: () => void;
}

export default function DirectoryEditorHeader(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const { directoryEditorContext } = useContext(DirectoryEditorContext);
  const [sourceLocaleOpen, setSourceLocaleOpen] = useState(false);
  const localeName = appUiContext.dictionaries?.supportedLocales?.find(l => l.id == directoryEditorContext?.editorState.draft.locale)?.name;
  const original = directoryEditorContext.editorState.original?.summary?.publishedAt ?
    `Published ${getDateTimeString(directoryEditorContext.editorState.original.summary.publishedAt)}` :
    "Not published";
  //---------------------------------------------------------------------------
  let draftStateString = directoryEditorContext?.editorState.draft.log?.getDraftStateString();
  let rejected = directoryEditorContext.editorState.draft.log?.getActualRecord()?.isRejected;
  let attention = true;
  if (!draftStateString) {
    draftStateString = "Not saved";
    if (directoryEditorContext?.editorState.draft.summary.savedAt) {
      draftStateString = `Saved ${getDateTimeString(directoryEditorContext.editorState.draft.summary.savedAt)}`;
    };
    attention = false;
  };
  //---------------------------------------------------------------------------
  let sourceLocaleId = "i18n";
  let sourceLocale: IUiDictionaryItem | undefined = {
    index: 0,
    id: sourceLocaleId,
    name: { en: "System Id" },
    description: undefined
  };
  if (directoryEditorContext?.sourceLocale) {
    sourceLocaleId = directoryEditorContext.sourceLocale;
    sourceLocale = appUiContext.dictionaries?.supportedLocales?.find(l => l.id == directoryEditorContext?.sourceLocale);
  };
  //---------------------------------------------------------------------------
  const onSourceLocaleSelect = (selectedLocale?: IUiDictionaryItem) => {
    setSourceLocaleOpen(false);
    props.onSelectSourceLocale && props.onSelectSourceLocale(selectedLocale ? selectedLocale.id : undefined);
  };
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>

      {/* Directory Id */}
      <div className={styles.caption}>
        {`${directoryEditorContext?.directoryType} Id`}
      </div>
      <div
        className={CombineStyles([
          styles.value,
          props.onDetailsEdit ? styles.id : ''
        ])}
        onClick={props.onDetailsEdit}
      >
        {directoryEditorContext?.id}
      </div>

      {/* Directory Name */}
      <div className={styles.caption}>
        Name
      </div>
      <div
        className={styles.value}
      >
        {directoryEditorContext?.name}
      </div>

      {/* Directory Description */}
      <div className={styles.caption}>
        Description
      </div>
      <div className={styles.value}>
        {directoryEditorContext?.description}
      </div>

      {/* Locale */}
      <div className={styles.caption}>
        Locale
      </div>
      <div>
        {directoryEditorContext?.editorState.draft.locale && GetName(appUiContext, directoryEditorContext.editorState.draft.locale, localeName)}
      </div>

      {/* Source Locale */}
      {props.onSelectSourceLocale &&
        <React.Fragment>
          <div className={styles.caption}>
            Source Locale
          </div>
          <div
            id='OptionSourceLocale'
            className={styles.option}
            onClick={() => setSourceLocaleOpen(true)}
          >
            {GetName(appUiContext, sourceLocaleId, sourceLocale?.name)}
          </div>
        </React.Fragment>}

      {/* Draft */}
      <div className={styles.caption}>
        Draft
      </div>
      <div className={CombineStyles([
        styles.value,
        directoryEditorContext?.editorState.draft.summary.savedAt ? '' : styles.notSaved,
        attention ? styles.attention : '',
        rejected ? styles.rejected : ''
      ])}
      >
        {draftStateString}
      </div>

      {/* Original */}
      <div className={styles.caption}>
        Original
      </div>
      <div className={CombineStyles([
        styles.value,
        directoryEditorContext?.editorState.draft.summary.publishedAt ? '' : styles.notSaved])}>
        {original}
      </div>

      {sourceLocaleOpen && appUiContext.dictionaries?.supportedLocales &&
        <InputDropdownPopup
          ui={{
            id: 'OptionSourceLocale',
            directoryId: "",
            elementType: "",
            dictionaryId: "",
            caption: undefined,
            placeholder: { en: "System Id" },
            hint: undefined
          }}
          options={appUiContext.dictionaries.supportedLocales}
          sortOptions={true}
          hideDescription={true}
          onClose={() => setSourceLocaleOpen(false)}
          onSelect={onSourceLocaleSelect}
        />}

    </div>
  );
}