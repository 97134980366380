import { useContext } from 'react';
import PopupTest from '../../../../../../common/popup-v2/popup/PopupTest';
import { IDocumentsListItemTranslation } from '../translations-item/IDocumentsListItemTranslation';
import styles from './PopupSelectOriginal.module.css';
import { AppUiContextStore } from '../../../../../../../context/app-ui-context/AppUiContextProvider';
import GetName from '../../../../../../common/functions/GetName';

interface IProps {
  data: IDocumentsListItemTranslation[];
  onSelect: (locale: string) => void;
  onClose: () => void;
}

export default function PopupSelectOriginal(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  //---------------------------------------------------------------------------
  const listItems = props.data.filter(item => item.originalExists).map(item => {
    const localeName = appUiContext.dictionaries?.supportedLocales?.find(l => l.id === item.locale)?.name;
    return (
      <div
        key={item.locale}
        className={styles.item}
        onClick={() => props.onSelect(item.locale)}
      >
        {GetName(appUiContext, item.locale, localeName)}
      </div>
    )
  });
  //---------------------------------------------------------------------------
  return (
    <PopupTest
      id=""
      closeOnClickOutside={true}
      onClose={props.onClose}
    >
      <div className={styles.container}>
        {listItems}
      </div>
    </PopupTest>
  );
}