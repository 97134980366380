import { useParams } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import getDictionaryTranslationDraft from './getDictionaryTranslationDraft';
import { useMsal } from '@azure/msal-react';
import { AppAuthContextStore } from '../../../../../context/app-auth-context/AppAuthContext';
import DictionaryEditorWrapper from '../../../common/dictionary-editor-wrapper/DictionaryEditorWrapper';
import { IDictionaryDraftFilter } from '../../../common/dictionary-editor-wrapper/dictionary-editor-filter/DictionaryEditorFilter';
import { dictionaryNodeWrapperTmpUi } from '../../../administrator-console/dictionaries/common/dictionary-node-wrapper/DictionaryEditorNodeWrapper';
import { DictionaryTranslationDraftItem } from '../DictionaryTranslationDraftItem';
import saveDictionaryTranslationDraft from './saveDictionaryTranslationDraft';
import DictionaryTranslatorTableHeader from '../dictionary-translator-table-header/DictionaryTranslatorTableHeader';
import deleteDictionaryTranslationDraft from './deleteDictionaryTranslationDraft';
import publishDictionaryTranslationDraft from './publishDictionaryTranslationDraft';
import useBeforeUnload from '../../../../../hooks/useBeforeUnload';
import { DictionaryTranslationDraft, DictionaryTranslatorState, IDictionaryTranslatorState } from './DictionaryTranslator';
import DictionaryTranslatorNodeV2 from '../dictionary-translator-node/DictionaryTranslatorNodeV2';
import { DictionaryDraft } from '../../../common/dictionary-draft-models/DictionaryDraft';
import { useAbortController } from '../../../../../hooks/useAbortController';
import { EHttpStatusCode } from '../../../../../utils/HttpStatusCodes';

export default function DictionaryTranslatorV2() {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { dictionaryId, localeId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [state, setState] = useState<DictionaryTranslatorState>();
  useBeforeUnload(state?.isUpdatedInSession);
  const abortController = useAbortController("DictionaryTranslatorV2");
  //---------------------------------------------------------------------------
  useEffect(() => {
    return () => {
      abortController.abortOnUnmount();
    }
  }, []);
  //--------------------------------------------------------------------------- Get data
  useEffect(() => {
    getDraft(false);
  }, [dictionaryId]);
  //---------------------------------------------------------------------------
  const getDraft = (lockIsNeeded: boolean) => {
    if (dictionaryId && localeId) {
      console.log("DictionaryTranslator: getting draft...")
      //-----------------------------------------------------------------------
      // Get Dictionary translation draft
      setIsLoading(true);
      let controller = abortController.newController("getDictionaryTranslationDraft");
      getDictionaryTranslationDraft(
        instance,
        dictionaryId,
        localeId,
        lockIsNeeded, 
        appAuthContext.config,
        controller.signal
      ).then(dictionaryDraft => {
        console.log("getDictionaryTranslationDraft:", dictionaryDraft);
        let locker = dictionaryDraft.locker;
        if (locker)
          locker.errorPrefix = `Dictionary[${dictionaryId}]`;
        if (state) {
          //-------------------------------------------------------------------
          // State is already set
          const translatorState = new DictionaryTranslatorState(undefined, {
            state: state,
            spread: {
              draft: new DictionaryTranslationDraft(dictionaryDraft),
              editModeIsEnabled: !!(locker && locker?.statusCode == EHttpStatusCode.OK)
            }
          });
          console.log("draft:", translatorState.draft);
          !controller.aborted && setState(translatorState);
        }
        else {
          //-------------------------------------------------------------------
          // State is not set yet
          const translatorState = new DictionaryTranslatorState({
            dictionaryDraft: dictionaryDraft,
            editMode: !!(locker && locker.statusCode == EHttpStatusCode.OK)
          });
          !controller.aborted && setState(translatorState);
        }
      }).catch(error => {
        console.error(error);
      }).finally(() => {
        !controller.aborted && setIsLoading(false);
      });
    };
  };
  //---------------------------------------------------------------------------
  const onSaveDraft = () => {
    if (state?.draft) {
      setIsLoading(true);
      let controller = abortController.newController("saveDictionaryTranslationDraft");
      saveDictionaryTranslationDraft(
        instance,
        state?.draft,
        appAuthContext.config
      ).then(dictionaryDraft => {
        const translatorState = new DictionaryTranslatorState({
          dictionaryDraft: dictionaryDraft,
          editMode: true
        });
        !controller.aborted && setState(translatorState);
      }).catch(error => {
        console.error(error);
      }).finally(() => {
        !controller.aborted && setIsLoading(false);
      });
    } else console.error("Cannot save dictionary draft, state is not set");
  };
  //---------------------------------------------------------------------------
  const onDeleteDraft = (releaseLockCallback?: () => void) => {
    if (state?.draft && state.draft.dictionaryId && state.draft.locale) {
      setIsLoading(true);
      //-----------------------------------------------------------------------
      // Delete Dictionary translation draft
      let controller = abortController.newController("deleteDictionaryTranslationDraft");
      deleteDictionaryTranslationDraft(
        instance,
        state.draft.dictionaryId,
        state.draft.locale,
        appAuthContext.config
      ).then(dictionaryDraft => {
        // Probably we should release lock on backend: need to update Locker component
        releaseLockCallback && releaseLockCallback();
        const translatorState = new DictionaryTranslatorState({
          dictionaryDraft: dictionaryDraft,
          editMode: false
        });
        !controller.aborted && setState(translatorState);
      }).catch(error => {
        console.error(error);
      }).finally(() => {
        !controller.aborted && setIsLoading(false);
      });
    } else console.error("Cannot delete dictionary draft, state is not set");
  };
  //---------------------------------------------------------------------------
  const onPublish = () => {
    if (state?.draft && state.draft.locale) {
      setIsLoading(true);
      let controller = abortController.newController("publishDictionaryTranslationDraft");
      publishDictionaryTranslationDraft(
        instance,
        state.draft.dictionaryId,
        state.draft.locale,
        appAuthContext.config
      ).then(dictionaryDraft => {
        const translatorState = new DictionaryTranslatorState({
          dictionaryDraft: dictionaryDraft,
          editMode: false // ???
        });
        !controller.aborted && setState(translatorState);
      }).catch(error => {
        console.error(error);
      }).finally(() => {
        !controller.aborted && setIsLoading(false);
      });
    } else console.error("Cannot save dictionary draft, state is not set");
  };
  //---------------------------------------------------------------------------
  const onFilterUpdate = (updatedFilter: IDictionaryDraftFilter) => {
    setState(new DictionaryTranslatorState(undefined, {
      state: state as IDictionaryTranslatorState,
      spread: { filter: updatedFilter }
    }));
  };
  //---------------------------------------------------------------------------
  const onEnableEditMode = () => {
    getDraft(true);
  };
  //---------------------------------------------------------------------------
  const onDisableEditMode = () => {
    setState(new DictionaryTranslatorState(undefined, {
      state: state as IDictionaryTranslatorState,
      spread: {
        draft: state?.draftSessionInitial,
        editModeIsEnabled: false
      }
    }));
  };
  //---------------------------------------------------------------------------
  const onTranslationDraftItemUpdate = (updatedItem: DictionaryTranslationDraftItem) => {
    if (state?.draft.items) {
      const updatedItems = state?.draft.items?.map(item => item.id == updatedItem.id ? updatedItem : item);
      const updatedDictionaryDraft = new DictionaryTranslationDraft(state.draft);
      updatedDictionaryDraft.items = updatedItems;
      setState(new DictionaryTranslatorState(undefined, {
        state: state as IDictionaryTranslatorState,
        spread: { draft: updatedDictionaryDraft }
      }));
    } else console.error("Items are being updated but state is not set");
  };
  //---------------------------------------------------------------------------
  const onSourceLocaleUpdate = (localeId: string) => {
    setState(new DictionaryTranslatorState(undefined, {
      state: state as IDictionaryTranslatorState,
      spread: { sourceLocale: localeId }
    }));
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (selectedOptionId: string, releaseLockCallback?: () => void) => {
    switch (selectedOptionId) {
      case "optionEnableEditMode":
        setState(new DictionaryTranslatorState(undefined, {
          state: state as IDictionaryTranslatorState,
          spread: { editModeIsEnabled: true }
        }));
        break;
      case "optionDisableEditMode":
        onDisableEditMode();
        break;
      case "optionSave":
        onSaveDraft();
        break;
      case "optionDraftDelete":
        onDeleteDraft(releaseLockCallback);
        break;
      case "optionPublish":
        onPublish();
        break;
      default:
        break;
    };
  };
  //---------------------------------------------------------------------------
  const tableHeader =
    <DictionaryTranslatorTableHeader />;
  //-------------------------------------------------------- Dictionary content
  //console.log("state?.draft", state?.draft);
  const filteredDictionaryItems =
    state?.draft.items?.filter(item => item.checkFilter(state?.filter));
  //---------------------------------------------------------------------------
  let rootItems: DictionaryTranslationDraftItem[] = [];
  let otherItems: DictionaryTranslationDraftItem[] = [];
  filteredDictionaryItems && DictionaryDraft.getSiblings(
    filteredDictionaryItems,
    rootItems, otherItems
  );
  const dictionaryNodes = rootItems?.map(item => (
    <DictionaryTranslatorNodeV2
      ui={dictionaryNodeWrapperTmpUi}
      key={item.id}
      stateGroups={state?.filter.stateGroups}
      draftLocale={state?.draft.locale}
      sourceLocale={state?.sourceLocale}
      isHierarchical={state?.draft.isHierarchical}
      // Pass item published data so we can display language fallback logic result
      data={item}
      items={otherItems}
      editModeIsEnabled={state?.editModeIsEnabled}
      onUpdate={onTranslationDraftItemUpdate}
    />
  ));
  //---------------------------------------------------------------------------
  return (
    <DictionaryEditorWrapper
      setup={{
        mode: "Translator",
        role: "SystemTranslator",
        entityType: "Dictionary"
      }}
      state={state}
      //tableHeader={tableHeader}
      isLoading={isLoading}
      onFilterUpdate={onFilterUpdate}
      onEnterEditMode={onEnableEditMode}
      onOptionSelect={onOptionSelect}
      onSourceLocaleUpdate={onSourceLocaleUpdate}
    >
      {dictionaryNodes}
    </DictionaryEditorWrapper>
  );
}