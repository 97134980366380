import { ImageList } from "./image-list/ImageList";
import ConsoleTabLayout from "../../../common/console-layout/console-tab-layout/ConsoleTabLayout";
import ConsoleTabContent from "../../../common/console-layout/console-tab-layout/console-tab-content/ConsoleTabContent";
import { useContext, useEffect, useState } from "react";
import { AppUiContextStore } from "../../../../context/app-ui-context/AppUiContextProvider";
import { IUiImageLibrary } from "./IUiImageLibrary";
import GetHint from "../../../common/functions/GetHint";
import { IUiMenuContent } from "../../../common/options/menus/IUiMenuContent";
import { IUiOption } from "../../../common/options/IUiOption";
import { getTUiMenuContent } from "../../../common/menu/menu-content/TUiMenuContent";
import FormOptions from "../../../common/form-options-bar/FormOptions";

export class ImageLibraryOptions {
  static addNewImage = "Add_New_Image_Option";
}

const addNewImageOption: IUiOption = {
  id: ImageLibraryOptions.addNewImage,
  caption: { en: "Add New Image..." },
  directoryId: "",
  elementType: "",
  hint: { en: "Add New Image..." },
}

const iUiMenuContent: IUiMenuContent = {
  id: "User_Image_Library_Options",
  directoryId: "",
  elementType: "",
  hint: "User_Image_Library_Options",
  options: [addNewImageOption],
};

interface IProps {
  ui?: IUiImageLibrary;
}

export default function ImageLibrary(props: IProps) {
  const { appUiContext, appUiContextDispatch } = useContext(AppUiContextStore);
  const [addImageSignal, setAddImageSignal] = useState(false);
  //-[addImageSignal]----------------------------------------------------
  useEffect(() => {
    if (addImageSignal) {
      setAddImageSignal(false);
    }
  }, [addImageSignal]);
  //---------------------------------------------------------------------------
  useEffect(() => {
    //console.log(props.ui?.breadcrumb)
    if (props.ui?.breadcrumb) {
      // const breadcrumb: TUiBreadcrumb = {
      //   id: props.ui.breadcrumb.id,
      //   caption: props.ui.breadcrumb.caption,
      //   path: `/${pathUserConsole}/${pathUserProfile}/${pathImageLibrary}`
      // };
      appUiContextDispatch({ type: "UpdateBreadcrumbsTrail", value: props.ui?.breadcrumb });
    };
  }, []);
  //-[addImageSignal]----------------------------------------------------
  useEffect(() => {
    if (addImageSignal) {
      setAddImageSignal(false);
    }
  }, [addImageSignal]);
  //---------------------------------------------------------------------------
  const onOptionSelect = (optionId: string) => {
    console.log("ImageLibrary.onOptionSelect:", optionId);
    switch (optionId) {
      case ImageLibraryOptions.addNewImage:
        setAddImageSignal(true);
        break;
    }
  }
  //---------------------------------------------------------------------------
  let tUiMenuContent = getTUiMenuContent(iUiMenuContent);
  let formOptions = <FormOptions ui={tUiMenuContent} onSelect={onOptionSelect} />
  //---------------------------------------------------------------------------
  return (
    <ConsoleTabLayout toolbar={formOptions} allowScroll={true}>
      {props.ui &&
        <ConsoleTabContent
          prompt={GetHint(appUiContext, props.ui.id, props.ui.hint)}
        >
          <ImageList
            ui={{
              ...props.ui.userImagesList,
              imageEditor: props.ui.imageEditor
            }}
            canSelectItem={false}
            clearContextOnUnmount={true}
            addImageSignal={addImageSignal}
          />
        </ConsoleTabContent>}
    </ConsoleTabLayout>
  );
}