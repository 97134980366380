import CombineStyles from '../../../../../../utils/combineStyles';
import IconCheckBox from '../../../../../common/icons/IconCheckBox';
import InputWrapper from '../../../../../common/input-v2/input-wrapper/InputWrapper';
import { IDictionaryItemStatus } from '../IDictionaryDraftItem';
import styles from './DictionaryItemStatus.module.css';

interface IProps {
  data?: IDictionaryItemStatus;
  isReadOnly?: boolean;
  oneLine?: boolean;
  onUpdate?: (updatedData: IDictionaryItemStatus) => void;
}

export default function DictionaryItemStatus(props: IProps) {
  //---------------------------------------------------------------------------
  const onEditorsUpdate = () => {
    if (props.isReadOnly) return;
    if (!props.onUpdate) return;
    const updatedData: IDictionaryItemStatus = props.data ? {
      ...props.data,
      editors: !props.data.editors
    } : {
      editors: true,
      viewers: false
    };
    props.onUpdate(updatedData);
  };
  //---------------------------------------------------------------------------
  const onViewersUpdate = () => {
    if (props.isReadOnly) return;
    if (!props.onUpdate) return;
    const updatedData: IDictionaryItemStatus = props.data ? {
      ...props.data,
      viewers: !props.data.viewers
    } : {
      editors: false,
      viewers: true
    };
    props.onUpdate(updatedData);
  };
  //---------------------------------------------------------------------------
  return (
    <InputWrapper
      caption="Accessibility"
      hint="Editors: system translators, readers: end users of the dictionary."
      hideCaption={false}
      hideHint={false}
    >
      <div className={styles.container}>
        <CheckboxGroupItem
          checked={!!props.data?.editors}
          caption="Editors"
          isReadOnly={!!props.isReadOnly}
          onSwitch={onEditorsUpdate}
        />
        <CheckboxGroupItem
          checked={!!props.data?.viewers}
          caption="Readers"
          isReadOnly={!!props.isReadOnly}
          onSwitch={onViewersUpdate}
        />
      </div>
    </InputWrapper>
  );
}

interface ICheckboxItemProps {
  checked: boolean;
  caption: string;
  isReadOnly: boolean;
  onSwitch: () => void;
}

export function CheckboxGroupItem(props: ICheckboxItemProps) {
  return (
    <div
      className={CombineStyles([
        styles.item,
        props.checked ? styles.selected : '',
        props.isReadOnly ? styles.disabled : ''
      ])}
      onClick={props.onSwitch}>

      {!props.isReadOnly &&
        <IconCheckBox checked={!!props.checked} />}

      <div className={styles.caption}>
        {props.caption}
      </div>

    </div>
  );
}