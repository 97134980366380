import { useContext, useEffect, useState } from 'react';
import styles from './ChecklistNodeReject.module.css';
import CombineStyles from '../../../../../utils/combineStyles';
import { IUiEditformReject } from './IUiEditformReject';
import { IUiOption } from '../../../../common/options/IUiOption';
import FormOptions from '../../../../common/form-options-bar/FormOptions';
import { getTUiMenuContent } from '../../../../common/menu/menu-content/TUiMenuContent';
import { EditFormStateV2 } from '../../../../common/edit-form/IEditFormStateV2';
import { ClassRequestRejectionReason, IRequestRejectionReason } from './IRequestRejectionReason';
import InputsGroupWrapper from '../../../../common/input-v2/inputs-group-wrapper/InputsGroupWrapper';
import InputDropdown from '../../../../common/input-v2/input-dropdown/InputDropdown';
import InputText from '../../../../common/input-v2/input-text/InputText';
import { AppUiContextStore } from '../../../../../context/app-ui-context/AppUiContextProvider';
import GetName from '../../../../common/functions/GetName';

interface IProps {
  ui: IUiEditformReject;
  data?: IRequestRejectionReason;
  isReadonly: boolean;
  editMode?: boolean;
  onUpdate: (updatedData: IRequestRejectionReason) => void;
  onCancel: () => void;
}

export default function ChecklistNodeReject(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const [editMode, setEditMode] = useState(!!props.editMode);
  const [state, setState] = useState<EditFormStateV2<ClassRequestRejectionReason>>();
  const rejectionReasonName = appUiContext.dictionaries?.requestRejectionReasons?.find(reason => reason.id === props.data?.reasonId)?.name;
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (editMode) {
      const newState = new EditFormStateV2<ClassRequestRejectionReason>({
        object: new ClassRequestRejectionReason(props.data)
      });
      setState(newState);
    } else {
      setState(undefined);
    };
  }, [editMode]);
  //---------------------------------------------------------------------------
  const onReasonUpdate = (reasonId?: string) => {
    if (!state?.object) return;
    if (!reasonId) return;
    const updatedObject = new ClassRequestRejectionReason({
      ...state.object,
      reasonId: reasonId
    });
    const updatedState = new EditFormStateV2<ClassRequestRejectionReason>(state, {
      object: updatedObject
    });
    setState(updatedState);
  };
  //---------------------------------------------------------------------------
  const onReasonDetailsUpdate = (updatedText: string) => {
    if (!state?.object) return;
    const updatedObject = new ClassRequestRejectionReason({
      ...state.object,
      reasonDetails: updatedText
    });
    const updatedState = new EditFormStateV2<ClassRequestRejectionReason>(state, {
      object: updatedObject
    });
    setState(updatedState);
  };
  //---------------------------------------------------------------------------
  const onConfirm = () => {
    if (!state?.object) return;
    setEditMode(false);
    props.onUpdate(state.object);
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (optionId: string) => {
    switch (optionId) {
      case "OptionClose":
      case "OptionCancel":
        setState(undefined);
        setEditMode(false);
        props.onCancel();
        break;
      case "OptionOk":
        onConfirm();
        break;
    };
  };
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  props.ui.menuContent.options.forEach(option => {
    switch (option.id) {
      case "OptionClose":
        if (props.isReadonly) {
          updatedOptions.push({ ...option, iconId: "close" });
        };
        break;
      case "OptionCancel":
        if (!props.isReadonly) {
          updatedOptions.push({ ...option, iconId: "close" });
        };
        break;
      case "OptionOk":
        const isDisabled = !state?.isValid || !state?.isUpdated;
        updatedOptions.push({
          ...option,
          iconId: "check",
          isDisabled: isDisabled,
          isDefault: !isDisabled,
          customColor: isDisabled ? undefined : 'var(--clr_accent_wrong)',
        });
        break;
    };
  });
  //---------------------------------------------------------------------------
  if (editMode) return (
    <div className={CombineStyles([styles.container, styles.edit])}>
      <InputsGroupWrapper addPadding>
        <InputDropdown
          ui={props.ui.inputRejectionReason}
          data={state?.object?.reasonId}
          options={appUiContext.dictionaries?.requestRejectionReasons}
          onUpdate={onReasonUpdate}
        />
        <InputText
          ui={props.ui.inputRejectionReasonDetails}
          linesCount={2}
          data={state?.object?.reasonDetails}
          onUpdate={onReasonDetailsUpdate}
        />
      </InputsGroupWrapper>
      <FormOptions
        ui={getTUiMenuContent({ ...props.ui.menuContent, options: updatedOptions })}
        onSelect={onOptionSelect}
      />
    </div>
  ); else if (props.data) return (
    <div
      className={CombineStyles([styles.container, styles.view])}
      onClick={() => setEditMode(true)}
    >
      {props.data.reasonDetails ?
        `${GetName(appUiContext, props.data.reasonId, rejectionReasonName)}: ${props.data.reasonDetails}` :
        `${GetName(appUiContext, props.data.reasonId, rejectionReasonName)}`}
    </div>
  ); else return null;
}