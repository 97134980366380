import { IUiInteractiveForm } from "../common/forms/IUiInteractiveForm";

export interface IUiDocumentViewer extends IUiInteractiveForm {

}

export const tmpUiDocumentViewer: IUiDocumentViewer = {
  id: "DocumentViewer",
  directoryId: "",
  elementType: "",
  caption: { en: "Riki.Wiki"},
  hint: undefined,
  menuContent: {
    id: "DocumentViewer.MenuContent",
    directoryId: "",
    elementType: "",
    hint: undefined,
    options: [{
      id: "OptionOk",
      directoryId: "",
      elementType: "",
      caption: { en: "Ok" },
      hint: undefined,
    }]
  }
}