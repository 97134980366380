import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../../context/app-auth-context/AppAuthContext";
import { apiBasePath, pathCreateDocumentDraft } from "../../../../../utils/apiPathConstant";
import { apiPostPrivate, checkResponse } from "../../../../../utils/api";
import { IDocumentDetails } from "../document-details/IDocumentDetails";
import { IDocumentsListItem } from "../documents-list/documents-list-item/IDocumentsListItem";
import { IDocumentDraft } from "../document-editor/IDocumentDraft";

interface IProps {
  msalInstance: IPublicClientApplication;
  authConfig?: IAuthConfig;
  abortSignal?: AbortSignal;
  documentDetails: IDocumentDetails;
  respondWithDraft?: boolean;
}

export async function createDocumentDraftAsync(props: IProps): Promise<IDocumentsListItem[] | IDocumentDraft> {
  if (!props.authConfig) throw new Error("createDocumentDraftAsync: auth config is not provided");
  //---------------------------------------------------------------------------
  let uri = `${apiBasePath}${pathCreateDocumentDraft}`;
  if (props.respondWithDraft) uri += "?list=false";
  const response = await apiPostPrivate(
    props.msalInstance,
    props.authConfig,
    uri,
    props.documentDetails,
    undefined,
    props.abortSignal
  );
  //---------------------------------------------------------------------------
  checkResponse(response, "createDocumentDraftAsync", props.abortSignal?.aborted);
  const documentListItems = props.respondWithDraft ?
    response?.content as IDocumentDraft :
    response?.content as IDocumentsListItem[];
  return documentListItems;
}