import { IAdCampaign } from "../../../context/business-context/IAdCampaign";
import { EBusinessType, IBusiness, IBusinessPaths } from "../../../context/business-context/IBusiness";
import { IImageData, TImageData } from "../../common/image-editor/TImageData";
import { Contact } from "./section-contacts/contact/Contact";
import { IBusinessLocationData } from "./section-location/business-location-editor/IBusinessLocationData";

export interface ICustomerBusiness extends IBusiness {
  name: string;
  logo?: TImageData;
  removedLogo?: TImageData;
  tags?: string[];
  locationData?: IBusinessLocationData<string>;
  contacts: Contact<string>[];
}

export class ClassCustomerBusiness implements ICustomerBusiness {
  id!: string;
  businessType!: EBusinessType;
  name!: string;
  //---------------------------------------------------------------------------
  private _logo?: TImageData;
  get logo(): TImageData | undefined {
    return this._logo;
  }
  set logo(value: IImageData | undefined ) {
    if (value)
      this._logo = new TImageData(value);
    else
      this._logo = undefined;
  }
  //---------------------------------------------------------------------------
  removedLogo?: TImageData;
  tags?: string[];
  locationData?: IBusinessLocationData<string>;
  contacts!: Contact<string>[];
  paths!: IBusinessPaths;
  adCampaigns!: IAdCampaign[];
  datetimeCreated!: string;
  //---------------------------------------------------------------------------
  constructor(data: ICustomerBusiness) {
    Object.assign(this, data);
    this.logo = this.logo ? new TImageData(this.logo) : undefined;
    this.contacts = this.contacts.map(contact => new Contact(contact));
  };
  //---------------------------------------------------------------------
  toJSON() {
    let result = {
      ...this,
      _logo: null,
      logo: this._logo,
      removedLogo: null,
    };
    delete result["_logo"];
    delete result["removedLogo"];
    return result;
  }
  //---------------------------------------------------------------------------
  validate(): boolean {
    if (!this.name) return false;
    return true;
  }  
}